import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, ScrollArea, Text} from "@mantine/core";
import {
    HiCloudDownload,
    HiOutlineX
} from "react-icons/hi";
import {setPoViewID, setPoViewModel} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";
import {
    ReceivedApproved,
    ReceivedChecked,
    ReceiveDetails,
    setRecViewID,
    setRecViewModel,
    setFetching
} from "../../../store/receivedItem/receivedItemSlice";

import {Tooltip} from '@mantine/core';
import PoViewModel from "../PurchaseOrder/PoViewModel";
import View from "../Requisition/RequisitionView";
import ReactToPrint from 'react-to-print';
import {format} from "date-fns";


function RecViewModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [requisitionID, setRequisitionID] = useState('');
    const [viewModel, setViewModel] = useState(false);


    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_CHECKED = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_CHECKED";
    })
    const ROLE_RECEIVE_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_APPROVE";
    })

    const ROLE_RECEIVE_VERIFY = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VERIFY";
    })

    const recViewID = useSelector((state) => state.receivedItemReducer.recViewID)
    const receiveDetailsData = useSelector((state) => state.receivedItemReducer.receiveDetailsData)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)

    useEffect((e) => {
        setTimeout(() => {
            dispatch(ReceiveDetails({
                received_id: recViewID
            }))
            setLoading(false)
        }, 500)
    }, [loading])

    let totalReceiveAmount = 0;

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ReceivedItemDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setRecViewModel(false))
                                        dispatch(setRecViewID(null))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                <Fragment>
                                                    <div className="bg-gray-100" ref={pdfRef}>
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                {
                                                                    receiveDetailsData &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">

                                                                                    <>
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('GoodsReceivedInformation')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list"
                                                                                            className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                receiveDetailsData.project_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('ProjectName')} : </span>
                                                                                                        {receiveDetailsData.project_name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }

                                                                                            {
                                                                                                (receiveDetailsData.vendors || receiveDetailsData.buyers) &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ReceivedFrom')} : </span>
                                                                                                        {
                                                                                                            receiveDetailsData.vendors ?
                                                                                                                'Vendor'
                                                                                                                :
                                                                                                                'Buyer'
                                                                                                        }
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.id &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('GRN')} : </span>
                                                                                                        {receiveDetailsData.id}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.ref_grn &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('RefNo')} : </span>
                                                                                                        {receiveDetailsData.ref_grn}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.received_email &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ReceivedBy')} : </span>
                                                                                                        {receiveDetailsData.received_email}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.contact_number &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ContactNumber')} : </span>
                                                                                                        {receiveDetailsData.contact_number}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.received_date &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ReceivedDate')} : </span>
                                                                                                        {receiveDetailsData.received_date}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700"></p>
                                                                                            </li>


                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('CheckedBy')} : </span>
                                                                                                    {receiveDetailsData.checked_by ? receiveDetailsData.checked_email : 'Not Checked'}
                                                                                                </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700"></p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('ApprovedBy')} : </span>
                                                                                                    {receiveDetailsData.rec_approved_by ? receiveDetailsData.rec_approved_full_name : 'Not Approved'}
                                                                                                </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700"></p>
                                                                                            </li>


                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('BillDateNumber')} : </span>
                                                                                                    {receiveDetailsData.bill_date ? format(new Date(receiveDetailsData.bill_date), 'dd-MM-yyyy') : ''}
                                                                                                    /
                                                                                                    {receiveDetailsData.bill_number ? receiveDetailsData.bill_number : ''}
                                                                                                </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700"></p>
                                                                                            </li>


                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('SendBackComment')} : </span>
                                                                                                    {receiveDetailsData.send_back_comment ? receiveDetailsData.send_back_comment : ''}
                                                                                                </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700"></p>
                                                                                            </li>
                                                                                            {
                                                                                                receiveDetailsData.net_total &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('TotalPoAmount')} : </span>
                                                                                                        TK. {Number(receiveDetailsData.net_total).toFixed(2)}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    (receiveDetailsData && receiveDetailsData.vendors) &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">

                                                                                    <>
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorInformation')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list"
                                                                                            className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                receiveDetailsData.vendors_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('VendorName')} : </span>
                                                                                                        {receiveDetailsData.vendors_name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }

                                                                                            {
                                                                                                receiveDetailsData.vendor_contract_person &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ContactPerson')} : </span>
                                                                                                        {receiveDetailsData.vendor_contract_person}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.vendor_contract_no &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ContactNumber')} : </span>
                                                                                                        {receiveDetailsData.vendor_contract_no}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.vendors_address &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Address')} : </span>
                                                                                                        {receiveDetailsData.vendors_address}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    (receiveDetailsData && receiveDetailsData.buyers) &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">

                                                                                    <>
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BuyerInformation')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list"
                                                                                            className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                receiveDetailsData.buyer_email &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('Name')} : </span>
                                                                                                        {receiveDetailsData.buyer_email}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                receiveDetailsData.buyer_contact_number &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('ContactNumber')} : </span>
                                                                                                        {receiveDetailsData.buyer_contact_number}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ReceivedItemsInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (receiveDetailsData && receiveDetailsData['received_items'] && receiveDetailsData['received_items'].length > 0) &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('Particulars')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('PRNo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('PRQty')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('PRAttach')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('PONo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('POQty')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('POAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('POAttach')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('RemainingQTY')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('GRNQty')}</div>
                                                                                            </th>

                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('UnitPrice')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SubTotal')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Vat')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Ait')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Mode')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('GRNAmount')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
{
    (receiveDetailsData && receiveDetailsData['received_items'] && receiveDetailsData['received_items'].length > 0) &&
    receiveDetailsData['received_items'].map((item, index) => {
        // console.log(item)
        // let remainQty = Number(item.total_order_quantity) - (Number(receiveDetailsData['approve_status'] == 1 ? 0 : (item.quantities ? Number(item.quantities) : 0)) + Number((item.total_order_receive_quantity ? Number(item.total_order_receive_quantity) : 0)))
        let remainQty = Number(item.total_order_quantity) - (Number(receiveDetailsData['rec_approved_status'] == 1 ? (item.quantities ? Number(item.quantities) : 0) : 0) + Number((item.total_order_receive_quantity ? Number(item.total_order_receive_quantity) : 0)))
        totalReceiveAmount = totalReceiveAmount + (item.quantities * item.item_unit_price);
        let totalItem = receiveDetailsData['received_items'].length
        return (
            <tr className={"bg-gray-100"}
                key={index}>
                <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.items_name ? item.items_name : ''}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center text-blue-600">
                    <button
                        onClick={() => {
                            setViewModel(true);
                            setRequisitionID(item.purchase_requisitions)
                        }}>{item.purchase_requisitions ? item.pr_number : ''}</button>

                </td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                    {item.pr_quantity ? item.pr_quantity : ''}
                    {' ' + item.item_unit_name}
                </td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.pr_attach ? item.pr_attach : ''}</td>

                {
                    (index && index === 1) &&
                    <td className="w-3/24 border-collapse border border-slate-400 text-center text-blue-600"
                        rowSpan={totalItem}>
                        <button
                            onClick={(e) => {
                                dispatch(setPoViewModel(true))
                                dispatch(setPoViewID(receiveDetailsData.purchase_orders))
                            }}>
                            {receiveDetailsData.purchase_orders ? receiveDetailsData.purchase_orders : ''}

                        </button>
                    </td>
                }


                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                    {item.total_order_quantity ? item.total_order_quantity : ''}
                    {' ' + item.item_unit_name}
                </td>
                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                    {Number(item.po_amount ? item.po_amount : '').toFixed(2)}
                </td>
                {
                    (index && index === 1) &&
                    <td className="w-3/24 border-collapse border border-slate-400 text-center"
                        rowSpan={totalItem}>
                        <a title="Vendor Quotation Attach"
                           target="new"
                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ receiveDetailsData.purchase_orders}/vendor-quotation`}
                           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                            <HiCloudDownload/>
                        </a>
                    </td>
                }

                <td className="w-3/24 border-collapse border border-slate-400 text-center">{remainQty + ' ' + item.item_unit_name}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.quantities + ' ' + item.item_unit_name}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_price).toFixed(2)}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_price*item.quantities).toFixed(2)}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_vat*item.quantities).toFixed(2)}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_ait*item.quantities).toFixed(2)}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.vat_mode}</td>
                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                    {Number(item.quantities * item.item_unit_price).toFixed(2)}
                </td>
            </tr>
        )
    })
}
                                                                                        <tr>
                                                                                            <td colSpan={5}
                                                                                                className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total
                                                                                            </td>
                                                                                            <td colSpan={3}
                                                                                                className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                                                                                TK. {Number(receiveDetailsData.net_total).toFixed(2)}
                                                                                            </td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold"
                                                                                                colSpan={9}>
                                                                                                TK. {Number(totalReceiveAmount).toFixed(2)}
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        receiveDetailsData &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('GRN')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Invoice')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Challan')}</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                receiveDetailsData &&
                                <tr className={"bg-gray-100"} key="1">
                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                        {
                                            (receiveDetailsData && receiveDetailsData.path) &&
                                            <a title="GRN Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.id}/grn`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{receiveDetailsData.path.split("/").pop() }</a>
                                        }
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {
                                            (receiveDetailsData && receiveDetailsData.invoice_path) &&
                                            <a title="Invoice Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.invoices}/invoice`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{receiveDetailsData.invoice_path.split("/").pop()}</a>
                                        }
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {
                                            (receiveDetailsData && receiveDetailsData.challan_path) &&
                                            <a title="Invoice Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.calans}/challan`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{receiveDetailsData.challan_path.split("/").pop()}</a>
                                        }
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>
    </div>

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('CheckedApprovedInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    receiveDetailsData &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('CheckedApproved')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('Name')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('Date')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            receiveDetailsData.checked_by &&
                                                                                            <tr className={"bg-gray-100"}
                                                                                                key="1">
                                                                                                <td className="w-1/24 border-collapse border border-slate-400 text-center">1</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">Checked</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.checked_email}</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.checked_date}</td>
                                                                                            </tr>
                                                                                        }
                                                                                        {
                                                                                            receiveDetailsData.rec_approved_by &&
                                                                                            <tr className={"bg-gray-100"}
                                                                                                key="2">
                                                                                                <td className="w-1/24 border-collapse border border-slate-400 text-center">2</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">Approved</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.rec_approved_full_name}</td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.rec_approved_date}</td>
                                                                                            </tr>
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">

                                                        <div className="float-left mb-3">

                                                            {(receiveDetailsData.process === 'Created' && ROLE_RECEIVE_CHECKED) &&
                                                                <button
                                                                    className="inline-flex items-center px-6 py-2 bg-teal-500 hover:bg-teal-600 text-white text-xs ml-2"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        openConfirmModal({
                                                                            title: (t('CheckedConfirmThisReceived')),
                                                                            centered: true,
                                                                            children: (
                                                                                <Text size="sm">
                                                                                    {t('AreYouSureToCheckedThisReceived')}
                                                                                </Text>
                                                                            ),
                                                                            labels: {
                                                                                confirm: (t('Confirm')),
                                                                                cancel: (t("Cancel"))
                                                                            },
                                                                            confirmProps: {color: 'green'},
                                                                            onCancel: () => console.log(t('Cancel')),
                                                                            onConfirm: () => {
                                                                                setLoading(true)
                                                                                setTimeout(() => {
                                                                                    dispatch(ReceivedChecked({
                                                                                        received_id: receiveDetailsData.id
                                                                                    }))
                                                                                }, 500)
                                                                            }
                                                                            ,
                                                                        });
                                                                    }}
                                                                >
                                                                    Checked
                                                                </button>
                                                            }

                                                            {(receiveDetailsData.process === 'Checked' && ROLE_RECEIVE_APPROVE) &&
                                                                <button
                                                                    className="inline-flex items-center px-6 py-2 bg-rose-500 hover:bg-rose-600 text-white text-xs ml-2"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        openConfirmModal({
                                                                            title: (t('ApprovedConfirmThisReceived')),
                                                                            centered: true,
                                                                            children: (
                                                                                <Text size="sm">
                                                                                    {t('AreYouSureToApprovedThisReceived')}
                                                                                </Text>
                                                                            ),
                                                                            labels: {
                                                                                confirm: (t('Confirm')),
                                                                                cancel: (t("Cancel"))
                                                                            },
                                                                            confirmProps: {color: 'green'},
                                                                            onCancel: () => console.log(t('Cancel')),
                                                                            onConfirm: () => {
                                                                                setLoading(true)
                                                                                setTimeout(() => {
                                                                                    dispatch(ReceivedApproved({
                                                                                        received_id: receiveDetailsData.id
                                                                                    }))
                                                                                }, 500)
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    Approved
                                                                </button>
                                                            }

                                                            {
                                                                receiveDetailsData.invoice_path ?
                                                                    <Tooltip
                                                                        label={' Bill No. ' + receiveDetailsData.invoice_bill_number + ' Date. ' + receiveDetailsData.invoice_bill_date}>
                                                                        <a target="_blank"
                                                                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.invoices}/invoice`}>
                                                                            <button
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs ml-2">
                                                                                Invoice / Bill
                                                                            </button>
                                                                        </a>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip
                                                                        label={'No Invoice Attachment'}>
                                                                        <button
                                                                            className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs ml-2">
                                                                            No Invoice / Bill Attachment
                                                                        </button>
                                                                    </Tooltip>
                                                            }

                                                            {
                                                                receiveDetailsData.challan_path ?
                                                                    <Tooltip
                                                                        label={' Challan No. ' + receiveDetailsData.challan_bill_number + ' Date. ' + receiveDetailsData.challan_bill_date}>
                                                                        <a target="_blank"
                                                                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.calans}/challan`}                                                                        >
                                                                            <button
                                                                                className="inline-flex items-center px-6 py-2 bg-cyan-700 hover:bg-cyan-900 text-white text-xs ml-2">
                                                                                Challan
                                                                            </button>
                                                                        </a>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip
                                                                        label={' No Challan Attachment'}>
                                                                        <button
                                                                            className="inline-flex items-center px-6 py-2 bg-cyan-700 hover:bg-cyan-900 text-white text-xs ml-2">
                                                                            No Challan Attachment
                                                                        </button>
                                                                    </Tooltip>
                                                            }

                                                            {
                                                                receiveDetailsData.path ?
                                                                    <Tooltip
                                                                        label={' GRN No. ' + receiveDetailsData.ref_grn + ' Date. ' + receiveDetailsData.received_date}>
                                                                        <a target="_blank"
                                                                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.id}/grn`}>
                                                                            <button
                                                                                className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2">
                                                                                GRN
                                                                            </button>
                                                                        </a>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip
                                                                        label={' No GRN Attachment '}>
                                                                        <button
                                                                            className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2">
                                                                            No GRN Attachment
                                                                        </button>
                                                                    </Tooltip>
                                                            }
                                                        </div>

                                                        <div className="float-right mb-3">
                                                            <ReactToPrint
                                                                trigger={() => {
                                                                    return <a href="#"
                                                                              className="inline-flex items-center px-6 py-2 bg-amber-700 hover:bg-amber-900 text-white text-xs ml-2">Print</a>;
                                                                }}
                                                                content={() => pdfRef.current}
                                                            />

                                                            <button
                                                                onClick={(e) => {
                                                                    dispatch(setRecViewModel(false))
                                                                    dispatch(setRecViewID(null))
                                                                    dispatch(setFetching(true))
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

            {poViewModel ? <PoViewModel/> : ''}
            {viewModel ? <View requisitionID={requisitionID} setViewModel={setViewModel}/> : ''}


        </>
    )
}

export default RecViewModel