import React, {Fragment, useEffect, useState} from 'react'
import {useRef} from 'react';

import {useTranslation} from "react-i18next";
import {ActionIcon, Loader, LoadingOverlay, ScrollArea, Stepper, Text, Textarea} from "@mantine/core";
import {
    HiArrowCircleRight,
    HiArrowSmRight, HiChevronLeft, HiOutlineUser,
    HiOutlineX, HiPencil
} from "react-icons/hi";
import {
    getPurchaseOrderDetails,
    poApproveInfomation,
    poCommentCreate,
    poLastPriceInfomation,
    setPoComment,
    setPoViewID,
    setPoViewModel,
    setPoCommentstatus,
    poApprove,
    setFetching,
    poProcessUpdate,
    setAfterApproveEditID,
    setAfterApproveEditModel,
    poAmendment,
    setEmptyPODetails
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {FiSave} from "react-icons/fi";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";

import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import AfterApproveEdit from "./AfterApproveEdit";
import {useNavigate, useParams} from "react-router";
import {
    setModelAllDataEmpty,
    setStoreRequisitionCreateID,
    setStoreRequisitionCreateMsg, setViewId, setViewModel, storeRequisitionDelivered, storeRequisitionReceived
} from "../../../store/storeRequisition/storeRequisitionSlice";
import ReactToPrint from "react-to-print";
import {Link} from "react-router-dom";
import OpenItemInformation from "./include/OpenItemInformation";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import autoTable from "jspdf-autotable";

function PrintPdfView() {
    const printRef = useRef()
    const navigate = useNavigate();
    const {id} = useParams();
    console.log(id)

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [commenting, setCommenting] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);
    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_TWO";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_THREE";
    })
    const ROLE_PURCHASE_ORDER_ADD = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_ADD";
    })
    const ROLE_PURCHASE_ORDER_VIEW = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_VIEW";
    })
    const ROLE_PURCHASE_ORDER_EDIT = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_EDIT";
    })
    const ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_PURCHASE_ORDER_AMENDMENT = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_AMENDMENT";
    })


    const poViewID = useSelector((state) => state.purchaseOrderReducer.poViewID)
    const purchaseOrderDetails = useSelector((state) => state.purchaseOrderReducer.purchaseOrderDetails)
    const purchaseOrderItem = useSelector((state) => state.purchaseOrderReducer.purchaseOrderItem)
    const purchaseOrderTermConditions = useSelector((state) => state.purchaseOrderReducer.purchaseOrderTermConditions)
    const poApproveInfo = useSelector((state) => state.purchaseOrderReducer.poApproveInfo)
    const poLastPriceLowestPriceInfo = useSelector((state) => state.purchaseOrderReducer.poLastPriceInfo)
    const poComment = useSelector((state) => state.purchaseOrderReducer.poComment)
    const poCommentstatus = useSelector((state) => state.purchaseOrderReducer.poCommentstatus)
    const fetching = useSelector((state) => state.purchaseOrderReducer.fetching)
    const afterApproveEditModel = useSelector((state) => state.purchaseOrderReducer.afterApproveEditModel)
    const poAmendmentMsg = useSelector((state) => state.purchaseOrderReducer.poAmendmentMsg)
    const poAmendmentId = useSelector((state) => state.purchaseOrderReducer.poAmendmentId)

    console.log(purchaseOrderDetails.project_address)


    useEffect((e) => {
        setTimeout(() => {
            dispatch(
                getPurchaseOrderDetails({
                    po_id: id
                })
            )
            dispatch(poApproveInfomation({
                po_id: id
            }))
            dispatch(poLastPriceInfomation({
                po_id: id
            }))
            setLoading(false)
            dispatch(setPoCommentstatus(false))
            setCommenting(false)
            dispatch(setFetching(false))
        }, 500)
    }, [poCommentstatus, fetching,afterApproveEditModel])


    let totalAmount = 0;
    let subTotalAmount = 0;
    let totalVatAmount = 0;
    let totalAitAmount = 0;
    // let advancePayment = 0.00;
    //  advancePayment = advancePayment+purchaseOrderDetails.advance_payment_amount;
    // console.log(advancePayment)


    const downloadPdfDocument = async () => {
        const doc = new jsPDF('p','pt','a4')
        // doc.setFontSize(12);
        // doc.setFontStyle('normal');
        // doc.text(new Date().toLocaleString(), 40, 50)
        /*doc.setProperties({
            title: 'Title',
            subject: 'This is the subject',
            author: 'James Hall',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'MEEE'
        });*/
        autoTable(doc, { html: '#pdf-header' })

        autoTable(doc, {
            body: [
                [{ content: new Date().toLocaleString(),theme: 'plain', styles: { halign: 'center',fontSize:8 } }],
                [{ content: 'Nourish Poultry and Hatchery Ltd',theme: 'grid', styles: { halign: 'center',fontStyle:'bold',fontSize: 15} }],
                [{ content: purchaseOrderDetails.project_address.projects_name,theme: 'grid', styles: { halign: 'center',fontStyle:'bold',fontSize: 12} }],
                [{ content: 'TIN-00000  BIN-11111',theme: 'grid', styles: { halign: 'center',fontStyle:'bold',fontSize: 10} }],
            ],
        })
        autoTable(doc, {
            body: [
                [{ content: 'Purchase Order / Work order',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#work-order' })
        autoTable(doc, {
            body: [
                [{ content: 'Approval Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#approve-info' })
        autoTable(doc, {
            body: [
                [{ content: t('PurchaseOrderItemsInformation'),theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#po-item-info' })
        autoTable(doc, { html: '#po-signature' })
        autoTable(doc, {
            body: [
                [{ content: t('TermsConditionsInformation'),theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#terms_conditions' })
        autoTable(doc, { html: '#project-address' })
        /*autoTable(doc, {
            body: [
                [{ content: 'Project Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })

        autoTable(doc, {
            body: [
                [{ content: 'Item Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#ItemInformation',theme: 'grid' })

        autoTable(doc, {
            body: [
                [{ content: 'Attachment Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#AttachmentInformation',theme: 'grid' })
        autoTable(doc, {
            body: [
                [{ content: 'Note Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold',marginBottom:-10 } }],
            ],
        })
        autoTable(doc, { html: '#NoteInformation',columnStyles: {marginLeft:20}})
        autoTable(doc, {
            body: [
                [{ content: 'Approval Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#ApprovalInformation',theme: 'grid' })*/
        // doc.save('store-requisition-'+storeRequisitionDetailsData.sr_number+'.pdf')
        doc.save('po-'+purchaseOrderDetails.po_number+'.pdf')
    }


    return (
        <>
            <main className="w-full">
                {/*<div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('PurchaseOrder')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllPurchaseOrderRelatedInformationCanBeFoundHere')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={() => navigate(-1)}
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
            {/*FOR PDF VIEW START*/}


                                                    <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>


                                                    {
                                                        purchaseOrderDetails &&

                                                        <table className="mt-5 table-full w-full border-collapse text-xs text-center" id={'work-order'}>
                                                            <tbody>

                                                            <tr className={"bg-gray-100"} key='1'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('OrderNumber')} : </span>
                                                                    {purchaseOrderDetails.purchase_order_id}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                            <>
                                                                                <span className="font-bold">{t('Name')} : </span>
                                                                                {purchaseOrderDetails.vendor_info ? JSON.parse(purchaseOrderDetails.vendor_info).name : ''}
                                                                            </>
                                                                    }
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.buyers &&
                                                                            <>
                                                                                <span className="font-bold">{t('Name')} : </span>
                                                                                {purchaseOrderDetails.buyer_full_name}
                                                                            </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='2'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('RefNo')} : </span>
                                                                    {purchaseOrderDetails.ref_no}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('Address')} : </span>
                                                                            {purchaseOrderDetails.vendor_info ? JSON.parse(purchaseOrderDetails.vendor_info).address : ''}
                                                                        </>
                                                                    }
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.buyers &&
                                                                        <>
                                                                            <span className="font-bold">{t('Email')} : </span>
                                                                            {purchaseOrderDetails.buyer_email}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='3'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('ComplianceStatus')} : </span>
                                                                    {purchaseOrderDetails.purchase_type_name}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('ContactPerson')} : </span>
                                                                            {purchaseOrderDetails.vendor_info ? JSON.parse(purchaseOrderDetails.vendor_info).contact_person : ''}
                                                                        </>
                                                                    }
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.buyers &&
                                                                        <>
                                                                            <span className="font-bold">{t('Mobile')} : </span>
                                                                            {purchaseOrderDetails.buyer_cellphone}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='4'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('IssueDate')} : </span>
                                                                    {purchaseOrderDetails.created_date}
                                                                </td>

                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('Mobile')} : </span>
                                                                            {purchaseOrderDetails.vendor_info ? JSON.parse(purchaseOrderDetails.vendor_info).contact_no : ''}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='5'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('PaymentType')} : </span>
                                                                    {purchaseOrderDetails.payment_type}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('VendorQuotationRef')} : </span>
                                                                            {purchaseOrderDetails.vendor_quotation_reference_number}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='6'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('PaymentMethod')} : </span>
                                                                    {purchaseOrderDetails.payment_method}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('TIN')} : </span>
                                                                            {''}
                                                                            {/*{purchaseOrderDetails.vendor_quotation_reference_number}*/}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr className={"bg-gray-100"} key='7'>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    <span className="font-bold">{t('PaymentFrom')} : </span>
                                                                    {purchaseOrderDetails.payment_from == 1 ? 'Local Office' : 'Head Office'}
                                                                </td>
                                                                <td className="w-1/2 border-collapse  text-center">
                                                                    {
                                                                        purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                        <>
                                                                            <span className="font-bold">{t('BIN')} : </span>
                                                                            {''}
                                                                            {/*{purchaseOrderDetails.vendor_quotation_reference_number}*/}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                    }

                                                    {
                                                        (poApproveInfo && poApproveInfo.created_by) &&
                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4" >
                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                    <div className="mt-4">
                                                                        <div className="flex items-center">
                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ApproveInformation')}</h4>
                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                        </div>
                                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="approve-info">
                                                                            <tbody>
                                                                            <tr className={"bg-gray-100"}>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('SL')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('ApproveType')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Name')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Date')}</div>
                                                                                </th>
                                                                            </tr>

                                                                            <tr className={"bg-gray-100"} key='Created'>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">1</td>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Created</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.created_user_name ? poApproveInfo.created_user_name : poApproveInfo.created_email}</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.created_date ? poApproveInfo.created_date : ''}</td>
                                                                            </tr>

                                                                            <tr className={"bg-gray-100"} key='Verified'>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">2</td>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Verified</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.one_user_name ? poApproveInfo.one_user_name : poApproveInfo.one_email}</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_one_date ? poApproveInfo.approved_one_date : ''}</td>
                                                                            </tr>

                                                                            <tr className={"bg-gray-100"} key='Validated'>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">3</td>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Validated</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.two_user_name ? poApproveInfo.two_user_name :  poApproveInfo.two_email}</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_two_date ? poApproveInfo.approved_two_date : ''}</td>
                                                                            </tr>

                                                                            <tr className={"bg-gray-100"} key='Approved'>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">4</td>
                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Approved</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.three_user_name ? poApproveInfo.three_user_name : poApproveInfo.three_email}</td>
                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_three_date ? poApproveInfo.approved_three_date : ''}</td>
                                                                            </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length > 0) &&


                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                    <div className="mt-4">
                                                                        <div className="flex items-center">
                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderItemsInformation')}</h4>
                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                        </div>


                                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="po-item-info">

                                                                            <tbody>
                                                                            <tr>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('SL')}</div>
                                                                                </th>

                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Particulars')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Unit')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Brand')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Quantity')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('UnitPrice')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('SubTotal')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Vat')}</div>
                                                                                </th>

                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('Ait')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('UnitTotal')}</div>
                                                                                </th>
                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                    <div className="font-semibold text-center">{t('TotalAmount')}</div>
                                                                                </th>
                                                                            </tr>

                                                                            {
                                                                                (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length > 0) && purchaseOrderItem.map((orderItem, index) => {
                                                                                    totalAmount = (totalAmount + Number(orderItem.amount))
                                                                                    subTotalAmount = (subTotalAmount + Number(orderItem.sub_total))
                                                                                    totalAitAmount = (totalAitAmount + Number(orderItem.total_ait_amount))
                                                                                    totalVatAmount = (totalVatAmount + Number(orderItem.total_vat_amount))
                                                                                    return (
                                                                                        <tr className={"bg-gray-100"}
                                                                                            key={index}>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                                                                                {++index}
                                                                                            </td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{orderItem.items_name ? orderItem.items_name : ''}</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                                                                                {orderItem.item_unit_name ? orderItem.item_unit_name : ''}
                                                                                            </td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.brand ? orderItem.brand : ''}</td>
                                                                                            <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.quantities ? orderItem.quantities : ''}</td>
                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.unit_price ? Number(orderItem.unit_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.sub_total ? Number(orderItem.sub_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.total_vat_amount ? Number(orderItem.total_vat_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.total_ait_amount ? Number(orderItem.total_ait_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.price ? Number(orderItem.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>


                                                                                            <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.amount ? Number(orderItem.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })}

                                                                            <tr>
                                                                                <td colSpan={11}></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan="6" className="font-bold border-collapse border border-slate-400 text-right pr-5">{t('Total')}</td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400">{subTotalAmount ? subTotalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalVatAmount ? totalVatAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAitAmount ? totalAitAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAmount ? totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="10"
                                                                                    className="font-bold border-collapse border border-slate-400 text-right pr-5">Grand Total PO Amount</td>
                                                                                <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAmount ? totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                                                            </tr>
                                                                            {
                                                                                (purchaseOrderDetails.payment_type=='advance-payment' && purchaseOrderDetails.advance_payment_amount>0) &&
                                                                                <tr>
                                                                                    <td colSpan="10"
                                                                                        className="font-bold border-collapse border border-slate-400 text-right pr-5">{t('AdvancePayment')}</td>
                                                                                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{purchaseOrderDetails.advance_payment_amount ? purchaseOrderDetails.advance_payment_amount:''}</td>
                                                                                </tr>

                                                                            }


                                                                            </tbody>
                                                                        </table>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                        {
                                                            (purchaseOrderDetails && purchaseOrderTermConditions) &&
                                                            <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                    <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                        <div className="mt-4">
                                                                            <div className="flex items-center">
                                                                                <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('TermsConditionsInformation')}</h4>
                                                                                <div className="flex-1 border-t-2 border-gray-200"/>
                                                                            </div>
                                                                            <table className="table-full w-full mt-5" id="terms_conditions">
                                                                                <tbody>
                                                                                {
                                                                                    (purchaseOrderDetails && purchaseOrderTermConditions && purchaseOrderTermConditions.length > 0) && purchaseOrderTermConditions.map((term, index) => {
                                                                                        return (
                                                                                            <tr className={"border"}
                                                                                                key={index}>
                                                                                                <td className="w-1/12 bg-green-50 px-6 py-1">
                                                                                                    {term.conditions}
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            purchaseOrderDetails.po_signature && purchaseOrderDetails['po_signature'].length>0 &&
                                                            <table className="mt-5 table-full w-full border-collapse text-xs text-center" id={'po-signature'}>
                                                                <tbody>

                                                                <tr>
                                                                    <td colSpan={4}></td>
                                                                </tr>

                                                                <tr className={"bg-gray-100"} key='po-1'>
                                                                    <td className="w-1/4 border-collapse  text-center">
                                                                        <span className="font-bold">{poApproveInfo.created_user_name ? poApproveInfo.created_user_name : poApproveInfo.created_email}  </span><br/>
                                                                        <span className="font-bold">Creator , Nourish Poultry &
Hatchery Ltd. Khaled Group</span>
                                                                    </td>

                                                                    {
                                                                        (purchaseOrderDetails && purchaseOrderDetails['po_signature'] && purchaseOrderDetails['po_signature'].length > 0) &&
                                                                        purchaseOrderDetails['po_signature'].map((sign, index) => {
                                                                            return (
                                                                                <td className="w-1/4 border-collapse  text-center">
                                                                                    <span className="font-bold">{sign.name}  </span><br/>
                                                                                    <span className="font-bold">{sign.address }  </span><br/>
                                                                                    {/*<span className="font-bold">{poApproveInfo.created_date ? poApproveInfo.created_date : ''} </span><br/>*/}
                                                                                    {/*<span className="font-bold">Nourish Poultry &*/}
{/*Hatchery Ltd. Khaled Group</span>*/}
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        }

                                                        {
                                                            purchaseOrderDetails.project_address &&
                                                            <table className="mt-5 table-full w-full border-collapse text-xs text-center" id={'project-address'}>
                                                                <tbody>

                                                                <tr>
                                                                    <td colSpan={4}></td>
                                                                </tr>

                                                                <tr className={"bg-gray-100"} key='po-1'>
                                                                    <td className="w-1/2 border-collapse  text-center">
                                                                        <span className="font-bold">Project Address</span><br/>
                                                                        <hr/>
                                                                        <span className="font-bold">{purchaseOrderDetails.project_address.address}  </span><br/>
                                                                        <span className="font-bold">{purchaseOrderDetails.project_address.username}</span><br/>
                                                                        <span className="font-bold">{purchaseOrderDetails.project_address.mobile}</span>
                                                                    </td>
                                                                    <td className="w-1/2 border-collapse  text-center">
                                                                        <span className="font-bold">Currier Address</span><br/>
                                                                        <hr/>
                                                                        <span className="font-bold">Demo--{purchaseOrderDetails.project_address.address}  </span><br/>
                                                                        <span className="font-bold">Demo--{purchaseOrderDetails.project_address.username}</span><br/>
                                                                        <span className="font-bold">Demo--{purchaseOrderDetails.project_address.mobile}</span>
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        }




                                                    {/*FOR PDF VIEW END*/}

                                                    <footer className="relative mt-1 border-t">
                                                        <div className="mr-6">
                                                            <div className="text-right pt-0.5 mb-0.5 ">
                                                                <button className="inline-flex items-center px-6 py-2 bg-blue-700 hover:bg-blue-900 text-white text-xs ml-2" onClick={downloadPdfDocument}>Generate PDF</button>

                                                            </div>
                                                        </div>
                                                    </footer>
                                                    </ScrollArea>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


        </>
    )
}

export default PrintPdfView