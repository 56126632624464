import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {masterDataDropdownService} from "../../services/MasterDataService";


export const masterDataDropdown = createAsyncThunk("master-data/dropdown", async (type) => {
    try {
        const response = masterDataDropdownService(type);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const masterDataSlice = createSlice({
    name : "master-data",
    initialState : {
        isLoading : true,
        message : null,
        paymentTypeDropdown : [],
        paymentFromDropdown : [],
        paymentMethodDropdown : [],
    },
    extraReducers : (builder) => {
        builder.addCase(masterDataDropdown.fulfilled, (state, action) => {
            if (action.payload.type === 'payment-type'){
                state.paymentTypeDropdown = action.payload.data
                state.message = 'masterDataDropdownLoad'
            }
            if (action.payload.type === 'payment-from'){
                state.paymentFromDropdown = action.payload.data
                state.message = 'masterDataDropdownLoad'
            }
            if (action.payload.type === 'payment-method'){
                state.paymentMethodDropdown = action.payload.data
                state.message = 'masterDataDropdownLoad'
            }
            state.isLoading = false
        })
    }
})

export default masterDataSlice.reducer;