import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiTrash, HiArrowCircleRight, HiArrowCircleLeft
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {
    ActionIcon,
    ScrollArea,
    Select,
    Text,
    TextInput,
    Switch,
    Grid,
    Button,
    Group,
    useMantineTheme
} from "@mantine/core";
import {openConfirmModal} from "@mantine/modals";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import dayjs from "dayjs";
import {IconPhoto, IconPlus, IconUpload, IconX} from "@tabler/icons-react";
import VariantModel from "../PreRequisition/VariantModel";
import {useDisclosure} from "@mantine/hooks";
import {getCategorySubCategory, setItemCategorySubCat} from "../../../store/items/itemsSlice";
import {useDispatch, useSelector} from "react-redux";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {getSubCategoryDropdown} from "../../../store/subCategory/subCategorySlice";
import {getCategoryDropdown} from "../../../store/category/CategorySlice";


function EditAfterClaim() {
    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [opened, {open, close}] = useDisclosure(false);

    const fullFormHeight = localStorage.getItem('fullFormHeight');


    const [showVechileNumberInput,setShowVechileNumberInput] = useState(false)
    const [pdfFile,setPdfFile] = useState(null)
    const [pdfPath,setPdfPath] = useState(null)
    const [isSubmit, setSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const token = localStorage.getItem('user_token');
    const [projectData, setProjectData] = useState(null);
    const [priorityData, setPriorityData] = useState(null);
    const [budgetData, setBudgetData] = useState(null);
    const [isProjectValue, setIsProjectValue] = useState(false);
    const [prNumber, setPrNumber] = useState(null);
    const [requisitionItems, setRequisitionItems] = useState(null);
    const [budgetHeadDropdown, setBudgetHeadDropdown] = useState([]);
    const [budgeHeadData, setBudgetHeadData] = useState(null);
    const [refreshItem, setRefreshItem] = useState(false);
    const [masterDataTypeDropdown, setMasterDataTypeDropdown] = useState([]);
    const [masterDataStatus, setMasterDataStatus] = useState(true);
    const [budgetStatus, setBudgetStatus] = useState(true);
    const [variantModel, setVariantModel] = useState(false)
    const [masterDataTypeSlug, setMasterDataTypeSlug] = useState('')
    const [isSlug, setIsSlug] = useState(false)
    const [masterDataType, setMasterDataType] = useState(null);
    const [masterDataDropdown, setMasterDataDropdown] = useState([]);
    const [masterData, setMasterData] = useState(null);
    const [projectTypeData, setProjectTypeData] = useState(null);
    const [requisitionDetails, setRequisitionDetails] = useState(null);
    const [totalPOiIndex, setTotalPoiIndex] = useState(0);


    /*COMMON CATEGORY & CATEGORY WISE SUBCATEGORY*/
    const categoryDropdown = useSelector((state) => state.categoryReducer.categoryDropdownData)
    const subCategoryDropdown = useSelector((state) => state.subCategoryReducer.subCategoryDropdownData)

    useEffect(() => {
        dispatch(getCategoryDropdown())
    },[])

    const [categoryData, setCategoryData] = useState([]);
    const categoryDropdownHandel = (e) => {
        setCategoryData(e)
        dispatch(getSubCategoryDropdown(e))
    }
    /*COMMON CATEGORY & CATEGORY WISE SUBCATEGORY*/

    const HandelBugdetStatus = (e) => {
        setBudgetStatus(e.currentTarget.checked)
    }

    const [itemNatureData, setIItemNatureData] = useState(null);
    const ItemNatureDataHandel = (e) => {
        setIItemNatureData(e);
    }

    // start get spend area dropdown
    const [spendArea, setSpendArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/spend-area/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setSpendArea(res.data.data);
            });
    }, []);

    let spendAreaDropdown =
        spendArea.map((type, index) => {
            return ({
                'label': type.titles, 'value': type.id
            })
        })

    // GET PURCHASE REQUISITION DETAILS
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/details/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            // console.log(res.data.data)
            setRequisitionDetails(res.data.data)
            setTotalPoiIndex(res.data.data.totalPoiIndex)

            if (res.data.status === 200) {
                if (res.data.data.projectId) {
                    axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/dropdown?project_id=${res.data.data.projectId}`,
                        {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                        let data =
                            res.data.data.map((head, index) => {
                                if (head.net_total > 0) {
                                    return ({
                                        'label': head.budget_generate_id + ' (' + head.net_total + 'tk. (' + head.budget_start_date + ')(' + head.budget_end_date + '))',
                                        'value': Number(head.id)
                                    })
                                } else {
                                    return ({
                                        'label': head.budget_generate_id + ' (' + head.name_of_work + ')',
                                        'value': Number(head.id)
                                    })
                                }
                            })
                        setBudgetDropdown(data);
                    });
                }
                setProjectData(res.data.data.projectId);
                setPriorityData(res.data.data.priority);
                setRefData(res.data.data.ref_no);
                setIsProjectValue(true);
                setPrNumber(res.data.data.pr_number);
                setRequisitionItems(res.data.data.purchaseRequisitionItems);
                setProjectTypeData(res.data.data.project_type?res.data.data.project_type:'');
                setPdfPath(res.data.data.path)



                if (res.data.data.budget) {
                    setBudgetStatus(true)
                    setBudgetData(res.data.data.budget)
                    // GetBudgetHeadData(res.data.data.budget);
                }

                if (res.data.data.variant && res.data.data.variant != null){
                    setMasterDataType('1#vehicle')
                    GetMasterDataDropdownByTypeSlug('vehicle')
                    setValue('master_data_type_id','1#vehicle')
                    setShowVechileNumberInput(true)
                }else {
                    if (res.data.data.purchaseRequisitionItems.length>0){
                        setValue('master_data_type_id','6#not-applicable')
                        setMasterDataType('6#not-applicable')
                    }
                }

                setValue('projects', res.data.data.projectId)
                setValue('project_type', res.data.data.project_type?res.data.data.project_type:'')
                setValue('ref_no', res.data.data.ref_no)
                setValue('vehicle', res.data.data.vehicle)
                setValue('budget_id',res.data.data.budget)
                // setMasterDataType(res.data.data.variant_type + '#' + res.data.data.variant_type_slug)
                // setValue('master_data_type_id', res.data.data.variant_type)
                setMasterData(res.data.data.variant)
                setValue('master_data', res.data.data.variant)

                setMasterDataTypeSlug(res.data.data.variant_type_slug)
                setIsSlug(true)
                setVariantModel(false)
                setRefreshItem(false)
            }
        });
    }, [refreshItem]);
    // GET PURCHASE REQUISITION DETAILS


    // GET MASTER DATA TYPE DROPDOWN START
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/types`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    setMasterDataTypeDropdown(
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': Number(type.id) + '#' + type.slug
                            })
                        })
                    )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [])
    // GET MASTER DATA TYPE DROPDOWN END


    // GET PROJECT DETAILS
    useEffect(() => {
        if (projectData) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/${projectData}`,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    setProjectDetails({
                        project_address: res.data.data[0].address,
                        project_areas_name: res.data.data[0].areas_name,
                        projects_category_name: res.data.data[0].projects_category_name,
                        project_cost_center_number: res.data.data[0].cost_center_number,
                        project_head: res.data.data[0].email,
                    })
                });
        }
    }, [isProjectValue]);
    // GET PROJECT DETAILS


    const updateFormData = new FormData();
    updateFormData.append('status', 1);
    updateFormData.append('cost', 0);


    // start get project dropdown
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'label': type.projects_name, 'value': Number(type.id)
            })
        })

    // on change project data get
    const [projectDetails, setProjectDetails] = useState({
        project_address: '',
        project_areas_name: '',
        projects_category_name: '',
        project_cost_center_number: '',
        project_head: '',
    })
    const [budgetDropdown, setBudgetDropdown] = useState([]);

    const ProjectDataHandel = (e) => {
        setBudgetDropdown([])
        setBudgetData(null)
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjectDetails({
                    project_address: res.data.data[0].address,
                    project_areas_name: res.data.data[0].areas_name,
                    projects_category_name: res.data.data[0].projects_category_name,
                    project_cost_center_number: res.data.data[0].cost_center_number,
                    project_head: res.data.data[0].email,
                })
            });

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/dropdown?project_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            let data =
                res.data.data.map((head, index) => {
                    if (head.net_total > 0) {
                        return ({
                            'label': head.budget_generate_id + ' (' + head.net_total + 'tk. (' + head.budget_start_date + ')(' + head.budget_end_date + '))',
                            'value': Number(head.id)
                        })
                    } else {
                        return ({
                            'label': head.budget_generate_id + ' (' + head.name_of_work + ')', 'value': Number(head.id)
                        })
                    }
                })
            setBudgetDropdown(data);
        });

        setProjectData(e);
        setIsProjectValue(true);
        setSubmit(true);
        setValue('projects', e)
        setError('projects', null)
    }
    // End get project dropdown

    const updateItemNatureData = new FormData();

    const UpdateItemNatureHandel = (e, itemId) => {
        updateItemNatureData.append('item_nature', e);

        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/edit/item-nature/${itemId}`,
            updateItemNatureData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 202) {
                        setRefreshItem(true);
                    }
                }
            );
    }

    const updateLocalHeadData = new FormData();

    const UpdateLocalHeadHandel = (e, itemId) => {
        updateLocalHeadData.append('local_head', e);

        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/edit/local-head/${itemId}`,
            updateLocalHeadData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 202) {
                        setRefreshItem(true);
                    }
                }
            );
    }


    // start priority dropdown
    let priorityDropdown =
        [
            {'label': 'High', 'value': 'high'},
            {'label': 'Medium', 'value': 'medium'},
            {'label': 'Low', 'value': 'low'}
        ];

    const PriorityDataHandel = (e) => {
        setPriorityData(e);
        setSubmit(true);
    }

    const ProjectTypeDataHandel = (e) => {
        setProjectTypeData(e);
        setValue('project_type',e)
        setError('project_type',null)
        setSubmit(true);
    }

    const MasterDataTypeDataHandel = (e) => {
        setMasterDataType(e);
        if (e=='6#not-applicable'){
            setShowVechileNumberInput(false)
            setMasterData('')
            setValue('master_data', '')
            // setMasterDataType('6#not-applicable');
            setValue('master_data_type_id', 6)
            setMasterDataDropdown([])
            setSubmit(true);
        }else {
            setShowVechileNumberInput(true)
            setMasterData('')
            setValue('master_data', '')
            let masterDataTypeId = e.split('#')[0];
            setValue('master_data_type_id', masterDataTypeId)
            setMasterDataTypeSlug(e.split('#')[1]);
            GetMasterDataDropdownByTypeSlug('vehicle')
            setIsSlug(true)
        }
        setError('master_data_type_id',null)
    }
    const GetMasterDataDropdownByTypeSlug = (typeSlug) => {
        if (typeSlug && typeSlug != null) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {
                    slug: typeSlug
                }
            })
                .then(res => {
                    if (res.data.status === 200) {
                        setMasterDataDropdown(
                            res.data.data.map((type, index) => {
                                return ({
                                    'label': type.name, 'value': Number(type.id)
                                })
                            })
                        )
                        setIsSlug(false)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }


    if (isSlug) {
        GetMasterDataDropdownByTypeSlug(masterDataTypeSlug)
    }

    const MasterDataHandel = (e) => {
        setMasterData(e);
        setValue('master_data', e)
        setSubmit(true);
    }

    const BudgetDataHandel = (e) => {
        setBudgetData(e);
        setValue('budget_id', e)
        setError('budget_id', null)
        setSubmit(true);
        // GetBudgetHeadData(e);
    }
    // get budget Head data start

    // on change spend area data get
    const HandelBudgeHeadData = (e) => {
        setBudgetHeadData(e);
    }
    // End get spend area dropdown
    // get budget Head data end


    // Reference Data Handel
    const [refData, setRefData] = useState('');
    const ReferenceDataHandel = (e) => {
        setRefData(e.target.value);
        setValue('ref_no', e.target.value)
        setError('ref_no', null)
        setSubmit(true);
    }

    // GET LOCAL & HEAD DROPDOWN
    const [localHead, setLocalHead] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/particulars-type/local-head/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setLocalHead(res.data.data);
            });
    }, []);

    let localHeadDropdown =
        localHead.map((type, index) => {
            return ({
                'label': type.name, 'value': type.slug
            })
        })
    //LOCAL & HEAD data handel

    const [localHeadData, setILocalHeadData] = useState(null);
    const LocalHeadDataHandel = (e) => {
        setILocalHeadData(e);
    }
    //LOCAL & HEAD DROPDOWN


    // on change budget data get
    if (isSubmit) {
        updateFormData.append('projects', projectData);
        updateFormData.append('priority', priorityData);
        updateFormData.append('ref_no', refData);
        updateFormData.append('variant', masterData && masterData != "null" ? masterData : '');
        updateFormData.append('budget', budgetData && budgetData != "null" ? budgetData : '');
        updateFormData.append('project_type', projectTypeData);
        updateFormData.append('pdf', pdfFile);


        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/edit/${id}`,
            updateFormData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (pdfFile){
                    setRefreshItem(true)
                }
                }
            );

        setSubmit(false)
    }

    const itemFormData = new FormData();
    itemFormData.append('status', 1);


    //CONTROL CATEGORY & GET SUB CATEGORY
    const [subCategoryData, setSubCategoryData] = useState([]);

    //CONTROL SUB CATEGORY
    const SubCategoryDataHandel = (e, itemID) => {
        setSubCategoryData(e);
    }
    // End get category dropdown
    //CONTROL CATEGORY & GET SUB CATEGORY


    // start get contact person dropdown
    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name+' ('+head.item_unit+')'
                        })
                    })
                setItems(data);
            });
        }
    }

    // start get items dropdown

    // on change item dropdown handel
    const [itemData, setItemData] = useState(null);
    const [itemWiseType, setItemWiseType] = useState(null);
    const [itemWiseUnit, setItemWiseUnit] = useState(null);
    const itemDropdownHandel = (e) => {
        setItemData(e)
        dispatch(getCategorySubCategory({
            item_id: e
        }))
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setItemWiseType(res.data.data[0].item_types);
                setItemWiseUnit(res.data.data[0].item_unit);
            });
    }
    // get items dropdown

    //Quantity handel
    const [quantitiesData, setQuantitiesData] = useState('');
    const QuantityHandel = (e) => {
        setQuantitiesData(e.target.value)
    }
    //Quantity handel

    //Remark handel
    const [remarkData, setRemarkData] = useState('');
    const RemarkHandel = (e) => {
        setRemarkData(e.target.value)
    }
    //Remark handel


    // REQUIRED DATE HANDEL
    const [dateOfRequired, setDateOfRequired] = useState('');
    const RequiredDateHandel = (e) => {
        setDateOfRequired(e);
    }
    // REQUIRED DATE HANDEL


    //REQUISITION ITEM SUBMIT
    const PreRequisitionItemsInsert = (e) => {
        let validation = true;
        let validationMessage = '';

        if (quantitiesData == '' || quantitiesData == null) {
            validation = false;
            validationMessage = t('QuantityFillUp')
        }
        if (dateOfRequired == '' || dateOfRequired == null) {
            validation = false;
            validationMessage = t('ChooseDate')
        }
        if (itemNatureData == '' || itemNatureData == null) {
            validation = false;
            validationMessage = t('ChooseItemNature')
        }
        if (localHeadData == '' || localHeadData == null) {
            validation = false;
            validationMessage = t('ChoosePurchaseFrom')
        }
        if (budgeHeadData == '' || budgeHeadData == null) {
            validation = false;
            validationMessage = t('ChooseBudgetHead')
        }

        if (subCategoryData == '' || subCategoryData == null) {
            validation = false;
            validationMessage = t('SubCategoryFillUp')
        }

        if (itemData == '' || itemData == null) {
            validation = false;
            validationMessage = t('ItemFillUp')
        }

        if (validation) {
            const itemFormData = {
                purchase_requisitions: id,
                items: itemData,
                quantities: quantitiesData,
                status: 1,
                // categories: Number(itemCategorySubCat.categories_id),
                categories: categoryData,
                sub_categories: subCategoryData,
                date_of_required: format(dateOfRequired, 'yyyy-MM-dd'),
                comments: remarkData,
                spend_area: null,
                budget_head: budgeHeadData,
                is_head_or_local: localHeadData,
                project_id: projectData,
                item_nature: itemNatureData
            };

            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/create`,
                itemFormData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('ItemAddedSuccesfully')),
                            message: (t('DataLoad')),
                            autoClose: 800,
                            disallowClose: true,
                            color: 'green',
                        });
                        setTimeout(function () {
                                dispatch(setItemCategorySubCat())
                                setRefreshItem(true);
                                setCategoryData(null);
                                setSubCategoryData(null);
                                setItemData(null);
                                setQuantitiesData(null);
                                setItemWiseUnit(null);
                                setItemWiseType(null);
                                setDateOfRequired(null);
                                setRemarkData(null);
                                setBudgetHeadData(null);
                                setILocalHeadData(null);
                                setIItemNatureData(null)
                            }
                            , 500)

                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 3000,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
            setRefreshItem(false);
        } else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('ValidationError')),
                message: validationMessage,
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }
    }


    // DELETE REQUISITION ITEM
    const PreRequisitionItemDelete = (e, itemID) => {
        e.preventDefault();
        const thisClick = e.currentTarget;
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteRequisitionItem')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/delete/${itemID}`,
                        {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 800,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setTimeout(function () {
                                        setRefreshItem(true)
                                    }
                                    , 500)
                            }
                        })
                )
            ,
        });
        setRefreshItem(false);
    }


    const [updateQuantity, setUpdateQuantity] = useState('');
    const updateQuantityData = new FormData();

    const UpdateQuantityHandel = (e, itemId) => {
        e.persist();
        setUpdateQuantity({...updateQuantity, [e.target.id]: e.target.value});
        updateQuantityData.append('quantities', e.target.value);

        if (updateQuantityData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/edit/quantity/${itemId}`,
                updateQuantityData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 202) {
                            setRefreshItem(true);
                        }
                    }
                );
        }
    }

    const [updateSubCategory, setUpdateSubCategory] = useState(null);
    const updateSubCategoryData = new FormData();

    const UpdateSubCategoryHandel = (e, itemId) => {
        setUpdateSubCategory({...updateSubCategory, [itemId]: e});
        updateSubCategoryData.append('sub_categories', e);

        if (updateQuantityData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/edit/sub-category/${itemId}`,
                updateSubCategoryData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 202) {
                            setRefreshItem(true);
                        }
                    }
                );
        }
    }


    const validationSchema = Yup.object().shape({
            projects: Yup.string().required(t("EnterProjectName")),
            budget_id: Yup.string().required('Choose budget'),
            ref_no: Yup.string().required(t('RefNoIsRequired')),
            project_type: Yup.string().required('Choose project type'),
            master_data_type_id: Yup.string().required('Choose Vehicle Type'),
            master_data: Yup.string().when("master_data_type_id", (value) => {
                if (1==value) {
                    return Yup.string().required('Choose vehicle number');
                }
            }),

            budgetStatus: Yup.boolean(),

            /*budget_id: Yup
                .string()
                .when("budget_status", {
                    is: true,
                    then: Yup.string().required(t("ChooseBudget"))
                })*/
        },
        [
            ["master_data", "master_data"],
        ]);

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, setError, formState, setValue} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    const updateRequisitionData = new FormData();

    function formSubmit(data) {
        setSpinner(true);

        if (updateRequisitionData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/final-update/after-approve/${id}`,
                updateRequisitionData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 202) {
                            setSpinner(false);
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('RequisitionCreate')),
                                message: (t('DataLoad')),
                                autoClose: 800,
                                disallowClose: true,
                                color: 'green',
                            });
                            navigate('/requisition/');
                        }
                    }
                );
        }

    }


    return (
        <>
            <main className="flex flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('CreatePurchaseRequisitions')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('CreatePurchaseRequisitions')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">
                                                            <Link to='/requisition'
                                                                  className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar size={16}
                                                                                         className={'mr-1'}></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/pre-requisition/'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden w-full'}>


                                                {isAddForm ?
                                                    <div className="w-full mr-0 mx-auto border">

                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                            <ScrollArea style={{height: fullFormHeight}}>
                                                                <div className="mx-auto p-2 justify-items-center">
                                                                    <section className="">
                                                                        <Grid>
                                                                            <Grid.Col md={6} lg={6}>
                                                                                <div className="e">
                                                                                    <div
                                                                                        className={'pt-3 sm:border-gray-200'}>

                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="project"
                                                                                                   className="form-input-sm-label-required">{t('ProjectName')}</label>

                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                {errors.projects?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.projects?.message}
                                                                                                </div>}

                                                                                                <Select
                                                                                                    {...register("projects")}
                                                                                                    placeholder={t("ChooseProjectName")}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={projectDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={ProjectDataHandel}
                                                                                                    value={projectData}
                                                                                                    disabled={totalPOiIndex>0?true:false}
                                                                                                />
                                                                                            </div>
                                                                                        </div>


                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="priority"
                                                                                                   className="form-input-sm-label">{t('Priority')}
                                                                                            </label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                <Select
                                                                                                    {...register("priority")}
                                                                                                    placeholder={t("SelectPriority")}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={priorityDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={PriorityDataHandel}
                                                                                                    value={priorityData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="flex">
                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label htmlFor="ref_no"
                                                                                                       className="form-input-sm-label-required">{t('RefNo')}</label>
                                                                                                <div
                                                                                                    className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.ref_no?.type === 'required' &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}></HiOutlineExclamation> {errors.ref_no?.message}
                                                                                                    </div>}

                                                                                                    <TextInput
                                                                                                        {...register("ref_no")}
                                                                                                        placeholder={t("EnterRefNo")}
                                                                                                        onChange={ReferenceDataHandel}
                                                                                                        value={refData ? refData : ''}
                                                                                                        withAsterisk
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

<div className="mb-3 w-1/2 items-center">
    <label htmlFor="project_type" className="form-input-sm-label-required">{t('ProjectType')}</label>
    <div
        className="relative mt-1 sm:col-span-2 sm:mt-0">
        {errors.project_type?.type === 'required' &&
        <div
            className="form-input-sm-error">
            <HiOutlineExclamation
                size={16}
                className={'mr-2'}></HiOutlineExclamation> {errors.project_type?.message}
        </div>}

        <Select
            {...register("project_type")}
            placeholder={t("ChooseProjectType")}
            searchable
            allowDeselect
            nothingFound="No options"
            withAsterisk
            data={['Operation period','Cleaning period','Construction period']}
            transition="pop-top-left"
            transitionDuration={80}
            transitionTimingFunction="ease"
            onChange={ProjectTypeDataHandel}
            value={projectTypeData}
        />
    </div>
</div>
                                                                                        </div>

                                                                                        <div className="flex">
                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label
                                                                                                    htmlFor="master_data_type_id"
                                                                                                    className="form-input-sm-label-required"> Vehicle Type
                                                                                                </label>

                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.master_data_type_id?.type === 'required' &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}></HiOutlineExclamation> {errors.master_data_type_id?.message}
                                                                                                    </div>}
                                                                                                    <Select
                                                                                                        {...register("master_data_type_id")}
                                                                                                        placeholder="Choose Vehicle Type"
                                                                                                        searchable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        withAsterisk
                                                                                                        data={
                                                                                                            [
                                                                                                                {
                                                                                                                    "label": "Vehicle",
                                                                                                                    "value": "1#vehicle"
                                                                                                                },
                                                                                                                {
                                                                                                                    "label": "Not Applicable",
                                                                                                                    "value": "6#not-applicable"
                                                                                                                }
                                                                                                            ]
                                                                                                        }
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={MasterDataTypeDataHandel}
                                                                                                        value={masterDataType?masterDataType:''}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            {
                                                                                                showVechileNumberInput &&

                                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                                    <label
                                                                                                        htmlFor="master_data"
                                                                                                        className="form-input-sm-label-required">
                                                                                                        Vehicle Number
                                                                                                    </label>

                                                                                                    <div
                                                                                                        className="relative sm:col-span-2">
                                                                                                        {errors.master_data &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error custom-error-message">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}></HiOutlineExclamation> {errors.master_data?.message}
                                                                                                        </div>}
                                                                                                        <Button.Group>
                                                                                                            <Select
                                                                                                                {...register("master_data")}
                                                                                                                placeholder="Choose Vehicle Number"
                                                                                                                searchable
                                                                                                                clearable
                                                                                                                allowDeselect
                                                                                                                nothingFound="No options"
                                                                                                                withAsterisk
                                                                                                                data={masterDataDropdown}
                                                                                                                transition="pop-top-left"
                                                                                                                transitionDuration={80}
                                                                                                                transitionTimingFunction="ease"
                                                                                                                onChange={MasterDataHandel}
                                                                                                                value={masterData}
                                                                                                            />
                                                                                                            <Button
                                                                                                                onClick={open}
                                                                                                                variant="outline"
                                                                                                                color="gray">
                                                                                                                <IconPlus
                                                                                                                    size="1rem"/></Button>
                                                                                                        </Button.Group>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="flex">

                                                                                            <div
                                                                                                className="mb-3 w-full  items-center">
                                                                                                <label
                                                                                                    htmlFor="budget_id"
                                                                                                    className="form-input-sm-label-required">{t('ChooseBudget')}
                                                                                                </label>

                                                                                                <div
                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.budget_id?.type === 'required' &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}></HiOutlineExclamation> {errors.budget_id?.message}
                                                                                                    </div>}
                                                                                                    <Select
                                                                                                        {...register("budget_id")}
                                                                                                        placeholder={t("ChooseBudget")}
                                                                                                        searchable
                                                                                                        clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        withAsterisk
                                                                                                        data={budgetDropdown}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        // onSearchChange={BudgetSearchHandel}
                                                                                                        onChange={e => {
                                                                                                            BudgetDataHandel(e)
                                                                                                        }}
                                                                                                        value={budgetData}
                                                                                                        // searchValue={budgetSearchValue}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/*:''
                                                                                            }*/}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid.Col>

                                                                            <Grid.Col md={6} lg={6}>
                                                                                <div className="mt-10">
                                                                                    <Grid columns={12}>
                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('PRNumber')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{prNumber}</Grid.Col>
                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('Area')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{projectDetails.project_areas_name}</Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('CompanyType')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{projectDetails.projects_category_name}</Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('CCN')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{projectDetails.project_cost_center_number}</Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('ProjectHead')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{projectDetails.project_head}</Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('Address')}</Grid.Col>
                                                                                        <Grid.Col
                                                                                            span={9}>{projectDetails.project_address}</Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('Created')}</Grid.Col>
                                                                                        <Grid.Col span={9}>
                                                                                            {localStorage.getItem('username') ? localStorage.getItem('username') : ''}
                                                                                        </Grid.Col>

                                                                                        <Grid.Col ta="right"
                                                                                                  span={3}>{t('IssueDate')}</Grid.Col>
                                                                                        <Grid.Col span={9}>
                                                                                            {new Date().toLocaleString() + "" ? new Date().toLocaleString() + "" : ''}
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </section>
                                                                </div>
                                                                {/* Table At End Section */}


                                                                <div className="mx-auto p-2 justify-items-center">
                                                                    <div className="mb-3 w-full items-center">
                                                                        <label htmlFor="description" className="form-input-sm-label">{t('AttachmentInformation')}</label>
                                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                            <tbody>
                                                                            <tr className={"bg-gray-100"} key={'1'}>
                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                                                                    <Dropzone
                                                                                        onDrop={(file) => {
                                                                                            setRefreshItem(false)
                                                                                            setPdfFile(file[0]);
                                                                                            setSubmit(true);
                                                                                        }}
                                                                                        onReject={(files) =>{
                                                                                            showNotification({
                                                                                                id: 'load-data',
                                                                                                loading: true,
                                                                                                title: 'Please select PDF file.',
                                                                                                message: (t('DataLoad')),
                                                                                                autoClose: 1000,
                                                                                                disallowClose: true,
                                                                                                color: 'red',
                                                                                            });
                                                                                        }}
                                                                                        accept={[MIME_TYPES.pdf]}
                                                                                        multiple={false}                                >
                                                                                        <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                                                                                            <Dropzone.Accept>
                                                                                                <IconUpload
                                                                                                    size="2rem"
                                                                                                    stroke={1.5}
                                                                                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                />
                                                                                            </Dropzone.Accept>
                                                                                            <Dropzone.Reject>
                                                                                                <IconX
                                                                                                    size="2rem"
                                                                                                    stroke={1.5}
                                                                                                />
                                                                                            </Dropzone.Reject>
                                                                                            <Dropzone.Idle>
                                                                                                <IconPhoto size="2rem" stroke={1.5} />
                                                                                            </Dropzone.Idle>

                                                                                            <div>
                                                                                                <Text size="xl" inline>
                                                                                                    {
                                                                                                        pdfPath ? pdfPath.split("/").pop() :'Drag PDF here or click to select files'
                                                                                                    }
                                                                                                </Text>
                                                                                            </div>
                                                                                        </Group>
                                                                                    </Dropzone>
                                                                                    {pdfPath &&
                                                                                        <a title="Purchase Requisition"
                                                                                           target="_blank"
                                                                                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-requisition/pdf-show/'+ id}`}
                                                                                           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                                            Open Attachment
                                                                                        </a>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>



                                                                <div
                                                                    className=" mx-auto p-2 justify-items-center bg-indigo-100">
                                                                    <section className="p-1">
                                                                        <header className="relative mb-1 bg-indigo-400">
                                                                            <div
                                                                                className="text-white text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-md text-left font-bold items-center leading-7">  {t('PurchaseRequisitionItems')}</div>
                                                                            </div>
                                                                        </header>
                                                                        {
                                                                            // (requisitionDetails && requisitionDetails.pre_requisition == null) &&
                                                                            (requisitionDetails ) &&
                                                                            <>
                                                                                <Grid columns={24}>
                                                                                    <Grid.Col span={12}>
                                                                                        <Select
                                                                                            {...register("item")}
                                                                                            placeholder={t("SelectItem")}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={items}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onSearchChange={ItemSearchHandel}
                                                                                            onChange={e => {
                                                                                                itemDropdownHandel(e)
                                                                                            }}
                                                                                            value={itemData}
                                                                                            searchValue={itemSearchValue}
                                                                                        />
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={6}>
                                                                                        <Select
                                                                                            {...register("category")}
                                                                                            placeholder={t("SelectCategory")}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={categoryDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={e => {
                                                                                                categoryDropdownHandel(e)
                                                                                            }}
                                                                                            value={Number(categoryData)}
                                                                                        />
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={6}>
                                                                                        <Select
                                                                                            {...register("subCategory")}
                                                                                            placeholder={t("SelectSubCategory")}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={subCategoryDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={e => {
                                                                                                SubCategoryDataHandel(e)
                                                                                            }}
                                                                                            value={subCategoryData}
                                                                                        />
                                                                                    </Grid.Col>
                                                                                </Grid>
                                                                                <Grid columns={24}>

                                                                                    <Grid.Col span={12}>
                                                                                        <Select
                                                                                            {...register("budget_head")}
                                                                                            placeholder={t("ChooseSpendArea")}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={spendAreaDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={HandelBudgeHeadData}
                                                                                            value={budgeHeadData}
                                                                                        />
                                                                                    </Grid.Col>

                                                                                    <Grid.Col span={12}>
                                                                                        <TextInput
                                                                                            {...register("remark")}
                                                                                            placeholder={t("Remark")}
                                                                                            onChange={e => {
                                                                                                RemarkHandel(e)
                                                                                            }}
                                                                                            value={remarkData ? remarkData : ''}
                                                                                            withAsterisk
                                                                                        />
                                                                                    </Grid.Col>

                                                                                </Grid>
                                                                                <Grid columns={24}>

                                                                                    <Grid.Col span={8}>
                                                                                        <Select
                                                                                            {...register("local-head")}
                                                                                            placeholder="Purchase From"
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={localHeadDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={e => {
                                                                                                LocalHeadDataHandel(e)
                                                                                            }}
                                                                                            value={localHeadData}
                                                                                        />
                                                                                    </Grid.Col>

                                                                                    <Grid.Col span={4}>
                                                                                        <Select
                                                                                            {...register("item-nature")}
                                                                                            placeholder={t('ChooseItemNature')}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={
                                                                                                [
                                                                                                    {
                                                                                                        'label': 'Capex',
                                                                                                        'value': 'Capex'
                                                                                                    },
                                                                                                    {
                                                                                                        'label': 'Opex',
                                                                                                        'value': 'Opex'
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={e => {
                                                                                                ItemNatureDataHandel(e)
                                                                                            }}
                                                                                            value={itemNatureData}
                                                                                        />
                                                                                    </Grid.Col>

                                                                                    <Grid.Col span={4}>
                                                                                        <DatePicker
                                                                                            {...register("date")}
                                                                                            dropdownType="modal"
                                                                                            className={' border border-1 border-gray-500  h-9 hover:border-gray-400'}
                                                                                            placeholder={t("Date")}
                                                                                            onChange={RequiredDateHandel}
                                                                                            value={dateOfRequired}
                                                                                            minDate={dayjs(new Date()).startOf('month').add(new Date().toLocaleString("en-US", {day: '2-digit'}) - 1, 'days').toDate()}
                                                                                        />
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={4}>
                                                                                        <TextInput
                                                                                            {...register("quantity")}
                                                                                            onChange={e => {
                                                                                                QuantityHandel(e)
                                                                                            }}
                                                                                            placeholder={t("Quantity")}
                                                                                            value={quantitiesData ? quantitiesData : ''}
                                                                                            withAsterisk
                                                                                        />
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={4}>
                                                                                        <button type={'button'}
                                                                                                onClick={(e) => PreRequisitionItemsInsert(e)}
                                                                                                className="inline-flex w-full h-full justify-center items-center px-2 py-1 bg-indigo-700 text-white hover:bg-indigo-600 text-xs">
                                                                                            <HiOutlinePlus size={12}
                                                                                                           className={'mr-1'}></HiOutlinePlus>{t("Add")}
                                                                                        </button>
                                                                                    </Grid.Col>
                                                                                </Grid>
                                                                            </>
                                                                        }


                                                                        <div
                                                                            className=" mx-auto justify-items-center bg-indigo-100 sm:px-2 sm:py-1">

                                                                            <Grid columns={24}
                                                                                  className="bg-indigo-400 mt-2 justify-center h-full text-xs   text-white">
                                                                                <Grid.Col span={3}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Item')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Category')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={4}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('SubCategory')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('SpendArea')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('ItemNature')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Quantity')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Date')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('From')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Remark')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={1}>
                                                                                    <div
                                                                                        className="font-semibold text-left">{t('Action')}</div>
                                                                                </Grid.Col>
                                                                            </Grid>

                                                                            {requisitionItems ? requisitionItems.map((item, index) => {
                                                                                // console.log(item.isHeadOrLocal)
                                                                                let dropDown =
                                                                                    item.subCategoryDropdownData.map((head, index) => {
                                                                                        return ({
                                                                                            'value': Number(head.id),
                                                                                            'label': head.sub_categories_name
                                                                                        })
                                                                                    })
                                                                                return (
                                                                                    <Grid columns={24}
                                                                                          className="mt-2  justify-center h-full table-fixed w-full text-xs font-semibold">
                                                                                        <Grid.Col span={3}>
                                                                                            {item.itemName ? item.itemName : null} ({item.itemType ? item.itemType : null}-{item.itemUnit ? item.itemUnit : null})
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={2}>
                                                                                            {item.category_name ? item.category_name : null}
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={4}>
                                                                                            {
                                                                                                /*item.purchase_order_quantity>0 ?
                                                                                                    item.sub_category_name
                                                                                                    :*/
                                                                                                    <Select
                                                                                                        placeholder={t("SelectSubCategory")}
                                                                                                        searchable clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        withAsterisk
                                                                                                        data={dropDown ? dropDown : []}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={e => {
                                                                                                            UpdateSubCategoryHandel(e, item.id)
                                                                                                        }}
                                                                                                        defaultValue={item && item.sub_categories ? item.sub_categories : null}
                                                                                                    />
                                                                                            }

                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={3}>
                                                                                            {item.spend_area_name ? item.spend_area_name : null}
                                                                                            {item.budget_head_amount ? ' (' + item.budget_head_amount + ' tk.)' : null}                                                                                        </Grid.Col>

                                                                                        <Grid.Col span={2}>
                                                                                            {
                                                                                                /*item.purchase_order_quantity>0 ?
                                                                                                    item.item_nature
                                                                                                    :*/
                                                                                                    <Select
                                                                                                        placeholder={t('ChooseItemNature')}
                                                                                                        searchable clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        withAsterisk
                                                                                                        data={['Capex', 'Opex']}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={e => {
                                                                                                            UpdateItemNatureHandel(e, item.id)
                                                                                                        }}
                                                                                                        value={item.item_nature ? item.item_nature : null}
                                                                                                    />
                                                                                            }
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={2}>
                                                                                            {
                                                                                                item.isPoiExists>0 ?
                                                                                                    item.quantities
                                                                                                    :
                                                                                                    <TextInput
                                                                                                        name="quantity"
                                                                                                        id={`${index}`}
                                                                                                        placeholder={t("quantity")}
                                                                                                        onChange={e => {
                                                                                                            UpdateQuantityHandel(e, item.id)
                                                                                                        }}
                                                                                                        value={updateQuantity ? updateQuantity.index : item.quantities}
                                                                                                        withAsterisk
                                                                                                    />
                                                                                            }

                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={2}>
                                                                                            {item.date_of_required ? item.date_of_required : null}
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={2}>
                                                                                            <Select
                                                                                                placeholder="Purchase From"
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={localHeadDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={e => {
                                                                                                    UpdateLocalHeadHandel(e, item.id)
                                                                                                }}
                                                                                                value={item.isHeadOrLocal?String(item.isHeadOrLocal):null}
                                                                                            />
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={3}>
                                                                                            {item.comments ? item.comments : null}
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={1}>
                                                                                            {
                                                                                                item.isPoiExists > 0 ?
                                                                                                    ''
                                                                                                    :

                                                                                                    <ActionIcon
                                                                                                        color="red">
                                                                                                        <HiTrash
                                                                                                            size={16}
                                                                                                            onClick={(e) => {
                                                                                                                PreRequisitionItemDelete(e, item.id)
                                                                                                            }}/>
                                                                                                    </ActionIcon>
                                                                                            }
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                )
                                                                            }) : null}
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </ScrollArea>

                                                            <footer className="relative mt-1">
                                                                <div className="justify-items-center">
                                                                    <div
                                                                        className="text-right border-t pt-0.5 mb-0.5 pr-2">
                                                                        <Button
                                                                            onClick={() => navigate(-1)}
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                            <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                        <button type='submit'
                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                            {spinner ? (
                                                                                <AiOutlineLoading3Quarters
                                                                                    className={'mr-2 animate-spin'}
                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                            ) : (<HiArrowCircleRight size={16}
                                                                                         className={'mr-2'}></HiArrowCircleRight>)}
                                                                            <span>{t('Submit')}</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </footer>
                                                        </form>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {open &&
                <VariantModel
                    opened={opened}
                    close={close}
                    setVariantModel={setVariantModel}
                    setIsSlug={setIsSlug}
                    setMasterDataTypeSlug={setMasterDataTypeSlug}
                />
                }


            </main>
        </>
    )
}

export default EditAfterClaim