import axios from "axios";


export const createStoreRequisition = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/create`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 201){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const inlineUpdateStoreRequisition = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/inline-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const inlineUpdateStoreRequisitionAttachment = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/inline-update/attachment`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const createStoreRequisitionItem = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition-item/create`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 201){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const inlineUpdateStoreRequisitionItem = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition-item/inline-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 201){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getStoreRequisitionDetailsData = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/details/${param.id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const storeRequisitionFinalUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/final-update/${data.id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getStoreRequisitionIndex = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/list/${param.activeTab}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const storeRequisitionApprove = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/approve/${data.sr_id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getStoreRequisitionDelete = async (id) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/delete/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const getStoreRequisitionReceived = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/received`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getStoreRequisitionDelivery = async (id) => {
    let data = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition/delivery/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};





export const getStoreRequisitionItemDelete = async (id) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/store-requisition-item/delete/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};