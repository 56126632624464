import React, { useState} from "react";
import {Routes, Route} from 'react-router-dom';
import {MantineProvider, Text} from '@mantine/core';
import {ModalsProvider} from '@mantine/modals';
import {NotificationsProvider} from '@mantine/notifications';
import jwt_decode from "jwt-decode";


import Main from "./components/layouts/Main";
import RequisitionDashboard from "./components/modules/Requisition/RequisitionList";
import BudgetDashboard from "./components/modules/Budget/BudgetList";
import MasterDataDashboard from "./components/modules/master-data/dashboard";
import Login from "./components/layouts/Login";
import Item from "./components/modules/master-data/item/Item";
import ItemType from "./components/modules/master-data/item-type/ItemType";
import ProjectType from "./components/modules/master-data/project-type/ProjectType";
import CompanyType from "./components/modules/master-data/company-type/CompanyType";
import Category from "./components/modules/master-data/category/Category";
import SubCategory from "./components/modules/master-data/sub-category/SubCategory";
import Area from "./components/modules/master-data/area/Area";
import CostHeader from "./components/modules/master-data/cost-header/CostHeader";
import ErpLedgerAccount from "./components/modules/master-data/erp-ledger-account/ErpLedgerAccount";
import TermsAndConditions from "./components/modules/master-data/terms-and-conditions/TermsAndConditions";
import PurchaseType from "./components/modules/master-data/purchase-type/PurchaseType";
import CompanySetting from "./components/modules/master-data/company-setting/CompanySetting";
import RelatedBank from "./components/modules/master-data/related-bank/RelatedBank";
import Signature from "./components/modules/master-data/signature/Signature";
import CreateVendor from "./components/modules/master-data/vendor/CreateVendor";
import VendorDashboard from "./components/modules/master-data/vendor/Dashboard";
import ProjectDashboard from "./components/modules/master-data/project/Dashboard";
import {Toaster} from "react-hot-toast";
import PurchaseOrderDashboard from "./components/modules/PurchaseOrder/Dashboard";
import DeliveryIssuedDashboard from "./components/modules/DeliveryIssued/Dashboard";
import ReceivedItemDashboard from "./components/modules/ReceivedItem/Dashboard";
import PaymentDashboard from "./components/modules/Payment/Dashboard";
import UserPermission from "./components/modules/master-data/user-permission/UserPermission";
import SpendArea from "./components/modules/master-data/spend-area/SpendArea";
import EditRequisition from "./components/modules/Requisition/EditRequisition";

import PreRequisitionDashboard from "./components/modules/PreRequisition/PreRequisitionList";
import EditPreRequisition from "./components/modules/PreRequisition/EditPreRequisition";
import EditBudget from "./components/modules/Budget/EditBudget";
import Construction from "./components/modules/Construction/Construction";
import TentativeBudget from "./components/modules/TentativeBudget/TentativeBudget";
import {useLayoutEffect} from "@radix-ui/react-use-layout-effect";
import ProjectMantine from "./components/modules/master-data/project-mantain/ProjectMantine";
import Varieties from "./components/modules/master-data/varieties/Varieties";
import CreateUser from "./components/modules/User/CreateUser";
import Index from "./components/modules/User/Index";
import EditUser from "./components/modules/User/EditUser";
import CreatePurchaseOrder from "./components/modules/PurchaseOrder/CreatePurchaseOrder";
import Dashboard from "./components/layouts/Dashboard";
import VarietyType from "./components/modules/master-data/variety-type/VarietyType";
import Provider from "react-redux/es/components/Provider";
import store, {persistor} from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import UpdatePurchaseOrder from "./components/modules/PurchaseOrder/UpdatePurchaseOrder";
import CreateReceivedItem from "./components/modules/ReceivedItem/CreateReceivedItem";
import UpdateReceivedItem from "./components/modules/ReceivedItem/UpdateReceivedItem";
import {useNavigate} from "react-router";
import AddPermission from "./components/modules/master-data/user-permission/AddPermission";
import UpdateStoreRequisition from "./components/modules/StoreRequisition/UpdateStoreRequisition";
import StoreRequisitionDashboard from "./components/modules/StoreRequisition/Dashboard";
import EditAfterApproveRequisition from "./components/modules/Requisition/EditAfterApproveRequisition";
import EditPermission from "./components/modules/master-data/user-permission/EditPermission";
import Role from "./components/modules/master-data/role/Role";
import AddRole from "./components/modules/master-data/role/AddRole";
import EditRole from "./components/modules/master-data/role/EditRole";
import StockItemReport from "./components/modules/Inventory/StockItemReport";
import IssueItemReport from "./components/modules/Inventory/IssueItemReport";
import IssueItemProjectReport from "./components/modules/Inventory/IssueItemProjectReport";
import IssueWeiAvgReport from "./components/modules/Inventory/IssueWeiAvgReport";
import ValuationReport from "./components/modules/Inventory/ValuationReport";
import WeightedAveragePriceReport from "./components/modules/Inventory/WeightedAveragePriceReport";
import Vehicle from "./components/modules/master-data/vehicle/Vehicle";
import UpdateAmendmentPurchaseOrder from "./components/modules/PurchaseOrder/UpdateAmendmentPurchaseOrder";
import AdvancePaymentDashboard from "./components/modules/Payment/AdvancePaymentDashboard";
import PrintPdfView from "./components/modules/PurchaseOrder/PrintPdfView";
import EditVendor from "./components/modules/master-data/vendor/EditVendor";
import CreateProject from "./components/modules/master-data/project/CreateProject";
import EditProject from "./components/modules/master-data/project/EditProject";
import PoReport from "./components/modules/Report/PO/PoReport";
import ReceivedItemReport from "./components/modules/Report/Received-item/ReceivedItemReport";
import PrDailyReport from "./components/modules/Report/pr/PrDailyReport";
import PrActivitiesReport from "./components/modules/Report/pr/PrActivitiesReport";
import BudgetPoWiseReport from "./components/modules/Report/Budget/BudgetPoWiseReport";
import PrPendingClaimReport from "./components/modules/Report/pr/PrPendingClaimReport";
import BudgetSummaryReport from "./components/modules/Report/Budget/BudgetSummaryReport";
import EditAfterClaim from "./components/modules/Requisition/EditAfterClaim";
import PriceDirectoryReport from "./components/modules/Report/Price/PriceDirectoryReport";



function App() {
    const navigate = useNavigate();

    if (localStorage.getItem('user')){
        const {token} = JSON.parse(localStorage.getItem('user'));
        const decode_token = jwt_decode(token);
        if (decode_token.exp < Date.now() / 1000) {
            localStorage.clear();
            navigate("/login");
        }
    }

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const formHeight = useWindowSize()[1] - 255 + "px";
    const formTableHeight = useWindowSize()[1] - 170 + "px";
    const fullTableHeight = useWindowSize()[1] - 205 + "px";
    const fullFormHeight = useWindowSize()[1] - 230 + "px";
    const homeHeight = useWindowSize()[1] - 107 + "px";
    const calendarHeight = useWindowSize()[1] - 245 + "px";
    const rightSidebarHeight = useWindowSize()[1] - 245 + "px";
    const leftSidebarHeight = useWindowSize()[1] - 110 + "px";
    const modalHeight = useWindowSize()[1] - 130 + "px";
    const tableHeight = useWindowSize()[1]-203 + "px";
    const tableHeightWithFilter = useWindowSize()[1]-257 + "px";
    const reportTableHeight = useWindowSize()[1]-230 + "px";
    const reportWithHeadTableHeight = useWindowSize()[1]-292 + "px";
    const drawerHeight = useWindowSize()[1] - 133 + "px";
    const containerWidth = window.innerWidth - 270 + "px";
    const tabWithoutTableHeight = useWindowSize()[1] - 160 + "px";


    localStorage.setItem('formHeight', formHeight);
    localStorage.setItem('formTableHeight', formTableHeight);
    localStorage.setItem('fullTableHeight', fullTableHeight);
    localStorage.setItem('fullFormHeight', fullFormHeight);
    localStorage.setItem('homeHeight', homeHeight);
    localStorage.setItem('calendarHeight', calendarHeight);
    localStorage.setItem('modalHeight', modalHeight);
    localStorage.setItem('leftSidebarHeight', leftSidebarHeight);
    localStorage.setItem('tableHeight', tableHeight);
    localStorage.setItem('drawerHeight', drawerHeight);
    localStorage.setItem('containerWidth', containerWidth);
    localStorage.setItem('tabWithoutTableHeight', tabWithoutTableHeight);
    localStorage.setItem('reportTableHeight', reportTableHeight);
    localStorage.setItem('reportWithHeadTableHeight', reportWithHeadTableHeight);
    localStorage.setItem('tableHeightWithFilter', tableHeightWithFilter);


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
            <MantineProvider withNormalizeCSS withGlobalStyles>
                <ModalsProvider>
                    <NotificationsProvider>
                        <Toaster
                            position="top-right"
                            reverseOrder={true}
                        />
                        <Routes>
                            {/*<Route path='/' element={<Main/>}/>*/}
                            {/*<Route path='/' element={<FormBuilder/>}/>*/}
                            {/*<Route path='/' element={<UserForm/>}/>*/}
                            {/*<Route path='/' element={<PageForm/>}/>*/}
                            <Route path='/login' element={<Login/>}/>

                            <Route path="/" element={<Main/>}>

                                {/*<Route path='basic-crud' element={<BasicCrud/>}/>*/}
                                {/*<Route path='/dashboard' element={<Main/>}/>*/}
                                {/*<Route path='/page-form' element={<PageForm/>}/>*/}
                                {/*<Route path='/single-form' element={<SingleForm/>}/>*/}
                                {/*<Route path='/two-column-form' element={<TwoColumnForm/>}/>*/}
                                {/*<Route path='/' element={<Varieties/>}  />*/}
                                <Route path='/dashboard' element={<Dashboard/>}/>
                                <Route path="/user/">
                                    <Route path="" element={<Index/>}/>
                                    <Route path="add-user" element={<CreateUser/>}/>
                                    <Route path="edit/:id" element={<EditUser/>}/>
                                </Route>

                                <Route path="/requisition/">
                                    <Route path="" element={<RequisitionDashboard/>}/>
                                    <Route path="edit/:id" element={<EditRequisition/>}/>
                                    <Route path="edit-after-approve/:id" element={<EditAfterApproveRequisition/>}/>
                                    <Route path="edit-after-claim/:id" element={<EditAfterClaim/>}/>
                                </Route>

                                <Route path="/pre-requisition/">
                                    <Route path="" element={<PreRequisitionDashboard/>}/>
                                    <Route path="edit/:id" element={<EditPreRequisition/>}/>
                                </Route>

                                <Route path="/purchase-order/">
                                    <Route path="" element={<PurchaseOrderDashboard/>}/>
                                    <Route path="create" element={<CreatePurchaseOrder/>}/>
                                    <Route path="update/:id" element={<UpdatePurchaseOrder/>}/>
                                    <Route path="amendment/:id" element={<UpdateAmendmentPurchaseOrder/>}/>
                                    <Route path="print-pdf/:id" element={<PrintPdfView/>}/>
                                </Route>
                                <Route path="/budget/">
                                    <Route path="" element={<BudgetDashboard/>}/>
                                    <Route path="edit/:id" element={<EditBudget/>}/>
                                </Route>
                                <Route path="/delivery-issued/">
                                    <Route path="" element={<DeliveryIssuedDashboard/>}/>
                                    {/*<Route path="issue-item" element={<IssueItem/>}/>*/}
                                    {/*<Route path="delivery-item" element={<DeliveryItem/>}/>*/}
                                    {/*<Route path="stock-item" element={<StockItem/>}/>*/}
                                </Route>
                                <Route path="/received-item/">
                                    <Route path="" element={<ReceivedItemDashboard/>}/>
                                    <Route path="create" element={<CreateReceivedItem/>}/>
                                    <Route path="update/:id" element={<UpdateReceivedItem/>}/>
                                </Route>


                                <Route path="/store-requisition/">
                                    <Route path="" element={<StoreRequisitionDashboard/>}/>
                                    {/*<Route path="create" element={<UpdateStoreRequisition/>}/>*/}
                                    <Route path="edit/:id" element={<UpdateStoreRequisition/>}/>
                                </Route>

                                <Route path="/payment/">
                                    <Route path="" element={<PaymentDashboard/>}/>
                                    <Route path="advance" element={<AdvancePaymentDashboard/>}/>
                                </Route>

                                <Route path="/construction-budget-plan">
                                    <Route path="" element={<Construction/>}/>
                                </Route>
                                <Route path="/tentative-budget">
                                    <Route path="" element={<TentativeBudget/>}/>
                                </Route>

                                <Route path="/html/">
                                    <Route path="" element={<BudgetDashboard/>}/>
                                </Route>

                                <Route path="/permission">
                                    <Route path="" element={<UserPermission/>}/>
                                    <Route path="create" element={<AddPermission/>}/>
                                    <Route path="edit/:id" element={<EditPermission/>}/>
                                </Route>

                                <Route path="/role">
                                    <Route path="" element={<Role/>}/>
                                    <Route path="create" element={<AddRole/>}/>
                                    <Route path="edit/:id" element={<EditRole/>}/>
                                </Route>

                                <Route path="/inventory">
                                    <Route path="issue-item-list" element={<IssueItemReport/>}/>
                                    <Route path="issue-item-project-list" element={<IssueItemProjectReport/>}/>
                                    <Route path="stock-item-list" element={<StockItemReport/>}/>
                                    <Route path="issue-report-wei-avg" element={<IssueWeiAvgReport/>}/>
                                    <Route path="valuation-report" element={<ValuationReport/>}/>
                                    <Route path="weighted-average-price-report" element={<WeightedAveragePriceReport/>}/>
                                </Route>

                                <Route path="/report">
                                    <Route path="pr-daily-report" element={<PrDailyReport/>}/>
                                    <Route path="pr-activities-report" element={<PrActivitiesReport/>}/>
                                    <Route path="pr-pending-claim" element={<PrPendingClaimReport/>}/>
                                    <Route path="po-wise-budget" element={<BudgetPoWiseReport/>}/>
                                    <Route path="budget-summary" element={<BudgetSummaryReport/>}/>
                                    <Route path="po-report" element={<PoReport/>}/>
                                    <Route path="receive-item" element={<ReceivedItemReport/>}/>
                                    <Route path="purchase-price-directory" element={<PriceDirectoryReport/>}/>
                                </Route>

                                <Route path="master-data">
                                    <Route path="" element={<MasterDataDashboard/>}/>
                                    <Route path="project" element={<ProjectDashboard/>}/>
                                    <Route path="create-project" element={<CreateProject/>}/>
                                    <Route path="project-edit/:id" element={<EditProject/>}/>
                                    <Route path="vendor" element={<VendorDashboard/>}/>
                                    <Route path="create-vendor" element={<CreateVendor/>}/>
                                    <Route path="vendor-edit/:id" element={<EditVendor/>}/>
                                    <Route path="item" element={<Item/>}/>
                                    <Route path="item-type" element={<ItemType/>}/>
                                    <Route path="variety-type" element={<VarietyType/>}/>
                                    <Route path="spend-area" element={<SpendArea/>}/>
                                    {/*<Route path="project" element={<ProjectMantine/>}/>*/}
                                    <Route path="project-type" element={<ProjectType/>}/>
                                    <Route path="company-type" element={<CompanyType/>}/>
                                    <Route path="category" element={<Category/>}/>
                                    <Route path="sub-category" element={<SubCategory/>}/>
                                    <Route path="area" element={<Area/>}/>
                                    <Route path="cost-header" element={<CostHeader/>}/>
                                    <Route path="erp-ledger-account" element={<ErpLedgerAccount/>}/>
                                    <Route path="terms-and-conditions" element={<TermsAndConditions/>}/>
                                    <Route path="purchase-type" element={<PurchaseType/>}/>
                                    <Route path="company-setting" element={<CompanySetting/>}/>
                                    <Route path="related-bank" element={<RelatedBank/>}/>
                                    <Route path="signature" element={<Signature/>}/>
                                    {/*<Route path="user-group" element={<UserGroup/>}/>*/}
                                    {/*<Route path="user-permission" element={<UserPermission/>}/>*/}
                                    {/*<Route path="permission-add" element={<AddPermission/>}/>*/}
                                    <Route path="create-vendor" element={<CreateVendor/>}/>
                                    {/*<Route path="vendor-sourcing" element={<VendorSourcing/>}/>*/}
                                    {/*<Route path="varieties" element={<Varieties/>}/>*/}
                                    <Route path="vehicles" element={<Vehicle/>}/>
                                </Route>
                            </Route>
                        </Routes>
                    </NotificationsProvider>
                </ModalsProvider>
            </MantineProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;