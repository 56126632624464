import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getRelatedBankDropdown} from "../../services/RelatedBankService";

export const RelatedBankDropdown = createAsyncThunk("related-bank/dropdown", async () => {
    try {
        const response = getRelatedBankDropdown();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const relatedBankSlice = createSlice({
    name : "RelatedBank",
    initialState : {
        relatedBankDropdown : []
    },
    reducers : {

        },


    extraReducers : (builder) => {

        builder.addCase(RelatedBankDropdown.fulfilled,(state,action)=>{
            state.relatedBankDropdown = action.payload
        })

    }
})

export const { setProjectData,setLoading,setPage,setSortStatus,setOpened,setFetching,setRefresh,setFilterData,setActiveTab,setReadyIdChecked,setReadyIdUnChecked,setPaymentVerifyModel,setVerifyFromSubmit,setPaymentIdChecked,setPaymentIdUnChecked,setPaymentPayModel } = relatedBankSlice.actions
export default relatedBankSlice.reducer;