import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    vendorDetailsService,
    vendorDropdownService,
    vendorIndex,
    vendorShowService
} from "../../services/VendorService";


export const vendorDropdown = createAsyncThunk("vendor/dropdown", async () => {
    try {
        const response = vendorDropdownService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const vendorShow = createAsyncThunk("vendor/show", async (id) => {
    try {
        const response = vendorShowService(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const vendorDetails = createAsyncThunk("vendor/details", async (id) => {
    try {
        const response = vendorDetailsService(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getVendorIndex = createAsyncThunk("vendor/index", async (id) => {
    try {
        const response = vendorIndex(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const vendorSlice = createSlice({
    name : "vendor",
    initialState : {
        isLoading : true,
        message : null,
        vendorDropdownData : [],
        vendorData : [],
        vendorDetailsData : [],
        vendorIndexData: [],
        totalItem : 0,
        fetching : true,
        refresh : false,
        opened : false,
        page : localStorage.getItem('vendor_page') ? localStorage.getItem('vendor_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'vendorsName', direction: 'ASC' },
        filterData : {vendor:'',item_type:'',area:''},
    },
    reducers : {
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.item_type = action.payload.item_type;
            state.filterData.area = action.payload.area;
        },
        setEmptyVendorDetailsData : (state,action) => {
            state.vendorDetailsData = [];
        },
        /*setFromData : (state,action) => {
            state.fromData.project_from = action.payload.project_from;
            state.fromData.project_to = action.payload.project_to;
        },*/

    },

    extraReducers : (builder) => {
        builder.addCase(vendorDropdown.fulfilled, (state, action) => {
            state.vendorDropdownData = action.payload
            state.message = 'vendorDropdownLoad'
            state.isLoading = false
        })
        builder.addCase(vendorShow.fulfilled,(state,action) => {
            state.vendorData = action.payload
            state.message = 'vendorDataLoad'
            state.isLoading = false
        })
        builder.addCase(vendorDetails.fulfilled,(state,action) => {
            state.vendorDetailsData = action.payload
            state.message = 'vendorDataLoad'
            state.isLoading = false
        })

        builder.addCase(getVendorIndex.fulfilled,(state,action) => {
            state.vendorIndexData = action.payload.data
            state.totalItem = action.payload.total
            state.fetching = false
        })

    }
})
export const { setActiveTab,setSortStatus,setFilterData,setPage,setFetching,setRefresh,setSpinner,setOpened,setEmptyVendorDetailsData } = vendorSlice.actions

export default vendorSlice.reducer;