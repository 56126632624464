import React from "react";

function Footer(){
    return(
        <>
            <footer className="flex block w-full py-3 bg-indigo-100 items-center text-indigo-400">
       <p className={'w-full text-center'}> Power by Right Brain Solution Ltd</p>
      </footer>
        </>
    )
}

export default Footer