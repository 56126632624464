import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil, HiEye, HiTrash
} from "react-icons/hi";
import { IconSearch } from '@tabler/icons-react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActionIcon, Box, Drawer, Grid, Group, ScrollArea, Select, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {poDelete, setSpinner} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {vendorDropdown} from "../../../store/vendor/vendorSlice";
import {projectDropdown, projectDropdownUserWise} from "../../../store/project/projectSlice";
import {
    issueToCustomerDeliveryIndex,
    setIssueProjectModel,
    setPage,
    setActiveTab,
    setSortStatus,
    setOpened,
    setFetching,
    setFilterData,
    setRefresh,
    setApprovalModelCustomer,
    setDeliveryApproveID,
    setProjectID,
    setDeliveryViewModel,
    setIssueToCustomerIndex,
    setIssueProjectToProjectModel,
    setApprovalModelProject,
    setProjectApproveOrSubmit,
    setDeliveryViewModelProject,
    setDeliveryApproveAfterEditModel,
    setDeliveryApproveAfterEditId, deliveryItemDelete,
} from "../../../store/issue/issueSlice";
import IssueProjectModel from "./IssueProjectModel";
import ItemIssueCustomerModel from "./ItemIssueCustomerModel";
import {format} from "date-fns";
import {getItemDropdownProjectWise} from "../../../store/items/itemsSlice";
import axios from "axios";
import {DatePicker} from "@mantine/dates";
import DeliveryEditAfterApprove from "./DeliveryEditAfterApprove";
import DeliveryCustomerViewModel from "./DeliveryCustomerViewModel";
import IssueProjectToProjectModel from "./project/IssueProjectToProjectModel";
import ItemIssueProjectModel from "./project/ItemIssueProjectModel";
import ApprovalProjectModel from "./project/ApprovalProjectModel";
import DeliveryProjectViewModel from "./project/DeliveryProjectViewModel";
import ApprovalCustomerModel from "./ApprovalCustomerModel";
import {openConfirmModal} from "@mantine/modals";

const PAGE_SIZE = 20;

function Dashboard() {
    const {t, i18n} = useTranslation();
    const tableHeight = localStorage.getItem('tableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const drawerHeight = localStorage.getItem('drawerHeight')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);


    const ROLE_INVENTORY_DELIVERY_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_INVENTORY_DELIVERY_APPROVE";
    })

    const ROLE_INVENTORY_DELIVERY_VIEW = decoded.roles.find(role=>{
        return role === "ROLE_INVENTORY_DELIVERY_VIEW" ;
    })
    const ROLE_INVENTORY_DELIVERY_ADD = decoded.roles.find(role=>{
        return role === "ROLE_INVENTORY_DELIVERY_ADD" ;
    })
    const ROLE_INVENTORY_DELIVERY_EDIT = decoded.roles.find(role=>{
        return role === "ROLE_INVENTORY_DELIVERY_EDIT" ;
    })

    const ROLE_INVENTORY_DELIVERY_DELETE = decoded.roles.find(role=>{
        return role === "ROLE_INVENTORY_DELIVERY_DELETE" ;
    })
    const ROLE_PROJECT_TO_PROJECT_DELIVERY = decoded.roles.find(role=>{
        return role === "ROLE_PROJECT_TO_PROJECT_DELIVERY" ;
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role=>{
        return role === "ROLE_SUPER_ADMIN" ;
    })

    const ROLE_ERP_LEDGER_HEAD_ACCESS = decoded.roles.find(role=>{
        return role === "ROLE_ERP_LEDGER_HEAD_ACCESS" ;
    })

    const ROLE_INVENTORY_DELIVERY_EDIT_FOR_SUBCAT_SPEND = decoded.roles.find(role=>{
        return role === "ROLE_INVENTORY_DELIVERY_EDIT_FOR_SUBCAT_SPEND" ;
    })

    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 0) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }

    const projectWiseItemDropdown = useSelector((state) => state.itemReducer.projectWiseItemDropdown)
    const issueToCustomerIndex = useSelector((state) => state.issueReducer.issueToCustomerIndex)
    const fetching = useSelector((state) => state.issueReducer.fetching)
    const totalItem = useSelector((state) => state.issueReducer.totalItem)
    const page = useSelector((state) => state.issueReducer.page)
    const activeTab = useSelector((state) => state.issueReducer.activeTab)
    const opened = useSelector((state) => state.issueReducer.opened)
    const refresh = useSelector((state) => state.issueReducer.refresh)
    const sortStatus = useSelector((state) => state.issueReducer.sortStatus)
    const filterData = useSelector((state) => state.issueReducer.filterData)
    const deliveryViewModel = useSelector((state) => state.issueReducer.deliveryViewModel)
    const issueProjectModel = useSelector((state) => state.issueReducer.issueProjectModel)
    const itemIssueCustomerModel = useSelector((state) => state.issueReducer.itemIssueCustomerModel)
    const approvalModelCustomer = useSelector((state) => state.issueReducer.approvalModelCustomer)
    // const projectDropdownData = useSelector((state) => state.projectReducer.projects)
    const projectDropdownData = useSelector((state) => state.projectReducer.projectsUserWise)

    const issueProjectToProjectModel = useSelector((state) => state.issueReducer.issueProjectToProjectModel)
    const itemIssueProjectModel = useSelector((state) => state.issueReducer.itemIssueProjectModel)
    const approvalModelProject = useSelector((state) => state.issueReducer.approvalModelProject)
    const deliveryViewModelProject = useSelector((state) => state.issueReducer.deliveryViewModelProject)
    const deliveryApproveAfterEditModel = useSelector((state) => state.issueReducer.deliveryApproveAfterEditModel)


    const validationSchema = Yup.object().shape({});
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit} = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1) {
            dispatch(setRefresh(true))
        } else {
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(projectDropdownUserWise())
    }, [])

    if (filterData.deliveryDate == '') {
        dispatch(setFilterData({...filterData, ['deliveryDate']: new Date()}))
    }

    // console.log(new Date())

    useEffect(() => {
        const param = {
            activeTab: activeTab,
            offset: PAGE_SIZE,
            page: page,
            order_type: sortStatus.columnAccessor ? sortStatus.columnAccessor : '',
            order_by: sortStatus.direction ? sortStatus.direction : '',
            delivery_type: filterData.delivery_type ? filterData.delivery_type : '',
            deliveryDate: filterData.deliveryDate ? format(filterData.deliveryDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
            project: filterData.project ? filterData.project : '',
            get_pass: filterData.get_pass ? filterData.get_pass : '',
            item: filterData.item ? filterData.item : '',
        }
        // console.log(param)
        dispatch(issueToCustomerDeliveryIndex(param))
    }, [page, activeTab, sortStatus, fetching, refresh]);

    const HandelPoTabChange = (e, value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);

    return (
        <>
            <main className="flex flex-1 flex-1">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('DeliveryIssued')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllDeliveryIssuedRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button
                                                                onClick={() => dispatch(setOpened(true))}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            {
                                                                (ROLE_INVENTORY_DELIVERY_ADD || ROLE_INVENTORY_DELIVERY_EDIT || ROLE_SUPER_ADMIN) &&
                                                                <button onClick={(event) => {
                                                                    dispatch(setIssueProjectModel(true))
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12}
                                                                                   className={'mr-1'}></HiOutlinePlus>{t('IssueToCustomer')}
                                                                </button>
                                                            }

                                                            {
                                                                (((ROLE_INVENTORY_DELIVERY_ADD || ROLE_INVENTORY_DELIVERY_EDIT) && ROLE_PROJECT_TO_PROJECT_DELIVERY) || ROLE_SUPER_ADMIN) &&
                                                                <button onClick={(event) => {
                                                                    dispatch(setIssueProjectToProjectModel(true))
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12}
                                                                                   className={'mr-1'}></HiOutlinePlus>{t('ProjectToProject')}
                                                                </button>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">

                                                                <div className="h-11">


                                                                    <button
                                                                        className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                        onClick={(e) => HandelPoTabChange(e, 'all')}>
                                                                        {t('All')}
                                                                    </button>

                                                                    <button
                                                                        className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Created' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                        onClick={(e) => HandelPoTabChange(e, 'Created')}>
                                                                        {t('Created')}
                                                                    </button>

                                                                    <button
                                                                        className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Approved' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                        onClick={(e) => HandelPoTabChange(e, 'Approved')}>
                                                                        {t('Approved')}
                                                                    </button>


                                                                    <button
                                                                        className={`inline-flex items-center px-2 py-1 h-10 ml-10 ${filterData.delivery_type == 'individual' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                        onClick={(e) => {
                                                                            dispatch(setIssueToCustomerIndex())
                                                                            dispatch(setFilterData({
                                                                                ...filterData,
                                                                                ['delivery_type']: 'individual'
                                                                            }))
                                                                            dispatch(setFetching(true))
                                                                        }}>
                                                                        {t('Individual')}
                                                                    </button>
                                                                    {
                                                                        (ROLE_SUPER_ADMIN || ROLE_PROJECT_TO_PROJECT_DELIVERY) &&
                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${filterData.delivery_type == 'project' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                            onClick={(e) => {
                                                                                dispatch(setIssueToCustomerIndex())
                                                                                dispatch(setFilterData({
                                                                                    ...filterData,
                                                                                    ['delivery_type']: 'project'
                                                                                }))
                                                                                dispatch(setFetching(true))
                                                                            }}>
                                                                            {t('Project')}
                                                                        </button>
                                                                    }

                                                                </div>

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => dispatch(setOpened(false))}
                                                                    title={t('DeliveryIssueFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{height: drawerHeight}}>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="SelectProject"
                                                                                       className="form-input-sm-label">{t('Project')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseProject")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={projectDropdownData}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e) => {
                                                                                        dispatch(setFilterData({
                                                                                            ...filterData,
                                                                                            ['project']: e
                                                                                        }))
                                                                                    }}
                                                                                    value={filterData && filterData.project ? filterData.project : null}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                filterData.project &&
                                                                                <div className="mb-5">
                                                                                    <label htmlFor="SelectProject"
                                                                                           className="form-input-sm-label">{t('Item')}</label>
                                                                                    <Select
                                                                                        placeholder='Choose Item'
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={projectWiseItemDropdown ? projectWiseItemDropdown : []}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        maxDropdownHeight={280}
                                                                                        onSearchChange={(e) => {
                                                                                            setItemSearchValue(e)
                                                                                            if (e.length != 0 && filterData.project) {
                                                                                                dispatch(getItemDropdownProjectWise({
                                                                                                    value: e,
                                                                                                    project_id: filterData.project
                                                                                                }))
                                                                                            }
                                                                                        }}
                                                                                        onChange={e => {
                                                                                            dispatch(setFilterData({
                                                                                                ...filterData,
                                                                                                ['item']: e
                                                                                            }))
                                                                                        }}
                                                                                        searchValue={itemSearchValue}
                                                                                        value={filterData.item}
                                                                                    />
                                                                                </div>
                                                                            }

                                                                            {
                                                                                filterData.project == '' &&
                                                                                <div className="mb-5">
                                                                                    <label htmlFor="SelectProject"
                                                                                           className="form-input-sm-label">{t('Item')}</label>
                                                                                    <Select
                                                                                        placeholder='Choose Item'
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={items ? items : [items ? items : []]}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        maxDropdownHeight={280}
                                                                                        onSearchChange={ItemSearchHandel}
                                                                                        onChange={e => {
                                                                                            dispatch(setFilterData({
                                                                                                ...filterData,
                                                                                                ['item']: e
                                                                                            }))
                                                                                        }}
                                                                                        searchValue={itemSearchValue}
                                                                                        value={filterData.item}
                                                                                    />
                                                                                </div>
                                                                            }

                                                                            <div className="mb-5">
                                                                                <label htmlFor="SelectProject"
                                                                                       className="form-input-sm-label">{t('GetPass')}</label>
                                                                                <TextInput
                                                                                    onChange={(e) => {
                                                                                        dispatch(setFilterData({
                                                                                            ...filterData,
                                                                                            ['get_pass']: e.target.value
                                                                                        }))
                                                                                    }}
                                                                                    placeholder={t("EnterGetPass")}
                                                                                    value={filterData.get_pass ? filterData.get_pass : ''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="startDate"
                                                                                       className="form-input-sm-label">{t('IssueDate')}</label>
                                                                                <DatePicker
                                                                                    dropdownType="modal"
                                                                                    placeholder="Pick date"
                                                                                    onChange={(e) => {
                                                                                        dispatch(setFilterData({
                                                                                            ...filterData,
                                                                                            ['deliveryDate']: e
                                                                                        }))
                                                                                    }}
                                                                                    value={filterData && filterData.deliveryDate ? filterData.deliveryDate : ''}
                                                                                />
                                                                            </div>

                                                                        </ScrollArea>

                                                                        <div className="mb-2 mt-5 text-right">
                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {
                                                                                                    'label': 'ID',
                                                                                                    'value': 'id'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'Item',
                                                                                                    'value': 'itemsName'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'Unit',
                                                                                                    'value': 'itemUnitName'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'Quantity',
                                                                                                    'value': 'quantity'
                                                                                                },
                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e) => {
                                                                                            dispatch(setSortStatus({
                                                                                                ...sortStatus,
                                                                                                ['columnAccessor']: e
                                                                                            }))
                                                                                        }}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {
                                                                                                    'label': 'ASC',
                                                                                                    'value': 'ASC'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'DESC',
                                                                                                    'value': 'DESC'
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e) => {
                                                                                            dispatch(setSortStatus({
                                                                                                ...sortStatus,
                                                                                                ['direction']: e
                                                                                            }))
                                                                                        }}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit'
                                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                                </Drawer>

                                                                <Box sx={{height: tableHeight, width: containerWidth}}>
                                                                    {
                                                                        filterData.delivery_type === 'individual' &&
                                                                        <DataTable
                                                                            withBorder
                                                                            records={issueToCustomerIndex}
                                                                            columns={[
                                                                                {
                                                                                    accessor: 'index',
                                                                                    title: 'SL#',
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (issueToCustomerIndex.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                },
                                                                                {
                                                                                    accessor: 'itemsName',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Item')}</Text>,
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'itemUnitName',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Unit')}</Text>,
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'quantity',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Quantity')}</Text>,
                                                                                    sortable: true,
                                                                                },

                                                                                {
                                                                                    accessor: 'issue_date',
                                                                                    title: <Text
                                                                                        mr="xs">{t('IssueDate')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'issue_by_full_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('IssueBy')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'project_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Project')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'getPass',
                                                                                    title: <Text
                                                                                        mr="xs">{t('GetPass')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'category_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Category')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'sub_category_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('SubCategory')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'spend_area_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('SpendArea')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'erp_ledger_parent_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ERPSecondaryGroup')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'erp_ledger_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ERPLedgerAccount')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'process',
                                                                                    title: 'Status',
                                                                                    sortable: true,
                                                                                    render: (item) =>
                                                                                        <>
                                                                                            {item.process === 'Created' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-white text-xs bg-green-600	">Created</span>}
                                                                                            {item.process === 'Approved' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-white text-xs bg-lime-600	">Approved</span>}
                                                                                        </>
                                                                                },
                                                                                {
                                                                                    accessor: 'approved',
                                                                                    title: <Text mr="xs">Approve</Text>,
                                                                                    textAlignment: 'right',
                                                                                    hidden : !ROLE_INVENTORY_DELIVERY_APPROVE && !ROLE_SUPER_ADMIN,
                                                                                    render: (item) =>
                                                                                        <>

                                                                                            {
                                                                                                (item.process === 'Created' && (ROLE_INVENTORY_DELIVERY_APPROVE || ROLE_SUPER_ADMIN)) &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        dispatch(setApprovalModelCustomer(true))
                                                                                                        dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                        dispatch(setProjectID(item.project_id))
                                                                                                        dispatch(setProjectApproveOrSubmit('approve'))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-rose-100 text-rose-600 hover:bg-rose-200 hover:text-rose-800 text-white text-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            }
                                                                                        </>,
                                                                                },
                                                                                {
                                                                                    accessor: 'actions',
                                                                                    title: <Text mr="xs">Action</Text>,
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (
                                                                                        <Group spacing={4} position="right" noWrap>


                                                                                            {
                                                                                                (item.process == 'Created' && (ROLE_INVENTORY_DELIVERY_ADD || ROLE_INVENTORY_DELIVERY_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                                                <ActionIcon
                                                                                                    color="green"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        dispatch(setApprovalModelCustomer(true))
                                                                                                        dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                        dispatch(setProjectID(item.project_id))
                                                                                                        dispatch(setProjectApproveOrSubmit('submit'))

                                                                                                    }}>
                                                                                                    <HiPencil
                                                                                                        size={16}/>
                                                                                                </ActionIcon>
                                                                                            }
                                                                                            {
                                                                                                (item.process == 'Approved' && (ROLE_SUPER_ADMIN || ROLE_ERP_LEDGER_HEAD_ACCESS || ROLE_INVENTORY_DELIVERY_EDIT_FOR_SUBCAT_SPEND)) &&
                                                                                                <ActionIcon
                                                                                                    color="green"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        dispatch(setDeliveryApproveAfterEditModel(true))
                                                                                                        dispatch(setDeliveryApproveAfterEditId(item.delivery_id))
                                                                                                        dispatch(setProjectID(item.project_id))
                                                                                                    }}>
                                                                                                    <HiPencil
                                                                                                        size={16}/>
                                                                                                </ActionIcon>
                                                                                            }

                                                                                            {
                                                                                                ( ROLE_INVENTORY_DELIVERY_APPROVE || (ROLE_INVENTORY_DELIVERY_ADD || ROLE_INVENTORY_DELIVERY_EDIT || ROLE_SUPER_ADMIN || ROLE_INVENTORY_DELIVERY_VIEW)) &&
                                                                                                <ActionIcon
                                                                                                    color="blue">
                                                                                                    <HiEye size={16}
                                                                                                           onClick={(e) => {
                                                                                                               dispatch(setDeliveryViewModel(true))
                                                                                                               dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                               dispatch(setProjectID(item.project_id))
                                                                                                           }}
                                                                                                    />
                                                                                                </ActionIcon>
                                                                                            }

                                                                                            {
                                                                                                item.process == 'Created' &&
                                                                                                <ActionIcon color="red">
                                                                                                    <HiTrash size={16} onClick={(e) =>{
                                                                                                        openConfirmModal({
                                                                                                            title: t('DeliveryIssueDelete'),
                                                                                                            centered: true,
                                                                                                            children: (
                                                                                                                <Text size="sm">
                                                                                                                    {t('AreYouSureToDeliveryIssueDelete')}
                                                                                                                </Text>
                                                                                                            ),
                                                                                                            labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                                            confirmProps: {color: 'red'},
                                                                                                            onCancel: () => console.log(t('Cancel')),
                                                                                                            onConfirm: () => {
                                                                                                                dispatch(deliveryItemDelete({
                                                                                                                    item_id:item.id
                                                                                                                }))
                                                                                                            }
                                                                                                        });
                                                                                                    }}/>
                                                                                                </ActionIcon>
                                                                                            }
                                                                                        </Group>
                                                                                    ),
                                                                                },

                                                                            ]}
                                                                            totalRecords={totalItem}
                                                                            recordsPerPage={PAGE_SIZE}
                                                                            page={page}
                                                                            onPageChange={(p) => {
                                                                                dispatch(setPage(p))
                                                                                dispatch(setFetching(true))
                                                                            }}
                                                                            fetching={fetching}
                                                                            loaderSize="xs"
                                                                            loadercolor="blue"
                                                                            loaderBackgroundBlur={1}
                                                                            sortStatus={sortStatus}
                                                                            onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                        />
                                                                    }

                                                                    {
                                                                        filterData.delivery_type === 'project' &&
                                                                        <DataTable
                                                                            withBorder
                                                                            records={issueToCustomerIndex}
                                                                            columns={[
                                                                                {
                                                                                    accessor: 'index',
                                                                                    title: 'SL#',
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (issueToCustomerIndex.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                },
                                                                                {
                                                                                    accessor: 'itemsName',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Item')}</Text>,
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'itemUnitName',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Unit')}</Text>,
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'quantity',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Quantity')}</Text>,
                                                                                    sortable: true,
                                                                                },

                                                                                {
                                                                                    accessor: 'issue_date',
                                                                                    title: <Text
                                                                                        mr="xs">{t('IssueDate')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'issue_by_email',
                                                                                    title: <Text
                                                                                        mr="xs">{t('IssueBy')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'issue_customer_email',
                                                                                    title: <Text
                                                                                        mr="xs">{t('IssueTo')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'project_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ProjectFrom')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'issue_project_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ProjectTo')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'getPass',
                                                                                    title: <Text
                                                                                        mr="xs">{t('GetPass')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'category_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('Category')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'sub_category_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('SubCategory')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'spend_area_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('SpendArea')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'erp_ledger_parent_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ERPSecondaryGroup')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'erp_ledger_name',
                                                                                    title: <Text
                                                                                        mr="xs">{t('ERPLedgerAccount')}</Text>,
                                                                                },
                                                                                {
                                                                                    accessor: 'process',
                                                                                    title: 'Status',
                                                                                    sortable: true,
                                                                                    render: (item) =>
                                                                                        <>
                                                                                            {item.process === 'Created' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-white text-xs bg-green-600	">Created</span>}
                                                                                            {item.process === 'Approved' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-white text-xs bg-lime-600	">Approved</span>}
                                                                                        </>
                                                                                },
                                                                                {
                                                                                    accessor: 'approved',
                                                                                    title: <Text mr="xs">Approve</Text>,
                                                                                    textAlignment: 'right',
                                                                                    hidden: !ROLE_SUPER_ADMIN && !ROLE_INVENTORY_DELIVERY_APPROVE,
                                                                                    render: (item) =>
                                                                                        <>

                                                                                            {
                                                                                                (item.process === 'Created' && ((ROLE_INVENTORY_DELIVERY_APPROVE && ROLE_PROJECT_TO_PROJECT_DELIVERY) || ROLE_SUPER_ADMIN)) &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        dispatch(setProjectApproveOrSubmit('approve'))
                                                                                                        dispatch(setApprovalModelProject(true))
                                                                                                        dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                        dispatch(setProjectID(item.project_id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-rose-100 text-rose-600 hover:bg-rose-200 hover:text-rose-800 text-white text-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            }
                                                                                        </>,
                                                                                },
                                                                                {
                                                                                    accessor: 'actions',
                                                                                    title: <Text mr="xs">Action</Text>,
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (
                                                                                        <Group spacing={4}
                                                                                               position="right" noWrap>
                                                                                            {
                                                                                                (item.process == 'Created' && ((ROLE_INVENTORY_DELIVERY_EDIT || ROLE_INVENTORY_DELIVERY_ADD) && ROLE_PROJECT_TO_PROJECT_DELIVERY) || ROLE_SUPER_ADMIN) &&
                                                                                                <ActionIcon
                                                                                                    color="green"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        dispatch(setApprovalModelProject(true))
                                                                                                        dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                        dispatch(setProjectID(item.project_id))
                                                                                                        dispatch(setProjectApproveOrSubmit('submit'))
                                                                                                    }}>
                                                                                                    <HiPencil
                                                                                                        size={16}/>
                                                                                                </ActionIcon>
                                                                                            }

                                                                                            {
                                                                                                ( ((ROLE_INVENTORY_DELIVERY_VIEW && ROLE_PROJECT_TO_PROJECT_DELIVERY) || ROLE_SUPER_ADMIN)) &&
                                                                                                <ActionIcon
                                                                                                    color="blue">
                                                                                                    <HiEye size={16}
                                                                                                           onClick={(e) => {
                                                                                                               dispatch(setDeliveryViewModelProject(true))
                                                                                                               dispatch(setDeliveryApproveID(item.delivery_id))
                                                                                                               dispatch(setProjectID(item.project_id))
                                                                                                           }}/>
                                                                                                </ActionIcon>
                                                                                            }


                                                                                        </Group>
                                                                                    ),
                                                                                },

                                                                            ]}
                                                                            totalRecords={totalItem}
                                                                            recordsPerPage={PAGE_SIZE}
                                                                            page={page}
                                                                            onPageChange={(p) => {
                                                                                dispatch(setPage(p))
                                                                                dispatch(setFetching(true))
                                                                            }}
                                                                            fetching={fetching}
                                                                            loaderSize="xs"
                                                                            loadercolor="blue"
                                                                            loaderBackgroundBlur={1}
                                                                            sortStatus={sortStatus}
                                                                            onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                        />
                                                                    }
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {issueProjectModel ? <IssueProjectModel/> : ''}
            {itemIssueCustomerModel ? <ItemIssueCustomerModel/> : ''}
            {approvalModelCustomer ? <ApprovalCustomerModel/> : ''}
            {deliveryViewModel ? <DeliveryCustomerViewModel/> : ''}
            {deliveryApproveAfterEditModel ? <DeliveryEditAfterApprove/> : ''}

            {issueProjectToProjectModel ? <IssueProjectToProjectModel/> : ''}
            {itemIssueProjectModel ? <ItemIssueProjectModel/> : ''}
            {approvalModelProject ? <ApprovalProjectModel/> : ''}
            {deliveryViewModelProject ? <DeliveryProjectViewModel/> : ''}

        </>
    )
}

export default Dashboard