import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {
    getClaimItemSendBack,
    openItem,
    setCheckOpenItem, setCloseId, setCloseModel,
    setKeywordData,
    setLoading,
    setProjectData, setRemoveOpenItem
} from "../../../../store/purchaseOrder/purchaseOrderSlice";
import {Loader, LoadingOverlay, Select, Text, TextInput} from "@mantine/core";
import {projectDropdown, projectDropdownUserWise} from "../../../../store/project/projectSlice";
import {openConfirmModal} from "@mantine/modals";
import axios from "axios";
import jwt_decode from "jwt-decode";
import PoViewModel from "../PoViewModel";
import CloseOpenItemModel from "../CloseOpenItemModel";
import {showNotification} from "@mantine/notifications";


function OpenItemInformation(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PR_ITEM_CLOSE_HEAD_OFFICE = decoded.roles.find(role => {
        return role === "ROLE_PR_ITEM_CLOSE_HEAD_OFFICE";
    })
    const ROLE_PR_ITEM_CLOSE_LOCAL_OFFICE = decoded.roles.find(role => {
        return role === "ROLE_PR_ITEM_CLOSE_LOCAL_OFFICE";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const projectDropdownData = useSelector((state) => state.projectReducer.projectsUserWise)
    const openItems = useSelector((state) => state.purchaseOrderReducer.openItemData)
    const keywordData = useSelector((state) => state.purchaseOrderReducer.keywordData)
    const projectData = useSelector((state) => state.purchaseOrderReducer.projectData)
    const isLoading = useSelector((state) => state.purchaseOrderReducer.isLoading)
    const closeModel = useSelector((state) => state.purchaseOrderReducer.closeModel)
    const [reload,setReload] = useState(false)
    const [check,setCheck] = useState(false)

    useEffect(()=>{
        dispatch(projectDropdownUserWise())
        dispatch(openItem({
            project_id : projectData?projectData:null,
            keyword : keywordData?keywordData:null
        }))
        setReload(false)
    },[projectData,closeModel,reload])

    const ProjectDataHandel = (e,check=null) => {
        if (check == null){
            setOpenItemCheckData([])
            dispatch(setLoading(true))
        }
        if (Object.keys(openItemCheckData).length==0){
            dispatch(setLoading(true))
        }
        dispatch(setProjectData(Number(e)))
        setTimeout(() => {
            dispatch(openItem({
                project_id : e,
                keyword : keywordData
            }))
        }, 700);
    }
    const KeywordDataHandel = (e) => {
        dispatch(setKeywordData(e.target.value))
        setTimeout(() => {
            dispatch(openItem({
                project_id : projectData,
                keyword : e.target.value
            }))
        });
    }

    const {openItemCheckData,setOpenItemCheckData,setValue,setError} = props
    const openItemsHandel = (e, openItem) => {
        setCheck(true)
        if (e.target.checked === true) {
            ProjectDataHandel(openItem.project_id,'check')
            setOpenItemCheckData({...openItemCheckData,[e.target.value] : Number(openItem.requisition_item_id)}) ;
            dispatch(setCheckOpenItem(openItem))
        } else {
            delete openItemCheckData[e.target.value];
            setOpenItemCheckData({...openItemCheckData}) ;
            dispatch(setRemoveOpenItem(openItem.requisition_item_id))
        }
    }

    if (check){
        if (openItemCheckData && Object.keys(openItemCheckData).length > 0){
            setValue('open_item',openItemCheckData)
            setError('open_item',null)
        }else {
            dispatch(setProjectData(null))
            setReload(true)
        }
        setCheck(false)
    }



    return(
        <>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">

                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                <div className="mb-5">
                                    <Select
                                        placeholder={t("ChooseProjectName")}
                                        searchable clearable
                                        allowDeselect
                                        nothingFound="No options"
                                        withAsterisk
                                        data={projectDropdownData}
                                        transition="pop-top-left"
                                        transitionDuration={80}
                                        transitionTimingFunction="ease"
                                        onChange={ProjectDataHandel}
                                        value={projectData}
                                    />
                                </div>


                                <div className="mb-5">
                                    <TextInput
                                        onKeyUp={KeywordDataHandel}
                                        placeholder={t("EnterKeyword")}
                                        defaultValue={keywordData?keywordData:''}
                                    />
                                </div>

                                <LoadingOverlay
                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                    visible={isLoading}
                                    overlayBlur={2}
                                />
                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                            <div className="mx-auto justify-items-right w-full">
                                                <section className="-mx-0.5">
                                                    <div className="flex flex-col justify-center h-full">

                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                            <tr>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('SL')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('Particulars')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('PRQuantity')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('OrderedQuantity')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('DraftQuantity')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('RemainQuantity')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('ProjectName')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('CreatedBy')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('ClamiedBy')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('ApprovedDate')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('ClaimedDate')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                    <div className="font-semibold text-center">{t('PRNumber')}</div>
                                                                </th>
                                                                <th className="p-2 border-collapse border border-slate-400 w-[10%]">
                                                                    <div className="font-semibold text-center">{t('Action')}</div>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                { openItems && openItems.length > 0 ? openItems.map((openItemVal, index) => {
                                                                    return (
                                                                <tr className={"bg-gray-100"} key={index}>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {++index}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <input
                                                                            onChange={(e) => openItemsHandel(e, openItemVal)}
                                                                            type="checkbox"
                                                                            value={openItemVal.requisition_item_id}
                                                                            checked={openItemCheckData && openItemCheckData.hasOwnProperty(openItemVal.requisition_item_id)?true:false}
                                                                            className="w-4 h-4 p-2 text-blue-600 bg-gray-100"/>
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{openItemVal.items_name?openItemVal.items_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.quantities?openItemVal.quantities:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.purchase_order_quantity?openItemVal.purchase_order_quantity:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.draft_quantity}
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.quantities?
                                                                            ((openItemVal.quantities-openItemVal.purchase_order_quantity)-openItemVal.draft_quantity):0}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.projects_name?openItemVal.projects_name:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.order_by?openItemVal.order_by:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.claimed_by?openItemVal.claimed_by:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {openItemVal.requisition_approve_date?dayjs(openItemVal.requisition_approve_date).format('DD/MM/YYYY'):''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{openItemVal.claimed_date?dayjs(openItemVal.claimed_date).format('DD/MM/YYYY'):''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{openItemVal.pr_number?openItemVal.pr_number:''}</td>
                                                                    <td className="w-2/24 border-collapse border border-slate-400 text-center">
{
    (ROLE_SUPER_ADMIN || ROLE_PR_ITEM_CLOSE_HEAD_OFFICE || ROLE_PR_ITEM_CLOSE_LOCAL_OFFICE) &&
    <button
        type="button"
        className="px-1 py-1 bg-red-500 hover:bg-red-600 text-white text-xs"
        // onClick={(e)=> CloseModelHandel(openItem.requisition_item_id) }
        onClick={(e)=> {
            dispatch(setCloseModel(true))
            dispatch(setCloseId(openItemVal.requisition_item_id))
        }}
    >
        Close
    </button>
}

                                                                        {
                                                                            openItemVal.purchase_order_quantity == 0 &&
                                                                            <button
                                                                                type="button"
                                                                                className="px-1 py-1 ml-2 bg-lime-500 hover:bg-lime-600 text-white text-xs"
                                                                                onClick={(e)=> {
                                                                                    openConfirmModal({
                                                                                        title: (t('SendBackThisClaimedItem')),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureSendBackThisClaimedItem')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                        confirmProps: {color: 'green'},
                                                                                        // cancelProps: { color: 'red' },
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            dispatch(getClaimItemSendBack(openItemVal.requisition_item_id))
                                                                                            dispatch(setLoading(true))
                                                                                            setTimeout(() => {
                                                                                                showNotification({
                                                                                                    id: 'load-data',
                                                                                                    loading: true,
                                                                                                    title: (t('DataSendbackSuccessfully')),
                                                                                                    message: (t('DataLoad')),
                                                                                                    autoClose: 1000,
                                                                                                    disallowClose: true,
                                                                                                    color: 'green',
                                                                                                });
                                                                                                dispatch(openItem({
                                                                                                    project_id : projectData,
                                                                                                    keyword : keywordData
                                                                                                }))
                                                                                            }, 1000);
                                                                                        }
                                                                                        ,
                                                                                    });
                                                                                }}
                                                                            >{t('SendBack')}</button>

                                                                        }
                                                                    </td>
                                                                </tr>
                                                                )
                                                            }) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br/>
                                                </section>
                                            </div>
                                        </div>
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                </div>
            {closeModel?<CloseOpenItemModel />:''}
        </>
    )
}

export default OpenItemInformation