import React, {useEffect} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiArrowCircleLeft,
    HiOutlineChartSquareBar,
    HiOutlineExclamation, HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiTrash,HiArrowCircleRight
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {
    ActionIcon,
    Group,
    Text,
    ScrollArea,
    Select,
    TextInput,
    Textarea,
    Grid,
    Button,
    useMantineTheme
} from "@mantine/core";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useParams} from "react-router";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";

function EditBudget() {
    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const token = localStorage.getItem('user_token');

    const [spinner, setSpinner] = useState(false);
    const [projectData, setProjectData] = useState(null);
    const [coordinatorData, setCoordinatorData] = useState(null);
    const [engineerData, setEngineerData] = useState(null);
    const [nameOfWork, setNameOfWork] = useState('');
    const [budgetAmount, setBudgetAmount] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [path, setPath] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [descriptionData, setDestriptionData] = useState('');
    const [isSubmit, setSubmit] = useState(false);
    const [budgetItemData, setBudgetItemData] = useState([]);
    const [refreshItem, setRefreshItem] = useState(false);
    const [itemTotalAmount,setItemTotalAmount] = useState(0)
    const [isOld,setIsOld] = useState(0)

    // GET PURCHASE REQUISITION DETAILS
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/details/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setProjectData(res.data.data.project);
                setPath(res.data.data.path);
                setNameOfWork(res.data.data.name_of_work);
                setCoordinatorData(res.data.data.project_coordinator);
                setEngineerData(res.data.data.project_engineer);
                setBudgetAmount(res.data.data.net_total);
                setStartDate(res.data.data.budget_start_date ? new Date(res.data.data.budget_start_date) : null);
                setEndDate(res.data.data.budget_end_date ? new Date(res.data.data.budget_end_date) : null);
                setDestriptionData(res.data.data.comment ? res.data.data.comment : null);
                setBudgetItemData(res.data.data.budget_item);
                setItemTotalAmount(res.data.data.total_item_amount);
                setIsOld(res.data.data.is_old)


                setValue('projectName', res.data.data.project ? res.data.data.project : '')
                setValue('workName', res.data.data.name_of_work ? res.data.data.name_of_work : '')
                setValue('projectCoordinator', res.data.data.project_coordinator ? res.data.data.project_coordinator : '')
                setValue('projectEngineer', res.data.data.project_engineer ? res.data.data.project_engineer : '')
                setValue('budgetAmount', res.data.data.total_item_amount ? res.data.data.total_item_amount : '')
                setValue('budgetStartDate', res.data.data.budget_start_date ? new Date(res.data.data.budget_start_date) : '')
                setValue('budgetEndDate', res.data.data.budget_end_date ? new Date(res.data.data.budget_end_date) : '')
            }
        });
    }, [refreshItem]);
    // GET PURCHASE REQUISITION DETAILS

    // start get project dropdown
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'label': type.projects_name, 'value': type.id
            })
        })

    // on change project data get
    const ProjectDataHandel = (e) => {
        setProjectData(e);
        setValue('projectName', e)
        setSubmit(true);
    }
    // End get project dropdown


    // START HANDEL NAME OF WORK
    const NameOfWorkHandel = (e) => {
        setNameOfWork(e);
        setValue('workName', e)
        setSubmit(true);
    }
    // END HANDEL NAME OF WORK


    // start get project coordinator & engineer dropdown
    const [users, setUser] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/head/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setUser(res.data.data);
            });
    }, []);

    let coordinatorEngineerDropdown =
        users.map((type, index) => {
            return ({
                'label': type.username?type.username:type.email, 'value': type.id
            })
        })

    // on change project coordinator data get
    const CoordinatorDataHandel = (e) => {
        setCoordinatorData(e);
        setValue('projectCoordinator', e)
        setSubmit(true);
    }

    // on change project engineer data get
    const EngineerDataHandel = (e) => {
        setEngineerData(e);
        setValue('projectEngineer', e)
        setSubmit(true);
    }
    // End get coordinator & engineer dropdown


    // Handel Budget amount
    const BudgetAmountHandel = (e) => {
        setBudgetAmount(e.target.value);
        setValue('budgetAmount', e.target.value)
        setSubmit(true);
    }
    //Handel budget amount

    // Handel Budget start date
    const StartDateHandel = (e) => {
        setStartDate(format(e, 'yyyy-MM-dd'));
        setValue('budgetStartDate', e)
        setSubmit(true);
    }
    //Handel budget start date

    // Handel Budget end date
    const EndDateHandel = (e) => {
        setEndDate(format(e, 'yyyy-MM-dd'));
        setValue('budgetEndDate', e)
        setSubmit(true);
    }
    //Handel budget end date

    // Handel Budget end date
    const DescriptionDataHandel = (e) => {
        setDestriptionData(e.target.value);
        setSubmit(true);
    }
    //Handel budget end date


    if (isSubmit) {
        const updateFormData = {
            project: projectData,
            name_of_work: nameOfWork,
            project_coordinator: coordinatorData,
            project_engineer: engineerData,
            // net_total: budgetAmount > 0 ? budgetAmount : 0,
            net_total: itemTotalAmount,
            budget_start_date: startDate && typeof startDate === "object" ? format(startDate, 'yyyy-MM-dd') : startDate,
            budget_end_date: endDate && typeof endDate === "object" ? format(endDate, 'yyyy-MM-dd') : endDate,
            comment: descriptionData
        };
        // console.log(updateFormData)

        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/edit/${id}`,
            updateFormData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    console.log(res)
                }
            );
    }


    const [updateBudgetItemAmount, setUpdateBudgetItemAmount] = useState(null);

    const UpdateBudgetItemAmount = (e, itemId) => {
        e.persist();
        setUpdateBudgetItemAmount({...updateBudgetItemAmount, [e.target.id]: e.target.value});
        const updateQuantityData = {
            amount: e.target.value
        };

        if (updateQuantityData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/budget-item/${itemId}/update`,
                updateQuantityData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 200) {
                            setRefreshItem(true);
                        }
                    }
                );
        }
        setRefreshItem(false);

    }

    // start get spend area dropdown
    const [spendArea, setSpendArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/spend-area/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setSpendArea(res.data.data);
            });
    }, []);

    let spendAreaDropdown =
        spendArea.map((type, index) => {
            return ({
                'label': type.titles, 'value': type.id
            })
        })


    // on change spend area data get
    const [spendAreaData, setSpendAreaData] = useState(null);
    const SpendAreaDataHandel = (e) => {
        setSpendAreaData(e);
    }
    // End get spend area dropdown


    // BUDGET ITEM AMOUNT
    const [itemValidateStatus, setItemValidateStatus] = useState(true);
    const [budgetItemAmount, setBudgetItemAmount] = useState(null);
    const BudgetItemAmountHandel = (e) => {
        setBudgetItemAmount(e.target.value);
    }
    // BUDGET ITEM AMOUNT

    const BudgetItemsInsert = (e) => {

        if (spendAreaData == '' || spendAreaData == null) {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('ValidationError')),
                message: t('BudgetFillup'),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        } else {
            if (budgetItemAmount == '' || budgetItemAmount == null) {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('ValidationError')),
                    message: t('BudgetAmountFillup'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                const itemFormData = {
                    budget: id,
                    spend_area: spendAreaData,
                    amount: budgetItemAmount,
                };
                axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/budget-item/create`,
                    itemFormData,
                    {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        if (res.data.status === 201) {
                            console.log('item insert')
                            setRefreshItem(true);
                            setBudgetItemAmount(0);
                            setSpendAreaData([]);
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: res.data.message,
                                autoClose: 3000,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    });
                setRefreshItem(false);
            }
        }
    }


    // DELETE BUDGET ITEM
    const BudgetItemDelete = (e, itemID) => {
        e.preventDefault();
        const thisClick = e.currentTarget;
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteRequisitionItem')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/budget-item/delete/${itemID}`,
                        {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 800,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setRefreshItem(true);
                            }
                        })
                )
            ,
        });
        setRefreshItem(false);
    }


    //Form validation
    const validationSchema = Yup.object().shape({
        projectName: Yup.string().required("Choose project"),
        workName: Yup.string().required("Choose name of work"),
        projectCoordinator: Yup.string().required("Choose project coordinator"),
        projectEngineer: Yup.string().required("Choose project engineer"),
        budgetAmount: Yup.string().required("Please added budget"),
        budgetStartDate: Yup.string().required("Choose start date"),
        budgetEndDate: Yup.string().required("Choose end date"),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            net_total: itemTotalAmount,
            status: 1
        };
        if (budgetItemData.length >0) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/final-update/${id}`,
                formData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 202) {
                            setSpinner(false);
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('BudgetCreate')),
                                message: (t('DataLoad')),
                                autoClose: 800,
                                disallowClose: true,
                                color: 'green',
                            });
                            navigate('/budget/');
                        }
                    }
                );
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Error')),
                message: 'Please Added Budget Items',
                autoClose: 700,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    return (
        <>
            <main className="flex flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('NewBudget')}
                                                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('AllBudgetRelatedInformation')}</sub></span>
                                                    </div>
                                                </div>

                                                <div className={"right flex mr-8"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <Link to='/pre-requisition/' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar size={16} className={'mr-1'}></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/pre-requisition/' className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle size={16}></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden w-full'}>


                                                    <div className="w-full mr-0 mx-auto border">

                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                            <ScrollArea style={{height: fullFormHeight}}>
                                                                <div className="mx-auto p-2 justify-items-center">
                                                                    <section className="">
                                                                        <Grid>
                                                                            <Grid.Col md={12}>
                                                                                <div className={' sm:border-gray-200'}>
                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="projectName"
                                                                                               className="form-input-sm-label-required">{t('ProjectName')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.projectName?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16}
                                                                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.projectName?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                placeholder="Choose Project"
                                                                                                data={projectDropdown}
                                                                                                onChange={ProjectDataHandel}
                                                                                                value={projectData}
                                                                                                searchable
                                                                                                allowDeselect
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid.Col>

                                                                            <Grid.Col md={6} lg={6}>
                                                                                <div className={' sm:border-gray-200'}>
                                                                                    {/* Name Of Work */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="workName" className="form-input-sm-label-required">{t('NameOfWork')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.workName?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.workName?.message}
                                                                                            </div>}
                                                                                            {/*<TextInput
                                                                                                {...register("workName")}
                                                                                                onBlur={NameOfWorkHandel}
                                                                                                placeholder={t("EnterNameOfWork")}
                                                                                                defaultValue ={nameOfWork ? nameOfWork : ''}
                                                                                                withAsterisk
                                                                                            />*/}

                                                                                            <Select
                                                                                                {...register("workName")}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                placeholder="Choose name of work"
                                                                                                data={[
                                                                                                    'New work',
                                                                                                    'Maintenance work',
                                                                                                    'Extension work',
                                                                                                ]}
                                                                                                onChange={NameOfWorkHandel}
                                                                                                value={nameOfWork ? nameOfWork : ''}
                                                                                                searchable
                                                                                                allowDeselect
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* Project Coordinator */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="projectCoordinator"
                                                                                               className="form-input-sm-label-required">{t('ProjectCoordinator')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.projectCoordinator?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16}
                                                                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.projectCoordinator?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                placeholder="Choose Project Coordinator"
                                                                                                data={coordinatorEngineerDropdown}
                                                                                                onChange={CoordinatorDataHandel}
                                                                                                value={coordinatorData}
                                                                                                searchable
                                                                                                allowDeselect
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* Project Engineer */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="projectEngineer"
                                                                                               className="form-input-sm-label-required">{t('ProjectEngineer')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.projectEngineer?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16}
                                                                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.projectEngineer?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                placeholder="Choose Project Coordinator"
                                                                                                data={coordinatorEngineerDropdown}
                                                                                                onChange={EngineerDataHandel}
                                                                                                value={engineerData}
                                                                                                searchable
                                                                                                allowDeselect
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid.Col>

                                                                            <Grid.Col md={6} lg={6}>
                                                                                <div className={' sm:border-gray-200'}>

                                                                                    {/* Start Date Section */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="budgetStartDate"
                                                                                               className="form-input-sm-label-required">{t('StartDate')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.budgetStartDate?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16}
                                                                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.budgetStartDate?.message}
                                                                                            </div>}
                                                                                            <DatePicker
                                                                                                {...register("budgetStartDate")}
                                                                                                dropdownType="modal"
                                                                                                placeholder={t('ChooseDate')}
                                                                                                onChange={StartDateHandel}
                                                                                                value={startDate}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* End Date Section */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="budgetEndDate" className="form-input-sm-label-required">{t('EndDate')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.budgetEndDate?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16}
                                                                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.budgetEndDate?.message}
                                                                                            </div>}

                                                                                            <DatePicker
                                                                                                {...register("budgetEndDate")}
                                                                                                dropdownType="modal"
                                                                                                placeholder={t('ChooseDate')}
                                                                                                onChange={EndDateHandel}
                                                                                                value={endDate}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="mt-8 w-full items-center">
                                                                                        <label htmlFor="ref_no" className="form-input-sm-label">
                                                                                            {t('Created')} :
                                                                                            {localStorage.getItem('username') ? localStorage.getItem('username') : ''}
                                                                                            <br/>
                                                                                            {t('IssueDate')} :
                                                                                            {new Date().toLocaleString() + "" ? new Date().toLocaleString() + "" : ''}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid.Col>
                                                                        </Grid>

                                                                        <Grid>
                                                                            <Grid.Col md={12} lg={12}>
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="description" className="form-input-sm-label">{t('Description')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        <Textarea
                                                                                            {...register("description")}
                                                                                            placeholder="Description"
                                                                                            onBlur={DescriptionDataHandel}
                                                                                            defaultValue={descriptionData ? descriptionData : ''}
                                                                                            withAsterisk
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="description" className="form-input-sm-label">{t('AttachmentInformation')}</label>
    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
        <tbody>
        <tr className={"bg-gray-100"} key={'1'}>
            <td className="w-3/24 border-collapse border border-slate-400 text-center">

                <Dropzone
                    onDrop={(file) => {
                        setRefreshItem(false)

                        const imageData = {
                            pdfFile : file[0]
                        }
                         axios({
                            method: 'post',
                            url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/edit-attachment/${id}`,
                            headers: {
                                "Accept": `application/json`,
                                "Content-Type": `multipart/form-data`,
                                "Access-Control-Allow-Origin": '*',
                                "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                            },
                            data : imageData
                        })
                            .then(res => {
                                // if (res.status === 200){
                                    setRefreshItem(true)
                                // }
                            })
                            .catch(function (error) {
                                console.log(error)
                            })
                    }}
                    onReject={(files) =>{
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: 'Please select PDF file.',
                            message: (t('DataLoad')),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    }}
                    accept={[MIME_TYPES.pdf]}
                    multiple={false}                                >
                    <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <IconUpload
                                size="3.2rem"
                                stroke={1.5}
                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX
                                size="3.2rem"
                                stroke={1.5}
                            />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconPhoto size="3.2rem" stroke={1.5} />
                        </Dropzone.Idle>

                        <div>
                            <Text size="xl" inline>
                                Drag images here or click to select files
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                Drag PDF here or click to select files
                            </Text>
                        </div>
                    </Group>
                </Dropzone>
                { path &&
                    <>
                        <a title="Budget Attachment" target="_blank"
                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/budget/pdf-show/'+ id}`}
                           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                            Open Attachment
                        </a>
                    </>
                }
            </td>
        </tr>
        </tbody>
    </table>
                                                                                </div>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </section>
                                                                </div>
                                                                {/* Table At End Section */}
                                                                <div className=" mx-auto p-2 justify-items-center bg-indigo-100">
                                                                    <section className="p-1">
                                                                        <header className="relative mb-1 bg-indigo-400">
                                                                            <div className="text-white text-right sm:px-2 sm:py-1">
                                                                                <div className="text-md text-left font-bold items-center leading-7">  {t('NewBudgetItem')}</div>
                                                                            </div>
                                                                        </header>
                                                                        <Grid columns={12}>

                                                                            <Grid.Col span={2}>
                                                                            </Grid.Col>
                                                                            <Grid.Col span={5}>
                                                                                <Select
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    placeholder={t('ChooseBudgetHead')}
                                                                                    data={spendAreaDropdown}
                                                                                    onChange={SpendAreaDataHandel}
                                                                                    value={spendAreaData}
                                                                                    searchable
                                                                                    allowDeselect
                                                                                />
                                                                            </Grid.Col>

                                                                            <Grid.Col span={3}>
                                                                                <TextInput
                                                                                    {...register("budgetItemAmount")}
                                                                                    onChange={BudgetItemAmountHandel}
                                                                                    placeholder={t("EnterBudgetAmount")}
                                                                                    value={budgetItemAmount ? budgetItemAmount : ''}
                                                                                    withAsterisk
                                                                                />
                                                                            </Grid.Col>
                                                                            <Grid.Col span={2}>
                                                                                <button type="button"
                                                                                        className="inline-flex items-center h-8 ml-10 px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs" onClick={() => BudgetItemsInsert()}>
                                                                                    <HiOutlinePlus size={12}
                                                                                                   className={'mr-1'}></HiOutlinePlus>{t('AddItem')}
                                                                                </button>
                                                                            </Grid.Col>
                                                                        </Grid>


                                                                        <div className=" mx-auto justify-items-center bg-indigo-100 sm:px-2 sm:py-1">

                                                                            <Grid columns={12} className="bg-indigo-400 mt-2 justify-center h-full text-xs   text-white">
                                                                                <Grid.Col span={2}>
                                                                                    <div className="font-semibold text-left">{t('SL')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={5}>
                                                                                    <div className="font-semibold text-left">{t('BudgetHead')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <div className="font-semibold text-left">{t('BudgetAmount')}</div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                    <div className="font-semibold text-left">{t('Action')}</div>
                                                                                </Grid.Col>
                                                                            </Grid>

                                                                            {budgetItemData ? budgetItemData.map((item, index) => {
                                                                                return (
                                                                                    <Grid columns={12} className="mt-2  justify-center h-full table-fixed w-full text-xs font-semibold">
                                                                                        <Grid.Col span={2}>
                                                                                            {index+1}
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={5}>
                                                                                            {item.titles ? item.titles : null}
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={3}>
                                                                                            <TextInput
                                                                                                value={(updateBudgetItemAmount && updateBudgetItemAmount>0) ? updateBudgetItemAmount.index : item.amount}
                                                                                                onChange={e => {
                                                                                                    UpdateBudgetItemAmount(e, item.id)
                                                                                                }}
                                                                                                id={`${index}`}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={2}>
                                                                                            <ActionIcon color="red">
                                                                                                <HiTrash size={16}
                                                                                                         onClick={(e) => {
                                                                                                             BudgetItemDelete(e, item.id)
                                                                                                         }}/>
                                                                                            </ActionIcon>
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                )
                                                                            }):null}

                                                                            <Grid columns={12} className="mt-2  justify-center h-full table-fixed w-full text-xs font-semibold">
                                                                                <Grid.Col span={2}>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={5}>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <TextInput
                                                                                        {...register("budgetAmount")}
                                                                                        inputprops={{readOnly: true, disableUnderline: true}}
                                                                                        placeholder={t("EnterBudgetAmount")}
                                                                                        value={itemTotalAmount ? itemTotalAmount : 0}
                                                                                        withAsterisk
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={2}>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </ScrollArea>

                                                            <footer className="relative mt-1">
                                                                <div className="justify-items-center">
                                                                    <div className="text-right border-t pt-0.5 mb-0.5 pr-2">
                                                                        <Button
                                                                            onClick={() => navigate(-1)}
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                            <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                        <button type='submit'
                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                            {spinner ? (
                                                                                <AiOutlineLoading3Quarters
                                                                                    className={'mr-2 animate-spin'}
                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                            ) : (<HiArrowCircleRight size={16}
                                                                                         className={'mr-2'}></HiArrowCircleRight>)}
                                                                            <span>{t('Submit')}</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </footer>
                                                        </form>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default EditBudget