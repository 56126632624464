import React, {useRef} from "react";
import {
    HiDocumentText, HiOutlineChartSquareBar,
    HiOutlineDeviceMobile, HiOutlineExclamation, HiOutlineExclamationCircle,
    HiOutlineKey,
    HiOutlineMail,
    HiOutlineOfficeBuilding,
    HiOutlinePhone, HiOutlinePlus,
    HiOutlineUserAdd
} from "react-icons/hi";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import { useState } from "react";
import {MdAccountBalanceWallet} from "react-icons/md";
import {Link} from "react-router-dom";
import ModalForm from "../../Html/Modal/ModalForm";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useTranslation} from "react-i18next";

function Dashboard(){
    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height-63);
    var fromHeight = height-153+"px";
    return(
        <>
            <main className="flex flex-1 flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('MasterData')} 
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllMasterDataRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2 mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                            </button>

                                                            <Link to='/master-data/item' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/master-data/item'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden -ml-4'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full px-6 py-2">
                                                                {t('MasterData')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}
export default Dashboard