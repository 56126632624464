import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { ScrollArea} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {
    poItemLastPrice,
    setItemPriceModel,
    setItemPriceModelItemID
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";

function ItemPriceView() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = '400px';
    const [loading, setLoading] = useState(true);
    const itemPriceModelItemID = useSelector((state) => state.purchaseOrderReducer.itemPriceModelItemID)
    const itemLastPriceDetails = useSelector((state) => state.purchaseOrderReducer.itemLastPriceDetails)

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(poItemLastPrice(itemPriceModelItemID))
            setLoading(false)
        },500)
    },[])

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('LastItemDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setItemPriceModel(false))
                                        dispatch(setItemPriceModelItemID(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                {loading ?
                                                    <>
                                                        <Skeleton height={50} mt={20} circle mb="xl"/>
                                                        <Skeleton height={8} radius="xl"/>
                                                        <Skeleton height={8} mt={6} radius="xl"/>
                                                        <Skeleton height={8} mt={6} width="70%" radius="xl"/>

                                                        <Skeleton height={50} mt={20} circle mb="xl"/>
                                                        <Skeleton height={8} radius="xl"/>
                                                        <Skeleton height={8} mt={6} radius="xl"/>
                                                        <Skeleton height={8} mt={6} width="70%" radius="xl"/>

                                                    </>
                                                    :
                                                    <>

                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        {
                                                                            itemLastPriceDetails && itemLastPriceDetails.buyers &&
                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">

                       <>
                           <div className="flex items-center">
                               <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BuyerInformation')}</h4>
                               <div className="flex-1 border-t-2 border-gray-200"/>
                           </div>
                           <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                               {
                                   itemLastPriceDetails.full_name &&
                                   <li className="flex items-start lg:col-span-1">
                                       <p className="ml-3 text-sm text-gray-700">
                                           <span className="font-bold">{t('Name')} : </span>
                                           {itemLastPriceDetails.full_name}
                                       </p>
                                   </li>
                               }
                               {
                                   itemLastPriceDetails.email &&
                                   <li className="flex items-start lg:col-span-1">
                                       <p className="ml-3 text-sm text-gray-700">
                                           <span className="font-bold">{t('Email')} : </span>
                                           {itemLastPriceDetails.email}
                                       </p>
                                   </li>
                               }
                               {
                                   itemLastPriceDetails.cellphone &&
                                   <li className="flex items-start lg:col-span-1">
                                       <p className="ml-3 text-sm text-gray-700">
                                           <span className="font-bold">{t('Phone')} : </span>
                                           {itemLastPriceDetails.cellphone}
                                       </p>
                                   </li>
                               }
                           </ul>
                       </>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }

                                                                        {
                                                                            itemLastPriceDetails && itemLastPriceDetails.vendors &&
                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">

                                                                                            <>
                                                                                                <div className="flex items-center">
                                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorInformation')}</h4>
                                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                                </div>
                                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                                    {
                                                                                                        itemLastPriceDetails.vendors_name &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('Name')} : </span>
                                                                                                                {itemLastPriceDetails.vendors_name}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                    {
                                                                                                        itemLastPriceDetails.vendors_address &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('Address')} : </span>
                                                                                                                {itemLastPriceDetails.vendors_address}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                    {
                                                                                                        itemLastPriceDetails.contract_person &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('ContactPerson')} : </span>
                                                                                                                {itemLastPriceDetails.contract_person}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                    {
                                                                                                        itemLastPriceDetails.vendor_email &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('Email')} : </span>
                                                                                                                {itemLastPriceDetails.vendor_email}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                </ul>
                                                                                            </>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }

                                                                        {
                                                                            itemLastPriceDetails && itemLastPriceDetails.items_name &&
                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">

                                                                                            <>
                                                                                                <div className="flex items-center">
                                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemInformation')}</h4>
                                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                                </div>
                                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                                    {
                                                                                                        itemLastPriceDetails.items_name &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('ItemName')} : </span>
                                                                                                                {itemLastPriceDetails.items_name}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                                        <p className="ml-3 text-sm text-gray-700">
                                                                                                            <span className="font-bold">{t('Quantity')} : </span>
                                                                                                            1
                                                                                                        </p>
                                                                                                    </li>
                                                                                                    {
                                                                                                        itemLastPriceDetails.price &&
                                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                                            <p className="ml-3 text-sm text-gray-700">
                                                                                                                <span className="font-bold">{t('UnitPrice')} : </span>
                                                                                                                {itemLastPriceDetails.price}
                                                                                                            </p>
                                                                                                        </li>
                                                                                                    }
                                                                                                </ul>
                                                                                            </>

                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                                    </>
                                                }

                                            </ScrollArea>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

        </>
    )
}

export default ItemPriceView