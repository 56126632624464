import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

import {useDispatch, useSelector} from "react-redux";
import {
    setLoading,
    inlineUpdatePO,
    getPurchaseOrderDetails,
    setProcessMode,
    setVendor,
    setPurchaseType,
    setPaymentType,
    setPaymentFrom,
    setComputationStatus,
    inlineUpdatePOItem,
    setPoItemInlineUpdate,
    setPoItemInlineUpdateID,
    inlineAttachUpdatePO,
    setBuyer,
    setItemPriceModel,
    setItemPriceModelItemID,
    getClaimItemSendBack,
    openItem,
    prItemClose,
    setPoViewModel,
    setPoViewID,
    amendmentItemDelete, setEmptyPODetails
} from "../../../../store/purchaseOrder/purchaseOrderSlice";
import {
    Group,
    Loader,
    MultiSelect,
    Select,
    Text,
    TextInput,
    useMantineTheme,
    Textarea, ActionIcon
} from "@mantine/core";

import {format} from "date-fns";
import {HiEye, HiOutlineExclamation, HiTrash} from "react-icons/hi";
import {purchaseTypeDropdown} from "../../../../store/purchase-type/purchaseTypeSlice";
import  {masterDataDropdown} from "../../../../store/master-data/masterDataSlice";
import  {vendorDropdown} from "../../../../store/vendor/vendorSlice";
import {showNotification} from "@mantine/notifications";

import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import {Dropzone, MIME_TYPES} from '@mantine/dropzone';
import {buyerDropdown} from "../../../../store/user/userSlice";
import ItemPriceView from "../ItemPriceView";
import dayjs from "dayjs";
import {openConfirmModal} from "@mantine/modals";
import {DatePicker} from "@mantine/dates";
import {ReceiveInlineUpdate} from "../../../../store/receivedItem/receivedItemSlice";
import {budgetDelete} from "../../../../store/budget/budgetSlice";

function PurchaseOrderAmendmentformation(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const theme = useMantineTheme();
    const {register,setValue,setError,errors,purchaseOrderId} = props

    const purchaseTypeDropdownData = useSelector((state) => state.purchaseTypeReducer.purchaseTypeDropdown)
    const paymentTypeDropdownData = useSelector((state) => state.masterDataReducer.paymentTypeDropdown)
    const paymentFromDropdownData = useSelector((state) => state.masterDataReducer.paymentFromDropdown)
    const paymentMethodDropdownData = useSelector((state) => state.masterDataReducer.paymentMethodDropdown)
    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)
    const purchaseOrderDetails = useSelector((state) => state.purchaseOrderReducer.purchaseOrderDetails)
    const purchaseOrderItem = useSelector((state) => state.purchaseOrderReducer.purchaseOrderItem)
    // console.log(purchaseOrderDetails.amendment_status,purchaseOrderItem)
    const purchaseOrderTermConditions = useSelector((state) => state.purchaseOrderReducer.purchaseOrderTermConditions)
    const processMode = useSelector((state) => state.purchaseOrderReducer.processMode)
    const vendor = useSelector((state) => state.purchaseOrderReducer.vendor)
    const purchaseType = useSelector((state) => state.purchaseOrderReducer.purchaseType)
    const paymentType = useSelector((state) => state.purchaseOrderReducer.paymentType)
    const paymentFrom = useSelector((state) => state.purchaseOrderReducer.paymentFrom)
    const computationStatus = useSelector((state) => state.purchaseOrderReducer.computationStatus)
    const message = useSelector((state) => state.purchaseOrderReducer.message)
    const poItemInlineUpdate = useSelector((state) => state.purchaseOrderReducer.poItemInlineUpdate)
    const poItemInlineUpdateID = useSelector((state) => state.purchaseOrderReducer.poItemInlineUpdateID)
    const isLoading = useSelector((state) => state.purchaseOrderReducer.isLoading)
    const purchaseTypeName = useSelector((state) => state.purchaseOrderReducer.purchaseTypeName)
    const buyerDropdownData = useSelector((state) => state.userReducer.buyerDropdownData)
    const buyer = useSelector((state) => state.purchaseOrderReducer.buyer)
    const itemPriceModel = useSelector((state) => state.purchaseOrderReducer.itemPriceModel)
    const [deliveryDate,setDeliveryDate] = useState(null)

    useEffect(()=>{
        dispatch(setEmptyPODetails())
        dispatch(purchaseTypeDropdown())
        dispatch(masterDataDropdown('payment-type'))
        dispatch(masterDataDropdown('payment-from'))
        dispatch(masterDataDropdown('payment-method'))
        dispatch(vendorDropdown())
        dispatch(buyerDropdown())
    },[])

    useEffect(()=>{
        dispatch(
            getPurchaseOrderDetails({
                po_id : purchaseOrderId
            })
        )
    },[processMode,vendor,purchaseType,paymentType,paymentFrom,computationStatus,poItemInlineUpdate,isLoading,buyer])

    if (purchaseOrderDetails && purchaseOrderDetails.po_nonpo){
        setValue('po_nonpo',purchaseOrderDetails.po_nonpo)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.ref_no){
        setValue('ref_no',purchaseOrderDetails.ref_no)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.date_of_delivered){
        setValue('date_of_delivered',purchaseOrderDetails.date_of_delivered)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.payment_type){
        setValue('payment_type',purchaseOrderDetails.payment_type)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.payment_from){
        setValue('payment_from',purchaseOrderDetails.payment_from)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.computation_status){
        setValue('computation_status',purchaseOrderDetails.computation_status)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.payment_method){
        setValue('payment_method',purchaseOrderDetails.payment_method)
    }

    if (purchaseOrderDetails && purchaseOrderDetails.vendors){
        setValue('vendors',purchaseOrderDetails.vendors)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.buyers){
        setValue('buyers',purchaseOrderDetails.buyers)
    }
    if (purchaseOrderDetails && purchaseOrderDetails.vat_mode){
        setValue('vat_mode',purchaseOrderDetails.vat_mode)
    }

    let subTotalAmount = 0;
    let totalAitAmount = 0;
    let totalVatAmount = 0;
    let totalAmount = 0;


    return(
        <>
            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                <div className=" w-1/3 items-center">
                                    <label htmlFor="process_mode" className="form-input-sm-label-required">{t('ProcessMode')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        <Select
                                            {...register("process_mode")}
                                            placeholder={t('ChoosePurchaseType')}
                                            className={`${errors.process_mode ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            nothingFound="No options"
                                            data={[
                                                // { value: 'Quotation', label: 'Quotation' },
                                                { value: 'Work-Order', label: 'Work Order' },
                                            ]}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={(e)=>{
                                                setValue('process_mode',e)
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "process_mode",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setProcessMode(e))
                                                if (e === 'Quotation'){
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendors",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendor_quotation_reference_number",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "path",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "path_two",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(setVendor(null))
                                                }
                                            }}
                                            value={purchaseOrderDetails && processMode ? processMode:null}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="po_nonpo" className="form-input-sm-label-required">{t('PurchaseType')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.po_nonpo?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.po_nonpo?.message}
                                        </div>}

                                        <Select
                                            {...register("po_nonpo")}
                                            placeholder={t('ChoosePurchaseType')}
                                            className={`${errors.po_nonpo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            searchable
                                            allowDeselect
                                            nothingFound="No options"
                                            data={purchaseTypeDropdownData}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            value={purchaseType ? Number(purchaseType) : Number(purchaseOrderDetails.po_nonpo)}
                                            onChange={(e)=>{
                                                setValue('po_nonpo',e)
                                                setError("po_nonpo", null);
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "po_nonpo",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setPurchaseType(e))
                                                if (e === 10 ){
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendors",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendor_quotation_reference_number",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "path",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "path_two",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(setVendor(null))
                                                }else {
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "buyers",
                                                            value : null,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                    dispatch(setBuyer(null))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vat_mode" className="form-input-sm-label-required">{t('VatMode')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.vat_mode?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.vat_mode?.message}
                                        </div>}

                                        <Select
                                            {...register("vat_mode")}
                                            placeholder={t('ChooseVatMode')}
                                            className={`${errors.vat_mode ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            searchable
                                            allowDeselect
                                            nothingFound="No options"
                                            data={purchaseOrderDetails.is_received=='yes'?[purchaseOrderDetails.vat_mode]:['Including','Excluding']}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            value={purchaseOrderDetails.vat_mode}
                                            onChange={(e)=>{
                                                setValue('vat_mode',e)
                                                setError("vat_mode", null);
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "vat_mode",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setLoading(true))
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                (processMode === 'Work-Order' && purchaseTypeName != 'PROJECT PURCHASE') &&

                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="vendor"
                                               className="form-input-sm-label-required">{t('Vendor')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {(errors.vendors && errors.vendors != null && errors.vendors != '' && errors.vendors.message !='' && errors.vendors.message !=null ) &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.vendors?.message}
                                            </div>}

                                            <Select
                                                {...register("vendors")}
                                                placeholder={t('ChooseVendor')}
                                                className={`${errors.vendors ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                searchable clearable

                                                nothingFound="No options"
                                                data={vendorDropdownData}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                value={purchaseOrderDetails && vendor ? Number(vendor) :null}
                                                onChange={(e) => {
                                                    setValue('vendors', e)
                                                    setError("vendors", null)
                                                    dispatch(setVendor(e))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendors",
                                                            value : e,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center"></div>
                                    <div className=" w-1/3 items-center"></div>

                                    {
                                            ( purchaseOrderDetails) &&
                                            <>

                                                {
                                                    (purchaseOrderDetails.contract_person)  &&
                                                    <div className=" w-1/3 items-center">
                                                        <label htmlFor="vendor" className="form-input-sm-label">{t('ContactPerson')} : {purchaseOrderDetails.contract_person}</label>
                                                    </div>
                                                }
                                                {

                                                    (purchaseOrderDetails.contract_no) &&
                                                    <div className=" w-1/3 items-center">
                                                        <label htmlFor="vendor" className="form-input-sm-label">{t('Mobile')} : {purchaseOrderDetails.contract_no}</label>
                                                    </div>
                                                }
                                                {
                                                    (purchaseOrderDetails.email) &&
                                                    <div className=" w-1/3 items-center">
                                                        <label htmlFor="vendor" className="form-input-sm-label">{t('Email')} : {purchaseOrderDetails.email}</label>
                                                    </div>
                                                }
                                                {
                                                    (purchaseOrderDetails.vendors_address) &&
                                                    <div className=" w-full items-center">
                                                        <label htmlFor="vendor" className="form-input-sm-label">{t('Address')} : {purchaseOrderDetails.vendors_address}</label>
                                                    </div>
                                                }
                                            </>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                (processMode === 'Work-Order' && purchaseTypeName === 'PROJECT PURCHASE') &&

                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="buyers" className="form-input-sm-label-required">{t('Buyer')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {(errors.buyers && errors.buyers.message !='' && errors.buyers.message !=null) &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.buyers?.message}
                                            </div>}

                                            <Select
                                                {...register("buyers")}
                                                placeholder={t('ChooseBuyer')}
                                                className={`${errors.buyers ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                searchable clearable
                                                nothingFound="No options"
                                                data={buyerDropdownData}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                value={purchaseOrderDetails && buyer ? Number(buyer) :null}
                                                onChange={(e) => {
                                                    setValue('buyers', e)
                                                    setError("buyers", null)
                                                    dispatch(setBuyer(e))
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "buyers",
                                                            value : e,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center"></div>
                                    <div className=" w-1/3 items-center"></div>

                                    {
                                        ( purchaseOrderDetails) &&
                                        <>

                                            {
                                                (purchaseOrderDetails.buyer_email)  &&
                                                <div className=" w-1/3 items-center">
                                                    <label htmlFor="vendor" className="form-input-sm-label">{t('Email')} : {purchaseOrderDetails.buyer_email}</label>
                                                </div>
                                            }
                                            {

                                                (purchaseOrderDetails.buyer_cellphone) &&
                                                <div className=" w-1/3 items-center">
                                                    <label htmlFor="vendor" className="form-input-sm-label">{t('Mobile')} : {purchaseOrderDetails.buyer_cellphone}</label>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="issue_date" className="form-input-sm-label-required">{t('IssueDate')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.issue_date?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.issue_date?.message}
                                        </div>
                                        }

                                        <input
                                            {...register("issue_date")}
                                            type="date"
                                            className={`form-input-sm-control ${errors.issue_date ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.created_date ? format(new Date(purchaseOrderDetails.created_date), 'yyyy-MM-dd') : null}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="first_name" className="form-input-sm-label-required">{t('RefNo')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.ref_no?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.ref_no?.message}
                                        </div>}

                                        <input
                                            {...register("ref_no")}
                                            type="text"
                                            className={`form-input-sm-control ${errors.ref_no ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            placeholder={t("EnterRefNo")}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.ref_no ? purchaseOrderDetails.ref_no : null}
                                            onBlur={(e)=>{
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "ref_no",
                                                        value : e.target.value,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="DateOfDelivery" className="form-input-sm-label">{t('DateOfDelivery')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.date_of_delivered?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.date_of_delivered?.message}
                                        </div>
                                        }
                                        <DatePicker
                                            {...register('date_of_delivered')}
                                            value={deliveryDate?deliveryDate:(purchaseOrderDetails && purchaseOrderDetails.date_of_delivered ? new Date(purchaseOrderDetails.date_of_delivered) : null)}
                                            onChange={(e)=>{
                                                setDeliveryDate(format(e,'yyyy-MM-dd'))
                                                setValue('date_of_delivered',format(e,'yyyy-MM-dd'))
                                                setError("date_of_delivered", null);
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "date_of_delivered",
                                                        value : format(e,'yyyy-MM-dd'),
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>

                                {/*<div className=" w-1/3 items-center">
                                    <label htmlFor="issue_date" className="form-input-sm-label-required">{t('DateOfDelivery')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.date_of_delivered?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.date_of_delivered?.message}
                                        </div>
                                        }

                                        <input
                                            {...register("date_of_delivered")}
                                            type="date"
                                            className={`form-input-sm-control ${errors.date_of_delivered ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.date_of_delivered ? format(new Date(purchaseOrderDetails.date_of_delivered), 'yyyy-MM-dd') : null}

                                            onChange={(e)=>{
                                                setValue('date_of_delivered',e.target.value)
                                                setError("date_of_delivered", null);
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "date_of_delivered",
                                                        value : e.target.value,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>*/}

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="payment_type" className="form-input-sm-label-required">{t('PaymentType')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.payment_type?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.payment_type?.message}
                                        </div>}

                                        <Select
                                            {...register("payment_type")}
                                            placeholder={t('SelectPaymentType')}
                                            className={`${errors.payment_type ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            data={paymentTypeDropdownData}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            value={paymentType ? paymentType : purchaseOrderDetails.payment_type}
                                            onChange={(e)=>{
                                                setValue('payment_type',e)
                                                setError("payment_type", null);
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "payment_type",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setPaymentType(e))
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className=" w-1/3 items-center">
                                    <label htmlFor="payment_from" className="form-input-sm-label-required">{t('PaymentFrom')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.payment_from?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.payment_from?.message}
                                        </div>}

                                        <Select
                                            {...register("payment_from")}
                                            placeholder={t('ChoosePaymentFrom')}
                                            className={`${errors.payment_from ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            data={paymentFromDropdownData}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            value={paymentFrom ? Number(paymentFrom) : Number(purchaseOrderDetails.payment_from)}
                                            onChange={(e)=>{
                                                setValue('payment_from',e)
                                                setError('payment_from',null)
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "payment_from",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setPaymentFrom(e))
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className=" w-1/3 items-center">
                                    <label htmlFor="computation_status" className="form-input-sm-label-required">{t('CompetitionStatus')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        {errors.computation_status?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.computation_status?.message}
                                        </div>}

                                        <Select
                                            {...register("computation_status")}
                                            placeholder={t('ChooseCompetitionStatus')}
                                            className={`${errors.computation_status ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            value={computationStatus ? Number(computationStatus) : Number(purchaseOrderDetails.computation_status)}
                                            data={[
                                                { value: 1, label: 'Yes' },
                                                { value: 2, label: 'No' },
                                            ]}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={(e)=>{
                                                setValue('computation_status',e)
                                                setError('computation_status',null)
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "computation_status",
                                                        value : e,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                                dispatch(setComputationStatus(e))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                processMode === 'Quotation' &&

                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-full items-center">
                                        <label htmlFor="vendor" className="form-input-sm-label-required">{t('Vendor')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {errors.vendor?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.vendor?.message}
                                            </div>}

                                            <MultiSelect
                                                {...register("vendor")}
                                                placeholder={t('ChooseVendor')}
                                                className={`${errors.vendor ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                searchable clearable

                                                nothingFound="No options"
                                                data={vendorDropdownData}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={(e)=>{
                                                    setValue('vendor',e)
                                                    if (e == '' || e == null){
                                                        setError("vendor", Yup.array().required(t("ChooseVendor")).nullable());
                                                    }else {
                                                        setError("vendor", null);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                processMode === 'Work-Order' &&
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="vendor_quotation_reference_number" className="form-input-sm-label">{t('VendorQuotationRef')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            <input
                                                {...register("vendor_quotation_reference_number")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.vendor_quotation_reference_number ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                placeholder={t("EnterVendorQuotationRef")}
                                                defaultValue={purchaseOrderDetails && purchaseOrderDetails.vendor_quotation_reference_number ? purchaseOrderDetails.vendor_quotation_reference_number : null}
                                                onBlur={(e)=>{
                                                    dispatch(inlineUpdatePO(
                                                        {
                                                            field_name: "vendor_quotation_reference_number",
                                                            value : e.target.value,
                                                            po_id : purchaseOrderId
                                                        }
                                                    ))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="vendor_quotation_reference_number" className="form-input-sm-label">{t('VendorQuotationAttach')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            <Dropzone
                                                onDrop={(file) => {
                                                    const data = {
                                                        field_name : "path",
                                                        value : file[0],
                                                        po_id : purchaseOrderId
                                                    }
                                                    dispatch(inlineAttachUpdatePO(data))
                                                    dispatch(setLoading(true))
                                                }}
                                                onReject={(files) =>{
                                                    showNotification({
                                                        id: 'load-data',
                                                        loading: true,
                                                        title: 'Please select PDF file.',
                                                        message: (t('DataLoad')),
                                                        autoClose: 1000,
                                                        disallowClose: true,
                                                        color: 'red',
                                                    });
                                                }}
                                                accept={[MIME_TYPES.pdf]}
                                                multiple={false}
                                            >
                                                <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                    <Dropzone.Accept>
                                                        <IconUpload
                                                            stroke={1.5}
                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                        />
                                                    </Dropzone.Accept>
                                                    <Dropzone.Reject>
                                                        <IconX
                                                            stroke={1.5}
                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                        />
                                                    </Dropzone.Reject>
                                                    <Dropzone.Idle>
                                                        <IconPhoto  stroke={1.5} />
                                                    </Dropzone.Idle>

                                                    <div>
                                                        <Text size="sm" inline>
                                                            {
                                                                (purchaseOrderDetails && purchaseOrderDetails.path) ?
                                                                    purchaseOrderDetails.path.split("/").pop() :
                                                                    'Drag PDF here or click to select files'
                                                            }
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </Dropzone>
                                            {
                                            (purchaseOrderDetails && purchaseOrderDetails.path) &&
                                                <>
                                                <a
                                                    title={t('VendorQuotationAttach')}
                                                    target="_blank"
                                                    href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ purchaseOrderId}/vendor-quotation`}
                                                    className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                            </>
                                            }
                                        </div>
                                    </div>


                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="vendor_quotation_reference_number" className="form-input-sm-label">{t('ComparisonAttach')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            <Dropzone
                                                onDrop={(file) => {
                                                    const data = {
                                                        field_name : "path_two",
                                                        value : file[0],
                                                        po_id : purchaseOrderId
                                                    }
                                                    dispatch(inlineAttachUpdatePO(data))
                                                    dispatch(setLoading(true))
                                                }}
                                                onReject={(files) =>{
                                                    showNotification({
                                                        id: 'load-data',
                                                        loading: true,
                                                        title: 'Please select PDF file.',
                                                        message: (t('DataLoad')),
                                                        autoClose: 1000,
                                                        disallowClose: true,
                                                        color: 'red',
                                                    });
                                                }}
                                                accept={[MIME_TYPES.pdf]}
                                                multiple={false}
                                            >
                                                <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                    <Dropzone.Accept>
                                                        <IconUpload
                                                            stroke={1.5}
                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                        />
                                                    </Dropzone.Accept>
                                                    <Dropzone.Reject>
                                                        <IconX
                                                            stroke={1.5}
                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                        />
                                                    </Dropzone.Reject>
                                                    <Dropzone.Idle>
                                                        <IconPhoto  stroke={1.5} />
                                                    </Dropzone.Idle>

                                                    <div>
                                                        <Text size="sm" inline>
                                                            {
                                                                (purchaseOrderDetails && purchaseOrderDetails.path_two) ?
                                                                    purchaseOrderDetails.path_two.split("/").pop() :
                                                                    'Drag PDF here or click to select files'
                                                            }
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </Dropzone>
                                            {
                                                (purchaseOrderDetails && purchaseOrderDetails.path_two) &&
                                                    <>
                                                <a
                                                    title={t('ComparisonAttach')}
                                                    target="_blank"
                                                    href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ purchaseOrderId}/comparison`}
                                                    className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                                </>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {
                (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length >0 && purchaseOrderDetails.vat_mode == 'Including' ) &&
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className="mx-auto justify-items-right w-full">
                                        <section className="-mx-0.5">
                                            <div className="flex flex-col justify-center h-full">
                                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                    <tr>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('SL')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('PRRef')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Particulars')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Project')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Brand')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Quantity')}</div>
                                                        </th>

                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('UnitPrice')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('SubTotal')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400 text-center" colSpan={3}>
                                                            <div className="font-semibold ">{t('Vat')}</div>
                                                        </th>
                                                        {/*<th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('VatAmount')}</div>
                                                        </th>*/}
                                                        <th className="p-2 border-collapse border border-slate-400 text-center" colSpan={3}>
                                                            <div className="font-semibold ">{t('Ait')}</div>
                                                        </th>
                                                        {/*<th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('AitAmount')}</div>
                                                        </th>*/}
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('UnitTotal')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('TotalAmount')}</div>
                                                        </th>
                                                        <th align={`right`} className="p-2"  rowSpan={2}>

                                                        </th>
                                                    </tr>

                                                    <tr>

                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('')}%</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Total')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('')}%</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Total')}</div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length >0) && purchaseOrderItem.map((orderItem, index) => {
                                                            totalAmount = (totalAmount + Number(orderItem.amount))
                                                            subTotalAmount = (subTotalAmount + Number(orderItem.sub_total))
                                                            totalAitAmount = (totalAitAmount + Number(orderItem.total_ait_amount))
                                                            totalVatAmount = (totalVatAmount + Number(orderItem.total_vat_amount))
                                                            return (
                                                                <tr className={Number(orderItem.received_quantity) > 0?"bg-rose-100":"bg-gray-100"} key={index}>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {++index}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {orderItem.pr_number?orderItem.pr_number:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{orderItem.items_name?orderItem.items_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {orderItem.item_unit_name?orderItem.item_unit_name:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{orderItem.projects_name?orderItem.projects_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                    <TextInput
                                                                        placeholder="Brand"
                                                                        name="brand"
                                                                        className="w-1/2"
                                                                        defaultValue={orderItem.brand ? orderItem.brand : ''}
                                                                        readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                        onBlur={(e)=>{
                                                                            if (e.target.value!='' && e.target.value!=orderItem.brand){
                                                                                let data = {
                                                                                    field_name : "brand",
                                                                                    value : e.target.value,
                                                                                    po_item_id : orderItem.id
                                                                                }
                                                                                dispatch(inlineUpdatePOItem(data))
                                                                            }
                                                                        }}
                                                                    />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <TextInput
                                                                            placeholder={orderItem.amendment_quantity?orderItem.amendment_quantity:''}
                                                                            name="quantities"
                                                                            defaultValue={orderItem.quantities ? orderItem.quantities : ''}
                                                                            readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                            required={true}
                                                                            onKeyUp={(e)=>{
                                                                                if (Number(orderItem.received_quantity) > 0){
                                                                                    console.log('ok')
                                                                                }else {
                                                                                    let existingQuantity = orderItem.amendment_quantity
                                                                                    if (Number(existingQuantity) < Number(e.target.value)) {
                                                                                        openConfirmModal({
                                                                                            title: 'Quantity should be 0 to ' + existingQuantity,
                                                                                            centered: true,
                                                                                            children: (
                                                                                                <Text size="sm">
                                                                                                    {'Are you sure , you want to create po for quantity ' + e.target.value + ' ? Press confirm, if you want to confirm otherwise cancel.'}
                                                                                                </Text>
                                                                                            ),
                                                                                            labels: {
                                                                                                confirm: (t('Confirm')),
                                                                                                cancel: (t("Cancel"))
                                                                                            },
                                                                                            confirmProps: {color: 'green'},
                                                                                            onCancel: () => console.log(t('Cancel')),
                                                                                            onConfirm: () => {
                                                                                                setTimeout(()=>{
                                                                                                    dispatch(setPoItemInlineUpdate('process'))
                                                                                                    dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                                    let data = {
                                                                                                        field_name: "quantities",
                                                                                                        value: e.target.value,
                                                                                                        po_item_id: orderItem.id
                                                                                                    }
                                                                                                    dispatch(inlineUpdatePOItem(data))
                                                                                                },700)
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        setTimeout(()=>{
                                                                                            dispatch(setPoItemInlineUpdate('process'))
                                                                                            dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                            let data = {
                                                                                                field_name: "quantities",
                                                                                                value: e.target.value,
                                                                                                po_item_id: orderItem.id
                                                                                            }
                                                                                            dispatch(inlineUpdatePOItem(data))
                                                                                        },700)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-right">
                                                                        <TextInput
                                                                            // placeholder="Unit Price"
                                                                            name="unit_price"
                                                                            className="w-1/2 text-right"
                                                                            defaultValue={orderItem.unit_price ? orderItem.unit_price : ''}
                                                                            readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                            required={true}
                                                                            onKeyUp={(e)=>{
                                                                                if (e.target.value!='' && e.target.value!=orderItem.unit_price) {
                                                                                    setTimeout(()=>{
                                                                                        dispatch(setPoItemInlineUpdate('process'))
                                                                                        dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                        let data = {
                                                                                            field_name: "unit_price",
                                                                                            value: e.target.value,
                                                                                            po_item_id: orderItem.id
                                                                                        }
                                                                                        dispatch(inlineUpdatePOItem(data))
                                                                                    },700)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'} />
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <>
                                                                                <TextInput
                                                                                    // placeholder="Total Price"
                                                                                    name="total_price"
                                                                                    className="w-1/2"
                                                                                    defaultValue={orderItem.sub_total ? orderItem.sub_total : ''}
                                                                                    readOnly
                                                                                    onChange={(e)=>{
                                                                                        console.log('ok')
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-right">

                                                                                <Select
                                                                                    // placeholder={t('ChooseVat')}
                                                                                    required={true}
                                                                                    searchable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    data={Number(orderItem.received_quantity) > 0?[orderItem.vat]:['5','7.5','10','15','0']}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    value={orderItem.vat ? orderItem.vat : ''}
                                                                                    onChange={(e)=>{
                                                                                        if (Number(orderItem.received_quantity) > 0){
                                                                                            console.log('ok')
                                                                                        }else {
                                                                                            dispatch(setPoItemInlineUpdate('process'))
                                                                                            dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                            let validateMessage = '';
                                                                                            let validate = false
                                                                                            if (orderItem.unit_price>0){
                                                                                                validate = true
                                                                                            }else {
                                                                                                validateMessage = 'Unit price missing '
                                                                                            }
                                                                                            if (orderItem.quantities>0){
                                                                                                validate = true
                                                                                            }else {
                                                                                                validateMessage = 'Quantity missing '
                                                                                            }

                                                                                            if (validate) {
                                                                                                // console.log(Number(e))
                                                                                                let unitVat = Number(orderItem.unit_price) * Number(e) / 100;
                                                                                                let totalVat = Number(unitVat) * Number(orderItem.quantities)
                                                                                                let data = {
                                                                                                    field_name: "vat",
                                                                                                    total_vat_amount: totalVat,
                                                                                                    value: e,
                                                                                                    po_item_id: orderItem.id
                                                                                                }
                                                                                                dispatch(inlineUpdatePOItem(data))
                                                                                            } else {
                                                                                                showNotification({
                                                                                                    id: 'load-data',
                                                                                                    loading: true,
                                                                                                    title: validateMessage + ' in line ' + index,
                                                                                                    message: (t('DataLoad')),
                                                                                                    autoClose: 1000,
                                                                                                    disallowClose: true,
                                                                                                    color: 'red',
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-right">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Unit"
                                                                                name="unit"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.unit_vat && Number(orderItem.unit_vat)>0 ? Number(orderItem.unit_vat).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total vat amount"
                                                                                name="total_vat_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.total_vat_amount && Number(orderItem.total_vat_amount)>0 ? Number(orderItem.total_vat_amount).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <Select
                                                                            // placeholder={t('ChooseVat')}
                                                                            // className={`${errors.vat_mode ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                            required={true}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            data={Number(orderItem.received_quantity) > 0?[orderItem.ait]:['0.1','1','2','3','4','5','7','8','10','0','0.60']}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            value={orderItem.ait ? orderItem.ait : ''}
                                                                            onChange={(e)=>{
                                                                                if (Number(orderItem.received_quantity) > 0){
                                                                                    console.log('ok')
                                                                                }else {
                                                                                    dispatch(setPoItemInlineUpdate('process'))
                                                                                    dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                    let validateMessage = '';
                                                                                    let validate = false
                                                                                    if (orderItem.unit_price>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Unit price missing '
                                                                                    }
                                                                                    if (orderItem.quantities>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Quantity missing '
                                                                                    }

                                                                                    if (validate) {
                                                                                        let unitAit = Number(orderItem.unit_price) * Number(e) / 100;
                                                                                        let totalAit = Number(unitAit) * Number(orderItem.quantities)
                                                                                        let data = {
                                                                                            field_name: "ait",
                                                                                            total_ait_amount: totalAit,
                                                                                            value: e,
                                                                                            po_item_id: orderItem.id
                                                                                        }
                                                                                        dispatch(inlineUpdatePOItem(data))
                                                                                    } else {
                                                                                        showNotification({
                                                                                            id: 'load-data',
                                                                                            loading: true,
                                                                                            title: validateMessage + ' in line ' + index,
                                                                                            message: (t('DataLoad')),
                                                                                            autoClose: 1000,
                                                                                            disallowClose: true,
                                                                                            color: 'red',
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Unit"
                                                                                name="unit"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.unit_ait && Number(orderItem.unit_ait)>0 ? Number(orderItem.unit_ait).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                               {/* <div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total ait amount"
                                                                                name="total_ait_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.total_ait_amount && Number(orderItem.total_ait_amount)>0 ? Number(orderItem.total_ait_amount).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                // placeholder="Total"
                                                                                name="price"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.price ? orderItem.price : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                               {/* <div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>
*/}
                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                // placeholder="Total amount"
                                                                                name="total_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.amount ? orderItem.amount : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-12/24 border-collapse border border-slate-400 text-center">

                                                                        <Group spacing={4} position="right" noWrap>
                                                                        <ActionIcon color="blue">
                                                                            <HiEye size={16} onClick={(e) => {
                                                                                dispatch(setItemPriceModel(true))
                                                                                dispatch(setItemPriceModelItemID(orderItem.last_price_id))
                                                                            }}/>
                                                                        </ActionIcon>

                                                                        <ActionIcon color="red">
                                                                            <HiTrash size={16} onClick={(e) =>{
                                                                                openConfirmModal({
                                                                                    title: t('AmendmentItemDelete'),
                                                                                    centered: true,
                                                                                    children: (
                                                                                        <Text size="sm">
                                                                                            {t('AreYouSureAmendmentItemDelete')}
                                                                                        </Text>
                                                                                    ),
                                                                                    labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                    confirmProps: {color: 'red'},
                                                                                    onCancel: () => console.log(t('Cancel')),
                                                                                    onConfirm: () => {
                                                                                        dispatch(amendmentItemDelete({
                                                                                            'po_item_id':orderItem.id
                                                                                        }))
                                                                                        dispatch(setLoading(true))
                                                                                    }
                                                                                    ,
                                                                                });
                                                                            }}/>
                                                                        </ActionIcon>
                                                                        </Group>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        }) }

                                                    <tr>
                                                        <td colSpan="18" className="border-t-4 border-indigo-500"></td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="3">{t('PaymentMethod')} </td>
                                                        <td colSpan="5">
                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                {errors.payment_method?.type === 'required' &&
                                                                <div className="form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.payment_method?.message}
                                                                </div>}
                                                                <Select
                                                                    {...register("payment_method")}
                                                                    placeholder={t('ChoosePaymentMethod')}
                                                                    className={`${errors.payment_method ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    data={paymentMethodDropdownData}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    defaultValue={purchaseOrderDetails && purchaseOrderDetails.payment_method ? purchaseOrderDetails.payment_method : null}
                                                                    onChange={(e)=>{
                                                                        setValue('payment_method',e)
                                                                        setError('payment_method',null)
                                                                        dispatch(inlineUpdatePO(
                                                                            {
                                                                                field_name: "payment_method",
                                                                                value : e,
                                                                                po_id : purchaseOrderId
                                                                            }
                                                                        ))
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {poItemInlineUpdate?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'} />
                                                                    </div>*/}

                                                                </>
                                                                :
                                                                <>
                                                                    <TextInput
                                                                        placeholder="Total Price"
                                                                        name="sub_total"
                                                                        className="w-1/2 font-bold"
                                                                        defaultValue={subTotalAmount ? Number(subTotalAmount): ''}
                                                                        readOnly
                                                                        onChange={(e)=>{
                                                                            console.log('ok')
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate ?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'}/>
                                                                    </div>*/}

                                                                </>
                                                                :
                                                                <TextInput
                                                                    placeholder="Total Price"
                                                                    name="sub_total"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={totalVatAmount ? Number(totalVatAmount).toFixed(2) : ''}
                                                                    readOnly
                                                                    onChange={(e) => {
                                                                        console.log('ok')
                                                                    }}
                                                                />
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate ?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'}/>
                                                                    </div>*/}

                                                                </>
                                                                :
                                                                <TextInput
                                                                    placeholder="Total Price"
                                                                    name="sub_total"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={totalAitAmount ? Number(totalAitAmount).toFixed(2) : ''}
                                                                    readOnly
                                                                    onChange={(e) => {
                                                                        console.log('ok')
                                                                    }}
                                                                />
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'} />
                                                                    </div>*/}

                                                                </>
                                                                :
                                                                <>
                                                                    <TextInput
                                                                        placeholder={t('POAmount')}
                                                                        name="po_amount"
                                                                        className="w-1/2 font-bold"
                                                                        defaultValue={totalAmount ? totalAmount : ''}
                                                                        readOnly
                                                                        onChange={(e)=>{
                                                                            console.log('ok')
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    {
                                                        (purchaseOrderDetails.payment_type && purchaseOrderDetails.payment_type == 'advance-payment') &&
                                                        <tr>
                                                            <td colSpan="13"></td>
                                                            <td colSpan="3">{t('AdvancePayment')} </td>
                                                            <td>
                                                                <TextInput
                                                                    placeholder="Adv. pay"
                                                                    name="advance_payment_amount"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={purchaseOrderDetails.advance_payment_amount ? purchaseOrderDetails.advance_payment_amount : ''}
                                                                    onBlur={(e)=>{
                                                                        dispatch(inlineUpdatePO(
                                                                            {
                                                                                field_name: "advance_payment_amount",
                                                                                value : e.target.value,
                                                                                po_id : purchaseOrderId
                                                                            }
                                                                        ))
                                                                    }}

                                                                />
                                                            </td>
                                                        </tr>
                                                    }

                                                    <tr>
                                                        <td colSpan="18" className="border-t-4 border-indigo-500"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {
                (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length >0 && purchaseOrderDetails.vat_mode == 'Excluding' ) &&
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                    <div className="mt-5 md:col-span-5 md:mt-0">
                        <div className="shadow      sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className="mx-auto justify-items-right w-full">
                                        <section className="-mx-0.5">
                                            <div className="flex flex-col justify-center h-full">
                                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                    <tr>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('SL')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('PRRef')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Particulars')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Project')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Brand')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('Quantity')}</div>
                                                        </th>

                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('UnitPrice')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('SubTotal')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400 text-center" colSpan={3}>
                                                            <div className="font-semibold ">{t('Vat')}</div>
                                                        </th>
                                                        {/*<th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('VatAmount')}</div>
                                                        </th>*/}
                                                        <th className="p-2 border-collapse border border-slate-400 text-center" colSpan={3}>
                                                            <div className="font-semibold ">{t('Ait')}</div>
                                                        </th>
                                                        {/*<th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('AitAmount')}</div>
                                                        </th>*/}
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('UnitTotal')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                                            <div className="font-semibold text-left">{t('TotalAmount')}</div>
                                                        </th>
                                                        <th align={`right`} className="p-2"  rowSpan={2}>

                                                        </th>
                                                    </tr>

                                                    <tr>

                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('')}%</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Total')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('')}%</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Unit')}</div>
                                                        </th>
                                                        <th className="p-2 border-collapse border border-slate-400">
                                                            <div className="font-semibold text-left">{t('Total')}</div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length >0) && purchaseOrderItem.map((orderItem, index) => {
                                                            totalAmount = (totalAmount + Number(orderItem.amount))
                                                            subTotalAmount = (subTotalAmount + Number(orderItem.sub_total))
                                                            totalAitAmount = (totalAitAmount + Number(orderItem.total_ait_amount))
                                                            totalVatAmount = (totalVatAmount + Number(orderItem.total_vat_amount))
                                                            return (
                                                                <tr className={Number(orderItem.received_quantity) > 0?"bg-rose-100":"bg-gray-100"} key={index}>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {++index}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {orderItem.pr_number?orderItem.pr_number:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{orderItem.items_name?orderItem.items_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {orderItem.item_unit_name?orderItem.item_unit_name:''}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{orderItem.projects_name?orderItem.projects_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">

                                                                <TextInput
                                                                    placeholder="Brand"
                                                                    name="brand"
                                                                    className="w-1/2"
                                                                    defaultValue={orderItem.brand ? orderItem.brand : ''}
                                                                    readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                    onBlur={(e)=>{
                                                                        if (e.target.value!='' && e.target.value!=orderItem.brand){
                                                                            let data = {
                                                                                field_name : "brand",
                                                                                value : e.target.value,
                                                                                po_item_id : orderItem.id
                                                                            }
                                                                            dispatch(inlineUpdatePOItem(data))
                                                                        }
                                                                    }}
                                                                />

                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <TextInput
                                                                            // placeholder={orderItem.remain_quantity?orderItem.remain_quantity:''}
                                                                            placeholder={orderItem.amendment_quantity?orderItem.amendment_quantity:''}
                                                                            name="quantities"
                                                                            defaultValue={orderItem.quantities ? orderItem.quantities : ''}
                                                                            readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                            required={true}
                                                                            onKeyUp={(e)=>{
                                                                                if (Number(orderItem.received_quantity) > 0){
                                                                                    console.log('ok')
                                                                                }else {
                                                                                    let existingQuantity = orderItem.amendment_quantity
                                                                                    if (Number(existingQuantity) < Number(e.target.value)) {
                                                                                        openConfirmModal({
                                                                                            title: 'Quantity should be 0 to ' + existingQuantity,
                                                                                            centered: true,
                                                                                            children: (
                                                                                                <Text size="sm">
                                                                                                    {'Are you sure , you want to create po for quantity ' + e.target.value + ' ? Press confirm, if you want to confirm otherwise cancel.'}
                                                                                                </Text>
                                                                                            ),
                                                                                            labels: {
                                                                                                confirm: (t('Confirm')),
                                                                                                cancel: (t("Cancel"))
                                                                                            },
                                                                                            confirmProps: {color: 'green'},
                                                                                            onCancel: () => console.log(t('Cancel')),
                                                                                            onConfirm: () => {
                                                                                                setTimeout(()=>{
                                                                                                    dispatch(setPoItemInlineUpdate('process'))
                                                                                                    dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                                    let data = {
                                                                                                        field_name: "quantities",
                                                                                                        value: e.target.value,
                                                                                                        po_item_id: orderItem.id
                                                                                                    }
                                                                                                    dispatch(inlineUpdatePOItem(data))
                                                                                                },700)
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        setTimeout(()=>{
                                                                                            dispatch(setPoItemInlineUpdate('process'))
                                                                                            dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                            let data = {
                                                                                                field_name: "quantities",
                                                                                                value: e.target.value,
                                                                                                po_item_id: orderItem.id
                                                                                            }
                                                                                            dispatch(inlineUpdatePOItem(data))
                                                                                        },700)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <TextInput
                                                                            placeholder="Unit Price"
                                                                            name="unit_price"
                                                                            className="w-1/2 text-right"
                                                                            defaultValue={orderItem.unit_price ? orderItem.unit_price : ''}
                                                                            readOnly={Number(orderItem.received_quantity) > 0?true:false}
                                                                            required={true}
                                                                            onKeyUp={(e) => {
                                                                                if (e.target.value != '' && e.target.value != orderItem.unit_price) {
                                                                                    setTimeout(()=>{
                                                                                        dispatch(setPoItemInlineUpdate('process'))
                                                                                        dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                        let data = {
                                                                                            field_name: "unit_price",
                                                                                            value: e.target.value,
                                                                                            po_item_id: orderItem.id
                                                                                        }
                                                                                        dispatch(inlineUpdatePOItem(data))
                                                                                    },700)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">

                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'} />
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <>
                                                                                <TextInput
                                                                                    placeholder="Total Price"
                                                                                    name="total_price"
                                                                                    className="w-1/2"
                                                                                    defaultValue={orderItem.sub_total ? orderItem.sub_total : ''}
                                                                                    readOnly
                                                                                    onChange={(e)=>{
                                                                                        console.log('ok')
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-right">

                                                                        <Select
                                                                            // placeholder={t('ChooseVat')}
                                                                            required={true}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            data={Number(orderItem.received_quantity) > 0?[orderItem.vat]:['5','7.5','10','15','0']}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            value={orderItem.vat ? orderItem.vat : ''}
                                                                            onChange={(e)=>{
                                                                                if (Number(orderItem.received_quantity) > 0){
                                                                                    console.log('ok')
                                                                                }else {
                                                                                    dispatch(setPoItemInlineUpdate('process'))
                                                                                    dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                    let validateMessage = '';
                                                                                    let validate = false
                                                                                    if (orderItem.unit_price>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Unit price missing '
                                                                                    }
                                                                                    if (orderItem.quantities>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Quantity missing '
                                                                                    }

                                                                                    if (validate) {
                                                                                        let unitVat = Number(orderItem.unit_price) * Number(e) / 100;
                                                                                        let totalVat = Number(unitVat) * Number(orderItem.quantities)
                                                                                        let data = {
                                                                                            field_name: "vat",
                                                                                            total_vat_amount: totalVat,
                                                                                            value: e,
                                                                                            po_item_id: orderItem.id
                                                                                        }
                                                                                        dispatch(inlineUpdatePOItem(data))
                                                                                    } else {
                                                                                        showNotification({
                                                                                            id: 'load-data',
                                                                                            loading: true,
                                                                                            title: validateMessage + ' in line ' + index,
                                                                                            message: (t('DataLoad')),
                                                                                            autoClose: 1000,
                                                                                            disallowClose: true,
                                                                                            color: 'red',
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-right">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Unit"
                                                                                name="unit"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.unit_vat && Number(orderItem.unit_vat)>0 ? Number(orderItem.unit_vat).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}
                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total vat amount"
                                                                                name="total_vat_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.total_vat_amount && Number(orderItem.total_vat_amount)>0 ? Number(orderItem.total_vat_amount).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <Select
                                                                            required={true}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            data={Number(orderItem.received_quantity) > 0?[orderItem.ait]:['0.1','1','2','3','4','5','7','8','10','0','0.60']}

                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            value={orderItem.ait ? orderItem.ait : ''}
                                                                            onChange={(e)=>{
                                                                                if (Number(orderItem.received_quantity) > 0){
                                                                                    console.log('ok')
                                                                                }else{
                                                                                    dispatch(setPoItemInlineUpdate('process'))
                                                                                    dispatch(setPoItemInlineUpdateID(orderItem.id))
                                                                                    let validateMessage = '';
                                                                                    let validate = false
                                                                                    if (orderItem.unit_price>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Unit price missing '
                                                                                    }
                                                                                    if (orderItem.quantities>0){
                                                                                        validate = true
                                                                                    }else {
                                                                                        validateMessage = 'Quantity missing '
                                                                                    }

                                                                                    if (validate){
                                                                                        let unitAit = Number(orderItem.unit_price)*Number(e)/100;
                                                                                        let totalAit = Number(unitAit)*Number(orderItem.quantities)
                                                                                        let data = {
                                                                                            field_name: "ait",
                                                                                            total_ait_amount: totalAit,
                                                                                            value: e,
                                                                                            po_item_id: orderItem.id
                                                                                        }
                                                                                        dispatch(inlineUpdatePOItem(data))
                                                                                        dispatch(setLoading(true))
                                                                                    }else {
                                                                                        showNotification({
                                                                                            id: 'load-data',
                                                                                            loading: true,
                                                                                            title: validateMessage+' in line '+index,
                                                                                            message: (t('DataLoad')),
                                                                                            autoClose: 1000,
                                                                                            disallowClose: true,
                                                                                            color: 'red',
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Unit"
                                                                                name="unit"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.unit_ait && Number(orderItem.unit_ait)>0 ? Number(orderItem.unit_ait).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total ait amount"
                                                                                name="total_ait_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.total_ait_amount && Number(orderItem.total_ait_amount)>0 ? Number(orderItem.total_ait_amount).toFixed(2) : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}

                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total"
                                                                                name="price"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.price ? orderItem.price : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {poItemInlineUpdate && orderItem.id === poItemInlineUpdateID ?
                                                                            <>
                                                                                {/*<div
                                                                                    className="grid place-items-center">
                                                                                    <Loader size="sm" color={'green'}/>
                                                                                </div>*/}
                                                                            </>
                                                                            :
                                                                            <TextInput
                                                                                placeholder="Total amount"
                                                                                name="total_amount"
                                                                                className="w-1/2"
                                                                                defaultValue={orderItem.amount ? orderItem.amount : ''}
                                                                                readOnly
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <Group spacing={4} position="right" noWrap>
                                                                            <ActionIcon color="blue">
                                                                                <HiEye size={16} onClick={(e) => {
                                                                                    dispatch(setItemPriceModel(true))
                                                                                    dispatch(setItemPriceModelItemID(orderItem.last_price_id))
                                                                                }}/>
                                                                            </ActionIcon>

                                                                            <ActionIcon color="red">
                                                                                <HiTrash size={16} onClick={(e) =>{
                                                                                    openConfirmModal({
                                                                                        title: t('AmendmentItemDelete'),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureAmendmentItemDelete')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                        confirmProps: {color: 'red'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            dispatch(amendmentItemDelete({
                                                                                                'po_item_id':orderItem.id
                                                                                            }))
                                                                                            dispatch(setLoading(true))
                                                                                        }
                                                                                        ,
                                                                                    });
                                                                                }}/>
                                                                            </ActionIcon>
                                                                        </Group>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        }) }

                                                    <tr>
                                                        <td colSpan="18" className="border-t-4 border-indigo-500"></td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="3">{t('PaymentMethod')} </td>
                                                        <td colSpan="5">
                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                {errors.payment_method?.type === 'required' &&
                                                                <div className="form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.payment_method?.message}
                                                                </div>}
                                                                <Select
                                                                    {...register("payment_method")}
                                                                    placeholder={t('ChoosePaymentMethod')}
                                                                    className={`${errors.payment_method ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    data={paymentMethodDropdownData}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    defaultValue={purchaseOrderDetails && purchaseOrderDetails.payment_method ? purchaseOrderDetails.payment_method : null}
                                                                    onChange={(e)=>{
                                                                        setValue('payment_method',e)
                                                                        setError('payment_method',null)
                                                                        dispatch(inlineUpdatePO(
                                                                            {
                                                                                field_name: "payment_method",
                                                                                value : e,
                                                                                po_id : purchaseOrderId
                                                                            }
                                                                        ))
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {poItemInlineUpdate?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'} />
                                                                    </div>*/}
                                                                </>
                                                                :
                                                                <>
                                                                    <TextInput
                                                                        placeholder="Total Price"
                                                                        name="sub_total"
                                                                        className="w-1/2 font-bold"
                                                                        defaultValue={subTotalAmount ? Number(subTotalAmount): ''}
                                                                        readOnly
                                                                    />
                                                                </>
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate ?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'}/>
                                                                    </div>*/}
                                                                </>
                                                                :
                                                                <TextInput
                                                                    placeholder="Total Price"
                                                                    name="sub_total"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={totalVatAmount ? Number(totalVatAmount).toFixed(2) : ''}
                                                                    readOnly
                                                                />
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate ?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'}/>
                                                                    </div>*/}
                                                                </>
                                                                :
                                                                <TextInput
                                                                    placeholder="Total Price"
                                                                    name="sub_total"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={totalAitAmount ? Number(totalAitAmount).toFixed(2) : ''}
                                                                    readOnly
                                                                />
                                                            }
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {poItemInlineUpdate?
                                                                <>
                                                                    {/*<div className="grid place-items-center">
                                                                        <Loader size="sm" color={'green'} />
                                                                    </div>*/}

                                                                </>
                                                                :
                                                                <>
                                                                    <TextInput
                                                                        placeholder={t('POAmount')}
                                                                        name="po_amount"
                                                                        className="w-1/2 font-bold"
                                                                        defaultValue={totalAmount ? totalAmount : ''}
                                                                        readOnly
                                                                    />
                                                                </>
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                    {
                                                        (purchaseOrderDetails.payment_type && purchaseOrderDetails.payment_type == 'advance-payment') &&
                                                        <tr>
                                                            <td colSpan="13"></td>
                                                            <td colSpan="3">{t('AdvancePayment')} </td>
                                                            <td>
                                                                <TextInput
                                                                    placeholder="Adv. pay"
                                                                    name="advance_payment_amount"
                                                                    className="w-1/2 font-bold"
                                                                    defaultValue={purchaseOrderDetails.advance_payment_amount ? purchaseOrderDetails.advance_payment_amount : ''}
                                                                    onBlur={(e)=>{
                                                                        dispatch(inlineUpdatePO(
                                                                            {
                                                                                field_name: "advance_payment_amount",
                                                                                value : e.target.value,
                                                                                po_id : purchaseOrderId
                                                                            }
                                                                        ))
                                                                    }}

                                                                />
                                                            </td>
                                                        </tr>
                                                    }

                                                    <tr>
                                                        <td colSpan="18" className="border-t-4 border-indigo-500"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                <div className=" w-full items-center">
                                    <label htmlFor="remark" className="form-input-sm-label">{t('Remark')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        <Textarea
                                            placeholder="Your comment"
                                            autosize
                                            minRows={2}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.comments ? purchaseOrderDetails.comments : null}
                                            onBlur={(e)=>{
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "comments",
                                                        value : e.target.value,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-full items-center">
                                    <label htmlFor="edit_reason" className="form-input-sm-label">{t('EditReason')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        <Textarea
                                            placeholder={t('EditReason')}
                                            autosize
                                            minRows={2}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.edit_reason ? purchaseOrderDetails.edit_reason : null}
                                            onBlur={(e)=>{
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "edit_reason",
                                                        value : e.target.value,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                <div className="mx-auto justify-items-right w-full">
                                    <section className="-mx-0.5">
                                        <div className="flex flex-col justify-center h-full">
                                            <table className="table-full w-full">
                                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                <tr>
                                                    <th className="p-2">
                                                        <div className="font-semibold text-left">{t('TermsAndConditions')}</div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    (purchaseOrderDetails && purchaseOrderTermConditions && purchaseOrderTermConditions.length >0) && purchaseOrderTermConditions.map((term, index) => {
                                                        return (
                                                            <tr className={"border"} key={index}>
                                                                <td className="w-1/12 bg-green-50 px-6 py-1">
                                                                    {term.conditions}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) }

                                                </tbody>
                                            </table>
                                        </div>
                                        <br/>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-full items-center">
                                    <label htmlFor="remark" className="form-input-sm-label">{t('CustomTermConditions')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        <Textarea
                                            placeholder={t('CustomTermConditions')}
                                            autosize
                                            minRows={2}
                                            defaultValue={purchaseOrderDetails && purchaseOrderDetails.custom_term_condition ? purchaseOrderDetails.custom_term_condition : null}
                                            onBlur={(e)=>{
                                                dispatch(inlineUpdatePO(
                                                    {
                                                        field_name: "custom_term_condition",
                                                        value : e.target.value,
                                                        po_id : purchaseOrderId
                                                    }
                                                ))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                <div className=" w-full items-center">
                                    {purchaseOrderDetails['project_address'] &&
                                        <>
                                    <label htmlFor="remark" className="form-input-sm-label">
                                        {t('ProjectName')} :
                                        <span className="font-bold pl-2 "> {' '+purchaseOrderDetails['project_address']['projects_name']}</span>
                                    </label>

                                        <label htmlFor="remark" className="form-input-sm-label">
                                        {t('DeliveryAddress')} :
                                        <span className="font-bold pl-2 "> {' '+purchaseOrderDetails['project_address']['address']}</span>
                                        </label>

                                            <label htmlFor="remark" className="form-input-sm-label">
                                        {t('ContactPersoncellPhone')} :
                                        <span className="font-bold pl-2 "> {' '+purchaseOrderDetails['project_address']['mobile']}</span>
                                        </label>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {itemPriceModel?<ItemPriceView />:''}
        </>
    )
}

export default PurchaseOrderAmendmentformation