import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getInventoryIssueItem,
    getInventoryStockItem,
    getInventoryValuationReportData,
    getIssueReportData, getStockUpdate, getWeightedAveragePriceReportData, stockItemUpdateData
} from "../../services/InventoryService";


export const getStockItem = createAsyncThunk("inventory/stock-item", async (param) => {
    try {
        const response = getInventoryStockItem(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getAllStockItem = createAsyncThunk("inventory/all-stock-item", async (param) => {
    try {
        const response = getInventoryStockItem(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const getIssueItem = createAsyncThunk("inventory/issue-item", async (param) => {
    try {
        const response = getInventoryIssueItem(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getAllIssueItem = createAsyncThunk("inventory/all-issue-item", async (param) => {
    try {
        const response = getInventoryIssueItem(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getIssueReport = createAsyncThunk("inventory/issue-report", async (param) => {
    try {
        const response = getIssueReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getAllIssueReport = createAsyncThunk("inventory/all-issue-report", async (param) => {
    try {
        const response = getIssueReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});



export const getInventoryValuationReport = createAsyncThunk("inventory/valuation-report", async (param) => {
    try {
        const response = getInventoryValuationReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const getAllInventoryValuationReport = createAsyncThunk("inventory/all-valuation-report", async (param) => {
    try {
        const response = getInventoryValuationReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getWeightedAveragePriceReport = createAsyncThunk("inventory/weight-average-price-report", async (param) => {
    try {
        const response = getWeightedAveragePriceReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const getAllWeightedAveragePriceReport = createAsyncThunk("inventory/all-weight-average-price-report", async (param) => {
    try {
        const response = getWeightedAveragePriceReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const getStockUpdateData = createAsyncThunk("inventory/stock-update-data", async (param) => {
    try {
        const response = getStockUpdate(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const stockItemUpdate = createAsyncThunk("inventory/stock-item-update", async (param) => {
    try {
        const response = stockItemUpdateData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const inventorySlice = createSlice({
    name : "Inventory",
    initialState : {
        fetching : false,
        page : 1,
        totalItem : 0,
        stockItems : [],
        allStockItems : [],
        issueItemFilterData : {deliveryDate:'',project:'',get_pass:'',item:''},
        issueReportFilterData : {project:'',item:'',start_date:'',end_date:''},
        weightAverageFilterData : {project:'',item:'',start_date:'',end_date:''},
        valuationReportFilterData : {project:'',item:'',start_date:''},
        issueItems : [],
        issueItemsDownloadData : [],
        allIssueItems : [],
        issueReportItems : [],
        issueReportDownloadData : [],
        allIssueReportDownloadData : [],
        valuationReportData : [],
        valuationReportDownloadData : [],
        allValuationReportDownloadData : [],
        weightedAveragePriceData : [],
        weightedAveragePriceDownloadData : [],
        allWeightedAveragePriceDownloadData : [],
        stockItemUpdateModel : false,
        stockItemUpdateId : null,
        stockUpdateData : [],
        stockUpdateComplete : false,
    },
    reducers : {
        setStockUpdateComplete : (state,action) => {
            state.stockUpdateComplete = action.payload
        },
        setStockUpdateDataEmpty : (state,action) => {
            state.stockUpdateData = []
        },
        setStockItemUpdateModel : (state,action) => {
            state.stockItemUpdateModel = action.payload
        },
        setStockItemUpdateId : (state,action) => {
            state.stockItemUpdateId = action.payload
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setIssueItemFilterData : (state,action) => {
            state.issueItemFilterData.deliveryDate = action.payload.deliveryDate;
            state.issueItemFilterData.project = action.payload.project;
            state.issueItemFilterData.get_pass = action.payload.get_pass;
            state.issueItemFilterData.item = action.payload.item;
            state.page = 1;
        },
        setIssueReportFilterData : (state,action) => {
            state.issueReportFilterData.start_date = action.payload.start_date;
            state.issueReportFilterData.project = action.payload.project;
            state.issueReportFilterData.end_date = action.payload.end_date;
            state.issueReportFilterData.item = action.payload.item;
            state.totalItem = 0;
            state.page = 1;
        },
        setValuationReportFilterData : (state,action) => {
            state.valuationReportFilterData.start_date = action.payload.start_date;
            state.valuationReportFilterData.project = action.payload.project;
            state.valuationReportFilterData.item = action.payload.item;
            state.totalItem = 0;
            state.page = 1;
        },
        setWeightAverageReportFilterData : (state,action) => {
            state.weightAverageFilterData.start_date = action.payload.start_date;
            state.weightAverageFilterData.end_date = action.payload.end_date;
            state.weightAverageFilterData.project = action.payload.project;
            state.weightAverageFilterData.item = action.payload.item;
            state.totalItem = 0;
            state.page = 1;
        },
        },


    extraReducers : (builder) => {
        builder.addCase(getStockItem.fulfilled, (state, action) => {
            state.stockItems = action.payload.data
            state.allStockItems = []
            state.totalItem = action.payload.total
            state.fetching = false
        })
        builder.addCase(getAllStockItem.fulfilled, (state, action) => {
            state.allStockItems = action.payload.data
        })

        builder.addCase(getIssueItem.fulfilled, (state, action) => {
            state.issueItems = action.payload.data
            state.issueItemsDownloadData = action.payload.downloadData
            state.allIssueItems = []
            state.totalItem = action.payload.total
            state.fetching = false
        })
        builder.addCase(getAllIssueItem.fulfilled, (state, action) => {
            state.allIssueItems = action.payload.downloadData
        })

        builder.addCase(getIssueReport.fulfilled,(state,action) => {
            state.issueReportItems = action.payload.data
            state.issueReportDownloadData = action.payload.downloadData
            state.totalItem = action.payload.total
            state.allIssueReportDownloadData = []
            state.fetching = false
        })

        builder.addCase(getAllIssueReport.fulfilled,(state,action) => {
            state.allIssueReportDownloadData = action.payload.downloadData
        })

        builder.addCase(getInventoryValuationReport.fulfilled,(state,action) => {
            state.valuationReportData = action.payload.data
            state.valuationReportDownloadData = action.payload.downloadData
            state.totalItem = action.payload.total
            state.allValuationReportDownloadData = []
            state.fetching = false
        })
        builder.addCase(getAllInventoryValuationReport.fulfilled,(state,action) => {
            state.allValuationReportDownloadData = action.payload.downloadData
        })

        builder.addCase(getWeightedAveragePriceReport.fulfilled,(state,action) => {
            state.weightedAveragePriceData = action.payload.data
            state.weightedAveragePriceDownloadData = action.payload.downloadData
            state.totalItem = action.payload.total
            state.allWeightedAveragePriceDownloadData = []
            state.fetching = false
        })
        builder.addCase(getAllWeightedAveragePriceReport.fulfilled,(state,action) => {
            state.allWeightedAveragePriceDownloadData = action.payload.downloadData
        })
        builder.addCase(getStockUpdateData.fulfilled,(state,action) => {
            state.stockUpdateData = action.payload
        })
        builder.addCase(stockItemUpdate.fulfilled,(state,action) => {
            // state.stockUpdateData = action.payload
        })
    }
})

export const {setFetching,setPage,setIssueItemFilterData,setIssueReportFilterData,setValuationReportFilterData,setWeightAverageReportFilterData,setStockItemUpdateId,setStockItemUpdateModel,setStockUpdateDataEmpty,setStockUpdateComplete} = inventorySlice.actions
export default inventorySlice.reducer;