import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createReceived,
    getGRNDropdown,
    getInvoiceBillDropdown,
    getReceivableItem,
    getReceivedApproved,
    getReceivedChecked,
    getReceiveDetails,
    getReceivedWiseInvoiceBillDropdown,
    getReceiveList,
    postInvoiceChallanInlineUpdate,
    postReceivedFinalUpdate,
    postReceivedInlineUpdate,
    postReceivedItemsInlineUpdate, postReceivedVerifyForPayment
} from "../../services/ReceivedItemService";
import {getBudgetDelete, getBudgetDetailsData} from "../../services/BudgetService";

export const getBudgetDetails = createAsyncThunk("budget/details", async (param) => {
    try {
        const response = getBudgetDetailsData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const budgetDelete = createAsyncThunk("budget/delete", async (param) => {
    try {
        const response = getBudgetDelete(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const budgetSlice = createSlice({
    name : "Budget",
    initialState : {
        loading : false,
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('budget_page') ? localStorage.getItem('budget_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'id', direction: 'DESC' },
        opened : false,
        refresh : false,
        filterData : {},
        budgetModelView : false,
        budgetViewID : '',
        budgetDelete : null,
        budgetDetails : []

    },
    reducers : {
        setLoading : (state,action) => {
            state.loading = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setBudgetFetching : (state,action) => {
            state.fetching = action.payload;
        },
        setBudgetModelView : (state,action)=>{
            state.budgetModelView = action.payload
        },
        setBudgetViewID : (state,action)=>{
            state.budgetViewID = action.payload
        },
        setBudgetDetailsEmpty : (state,action)=>{
            state.budgetDetails = []
        }
        },


    extraReducers : (builder) => {
        builder.addCase(getBudgetDetails.fulfilled, (state, action) => {
            state.budgetDetails = action.payload
        })
        builder.addCase(budgetDelete.fulfilled, (state, action) => {
            state.budgetDelete = action.payload
        })
    }
})

export const { setProjectData,setLoading,setPage,setActiveTab,setSortStatus,setOpened,setFetching,setBudgetFetching,setBudgetModelView,setBudgetViewID,setBudgetDetailsEmpty} = budgetSlice.actions
export default budgetSlice.reducer;