import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {
    createDeliveryTempItemsTemp, getDeliveryApprove, getDeliveryDetails, getDeliveryItemDelete,
    getDeliveryTempDetails, getIssueToCustomerDeliveryIndex,
    getLedgerDropdown,
    getRemainingQuantityByItemProject,
    postInlineUpdateDeliveryTemp,
    postTempDataInsertDelivery,
    postTempItemAdded,
    postTempItemDelete,
    postTempItemInlineUpdate
} from "../../services/DeliveryIssuedService";

export const createDeliveryTemp = createAsyncThunk("create/temp-delivery-and-temp-item", async (data) => {
    try {
        const response = createDeliveryTempItemsTemp(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const deliveryTempDetails = createAsyncThunk("get/temp-details", async (param) => {
    try {
        const response = getDeliveryTempDetails(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const inlineUpdateTempDelivery = createAsyncThunk("post/inline-update/temp-delivery", async (data) => {
    try {
        const response = postInlineUpdateDeliveryTemp(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const groupLedgerDropdown = createAsyncThunk("get/legder-dropdown", async () => {
    try {
        const response = getLedgerDropdown();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getRemainingQuantity = createAsyncThunk("get/remaining-quantity", async (param) => {
    try {
        const response = getRemainingQuantityByItemProject(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const tempItemAdded = createAsyncThunk("post/temp-item-added", async (data) => {
    try {
        const response = postTempItemAdded(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const tempItemInlineUpdate = createAsyncThunk("post/temp-item-inline-update", async (data) => {
    try {
        const response = postTempItemInlineUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const tempItemDelete = createAsyncThunk("post/temp-item-delete", async (id) => {
    try {
        const response = postTempItemDelete(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const tempDataInsertDelivery = createAsyncThunk("post/temp-data-insert-delivery", async (param) => {
    try {
        const response = postTempDataInsertDelivery(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const issueToCustomerDeliveryIndex = createAsyncThunk("get/delivery-to-customer-index", async (param) => {
    try {
        const response = getIssueToCustomerDeliveryIndex(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getDeliveryDetailsData = createAsyncThunk("get/delivery-details", async (param) => {
    try {
        const response = getDeliveryDetails(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getDeliveryApproveData = createAsyncThunk("get/delivery-approve", async (param) => {
    try {
        const response = getDeliveryApprove(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const deliveryItemDelete = createAsyncThunk("delivery-item/delete", async (data) => {
    try {
        const response = getDeliveryItemDelete(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const issueSlice = createSlice({
    name : "Issue",
    initialState : {
        loading : false,
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('delivery_index') ? localStorage.getItem('delivery_index') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'id', direction: 'DESC' },
        opened : false,
        refresh : false,
        filterData : {delivery_type:'individual',deliveryDate:'',project:'',get_pass:'',item:''},
        issueProjectModel : false,
        projectID : '',
        itemIssueCustomerModel : false,
        deliveryTempID : '',
        tempDetails :[],
        inlineUpdateTempDeliveryMessage : '',
        existsMessage : '',
        ledgerDropdown : '',
        remainingQuantity : [],
        issueFromData : {item_id:'',sub_category_id:'',spend_area:'',ledger_head:'',remark:'',quantity:''},
        tempItemAddedStatus : false,
        tempItemInlineUpdateMessage : '',
        tempItemDeleteMessage : '',
        tempDataInsertDeliveryMessage : '',
        issueToCustomerIndex : [],
        approvalModelCustomer : false,
        deliveryApproveID : '',
        deliveryDetailsData : '',
        deliveryApproveMessage : '',
        deliveryViewModel : false,
        issueProjectToProjectModel : false,
        projectFrom : '',
        itemIssueProjectModel : false,
        approvalModelProject : false,
        projectApproveOrSubmit : '',
        deliveryViewModelProject : false,
        deliveryApproveAfterEditModel : false,
        deliveryApproveAfterEditId : null

    },
    reducers : {
        setDeliveryApproveAfterEditModel : (state,action) => {
            state.deliveryApproveAfterEditModel = action.payload
        },
        setDeliveryApproveAfterEditId : (state,action) => {
            state.deliveryApproveAfterEditId = action.payload
        },
        setLoading : (state,action) => {
            state.loading = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.delivery_type = action.payload.delivery_type;
            state.filterData.deliveryDate = action.payload.deliveryDate;
            state.filterData.project = action.payload.project;
            state.filterData.get_pass = action.payload.get_pass;
            state.filterData.item = action.payload.item;
        },
        setIssueProjectModel : (state,action) => {
            state.issueProjectModel = action.payload
        },
        setProjectID : (state,action) => {
            state.projectID = action.payload
        },
        setItemIssueCustomerModel : (state,action) => {
            state.itemIssueCustomerModel = action.payload
        },

        setIssueFromData : (state,action) => {
            state.issueFromData.item_id = action.payload.item_id;
            state.issueFromData.sub_category_id = action.payload.sub_category_id;
            state.issueFromData.spend_area = action.payload.spend_area;
            state.issueFromData.ledger_head = action.payload.ledger_head;
            state.issueFromData.remark = action.payload.remark;
            state.issueFromData.quantity = action.payload.quantity;
        },

        setEmptyIssueFromData : (state,action) => {
            state.issueFromData.item_id = '';
            state.issueFromData.sub_category_id = '';
            state.issueFromData.spend_area = '';
            state.issueFromData.ledger_head = '';
            state.issueFromData.remark = '';
            state.issueFromData.quantity = '';
        },
        setApprovalModelCustomer : (state,action) => {
            state.approvalModelCustomer = action.payload
        },
        setDeliveryApproveID : (state,action) => {
            state.deliveryApproveID = action.payload
        },
        setDeliveryViewModel : (state,action) => {
            state.deliveryViewModel = action.payload
        },
        setIssueToCustomerIndex : (state,action) => {
            state.issueToCustomerIndex = []
        },
        setIssueProjectToProjectModel : (state,action)=>{
            state.issueProjectToProjectModel = action.payload
        },
        setProjectFrom : (state,action) => {
            state.projectFrom = action.payload
        },

        setItemIssueProjectModel : (state,action) => {
            state.itemIssueProjectModel = action.payload
        },
        setApprovalModelProject : (state,action) => {
            state.approvalModelProject = action.payload
        },
        setProjectApproveOrSubmit : (state,action) => {
            state.projectApproveOrSubmit = action.payload
        },
        setDeliveryViewModelProject : (state,action) => {
            state.deliveryViewModelProject = action.payload
        },
        setEmptyRemainingQuantity : (state,action) => {
            state.remainingQuantity = []
        },
        setEmptyTempDetails : (state,action) => {
            state.tempDetails = []
        }
    },

    extraReducers : (builder) => {

        builder.addCase(createDeliveryTemp.fulfilled,(state,action) => {
            state.deliveryTempID = action.payload
        })

        builder.addCase(deliveryTempDetails.fulfilled,(state,action)=>{
            state.tempDetails = action.payload
        })

        builder.addCase(getDeliveryDetailsData.fulfilled,(state,action)=>{
            state.deliveryDetailsData = action.payload
        })


        builder.addCase(inlineUpdateTempDelivery.fulfilled,(state,action)=>{
            if (action.payload.message == 'GetPassAlreadyExists'){
                state.existsMessage = action.payload.message
                state.inlineUpdateTempDeliveryMessage = ''
            }else {
                state.inlineUpdateTempDeliveryMessage = action.payload.message
                if (action.payload.fieldType == 'getPass'){
                    state.existsMessage = ''
                }
            }
        })

        builder.addCase(groupLedgerDropdown.fulfilled,(state,action)=>{
            state.ledgerDropdown = action.payload
        })

        builder.addCase(getRemainingQuantity.fulfilled,(state,action) =>{
            state.remainingQuantity = action.payload
        })

        builder.addCase(tempItemAdded.fulfilled,(state,action)=>{
            if (action.payload =='create'){
                state.tempItemAddedStatus = true
                state.issueFromData.item_id = '';
                state.issueFromData.sub_category_id = '';
                state.issueFromData.spend_area = '';
                state.issueFromData.ledger_head = '';
                state.issueFromData.remark = null;
                state.issueFromData.quantity = null;
            }
        })

        builder.addCase(tempItemInlineUpdate.fulfilled,(state,action)=>{
            state.tempItemInlineUpdateMessage = action.payload
        })
        builder.addCase(tempItemDelete.fulfilled,(state,action)=>{
            state.tempItemDeleteMessage = action.payload
        })
        builder.addCase(tempDataInsertDelivery.fulfilled,(state,action)=>{
            state.tempDataInsertDeliveryMessage = action.payload
        })

        builder.addCase(issueToCustomerDeliveryIndex.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.fetching = false
            state.issueToCustomerIndex = action.payload.data
            state.deliveryApproveMessage = ''
            state.tempDataInsertDeliveryMessage = ''
            state.tempItemInlineUpdateMessage = ''
            state.tempItemDeleteMessage = ''
            state.deliveryDetailsData = []
            state.tempDetails = []
        })
        builder.addCase(getDeliveryApproveData.fulfilled,(state,action)=>{
            state.deliveryApproveMessage = action.payload
        })
        builder.addCase(deliveryItemDelete.fulfilled,(state,action)=>{
            state.fetching = action.payload
        })

    }
})

export const { setProjectData,setLoading,setPage,setSortStatus,setOpened,setFetching,setRefresh,setFilterData,setActiveTab,setIssueProjectModel,setProjectID,setItemIssueCustomerModel,setIssueFromData,setApprovalModelCustomer,setDeliveryApproveID,setDeliveryViewModel,setIssueToCustomerIndex,setIssueProjectToProjectModel,setProjectFrom,setItemIssueProjectModel,setApprovalModelProject,setProjectApproveOrSubmit,setDeliveryViewModelProject,setEmptyRemainingQuantity,setEmptyIssueFromData,setEmptyTempDetails,setDeliveryApproveAfterEditId,setDeliveryApproveAfterEditModel } = issueSlice.actions
export default issueSlice.reducer;