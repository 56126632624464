import React from "react";
import {
  HiOutlineHome,
  HiCurrencyDollar,
  HiViewList,
  HiOutlinePlusCircle,
  HiShoppingBag,HiShoppingCart,HiLibrary,HiOutlineUserCircle,HiOutlineDatabase,HiBriefcase,HiBadgeCheck
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import jwt_decode from "jwt-decode";
import {Sidebar, Menu, MenuItem, SubMenu, menuClasses} from 'react-pro-sidebar';
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {useDispatch, useSelector} from "react-redux";
import {
  createEmptyStoreRequisition,
  setStoreRequisitionCreateID, setStoreRequisitionCreateMsg
} from "../../store/storeRequisition/storeRequisitionSlice";
import {setItemCategorySubCat} from "../../store/items/itemsSlice";

function LeftMenu(){
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  let currentRoute = location.pathname;
  {location.pathname.split("/").map(url => {
    if (url === "requisition"){currentRoute = 'requisition'}
    if (url === "pre-requisition"){currentRoute = 'pre-requisition'}
    if (url === "purchase-order"){currentRoute = 'purchase-order'}
    if (url === "budget"){currentRoute = 'budget'}
    if (url === "permission"){currentRoute = 'permission'}
    if (url === "role"){currentRoute = 'role'}
    if (url === "delivery-issued"){currentRoute = 'delivery-issued'}
    if (url === "received-item"){currentRoute = 'received-item'}
    if (url === "store-requisition"){currentRoute = 'store-requisition'}
    if (url === "payment"){currentRoute = 'payment'}
    if (url === "user"){currentRoute = 'user'}
    if (url === "inventory"){currentRoute = 'inventory'}
    if (url === "report"){currentRoute = 'report'}
    currentRoute = currentRoute.replace(/\//g,'')
  })}

  const leftSidebarHeight = localStorage.getItem('leftSidebarHeight');

  const token = localStorage.getItem('user_token');
  const decoded = jwt_decode(token);

  const ROLE_BUDGET_VIEW = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_VIEW";
  })
  const ROLE_BUDGET_ADD = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_ADD";
  })
  const ROLE_BUDGET_EDIT = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_EDIT";
  })
  const ROLE_BUDGET_APPROVE_ONE = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_APPROVE_ONE";
  })
  const ROLE_BUDGET_APPROVE_TWO = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_APPROVE_TWO";
  })
  const ROLE_BUDGET_APPROVE_THREE = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_APPROVE_THREE";
  })
  const ROLE_BUDGET_DELETE = decoded.roles.find(role => {
    return role === "ROLE_BUDGET_DELETE";
  })
  const ROLE_BUDGET_ADD_VIEW_EDIT = decoded.roles.find(role => {
      return role === "ROLE_BUDGET_EDIT" || role === "ROLE_BUDGET_ADD" || role === "ROLE_BUDGET_VIEW";
  })

  const ROLE_PURCHASE_REQUISITION_ADD = decoded.roles.find(role => {
    return role === "ROLE_PURCHASE_REQUISITION_ADD";
  })
  const ROLE_PURCHASE_REQUISITION_VIEW = decoded.roles.find(role => {
    return role === "ROLE_PURCHASE_REQUISITION_VIEW";
  })
  const ROLE_PURCHASE_REQUISITION_EDIT = decoded.roles.find(role => {
    return role === "ROLE_PURCHASE_REQUISITION_EDIT";
  })
  const ROLE_PURCHASE_REQUISITION_CLOSE = decoded.roles.find(role => {
    return role === "ROLE_PURCHASE_REQUISITION_CLOSE";
  })

  const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
    return role === "ROLE_SUPER_ADMIN";
  })
  const ROLE_RECEIVE_ITEM_REPORT_VIEW = decoded.roles.find(role => {
    return role === "ROLE_RECEIVE_ITEM_REPORT_VIEW";
  })
  const ROLE_INVENTORY_STOCK_REPORT_VIEW = decoded.roles.find(role => {
    return role === "ROLE_INVENTORY_STOCK_REPORT_VIEW";
  })
  const ROLE_INVENTORY_STOCK_VIEW = decoded.roles.find(role => {
    return role === "ROLE_INVENTORY_STOCK_VIEW";
  })

  const ROLE_PURCHASE_REQUISITION_ADD_VIEW_EDIT_CLOSE = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_ADD" || role === "ROLE_PURCHASE_REQUISITION_VIEW" || role === "ROLE_PURCHASE_REQUISITION_EDIT" || role === "ROLE_PURCHASE_REQUISITION_CLOSE";
  })

  const ROLE_PURCHASE_REQUISITION_CLAIM = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_CLAIM" ;
  })
  const ROLE_PRE_PURCHASE_REQUISITION_ADD_VIEW_EDIT = decoded.roles.find(role=>{
    return role === "ROLE_PRE_PURCHASE_REQUISITION_ADD" || role === "ROLE_PRE_PURCHASE_REQUISITION_EDIT" || role === "ROLE_PRE_PURCHASE_REQUISITION_VIEW" || role === "ROLE_PRE_PURCHASE_REQUISITION_DELETE" || role === "ROLE_PRE_PURCHASE_REQUISITION_APPROVE" || role === "ROLE_PRE_PURCHASE_REQUISITION_TO_REQUISITION";
  })

  const ROLE_PURCHASE_ORDER_ADD_EDIT_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_ORDER_VIEW" || role === "ROLE_PURCHASE_ORDER_ADD"|| role === "ROLE_PURCHASE_ORDER_EDIT";
  })

  const ROLE_RECEIVE_ADD_EDIT_VIEW_CHECKED_APPROVE_VERIFY = decoded.roles.find(role=>{
    return role === "ROLE_RECEIVE_VIEW" || role === "ROLE_RECEIVE_ADD"|| role === "ROLE_RECEIVE_EDIT"|| role === "ROLE_RECEIVE_CHECKED"|| role === "ROLE_RECEIVE_APPROVE"|| role === "ROLE_RECEIVE_VERIFY";
  })

  const ROLE_STORE_REQUISITION_ADD_VIEW_EDIT_APPROVE_RECEIVED = decoded.roles.find(role=>{
    return role === "ROLE_STORE_REQUISITION_ADD" || role === "ROLE_STORE_REQUISITION_VIEW" || role === "ROLE_STORE_REQUISITION_EDIT" || role === "ROLE_STORE_REQUISITION_APPROVE_ONE" || role === "ROLE_STORE_REQUISITION_APPROVE_TWO" || role === "ROLE_STORE_REQUISITION_APPROVE_THREE" || role === "ROLE_STORE_REQUISITION_RECEIVED";
  })
  const ROLE_STORE_REQUISITION_ADD = decoded.roles.find(role=>{
    return role === "ROLE_STORE_REQUISITION_ADD" ;
  })
  const ROLE_STORE_REQUISITION_EDIT = decoded.roles.find(role=>{
    return role === "ROLE_STORE_REQUISITION_EDIT" ;
  })
  const ROLE_STORE_REQUISITION_RECEIVED = decoded.roles.find(role=>{
    return role === "ROLE_STORE_REQUISITION_RECEIVED" ;
  })
  const ROLE_ERP_LEDGER_HEAD_ACCESS = decoded.roles.find(role=>{
    return role === "ROLE_ERP_LEDGER_HEAD_ACCESS" ;
  })
  const ROLE_INVENTORY_VALUATION_REPORT_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_VALUATION_REPORT_VIEW" ;
  })
  const ROLE_INVENTORY_DELIVERY_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_DELIVERY_VIEW" ;
  })
  const ROLE_INVENTORY_DELIVERY_ADD = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_DELIVERY_ADD" ;
  })
  const ROLE_INVENTORY_DELIVERY_EDIT = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_DELIVERY_EDIT" ;
  })
  const ROLE_INVENTORY_DELIVERY_APPROVE = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_DELIVERY_APPROVE" ;
  })
  const ROLE_INVENTORY_DELIVERY_DELETE = decoded.roles.find(role=>{
    return role === "ROLE_INVENTORY_DELIVERY_DELETE" ;
  })
  const ROLE_PROJECT_TO_PROJECT_DELIVERY = decoded.roles.find(role=>{
    return role === "ROLE_PROJECT_TO_PROJECT_DELIVERY" ;
  })
  const ROLE_ISSUE_REPORT_WEIGHTED_PRICE_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_ISSUE_REPORT_WEIGHTED_PRICE_VIEW" ;
  })
  const ROLE_REQUEST_FOR_PAYMENT_ADD = decoded.roles.find(role=>{
    return role === "ROLE_REQUEST_FOR_PAYMENT_ADD" ;
  })
  const ROLE_REQUEST_FOR_PAYMENT = decoded.roles.find(role=>{
    return role === "ROLE_REQUEST_FOR_PAYMENT" ;
  })
  const ROLE_PAYMENT_EDIT = decoded.roles.find(role=>{
    return role === "ROLE_PAYMENT_EDIT" ;
  })
  const ROLE_PAYMENT_APPROVE_ONE = decoded.roles.find(role=>{
    return role === "ROLE_PAYMENT_APPROVE_ONE" ;
  })
  const ROLE_PAYMENT_APPROVE_TWO = decoded.roles.find(role=>{
    return role === "ROLE_PAYMENT_APPROVE_TWO" ;
  })
  const ROLE_PAYMENT_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_PAYMENT_VIEW" ;
  })
  const ROLE_ADVANCE_PAYMENT_ALL = decoded.roles.find(role=>{
    return role === "ROLE_ADVANCE_PAYMENT_ALL" ;
  })
  const ROLE_ADVANCE_PAYMENT_HEAD = decoded.roles.find(role=>{
    return role === "ROLE_ADVANCE_PAYMENT_HEAD" ;
  })
  const ROLE_ADVANCE_PAYMENT_LOCAL = decoded.roles.find(role=>{
    return role === "ROLE_ADVANCE_PAYMENT_LOCAL" ;
  })
  const ROLE_DAILY_BASIS_PR_REPORT_VIEW = decoded.roles.find(role=>{
    return role === "ROLE_DAILY_BASIS_PR_REPORT_VIEW" ;
  })
  const ROLE_BUDGET_PO_WISE_REPORT = decoded.roles.find(role=>{
    return role === "ROLE_BUDGET_PO_WISE_REPORT" ;
  })
  const ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE" ;
  })
  const ROLE_PURCHASE_REQUISITION_APPROVE_ONE = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_APPROVE_ONE" ;
  })
  const ROLE_PURCHASE_REQUISITION_APPROVE_TWO = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_APPROVE_TWO" ;
  })
  const ROLE_PURCHASE_REQUISITION_APPROVE_THREE = decoded.roles.find(role=>{
    return role === "ROLE_PURCHASE_REQUISITION_APPROVE_THREE" ;
  })


  const navigate = useNavigate();

  const leftMenuCollapsed = useSelector((state) => state.commonReducer.leftMenuCollapsed)
  const storeRequisitionCreateID = useSelector((state) => state.storeRequisitionReducer.storeRequisitionCreateID)
  const storeRequisitionCreateMsg = useSelector((state) => state.storeRequisitionReducer.storeRequisitionCreateMsg)

  if (storeRequisitionCreateID && storeRequisitionCreateMsg=='created'){
    const id = storeRequisitionCreateID;
    dispatch(setStoreRequisitionCreateID(null))
    dispatch(setStoreRequisitionCreateMsg(null))
    navigate('/store-requisition/edit/'+id);
  }



  const CreateBudget = (e) => {
    const formData = {
      status: 0
    };
    if (formData) {
      axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/create`,
          formData,
          {headers: {"Authorization": `Bearer ${token}`}})
          .then(res => {
                if (res.data.status === 201) {
                  const last_id = res.data.last_id;
                  navigate('/budget/edit/'+last_id);
                } else {
                  showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 500,
                    disallowClose: true,
                    color: 'green',
                  });
                }
              }
          );
    }
  }
  const CreateStoreRequisition = (e) => {
    dispatch(setItemCategorySubCat())
    dispatch(createEmptyStoreRequisition({
      "status": 0
    }))
  }

  const CreatePreRequisition = (e) => {
    const formData = {
      status: 0,
      requisition_type: "pre-requisition"
    };
    if (formData) {
      axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/create`,
          formData,
          {headers: {"Authorization": `Bearer ${token}`}})
          .then(res => {
                if (res.data.status === 201) {
                  const last_id = res.data.last_id;
                  navigate('/pre-requisition/edit/'+last_id);
                } else {
                  showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 500,
                    disallowClose: true,
                    color: 'green',
                  });
                }
              }
          );
    }
  }
  const CreatePurchaseRequisition = (e) => {
    const formData = {
      status: 0,
      requisition_type: "requisition"
    };
    if (formData) {
      axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/create`,
          formData,
          {headers: {"Authorization": `Bearer ${token}`}})
          .then(res => {
                if (res.data.status === 201) {
                  const last_id = res.data.last_id;
                  navigate('/requisition/edit/'+last_id);
                } else {
                  showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 500,
                    disallowClose: true,
                    color: 'green',
                  });
                }
              }
          );
    }
  }

  // console.log(currentRoute)

  return(
        <aside className="hidden sm:block bg-indigo-50 w-114">
          <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
            <Sidebar
                transitionDuration={1000}
                collapsed={leftMenuCollapsed}
            >
              <Menu
                  transitionDuration={1000}
                  menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      if (level === 0)
                        return {
                          color: 'rgb(30 41 59)',
                          backgroundColor: active ? '#eecef9' : undefined,
                        };
                    },
                  }}
              >
                <MenuItem component={<Link to="/dashboard" />}  icon={<HiOutlineHome/>}
                      rootStyles={{
                        ['.' + menuClasses.button]: {
                          backgroundColor: currentRoute=='dashboard' && 'rgb(199 210 254)'
                        },
                      }}
                > Home </MenuItem>
                {
                  (ROLE_SUPER_ADMIN || ROLE_BUDGET_EDIT || ROLE_BUDGET_VIEW || ROLE_BUDGET_DELETE || ROLE_BUDGET_APPROVE_THREE || ROLE_BUDGET_APPROVE_TWO || ROLE_BUDGET_APPROVE_ONE || ROLE_BUDGET_ADD ) &&
                  <SubMenu label="Budget" icon={<HiCurrencyDollar size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='budget' && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/budget"/>} icon={<HiViewList/>}> Budget List </MenuItem>
                    {
                      (ROLE_SUPER_ADMIN || ROLE_BUDGET_EDIT || ROLE_BUDGET_ADD) &&
                      <MenuItem onClick={event => CreateBudget(event)} icon={<HiOutlinePlusCircle/>}> New Budget </MenuItem>
                    }

                  </SubMenu>
                }
                {
                  (ROLE_SUPER_ADMIN || ROLE_PRE_PURCHASE_REQUISITION_ADD_VIEW_EDIT) &&
                  <SubMenu label={t('PreRequisition')} icon={<HiShoppingBag size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='pre-requisition' && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/pre-requisition"/>} icon={<HiViewList/>}> List Pre PR </MenuItem>
                    {
                      (ROLE_PRE_PURCHASE_REQUISITION_ADD_VIEW_EDIT || ROLE_SUPER_ADMIN) &&
                      <MenuItem onClick={event => CreatePreRequisition(event)} icon={<HiOutlinePlusCircle/>}> New Pre PR </MenuItem>
                    }
                  </SubMenu>
                }

                {
                  (ROLE_PURCHASE_REQUISITION_ADD_VIEW_EDIT_CLOSE || ROLE_SUPER_ADMIN || ROLE_PURCHASE_REQUISITION_CLAIM || ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE || ROLE_PURCHASE_REQUISITION_APPROVE_ONE || ROLE_PURCHASE_REQUISITION_APPROVE_TWO || ROLE_PURCHASE_REQUISITION_APPROVE_THREE ) &&
                  <SubMenu label={t('Requisition')} icon={<HiShoppingBag size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='requisition' && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/requisition"/>} icon={<HiViewList/>}> {t('Requisition')} List </MenuItem>
                    {
                      (ROLE_PURCHASE_REQUISITION_ADD || ROLE_PURCHASE_REQUISITION_EDIT) &&
                      <MenuItem onClick={event => CreatePurchaseRequisition(event)} icon={<HiOutlinePlusCircle/>}> New {t('Requisition')} </MenuItem>
                    }
                  </SubMenu>
                }
                {
                  (ROLE_PURCHASE_ORDER_ADD_EDIT_VIEW || ROLE_SUPER_ADMIN ) &&
                  <SubMenu label="Purchase Order" icon={<HiShoppingCart size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='purchase-order' && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/purchase-order"/>} icon={<HiViewList/>}> PO List </MenuItem>
                    <MenuItem component={<Link to="/purchase-order/create"/>} icon={<HiOutlinePlusCircle/>}> New PO </MenuItem>
                  </SubMenu>
                }
                {
                  (ROLE_RECEIVE_ADD_EDIT_VIEW_CHECKED_APPROVE_VERIFY || ROLE_SUPER_ADMIN || ROLE_REQUEST_FOR_PAYMENT_ADD) &&
                  <MenuItem component={<Link to="/received-item"/>} icon={<HiViewList/>} icon={<HiOutlineDatabase size={16}/>}
                            rootStyles={{
                              ['.' + menuClasses.button]: {
                                backgroundColor: currentRoute=='received-item' && 'rgb(199 210 254)'
                              },
                            }}
                  > Received </MenuItem>
                }

                {/*{
                  (ROLE_SUPER_ADMIN || ROLE_REQUEST_FOR_PAYMENT || ROLE_PAYMENT_APPROVE_ONE || ROLE_PAYMENT_APPROVE_TWO || ROLE_PAYMENT_EDIT || ROLE_PAYMENT_VIEW) &&
                  <MenuItem component={<Link to="/payment"/>} icon={<HiViewList/>} icon={<HiBriefcase size={16}/>}
                            rootStyles={{
                              ['.' + menuClasses.button]: {
                                backgroundColor: currentRoute=='payment' && 'rgb(199 210 254)'
                              },
                            }}
                  > Payment </MenuItem>
                }*/}

                {
                  (ROLE_SUPER_ADMIN || ROLE_REQUEST_FOR_PAYMENT || ROLE_PAYMENT_APPROVE_ONE || ROLE_PAYMENT_APPROVE_TWO || ROLE_PAYMENT_EDIT || ROLE_PAYMENT_VIEW || ROLE_ADVANCE_PAYMENT_ALL || ROLE_ADVANCE_PAYMENT_HEAD || ROLE_ADVANCE_PAYMENT_LOCAL) &&
                  <SubMenu label="Payment" icon={<HiShoppingCart size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='payment' && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    {
                      (ROLE_SUPER_ADMIN || ROLE_REQUEST_FOR_PAYMENT || ROLE_PAYMENT_APPROVE_ONE || ROLE_PAYMENT_APPROVE_TWO || ROLE_PAYMENT_EDIT || ROLE_PAYMENT_VIEW) &&
                      <MenuItem component={<Link to="/payment"/>} icon={<HiViewList/>}> Payment List </MenuItem>
                    }
                    {
                      (ROLE_SUPER_ADMIN || ROLE_ADVANCE_PAYMENT_ALL || ROLE_ADVANCE_PAYMENT_HEAD || ROLE_ADVANCE_PAYMENT_LOCAL) &&
                      <MenuItem component={<Link to="/payment/advance"/>} icon={<HiViewList/>}> Advance List </MenuItem>
                    }
                  </SubMenu>
                }


                {
                  (ROLE_STORE_REQUISITION_ADD_VIEW_EDIT_APPROVE_RECEIVED || ROLE_SUPER_ADMIN ) &&
                  <SubMenu label={t('StoreRequisition')} icon={<HiShoppingBag size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='store-requisition'  && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/store-requisition"/>} icon={<HiViewList/>}> {t('Requisition')} List </MenuItem>
                    {
                      (ROLE_STORE_REQUISITION_ADD || ROLE_STORE_REQUISITION_EDIT || ROLE_SUPER_ADMIN) &&
                      <MenuItem  onClick={event => CreateStoreRequisition(event)} icon={<HiOutlinePlusCircle/>}> New {t('Requisition')} </MenuItem>
                    }
                    {/*{
                      (ROLE_STORE_REQUISITION_ADD || ROLE_STORE_REQUISITION_EDIT || ROLE_STORE_REQUISITION_RECEIVED || ROLE_SUPER_ADMIN) &&
                      <MenuItem onClick={event => CreatePurchaseRequisition(event)} icon={<HiOutlinePlusCircle/>}> Received {t('Requisition')} </MenuItem>
                    }*/}

                  </SubMenu>
                }

                {
                  (ROLE_SUPER_ADMIN || ROLE_PURCHASE_ORDER_ADD_EDIT_VIEW || ROLE_DAILY_BASIS_PR_REPORT_VIEW ) &&
                  <SubMenu label={t('Report')} icon={<HiShoppingBag size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='report'  && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    {
                      (ROLE_SUPER_ADMIN || ROLE_BUDGET_PO_WISE_REPORT) &&
                      <SubMenu label={t('Budget')} icon={<HiShoppingBag size={16}/>}
                               rootStyles={{
                                 ['.' + menuClasses.button]: {
                                   backgroundColor: currentRoute == 'report' && 'rgb(199 210 254)'
                                 },
                               }}
                      >
                        {
                          (ROLE_SUPER_ADMIN || ROLE_BUDGET_PO_WISE_REPORT) &&
                          <>
                            <MenuItem component={<Link to="/report/po-wise-budget"/>} icon={<HiViewList/>}>PO Wise</MenuItem>
                            <MenuItem component={<Link to="/report/budget-summary"/>} icon={<HiViewList/>}>Summary</MenuItem>
                          </>
                        }
                      </SubMenu>
                    }

                    {
                      (ROLE_SUPER_ADMIN || ROLE_DAILY_BASIS_PR_REPORT_VIEW) &&
                      <SubMenu label={t('PR')} icon={<HiShoppingBag size={16}/>}
                               rootStyles={{
                                 ['.' + menuClasses.button]: {
                                   backgroundColor: currentRoute == 'report' && 'rgb(199 210 254)'
                                 },
                               }}
                      >
                        {
                          (ROLE_SUPER_ADMIN || ROLE_DAILY_BASIS_PR_REPORT_VIEW) &&
                              <>
                                <MenuItem component={<Link to="/report/pr-daily-report"/>} icon={<HiViewList/>}> {t('DailyBasis')}</MenuItem>
                                <MenuItem component={<Link to="/report/pr-activities-report"/>} icon={<HiViewList/>}>Activities</MenuItem>
                                <MenuItem component={<Link to="/report/pr-pending-claim"/>} icon={<HiViewList/>}>Pending Claim</MenuItem>
                          </>
                        }
                      </SubMenu>
                    }

                    {
                      (ROLE_SUPER_ADMIN || ROLE_PURCHASE_ORDER_ADD_EDIT_VIEW) &&
                      <SubMenu label={t('Po')} icon={<HiShoppingBag size={16}/>}
                               rootStyles={{
                                 ['.' + menuClasses.button]: {
                                   backgroundColor: currentRoute == 'report' && 'rgb(199 210 254)'
                                 },
                               }}
                      >
                        <MenuItem component={<Link to="/report/po-report"/>}
                                  icon={<HiViewList/>}> {t('PoReport')}</MenuItem>
                      </SubMenu>
                    }

                    {
                      (ROLE_SUPER_ADMIN || ROLE_RECEIVE_ITEM_REPORT_VIEW) &&
                    <SubMenu label={t('Receive')} icon={<HiShoppingBag size={16}/>}
                             rootStyles={{
                               ['.' + menuClasses.button]: {
                                 backgroundColor: currentRoute=='report'  && 'rgb(199 210 254)'
                               },
                             }}
                    >
                      <MenuItem  component={<Link to="/report/receive-item"/>} icon={<HiViewList/>}> {t('ReceivedItem')}</MenuItem>
                    </SubMenu>
                    }

                    {
                        (ROLE_SUPER_ADMIN) &&
                        <SubMenu label={t('Price')} icon={<HiShoppingBag size={16}/>}
                                 rootStyles={{
                                   ['.' + menuClasses.button]: {
                                     backgroundColor: currentRoute=='report'  && 'rgb(199 210 254)'
                                   },
                                 }}
                        >
                          <MenuItem  component={<Link to="/report/purchase-price-directory"/>} icon={<HiViewList/>}> {t('PriceDirectory')}</MenuItem>
                        </SubMenu>
                    }

                  </SubMenu>
                }

                {
                  (ROLE_SUPER_ADMIN || ROLE_INVENTORY_STOCK_REPORT_VIEW || ROLE_ERP_LEDGER_HEAD_ACCESS || ROLE_INVENTORY_DELIVERY_VIEW || ROLE_INVENTORY_VALUATION_REPORT_VIEW || ROLE_ISSUE_REPORT_WEIGHTED_PRICE_VIEW || ROLE_INVENTORY_DELIVERY_ADD ||ROLE_INVENTORY_DELIVERY_APPROVE || ROLE_INVENTORY_DELIVERY_EDIT || ROLE_INVENTORY_DELIVERY_DELETE || ROLE_INVENTORY_DELIVERY_VIEW || ROLE_PROJECT_TO_PROJECT_DELIVERY) &&
                  <SubMenu label={t('Inventory')} icon={<HiShoppingBag size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: currentRoute=='inventory'  && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    {
                      (ROLE_SUPER_ADMIN || ROLE_INVENTORY_DELIVERY_ADD || ROLE_INVENTORY_DELIVERY_APPROVE || ROLE_INVENTORY_DELIVERY_EDIT || ROLE_INVENTORY_DELIVERY_DELETE || ROLE_INVENTORY_DELIVERY_VIEW || ROLE_PROJECT_TO_PROJECT_DELIVERY || ROLE_ERP_LEDGER_HEAD_ACCESS) &&
                      <MenuItem component={<Link to="/delivery-issued"/>} icon={<HiViewList/>} icon={<HiBadgeCheck size={16}/>}
                                rootStyles={{
                                  ['.' + menuClasses.button]: {
                                    backgroundColor: currentRoute=='delivery-issued' && 'rgb(199 210 254)'
                                  },
                                }}
                      > Delivery Issue </MenuItem>
                    }
                    {
                      (ROLE_SUPER_ADMIN || ROLE_INVENTORY_STOCK_REPORT_VIEW || ROLE_INVENTORY_STOCK_VIEW) &&
                          <>
                            {/*<MenuItem component={<Link to="/inventory/issue-item-list"/>} icon={<HiViewList/>}> {t('IssueItem')} </MenuItem>*/}
                            {/*<MenuItem component={<Link to="/inventory/issue-item-project-list"/>} icon={<HiViewList/>}> {t('IssueItemProject')} </MenuItem>*/}
                            <MenuItem component={<Link to="/inventory/stock-item-list"/>} icon={<HiViewList/>}> {t('StockItem')} </MenuItem>
                            {/*<MenuItem component={<Link to="/inventory/issue-report-wei-avg"/>} icon={<HiViewList/>}> {t('IssueWeiAvg')} </MenuItem>*/}
                          </>
                    }

                    {
                      (ROLE_SUPER_ADMIN || ROLE_INVENTORY_DELIVERY_VIEW) &&
                          <>
                            <MenuItem component={<Link to="/inventory/issue-report-wei-avg"/>} icon={<HiViewList/>}> {t('IssueReport')} </MenuItem>
                          </>
                    }
                    {
                      (ROLE_SUPER_ADMIN || ROLE_INVENTORY_VALUATION_REPORT_VIEW ) &&
                          <>
                            <MenuItem component={<Link to="/inventory/valuation-report"/>} icon={<HiViewList/>}> {t('ValuationReport')} </MenuItem>
                          </>
                    }
                    {
                      (ROLE_SUPER_ADMIN || ROLE_ISSUE_REPORT_WEIGHTED_PRICE_VIEW ) &&
                          <>
                            {/*<MenuItem component={<Link to="/inventory/weighted-average-price-report"/>} icon={<HiViewList/>}> {t('WeightedAveragePrice')} </MenuItem>*/}
                          </>
                    }

                  </SubMenu>
                }

                {
                  ROLE_SUPER_ADMIN &&
                  <SubMenu label={t('User')} icon={<HiOutlineUserCircle size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: (currentRoute=='user' || currentRoute=='permission'|| currentRoute=='role') && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/user"/>} icon={<HiViewList/>}> {t('User')} List </MenuItem>
                    {/*<MenuItem component={<Link to="/user/add-user"/>} icon={<HiOutlinePlusCircle/>}> New {t('User')} </MenuItem>*/}
                    {/*<MenuItem component={<Link to="/master-data/user-group"/>} icon={<HiViewList/>}> Role </MenuItem>*/}
                    <MenuItem component={<Link to="/role"/>} icon={<HiViewList/>}> Role </MenuItem>
                    <MenuItem component={<Link to="/permission"/>} icon={<HiViewList/>}> Permission </MenuItem>
                  </SubMenu>
                }

                {
                  ( ROLE_SUPER_ADMIN ) &&
                  <SubMenu label="Master Data" icon={<HiLibrary size={16}/>}
                           rootStyles={{
                             ['.' + menuClasses.button]: {
                               backgroundColor: (currentRoute=='master-dataitem' || currentRoute=='master-dataitem-type'|| currentRoute=='master-dataspend-area'|| currentRoute=='master-dataproject'|| currentRoute=='master-dataproject-type'|| currentRoute=='master-datacompany-type'|| currentRoute=='master-datacategory'|| currentRoute=='master-datasub-category'|| currentRoute=='master-dataarea'|| currentRoute=='master-datacost-header'|| currentRoute=='master-dataerp-ledger-account'|| currentRoute=='master-dataterms-and-conditions'|| currentRoute=='master-datapurchase-type'|| currentRoute=='master-datacompany-setting'|| currentRoute=='master-datarelated-bank'|| currentRoute=='master-datasignature'||  currentRoute=='master-datavarieties') && 'rgb(199 210 254)'
                             },
                           }}
                  >
                    <MenuItem component={<Link to="/master-data/vendor"/>} icon={<HiViewList/>}> Vendor </MenuItem>
                    <MenuItem component={<Link to="/master-data/item"/>} icon={<HiViewList/>}> Item </MenuItem>
                    <MenuItem component={<Link to="/master-data/item-type"/>} icon={<HiViewList/>}> Item Type </MenuItem>
                    <MenuItem component={<Link to="/master-data/spend-area"/>} icon={<HiViewList/>}> Spend Area </MenuItem>
                    <MenuItem component={<Link to="/master-data/project"/>} icon={<HiViewList/>}> Project </MenuItem>
                    <MenuItem component={<Link to="/master-data/project-type"/>} icon={<HiViewList/>}> Project Type </MenuItem>
                    <MenuItem component={<Link to="/master-data/company-type"/>} icon={<HiViewList/>}> Company Type </MenuItem>
                    <MenuItem component={<Link to="/master-data/category"/>} icon={<HiViewList/>}> Category </MenuItem>
                    <MenuItem component={<Link to="/master-data/sub-category"/>} icon={<HiViewList/>}> Sub-Category </MenuItem>
                    <MenuItem component={<Link to="/master-data/area"/>} icon={<HiViewList/>}> Area </MenuItem>
                    <MenuItem component={<Link to="/master-data/cost-header"/>} icon={<HiViewList/>}> Cost Header </MenuItem>
                    <MenuItem component={<Link to="/master-data/erp-ledger-account"/>} icon={<HiViewList/>}> ERP Ledger Account </MenuItem>
                    <MenuItem component={<Link to="/master-data/terms-and-conditions"/>} icon={<HiViewList/>}> Terms & Conditions </MenuItem>
                    <MenuItem component={<Link to="/master-data/purchase-type"/>} icon={<HiViewList/>}> Purchase Type </MenuItem>
                    <MenuItem component={<Link to="/master-data/company-setting"/>} icon={<HiViewList/>}> Company Setting </MenuItem>
                    <MenuItem component={<Link to="/master-data/related-bank"/>} icon={<HiViewList/>}> Related Bank </MenuItem>
                    <MenuItem component={<Link to="/master-data/signature"/>} icon={<HiViewList/>}> Signature </MenuItem>
                    {/*<MenuItem component={<Link to="/master-data/varieties"/>} icon={<HiViewList/>}> {t('Vehicles')} </MenuItem>*/}
                    <MenuItem component={<Link to="/master-data/vehicles"/>} icon={<HiViewList/>}> Vehicles </MenuItem>
                  </SubMenu>
                }
              </Menu>
            </Sidebar>

          </div>
          </aside>
    )
}

export default LeftMenu