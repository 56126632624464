import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, ScrollArea, Text} from "@mantine/core";
import {HiOutlineX} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";
import PoViewModel from "../PurchaseOrder/PoViewModel";
import View from "../Requisition/RequisitionView";
import {format} from "date-fns";
import {
    getBudgetDetails,
    setBudgetDetailsEmpty,
    setBudgetModelView,
    setBudgetViewID, setBudgetFetching
} from "../../../store/budget/budgetSlice";
import axios from "axios";
import { jsPDF } from "jspdf";

function BudgetViewModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [requisitionID,setRequisitionID] = useState('');
    const [viewModel, setViewModel] = useState(false);


    const [pdfView,setPdfView] = useState(false)
    const jspdfTest = new jsPDF('p','pt','letter')

    const DownloadPdf = (e) => {
        e.preventDefault()
        setPdfView(true);
        const val = document.getElementById("pdf");
        const data = {
            callback:function(jspdfTest){
                jspdfTest.save('demo.pdf')
            },
            margin:[10,10,10,10],
            autoPaging:'text'
        }

        jspdfTest.html(val,data)
    }

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_BUDGET_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_ONE";
    })
    const ROLE_BUDGET_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_TWO";
    })
    const ROLE_BUDGET_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_THREE";
    })

    const recViewID = useSelector((state) => state.receivedItemReducer.recViewID)
    const receiveDetailsData = useSelector((state) => state.receivedItemReducer.receiveDetailsData)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)

    const budgetViewID = useSelector((state) => state.budgetReducer.budgetViewID)
    const budgetDetails = useSelector((state) => state.budgetReducer.budgetDetails)

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(getBudgetDetails({
                id : budgetViewID
            }))
            setLoading(false)
        },500)
    },[loading])

    const budgetApprove = (e, id,status) => {
        let message = ''
        let messageDetails = ''
        if ( status=== 'Created'){
            message = 'Verify This Budget ?'
            messageDetails = ' Are you sure , you want to verify this budget? Press confirm , if you want to verify otherwise cancel.'
        }
        if ( status=== 'Verified'){
            message = 'Validate This Budget ?'
            messageDetails = ' Are you sure , you want to validate this budget? Press confirm , if you want to validate otherwise cancel.'
        }
        if ( status=== 'Validated'){
            message = 'Approve This Budget ?'
            messageDetails = ' Are you sure , you want to approve this budget? Press confirm , if you want to approve otherwise cancel.'
        }
        e.preventDefault();
        openConfirmModal({
            title: message,
            centered: true,
            children: (
                <Text size="sm">
                    {messageDetails}
                </Text>
            ),
            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
            confirmProps: { color: 'green' },
            // cancelProps: { color: 'red' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/approve`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },

                    params: {
                        "budget_id" : id,
                        "status" : status
                    }
                })
                    .then(res => {
                        setTimeout(()=>{
                            if (res.status === 200){
                                setLoading(true);
                            }
                        },500)

                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('BudgetDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setBudgetFetching(true))
                                        dispatch(setBudgetModelView(false))
                                        dispatch(setBudgetViewID(''))
                                        dispatch(setBudgetDetailsEmpty())
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2" >
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                    <>

                                                        <Fragment>
                                                            <div className="bg-gray-100" ref={pdfRef}>
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
{
    budgetDetails  &&
    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">

                    <>
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BudgetInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>
                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                            {
                                budgetDetails.projects_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('ProjectName')} : </span>
                                        {budgetDetails.projects_name}
                                    </p>
                                </li>
                            }

                            {
                                budgetDetails.name_of_work &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('NameOfWork')} : </span>
                                        {budgetDetails.name_of_work}
                                    </p>
                                </li>
                            }

                            {
                                budgetDetails.coordinator_email &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('ProjectCoordinator')} : </span>
                                        {budgetDetails.coordinator_usermame?budgetDetails.coordinator_usermame:budgetDetails.coordinator_email}
                                    </p>
                                </li>
                            }

                            {
                                budgetDetails.engineer_email &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('ProjectEngineer')} : </span>
                                        {budgetDetails.engineer_username?budgetDetails.engineer_username:budgetDetails.engineer_email}
                                    </p>
                                </li>
                            }
                            {
                                budgetDetails.budget_start_date &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('StartDate')} : </span>
                                        {budgetDetails.budget_start_date}
                                    </p>
                                </li>
                            }
                            {
                                budgetDetails.budget_end_date &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('EndDate')} : </span>
                                        {budgetDetails.budget_end_date}
                                    </p>
                                </li>
                            }
                        </ul>
                    </>
                </div>
            </div>
        </div>
    </div>
}



    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BudgetItemsInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                            (budgetDetails && budgetDetails['budget_item'] && budgetDetails['budget_item'].length > 0) &&
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SL')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('BudgetHead')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('BudgetAmount')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (budgetDetails && budgetDetails['budget_item'] && budgetDetails['budget_item'].length > 0) &&
                                    budgetDetails['budget_item'].map((item, index) => {
                                        return (
                                            <tr className={"bg-gray-100"} key={index}>
                                                <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.titles?item.titles:''}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                    { Number(item.amount).toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={2} className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total</td>
                                    <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                        TK. { Number(budgetDetails.total_item_amount).toFixed(2)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                    }
                </div>
            </div>
        </div>
    </div>



                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>

        { budgetDetails.path &&
            <>
                <a title="Budget Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/budget/pdf-show/'+ budgetDetails.id}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
            </>
        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row" >
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('CreatedApprovedInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        receiveDetailsData &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('SL')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('CreatedApproved')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Name')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Date')}</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                budgetDetails.created_name &&
                                <tr className={"bg-gray-100"} key="1">
                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">1</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">Created</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{budgetDetails.created_name}</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {format(new Date(budgetDetails.created_date), 'dd-MM-yyyy')}
                                    </td>
                                </tr>
                            }
                            {
                                budgetDetails.approve_one_name &&
                                <tr className={"bg-gray-100"} key="2">
                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">2</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">Verify</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{budgetDetails.approve_one_name}</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {format(new Date(budgetDetails.approve_one_date), 'dd-MM-yyyy')}
                                    </td>
                                </tr>
                            }
                            {
                                budgetDetails.approve_two_name &&
                                <tr className={"bg-gray-100"} key="3">
                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">3</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">Validate</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{budgetDetails.approve_two_name}</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {format(new Date(budgetDetails.approve_two_date), 'dd-MM-yyyy')}
                                    </td>
                                </tr>
                            }
                            {
                                budgetDetails.approve_three_name &&
                                <tr className={"bg-gray-100"} key="4">
                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">4</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">Approve</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{budgetDetails.approve_three_name}</td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {format(new Date(budgetDetails.approve_three_date), 'dd-MM-yyyy')}
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    </>

                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-left mb-3">

                    {
                        (budgetDetails.status === 'Created' && ROLE_BUDGET_APPROVE_ONE) &&
                        <button
                            onClick={(e) => budgetApprove(e, budgetDetails.id,budgetDetails.status)}
                            className="inline-flex items-center px-6 py-2 bg-indigo-600 text-white hover:bg-indigo-700  text-xs">
                            Verified

                        </button>
                    }
                    {
                        (budgetDetails.status === 'Verified' && ROLE_BUDGET_APPROVE_TWO) &&  <button
                            onClick={(e) => budgetApprove(e, budgetDetails.id,budgetDetails.status)}
                            className="inline-flex items-center px-6 py-2 bg-rose-600 text-white hover:bg-rose-700 text-xs">
                            Validated
                        </button>
                    }

                    {
                        (budgetDetails.status === 'Validated' && ROLE_BUDGET_APPROVE_THREE) &&  <button
                            onClick={(e) => budgetApprove(e, budgetDetails.id,budgetDetails.status)}
                            className="inline-flex items-center px-6 py-2 bg-lime-600 hover:bg-lime-700 text-white text-xs">
                            Approved
                        </button>
                    }


                </div>

                <div className="float-right mb-3">
                    <button
                        onClick={(e) => {
                            dispatch(setBudgetFetching(true))
                            dispatch(setBudgetModelView(false))
                            dispatch(setBudgetViewID(''))
                            dispatch(setBudgetDetailsEmpty())
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Close
                    </button>
                   {/* <button
                        onClick={(e) => {
                            DownloadPdf(e)
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Download
                    </button>*/}
                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

            {poViewModel?<PoViewModel />:''}
            {viewModel?<View requisitionID={requisitionID} setViewModel={setViewModel}/>:''}
            {pdfView?
                <Fragment>
                    <div className="bg-gray-100" id="pdf">
                        <div className="mt-2 bg-white">
                            <div className="relative">
                                {
                                    budgetDetails  &&
                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                <div className="mt-4">

                                                    <>
                                                        <div className="flex items-center">
                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BudgetInformation')}</h4>
                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                        </div>
                                                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                            {
                                                                budgetDetails.projects_name &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('ProjectName')} : </span>
                                                                        {budgetDetails.projects_name}
                                                                    </p>
                                                                </li>
                                                            }

                                                            {
                                                                budgetDetails.name_of_work &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('NameOfWork')} : </span>
                                                                        {budgetDetails.name_of_work}
                                                                    </p>
                                                                </li>
                                                            }

                                                            {
                                                                budgetDetails.coordinator_email &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('ProjectCoordinator')} : </span>
                                                                        {budgetDetails.coordinator_usermame?budgetDetails.coordinator_usermame:budgetDetails.coordinator_email}
                                                                    </p>
                                                                </li>
                                                            }

                                                            {
                                                                budgetDetails.engineer_email &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('ProjectEngineer')} : </span>
                                                                        {budgetDetails.engineer_username?budgetDetails.engineer_username:budgetDetails.engineer_email}
                                                                    </p>
                                                                </li>
                                                            }
                                                            {
                                                                budgetDetails.budget_start_date &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('StartDate')} : </span>
                                                                        {budgetDetails.budget_start_date}
                                                                    </p>
                                                                </li>
                                                            }
                                                            {
                                                                budgetDetails.budget_end_date &&
                                                                <li className="flex items-start lg:col-span-1">
                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                        <span className="font-bold ">{t('EndDate')} : </span>
                                                                        {budgetDetails.budget_end_date}
                                                                    </p>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }






                                {
                            (budgetDetails && budgetDetails['budget_item'] && budgetDetails['budget_item'].length > 0) &&
                            <table className="table table-auto " >
                                <tbody>
                                {
                                    (budgetDetails && budgetDetails['budget_item'] && budgetDetails['budget_item'].length > 0) &&
                                    budgetDetails['budget_item'].map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{++index}</td>
                                                <td>{item.titles?item.titles:''}</td>
                                                <td>
                                                    { Number(item.amount).toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>

                    }



                                                {
                                                    receiveDetailsData &&
                                                    <table className="table table-auto" >
                                                        <thead >
                                                        <tr>
                                                            <th>{t('SL')}</th>
                                                            <th>{t('CreatedApproved')}</th>
                                                            <th>{t('Name')}</th>
                                                            <th>{t('Date')}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            budgetDetails.created_name &&
                                                            <tr key="1">
                                                                <td>1</td>
                                                                <td>Created</td>
                                                                <td>{budgetDetails.created_name}</td>
                                                                <td>
                                                                    {format(new Date(budgetDetails.created_date), 'dd-MM-yyyy')}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            budgetDetails.approve_one_name &&
                                                            <tr key="2">
                                                                <td>2</td>
                                                                <td>Verify</td>
                                                                <td>{budgetDetails.approve_one_name}</td>
                                                                <td>
                                                                    {format(new Date(budgetDetails.approve_one_date), 'dd-MM-yyyy')}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            budgetDetails.approve_two_name &&
                                                            <tr key="3">
                                                                <td>3</td>
                                                                <td>Validate</td>
                                                                <td>{budgetDetails.approve_two_name}</td>
                                                                <td>
                                                                    {format(new Date(budgetDetails.approve_two_date), 'dd-MM-yyyy')}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            budgetDetails.approve_three_name &&
                                                            <tr key="4">
                                                                <td>4</td>
                                                                <td>Approve</td>
                                                                <td>{budgetDetails.approve_three_name}</td>
                                                                <td>
                                                                    {format(new Date(budgetDetails.approve_three_date), 'dd-MM-yyyy')}
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>

                                                }


                            </div>
                        </div>
                    </div>
                </Fragment>


                :''}
        </>
    )
}

export default BudgetViewModel