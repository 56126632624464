import React, {useEffect} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,
    HiPencil, HiEye, HiCloudUpload
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActionIcon, Box, Group, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {vendorDropdown} from "../../../store/vendor/vendorSlice";
import { projectDropdownUserWise} from "../../../store/project/projectSlice";
import {
    PaymentRequestList,
    setPage,
    setSortStatus,
    setOpened,
    setFetching,
    setActiveTab,
    setRefresh,
    setFilterData,
    setReadyIdChecked,
    setReadyIdUnChecked,
    setPaymentVerifyModel,
    setVerifyFromSubmit,
    setPaymentIdChecked,
    setPaymentIdUnChecked,
    setPaymentPayModel,
    setSendBackModel,
    setSendBackPOID,
    setSendBackRFPID,
    setPaymentViewID,
    setPaymentViewModel,
    setPaymentUpdateID,
    setPaymentUpdateModel,
    setPaymentVerifyTopSheetModel,
    setPaymentConfirmationCSVuploadmessage,
    setEmptyPaymentIdUnChecked
} from "../../../store/payment/paymentSlice";
import {
    setSpinner
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import PayVerifyModel from "./PayVerifyModel";
import {showNotification} from "@mantine/notifications";
import PayPaymentModel from "./PayPaymentModel";
import SendBackModel from "./SendBackModel";
const PAGE_SIZE = 20;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import PayViewModel from "./PayViewModel";
import PayUpdateModel from "./PayUpdateModel";
import PayVerifyTopSheetModel from "./PayVerifyTopSheetModel";
import PaymentFilter from "./PaymentFilter";

function Dashboard(){
    const {t, i18n} = useTranslation();
    const containerWidth = localStorage.getItem('containerWidth')
    const tableHeightWithFilter = localStorage.getItem('tableHeightWithFilter')

    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_REQUEST_FOR_PAYMENT = decoded.roles.find(role => {
        return role === "ROLE_REQUEST_FOR_PAYMENT";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_PAYMENT_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PAYMENT_APPROVE_ONE";
    })
    const ROLE_PAYMENT_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_PAYMENT_APPROVE_TWO";
    })
    const ROLE_PAYMENT_EDIT = decoded.roles.find(role => {
        return role === "ROLE_PAYMENT_EDIT";
    })
    const ROLE_PAYMENT_VIEW = decoded.roles.find(role => {
        return role === "ROLE_PAYMENT_VIEW";
    })

    const activeTab = useSelector((state) => state.paymentReducer.activeTab)
    const paymentRequestData = useSelector((state) => state.paymentReducer.paymentRequestData)
    const paymentVerifyData = useSelector((state) => state.paymentReducer.paymentVerifyData)
    const paymentArchiveData = useSelector((state) => state.paymentReducer.paymentArchiveData)
    const readyIdChecked = useSelector((state) => state.paymentReducer.readyIdChecked)
    const paymentIdChecked = useSelector((state) => state.paymentReducer.paymentIdChecked)
    const paymentVerifyModel = useSelector((state) => state.paymentReducer.paymentVerifyModel)
    const paymentPayModel = useSelector((state) => state.paymentReducer.paymentPayModel)
    const verifyFromSubmit = useSelector((state) => state.paymentReducer.verifyFromSubmit)

    const fetching = useSelector((state) => state.paymentReducer.fetching)
    const totalItem = useSelector((state) => state.paymentReducer.totalItem)
    const page = useSelector((state) => state.paymentReducer.page)
    const sortStatus = useSelector((state) => state.paymentReducer.sortStatus)
    const refresh = useSelector((state) => state.paymentReducer.refresh)
    const filterData = useSelector((state) => state.paymentReducer.filterData)
    const sendBackModel = useSelector((state) => state.paymentReducer.sendBackModel)
    const paymentViewModel = useSelector((state) => state.paymentReducer.paymentViewModel)
    const paymentUpdateModel = useSelector((state) => state.paymentReducer.paymentUpdateModel)
    const paymentVerifyTopSheetModel = useSelector((state) => state.paymentReducer.paymentVerifyTopSheetModel)


    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(()=>{
        dispatch(vendorDropdown())
        dispatch(projectDropdownUserWise())
        if (activeTab == 'payment-archive'){
            dispatch(setSortStatus({...sortStatus,['columnAccessor']:'payment_date'}))
        }else {
            dispatch(setSortStatus({...sortStatus,['columnAccessor']:'payment_request_date'}))
        }
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            dispatch(setVerifyFromSubmit(false))
        },1000)
    },[])

    {
        verifyFromSubmit &&
        showNotification({
            id: 'load-data',
            loading: true,
            title: 'Payment Verified',
            message: 'Ready for payment verify , for payment',
            autoClose: 700,
            disallowClose: true,
            color: 'green',
        });
    }

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            po : filterData.po ? filterData.po:'',
            vendor : filterData.vendor?filterData.vendor:'',
            buyer : filterData.buyer?filterData.buyer:'',
            start_date : filterData.start_date?filterData.start_date:'',
            end_date : filterData.end_date?filterData.end_date:'',
        }
        dispatch(PaymentRequestList(param))
    }, [page,activeTab,sortStatus,fetching,refresh]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setEmptyPaymentIdUnChecked());
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
        dispatch(
            setFilterData({
                po_id : '',po:'',vendor:''
            })
        )
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);

    let csvData = [];
    let fileName = "Reports";

    if (activeTab === 'payment-request'){
         csvData = paymentRequestData;
         fileName = 'payment-request'
    }
    if (activeTab === 'payment-verify'){
        csvData = paymentVerifyData;
        fileName = 'payment-verify'
    }
    if (activeTab === 'payment-archive'){
        csvData = paymentArchiveData;
        fileName = 'payment-archive'
    }


    return(
        <>
            <main className="flex flex-1 flex-1" >
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Payment')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllPaymentRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            {
                                                                (activeTab ==='payment-verify') &&
                                                                <button className={`inline-flex items-center px-2 py-1 bg-blue-600 text-white text-xs`}
                                                                        onClick={(e) => {
                                                                            dispatch(setPaymentConfirmationCSVuploadmessage())
                                                                            dispatch(setPaymentVerifyTopSheetModel(true))
                                                                        }}>
                                                                    <HiCloudUpload size={16} className={'mr-1'}/>{t('PaymentVerifyTopSheet')}
                                                                </button>
                                                            }

                                                            {/*<button
                                                                onClick={() => dispatch(setOpened(true))}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>*/}

                                                            {/*<button
                                                                    className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('Payment')}
                                                            </button>*/}

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                                    <PaymentFilter/>
                                                                </form>

                                                                    <div className="h-11">
                                                                        {
                                                                            (ROLE_REQUEST_FOR_PAYMENT || ROLE_SUPER_ADMIN || ROLE_PAYMENT_VIEW || ROLE_PAYMENT_EDIT || ROLE_PAYMENT_APPROVE_TWO || ROLE_PAYMENT_APPROVE_ONE) &&
                                                                            <>
                                                                                <button
                                                                                    className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'payment-request' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                    onClick={(e) => HandelPoTabChange(e, 'payment-request')}>
                                                                                    {t('PaymentRequest')}
                                                                                </button>

                                                                                <button
                                                                                    className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'payment-verify' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                    onClick={(e) => HandelPoTabChange(e, 'payment-verify')}>
                                                                                    {t('PaymentVerified')}
                                                                                </button>

                                                                                <button
                                                                                    className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'payment-archive' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                    onClick={(e) => HandelPoTabChange(e, 'payment-archive')}>
                                                                                    {t('PaymentArchive')}
                                                                                </button>
                                                                            </>
                                                                        }


                                                                        {
                                                                            (activeTab === 'payment-request' && readyIdChecked.length >= 1 && (ROLE_SUPER_ADMIN || ROLE_PAYMENT_APPROVE_ONE)) &&
                                                                            <button
                                                                                className={`inline-flex items-center ml-5 px-2 py-1 h-10 bg-rose-600 hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => {
                                                                                    dispatch(setPaymentVerifyModel(true))
                                                                                }}>
                                                                                {t('VerifyForPayment')}
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (activeTab === 'payment-verify' && paymentIdChecked.length >= 1 && (ROLE_SUPER_ADMIN || ROLE_PAYMENT_APPROVE_TWO)) &&
                                                                            <button
                                                                                className={`inline-flex items-center ml-5 px-2 py-1 h-10 bg-rose-600 hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => {
                                                                                    dispatch(setPaymentPayModel(true))
                                                                                }}>
                                                                                {t('Payment')}
                                                                            </button>
                                                                        }


                                                                    </div>

                                                                    {
                                                                        (ROLE_REQUEST_FOR_PAYMENT || ROLE_SUPER_ADMIN || ROLE_PAYMENT_VIEW || ROLE_PAYMENT_EDIT || ROLE_PAYMENT_APPROVE_TWO || ROLE_PAYMENT_APPROVE_ONE) &&
                                                                        <Box sx={{
                                                                            height: tableHeightWithFilter,
                                                                            width: containerWidth
                                                                        }}>
                                                                            {
                                                                                activeTab === 'payment-request' &&
                                                                                <DataTable
                                                                                    withBorder
                                                                                    records={paymentRequestData}
                                                                                    withColumnBorders={1}
                                                                                    columns={[
                                                                                        {
                                                                                            accessor: 'id',
                                                                                            title: '',
                                                                                            render: ({id, po_id}) =>
                                                                                                <>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="ready_id[]"
                                                                                                        value={id}
                                                                                                        onClick={(e) => {
                                                                                                            if (e.target.checked === true) {
                                                                                                                dispatch(setReadyIdChecked(e.target.value))
                                                                                                                dispatch(setFilterData({
                                                                                                                    ...filterData,
                                                                                                                    ['po']: po_id
                                                                                                                }))
                                                                                                                dispatch(setFetching(true))
                                                                                                            } else {
                                                                                                                dispatch(setReadyIdUnChecked(e.target.value))
                                                                                                                if (readyIdChecked.length === 1) {
                                                                                                                    dispatch(setFilterData({
                                                                                                                        ...filterData,
                                                                                                                        ['po']: null
                                                                                                                    }))
                                                                                                                    dispatch(setFetching(true))
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_request_date',
                                                                                            title: <Text mr="xs">Pay.
                                                                                                Req. Date</Text>,
                                                                                            sortable: true,
                                                                                            width: 110
                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_id',
                                                                                            title: <Text mr="xs">PO
                                                                                                No</Text>,
                                                                                            sortable: true,
                                                                                        },

                                                                                        // {accessor: 'grn_no',title: 'GRN No',sortable:true},
                                                                                        {
                                                                                            accessor: 'grn_no',
                                                                                            title: 'GRN No',
                                                                                            render: ({
                                                                                                         grn_no,
                                                                                                         adv_verify_email
                                                                                                     }) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (adv_verify_email && adv_verify_email != '' ? 'Advance' : grn_no)
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'vendorsName',
                                                                                            title: 'Vendor/Buyer',
                                                                                            render: ({
                                                                                                         vendor_name,
                                                                                                         buyer_name
                                                                                                     }) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (vendor_name ? vendor_name : '')
                                                                                                    }
                                                                                                    {
                                                                                                        (buyer_name ? buyer_name : '')
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_amount',
                                                                                            title: 'PO Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({po_amount}) =>
                                                                                                <>
                                                                                                    {po_amount && po_amount.toFixed(2)}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'grn_amount',
                                                                                            title: 'GRN Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({grn_amount}) =>
                                                                                                <>
                                                                                                    {grn_amount && grn_amount.toFixed(2)}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_type',
                                                                                            title: 'Payment Type'
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_from',
                                                                                            title: 'Local/Head Office',
                                                                                            render: ({payment_from}) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (payment_from && payment_from === 1 ? 'Local Office' : 'Head Office')
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'billNumber',
                                                                                            title: 'Bill No',
                                                                                            sortable: true
                                                                                        },
                                                                                        {
                                                                                            accessor: 'verify_email',
                                                                                            title: 'Verify By',
                                                                                            render: ({
                                                                                                         verify_email,
                                                                                                         adv_verify_email
                                                                                                     }) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (verify_email && verify_email != '' ? verify_email : adv_verify_email)
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'actions',
                                                                                            title: <Text
                                                                                                mr="xs">Action</Text>,
                                                                                            textAlignment: 'center',
                                                                                            width: 100,
                                                                                            render: (item) => (
                                                                                                <Group spacing={4}
                                                                                                       position="right"
                                                                                                       noWrap>
                                                                                                    <button
                                                                                                        className="inline-flex items-center px-2 py-1 bg-rose-500 hover:bg-rose-600 text-white text-xs"
                                                                                                        onClick={(e) => {
                                                                                                            dispatch(setSendBackPOID(item.po_id))
                                                                                                            dispatch(setSendBackRFPID(item.id))
                                                                                                            dispatch(setSendBackModel(true))
                                                                                                        }}
                                                                                                    >
                                                                                                        Send Back
                                                                                                    </button>
                                                                                                </Group>
                                                                                            ),
                                                                                        },

                                                                                    ]}
                                                                                    totalRecords={totalItem}
                                                                                    recordsPerPage={PAGE_SIZE}
                                                                                    page={page}
                                                                                    onPageChange={(p) => {
                                                                                        dispatch(setPage(p))
                                                                                        dispatch(setFetching(true))
                                                                                    }}
                                                                                    fetching={fetching}
                                                                                    loaderSize="xs"
                                                                                    loadercolor="blue"
                                                                                    loaderBackgroundBlur={1}
                                                                                    sortStatus={sortStatus}
                                                                                    onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                                />
                                                                            }
                                                                            {
                                                                                activeTab === 'payment-verify' &&
                                                                                <DataTable
                                                                                    withColumnBorders={1}
                                                                                    withBorder
                                                                                    records={paymentVerifyData}
                                                                                    columns={[
                                                                                        {
                                                                                            accessor: 'vendor_id',
                                                                                            title: '',
                                                                                            render: ({
                                                                                                         id,
                                                                                                         vendor_id,
                                                                                                         buyer_id
                                                                                                     }) =>
                                                                                                <>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="vendor_id[]"
                                                                                                        value={vendor_id ? vendor_id : ''}
                                                                                                        onClick={(e) => {
                                                                                                            if (e.target.checked === true) {
                                                                                                                dispatch(setPaymentIdChecked(id))
                                                                                                                if (vendor_id) {
                                                                                                                    dispatch(setFilterData({
                                                                                                                        ...filterData,
                                                                                                                        ['vendor']: vendor_id
                                                                                                                    }))
                                                                                                                }
                                                                                                                if (buyer_id) {
                                                                                                                    dispatch(setFilterData({
                                                                                                                        ...filterData,
                                                                                                                        ['buyer']: buyer_id
                                                                                                                    }))
                                                                                                                }
                                                                                                                dispatch(setPage(1))
                                                                                                                dispatch(setFetching(true))
                                                                                                            } else {
                                                                                                                dispatch(setPaymentIdUnChecked(id))
                                                                                                                if (paymentIdChecked.length === 1) {
                                                                                                                    if (vendor_id) {
                                                                                                                        dispatch(setFilterData({
                                                                                                                            ...filterData,
                                                                                                                            ['vendor']: null
                                                                                                                        }))
                                                                                                                    }
                                                                                                                    if (buyer_id) {
                                                                                                                        dispatch(setFilterData({
                                                                                                                            ...filterData,
                                                                                                                            ['buyer']: null
                                                                                                                        }))
                                                                                                                    }
                                                                                                                    dispatch(setPage(1))
                                                                                                                    dispatch(setFetching(true))
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'id',
                                                                                            title: <Text mr="xs">Pay.
                                                                                                ID</Text>,
                                                                                            sortable: true,
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_request_date',
                                                                                            title: <Text mr="xs">Pay.
                                                                                                Req. Date</Text>,
                                                                                            sortable: true,
                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_id',
                                                                                            title: <Text mr="xs">PO
                                                                                                No</Text>,
                                                                                            sortable: true,
                                                                                        },

                                                                                        {
                                                                                            accessor: 'vendorsName',
                                                                                            title: 'Vendor/Buyer',
                                                                                            render: ({
                                                                                                         vendor_name,
                                                                                                         buyer_name
                                                                                                     }) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (vendor_name ? vendor_name : '')
                                                                                                    }
                                                                                                    {
                                                                                                        (buyer_name ? buyer_name : '')
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_amount',
                                                                                            title: 'PO Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({po_amount}) =>
                                                                                                <>
                                                                                                    {po_amount ? po_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'requestAmount',
                                                                                            title: 'Req. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({requestAmount}) =>
                                                                                                <>
                                                                                                    {requestAmount ? requestAmount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'advance_payment_amount',
                                                                                            title: 'Adv. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({advance_payment_amount}) =>
                                                                                                <>
                                                                                                    {advance_payment_amount ? advance_payment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'adjustment_amount',
                                                                                            title: 'Adj. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({adjustment_amount}) =>
                                                                                                <>
                                                                                                    {adjustment_amount ? adjustment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_amount',
                                                                                            title: 'Pay. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({payment_amount}) =>
                                                                                                <>
                                                                                                    {payment_amount ? payment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_type',
                                                                                            title: 'Pay. Type'
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_from',
                                                                                            title: 'Local/Head Office',
                                                                                            render: ({payment_from}) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (payment_from && payment_from === 1 ? 'Local Office' : 'Head Office')
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'billNumber',
                                                                                            title: 'Bill No',
                                                                                            sortable: true
                                                                                        }
                                                                                    ]}
                                                                                    totalRecords={totalItem}
                                                                                    recordsPerPage={PAGE_SIZE}
                                                                                    page={page}
                                                                                    onPageChange={(p) => {
                                                                                        dispatch(setPage(p))
                                                                                        dispatch(setFetching(true))
                                                                                    }}
                                                                                    fetching={fetching}
                                                                                    loaderSize="xs"
                                                                                    loadercolor="blue"
                                                                                    loaderBackgroundBlur={1}
                                                                                    sortStatus={sortStatus}
                                                                                    onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                                />
                                                                            }
                                                                            {
                                                                                activeTab === 'payment-archive' &&
                                                                                <DataTable
                                                                                    withBorder
                                                                                    records={paymentArchiveData}
                                                                                    withColumnBorders={1}
                                                                                    columns={[
                                                                                        {
                                                                                            accessor: 'index',
                                                                                            title: 'SL#',
                                                                                            textAlignment: 'right',
                                                                                            render: (item) => (paymentArchiveData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                        },
                                                                                        {
                                                                                            accessor: 'id',
                                                                                            title: <Text mr="xs">Pay.
                                                                                                ID</Text>,
                                                                                            sortable: true,
                                                                                            width: 90
                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_created_date',
                                                                                            title: <Text mr="xs">Po
                                                                                                Date</Text>,
                                                                                            sortable: true,
                                                                                            width: 110
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_request_date',
                                                                                            title: <Text mr="xs">Req.
                                                                                                Date</Text>,
                                                                                            sortable: true,
                                                                                            width: 110
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_date',
                                                                                            title: <Text mr="xs">Pay.
                                                                                                Date</Text>,
                                                                                            sortable: true,
                                                                                            width: 110
                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_id',
                                                                                            title: <Text mr="xs">PO
                                                                                                No</Text>,
                                                                                            sortable: true,
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_type',
                                                                                            title: 'Payment Type',
                                                                                            width: 200
                                                                                        },
                                                                                        {
                                                                                            accessor: 'billNumber',
                                                                                            title: 'Bill No'
                                                                                        },

                                                                                        {
                                                                                            accessor: 'vendorsName',
                                                                                            title: 'Vendor/Buyer',
                                                                                            render: ({
                                                                                                         vendor_name,
                                                                                                         buyer_name
                                                                                                     }) =>
                                                                                                <>
                                                                                                    {
                                                                                                        (vendor_name ? vendor_name : '')
                                                                                                    }
                                                                                                    {
                                                                                                        (buyer_name ? buyer_name : '')
                                                                                                    }
                                                                                                </>

                                                                                        },
                                                                                        {
                                                                                            accessor: 'po_amount',
                                                                                            title: 'PO Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({po_amount}) =>
                                                                                                <>
                                                                                                    {po_amount && po_amount.toFixed(2)}
                                                                                                </>
                                                                                        },

                                                                                        {
                                                                                            accessor: 'advance_payment_amount',
                                                                                            title: 'Adv. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({advance_payment_amount}) =>
                                                                                                <>
                                                                                                    {advance_payment_amount ? advance_payment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'adjustment_amount',
                                                                                            title: 'Adj. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({adjustment_amount}) =>
                                                                                                <>
                                                                                                    {adjustment_amount ? adjustment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'payment_amount',
                                                                                            title: 'Pay. Amount',
                                                                                            textAlignment: "right",
                                                                                            sortable: true,
                                                                                            render: ({payment_amount}) =>
                                                                                                <>
                                                                                                    {payment_amount ? payment_amount.toFixed(2) : 0.00}
                                                                                                </>
                                                                                        },
                                                                                        {
                                                                                            accessor: 'actions',
                                                                                            title: <Text
                                                                                                mr="xs">Action</Text>,
                                                                                            textAlignment: 'right',
                                                                                            render: (item) => (
                                                                                                <Group spacing={4}
                                                                                                       position="right"
                                                                                                       noWrap>
                                                                                                    {
                                                                                                        (ROLE_SUPER_ADMIN || ROLE_PAYMENT_EDIT) &&
                                                                                                        <ActionIcon
                                                                                                            color="green">
                                                                                                            <HiPencil
                                                                                                                size={16}
                                                                                                                onClick={(e) => {
                                                                                                                    dispatch(setPaymentUpdateID(item.id))
                                                                                                                    dispatch(setPaymentUpdateModel(true))
                                                                                                                }}/>
                                                                                                        </ActionIcon>
                                                                                                    }

                                                                                                    {
                                                                                                        (ROLE_SUPER_ADMIN || ROLE_PAYMENT_VIEW) &&
                                                                                                        <ActionIcon
                                                                                                            color="blue">
                                                                                                            <HiEye
                                                                                                                size={16}
                                                                                                                onClick={(e) => {
                                                                                                                    dispatch(setPaymentViewID(item.id))
                                                                                                                    dispatch(setPaymentViewModel(true))
                                                                                                                }}/>
                                                                                                        </ActionIcon>
                                                                                                    }
                                                                                                </Group>
                                                                                            ),
                                                                                        },

                                                                                    ]}
                                                                                    totalRecords={totalItem}
                                                                                    recordsPerPage={PAGE_SIZE}
                                                                                    page={page}
                                                                                    onPageChange={(p) => {
                                                                                        dispatch(setPage(p))
                                                                                        dispatch(setFetching(true))
                                                                                    }}
                                                                                    fetching={fetching}
                                                                                    loaderSize="xs"
                                                                                    loadercolor="blue"
                                                                                    loaderBackgroundBlur={1}
                                                                                    sortStatus={sortStatus}
                                                                                    onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                                />
                                                                            }
                                                                        </Box>
                                                                    }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {paymentVerifyModel?<PayVerifyModel />:''}
            {sendBackModel?<SendBackModel />:''}
            {paymentPayModel?<PayPaymentModel />:''}
            {paymentViewModel?<PayViewModel />:''}
            {paymentUpdateModel?<PayUpdateModel />:''}
            {paymentVerifyTopSheetModel?<PayVerifyTopSheetModel />:''}
        </>
    )
}
export default Dashboard