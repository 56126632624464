import axios from "axios";


export const createDeliveryTempItemsTemp = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/create-temp-delivery`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.id
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getDeliveryTempDetails = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/temp-details`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postInlineUpdateDeliveryTemp = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/inline-update/temp-delivery`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postTempItemAdded = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/temp-item-added`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getLedgerDropdown = async () => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/erp-ledger/group-wise/dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getRemainingQuantityByItemProject = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/item-project-wise-rem-qty`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postTempItemInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/temp-item-inline-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postTempItemDelete = async (id) => {
    let returnData = [];
    await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/temp-item/delete/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};




export const getDeliveryItemDelete = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/delivery-item/delete`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};




export const postTempDataInsertDelivery = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/insert-delivery`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getIssueToCustomerDeliveryIndex = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/list/${param.activeTab}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "offset": param.offset,
            "page" : param.page,
            "order_type": param.order_type,
            "order_by": param.order_by,
            "project": param.project,
            "get_pass": param.get_pass,
            "item": param.item,
            "delivery_type" : param.delivery_type,
            "deliveryDate" : param.deliveryDate
    }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};




export const getDeliveryDetails = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/details`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getDeliveryApprove = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/approve-delivery`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

