import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createStoreRequisition,
    createStoreRequisitionItem, getStoreRequisitionDelete, getStoreRequisitionDelivery,
    getStoreRequisitionDetailsData,
    getStoreRequisitionIndex, getStoreRequisitionItemDelete,
    getStoreRequisitionReceived,
    inlineUpdateStoreRequisition, inlineUpdateStoreRequisitionAttachment,
    inlineUpdateStoreRequisitionItem,
    storeRequisitionApprove,
    storeRequisitionFinalUpdate
} from "../../services/StoreRequisitionService";

export const createEmptyStoreRequisition = createAsyncThunk("store-requisition/create", async (data) => {
    try {
        const response = createStoreRequisition(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const storeRequisitionInlineUpdate = createAsyncThunk("store-requisition/inline-update", async (data) => {
    try {
        const response = inlineUpdateStoreRequisition(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const storeRequisitionInlineUpdateAttachment = createAsyncThunk("store-requisition/inline-update-attachment", async (data) => {
    try {
        const response = inlineUpdateStoreRequisitionAttachment(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const createStoreRequisitionItems = createAsyncThunk("store-requisition-item/create", async (data) => {
    try {
        const response = createStoreRequisitionItem(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const storeRequisitionDetails = createAsyncThunk("store-requisition/details", async (data) => {
    try {
        const response = getStoreRequisitionDetailsData(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const storeRequisitionItemInlineUpdate = createAsyncThunk("store-requisition-item/inline-update", async (data) => {
    try {
        const response = inlineUpdateStoreRequisitionItem(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const finalUpdateStoreRequisition = createAsyncThunk("store-requisition/final-update", async (data) => {
    try {
        const response = storeRequisitionFinalUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getStoreRequisitionList = createAsyncThunk("store-requisition/index", async (param) => {
    try {
        const response = getStoreRequisitionIndex(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const approveStoreRequisition = createAsyncThunk("store-requisition/approve", async (data) => {
    try {
        const response = storeRequisitionApprove(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const storeRequisitionReceived = createAsyncThunk("store-requisition/received", async (data) => {
    try {
        const response = getStoreRequisitionReceived(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const storeRequisitionDelivered = createAsyncThunk("store-requisition/delivery", async (id) => {
    try {
        const response = getStoreRequisitionDelivery(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const storeRequisitionDelete = createAsyncThunk("store-requisition/delete", async (id) => {
    try {
        const response = getStoreRequisitionDelete(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const storeRequisitionItemDelete = createAsyncThunk("store-requisition-item/delete", async (id) => {
    try {
        const response = getStoreRequisitionItemDelete(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const storeRequisitionSlice = createSlice({
    name : "store-requisition",
    initialState : {
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('store_requisition_page') ? localStorage.getItem('store_requisition_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'id', direction: 'DESC' },
        filterData : {project_from:'',project_to:'',sr_number:''},
        fromData : {project_from:null,project_to:null,note:null},
        itemFromData : {item_id:null,sub_category_id:null,category_id:null,quantity:null,remark:null,},
        storeRequisitionCreateMsg : null,
        storeRequisitionCreateID : null,
        storeRequisitionInlineUpdateMsg : null,
        storeRequisitionItemCreateMsg : null,
        storeRequisitionItemInlineUpdateMsg : null,
        storeRequisitionfinalUpdateMsg : null,
        storeRequisitionDetailsData : [],
        storeRequisitionIndex : [],
        spinner : false,
        refresh : false,
        viewModel : false,
        viewID : null,
        storeRequisitionItems : [],
        approveMessage : null,
        storeRequisitionDeleteMessage : null,
        receivedMessage : null,
        deliveryMessage : null
    },
    reducers : {
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.project_from = action.payload.project_from;
            state.filterData.project_to = action.payload.project_to;
            state.filterData.sr_number = action.payload.sr_number;
        },
        setFromData : (state,action) => {
            state.fromData.project_from = action.payload.project_from;
            state.fromData.project_to = action.payload.project_to;
        },
        setStoreRequisitionCreateMsg : (state,action) => {
            state.storeRequisitionCreateMsg = action.payload;
        },
        setStoreRequisitionCreateID : (state,action) => {
            state.storeRequisitionCreateID = action.payload;
        },
        setItemFromData : (state,action) => {
            state.itemFromData.item_id = action.payload.item_id
            state.itemFromData.sub_category_id = action.payload.sub_category_id
            state.itemFromData.category_id = action.payload.category_id
            state.itemFromData.quantity = action.payload.quantity
            state.itemFromData.remark = action.payload.remark
            state.itemFromData.note = action.payload.note
        },
        setFinalUpdateEmptyMsg : (state,action) => {
            state.storeRequisitionfinalUpdateMsg = null
        },
        setViewModel : (state,action) => {
            state.viewModel = action.payload
        },
        setViewId : (state,action) => {
            state.viewID = action.payload
        },
        setModelAllDataEmpty : (state,action) => {
            state.storeRequisitionDetailsData = []
            state.approveMessage = null
            state.storeRequisitionItems = []
            state.fetching = true
        },

        },
    extraReducers : (builder) => {

        builder.addCase(createEmptyStoreRequisition.fulfilled,(state,action) => {
            state.storeRequisitionCreateMsg = action.payload.message
            state.storeRequisitionCreateID = action.payload.last_id
            state.itemFromData.item_id = null
            state.itemFromData.sub_category_id = null
            state.itemFromData.category_id = null
            state.itemFromData.quantity = null
            state.itemFromData.remark = null
            state.itemFromData.note = null
            state.fromData.project_from = null;
            state.fromData.project_to = null;
        })
        builder.addCase(storeRequisitionInlineUpdate.fulfilled,(state,action) => {
            state.storeRequisitionInlineUpdateMsg = action.payload
        })
        builder.addCase(storeRequisitionInlineUpdateAttachment.fulfilled,(state,action) => {
            state.storeRequisitionInlineUpdateMsg = action.payload
        })
        builder.addCase(storeRequisitionDelete.fulfilled,(state,action) => {
            state.storeRequisitionDeleteMessage = action.payload
        })
        builder.addCase(storeRequisitionItemDelete.fulfilled,(state,action) => {
            state.storeRequisitionDeleteMessage = action.payload
        })
        builder.addCase(createStoreRequisitionItems.fulfilled,(state,action) => {
            state.storeRequisitionItemCreateMsg = action.payload
            state.itemFromData.item_id = null
            state.itemFromData.sub_category_id = null
            state.itemFromData.category_id = null
            state.itemFromData.quantity = null
            state.itemFromData.remark = null
            state.itemFromData.note = null
        })
        builder.addCase(storeRequisitionDetails.fulfilled,(state,action) => {
            state.storeRequisitionDetailsData = action.payload
            state.storeRequisitionItems = action.payload.storeRequisitionItems
        })
        builder.addCase(storeRequisitionItemInlineUpdate.fulfilled,(state,action) => {
            state.storeRequisitionItemInlineUpdateMsg = action.payload
        })
        builder.addCase(finalUpdateStoreRequisition.fulfilled,(state,action) => {
            state.storeRequisitionfinalUpdateMsg = action.payload
            state.itemFromData.item_id = null
            state.itemFromData.sub_category_id = null
            state.itemFromData.category_id = null
            state.itemFromData.quantity = null
            state.itemFromData.remark = null
            state.itemFromData.note = null
            state.storeRequisitionCreateMsg = null
            state.storeRequisitionCreateID = null
            state.storeRequisitionInlineUpdateMsg = null
            state.storeRequisitionItemCreateMsg = null
            state.storeRequisitionItemInlineUpdateMsg = null
            state.fromData.project_from = null;
            state.fromData.project_to = null;
        })

        builder.addCase(getStoreRequisitionList.fulfilled,(state,action) => {
            state.storeRequisitionIndex = action.payload.data
            state.totalItem = action.payload.total
            state.fetching = false
        })

        builder.addCase(approveStoreRequisition.fulfilled,(state,action) => {
            state.approveMessage = action.payload
        })
        builder.addCase(storeRequisitionReceived.fulfilled,(state,action) => {
            state.receivedMessage = action.payload.message
        })
        builder.addCase(storeRequisitionDelivered.fulfilled,(state,action) => {
            state.deliveryMessage = action.payload.message
        })
    }
})

export const { setFromData,setStoreRequisitionCreateID,setStoreRequisitionCreateMsg,setItemFromData,setFinalUpdateEmptyMsg,setFetching,setFilterData,setActiveTab,setPage,setOpened,setSortStatus,setRefresh,setSpinner,setViewModel,setViewId,setModelAllDataEmpty } = storeRequisitionSlice.actions
export default storeRequisitionSlice.reducer;