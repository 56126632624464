import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiOutlineExclamation, HiArrowCircleLeft, HiArrowCircleRight
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {
    Group,
    MultiSelect,
    ScrollArea,
    Select,
    Text,
} from "@mantine/core";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import vendorReducer, {
    setSpinner,
    setOpened, vendorShow, vendorDetails, setEmptyVendorDetailsData,
} from "../../../../store/vendor/vendorSlice";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {useMantineTheme} from "@mantine/styles";
import toast from "react-hot-toast";

function EditVendor() {
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const {id} = useParams();
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);


    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })


    const [pdfFile, setPdfFile] = useState([]);
    const [complianceStatus, setComplianceStatus] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [areaData, setAreaData] = useState(null);
    const [itemTypeData, setItemTypeData] = useState([]);
    const [vendorDetailsData, setVendorDetailsData] = useState([]);
    const [address, setAddress] = useState(null);


    // const vendorDetailsData = useSelector((state) => state.vendorReducer.vendorDetailsData)

    //Form validation
    const validationSchema = Yup.object().shape({
        vendorName: Yup.string().required(t("EnterVendorName")),
        contractPerson: Yup.string().required(t("EnterContactPerson")),
        contractNo: Yup.string().required(t("EnterContactNo")),
        email: Yup.string().required(t("EnterEmail")),
        tradeLicenseNo: Yup.string().required(t("EnterTradeLicenseNo")),
        tinCertificateNo: Yup.string().required(t("EnterTinCertificateNo")),
        vatCertificateNo: Yup.string().required(t("EnterVatCertificateNo")),
        bankAccountNo: Yup.string().required(t("EnterBankAccountNo")),
        bankAccountName: Yup.string().required(t("EnterBankAccountName")),
        branchName: Yup.string().required(t("EnterBranchName")),
        address: Yup.string().required(t("EnterAddress")),
        paymentType: Yup.string().required(t("SelectPaymentType")),
        area: Yup.string().required(t("SelectArea"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setError, setValue} = useForm(formOptions);
    const {errors} = formState;




    useEffect(() => {
        setTimeout(()=>{
            // dispatch(vendorDetails(id))
             axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/vendor/details/${id}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                }
            })
                .then(res => {
                    if (res.status === 200){
                        // data = res.data.data
                        setVendorDetailsData(res.data.data)
                        if (res.data.data.vendorsName){
                            setValue('vendorName',res.data.data.vendorsName)
                        }
                        if (res.data.data.contractPerson){
                            setValue('contractPerson',res.data.data.contractPerson)
                        }
                        if (res.data.data.contractNo){
                            setValue('contractNo',res.data.data.contractNo)
                        }
                        if (res.data.data.bankAccountName){
                            setValue('bankAccountName',res.data.data.bankAccountName)
                        }
                        if (res.data.data.bankAccountNo){
                            setValue('bankAccountNo',res.data.data.bankAccountNo)
                        }
                        if (res.data.data.complianceStatus){
                            setValue('complianceStatus',res.data.data.complianceStatus)
                        }
                        if (res.data.data.branchName){
                            setValue('branchName',res.data.data.branchName)
                        }
                        if (res.data.data.email){
                            setValue('email',res.data.data.email)
                        }
                        if (res.data.data.paymentType){
                            setValue('paymentType',res.data.data.paymentType)
                        }
                        if (res.data.data.accountId){
                            setValue('accountId',res.data.data.accountId)
                        }
                        if (res.data.data.tinCertificateNo){
                            setValue('tinCertificateNo',res.data.data.tinCertificateNo)
                        }
                        if (res.data.data.tradeLicenseNo){
                            setValue('tradeLicenseNo',res.data.data.tradeLicenseNo)
                        }
                        if (res.data.data.vatCertificateNo){
                            setValue('vatCertificateNo',res.data.data.vatCertificateNo)
                        }
                        if (res.data.data.area_id){
                            setValue('area_id',res.data.data.area_id)
                        }
                        if (res.data.data.address){
                            setValue('address',res.data.data.address)
                        }
                        if (res.data.data.area_id){
                            setValue('area',res.data.data.area_id)
                        }
                        if (res.data.data.file_name){
                            setValue('file_name',res.data.data.file_name)
                        }
                        if (res.data.data.description){
                            setValue('file_description',res.data.data.description)
                        }
                        if (res.data.data.attachment_id){
                            setValue('attachment_id',res.data.data.attachment_id)
                        }

                        const roleExisting = res.data.data.items;
                        const userRoleID = [];
                        roleExisting && roleExisting.length>0 && roleExisting.map((roleID)=>{
                            userRoleID.push(Number(roleID));
                        })
                        roleExisting && roleExisting.length>0 && setValue('itemTypes',userRoleID)
                        roleExisting && roleExisting.length>0 && setItemTypeData(userRoleID);
                        res.data.data.address && setAddress(res.data.data.address)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })

            /*if (vendorDetailsData.vendorsName){
                setValue('vendorName',vendorDetailsData.vendorsName)
            }
            if (vendorDetailsData.contractPerson){
                setValue('contractPerson',vendorDetailsData.contractPerson)
            }
            if (vendorDetailsData.contractNo){
                setValue('contractNo',vendorDetailsData.contractNo)
            }
            if (vendorDetailsData.bankAccountName){
                setValue('bankAccountName',vendorDetailsData.bankAccountName)
            }
            if (vendorDetailsData.bankAccountNo){
                setValue('bankAccountNo',vendorDetailsData.bankAccountNo)
            }
            if (vendorDetailsData.complianceStatus){
                setValue('complianceStatus',vendorDetailsData.complianceStatus)
            }
            if (vendorDetailsData.branchName){
                setValue('branchName',vendorDetailsData.branchName)
            }
            if (vendorDetailsData.email){
                setValue('email',vendorDetailsData.email)
            }
            if (vendorDetailsData.paymentType){
                setValue('paymentType',vendorDetailsData.paymentType)
            }
            if (vendorDetailsData.accountId){
                setValue('accountId',vendorDetailsData.accountId)
            }
            if (vendorDetailsData.tinCertificateNo){
                setValue('tinCertificateNo',vendorDetailsData.tinCertificateNo)
            }
            if (vendorDetailsData.tradeLicenseNo){
                setValue('tradeLicenseNo',vendorDetailsData.tradeLicenseNo)
            }
            if (vendorDetailsData.vatCertificateNo){
                setValue('vatCertificateNo',vendorDetailsData.vatCertificateNo)
            }
            if (vendorDetailsData.area_id){
                setValue('area_id',vendorDetailsData.area_id)
            }
            if (vendorDetailsData.address){
                setValue('address',vendorDetailsData.address)
            }
            if (vendorDetailsData.area_id){
                setValue('area',vendorDetailsData.area_id)
            }
            if (vendorDetailsData.file_name){
                setValue('file_name',vendorDetailsData.file_name)
            }
            if (vendorDetailsData.description){
                setValue('file_description',vendorDetailsData.description)
            }
            if (vendorDetailsData.attachment_id){
                setValue('attachment_id',vendorDetailsData.attachment_id)
            }

            const roleExisting = vendorDetailsData.items;
            const userRoleID = [];
            roleExisting && roleExisting.length>0 && roleExisting.map((roleID)=>{
                userRoleID.push(Number(roleID));
            })
            roleExisting && roleExisting.length>0 && setValue('itemTypes',userRoleID)
            roleExisting && roleExisting.length>0 && setItemTypeData(userRoleID);
            vendorDetailsData.address && setAddress(vendorDetailsData.address)*/
        },700)
    }, []);


    //Form Data Submit
    function formSubmit(data) {
        data['itemTypes'] = itemTypeData

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/vendor/edit/${id}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `multipart/form-data`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.data.status == 201 && res.data.message == 'update') {
                    dispatch(setEmptyVendorDetailsData())
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('DataAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout(() => {
                        setSpinner(false);
                        reset();
                        navigate('/master-data/vendor')
                        location.reload()
                    }, 1000)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: res.data.message,
                        message: (t('DataLoad')),
                        autoClose: 1500,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (error) {
                toast.error(res.data.message);
            })

    }

    // start get item type dropdown
    const [itemType, setItemType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item-type/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    setItemType(res.data.data);
                }
            );
    }, []);


    let itemTypeDropdown =
        itemType.map((type, index) => {
            return ({
                'label': type.item_types, 'value': Number(type.id)
            })
        })
    // End get item type dropdown

    // start get area dropdown
    const [areas, setAreas] = useState([]);
    const [vendorPaymentTypeDropdown, setVendorPaymentTypeDropdown] = useState([]);
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                slug: 'vendor-payment-type'
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    setVendorPaymentTypeDropdown(
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': type.slug
                            })
                        })
                    )
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/area/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setAreas(res.data.data);
        });
    }, []);

    let areaDropdown =
        areas.map((type, index) => {
            return ({
                'label': type.areas_name, 'value': Number(type.id)
            })
        })
    // End get area dropdown


    return (
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Vendor')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllVendorRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            {
                                                                ROLE_SUPER_ADMIN &&
                                                                <button onClick={event => {
                                                                    navigate('/master-data/create-vendor')
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12}
                                                                                   className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/master-data/vendor'
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>

                                                            <div
                                                                className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className="flex w-full items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BasicInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="vendorName"
                                                                                               className="form-input-sm-label-required">{t('VendorName')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.vendorName?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.vendorName?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("vendorName")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.vendorName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="vendorName"
                                                                                                id='vendorName'
                                                                                                placeholder={t("EnterVendorName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="contractPerson"
                                                                                               className="form-input-sm-label-required">{t('ContactPerson')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.contractPerson?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={18}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.contractPerson?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("contractPerson")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.contractPerson ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="contractPerson"
                                                                                                id='contractPerson'
                                                                                                placeholder={t("EnterContactPerson")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="contractNo"
                                                                                               className="form-input-sm-label-required">{t('ContactNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.contractNo?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.contractNo?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("contractNo")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.contractNo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="contractNo"
                                                                                                id='contractNo'
                                                                                                placeholder={t("EnterContactNo")}
                                                                                                // value={vendorDetailsData.contractNo?vendorDetailsData.contractNo:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="email"
                                                                                               className="form-input-sm-label-required">{t('Email')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.email?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("email")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="email" id='email'
                                                                                                placeholder={t("EnterEmail")}
                                                                                                readOnly={true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="tradeLicenseNo"
                                                                                               className="form-input-sm-label-required">{t('TradeLicenseNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.tradeLicenseNo?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.tradeLicenseNo?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("tradeLicenseNo")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.tradeLicenseNo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="tradeLicenseNo"
                                                                                                id='tradeLicenseNo'
                                                                                                placeholder={t("EnterTradeLicenseNo")}
                                                                                                // value={vendorDetailsData.tradeLicenseNo?vendorDetailsData.tradeLicenseNo:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label
                                                                                            htmlFor="tinCertificateNo"
                                                                                            className="form-input-sm-label-required">{t('TinCertificateNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.tinCertificateNo?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.tinCertificateNo?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("tinCertificateNo")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.tinCertificateNo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="tinCertificateNo"
                                                                                                id='tinCertificateNo'
                                                                                                placeholder={t("EnterTinCertificateNo")}
                                                                                                // value={vendorDetailsData.tinCertificateNo?vendorDetailsData.tinCertificateNo:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label
                                                                                            htmlFor="vatCertificateNo"
                                                                                            className="form-input-sm-label-required">{t('VAT/eTinCertificateNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.vatCertificateNo?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.vatCertificateNo?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("vatCertificateNo")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.vatCertificateNo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="vatCertificateNo"
                                                                                                id='vatCertificateNo'
                                                                                                placeholder={t("EnterVat/eTinCertificateNo")}
                                                                                                // value={vendorDetailsData.vatCertificateNo?vendorDetailsData.vatCertificateNo:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="accountId"
                                                                                               className="form-input-sm-label">{t('AccountID')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("accountId")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control `}
                                                                                                name="accountId"
                                                                                                id='accountId'
                                                                                                placeholder={t("EnterAccountID")}
                                                                                                // value={vendorDetailsData.accountId?vendorDetailsData.accountId:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label
                                                                                            htmlFor="complianceStatus"
                                                                                            className="form-input-sm-label">{t('ComplianceStatus')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("complianceStatus")}
                                                                                                    placeholder={t("SelectComplianceStatus")}
                                                                                                    searchable clearable
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={
                                                                                                        [
                                                                                                            {
                                                                                                                'label': 'VAT Registered',
                                                                                                                'value': 'VAT Registered'
                                                                                                            },
                                                                                                            {
                                                                                                                'label': 'VAT & AIT Registered',
                                                                                                                'value': 'VAT & AIT Registered'
                                                                                                            },
                                                                                                            {
                                                                                                                'label': 'NoN Registered',
                                                                                                                'value': 'NoN Registered'
                                                                                                            },
                                                                                                        ]
                                                                                                    }
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={(e) => {
                                                                                                        setComplianceStatus(e)
                                                                                                        setValue('complianceStatus', e)
                                                                                                    }}
                                                                                                    value={complianceStatus?complianceStatus:vendorDetailsData.complianceStatus}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/2 pr-3 items-center">
                                                                                        <label htmlFor="paymentType"
                                                                                               className="form-input-sm-label-required">{t('PaymentType')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.paymentType?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.paymentType?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("paymentType")}
                                                                                                    placeholder={t("SelectPaymentType")}
                                                                                                    searchable clearable
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={vendorPaymentTypeDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={(e) => {
                                                                                                        setPaymentType(e)
                                                                                                        setValue('paymentType', e)
                                                                                                        setError('paymentType', null)
                                                                                                    }}
                                                                                                    value={paymentType?paymentType:vendorDetailsData.paymentType}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/2 pr-3 items-center">
                                                                                        <label htmlFor="area"
                                                                                               className="form-input-sm-label-required">{t('Area')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.area?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.area?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("area")}
                                                                                                    placeholder={t("ChooseArea")}
                                                                                                    searchable clearable
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={areaDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={(e) => {
                                                                                                        setAreaData(e)
                                                                                                        setValue('area', e)
                                                                                                        setError('area', null)
                                                                                                    }}
                                                                                                    value={areaData?areaData:vendorDetailsData.area_id}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/2 pr-3 items-center">
                                                                                        <label htmlFor="itemTypes"
                                                                                               className="form-input-sm-label">{t('ItemType')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">

                                                                                                <MultiSelect
                                                                                                    {...register("itemTypes")}
                                                                                                    placeholder={t("SelectItemType")}
                                                                                                    searchable clearable
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={itemTypeDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={(e) => {
                                                                                                        setItemTypeData(e)
                                                                                                    }}
                                                                                                    value={itemTypeData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/2 pr-3 items-center">
                                                                                        <label htmlFor="vendorAddress"
                                                                                               className="form-input-sm-label-required">{t('Address')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.address?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                    <textarea
                                                        {...register("address")}
                                                        name="vendorAddress" id='vendorAddress'
                                                        className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                        onChange={(e) => {
                                                            setAddress(e.target.value)
                                                            setValue('address', e.target.value)
                                                            setError('address', null)
                                                        }}
                                                        defaultValue={address}
                                                    />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div
                                                                className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className="flex w-full items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BankInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="bankAccountNo"
                                                                                               className="form-input-sm-label-required">{t('BankAccount')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.bankAccountNo?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.bankAccountNo?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("bankAccountNo")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.bankAccountNo ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="bankAccountNo"
                                                                                                id='bankAccountNo'
                                                                                                placeholder={t("EnterBankAccountNo")}
                                                                                                // value={vendorDetailsData.bankAccountNo?vendorDetailsData.bankAccountNo:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="bankAccountName"
                                                                                               className="form-input-sm-label-required">{t('BankAccountName')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.bankAccountName?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.bankAccountName?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("bankAccountName")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.bankAccountName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="bankAccountName"
                                                                                                id='bankAccountName'
                                                                                                placeholder={t("EnterBankAccountName")}
                                                                                                // value={vendorDetailsData.bankAccountName?vendorDetailsData.bankAccountName:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="branchName"
                                                                                               className="form-input-sm-label-required">{t('BranchName/Routing')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.branchName?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.branchName?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("branchName")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.branchName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="branchName"
                                                                                                id='branchName'
                                                                                                placeholder="Enter branch name / routing number"
                                                                                                // value={vendorDetailsData.branchName?vendorDetailsData.branchName:null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className="flex w-full items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="vat_file_file"
                                                                                               className="form-input-sm-label">Attachment</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <Dropzone
                                                                                                onDrop={(file) => {
                                                                                                    setPdfFile(file[0])
                                                                                                    setValue("file", file[0])
                                                                                                }}
                                                                                                onReject={(files) => console.log('rejected files', files)}
                                                                                                accept={[MIME_TYPES.pdf]}
                                                                                                multiple={false}
                                                                                            >
                                                                                                <Group position="center"
                                                                                                       spacing="sm"
                                                                                                       style={{pointerEvents: 'none'}}>
                                                                                                    <Dropzone.Accept>
                                                                                                        <IconUpload
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Accept>
                                                                                                    <Dropzone.Reject>
                                                                                                        <IconX
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Reject>
                                                                                                    <Dropzone.Idle>
                                                                                                        <IconPhoto
                                                                                                            stroke={1.5}/>
                                                                                                    </Dropzone.Idle>

                                                                                                    <div>
                                                                                                        <Text size="sm"
                                                                                                              inline>
                                                                                                            {
                                                                                                                pdfFile.path ?
                                                                                                                    pdfFile.path
                                                                                                                    : t('DragProfileImage')
                                                                                                            }

                                                                                                        </Text>
                                                                                                    </div>
                                                                                                </Group>
                                                                                            </Dropzone>
                                                                                            {
                                                                                                vendorDetailsData.path &&
                                                                                                <a title={t('VendorAttach')} target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/vendor/${vendorDetailsData.path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>

                                                                                            }

                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="file_name"
                                                                                               className="form-input-sm-label">File
                                                                                            Name</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("file_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="vat_file"
                                                                                                id='vat_file'
                                                                                                placeholder={t("EnterFileName")}
                                                                                                onChange={(e) => {
                                                                                                    setValue('file_name', e.target.value)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-1/3 pr-3 items-center">
                                                                                        <label htmlFor="FileDescription"
                                                                                               className="form-input-sm-label">File
                                                                                            Description</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            <textarea
                                                                {...register("file_description")}
                                                                name="FileDescription" id='FileDescription'
                                                                className={`form-input-sm-control`}
                                                                onChange={(e) => {
                                                                    setValue('file_description', e.target.value)
                                                                }}
                                                            />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-6">
                                                                <div className="text-right pt-0.5 mb-0.5 ">
                                                                    <button
                                                                        type='reset'
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16}
                                                                                           className={'mr-2'}/>
                                                                        <span>{t('Back')}</span>
                                                                    </button>

                                                                    <button type='submit'
                                                                            className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        <HiArrowCircleRight size={16}
                                                                                            className={'mr-2'}/>
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


        </>
    )
}

export default EditVendor