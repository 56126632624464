import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,HiSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Group, LoadingOverlay, Select, Stack, Text, TextInput, Button, Skeleton} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";

import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {openConfirmModal} from "@mantine/modals";
import {
    getPoReport,
    setReceiveItemReportFilterData,
    setFetching,
    setPage,
    getPoReportAll, getReceiveItemReport, getReceiveItemReportDownload
} from "../../../../store/report/reportSlice";
import {vendorDropdown} from "../../../../store/vendor/vendorSlice";
import {showNotification} from "@mantine/notifications";
import {IconChevronRight} from "@tabler/icons-react";
import clsx from "clsx";

const PAGE_SIZE = 20;
import fileDownload from 'js-file-download'

function ReceivedItemReport(){
    const {t, i18n} = useTranslation();

    const reportWithHeadTableHeight = localStorage.getItem('reportWithHeadTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })

    const [startDate,setStartDate] = useState(null)
    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);
    const [itemData, setItemData] = useState('');

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }

    // start get project dropdown
    const [projectData, setProjectData] = useState('');
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    // start get contact person dropdown
    const [isDownload, setIsDownload] = useState(false);

    const poReportReportData = useSelector((state) => state.reportReducer.poReportReportData)
    const poReportDownloadData = useSelector((state) => state.reportReducer.poReportDownloadData)
    const receiveItemReportFilterData = useSelector((state) => state.reportReducer.receiveItemReportFilterData)
    const receiveItemReportData = useSelector((state) => state.reportReducer.receiveItemReportData)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)
    const receiveItemReportDataDownload = useSelector((state) => state.reportReducer.receiveItemReportDataDownload)
    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)

    // console.log(receiveItemReportDataDownload)

    const [expandedCompanyIds, setExpandedCompanyIds] = useState([]);

    useEffect(() => {
        dispatch(vendorDropdown())
    }, []);

    useEffect(() => {
        dispatch(setFetching(true))
        const param = {
            offset : PAGE_SIZE,
            page : page,
        }
        dispatch(getReceiveItemReport(param))
    }, []);

    const CallPoReport = (page=1) => {
        if (receiveItemReportFilterData.start_date == '' || receiveItemReportFilterData.end_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start & end date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                project : receiveItemReportFilterData.project,
                vendor : receiveItemReportFilterData.vendor,
                start_date : receiveItemReportFilterData.start_date,
                end_date : receiveItemReportFilterData.end_date,
                po : receiveItemReportFilterData.po,
                item : receiveItemReportFilterData.item
            }
            dispatch(getReceiveItemReport(param))
        }
    }


    const exportToCSV = () => {
        openConfirmModal({
            title: 'It may some times , sure ? ',
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToDownloadAllStockItem')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
                const param = {
                    project : receiveItemReportFilterData.project,
                    vendor : receiveItemReportFilterData.vendor,
                    start_date : receiveItemReportFilterData.start_date,
                    end_date : receiveItemReportFilterData.end_date,
                    item : receiveItemReportFilterData.item,
                    po : receiveItemReportFilterData.po
                }
                 axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/received-item/download`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `text/html`,
                        "Content-Disposition": 'attachment; filename=data.csv',
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                    },
                    params : param
                })
                    .then(res => {
                        if (res.status === 200){
                            const  fileName = 'ReceivedItemReport-'+format(new Date(), 'dd-MM-yyyy')+'.csv'
                            const href = `${process.env.REACT_APP_API_GATEWAY_URL}/report/received-item/download-data`;
                            const anchorElement = document.createElement('a');
                            anchorElement.href = href;
                            anchorElement.download = fileName;
                            document.body.appendChild(anchorElement);
                            anchorElement.click();
                            setIsDownload(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }


    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('ReceiveItemReport')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PoReportRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {(receiveItemReportData['data'] && receiveItemReportData['data'].length>0) &&
                                                                <>
                                                                <button
                                                                onClick={(e) => exportToCSV('csv', 'all')}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }


                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-2/12">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={receiveItemReportFilterData.start_date?receiveItemReportFilterData.start_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setReceiveItemReportFilterData({
                                                                                        ...receiveItemReportFilterData,
                                                                                        ['start_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setReceiveItemReportFilterData({
                                                                                        ...receiveItemReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-2/12">
                                                                        <DatePicker
                                                                            placeholder="Choose End Date"
                                                                            value={receiveItemReportFilterData.end_date?receiveItemReportFilterData.end_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setReceiveItemReportFilterData({
                                                                                        ...receiveItemReportFilterData,
                                                                                        ['end_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setReceiveItemReportFilterData({
                                                                                        ...receiveItemReportFilterData,
                                                                                        ['end_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-2/12">
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setReceiveItemReportFilterData({
                                                                                    ...receiveItemReportFilterData,
                                                                                    ['project']: e
                                                                                }))
                                                                            }}
                                                                            value={receiveItemReportFilterData.project}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-2/12">
                                                                        <Select
                                                                            placeholder={t("ChooseVendor")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={vendorDropdownData}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setReceiveItemReportFilterData({
                                                                                    ...receiveItemReportFilterData,
                                                                                    ['vendor']: e
                                                                                }))
                                                                            }}
                                                                            value={receiveItemReportFilterData.vendor}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-2/12">
                                                                        <Select
                                                                            placeholder={t("ChooseItem")}
                                                                            searchable  allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={items}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onSearchChange={ItemSearchHandel}
                                                                            onChange={e => {
                                                                                dispatch(setPage(1))
                                                                                dispatch(setReceiveItemReportFilterData({
                                                                                    ...receiveItemReportFilterData,
                                                                                    ['item']: e
                                                                                }))
                                                                            }}
                                                                            value={receiveItemReportFilterData.item}
                                                                            searchValue={itemSearchValue}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/12">
                                                                        <TextInput
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setReceiveItemReportFilterData({
                                                                                    ...receiveItemReportFilterData,
                                                                                    ['po']: e.target.value
                                                                                }))
                                                                            }}
                                                                            placeholder="Po"
                                                                            value={receiveItemReportFilterData.po}
                                                                            withAsterisk
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/12">
                                                                        <button
                                                                            onClick={(e) => CallPoReport()}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                {
                                                                    fetching ?
                                                                        <div className="bg-rose-100 p-2 mt-2">
                                                                            <Skeleton height={8} mt={6} radius="xl"/>
                                                                            <Skeleton height={8} mt={6} radius="xl"/>
                                                                            <Skeleton height={8} mt={6} radius="xl"/>
                                                                        </div>
                                                                        :
                                                                        <div className="bg-rose-100 p-2 mt-2">
                                                                            <div
                                                                                className=" inline-flex items-center w-1/2">
                                                                                <li className="flex items-start lg:col-span-1">
                                                                                    <p className="ml-3 text-sm ">
                                                                                        <span className="font-bold ">Total no of PR Qty : </span>
                                                                                        {receiveItemReportData['totalNoOfPrQty'] ? receiveItemReportData['totalNoOfPrQty'] : ''}
                                                                                    </p>
                                                                                </li>
                                                                            </div>
                                                                            <div
                                                                                className=" inline-flex items-center w-1/2">
                                                                                <li className="flex items-start lg:col-span-1">
                                                                                    <p className="ml-3 text-sm ">
                                                                                        <span className="font-bold ">Total no of PO Qty : </span>
                                                                                        {receiveItemReportData['totalPoApproveItems'] ? receiveItemReportData['totalPoApproveItems'] : ''}
                                                                                    </p>
                                                                                </li>
                                                                            </div>

                                                                            <div
                                                                                className=" inline-flex items-center w-1/2">
                                                                                <li className="flex items-start lg:col-span-1">
                                                                                    <p className="ml-3 text-sm">
                                                                                        <span className="font-bold ">Total no of Received Qty : </span>
                                                                                        {receiveItemReportData['totalPoReceivedItems'] ? receiveItemReportData['totalPoReceivedItems'] : ''}
                                                                                    </p>
                                                                                </li>
                                                                            </div>
                                                                            <div
                                                                                className=" inline-flex items-center w-1/2">
                                                                                <li className="flex items-start lg:col-span-1">
                                                                                    <p className="ml-3 text-sm ">
                                                                                        <span className="font-bold ">Total remaining Qty : </span>
                                                                                        {receiveItemReportData['totalPoApproveItems'] && receiveItemReportData['totalPoReceivedItems'] ? receiveItemReportData['totalPoApproveItems'] - receiveItemReportData['totalPoReceivedItems'] : 0}
                                                                                    </p>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                }

                                                                <Box sx={{height: reportWithHeadTableHeight,width: containerWidth}} className="mt-3">

                                                                    <DataTable
                                                                        withBorder
                                                                        records={receiveItemReportData['data']}
                                                                        withColumnBorders={1}
                                                                        highlightOnHover
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                // textAlignment: 'right',
                                                                                render: (item) => (receiveItemReportData['data'].indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'itemName', title: <Text mr="xs">Item</Text>,},
                                                                            {accessor: 'poId', title: 'PO No',},
                                                                            {accessor: 'createdDate', title: 'PO Date',},
                                                                            {accessor: 'poiQuantity', title: 'PO Qty',textAlignment:"center"},
                                                                            {accessor: 'riQuantity', title: 'Grn Qty',textAlignment:"center"},
                                                                            {accessor: 'projectName', title: 'Project Name',textAlignment:"center"},
                                                                            {accessor: 'vendorName', title: 'Vendor',textAlignment:"center"},
                                                                            {accessor: 'GrnNo', title: 'Grn No',textAlignment:"center"},
                                                                            {accessor: 'receivedDate', title: 'Grn Date',textAlignment:"center"},
                                                                            {accessor: 'GrnBy', title: 'Grn By',textAlignment:"center"},
                                                                            {accessor: 'Remarks', title: 'Remarks',textAlignment:"center"},
                                                                            {accessor: 'closeQty', title: 'CloseQty',textAlignment:"center"},
                                                                            {accessor: 'closeRemark', title: 'CloseRemarks',textAlignment:"center"},
                                                                            {accessor: 'closedBy', title: 'ClosedBy',textAlignment:"center"},
                                                                            {accessor: 'fullName', title: 'PO Issued By',textAlignment:"center"},

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            CallPoReport(p)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ReceivedItemReport