import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link,useNavigate} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box} from "@mantine/core";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import toast from "react-hot-toast";

function Role() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()
    const token = localStorage.getItem('user_token');
    const containerWidth = localStorage.getItem('containerWidth');
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);
    const [searchKeywordData, setSearchKeywordData] = useState(null);

    // FOR DATA TABLE START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('user_group_page') ? localStorage.getItem('user_group_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63 + "px");

    const [userGroup, setUserGroups] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/user-group`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page,
                "searchKeywordData" : searchKeywordData
            }
        })
            .then(res => {
                setTimeout(()=>{
                    if (res.data.status === 200){
                        setUserGroups(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('user_group_page', page);
                        setFetching(false)
                        setSpinner(false)
                    }else {
                        toast.error(res.data.message);
                    }
                },500)
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [spinner, page,listRefresh,fetching]);

    // FOR DATA TABLE END

    const UserGroupDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: "Delete this role ?",
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: { confirm: (t('Delete')), cancel: (t("NoDontDeleteIt")) },
            confirmProps: { color: 'red' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/user-group/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                        if (res.status === 200) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('DataIsDeleting')),
                                message: (t('DataLoad')),
                                autoClose: 500,
                                disallowClose: true,
                                color: 'red',
                            });
                            setListRefresh(true);
                            setFetching(true)
                        }
                    })
                )
            ,
        });
        setListRefresh(false);
    }

    return (
        <>
            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Role')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('RoleInformation')}</sub></span>
                                                    </h1>
                                                </div>

                                                <div className={"flex-1"}>
                                                    <div className="text-gray-800  tracking-normal leading-tight ">
                                                        <div className="relative text-gray-600 focus-within:text-gray-400">
                                                            <input
                                                                onBlur={(e) => {
                                                                    setSearchKeywordData(e.target.value)
                                                                }}
                                                                type="text" id="small-input"
                                                                className="block w-full p-2 mt-2 mr-2 text-gray-900 border-1 border-gray-300 rounded  sm:text-xs  border-indigo-500  focus:border-indigo-800  dark:placeholder-gray-400 dark:text-white"/>
                                                            <span className="absolute right-0 inset-y-0 top-0 flex items-center pl-2">
                                                                <button className="p-1 focus:outline-none focus:shadow-outline"
                                                                        onClick={(e) => {
                                                                            setFetching(true)
                                                                        }}
                                                                >
                                                                 <HiOutlineSearch size={20} className={'mr-2 text-indigo-800'}></HiOutlineSearch>
                                                                </button>
                                                              </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"right flex mt-2 mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <Link to='/role/create' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddRole')}
                                                            </Link>
                                                            <Link to='/role' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/role'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <Box sx={{ height: tableHeight,width:containerWidth }}>
                                                                <DataTable
                                                                        withBorder
                                                                        records={userGroup}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL',
                                                                                render: (item) => (userGroup.indexOf(item) + 1) + PAGE_SIZE * (page - 1),
                                                                            },
                                                                            {accessor: 'name', title: <Text mr="xs">{t('Name')}</Text>},
                                                                            {accessor: 'description', title: <Text mr="xs">{t('Description')}</Text>},
                                                                            {
                                                                                accessor: 'roles',
                                                                                title: <Text mr="xs">{t('Permission')}</Text>,
                                                                                render: ({ roles }) => {
                                                                                    // return roles.toString('\n');
                                                                                    return roles?roles.join(' & '):''
                                                                                },
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => {
                                                                                                        navigate('/role/edit/'+item.id)
                                                                                                    }}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>

                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16} onClick={(e) => {UserGroupDelete(e, item.id)}}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                />

                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Role