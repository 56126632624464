import axios from "axios";


export const getBudgetDetailsData = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/details/${param.id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getBudgetDelete = async (id) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/delete/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};