import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getAdvancePaymentIndex,
    getAdvancePaymentVerify
} from "../../services/AdvancePaymentService";

export const advancePaymentIndex = createAsyncThunk("advance-payment/index", async (param) => {
    try {
        const response = getAdvancePaymentIndex(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const advancePaymentVerify = createAsyncThunk("advance-payment/verify", async (param) => {
    try {
        const response = getAdvancePaymentVerify(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const advancePaymentSlice = createSlice({
    name : "advance-payment",
    initialState : {
        isLoading : false,
        opened : false,
        advancePayments : [],
        totalItem : 0,
        fetching : true,
        spinner : true,
        page : localStorage.getItem('advance_payment_page') ? localStorage.getItem('advance_payment_page') : 1,
        activeTab : 'not-verify',
        sortStatus : { columnAccessor: 'created', direction: 'DESC' },
        filterData : {vendor:'',po:''},
        verifyMsg : null
    },
    reducers : {
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.po = action.payload.po;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },

        },
    extraReducers : (builder) => {
        builder.addCase(advancePaymentIndex.fulfilled,(state,action) => {
            state.advancePayments = action.payload.data
            state.totalItem = action.payload.total
            state.fetching = false
        })
        builder.addCase(advancePaymentVerify.fulfilled,(state,action) => {
            state.verifyMsg = action.payload
            state.fetching = true
        })
    }
})

export const { setProjectData,setKeywordData,setLoading,setCheckOpenItem,setRemoveOpenItem,setOpenItemData,setCreatePoId,setProcessMode,setVendor,setPurchaseType,setPaymentType,setPaymentFrom,setComputationStatus,setPoItemInlineUpdate,setPoItemInlineUpdateID,setBuyer,setFinalUpdate,setSpinner,setItemPriceModel,setItemPriceModelItemID,setFetching,setPage,setActiveTab,setSortStatus,setOpened,setRefresh,setFilterData,setPoViewModel,setPoViewID,setPoComment,setPoCommentstatus,setPoDropdownLoading,setEmptyPODetails,setCheckOpenItemEnpty,setAfterApproveEditID,setAfterApproveEditModel,setCloseModel,setCloseId,setPoItemCloseId,setPoItemCloseModel,setAdvancePaymentModel,setAdvancePaymentModelId } = advancePaymentSlice.actions
export default advancePaymentSlice.reducer;