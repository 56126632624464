import React, {useEffect} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {
    HiOutlineExclamation,
    HiArrowCircleRight,
    HiArrowCircleLeft,
    HiOutlineOfficeBuilding,
     HiOutlineChartSquareBar, HiOutlineExclamationCircle
} from "react-icons/hi";
import {
    Button,
    ScrollArea, TransferList
} from "@mantine/core";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from '@mantine/notifications';
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import initialValues from './user-group.json';


function AddRole() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()
    const token = localStorage.getItem('user_token');
    const [spinner, setSpinner] = useState(false);
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    // const [rolesPermission, setRolesPermission] = useState(initialValues);
    const [rolesPermission, setRolesPermission] = useState([[],[]]);
    //GET USER PERMISSION DROPDOWN
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/user-permission/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setRolesPermission(res.data.data);
        });
    }, []);
    //GET USER PERMISSION DROPDOWN


    //Form validation
    const validationSchema = Yup.object().shape({
        role_name: Yup.string().required(t("EnterRoleName")),
        description: Yup.string().required(t("EnterDescription")),
        // permission: Yup.string().required(t("ChoosePermission")),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset,setValue,setError, formState} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const permissionArray = [];
        rolesPermission[1].forEach(function (item) {
            permissionArray.push(item.value);
        });
        if (permissionArray.length>0){
            setValue('permission',permissionArray)
            setError('permission',null)
            const formData = {
                name: data.role_name,
                description : data.description,
                permission: permissionArray,
                status: true
            };
            if (formData){
                axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/user-group/`, formData,
                    {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: 'Role added successfully',
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                        navigate('/role')
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
            }
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('ChoosePermission')),
                message: (t('DataLoad')),
                autoClose: 1500,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    return (
        <>
            <main className="w-full">

                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3 ">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Role')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('RoleInformation')}</sub></span>
                                                    </h1>
                                                </div>

                                                <div className={"right flex mt-2 mr-8"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <Link to='/role' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/role'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">

                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">


    <div className="mb-3 w-full items-center">
        <label htmlFor="role_name" className="form-input-sm-label-required">{t('RoleName')}</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.role_name?.type === 'required' &&
            <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.role_name?.message}</div>}
            <input
                {...register("role_name")}
                type="text"
                className={`form-input-sm-control ${errors.role_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                placeholder={t("EnterRoleName")}
            />
        </div>
    </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="description" className="form-input-sm-label-required">{t('Description')}</label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.description?.type === 'required' &&
                <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.description?.message}</div>}
                <textarea
                    {...register("description")}
                    name="description" id='description'
                    className={`form-input-sm-control ${errors.description ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="permission" className="form-input-sm-label-required">{t('Permission')}</label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.permission?.type === 'required' &&
                <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.permission?.message}</div>}
                <TransferList
                    {...register('permission')}
                    value={rolesPermission}
                    onChange={setRolesPermission}
                    searchPlaceholder="Search..."
                    nothingFound="Nothing here"
                />
            </div>
        </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1">
                                                            <div className="justify-items-center">
                                                                <div className="text-right border-t pt-0.5 mb-0.5 pr-2">
                                                                    <Button
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-400 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}></AiOutlineLoading3Quarters>
                                                                        ) : (<HiArrowCircleRight size={16}
                                                                                                 className={'mr-2'}></HiArrowCircleRight>)}
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default AddRole