import axios from "axios";

export const getPaymentRequestList = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/list/${param.activeTab}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const createPaymentVerifyFrom = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/verify/rfp-to-pay`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const storePaymentVerify = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/verify`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const createPaymentPayFrom = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/vendor-wise-confirm`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const updatePaymentInline = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/inline-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const paymentConfirmationForArchive = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/confirmation`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const paymentSendBack = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/send-back`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getPaymentBankCheque = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/bank-cheque`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const postPaymentUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postPaymentConfirmAttachment = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/confirm-attachment`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getPayVerifyDataForCsvDownload = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/verify-csv-download`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const paymentConfirmationCSVupload = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payment/verify-for-csv/upload`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
    .then(res => {
        if (res.status === 200){
            returnData = res.data.message
        }
    })
    .catch(function (error) {
        console.log(error)
    })
    return returnData
};