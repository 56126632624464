import axios from "axios";

export const createReceived = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/create`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.id
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getReceivableItem = async (params) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/item`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: params
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
        return data
};

export const getReceiveDetails = async (params) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/details`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: params
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const postReceivedInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/inline/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postInvoiceChallanInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/invoice-challan/inline/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postReceivedItemsInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/item-inline/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data: data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const postReceivedFinalUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/final/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data: data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getReceiveList = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/list/${param.activeTab}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getReceivedChecked = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/checked`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getReceivedApproved = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/approved`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getGRNDropdown = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/grn-dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                if (res.data.data){
                    data =
                        res.data.data.map((type, index) => {
                            return ({
                                'value': Number(type.ref_grn), 'label': type.ref_grn
                            })
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getInvoiceBillDropdown = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/invoice-challan-dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                if (res.data.data){
                    data['data'] =
                        res.data.data.map((type, index) => {
                            return ({
                                'value': Number(type.id), 'label': type.purchase_order+'--'+type.bill_number+'--'+type.bill_amount+'--'+type.email
                            })
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    data['type'] = param.type
    return data
};

export const getReceivedWiseInvoiceBillDropdown = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/received-wise/invoice-challan-dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                if (res.data.data){
                    data['invoice'] =
                        res.data.data.map((type, index) => {
                            return ({
                                'value': Number(type.invoices), 'label': type.invoice_purchase_order+'--'+type.invoice_bill_number+'--'+type.invoice_bill_amount+'--'+type.invoice_user
                            })
                        })

                    data['challan'] =
                        res.data.data.map((t, index) => {
                            return ({
                                'value': Number(t.calans), 'label': t.challan_purchase_order+'--'+t.challan_bill_number+'--'+t.challan_bill_amount+'--'+t.challan_user
                            })
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const postReceivedVerifyForPayment = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/verify/for-payment`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data: data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getVerifyListForCsvDownload = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/verify-for-csv-download`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const verifyCsvUpload = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/verify-for-csv/upload`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data: data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const getReceiveDetailsMultiple = async (params) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/details/multiple`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: params
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const postReceivedMulVerifyForPayment = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/mul-verify/for-payment`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data: data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getReceiveDataDelete = async (id) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/received/delete/${id}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


