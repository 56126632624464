import React, { useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineUser,
    HiArrowCircleLeft,
    HiArrowCircleRight,
    HiOutlineExclamation,
    HiOutlinePlus,HiTrash
} from "react-icons/hi";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {ActionIcon, ScrollArea, Select, TextInput, Textarea, Group, Text, useMantineTheme} from '@mantine/core';
import {showNotification} from "@mantine/notifications";
import {useDispatch, useSelector} from "react-redux";
import {projectDropdown, projectDropdownUserWise} from "../../../store/project/projectSlice";
import {
    getRemainingQuantity, setEmptyRemainingQuantity,
} from "../../../store/issue/issueSlice";
import {
    createStoreRequisitionItems,
    finalUpdateStoreRequisition,
    setFinalUpdateEmptyMsg,
    setFromData,
    setItemFromData,
    storeRequisitionDetails,
    storeRequisitionInlineUpdate,
    storeRequisitionInlineUpdateAttachment,
    storeRequisitionItemDelete,
    storeRequisitionItemInlineUpdate
} from "../../../store/storeRequisition/storeRequisitionSlice";
import {useParams} from "react-router";
import {
    getCategorySubCategory,
    getItemDropdownProjectWise,
    setItemCategorySubCat
} from "../../../store/items/itemsSlice";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {openConfirmModal} from "@mantine/modals";

function UpdateStoreRequisition() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const theme = useMantineTheme();
    const [recall, setRecall] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const [itemSearchValue, setItemSearchValue] = useState('');

    const projectDropdownData = useSelector((state) => state.projectReducer.projects)
    const projectDropdownUserWiseData = useSelector((state) => state.projectReducer.projectsUserWise)
    const projectWiseItemDropdown = useSelector((state) => state.itemReducer.projectWiseItemDropdown)
    const fromData = useSelector((state) => state.storeRequisitionReducer.fromData)
    const storeRequisitionDetailsData = useSelector((state) => state.storeRequisitionReducer.storeRequisitionDetailsData)
    const storeRequisitionfinalUpdateMsg = useSelector((state) => state.storeRequisitionReducer.storeRequisitionfinalUpdateMsg)
    const itemFromData = useSelector((state) => state.storeRequisitionReducer.itemFromData)
    const itemCategorySubCat = useSelector((state) => state.itemReducer.itemCategorySubCat)
    const remainingQuantity = useSelector((state) => state.issueReducer.remainingQuantity)

    let categoryDropdown = [];
    if (itemCategorySubCat && itemCategorySubCat.categories_name && itemCategorySubCat.categories_id){
        dispatch(setItemFromData({...itemFromData,['category_id']:itemCategorySubCat.categories_id}))
        categoryDropdown = [{
            'label': itemCategorySubCat.categories_name, 'value': Number(itemCategorySubCat.categories_id)
        }]
    }

    const validationSchemaStep = Yup.object().shape({
        project_from: Yup.string().required(t("ChooseProjectFrom")),
        project_to: Yup.string().required(t("ChooseProjectTo")),
    });

    let formOptions = {resolver: yupResolver(validationSchemaStep)};

    // get functions to build form with useForm() hook
    const {handleSubmit,register, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;

    if (storeRequisitionDetailsData){
        if (storeRequisitionDetailsData.project_from && storeRequisitionDetailsData.project_from != null){
            setValue('project_from',storeRequisitionDetailsData.project_from)
        }
        if (storeRequisitionDetailsData.project_to && storeRequisitionDetailsData.project_to != null){
            setValue('project_to',storeRequisitionDetailsData.project_to)
        }
        if (storeRequisitionDetailsData.note && storeRequisitionDetailsData.note != null){
            setValue('note',storeRequisitionDetailsData.note)
        }
    }

    useEffect(()=>{
        dispatch(projectDropdownUserWise())
        dispatch(projectDropdown())
    },[])

    useEffect(()=>{
        dispatch(storeRequisitionDetails({
            id:id
        }))
        setRecall(false)
    },[recall])

    //Form Data Submit
    function formSubmit(data) {
        if (storeRequisitionDetailsData.storeRequisitionItems.length>0){
            dispatch(finalUpdateStoreRequisition({id:id}))
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please add item',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    if (storeRequisitionfinalUpdateMsg) {
        setTimeout(() => {
            dispatch(setFinalUpdateEmptyMsg())
            navigate('/store-requisition')
        }, 700)
    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('StoreRequisition')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllStoreRequisitionRelatedInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">




        <div className="relative mx-auto w-full px-2 lg:px-2 mb-4">
            <div className="mx-auto max-w-lg  rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                    <div className="mt-4">
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>

                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                            <div className="mt-5 md:col-span-5 md:mt-0">
                                <div className="shadow sm:rounded">
                                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                            <div className="mb-3 w-1/2 items-center">
                                                <label htmlFor="project_to" className="form-input-sm-label-required">{t('ProjectTo')}</label>
                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                    {errors.project_to?.type === 'required' &&
                                                    <div className="form-input-sm-error">
                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.project_to?.message}
                                                    </div>
                                                    }

                                                    <Select
                                                        placeholder={t("ChooseProjectTo")}
                                                        searchable clearable
                                                        allowDeselect
                                                        nothingFound="No options"
                                                        withAsterisk
                                                        data={projectDropdownUserWiseData}
                                                        transition="pop-top-left"
                                                        transitionDuration={80}
                                                        transitionTimingFunction="ease"
                                                        onChange={(e)=>{
                                                            let projectFrom = fromData.project_from?fromData.project_from:storeRequisitionDetailsData.project_from;
                                                            if (e != projectFrom){
                                                                dispatch(storeRequisitionInlineUpdate({
                                                                    id : id,
                                                                    field_name : "project_to",
                                                                    value : e
                                                                }))
                                                                setValue('project_to',e)
                                                                setError('project_to',null)
                                                                dispatch(setFromData({...fromData,['project_to']:e}))
                                                            }else {
                                                                setRecall(true)
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Project to & from are same.',
                                                                    message: (t('DataLoad')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }
                                                        }}
                                                        value={fromData.project_to?fromData.project_to:storeRequisitionDetailsData.project_to}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 w-1/2 items-center">
                                                <label htmlFor="project_from" className="form-input-sm-label-required">{t('ProjectFrom')}</label>
                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                    {errors.project_from?.type === 'required' &&
                                                    <div className="form-input-sm-error">
                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.project_from?.message}
                                                    </div>
                                                    }

                                                    <Select
                                                        placeholder={t("ChooseProjectFrom")}
                                                        searchable clearable
                                                        allowDeselect
                                                        nothingFound="No options"
                                                        withAsterisk
                                                        data={projectDropdownData}
                                                        transition="pop-top-left"
                                                        transitionDuration={80}
                                                        transitionTimingFunction="ease"
                                                        onChange={(e)=>{
                                                            let projectTo = fromData.project_to?fromData.project_to:storeRequisitionDetailsData.project_to;
                                                            if (e != projectTo){
                                                                setValue('project_from',e)
                                                                setError('project_from',null)
                                                                dispatch(setFromData({...fromData,['project_from']:e}))
                                                                dispatch(storeRequisitionInlineUpdate({
                                                                    id : id,
                                                                    field_name : "project_from",
                                                                    value : e
                                                                }))
                                                            }else {
                                                                setRecall(true)
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Project to & from are same.',
                                                                    message: (t('DataLoad')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }
                                                        }}
                                                        value={fromData.project_from?Number(fromData.project_from):Number(storeRequisitionDetailsData.project_from)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

     <div className="relative mx-auto w-full px-2 lg:px-2 mb-4">
    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
            <div className="mt-4">
                <div className="flex items-center">
                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemInformation')}</h4>
                    <div className="flex-1 border-t-2 border-gray-200"/>
                </div>

                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                    <thead className="text-black bg-gray-200">
                    <tr>
                        <td className="p-2 border-collapse border border-slate-400" colSpan={3}>
                            <div className="text-center">
                                <Select
                                    placeholder='Choose Item'
                                    searchable clearable allowDeselect
                                    nothingFound="No options"
                                    withAsterisk
                                    data={projectWiseItemDropdown?projectWiseItemDropdown:[]}
                                    transition="pop-top-left"
                                    transitionDuration={80}
                                    transitionTimingFunction="ease"
                                    maxDropdownHeight={280}
                                    onSearchChange={(e)=>{
                                        setItemSearchValue(e)
                                        if (e.length !=0){
                                            if ((fromData.project_from && fromData.project_from != null) || (storeRequisitionDetailsData.project_from)){
                                                dispatch(getItemDropdownProjectWise({
                                                    value : e,
                                                    project_id : fromData.project_from?fromData.project_from:storeRequisitionDetailsData.project_from
                                                }))
                                            }else {
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: 'Choose project from',
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                            }
                                        }
                                    }}
                                    onChange={e => {
                                        if (storeRequisitionDetailsData && storeRequisitionDetailsData['storeRequisitionItems'] && storeRequisitionDetailsData['storeRequisitionItems'].length >0){
                                            let existsItem = storeRequisitionDetailsData['storeRequisitionItems'].find(itm => itm.itemId === e);
                                            if (existsItem){
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: 'Already added this item.',
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                                dispatch(setItemCategorySubCat())
                                                dispatch(setEmptyRemainingQuantity())
                                                dispatch(setItemFromData({...itemFromData,['item_id']:null}))
                                                dispatch(setItemFromData({...itemFromData,['sub_category_id']:null}))
                                                dispatch(setItemFromData({...itemFromData,['category_id']:null}))
                                                dispatch(setItemFromData({...itemFromData,['quantity']:null}))
                                                dispatch(setItemFromData({...itemFromData,['remark']:null}))
                                            }else{
                                                dispatch(getRemainingQuantity({
                                                    item_id:e,
                                                    project_id:fromData.project_from?fromData.project_from:storeRequisitionDetailsData.project_from
                                                }))
                                                dispatch(getCategorySubCategory({
                                                    item_id:e
                                                }))
                                                dispatch(setItemFromData({...itemFromData,['item_id']:e}))
                                            }
                                        }else {
                                            /*console.log(fromData.project_from?fromData.project_from:storeRequisitionDetailsData.project_from,e,fromData.project_to?fromData.project_to:storeRequisitionDetailsData.project_to)*/
                                            dispatch(getRemainingQuantity({
                                                item_id:e,
                                                project_id:fromData.project_from?fromData.project_from:storeRequisitionDetailsData.project_from
                                            }))
                                            dispatch(getCategorySubCategory({
                                                item_id:e
                                            }))
                                            dispatch(setItemFromData({...itemFromData,['item_id']:e}))
                                        }
                                    }}
                                    searchValue={itemSearchValue}
                                    value={itemFromData.item_id}
                                />
                            </div>
                        </td>

                        <td className="p-2 border-collapse border border-slate-400" colSpan={3}>
                            <div className="text-center">
                                <Select
                                    placeholder='Choose Category'
                                    searchable clearable allowDeselect
                                    nothingFound="No options"
                                    withAsterisk
                                    data={categoryDropdown?categoryDropdown:[]}
                                    transition="pop-top-left"
                                    transitionDuration={80}
                                    transitionTimingFunction="ease"
                                    maxDropdownHeight={280}
                                    onChange={(e) => {
                                        dispatch(setItemFromData({...itemFromData,['category_id']:e}))
                                    }}
                                    value={itemCategorySubCat.categories_id?Number(itemCategorySubCat.categories_id):''}
                                />
                            </div>
                        </td>
                        <td className="p-2 border-collapse border border-slate-400" colSpan={2}>
                            <div className="text-center">
                                <Select
                                    placeholder='Choose Sub Category'
                                    searchable clearable allowDeselect
                                    nothingFound="No options"
                                    withAsterisk
                                    data={
                                        itemCategorySubCat && itemCategorySubCat.sub_category ?
                                            itemCategorySubCat.sub_category.map((head, index) => {
                                                return ({
                                                    'value': Number(head.id),
                                                    'label': head.sub_categories_name
                                                })
                                            }):[]
                                    }
                                    transition="pop-top-left"
                                    transitionDuration={80}
                                    transitionTimingFunction="ease"
                                    maxDropdownHeight={280}
                                    onChange={(e) => {
                                        dispatch(setItemFromData({...itemFromData,['sub_category_id']:e}))
                                    }}
                                    value={itemFromData.sub_category_id?Number(itemFromData.sub_category_id):''}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>

                        <td className="p-2 border-collapse border border-slate-400" colSpan={4}>
                            <div className="text-center">
                                <TextInput
                                    className={`text-center`}
                                    placeholder={`Remarks`}
                                    value={itemFromData.remark?itemFromData.remark:''}
                                    onChange={(e)=>{
                                        dispatch(setItemFromData({...itemFromData,['remark']:e.target.value}))
                                    }}
                                />
                            </div>
                        </td>

                        <td className="p-2 border-collapse border border-slate-400" colSpan={2}>
                            <div className="text-center">
                                <TextInput
                                    className={`text-center`}
                                    placeholder={remainingQuantity.remainingQuantity?'Stock Quantity '+remainingQuantity.remainingQuantity:'Quantity'}
                                    value={itemFromData.quantity?itemFromData.quantity:''}
                                    onChange={(e)=>{
                                        if (Number(e.target.value)>Number(remainingQuantity.remainingQuantity)){
                                            showNotification({
                                                id: 'load-data',
                                                loading: true,
                                                title: 'Stock quantity '+remainingQuantity.remainingQuantity,
                                                message: 'Exists Stock quantity',
                                                autoClose: 1000,
                                                disallowClose: true,
                                                color: 'red',
                                            });
                                        }else{
                                            dispatch(setItemFromData({...itemFromData,['quantity']:e.target.value}))
                                        }
                                    }}
                                />
                            </div>
                        </td>

                        <td className="p-2 border-collapse border border-slate-400" colSpan={2}>
                            <button type={'button'}
                                    onClick={(e) => {
                                        let validation = true
                                        let validationMessage = ''
                                        if (itemFromData.quantity == '' || itemFromData.quantity == null){
                                            validation = false
                                            validationMessage = 'Enter quantity'
                                        }else {
                                            if (Number(itemFromData.quantity)>Number(remainingQuantity.remainingQuantity)){
                                                validation = false
                                                validationMessage = 'Stock quantity '+remainingQuantity.remainingQuantity
                                            }
                                        }

                                        if (itemFromData.sub_category_id == '' || itemFromData.sub_category_id == null){
                                            validation = false
                                            validationMessage = 'Choose sub category'
                                        }

                                        if (itemFromData.item_id == '' || itemFromData.item_id == null){
                                            validation = false
                                            validationMessage = 'Choose item'
                                        }

                                        if (validation){
                                            let data = {
                                                store_requisition_id:id,
                                                item_id:itemFromData.item_id,
                                                category_id:itemFromData.category_id,
                                                sub_category_id:itemFromData.sub_category_id,
                                                remark:itemFromData.remark,
                                                quantity:itemFromData.quantity,
                                            }
                                            dispatch(createStoreRequisitionItems(data))
                                            dispatch(setItemCategorySubCat())
                                            dispatch(setEmptyRemainingQuantity())
                                            setRecall(true)
                                        }else {
                                            showNotification({
                                                id: 'load-data',
                                                loading: true,
                                                title: validationMessage,
                                                message: (t('DataLoad')),
                                                autoClose: 1000,
                                                disallowClose: true,
                                                color: 'red',
                                            });
                                        }
                                    }}
                                    className="inline-flex w-full h-full justify-center items-center px-2 py-2 bg-indigo-700 text-white hover:bg-indigo-600 text-xs">
                                <HiOutlinePlus  size={12} className={'mr-1'} ></HiOutlinePlus>{t("Add")}
                            </button>
                        </td>
                    </tr>
                    </thead>

                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                    <tr>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('Item')}</div>
                        </th>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('itemType')}</div>
                        </th>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('Unit')}</div>
                        </th>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('Category')}</div>
                        </th>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('SubCategory')}</div>
                        </th>

                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('IssueQty')}</div>
                        </th>

                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center">{t('Remarks')}</div>
                        </th>
                        <th className="p-2 border-collapse border border-slate-400">
                            <div className="font-semibold text-center"></div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        (storeRequisitionDetailsData && storeRequisitionDetailsData['storeRequisitionItems'] && storeRequisitionDetailsData['storeRequisitionItems'].length >0 ) &&
                        storeRequisitionDetailsData['storeRequisitionItems'].map((item, index) => {
                            let subCategoryDropdown =
                                item.subCategoryDropdownData?item.subCategoryDropdownData.map((head, index) => {
                                    return ({
                                        'value': Number(head.id),
                                        'label': head.sub_categories_name
                                    })
                                }):[]
                            return (
                                <tr className={"bg-gray-100"} key={index}>
                                    <td className="w-5/24 border-collapse border border-slate-400 text-center">
                                        {item.itemName?item.itemName:''}
                                    </td>
                                    <td className="w-5/24 border-collapse border border-slate-400 text-center">
                                        {item.itemType?item.itemType:''}
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {item.itemUnit?item.itemUnit:''}
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {item.category_name?item.category_name:''}
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <Select
                                            placeholder='Choose Sub Category'
                                            searchable clearable allowDeselect
                                            nothingFound="No options"
                                            withAsterisk
                                            data={subCategoryDropdown?subCategoryDropdown:[]}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            maxDropdownHeight={280}
                                            onChange={(e) => {
                                                dispatch(storeRequisitionItemInlineUpdate(
                                                    {
                                                        store_req_item_id : item.id,
                                                        "field_name": "sub_category",
                                                        "value": e
                                                    }
                                                ))
                                                setRecall(true)
                                            }}
                                            value={item.sub_categories?Number(item.sub_categories):''}
                                        />
                                    </td>

                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <TextInput
                                            className={`text-center`}
                                            defaultValue={item.quantity?Number(item.quantity):''}
                                            placeholder={item.remaining.remainingQuantity?'Stock Quantity '+item.remaining.remainingQuantity:'Quantity'}
                                            onKeyUp={(e)=>{
                                                if (Number(e.target.value)>Number(item.remaining.remainingQuantity)){
                                                    showNotification({
                                                        id: 'load-data',
                                                        loading: true,
                                                        title: 'Stock quantity '+item.remaining.remainingQuantity,
                                                        message: 'Exists Stock quantity',
                                                        autoClose: 700,
                                                        disallowClose: true,
                                                        color: 'red',
                                                    });
                                                }else {
                                                    dispatch(storeRequisitionItemInlineUpdate(
                                                        {
                                                            store_req_item_id : item.id,
                                                            "field_name": "quantity",
                                                            "value": e.target.value
                                                        }
                                                    ))
                                                }
                                            }}
                                        />
                                    </td>

                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <TextInput
                                            className={`text-center`}
                                            defaultValue={item.remark?item.remark:''}
                                            onKeyUp={(e)=>{
                                                dispatch(storeRequisitionItemInlineUpdate(
                                                    {
                                                        store_req_item_id : item.id,
                                                        "field_name": "remark",
                                                        "value": e.target.value
                                                    }
                                                ))
                                            }}
                                        />
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <button type={'button'} className="inline-flex w-full h-full justify-center items-center text-xs">
                                            <ActionIcon color="red">
                                                <HiTrash
                                                    size={16}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        openConfirmModal({
                                                            title: (t('DeleteThisData')),
                                                            centered: true,
                                                            children: (
                                                                <Text size="sm">
                                                                    {t('AreYouSureYouWantToDelete')}
                                                                </Text>
                                                            ),
                                                            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                            confirmProps: { color: 'red' },
                                                            onCancel: () => console.log(t('Cancel')),
                                                            onConfirm: () => {
                                                                setTimeout(() => {
                                                                    dispatch(storeRequisitionItemDelete(item.id))
                                                                    setRecall(true)
                                                                },500)
                                                            },
                                                        });
                                                    }}
                                                />
                                            </ActionIcon>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


    <div className="relative mx-auto w-full px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg  rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('Attachment')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>

                    <div className='flex flex-wrap'>
                        <div className="mb-3 w-1/2 items-center">
                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                <label htmlFor="project_to" className="form-input-sm-label">Requisition Attachment</label>
                                <Dropzone
                                    onDrop={(file) => {
                                        const data = {
                                            id : id,
                                            field_name : "attachment",
                                            value : file[0]
                                        }
                                        dispatch(storeRequisitionInlineUpdateAttachment(data))
                                        setRecall(true)
                                    }}
                                    onReject={(files) =>{
                                        showNotification({
                                            id: 'load-data',
                                            loading: true,
                                            title: 'Please select PDF file.',
                                            message: (t('DataLoad')),
                                            autoClose: 1000,
                                            disallowClose: true,
                                            color: 'red',
                                        });
                                    }}
                                    accept={[MIME_TYPES.pdf]}
                                    multiple={false}                                >
                                    <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                size="2.2rem"
                                                stroke={1.5}
                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                size="2.2rem"
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <IconPhoto size="2.2rem" stroke={1.5} />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text size="sm" inline>
                                                {
                                                    storeRequisitionDetailsData.path ? 'Selected PDF '+storeRequisitionDetailsData.path:'Drag images here or click to select files'
                                                }
                                            </Text>
                                        </div>
                                    </Group>
                                </Dropzone>
                                { storeRequisitionDetailsData.path &&
                                <>
                                    <a title="Store Requisition Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/store-req/${storeRequisitionDetailsData.path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                </>
                                }
                            </div>
                        </div>

                        <div className="mb-3 w-1/2 items-center">
                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                <label htmlFor="project_to" className="form-input-sm-label">Challan Attachment</label>
                                <Dropzone
                                    onDrop={(file) => {
                                        const data = {
                                            id : id,
                                            field_name : "challan-attachment",
                                            value : file[0]
                                        }
                                        dispatch(storeRequisitionInlineUpdateAttachment(data))
                                        setRecall(true)
                                    }}
                                    onReject={(files) =>{
                                        showNotification({
                                            id: 'load-data',
                                            loading: true,
                                            title: 'Please select PDF file.',
                                            message: (t('DataLoad')),
                                            autoClose: 1000,
                                            disallowClose: true,
                                            color: 'red',
                                        });
                                    }}
                                    accept={[MIME_TYPES.pdf]}
                                    multiple={false}                                >
                                    <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                size="2.2rem"
                                                stroke={1.5}
                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                size="2.2rem"
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <IconPhoto size="2.2rem" stroke={1.5} />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text size="sm" inline>
                                                {
                                                    storeRequisitionDetailsData.challan_path ? 'Selected PDF '+storeRequisitionDetailsData.challan_path:'Drag images here or click to select files'
                                                }
                                            </Text>
                                        </div>
                                    </Group>
                                </Dropzone>
                                { storeRequisitionDetailsData.challan_path &&
                                <>
                                    <a title="Store Requisition Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/store-req/${storeRequisitionDetailsData.challan_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



                                                                <div className="relative mx-auto w-full px-2 lg:px-2 mb-4">
                        <div className="mx-auto max-w-lg  rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                <div className="mt-4">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('Notes')}</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                    </div>

                                    <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                        <div className="mt-5 md:col-span-5 md:mt-0">
                                            <div className="shadow sm:rounded">
                                                <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                        <div className="mb-3 w-full items-center">
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                <Textarea
                                                                    className={`text-center`}
                                                                    placeholder={`Notes`}
                                                                    defaultValue={fromData.note?fromData.note:storeRequisitionDetailsData.note}
                                                                    onBlur={(e)=>{
                                                                        dispatch(storeRequisitionInlineUpdate({
                                                                            id : id,
                                                                            field_name : "note",
                                                                            value : e.target.value
                                                                        }))
                                                                        dispatch(setFromData({...fromData,['note']:e.target.value}))
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-6">
                                                                <div className="text-right pt-0.5 mb-0.5 ">
                                                                    <button
                                                                        type='reset'
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/>
                                                                        <span>{t('Back')}</span>
                                                                    </button>

                                                                    <button type='submit' className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                            <HiArrowCircleRight size={16} className={'mr-2'}/>
                                                                        <span>{ t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default UpdateStoreRequisition