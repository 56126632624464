import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    projectDetailsServie,
    projectDropdownService,
    projectDropdownUserWiseService, projectIndexsServie
} from "../../services/ProjectService";


export const projectDropdown = createAsyncThunk("project/dropdown", async () => {
    try {
        const response = projectDropdownService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const projectDropdownUserWise = createAsyncThunk("project/dropdown-user-wise", async () => {
    try {
        const response = projectDropdownUserWiseService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const projectDetailsData = createAsyncThunk("project/details", async (id) => {
    try {
        const response = projectDetailsServie(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getProjectIndexData = createAsyncThunk("project/index", async (param) => {
    try {
        const response = projectIndexsServie(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const projectSlice = createSlice({
    name : "project",
    initialState : {
        isLoading : true,
        message : null,
        projects : [],
        projectsIndexData : [],
        projectsUserWise : [],
        projectDetails : [],
        totalItem : 0,
        fetching : true,
        refresh : false,
        opened : false,
        page : localStorage.getItem('project_page') ? localStorage.getItem('project_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'createdDate', direction: 'DESC' },
        filterData : {project:'',item_type:'',area:''},
    },

    reducers : {
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.project = action.payload.project;
            state.filterData.company_type = action.payload.company_type;
            state.filterData.area = action.payload.area;
        },

    },
    extraReducers : (builder) => {
        builder.addCase(projectDropdown.fulfilled, (state, action) => {
            state.projects = action.payload
            state.isLoading = false
            state.message = 'projectLoad'
        })
        builder.addCase(projectDropdownUserWise.fulfilled, (state, action) => {
            state.projectsUserWise = action.payload
            state.isLoading = false
            state.message = 'projectLoad'
        })
        builder.addCase(projectDetailsData.fulfilled,(state,action) => {
            state.projectDetails = action.payload
        })

        builder.addCase(getProjectIndexData.fulfilled,(state,action)=>{
            state.projectsIndexData = action.payload.data.data
            state.totalItem = action.payload.data.total
            state.fetching = false
        })
    }
})
export const { setActiveTab,setSortStatus,setFilterData,setPage,setFetching,setRefresh,setSpinner,setOpened } = projectSlice.actions

export default projectSlice.reducer;