import React, {Fragment, useEffect, useState} from 'react'
import {useRef} from 'react';

import {useTranslation} from "react-i18next";
import {Group, Loader, LoadingOverlay, ScrollArea, Text, TextInput, useMantineTheme} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";

import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";

import {jsPDF} from "jspdf";
import {
    approveStoreRequisition, setModelAllDataEmpty,
    setViewId,
    setViewModel, storeRequisitionDelivered,
    storeRequisitionDetails, storeRequisitionInlineUpdateAttachment,
    storeRequisitionItemInlineUpdate, storeRequisitionReceived
} from "../../../store/storeRequisition/storeRequisitionSlice";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import autoTable from 'jspdf-autotable'


function SrViewModel() {
    const printRef = useRef()
    const theme = useMantineTheme();

    const downloadPdfDocument = async () => {
        const doc = new jsPDF('p','pt','a4')
        // doc.setFontSize(12);
        // doc.setFontStyle('normal');
        // doc.text(new Date().toLocaleString(), 40, 50)
        /*doc.setProperties({
            title: 'Title',
            subject: 'This is the subject',
            author: 'James Hall',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'MEEE'
        });*/
        autoTable(doc, {
            body: [
                [{ content: new Date().toLocaleString(),theme: 'plain', styles: { halign: 'center',fontSize:8 } }],
                [{ content: 'Store Requisition',theme: 'grid', styles: { halign: 'center',fontStyle:'bold',fontSize: 15} }],
            ],
        })
        autoTable(doc, {
            body: [
                [{ content: 'Project Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#projectInformation',theme: 'grid' })
        autoTable(doc, {
            body: [
                [{ content: 'Item Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#ItemInformation',theme: 'grid' })

        autoTable(doc, {
            body: [
                [{ content: 'Attachment Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#AttachmentInformation',theme: 'grid' })
        autoTable(doc, {
            body: [
                [{ content: 'Note Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold',marginBottom:-10 } }],
            ],
        })
        autoTable(doc, { html: '#NoteInformation',columnStyles: {marginLeft:20}})
        autoTable(doc, {
            body: [
                [{ content: 'Approval Information',theme: 'grid', styles: { halign: 'left',fontStyle:'bold' } }],
            ],
        })
        autoTable(doc, { html: '#ApprovalInformation',theme: 'grid' })
        doc.save('store-requisition-'+storeRequisitionDetailsData.sr_number+'.pdf')
    }

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_STORE_REQUISITION_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_ONE";
    })
    const ROLE_STORE_REQUISITION_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_TWO";
    })
    const ROLE_STORE_REQUISITION_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_THREE";
    })
    const ROLE_STORE_REQUISITION_RECEIVED = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_RECEIVED";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const viewID = useSelector((state) => state.storeRequisitionReducer.viewID)
    const fetching = useSelector((state) => state.storeRequisitionReducer.fetching)
    const storeRequisitionDetailsData = useSelector((state) => state.storeRequisitionReducer.storeRequisitionDetailsData)
    const storeRequisitionItems = useSelector((state) => state.storeRequisitionReducer.storeRequisitionItems)

    useEffect((e) => {
        setTimeout(() => {
            dispatch(
                storeRequisitionDetails({
                    id: viewID
                })
            )
            setLoading(false)
        }, 500)
    }, [loading])


    const srApproved = (e, srID, status) => {
        e.preventDefault();
        let titleMsg = ''
        let titleMsgDetails = ''
        if (status == 'Verified') {
            titleMsg = t('ValidatedThisSr')
            titleMsgDetails = t('ValidatedThisPOThisSrDetails')
        }
        if (status == 'Validated') {
            titleMsg = t('ApproveThisSR')
            titleMsgDetails = t('ApproveThisSRDetails')
        }
        if (status == 'Created') {
            titleMsg = t('VerifiedThisSR')
            titleMsgDetails = t('VerifiedThisSRDetails')
        }

        openConfirmModal({
            title: titleMsg,
            centered: true,
            children: (
                <Text size="sm">
                    {titleMsgDetails}
                </Text>
            ),
            labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
            confirmProps: {color: 'green'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                const approveData = {
                    sr_id: srID,
                    status: status
                }
                dispatch(approveStoreRequisition(approveData))
                setLoading(true)
            }
            ,
        });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('StoreRequisitionDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setViewModel(false))
                                        dispatch(setViewId(null))
                                        dispatch(setModelAllDataEmpty())
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100" id="">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative" ref={printRef}>

                                                                {
                                                                    storeRequisitionDetailsData  &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="projectInformation">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Date')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SrNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ProjectFrom')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ProjectTo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('TotalItem')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('TotalItemQuantity')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                        <tr className={"bg-gray-100"} key='Created'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.created_at?storeRequisitionDetailsData.created_at:''}</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.sr_number ? storeRequisitionDetailsData.sr_number : ''}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.project_from_name ? storeRequisitionDetailsData.project_from_name : ''}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.project_to_name ? storeRequisitionDetailsData.project_to_name : ''}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.total_item ? storeRequisitionDetailsData.total_item : ''}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.total_item_quantity ? storeRequisitionDetailsData.total_item_quantity : ''}</td>
                                                                                        </tr>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {
                                                                    (storeRequisitionDetailsData &&  storeRequisitionItems && storeRequisitionItems.length > 0) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4" id="pdf-id">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>


            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="ItemInformation">
                <thead className="text-xs font-semibold  text-black bg-gray-200">
                <tr>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('SL')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('ItemName')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Unit')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('ItemType')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Category')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('SubCategory')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('StockQuantity')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Quantity')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Remark')}</div>
                    </th>
                </tr>

                </thead>
                <tbody>
                {
                    (storeRequisitionDetailsData && storeRequisitionItems && storeRequisitionItems.length > 0) && storeRequisitionItems.map((orderItem, index) => {
                        return (
                            <tr className={"bg-gray-100"}
                                key={index}>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                    {++index}
                                </td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.itemName ? orderItem.itemName : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.itemUnit ? orderItem.itemUnit : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.itemType ? orderItem.itemType : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.category_name ? orderItem.category_name : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.sub_category_name ? orderItem.sub_category_name : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.remaining.remainingQuantity ? orderItem.remaining.remainingQuantity : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                    {
                                        (storeRequisitionDetailsData.process == 'Created' && ROLE_STORE_REQUISITION_APPROVE_ONE) ?
                                                <TextInput
                                                    className={`text-center`}
                                                    defaultValue={orderItem.quantity?Number(orderItem.quantity):''}
                                                    placeholder={orderItem.remaining.remainingQuantity?'Stock '+orderItem.remaining.remainingQuantity:'Quantity'}
                                                    onBlur={(e)=>{
                                                        if (Number(e.target.value)>Number(orderItem.remaining.remainingQuantity)){
                                                            showNotification({
                                                                id: 'load-data',
                                                                loading: true,
                                                                title: 'Stock quantity '+orderItem.remaining.remainingQuantity,
                                                                message: 'Exists Stock quantity',
                                                                autoClose: 700,
                                                                disallowClose: true,
                                                                color: 'red',
                                                            });
                                                        }else {
                                                            dispatch(storeRequisitionItemInlineUpdate(
                                                                {
                                                                    store_req_item_id : orderItem.id,
                                                                    "field_name": "quantity",
                                                                    "value": e.target.value
                                                                }
                                                            ))
                                                            setLoading(true)
                                                        }
                                                    }}
                                                />
                                            :
                                            orderItem.quantity
                                    }
                                </td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.remark ? orderItem.remark : ''}</td>
                            </tr>
                        );
                    })}


                </tbody>
            </table>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

{
    (storeRequisitionDetailsData && storeRequisitionDetailsData.path) &&
    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>

                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="AttachmentInformation">
                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                        <tr>

                            <th className="p-2 border-collapse border border-slate-400">
                                <div className="font-semibold text-center">Requisition</div>
                            </th>
                            <th className="p-2 border-collapse border border-slate-400">
                                <div className="font-semibold text-center">Challan</div>
                            </th>
                            <th className="p-2 border-collapse border border-slate-400">
                                <div className="font-semibold text-center">Delivery</div>
                            </th>
                        </tr>

                        </thead>
                        <tbody>
                        <tr className={"bg-gray-100"} key='1'>
                            <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                {
                                    storeRequisitionDetailsData.path ?
                                        <a title="Requisition Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/store-req/${storeRequisitionDetailsData.path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{storeRequisitionDetailsData.path}</a>
                                        :''
                                }

                            </td>
                            <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                {
                                    storeRequisitionDetailsData.challan_path ?
                                        <a title="Challan Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/store-req/${storeRequisitionDetailsData.challan_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{storeRequisitionDetailsData.challan_path}</a>
                                        :''
                                }
                            </td>
                            <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                {
                                    storeRequisitionDetailsData.delivery_path ?
                                        <a title="Delivery Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/store-req/${storeRequisitionDetailsData.delivery_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{storeRequisitionDetailsData.delivery_path}</a>
                                        :''
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}


                                                                {
                                                                    ((storeRequisitionDetailsData.is_approved == 'yes' && (ROLE_STORE_REQUISITION_APPROVE_THREE || ROLE_STORE_REQUISITION_APPROVE_TWO || ROLE_STORE_REQUISITION_APPROVE_ONE) && storeRequisitionDetailsData.approve_status == 3 && storeRequisitionDetailsData.status != 2 && storeRequisitionDetailsData.is_delivered == 0) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.approve_status == 3 && storeRequisitionDetailsData.status != 2 && storeRequisitionDetailsData.is_delivered == 0)) &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('DeliveryAttachmentInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <Dropzone
                                                                                        onDrop={(file) => {
                                                                                            const data = {
                                                                                                id: viewID,
                                                                                                field_name: "delivery-attachment",
                                                                                                value: file[0]
                                                                                            }
                                                                                            dispatch(storeRequisitionInlineUpdateAttachment(data))
                                                                                            setLoading(true)
                                                                                        }}
                                                                                        onReject={(files) => {
                                                                                            showNotification({
                                                                                                id: 'load-data',
                                                                                                loading: true,
                                                                                                title: 'Please select PDF file.',
                                                                                                message: (t('DataLoad')),
                                                                                                autoClose: 1000,
                                                                                                disallowClose: true,
                                                                                                color: 'red',
                                                                                            });
                                                                                        }}
                                                                                        accept={[MIME_TYPES.pdf]}
                                                                                        multiple={false}>
                                                                                        <Group position="center"
                                                                                               spacing="xl"
                                                                                               style={{pointerEvents: 'none'}}>
                                                                                            <Dropzone.Accept>
                                                                                                <IconUpload
                                                                                                    size="2.2rem"
                                                                                                    stroke={1.5}
                                                                                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                />
                                                                                            </Dropzone.Accept>
                                                                                            <Dropzone.Reject>
                                                                                                <IconX
                                                                                                    size="2.2rem"
                                                                                                    stroke={1.5}
                                                                                                />
                                                                                            </Dropzone.Reject>
                                                                                            <Dropzone.Idle>
                                                                                                <IconPhoto size="2.2rem"
                                                                                                           stroke={1.5}/>
                                                                                            </Dropzone.Idle>

                                                                                            <div>
                                                                                                <Text size="sm" inline>
                                                                                                    {
                                                                                                        storeRequisitionDetailsData.delivery_path ? 'Selected PDF ' + storeRequisitionDetailsData.delivery_path : 'Drag images here or click to select files'
                                                                                                    }
                                                                                                </Text>
                                                                                            </div>
                                                                                        </Group>
                                                                                    </Dropzone>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {
                                                                    (storeRequisitionDetailsData && storeRequisitionDetailsData.note) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('NoteInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <table className="mt-5 table-full w-full" id="NoteInformation">
                                                                                        <tbody>

                                                                                        <tr>
                                                                                            <td>
                                                                                                <ul role="list" >
                                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                                        <p className="ml-3 text-sm text-gray-700 text-justify">
                                                                                                            {storeRequisitionDetailsData.note}
                                                                                                        </p>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>

                                                                                        </tbody>
                                                                                    </table>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {
                                                                    (storeRequisitionDetailsData && storeRequisitionDetailsData.created_by) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ApproveInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="ApprovalInformation">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ApproveType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Name')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Date')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                        <tr className={"bg-gray-100"} key='Created'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">1</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">Created</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.created_by_username ? storeRequisitionDetailsData.created_by_username : storeRequisitionDetailsData.created_by_email}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.created_at ? storeRequisitionDetailsData.created_at : ''}</td>
                                                                                        </tr>
                                                                                        {
                                                                                            storeRequisitionDetailsData.approve_by_one &&
                                                                                            <tr className={"bg-gray-100"} key='Verified'>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">2</td>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Verified</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_one_username ? storeRequisitionDetailsData.approve_by_one_username : storeRequisitionDetailsData.approve_by_one_email}</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_one_date ? storeRequisitionDetailsData.approve_by_one_date : ''}</td>
                                                                                            </tr>
                                                                                        }


                                                                                        {
                                                                                            storeRequisitionDetailsData.approve_by_two &&
                                                                                            <tr className={"bg-gray-100"} key='Validated'>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">3</td>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Validated</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_two_username ? storeRequisitionDetailsData.approve_by_two_username : storeRequisitionDetailsData.approve_by_two_email}</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_two_date ? storeRequisitionDetailsData.approve_by_two_date : ''}</td>
                                                                                            </tr>
                                                                                        }
                                                                                        {
                                                                                            storeRequisitionDetailsData.approve_by_three &&
                                                                                            <tr className={"bg-gray-100"} key='Approved'>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">4</td>
                                                                                                <td className="w-1/12 border-collapse border border-slate-400 text-center">Approved</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_three_username ? storeRequisitionDetailsData.approve_by_three_username : storeRequisitionDetailsData.approve_by_three_email}</td>
                                                                                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{storeRequisitionDetailsData.approve_by_three_date ? storeRequisitionDetailsData.approve_by_three_date : ''}</td>
                                                                                            </tr>
                                                                                        }

                                                                                        </tbody>
                                                                                    </table>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>


                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-left mb-3">
                                                            {
                                                                fetching ?
                                                                    <>
                                                                        <div className="grid place-items-left">
                                                                            <Loader size="sm" color={'green'}/>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            ((storeRequisitionDetailsData.process === 'Created' && storeRequisitionDetailsData.is_approved=='yes' && ROLE_STORE_REQUISITION_APPROVE_ONE) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.process === 'Created')) &&
                                                                            <button
                                                                                onClick={(e) => srApproved(e, storeRequisitionDetailsData.id, storeRequisitionDetailsData.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Verified
                                                                            </button>
                                                                        }

                                                                        {
                                                                            ((storeRequisitionDetailsData.process === 'Verified' && storeRequisitionDetailsData.is_approved=='yes' && ROLE_STORE_REQUISITION_APPROVE_TWO) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.process === 'Verified')) &&
                                                                            <button
                                                                                onClick={(e) => srApproved(e, storeRequisitionDetailsData.id, storeRequisitionDetailsData.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Validated
                                                                            </button>
                                                                        }

                                                                        {
                                                                            ((storeRequisitionDetailsData.process === 'Validated' && storeRequisitionDetailsData.is_approved=='yes' && ROLE_STORE_REQUISITION_APPROVE_THREE) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.process === 'Validated')) &&
                                                                            <button
                                                                                onClick={(e) => srApproved(e, storeRequisitionDetailsData.id, storeRequisitionDetailsData.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Approved
                                                                            </button>
                                                                        }


                                                                        {
                                                                            ( (storeRequisitionDetailsData.is_approved=='yes' && (ROLE_STORE_REQUISITION_APPROVE_THREE || ROLE_STORE_REQUISITION_APPROVE_TWO || ROLE_STORE_REQUISITION_APPROVE_ONE) && storeRequisitionDetailsData.approve_status == 3 && storeRequisitionDetailsData.status!=2 && storeRequisitionDetailsData.is_delivered == 0) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.approve_status==3 && storeRequisitionDetailsData.status!=2 && storeRequisitionDetailsData.is_delivered == 0)) &&  <button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: 'Are you sure to delivery this store requisition ?',
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                Are you sure , you want to delivery this store requisition ? Press confirm, if you want to confirm otherwise cancel.
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            dispatch(storeRequisitionDelivered(storeRequisitionDetailsData.id))
                                                                                            setLoading(true)
                                                                                        }
                                                                                        ,
                                                                                    });

                                                                                }}
                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                Delivery
                                                                            </button>
                                                                        }


                                                                        {
                                                                            ( (storeRequisitionDetailsData.is_received=='yes' && ROLE_STORE_REQUISITION_RECEIVED && storeRequisitionDetailsData.approve_status == 3 && storeRequisitionDetailsData.status!=2 && storeRequisitionDetailsData.is_delivered == 1) || (ROLE_SUPER_ADMIN && storeRequisitionDetailsData.approve_status==3 && storeRequisitionDetailsData.status!=2 && storeRequisitionDetailsData.is_delivered == 1)) &&  <button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: 'Are you sure to received this store requisition ?',
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                Are you sure , you want to received this store requisition ? Press confirm, if you want to confirm otherwise cancel.
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            dispatch(storeRequisitionReceived({
                                                                                                id : storeRequisitionDetailsData.id
                                                                                            }))
                                                                                            setLoading(true)
                                                                                        }
                                                                                        ,
                                                                                    });

                                                                                }}
                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                Received
                                                                            </button>
                                                                        }

                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="float-right mb-3">
                                                            {/*<button className="inline-flex items-center px-6 py-2 bg-blue-700 hover:bg-blue-900 text-white text-xs ml-2" onClick={downloadPdfDocument}>Generate PDF</button>*/}
                                                                <button
                                                                onClick={(e) => {
                                                                    dispatch(setViewModel(false))
                                                                    dispatch(setViewId(null))
                                                                    dispatch(setModelAllDataEmpty())
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>


                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default SrViewModel