import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,HiSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, LoadingOverlay, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {DatePicker, Month} from "@mantine/dates";
import {format} from "date-fns";
import {openConfirmModal} from "@mantine/modals";
import {
    setPendingClaimReportFilterData,
    setFetching,
    setPage,
    getPendingClaim
} from "../../../../store/report/reportSlice";
import {showNotification} from "@mantine/notifications";
const PAGE_SIZE = 20;
function PrPendingClaimReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    // start get contact person dropdown
    const [isDownload, setIsDownload] = useState(false);

    const pendingClaimReportFilterData = useSelector((state) => state.reportReducer.pendingClaimReportFilterData)
    const pendingClaimData = useSelector((state) => state.reportReducer.pendingClaimData)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)

    const [value, setValue] = useState(new Date());


    const CallPoReport = (page=1) => {
        if (pendingClaimReportFilterData.start_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                date : pendingClaimReportFilterData.start_date,
            }
            dispatch(getPendingClaim(param))
        }
    }

    const exportToCSV = () => {
        openConfirmModal({
            title: 'It may some times , sure ? ',
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure to download
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
                dispatch(setFetching(true))
                const param = {
                    date : pendingClaimReportFilterData.start_date,
                }
                 axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/pending-claim/generate-csv`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `text/html`,
                        "Content-Disposition": 'attachment; filename=data.csv',
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                    },
                    params : param
                })
                    .then(res => {
                        if (res.status === 200){
                            const  fileName = 'PendingClaim-'+format(new Date(), 'dd-MM-yyyy')+'.csv'
                            const href = `${process.env.REACT_APP_API_GATEWAY_URL}/report/pending-claim/download-csv`;
                            const anchorElement = document.createElement('a');
                            anchorElement.href = href;
                            anchorElement.download = fileName;
                            document.body.appendChild(anchorElement);
                            anchorElement.click();
                            setIsDownload(false)
                            dispatch(setFetching(false))
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PendingClaimReport')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PendingClaimReportRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {(pendingClaimData && pendingClaimData.length>0) &&
                                                                <>
                                                                <button
                                                                onClick={(e) => exportToCSV()}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={pendingClaimReportFilterData.start_date?pendingClaimReportFilterData.start_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPendingClaimReportFilterData({
                                                                                        ...pendingClaimReportFilterData,
                                                                                        ['start_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPendingClaimReportFilterData({
                                                                                        ...pendingClaimReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <button
                                                                            onClick={(e) => {
                                                                                CallPoReport()
                                                                            }}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={fetching}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{height: reportTableHeight,width: containerWidth}} className="mt-3">
                                                                    <DataTable
                                                                        withBorder
                                                                        records={pendingClaimData}
                                                                        withColumnBorders={1}
                                                                        highlightOnHover
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                render: (item) => (pendingClaimData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'item_name', title: 'Item Name',},
                                                                            {accessor: 'pr_number', title: 'PR No',},
                                                                            {accessor: 'projects_name', title: 'Project Name'},
                                                                            {accessor: 'approve_date', title: 'Approve Date'},
                                                                            {accessor: 'poi_quantity', title: 'Quantity'},
                                                                            {accessor: 'close_quantity', title: 'Close Quantity'},
                                                                            {accessor: 'item_unit', title: 'Unit'},
                                                                            {accessor: 'head_or_local', title: 'Local/Head'},
                                                                            {accessor: 'process', title: 'Status'},
                                                                        ]}

                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            CallPoReport(p)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PrPendingClaimReport