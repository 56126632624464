import React from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {
    HiOutlineExclamation,
    HiOutlineUser,
    HiArrowCircleRight,
    HiArrowCircleLeft,
    HiOutlineOfficeBuilding,
    HiOutlineSearch,
    HiOutlinePlus, HiOutlineChartSquareBar, HiOutlineExclamationCircle
} from "react-icons/hi";
import {
    Button,
    ScrollArea
} from "@mantine/core";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from '@mantine/notifications';
import {AiOutlineLoading3Quarters} from "react-icons/ai";

function AddPermission() {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate()
    const token = localStorage.getItem('user_token');
    const [spinner, setSpinner] = useState(false);
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    //Form validation
    const validationSchema = Yup.object().shape({
        label: Yup.string().required(t("EnterLabel")),
        group: Yup.string().required(t("EnterGroup")),
        value: Yup.string().required(t("EnterPermission")),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            permission_label: data.label,
            permission_value: data.value,
            permission_group: data.group,
            status: true
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/user-permission/`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('UserPermissionAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    reset();
                    navigate('/permission')
                }else if (res.data.status === 200){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('ThisPermissionAlreadyEXists')),
                        message: (t('DataLoad')),
                        autoClose: 500,
                        disallowClose: true,
                        color: 'red',
                    });
                }else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
        }
    }

    return (
        <>
            <main className="w-full">
                {/*<div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('UserPermissionInformation')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('UserPermissionInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                </div>*/}

                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3 ">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('UserPermission')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('UserPermissionInformation')}</sub></span>
                                                    </h1>
                                                </div>

                                                <div className={"right flex mt-2 mr-8"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <Link to='/permission' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                            <Link to='/permission'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">

                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

        <div className="mb-3 w-full items-center">
            <label htmlFor="label" className="form-input-sm-label-required">{t('Group')}</label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.group?.type === 'required' &&
                <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.group?.message}</div>}
                <input
                    {...register("group")}
                    type="text"
                    className={`form-input-sm-control ${errors.group ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                    placeholder={t("EnterGroup")}
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="label" className="form-input-sm-label-required">{t('Label')}</label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.label?.type === 'required' &&
                <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.label?.message}</div>}
                <input
                    {...register("label")}
                    type="text"
                    className={`form-input-sm-control ${errors.label ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                    placeholder={t("EnterLabel")}
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="value" className="form-input-sm-label-required">{t('Permission')}</label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.value?.type === 'required' &&
                <div className="form-input-sm-error"><HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.value?.message}</div>}
                <input
                    {...register("value")}
                    type="text"
                    className={`form-input-sm-control ${errors.value ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                    placeholder={t("EnterPermission")}
                />
            </div>
        </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1">
                                                            <div className="justify-items-center">
                                                                <div className="text-right border-t pt-0.5 mb-0.5 pr-2">
                                                                    <Button
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-400 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}></AiOutlineLoading3Quarters>
                                                                        ) : (<HiArrowCircleRight size={16}
                                                                                                 className={'mr-2'}></HiArrowCircleRight>)}
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default AddPermission