import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {SubCategoryDropdown} from "../../services/SubCategoryService";


export const getSubCategoryDropdown = createAsyncThunk("sub-category/dropdown", async (id) => {
    try {
        const response = SubCategoryDropdown(id);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
const subCategorySlice = createSlice({
    name : "category",
    initialState : {
        isLoading : true,
        message : null,
        subCategoryDropdownData : [],
    },
    reducers : {
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.item_type = action.payload.item_type;
            state.filterData.area = action.payload.area;
        }

    },

    extraReducers : (builder) => {
        builder.addCase(getSubCategoryDropdown.fulfilled, (state, action) => {
            state.subCategoryDropdownData = action.payload
        })

    }
})
export const { setActiveTab,setSortStatus,setFilterData,setPage,setFetching,setRefresh,setSpinner,setOpened,setEmptyVendorDetailsData } = subCategorySlice.actions

export default subCategorySlice.reducer;