import React from "react";
import GetTab from "../utilities/modal/GetTab";
import {FiBell} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {
    HiMinusCircle,
    HiOutlineBell, HiOutlineMenu, HiPlusCircle
} from "react-icons/hi";
import ProfileDropdown from "./ProfileDropdown";
import {setLeftMenuCollapsed} from "../../store/common/commonSlice";
import {useDispatch, useSelector} from "react-redux";

function Header() {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    const changeLanguageHandler = (e, data) => {
        i18n.changeLanguage(data);
        localStorage.setItem('language', data);
    }
    const leftMenuCollapsed = useSelector((state) => state.commonReducer.leftMenuCollapsed)


    return (
        <>
            <header className="flex bg-indigo-700 h-16 items-center shadow-2xl z-10">
                <div className="flex-1 flex flex-col">
                    <nav className="px-4 flex justify-between bg-white h-16 border-b-2">

                        <div className="flex items-center">
                            <div className="h-6 w-5 mb-3 lg:w-24">
                                <img className="mx-auto w-full" src={`${process.env.REACT_APP_IMAGE_FRONTEND}/assets/nourish_logo.png`} alt="Your Company"/>

                            </div>

                            <div className={'flex right'}>
                                <div className="ml-4 flex items-center md:ml-6">
                                    <div className="group inline-block relative mr-2" id="button">
                                        <button
                                            onClick={() => {
                                                if (leftMenuCollapsed == true){
                                                    dispatch(setLeftMenuCollapsed(false))
                                                }else {
                                                    dispatch(setLeftMenuCollapsed(true))
                                                }
                                            }}
                                            className="relative group overflow-hidden px-4 h-8  flex space-x-2 items-center bg-gradient-to-r from-indigo-500 rounded-sm to-indigo-400 hover:to-purple-600">
                                            <span className="relative text-sm text-white">
                                                {
                                                    leftMenuCollapsed ? <HiPlusCircle size={'24'}></HiPlusCircle> : <HiMinusCircle size={'24'}></HiMinusCircle>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/*<div className={'flex right'}>
                                <div className="ml-4 flex items-center md:ml-6">
                                    <GetTab/>

                                </div>
                            </div>*/}
                        </div>

                        <div className="flex items-center">

                            {/*<form className="flex w-full md:ml-0" action="#" method="GET">
                                <label htmlFor="search-field" className="sr-only">Search</label>
                                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                                         aria-hidden="true">
                                        <FiBell></FiBell>
                                    </div>
                                    <input id="search-field" name="search-field"
                                           className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                           placeholder="Search transactions" type="search"></input>
                                </div>
                            </form>*/}
                        </div>

                        <div className="flex items-center">
                            {/*<div className="pr-3">
                                <HiOutlineBell size={'24'}></HiOutlineBell>
                            </div>*/}
                            {/*<label htmlFor="Toggle3"
                                   className="inline-flex items-center text-sm cursor-pointer text-gray-800 mr-4">
                                <input id="Toggle3" type="checkbox" className="hidden peer"/>
                                <span className="px-4 py-1 text-white text-sm bg-indigo-800 peer-checked:bg-indigo-400"
                                      onClick={(e) => changeLanguageHandler(e, 'en')}>EN</span>
                                <span className="px-4 py-1 text-sm text-white bg-gray-400 peer-checked:bg-gray-800"
                                      onClick={(e) => changeLanguageHandler(e, 'bn')}>BN</span>
                            </label>*/}
                            <ProfileDropdown></ProfileDropdown>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header