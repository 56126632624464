import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


const commonSlice = createSlice({
    name : "Common",
    initialState : {
        leftMenuCollapsed : false,
    },
    reducers : {
        setLeftMenuCollapsed : (state,action) => {
            state.leftMenuCollapsed = action.payload
        }
        },
})

export const { setLeftMenuCollapsed } = commonSlice.actions
export default commonSlice.reducer;