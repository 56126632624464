import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Loader, LoadingOverlay, ScrollArea, Text, Textarea} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {
    inlineUpdatePO,
    poItemLastPrice, poProcessUpdate, prItemClose, receivedItemClose, setCloseId, setCloseModel, setFetching,
    setItemPriceModel,
    setItemPriceModelItemID, setPoItemCloseId, setPoItemCloseModel, setPoViewID, setPoViewModel
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {openConfirmModal} from "@mantine/modals";

function CloseReceivedItemModel() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = '150px';
    const [loading, setLoading] = useState(true);
    const [remark, setRemark] = useState(null);
    const poItemCloseId = useSelector((state) => state.purchaseOrderReducer.poItemCloseId)
    const poItemCloseMessage = useSelector((state) => state.purchaseOrderReducer.poItemCloseMessage)

    if (poItemCloseMessage =='item-close'){
        dispatch(setPoItemCloseModel(false))
        dispatch(setPoItemCloseId(null))
    }

    useEffect((e)=>{
        setTimeout(()=>{
            setLoading(false)
        },500)
    },[])

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ReceivedItemClose')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setPoItemCloseModel(false))
                                        dispatch(setPoItemCloseId(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div className=" w-full items-center">
                                                                            <label htmlFor="remark" className="form-input-sm-label">{t('Remark')}</label>
                                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                <Textarea
                                                                                    placeholder="Your comment"
                                                                                    autosize
                                                                                    minRows={2}
                                                                                    defaultValue={remark ? remark : null}
                                                                                    onChange={(e)=>{
                                                                                        setRemark(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>



                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-left mb-3">

                                                        </div>
                                                        <div className="float-right mb-3">
                                                            <span
                                                                onClick={(e) => {
                                                                    openConfirmModal({
                                                                        title: (t('CloseThisReceivedItem')),
                                                                        centered: true,
                                                                        children: (
                                                                            <Text size="sm">
                                                                                {t('AreYouSureYouCloseThisReceivedItem')}
                                                                            </Text>
                                                                        ),
                                                                        labels: {
                                                                            confirm: (t('Confirm')),
                                                                            cancel: (t("Cancel"))
                                                                        },
                                                                        confirmProps: {color: 'green'},
                                                                        onCancel: () => console.log(t('Cancel')),
                                                                        onConfirm: () => {
                                                                            const data = {
                                                                                received_item_id : poItemCloseId,
                                                                                remark : remark
                                                                            }
                                                                            dispatch(receivedItemClose(data))
                                                                        }
                                                                    });
                                                                }}
                                                                className="inline-flex items-center cursor-pointer px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Received Close
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </footer>

                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

        </>
    )
}

export default CloseReceivedItemModel