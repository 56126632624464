import React, {useEffect, useState} from "react";
import {
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus, HiOutlineSearch,
    HiPencil,HiEye,HiTrash,HiOutlineRefresh
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {ActionIcon, Box, Drawer, Grid, Group, ScrollArea, Select, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import {openConfirmModal} from "@mantine/modals";

import axios from "axios";
import {
    getProjectIndexData,
    setFetching,
    setPage,
    setActiveTab,
    setSortStatus,
    setSpinner,
    setRefresh,
    setOpened,
    setFilterData, projectDropdown
} from "../../../../store/project/projectSlice";
const PAGE_SIZE = 20;

function Dashboard(){
    const {t, i18n} = useTranslation();

    const tableHeight = localStorage.getItem('tableHeight')
    const drawerHeight = localStorage.getItem('drawerHeight')

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const fetching = useSelector((state) => state.projectReducer.fetching)
    const projectsIndexData = useSelector((state) => state.projectReducer.projectsIndexData)
    const projectsDropdown = useSelector((state) => state.projectReducer.projects)
    const totalItem = useSelector((state) => state.projectReducer.totalItem)
    const page = useSelector((state) => state.projectReducer.page)
    const activeTab = useSelector((state) => state.projectReducer.activeTab)
    const sortStatus = useSelector((state) => state.projectReducer.sortStatus)
    const spinner = useSelector((state) => state.projectReducer.spinner)
    const opened = useSelector((state) => state.projectReducer.opened)
    const filterData = useSelector((state) => state.projectReducer.filterData)
    const refresh = useSelector((state) => state.projectReducer.refresh)

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }
    // start get area dropdown
    const [areas, setArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/area/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setArea(res.data.data);
        });
    }, []);

    let areaDropdown =
        areas.map((type, index) => {
            return ({
                'label': type.areas_name, 'value': type.id
            })
        })
    // End get area dropdown

    // start get company type dropdown
    const [companyTypes, setCompanyTypes] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project-type/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setCompanyTypes(res.data.data);
        });
    }, []);

    let companyTypesDropdown =
        companyTypes.map((type, index) => {
            return ({
                'label': type.projects_category_name, 'value': type.id
            })
        })
    // End get company type dropdown

    useEffect(()=>{
        dispatch(projectDropdown())
    },[])

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            project : filterData.project ? filterData.project:'',
            company_type : filterData.company_type ? filterData.company_type:'',
            area : filterData.area ? filterData.area:'',
        }
        dispatch(getProjectIndexData(param))
    }, [page,activeTab,sortStatus,refresh,fetching]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);


    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Project')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllProjectRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <button
                                                                onClick={() => dispatch(setOpened(true))}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            {
                                                                ROLE_SUPER_ADMIN &&
                                                                <button onClick={event => {
                                                                    navigate('/master-data/create-project')
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/master-data/vendor'
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">
                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => HandelPoTabChange(e,'all')}>
                                                                        {t('All')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'deleted'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => HandelPoTabChange(e,'deleted')}>
                                                                        {t('Deleted')}
                                                                    </button>
                                                                </div>

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => dispatch(setOpened(false))}
                                                                    title= {t('ProjectFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{ height: drawerHeight }}>
                                                                            <div className="mb-5">
                                                                                <label htmlFor="ChooseProject" className="form-input-sm-label">{t('ChooseProject')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseProject")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={projectsDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e)=>{
                                                                                        dispatch(setFilterData({...filterData,['project']:e}))
                                                                                    }}
                                                                                    value={filterData && filterData.project ? filterData.project : null}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="ChooseCompanyType" className="form-input-sm-label">{t('ChooseCompanyType')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseCompanyType")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={companyTypesDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e)=>{
                                                                                        dispatch(setFilterData({...filterData,['company_type']:e}))
                                                                                    }}
                                                                                    value={filterData && filterData.company_type?filterData.company_type:null}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="ChooseArea" className="form-input-sm-label">{t('ChooseArea')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseArea")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={areaDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e)=>{
                                                                                        dispatch(setFilterData({...filterData,['area']:e}))
                                                                                    }}
                                                                                    value={filterData && filterData.area?filterData.area:null}
                                                                                />
                                                                            </div>
                                                                        </ScrollArea>

                                                                        <div className="mb-2 mt-5 text-right">
                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {'label': 'Created Date', 'value': 'createdDate'},
                                                                                                {'label': 'Project Name', 'value': 'projectsName'},
                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e)=>{
                                                                                            dispatch(setSortStatus({...sortStatus,['columnAccessor']:e}))
                                                                                        }}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {'label': 'ASC', 'value': 'ASC'},
                                                                                                {'label': 'DESC', 'value': 'DESC'}
                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e) => {
                                                                                            dispatch(setSortStatus({...sortStatus,['direction']:e}))
                                                                                        }}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <HiOutlineSearch
                                                                                                className={'mr-2 '}
                                                                                                size={16}></HiOutlineSearch>
                                                                                        ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                                </Drawer>

                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={projectsIndexData}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (projectsIndexData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'projectsName',title: 'Project Name',sortable:true},
                                                                            {accessor: 'costCenterNumber',title: 'CCN'},
                                                                            {accessor: 'project_head',title: 'Project Head'},
                                                                            {accessor: 'projectsCategoryName',title: 'Company Type'},
                                                                            {accessor: 'project_type',title: 'Project Type'},
                                                                            {accessor: 'areasName',title: 'Area'},
                                                                            {accessor: 'address',title: 'Address'},
                                                                            {
                                                                                accessor: 'status',
                                                                                title: 'Status',
                                                                                textAlignment: 'center',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.status =='1' && <span className="items-center px-2 py-1 text-black text-xs bg-lime-300	w-20 block text-center">Active</span>}
                                                                                        {item.status =='0' && <span className="items-center px-2 py-1 text-black text-xs bg-rose-300	 w-20 block text-center">Delete</span>}
                                                                                    </>
                                                                            },

                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right" noWrap>
                                                                                        <ActionIcon color="green" onClick={(e) => {
                                                                                            navigate('/master-data/project-edit/'+item.id);
                                                                                        }}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>

                                                                                        {
                                                                                            item.status == 1 &&
                                                                                            <ActionIcon color="red">
                                                                                                <HiTrash size={16} onClick={(e) =>{
                                                                                                    openConfirmModal({
                                                                                                        title: t('ProjectDelete'),
                                                                                                        centered: true,
                                                                                                        children: (
                                                                                                            <Text size="sm">
                                                                                                                {t('AreYouSureProjectDelete')}
                                                                                                            </Text>
                                                                                                        ),
                                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                                        confirmProps: {color: 'red'},
                                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                                        onConfirm: () => {
                                                                                                            axios({
                                                                                                                method: 'get',
                                                                                                                url: `${process.env.REACT_APP_API_GATEWAY_URL}/project/active-inactive`,
                                                                                                                headers: {
                                                                                                                    "Accept": `application/json`,
                                                                                                                    "Content-Type": `application/json`,
                                                                                                                    "Access-Control-Allow-Origin": '*',
                                                                                                                    "Authorization": `Bearer ${token}`
                                                                                                                },
                                                                                                                params: {
                                                                                                                    id: item.id,
                                                                                                                    status : 0
                                                                                                                }
                                                                                                            })
                                                                                                                .then(res => {
                                                                                                                    if (res.status == 200) {
                                                                                                                        dispatch(setActiveTab('deleted'))
                                                                                                                    }
                                                                                                                })
                                                                                                                .catch(function (error) {
                                                                                                                    console.log(error)
                                                                                                                })
                                                                                                        }
                                                                                                        ,
                                                                                                    });
                                                                                                }}/>
                                                                                            </ActionIcon>
                                                                                        }


                                                                                        {
                                                                                            item.status == 0 &&
                                                                                            <ActionIcon color="red">
                                                                                                <HiOutlineRefresh size={16} onClick={(e) =>{
                                                                                                    openConfirmModal({
                                                                                                        title: t('restoreThisProject'),
                                                                                                        centered: true,
                                                                                                        children: (
                                                                                                            <Text size="sm">
                                                                                                                {t('AreYouSureProjectActive')}
                                                                                                            </Text>
                                                                                                        ),
                                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                                        confirmProps: {color: 'red'},
                                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                                        onConfirm: () => {
                                                                                                            axios({
                                                                                                                method: 'get',
                                                                                                                url: `${process.env.REACT_APP_API_GATEWAY_URL}/project/active-inactive`,
                                                                                                                headers: {
                                                                                                                    "Accept": `application/json`,
                                                                                                                    "Content-Type": `application/json`,
                                                                                                                    "Access-Control-Allow-Origin": '*',
                                                                                                                    "Authorization": `Bearer ${token}`
                                                                                                                },
                                                                                                                params: {
                                                                                                                    id: item.id,
                                                                                                                    status : 1
                                                                                                                }
                                                                                                            })
                                                                                                                .then(res => {
                                                                                                                    if (res.status == 200) {
                                                                                                                        dispatch(setActiveTab('all'))
                                                                                                                    }
                                                                                                                })
                                                                                                                .catch(function (error) {
                                                                                                                    console.log(error)
                                                                                                                })
                                                                                                        }
                                                                                                        ,
                                                                                                    });
                                                                                                }}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            dispatch(setFetching(true))
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={(e)=> dispatch(setSortStatus(e))}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Dashboard