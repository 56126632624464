import axios from "axios";


export const createPurchaseOrder = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/create`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.id
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const purchaseOrderOpenItem = async (params) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/open-item`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "project_id": params.project_id,
            "keyword": params.keyword
        }
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
        return data
};


export const purchaseOrderDetails = async (data) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/details`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/update/inline`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const purchaseOrderItemInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/po-item-update/inline`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderAttachInlineUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/update/inline/attach`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `multipart/form-data`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const purchaseOrderFinalUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/final/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderItemLastPriceDetails = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/item/last/price`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },

        params: {
            "po_item_id": param
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderIndex = async (param) => {

    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/list/${param.activeTab}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "offset": param.offset,
            "page" : param.page,
            "vendor" : param && param.vendor!= null ?param.vendor:'',
            "po" : param && param.po!= null ?param.po:'',
            "poRef" : param && param.poRef!= null ?param.poRef:'',
            "order_type": param.order_type,
            "order_by": param.order_by,
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData

};


export const purchaseOrderApprove = async (param) => {

    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/approve`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "po_id": param.po_id,
            "status" : param.status
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderApproveInfo = async (param) => {

    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/approve-info`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "po_id": param.po_id
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const purchaseOrderLastPriceInfo = async (param) => {

    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/last-lowest-price`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "po_id": param.po_id
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderCommentCreate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/comments`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderAdvancePaymentCreate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/advance-payment-added`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const purchaseOrderProcessUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/process/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderDropdown = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/dropdown/project-wise`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                if (res.data.data){
                    returnData =
                        res.data.data.map((type, index) => {
                            if (type.vendors_name && type.vendors_name !='' && type.vendors_name != null){
                                return ({
                                    'value': Number(type.id), 'label': 'POID: '+type.id+' (Vendor: '+type.vendors_name+') (Date Of Delivery: '+type.date_of_delivered+') (OrderQty: '+Number(type.total_order_item_quantity)+' )'
                                })
                            }
                            if (type.buyer_email && type.buyer_email !='' && type.buyer_email != null){
                                return ({
                                    'value': Number(type.id), 'label': 'POID: '+type.id+' (Buyer: '+(type.buyer_username?type.buyer_username:type.buyer_email)+') (Date Of Delivery: '+type.date_of_delivered+') (OrderQty: '+Number(type.total_order_item_quantity)+')'
                                })
                            }
                            if (type.buyer_email =='' || type.vendors_name == '' || type.vendors_name == null || type.buyer_email == null){
                                return ({
                                    'value': Number(type.id), 'label': 'POID: '+type.id+' (OrderQty: '+Number(type.total_order_item_quantity)+')'
                                })
                            }
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderDropdownPoIdWise = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/dropdown/id-wise`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                if (res.data.data){
                    returnData =
                        res.data.data.map((type, index) => {
                            return ({
                                'value': Number(type.id), 'label': '#PO-ID: '+type.id
                            })
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const POClaimItemSendBack = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/claim/send-back`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },

        params: {
            "item_id": param
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const PoItemDetailsAfterApproved = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/details/after-approve`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            "po_item_id": param
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const PoEditAfterApprove = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/after-approve/edit`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseRequisitionItemClose = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/open-item-close`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};



export const closeReceivedItem = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/received-item-close`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};

export const amendmentPo = async (data) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/amendment`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderAmendmentFinalUpdate = async (data) => {
    let returnData = [];
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/final/amendment-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const purchaseOrderAmendmentDeleteItem = async (data) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/amendment/item-delete`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};
export const purchaseOrderDelete = async (data) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/delete/${data}`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.message
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};
