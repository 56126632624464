import axios from "axios";

/*axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
    headers: {
        "Accept": `application/json`,
        "Content-Type": `application/json`,
        "Access-Control-Allow-Origin": '*',
        "Authorization": `Bearer ${token}`
    },
    params: {
        slug: typeSlug
    }
})
    .then(res => {
        if (res.data.status === 200) {
            setMasterDataDropdown(
                res.data.data.map((type, index) => {
                    return ({
                        'label': type.name, 'value': Number(type.id)
                    })
                })
            )
            setIsSlug(false)
        }
    })
    .catch(function (error) {
        console.log(error)
    })*/

export const getPrVehicleDropdown = async (params) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params: {
            slug: params
        }
    })
        .then(res => {
            if (res.data.status === 200) {
                res.data.data.map((type, index) => {
                    return ({
                        'label': type.name, 'value': Number(type.id)
                    })
                })
            }
        })
        .catch(function (error) {
            console.log(error)
        })
        return data
};