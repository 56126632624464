import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineUser,
    HiChevronLeft, HiEye, HiArrowCircleLeft, HiArrowCircleRight
} from "react-icons/hi";

import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {
    ScrollArea,
    Stepper,
    Button, Text, Loader,
} from '@mantine/core';
import {showNotification} from "@mantine/notifications";
import {useDispatch, useSelector} from "react-redux";
import {
    amendmentPoFinalUpdate,
    inlineUpdatePOItem, poAmendment,
    poFinalUpdate, poProcessUpdate, setEmptyPODetails, setFetching,
    setFinalUpdate, setPoItemInlineUpdate, setPoItemInlineUpdateID, setPoViewID, setPoViewModel,
    setSpinner
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import PoViewModel from "./PoViewModel";
import {openConfirmModal} from "@mantine/modals";
import PurchaseOrderAmendmentformation from "./include/PurchaseOrderAmendmentformation";

function UpdatePurchaseOrder() {
    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const [active, setActive] = useState(0);


    const processMode = useSelector((state) => state.purchaseOrderReducer.processMode)
    const purchaseOrderDetails = useSelector((state) => state.purchaseOrderReducer.purchaseOrderDetails)
    const finalUpdatePO = useSelector((state) => state.purchaseOrderReducer.finalUpdatePO)
    const spinner = useSelector((state) => state.purchaseOrderReducer.spinner)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)

    if (finalUpdatePO){
        showNotification({
            id: 'load-data',
            loading: true,
            title: (t('DataAddedSuccessfully')),
            message: (t('DataLoad')),
            autoClose: 1000,
            disallowClose: true,
            color: 'green',
        });
        dispatch(setFinalUpdate(false))
        dispatch(setSpinner(false))
        setTimeout(() => {
            navigate('/purchase-order');
        }, 700)
    }

    //Form validation
    const validationSchemaStep = Yup.object().shape({});
    const validationSchemaStepOne = Yup.object().shape({
        po_nonpo: Yup.string().required(t("ChoosePurchaseType")),
        ref_no: Yup.string().required(t("EnterRefNo")),
        date_of_delivered: Yup.string().required(t("ChooseDateOfDelivery")),
        payment_type: Yup.string().required(t("SelectPaymentType")),
        payment_from: Yup.string().required(t("ChoosePaymentFrom")),
        computation_status: Yup.string().required(t("ChooseCompetitionStatus")),
        vat_mode: Yup.string().required(t("ChooseVatMode")),
        payment_method: Yup.string().required(t("ChoosePaymentMethod")).nullable(),
        buyers: Yup.string().when("po_nonpo", (value) => {
            if (value == 10) {
                return Yup.string().required(t("ChooseBuyer")).nullable();
            }
        }),
        vendors: Yup.string().when("po_nonpo", (value) => {
            if (value != 10) {
                return Yup.string().required(t("ChooseVendor")).nullable();
            }
        }),
    },[
        ["buyers", "buyers", "vendors", "vendors"],
    ]);

    let formOptions = {resolver: yupResolver(validationSchemaStep)};
    if (active == 0) {
        formOptions = {resolver: yupResolver(validationSchemaStepOne)};
    }


    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;


    function formSubmit(data) {
        openConfirmModal({
            title: t('AreYouSureToUpdatePO'),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouUpdateThisPO')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'green'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                if (purchaseOrderDetails.sub_total==0 || purchaseOrderDetails.sub_total == '' || purchaseOrderDetails.sub_total== null){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('FillPoItemFieldProperly')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
                dispatch(setSpinner(true))
                const poData = {
                    po_type : processMode,
                    po_id : id
                }
                dispatch(amendmentPoFinalUpdate(poData))
            }
        });
    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('PurchaseOrder')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllPurchaseOrderRelatedInformationCanBeFoundHere')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={() => navigate(-1)}
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">

            <Stepper active={active} onStepClick={setActive} breakpoint="sm" color="green" size="sm">

                <Stepper.Step label={t("PurchaseOrder")} description={t('PurchaseOrderDetails')}>
                    <PurchaseOrderAmendmentformation
                        register={register}
                        setValue={setValue}
                        setError={setError}
                        errors={errors}
                        purchaseOrderId={id}
                    />
                </Stepper.Step>

                {
                    (processMode && processMode === 'Quotation') &&
                        <>
                    <Stepper.Step label={t("PoTypeInformation")} description={t('PoTypeDetails')}>
                        <h2>test {active}</h2>
                    </Stepper.Step>
                    </>
                }
            </Stepper>
                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className=" mt-3 mb-3">
                                                                <div className="float-left mb-3">
                                                                    {/*{
                                                                        purchaseOrderDetails.amendment_po &&
                                                                        <Button onClick={(e) => {
                                                                            dispatch(setPoViewModel(true))
                                                                            dispatch(setPoViewID(purchaseOrderDetails.amendment_po))
                                                                        }}
                                                                                className="px-6 py-2 ml-3 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border  rounded text-sm mr-2">
                                                                            <HiEye size={16} className={'mr-2'}/>
                                                                            <span> PO - {purchaseOrderDetails.amendment_po}</span></Button>
                                                                    }*/}

                                                                </div>
                                                                <div className="float-right mb-3">
                                                                    <Button
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                    <Button onClick={(e) => {
                                                                        dispatch(setPoViewModel(true))
                                                                        dispatch(setPoViewID(purchaseOrderDetails.purchase_order_id))
                                                                    }}
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border  rounded text-sm mr-2"><HiEye
                                                                        size={16}
                                                                        className={'mr-2'}/><span> {t('Preview')}</span></Button>

                                                                    <button type='submit'
                                                                            className="inline-flex mr-3 items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}/>
                                                                        ) : (processMode === 'Work-Order' ?
                                                                            <FiSave size={16} className={'mr-2'}/> :
                                                                            <HiArrowCircleRight size={16} className={'mr-2'}/>)}
                                                                        <span>{processMode === 'Work-Order' ? t('Submit') : t('Next')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {poViewModel?<PoViewModel />:''}

        </>
    )
}

export default UpdatePurchaseOrder