import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useState} from 'react'

import {FiX} from "react-icons/fi";
import {useTranslation} from "react-i18next";


function ErpLedgerView({data, setViewModel}) {
    // Language Init
    const { t, i18n } = useTranslation();

    //Add Model Init
    let [isOpen, setIsOpen] = useState(true);

    function closeModal() {
        setViewModel(false)
    }

    return (
        <>
            <Transition  show={true} as={Fragment} >
                <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {t('ERPLedgerAccountDetails')}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className={'pt-3 sm:border-t sm:border-gray-200 '}>

                                            <div className="px-4 sm:px-6 lg:px-8">

                                                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                    {t('ERPLedger')}:
                                                                </th>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {data.titles}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                     {t('LedgerCode')}:
                                                                </th>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {data.ledger_code}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                    {t('Parent')}:
                                                                </th>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {data.parent_title}
                                                                </td>
                                                            </tr>

                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>


                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                <label htmlFor="acceptTerms"
                                                       className="form-check-label">&nbsp;</label>
                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                    <button type='reset'
                                                            className={'px-4 py-2 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                            onClick={closeModal}><FiX size={16}
                                                                                      className={'mr-2'}></FiX>
                                                        <span>{t('Cancel')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default ErpLedgerView