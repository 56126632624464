import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, Select} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {projectDropdown, projectDropdownUserWise} from "../../../store/project/projectSlice";
import {
    createDeliveryTemp, setFetching,
    setIssueProjectModel,
    setItemIssueCustomerModel,
    setProjectID
} from "../../../store/issue/issueSlice";
import {showNotification} from "@mantine/notifications";

function IssueProjectModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const projectsDropdown = useSelector((state) => state.projectReducer.projectsUserWise)
    const projectID = useSelector((state) => state.issueReducer.projectID)

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(projectDropdownUserWise())
            setLoading(false)
        },500)
    },[loading])


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-6/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg text-center  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('Project')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setIssueProjectModel(false))
                                        dispatch(setProjectID(''))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2 relative">

                                            <LoadingOverlay
                                                loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                visible={loading}
                                                overlayBlur={2}
                                            />

                                            <Fragment>
                                                <div className="bg-gray-100" ref={pdfRef}>
                                                    <div className=" bg-white">
                                                        <div className="relative">


                                                            <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                    <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <table className=" table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                <tbody>

                                                                                <tr className={"bg-gray-100"} key="1">
                                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                        <Select
                                                                                            placeholder='Choose project'
                                                                                            searchable clearable allowDeselect
                                                                                            nothingFound="No options"
                                                                                            withAsterisk
                                                                                            data={projectsDropdown?projectsDropdown:[]}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            maxDropdownHeight={280}
                                                                                            onChange={(e) => {
                                                                                                dispatch(setProjectID(e))
                                                                                            }}
                                                                                            value={Number(projectID)}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>


                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-left mb-3">

                </div>

                <div className="float-right mb-3">
                    <button
                        onClick={(e) => {
                            dispatch(setIssueProjectModel(false))
                            dispatch(setProjectID(''))
                            dispatch(setFetching(true))
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Close
                    </button>

                    <button
                        onClick={(e) => {
                            if (projectID && projectID != ''){
                                dispatch(createDeliveryTemp({
                                    "project_id": projectID
                                }))
                                dispatch(setItemIssueCustomerModel(true))
                                dispatch(setIssueProjectModel(false))
                            }else {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('ChooseProject')),
                                    message: (t('DataLoad')),
                                    autoClose: 1000,
                                    disallowClose: true,
                                    color: 'red',
                                });
                            }
                        }}
                        className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
            </>
    )
}

export default IssueProjectModel