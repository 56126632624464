import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getPrVehicleDropdown} from "../../services/PurchaseRequisitionService";

export const PrVehicleDropdown = createAsyncThunk("purchase-requisition/vehicle-dropdown", async (param) => {
    try {
        const response = getPrVehicleDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});



const purchaseRequisitionSlice = createSlice({
    name : "purchase-requisition",
    initialState : {
        isLoading : false,
        opened : false,
        vehicleDropdown : [],
        totalItem : 0,
        fetching : true,
        spinner : true,
        page : localStorage.getItem('purchase_requisition_page') ? localStorage.getItem('purchase_requisition_page') : 1,
        activeTab : 'not-verify',
        sortStatus : { columnAccessor: 'created', direction: 'DESC' },
        filterData : {vendor:'',po:''},
        verifyMsg : null
    },
    reducers : {
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.po = action.payload.po;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },

    },
    extraReducers : (builder) => {
        builder.addCase(PrVehicleDropdown.fulfilled,(state,action) => {
            state.vehicleDropdown = action.payload.data
            // state.totalItem = action.payload.total
            // state.fetching = false
        })
    }
})

export const { setSpinner,setRefresh,setFetching,setPage,setFilterData,setSortStatus,setOpened,setActiveTab } = purchaseRequisitionSlice.actions
export default purchaseRequisitionSlice.reducer;