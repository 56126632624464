import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiCheck,
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, ScrollArea, TextInput, Select, Grid, Drawer} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import CompanyTypeModal from '../company-type/CompanyTypeModal';
import axios from "axios";
import SelectSearch from "react-select-search";
import 'react-select-search/style.css';
import View from "./ItemView";
import {showNotification, updateNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import {format} from "date-fns";
import {DatePicker} from "@mantine/dates";


function Item() {

    const {t, i18n} = useTranslation();
    const [isAddForm, setAddForm] = useState(true);
    const [listRefresh, setListRefresh] = useState(false);
    const token = localStorage.getItem('user_token');
    const [fetching, setFetching] = useState(true);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created_date', direction: 'DESC' });
    const [opened, setOpened] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

    const [itemFilterData, setItemFilterData] = useState({
        items_name: '',
        prices: '',
        item_unit_id: '',
        item_types: ''
    });


    // start get item type dropdown
    const [itemType, setItemType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item-type/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setItemType(res.data.data);
            }
        );
    }, []);

    let itemTypeDropdown =
        itemType.map((type, index) => {
            return ({
                'label': type.item_types, 'value': type.id
            })
        })
    // End get item type dropdown


    // start get item unit dropdown
    const [itemUnit, setItemUnit] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item-unit/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setItemUnit(res.data.data);
                }
            );
    }, []);

    let itemUnitDropdown =
        itemUnit.map((type, index) => {
            return ({
                'label': type.item_unit, 'value': type.id
            })
        })
    // End get item unit dropdown

    // Category Dropdown
    const [category, setCategory] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/category/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCategory(res.data.data);
            });
    }, []);

    let categoryDropdown =
        category.map((type, index) => {
            return ({
                'label': type.categories_name, 'value': Number(type.id)
            })
        })
    // Category Dropdown


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);


    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63);
    var fromHeight = height - 153 + "px";
    var drawerHeight = height - 20 + "px";

    const [items, setItems] = useState([]);
    const [totalItem, setTotalItem] = useState(0);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/item`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page,
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
                "item_types": itemFilterData.item_types,
                "item_unit": itemFilterData.item_unit_id,
                "items_name":itemFilterData.items_name,
                "prices":itemFilterData.prices
            }
        })
            .then(res => {
                setTimeout(()=>{
                    setItems(res.data.data);
                    setTotalItem(res.data.total);
                    localStorage.setItem('item_page', page);
                    setFetching(false);
                },2000)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [spinner, page, listRefresh,sortStatus,refresh]);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    // FOR DATA TABLE DATA SHOW END


    //Form validation
    /*let validationSchema = '';
    if (itemFilterData.prices || itemFilterData.item_types || itemFilterData.item_unit_id || itemFilterData.item_types){
         validationSchema = Yup.object().shape({});
        console.log('ok')

    }else{*/
         const validationSchema = Yup.object().shape({
            items_name: Yup.string().required(t("ItemNNameIsRequired")),
            // item_types: Yup.string().required(t("SelectItemType")),
            // item_unit_id: Yup.string().required(t("UnitIsRequired")),
            prices: Yup.string().required(t("PriceIsRequired"))
        });

    // }

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue} = useForm(formOptions);
    const {errors} = formState;


    // on change project head data get
    const [itemUnitData, setItemUnitData] = useState(null);
    const ItemUnitHandel = (e) => {
        setItemUnitData(e)
        setValue('item_unit_id',e);
    }

    // on change company type data get
    const [itemTypeData, setItemTypeData] = useState(null);
    const ItemTypeHandel = (e) => {
        setItemTypeData(e)
        setValue('item_types',e)
    }


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);

        const formData = {
            items_name: data.items_name,
            prices: data.prices,
            item_types: data.item_types,
            item_unit_id: data.item_unit_id,
            status: true
        };


        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/item/`,
            formData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('ItemAddedSuccesfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                }
            );
        }
    }


    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        items_name: '',
        prices: '',

    });

    const ItemEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 200) {
                        setEditInfo(res.data.data[0]);
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                }
            );
    }

    const handelInputUpdate = (e) => {
        e.persist();
        setEditInfo({...editInfo, [e.target.name]: e.target.value})
    }

    function formSubmitUpdate(data) {
        setSpinner(true);

        const updateFormData = {
            items_name: data.items_name,
            prices: data.prices,
            item_types: itemTypeData?itemTypeData:editInfo.item_type_id,
            item_unit_id: itemUnitData?itemUnitData:editInfo.item_unit_id,
            status: true
        };

        if (updateFormData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${editInfo.id}`,
                updateFormData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 202) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('ItemUpdatedSuccessfully')),
                                message: (t('DataLoad')),
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });
                            setFetching(true)
                            setSpinner(false);
                            reset();
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: res.data.message,
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });                    }
                    }
                );
        }
    }


    const ItemDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteItem')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${id}`,
                        {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                                if (res.status === 200) {
                                    showNotification({
                                        id: 'load-data',
                                        loading: true,
                                        title: (t('ItemDeletedSuccessfully')),
                                        message: (t('DataLoad')),
                                        autoClose: 500,
                                        disallowClose: true,
                                        color: 'red',
                                    });
                                    setListRefresh(true);
                                    setFetching(true)
                                }
                            }
                        )
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const ItemView = (e, id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 200) {
                        setViewData(res.data.data[0]);
                        setViewModel(true);
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 3000,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                }
            );
    }

    //Form Data Submit
    function filterSubmit(data) {
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created_date'},
            {'label': 'Item Name', 'value': 'items_name'},
            {'label': 'Price', 'value': 'prices'},
            {'label': 'Item Type', 'value': 'item_types'},
            {'label': 'Item Unit', 'value': 'item_unit'},
            {'label': 'End Date', 'value': 'budget_end_date'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})

    }

    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('Item')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('ItemRelatedInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            <button
                                                                onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12}
                                                                               className={'mr-1'}></HiOutlinePlus>{t('AddItem')}
                                                            </button>

                                                            <Link to='/master-data/project'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2 text-xs group relative text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title= {t('ItemFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    {/*<form onSubmit={handleSubmit(filterSubmit)}>*/}
                                                                        <ScrollArea style={{ height: drawerHeight }}>
                                                                            <div className="mb-3">
                                                                                <label htmlFor="items_name" className="form-input-sm-label">{t('ItemName')}</label>
                                                                                <TextInput
                                                                                    placeholder={t("EnterItemName")}
                                                                                    withAsterisk
                                                                                    value={itemFilterData.items_name?itemFilterData.items_name:''}
                                                                                    onChange={(e)=>{
                                                                                        setItemFilterData({...itemFilterData,['items_name']:e.target.value})
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-3">
                                                                                <label htmlFor="prices" className="form-input-sm-label">{t('Price')}</label>
                                                                                <TextInput
                                                                                    placeholder={t("EnterPrice")}
                                                                                    withAsterisk
                                                                                    value={itemFilterData.prices?itemFilterData.prices:''}
                                                                                    onChange={(e)=>{
                                                                                        setItemFilterData({...itemFilterData,['prices']:e.target.value})
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-3">
                                                                                <label htmlFor="item_unit_id" className="form-input-sm-label">{t('Unit')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseItemUnit")}
                                                                                    searchable clearable allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={itemUnitDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e)=>{
                                                                                        setItemFilterData({...itemFilterData,['item_unit_id']:e})
                                                                                    }}
                                                                                    value={itemFilterData.item_unit_id?itemFilterData.item_unit_id:''}
                                                                                />
                                                                            </div>


                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="item_types" className="form-input-sm-label">{t('ItemType')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseItemType")}
                                                                                    searchable clearable allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={itemTypeDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e)=>{
                                                                                        setItemFilterData({...itemFilterData,['item_types']:e})
                                                                                    }}
                                                                                    value={itemFilterData.item_types?itemFilterData.item_types:''}
                                                                                />
                                                                            </div>

                                                                        </ScrollArea>



                                                                        <div className="mb-2 mt-5 text-right">

                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderTypeDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderTypeDataHandel}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderByDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderByDataHandel}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button onClick={filterSubmit} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <HiOutlineSearch
                                                                                                className={'mr-2 animate-spin'}
                                                                                                size={16}></HiOutlineSearch>
                                                                                        ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    {/*</form>*/}
                                                                </Drawer>


                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={items}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (items.indexOf(item) + 1) + PAGE_SIZE * (page - 1)

                                                                            },
                                                                            {
                                                                                accessor: 'items_name',
                                                                                title: <Text mr="xs">{t('ItemName')}</Text>,
                                                                                sortable: true
                                                                            },

                                                                            {
                                                                                accessor: 'prices',
                                                                                title: <Text mr="xs">{t('Price')}</Text>,
                                                                                sortable: true
                                                                            },

                                                                            {
                                                                                accessor: 'item_types',
                                                                                title: <Text mr="xs">{t('ItemType')}</Text>,
                                                                                sortable: true
                                                                            },

                                                                            {
                                                                                accessor: 'item_unit',
                                                                                title: <Text mr="xs">{t('Unit')}</Text>,
                                                                                sortable: true
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text
                                                                                    mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => ItemEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => ItemView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => ItemDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="lg"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('ItemForm')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(false)}

                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                                            <ScrollArea style={{ height: fromHeight }}>
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>


        <div className="mb-3 w-full items-center">
            <label htmlFor="items_name" className="form-input-sm-label-required">{t('ItemName')}</label>

            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.items_name?.type === 'required' &&
                <div className="form-input-sm-error">
                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.items_name?.message}
                </div>}
                <TextInput
                    {...register("items_name")}
                    placeholder={t("EnterItemName")}
                    withAsterisk
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="prices" className="form-input-sm-label-required">{t('Price')}</label>

            <div
                className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.prices?.type === 'required' &&
                <div className="form-input-sm-error">
                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.prices?.message}
                </div>}

                <TextInput
                    {...register("prices")}
                    placeholder={t("EnterPrice")}
                    withAsterisk
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="item_unit_id" className="form-input-sm-label-required">{t('Category')}</label>
            <div className="relative mt-1 sm:col-span-20 sm:mt-0">
                {errors.item_unit_id?.type === 'required' &&
                <div className="form-input-sm-error">
                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.item_unit_id?.message}
                </div>}
                <Select
                    {...register("item_unit_id")}
                    placeholder='Choose Category'
                    searchable clearable allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={categoryDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={ItemUnitHandel}
                    value={itemUnitData}
                />
            </div>
        </div>

        <div className="mb-3 w-full items-center">
            <label htmlFor="item_unit_id" className="form-input-sm-label-required">{t('Unit')}</label>
            <div className="relative mt-1 sm:col-span-20 sm:mt-0">
                {errors.item_unit_id?.type === 'required' &&
                <div className="form-input-sm-error">
                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.item_unit_id?.message}
                </div>}
                    <Select
                        {...register("item_unit_id")}
                        placeholder={t("ChooseItemUnit")}
                        searchable clearable allowDeselect
                        nothingFound="No options"
                        withAsterisk
                        data={itemUnitDropdown}
                        transition="pop-top-left"
                        transitionDuration={80}
                        transitionTimingFunction="ease"
                        onChange={ItemUnitHandel}
                        value={itemUnitData}
                    />
            </div>
        </div>


        {/* Varieties Type Section */}
        <div className="mb-3 w-full items-center">
            <label htmlFor="item_types" className="form-input-sm-label-required">{t('ItemType')}</label>

            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.item_types?.type === 'required' &&
                <div className="form-input-sm-error">
                    <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.item_types?.message}
                </div>}

                    <Select
                        {...register("item_types")}
                        placeholder={t("ChooseItemType")}
                        searchable clearable allowDeselect
                        nothingFound="No options"
                        withAsterisk
                        data={itemTypeDropdown}
                        transition="pop-top-left"
                        transitionDuration={80}
                        transitionTimingFunction="ease"
                        onChange={ItemTypeHandel}
                        value={itemTypeData}
                    />
            </div>
        </div>


                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            // onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                            onClick={event => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}

                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateItemData')}</div>
                                                                                    <button
                                                                                        onClick={event => setEditForm(false)}

                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)} id="horizontal-form">
                                                                                <ScrollArea style={{ height: fromHeight }}>
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>


    {/* Varieties Name Section */}
    <div className="mb-3 w-full items-center">
        <label htmlFor="items_name" className="form-input-sm-label-required">{t('ItemName')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.items_name?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.items_name?.message}
            </div>}
            <div className="form-input-sm-right-icon">
                <FiAirplay size={16}></FiAirplay>
            </div>
            <input
                {...register("items_name")}
                type="text"
                className={`form-input-sm-control ${errors.projects_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                name="items_name"
                id='items_name'
                value={editInfo.items_name}
                onChange={handelInputUpdate}
                placeholder={t("EnterItemName")}
            />
        </div>
    </div>

    {/* Price Section */}
    <div className="mb-3 w-full items-center">
        <label htmlFor="prices" className="form-input-sm-label-required">{t('Price')}</label>

        <div
            className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.prices?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.prices?.message}
            </div>}
            <div className="form-input-sm-right-icon">
                <FiAirplay size={16}></FiAirplay>
            </div>
            <input
                {...register("prices")}
                type="text"
                className={`form-input-sm-control ${errors.prices ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                name="prices"
                id='prices'
                value={editInfo.prices}
                onChange={handelInputUpdate}
                placeholder={t("EnterPrice")}
            />
        </div>
    </div>

    {/* Unit Section */}
    <div className="mb-3 w-full items-center">
        <label htmlFor="item_unit_id" className="form-input-sm-label-required">{t('Unit')}</label>

        <div className="relative mt-1 sm:col-span-20 sm:mt-0">
            {errors.item_unit_id?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.item_unit_id?.message}
            </div>}
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <SelectSearch
                    name="item_unit_id"
                    id="item_unit_id"
                    onChange={ItemUnitHandel}
                    search="true"
                    value={itemUnitData?itemUnitData:editInfo.item_unit_id}
                    options={itemUnitDropdown}
                    placeholder={t("ChooseItemUnit")}/>
                <CompanyTypeModal></CompanyTypeModal>
            </div>
        </div>
    </div>


    {/* Varieties Type Section */}
    <div className="mb-3 w-full items-center">
        <label htmlFor="item_types" className="form-input-sm-label-required">{t('ItemType')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.item_types?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.item_types?.message}
            </div>}

            <div className="relative flex flex-grow items-stretch focus-within:z-10">

                <SelectSearch
                    name="item_types"
                    onChange={ItemTypeHandel}
                    value={itemTypeData?itemTypeData:editInfo.item_type_id}
                    options={itemTypeDropdown}
                    search={true}
                    placeholder={t("ChooseItemType")}/>
                <CompanyTypeModal></CompanyTypeModal>
            </div>
        </div>
    </div>


                                                                                </div>
                                                                                </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            // onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                            // onClick={event => addFormControl}

                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}

export default Item