import {Dialog, Switch, Transition} from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import {
    HiDocumentText,
    HiOutlineDeviceMobile,
    HiOutlineKey,
    HiOutlineMail,
    HiChevronDown,
    HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlinePlus,
    HiOutlineChartSquareBar,
    HiOutlinePhone,
    HiOutlineUserAdd,
    HiOutlineHome
} from "react-icons/hi";
import {FiAirplay, FiEye, FiEyeOff, FiSave, FiX} from "react-icons/fi";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {TbCurrencyTaka} from "react-icons/tb";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";



function CompanyTypeModal() {

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const { t, i18n } = useTranslation();

    const validationSchema = Yup.object().shape({
      projectCategoryName: Yup.string()
            .required(t('CompanyTypeIsRequired')),
        

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const { isSubmitting } = formState;

    function onSubmit(data) {
        // display form data on success
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        return false;
    }
  return (
    <>

<button  type="button" onClick={openModal} className="relative -ml-px inline-flex items-center space-x-2 border border-gray-300 bg-gray-50 px-4 text-sm font-medium text-indigo-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500">
       <HiOutlinePlus  size={12} ></HiOutlinePlus>
      </button>

      <Transition  show={isOpen} as={Fragment} >
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                      {t('AddCompanyType')}
                  </Dialog.Title>
                  <div className="mt-2">
                      <form onSubmit={handleSubmit(onSubmit)} id="horizontal-form">
                          <div className={'pt-3 sm:border-t sm:border-gray-200 '}>

                            {/* Company Type Section */}
                              <div className="mb-3 xl:w-96">
                                  <label htmlFor="projectCategoryName" className="inline-flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{t('CompanyType')}
                                      <span className="text-red-500 flex mt-1 ml-1">*</span></label>
                                 
                                 <div className="relative">
                                  <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                  {errors.projectCategoryName?.type === 'required' && <div
                                      className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                      <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.projectCategoryName?.message}
                                  </div>}
                                 
                                  <input
                                      {...register("projectCategoryName")}
                                      type="text"
                                      className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.projectCategoryName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                      name="projectCategoryName" id ="projectCategoryName"
                                      placeholder={t("EnterCompanyType")}
                                  />
                                  </div>
                              
                                  <br />
                                  <div class="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">{t("Status")}</label>
                                        </div>
                              
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-2 sm:pt-5">
                                  <label htmlFor="acceptTerms" className="form-check-label">&nbsp;</label>
                                  <div className="relative mt-1 sm:col-span-2 sm:mt-0 flex space-x-2 ml-8">

                                      <button type='submit'
                                              className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                          {isSubmitting ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'}
                                                                                      size={16}></AiOutlineLoading3Quarters>
                                          ) : (<FiSave size={16} className={'mr-2'}></FiSave>)}
                                          <span>{t('Save')}</span>
                                      </button>
                                      <button type='reset'
                                              className={'px-6 py-2 text-gray-400 bg-gray-200 inline-flex transition duration-150 ease-in-out  bg-gray-25 hover:bg-gray-50 items-center text-sm'}
                                              onClick={closeModal}><FiX size={16} className={'mr-2'}></FiX>
                                          <span>{t('Cancel')}</span>
                                      </button>
                                  </div>
                              </div>
                              </div>
                              </div>
                          </div>
                      </form>
                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CompanyTypeModal