import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, ScrollArea, TextInput, Grid, Select, Drawer} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ProjectTypeView from "./ProjectTypeView";
import {showNotification, updateNotification} from "@mantine/notifications";

function ProjectType() {

    const {t, i18n} = useTranslation();
    const token = localStorage.getItem('user_token');


    const [isAddForm, setAddForm] = useState(true);

    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }


    // FOR DATA TABLE START
        const PAGE_SIZE = 50;
        const [page, setPage] = useState(localStorage.getItem('project_type_page') ? localStorage.getItem('project_type_page') : 1);
        const innerContainer = useRef(100);
        const height = innerContainer.current.clientHeight;
        var tableHeight = (height - 63);
        var fromHeight = height - 153 + "px";
        var drawerHeight = height - 20 + "px";


        const [projectType, setProjectType] = useState([]);
        const [totalItem, setTotalItem] = useState(0);
        const [fetching, setFetching] = useState(true);
        const [opened, setOpened] = useState(false);
        const [refresh, setRefresh] = useState(false);
        const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created_date', direction: 'DESC' });
        const [filterData, setFilterData] = useState({
            title: ''
        });

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created_date'},
            {'label': 'Project Type', 'value': 'title'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})

    }


    useEffect(() => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/pro-type`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {
                    "offset": PAGE_SIZE,
                    "page" : page,
                    "title" : filterData.title ? filterData.title : null,
                    "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                    "order_by": sortStatus.direction?sortStatus.direction:'',                }
            })
                .then(res => {
                    setTimeout(()=>{
                        setProjectType(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('project_type_page', page);
                        setFetching(false);
                        setRefresh(false)
                    },2000)

                })
                .catch(function (error) {
                    console.log(error)
                })
        }, [spinner,page,listRefresh,sortStatus]);

    // FOR DATA TABLE END

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    function filterSubmit(data) {
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    //Form validation
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("ProjectTypeIsRequired"))
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState,setValue } = useForm(formOptions);
    const { errors } = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            title: data.title,
            created_by: localStorage.getItem('username'),
            status: true
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: t('DataIsLoading'),
                        message: t('ProjectTypeAddedSuccessfully'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                        style: { alignItems: 'top' }
                    });
                    setSpinner(false);
                    reset();
                    setFetching(true);
                } else {
                    toast.error(res.data.message);
                }
            });
        }
    }



    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        title: ''
    });
    const ProjectTypeEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setEditInfo(res.data.data[0]);
                setValue('title',res.data.data[0].title)
            } else {
                toast.error(res.data.message);
            }
        });
    }

    function formSubmitUpdate(data) {
        setSpinner(true);

        const formData = {
            title: data.title,
            status: true
        };

        if (formData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/${editInfo.id}`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 202) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: t('DataIsLoading'),
                        message: t('ProjectTypeUpdatedSuccessfully'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                        style: { alignItems: 'top' }
                    });
                    setSpinner(false);
                    reset();
                    setFetching(true);
                } else {
                    toast.error(res.data.message);
                }
            });
        }
    }


    const ProjectTypeDelete = (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: (t('AreYouSure')),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: (t('YesDeleteIt'))
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                    if (res.status === 200) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: t('DataIsLoading'),
                            message: t('ProjectTypeDeletedSuccessfully'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'green',
                            style: { alignItems: 'top' }
                        });
                        setListRefresh(true);
                        setFetching(true);
                    }
                });
            }
        })
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const ProjectView = (e,id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setViewData(res.data.data[0]);
                setViewModel(true);
            } else {
                toast.error(res.data.message);
            }
        });
    }

    return (
        <>
            {viewModel?<ProjectTypeView data={viewData} setViewModel={setViewModel}/>:''}

            <main className="flex flex-1 flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('ProjectType')}
                                                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('ProjectsAndItsTypes')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            <button
                                                                onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddProjectType')}
                                                            </button>

                                                         
                                                            <Link to='/master-data/project-type'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title= {t('ItemFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <ScrollArea style={{ height: drawerHeight }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="project_type" className="form-input-sm-label">{t('ProjectType')}</label>
                                                                            <TextInput
                                                                                placeholder={t("EnterProjectType")}
                                                                                withAsterisk
                                                                                value={filterData.title?filterData.title:''}
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['title']:e.target.value})
                                                                                }}
                                                                            />
                                                                        </div>

                                                                    </ScrollArea>



                                                                    <div className="mb-2 mt-5 text-right">

                                                                        <Grid>
                                                                            <Grid.Col span={6}>
                                                                                <Select
                                                                                    {...register("order_type")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderTypeDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderTypeDataHandel}
                                                                                    value={sortStatus.columnAccessor}
                                                                                />

                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <Select
                                                                                    {...register("order_by")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderByDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderByDataHandel}
                                                                                    value={sortStatus.direction}
                                                                                />
                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <button onClick={filterSubmit} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                    {spinner ? (
                                                                                        <HiOutlineSearch
                                                                                            className={'mr-2 animate-spin'}
                                                                                            size={16}></HiOutlineSearch>
                                                                                    ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                    <span>{t('Filter')}</span>
                                                                                </button>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </div>
                                                                    {/*</form>*/}
                                                                </Drawer>

                                                                <Box sx={{ height: tableHeight }}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={projectType}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (projectType.indexOf(item) + 1) + PAGE_SIZE * (page - 1)                                                                                          },
                                                                            {accessor: 'title',
                                                                            
                                                                                 title: <Text mr="xs">{t('Title')}</Text>, sortable:true
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => ProjectTypeEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => ProjectView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => {ProjectTypeDelete(e, item.id)}}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="lg"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all transition-shadow transition-transform	">
                                                    <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                        <div className="h-full ">
                                                            <section className="w-96 ml-3">
                                                                <div className="w-full mr-0 mx-auto border">
                                                                    <header className="relative mb-1 bg-indigo-600">
                                                                        <div
                                                                            className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                            <div
                                                                                className="text-center flex justify-between">
                                                                                <div className="text-white   text-sm font-bold items-center leading-7">  {t('ProjectTypeForm')}</div>
                                                                                <button onClick={event => setAddForm(false)}
                                                                                    className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                    type="button">
                                                                                    <FiX></FiX>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </header>
                                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                                        <div className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} >
                                                                            <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                            {/* Project Type Section*/}
                                                                            <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="title" className="form-input-sm-label-required">{t('ProjectType')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.title?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.title?.message}
                                                                                        </div>}
                                                                                        <div
                                                                                            className="form-input-sm-right-icon">
                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                        </div>
                                                                                        <input
                                                                                            {...register("title")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.title ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="title" id = "title"
                                                                                            placeholder={t("EnterProjectType")}
                                                                                        />
                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <footer className="relative mt-1">
                                                                            <div className="container mx-auto">
                                                                                <div
                                                                                    className="text-right border-t pt-0.5 mb-0.5">
                                                                                    <button onClick={event => setAddForm(false)} type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                            ><FiX
                                                                                        size={16}
                                                                                        className={'mr-2'}></FiX>
                                                                                        <span>{t('Cancel')}</span>
                                                                                    </button>
                                                                                    <button type='submit'
                                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <AiOutlineLoading3Quarters
                                                                                                className={'mr-2 animate-spin'}
                                                                                                size={16}></AiOutlineLoading3Quarters>
                                                                                        ) : (<FiSave size={16}
                                                                                                     className={'mr-2'}></FiSave>)}
                                                                                        <span>{t('Save')}</span>
                                                                                    </button>

                                                                                </div>
                                                                            </div>
                                                                        </footer>

                                                                    </form>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </aside> : ''}


                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all transition-shadow transition-transform	">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div className="text-white   text-sm font-bold items-center leading-7">  {t('ProjectTypeForm')}</div>
                                                                                    <button onClick={event => setAddForm(false)}
                                                                                            className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                            type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)} id="horizontal-form" >
                                                                            <div className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} >
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    {/* Project Type Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="title" className="form-input-sm-label-required">{t('ProjectType')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.title?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.title?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("title")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.title ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="title" id = "title"
                                                                                                placeholder={t("EnterProjectType")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button onClick={event => setAddForm(false)} type='reset'
                                                                                                className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}
export default ProjectType