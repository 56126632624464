import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, Select, ScrollArea, TextInput, Grid, Drawer} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import View from "./CostHeaderView";

import {openConfirmModal} from '@mantine/modals';
import {showNotification} from '@mantine/notifications';
import toast from "react-hot-toast";


function CostHeader() {

    const {t, i18n} = useTranslation();
    const token = localStorage.getItem('user_token');
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

    const [fetching, setFetching] = useState(true);
    const [opened, setOpened] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created_date', direction: 'DESC' });



    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('cost_header_page') ? localStorage.getItem('cost_header_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63);
    var fromHeight = height - 153 + "px";
    var drawerHeight = height - 20 + "px";

    const [filterData, setFilterData] = useState({
        titles: '',
        sub_categories: ''
    });

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created_date'},
            {'label': 'titles', 'value': 'titles'},
            {'label': 'Sub Category', 'value': 'sub_categories'}

        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})

    }

    const [costHeaders, setCostHeader] = useState([]);
    const [totalItem, setTotalItem] = useState(0);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/cost-header`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page,
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
                "titles" : filterData.titles ? filterData.titles:'',
                "sub_categories" : filterData.sub_categories ? filterData.sub_categories:'',
            }
        })
            .then(res => {
                setTimeout(()=>{
                    if (res.data.status === 200){
                        setCostHeader(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('cost_header_page', page);
                        setFetching(false)
                    }else {
                        toast.error(res.data.message);
                    }
                },2000)

            })
            .catch(function (error) {
                console.log(error)
            })

    }, [spinner, page, listRefresh,refresh,sortStatus]);
    // FOR DATA TABLE DATA SHOW END

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    function filterSubmit(data) {
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    // start get category dropdown
    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setSubCategoryDropdown(res.data.data);
        });
    }, []);

    let subCategoryDropdownValue =
        subCategoryDropdown.map((category, index) => {
            return ({
                'label': category.sub_categories_name, 'value': category.id
            })
        })
    // on change company type data get
    const [subCategory, setSubCategory] = useState(null);
    const subCategoryHandel = (e) => {
        setValue('sub_categories',e)
        setSubCategory(e)
    }
    // End get category dropdown

    //Form validation
    const validationSchema = Yup.object().shape({
        titles: Yup.string().required(t("TitleNameIsRequired")),
        sub_categories: Yup.string().required(t('CostHeaderCodeIsRequired')).nullable()
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            titles: data.titles,
            sub_categories: data.sub_categories,
            created_by: localStorage.getItem('username'),
            status: 1
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/cost-header/`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('CostHeaderAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout(()=>{
                        reset();
                        setValue('sub_categories',null)
                        setSubCategory(null)
                        setSpinner(false);
                        setFetching(true)
                    },1000)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
        }
    }

    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        sub_categories: '',
        titles: '',
    });

    const CostHeaderEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/cost-header/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setValue('sub_categories',res.data.data.sub_categories);
                setValue('titles',res.data.data.titles);
                setEditInfo(res.data.data);
            } else {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'green',
                });
            }
        });
    }

    function formSubmitUpdate(data) {
        setSpinner(true);
        const formData = {
            sub_categories: data.sub_categories,
            titles: data.titles,
            status: true
        };

        if (formData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/cost-header/${editInfo.id}`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 202) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('CostHeaderUpdatedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout(()=>{
                        setValue('sub_categories',null)
                        setSubCategory(null)
                        reset();
                        setSpinner(false);
                        setFetching(true)
                    },1000)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
        }
    }


    const CostHeaderDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteCostHeader')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/cost-header/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                        if (res.status === 200) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('DataDeleteSuccessfully')),
                                message: (t('DataLoad')),
                                autoClose: 1000,
                                disallowClose: true,
                                color: 'red',
                            });
                            setTimeout(()=>{
                                setFetching(true)
                                setListRefresh(true);
                            },1000)
                        }
                    })
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const CostHeaderView = (e, id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/cost-header/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setViewData(res.data.data[0]);
                setViewModel(true);
            } else {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'green',
                });
            }
        });
    }

    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="flex flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('CostHeader')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('CostHeaderInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            <button onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12}
                                                                               className={'mr-1'}></HiOutlinePlus>{t('AddCostHeader')}
                                                            </button>


                                                            <Link to='/master-data/cost-header'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title= {t('CostHeaderFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <ScrollArea style={{ height: drawerHeight }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="titles" className="form-input-sm-label">{t('Title')}</label>
                                                                            <TextInput
                                                                                placeholder={t("EnterTitle")}
                                                                                withAsterisk
                                                                                value={filterData.titles?filterData.titles:''}
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['titles']:e.target.value})
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label htmlFor="proType" className="form-input-sm-label">{t('SubCategory')}</label>

                                                                            <Select
                                                                                placeholder={t('ChooseSubCategory')}
                                                                                searchable clearable allowDeselect
                                                                                nothingFound="No options"
                                                                                withAsterisk
                                                                                data={subCategoryDropdownValue}
                                                                                transition="pop-top-left"
                                                                                transitionDuration={80}
                                                                                transitionTimingFunction="ease"
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['sub_categories']:e})
                                                                                }}
                                                                                value={filterData.sub_categories?filterData.sub_categories:''}
                                                                            />
                                                                        </div>
                                                                    </ScrollArea>

                                                                    <div className="mb-2 mt-5 text-right">
                                                                        <Grid>
                                                                            <Grid.Col span={6}>
                                                                                <Select
                                                                                    {...register("order_type")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderTypeDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderTypeDataHandel}
                                                                                    value={sortStatus.columnAccessor}
                                                                                />
                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <Select
                                                                                    {...register("order_by")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderByDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderByDataHandel}
                                                                                    value={sortStatus.direction}
                                                                                />
                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <button onClick={filterSubmit} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                    {spinner ? (
                                                                                        <HiOutlineSearch
                                                                                            className={'mr-2 animate-spin'}
                                                                                            size={16}></HiOutlineSearch>
                                                                                    ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                    <span>{t('Filter')}</span>
                                                                                </button>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </div>
                                                                </Drawer>

                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={costHeaders}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (costHeaders.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'titles', 
                                                                                 title: <Text mr="xs">{t('CostHeader')}</Text>,
                                                                                sortable:true
                                                                            },
                                                                            {
                                                                                accessor: 'sub_categories_name',
                                                                                title: <Text mr="xs">{t('SubCategory')}</Text>        ,
                                                                                sortable:true
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => CostHeaderEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => CostHeaderView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => CostHeaderDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="lg"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('CostHeaderForm')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                                            <ScrollArea style={{ height: fromHeight }}>

                                                                            <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="titles" className="form-input-sm-label-required">{t('Title')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.titles?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.titles?.message}
                                                                                            </div>}
                                                                                            <div className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("titles")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.titles ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterTitle")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="sub_categories" className="form-input-sm-label-required">{t('SubCategory')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.sub_categories &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.sub_categories?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("sub_categories")}
                                                                                                placeholder={t("ChooseSubCategory")}
                                                                                                searchable clearable allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={subCategoryDropdownValue}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={subCategoryHandel}
                                                                                                value={subCategory}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div className="text-center flex justify-between">
                                                                                    <div className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateCostHeader')}</div>
                                                                                    <button onClick={event => setEditForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)} id="horizontal-form">
                                                                            <ScrollArea style={{ height: fromHeight }}>

                                                                            <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="titles" className="form-input-sm-label-required">{t('Title')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.titles?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.titles?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("titles")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.titles ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="titles"
                                                                                                id="titles"
                                                                                                placeholder={t("EnterTitle")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="sub_categories" className="form-input-sm-label-required">{t('SubCategory')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.sub_categories?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.sub_categories?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("sub_categories")}
                                                                                                placeholder={t("ChooseSubCategory")}
                                                                                                searchable clearable allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={subCategoryDropdownValue}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={subCategoryHandel}
                                                                                                value={subCategory ? subCategory : editInfo.sub_categories}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button onClick={event => setEditForm(false)} type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default CostHeader