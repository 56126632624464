import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { LoadingOverlay, ScrollArea} from "@mantine/core";
import {HiOutlineX} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";

import {
    getStockUpdateData, setStockItemUpdateId,
    setStockItemUpdateModel, setStockUpdateComplete,
    setStockUpdateDataEmpty, stockItemUpdate, setFetching
} from "../../../store/inventory/inventorySlice";
import {showNotification} from "@mantine/notifications";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

function StockItemUpdateModel(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_BUDGET_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_ONE";
    })

    const stockItemUpdateId = useSelector((state) => state.inventoryReducer.stockItemUpdateId)
    const stockUpdateData = useSelector((state) => state.inventoryReducer.stockUpdateData)


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(getStockUpdateData({
                id : stockItemUpdateId
            }))
            setLoading(false)
        },500)
    },[loading])

    const validationSchemaStep = Yup.object().shape({});
    let formOptions = {resolver: yupResolver(validationSchemaStep)};

    // get functions to build form with useForm() hook
    const {handleSubmit, formState, setValue, setError,register} = useForm(formOptions);

    //Form Data Submit
    function formSubmit(data) {
        data['id'] = stockItemUpdateId
        dispatch(stockItemUpdate(data))
        dispatch(setStockUpdateComplete(true))
        showNotification({
            id: 'load-data',
            loading: true,
            title: (t('DataAddedSuccessfully')),
            message: (t('DataLoad')),
            autoClose: 1000,
            disallowClose: true,
            color: 'green',
        });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('StockItemUpdate')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setStockUpdateDataEmpty())
                                        // dispatch(setFetching(true))
                                        dispatch(setStockItemUpdateModel(false))
                                        dispatch(setStockItemUpdateId(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2" >
                                            <form onSubmit={handleSubmit(formSubmit)}>
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                    <>

                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">

                    <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                        <div className="mt-5 md:col-span-5 md:mt-0">
                            <div className="shadow      sm:rounded">
                                <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                        <div className=" w-1/3 items-center">
                                            <label htmlFor="rack_number" className="form-input-sm-label">{t('RackNumber')}</label>
                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                <input
                                                    {...register("rack_number")}
                                                    type="text"
                                                    className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                    placeholder={t("EnterRackNumber")}
                                                    defaultValue={stockUpdateData && stockUpdateData.rack_number ? stockUpdateData.rack_number : null}
                                                />
                                            </div>
                                        </div>
                                        <div className=" w-1/3 items-center">
                                            <label htmlFor="stock_book_number" className="form-input-sm-label">{t('StockBookNo')}</label>
                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                <input
                                                    {...register("stock_book_number")}
                                                    type="text"
                                                    className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                    placeholder={t("EnterStockBookNo")}
                                                    defaultValue={stockUpdateData && stockUpdateData.stock_book_number ? stockUpdateData.stock_book_number : null}
                                                />
                                            </div>
                                        </div>
                                        <div className=" w-1/3 items-center">
                                            <label htmlFor="register_page_number" className="form-input-sm-label">{t('StockRegisterPageNo')}</label>
                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                <input
                                                    {...register("register_page_number")}
                                                    type="text"
                                                    className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                    placeholder={t("EnterStockRegisterPageNo")}
                                                    defaultValue={stockUpdateData && stockUpdateData.register_page_number ? stockUpdateData.register_page_number : null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    </>

                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-left mb-3">

                </div>

                <div className="float-right mb-3">
                    <button className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </footer>
                                        </form>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

        </>
    )
}

export default StockItemUpdateModel