import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createPaymentPayFrom,
    createPaymentVerifyFrom, getPaymentBankCheque,
    getPaymentRequestList,
    paymentConfirmationForArchive,
    paymentSendBack, postPaymentConfirmAttachment, postPaymentUpdate,
    storePaymentVerify,
    updatePaymentInline
} from "../../services/PaymentService";
import {
    createDeliveryTempItemsTemp,
    getDeliveryTempDetails,
    postInlineUpdateDeliveryTemp
} from "../../services/DeliveryIssuedService";
import {
    getItemExistsInProjectByCreated,
    getItemWiseCategorySubCategory,
    getProjectWiseItemDropdown
} from "../../services/ItemsService";


export const getCategorySubCategory = createAsyncThunk("get/item-wise/category-sub-category", async (param) => {
    try {
        const response = getItemWiseCategorySubCategory(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getItemDropdownProjectWise = createAsyncThunk("get/project-wise/item-dropdown", async (param) => {
    try {
        const response = getProjectWiseItemDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getItemExists = createAsyncThunk("get/item-exists-in-project", async (param) => {
    try {
        const response = getItemExistsInProjectByCreated(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const itemsSlice = createSlice({
    name : "Items",
    initialState : {
        loading : false,
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('payment_page') ? localStorage.getItem('payment_page') : 1,
        activeTab : 'payment-request',
        sortStatus : { columnAccessor: 'payment_request_date', direction: 'DESC' },
        opened : false,
        refresh : false,
        filterData : {po_id:'',po:'',vendor:'',buyer:'',start_date:'',end_date:''},
        itemCategorySubCat : [],
        projectWiseItemDropdown : [],
        itemExists : []
    },
    reducers : {
        setLoading : (state,action) => {
            state.loading = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.po_id = action.payload.po_id;
            state.filterData.po = action.payload.po;
            state.filterData.vendor = action.payload.vendor;
            state.filterData.buyer = action.payload.buyer;
            state.filterData.start_date = action.payload.start_date;
            state.filterData.end_date = action.payload.end_date;
        },
        setItemCategorySubCat : (state,action) => {
            state.itemCategorySubCat = []
        }
    },

    extraReducers : (builder) => {

        builder.addCase(getCategorySubCategory.fulfilled,(state,action)=>{
            state.itemCategorySubCat = action.payload
        })

        builder.addCase(getItemDropdownProjectWise.fulfilled,(state,action)=>{
            state.projectWiseItemDropdown = action.payload
        })

        builder.addCase(getItemExists.fulfilled,(state,action)=>{
            state.itemExists = action.payload
        })

    }
})

export const { setProjectData,setLoading,setPage,setSortStatus,setOpened,setFetching,setRefresh,setFilterData,setActiveTab,setItemCategorySubCat } = itemsSlice.actions
export default itemsSlice.reducer;