import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {buyerDropdownService, getIssueCustomerService, getUserEnableDisable} from "../../services/UserService";


export const buyerDropdown = createAsyncThunk("buyer/dropdown", async () => {
    try {
        const response = buyerDropdownService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getIssueCustomer = createAsyncThunk("issue/customer", async () => {
    try {
        const response = getIssueCustomerService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const UserEnableDisable = createAsyncThunk("user/enable-disable", async (data) => {
    try {
        const response = getUserEnableDisable(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
const userSlice = createSlice({
    name : "vendor",
    initialState : {
        isLoading : true,
        message : null,
        buyerDropdownData : [],
        issueCustomer : [],
        userViewModel : false,
        userViewID : null,
        enableMessage : null
    },
    reducers : {
        setUserViewModel : (state,action) => {
            state.userViewModel = action.payload
        },
        setUserViewID : (state,action) => {
            state.userViewID = action.payload
        }
    },

    extraReducers : (builder) => {
        builder.addCase(buyerDropdown.fulfilled, (state, action) => {
            state.buyerDropdownData = action.payload
            state.message = 'buyerDropdownLoad'
            state.isLoading = false
        })

        builder.addCase(getIssueCustomer.fulfilled,(state,action)=>{
            state.issueCustomer = action.payload
        })

        builder.addCase(UserEnableDisable.fulfilled,(state,action)=>{
            state.enableMessage = action.payload
        })
    }
})

export const { setUserViewID,setUserViewModel } = userSlice.actions

export default userSlice.reducer;