import axios from "axios";


export const getInventoryStockItem = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/stock-item`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getInventoryIssueItem = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/issue-item`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getIssueReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/issue-item-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};



export const getInventoryValuationReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/valuation-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getWeightedAveragePriceReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/weighted-average-price-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getStockUpdate = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/stock-item/for-update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const stockItemUpdateData = async (data) => {
    let returnData = [];
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/inventory/stock-item/update`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        data : data
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};
