import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineUser,HiChevronLeft, HiEye, HiArrowCircleLeft, HiArrowCircleRight
} from "react-icons/hi";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {ScrollArea, Stepper, Button,} from '@mantine/core';
import {showNotification} from "@mantine/notifications";
import OpenItemInformation from "./include/OpenItemInformation";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewPurchaseOrder,
    setCheckOpenItemEnpty,
    setCreatePoId,
    setOpenItemData,
    setProjectData
} from "../../../store/purchaseOrder/purchaseOrderSlice";

function CreatePurchaseOrder() {
    const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [openItemCheckData, setOpenItemCheckData] = useState([]);
    const [recall, setRecall] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const [spinner, setSpinner] = useState(false);

    const validationSchemaStep = Yup.object().shape({});
    const validationSchemaStepOne = Yup.object().shape({
        po_nonpo: Yup.string().required(t("ChoosePurchaseType")),
        issue_date: Yup.string().required(t("ChooseIssueDate")),
        ref_no: Yup.string().required(t("EnterRefNo")),
        date_of_delivered: Yup.string().required(t("ChooseDateOfDelivery")),
        payment_type: Yup.string().required(t("SelectPaymentType")),
        payment_from: Yup.string().required(t("ChoosePaymentFrom")),
        competition_status: Yup.string().required(t("ChooseCompetitionStatus")),
        vendor: Yup.string().required(t("ChooseVendor")).nullable(),
    });

    let formOptions = {resolver: yupResolver(validationSchemaStep)};
    if (active == 1) {
        formOptions = {resolver: yupResolver(validationSchemaStepOne)};
    }

    // get functions to build form with useForm() hook
    const {handleSubmit, formState, setValue, setError} = useForm(formOptions);

    const checkOpenItem = useSelector((state) => state.purchaseOrderReducer.checkOpenItem)
    const createdPoId = useSelector((state) => state.purchaseOrderReducer.createdPoId)

    //Form Data Submit
    function formSubmit(data) {
        if (openItemCheckData && Object.keys(openItemCheckData).length > 0) {
            const formData = {
                itemInfo: checkOpenItem
            };
            dispatch(createNewPurchaseOrder(formData))
            dispatch(setCheckOpenItemEnpty())

            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('DataAddedSuccessfully')),
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'green',
            });
        } else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('PleaseSelectOpenItemData')),
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
            setError('open_item', Yup.array().required(t("ShiftAbleToWorkValidation")).nullable())
        }
    }

    if (createdPoId) {
        setTimeout(() => {
            let id = createdPoId;
            dispatch(setCreatePoId())
            dispatch(setOpenItemData())
            dispatch(setProjectData(null))
            navigate('/purchase-order/update/'+id)
        }, 700)
    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('PurchaseOrder')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllPurchaseOrderRelatedInformationCanBeFoundHere')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={() => navigate(-1)}
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">

            <Stepper active={active} onStepClick={setActive} breakpoint="sm" color="green" size="sm">
                <Stepper.Step label={t("OpenItemInformation")} description={t('OpenItemDetails')}>
                    <OpenItemInformation
                        setOpenItemCheckData={setOpenItemCheckData}
                        openItemCheckData={openItemCheckData}
                        setValue={setValue}
                        setError={setError}
                    />
                </Stepper.Step>

            </Stepper>
                                                            </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-6">
                                                                <div className="text-right pt-0.5 mb-0.5 ">
                                                                    {/*<button
                                                                        type='reset'
                                                                        onClick={(e) => {setRecall(true)}}
                                                                        className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}>
                                                                        <FiX size={16} className={'mr-2'}/>
                                                                        <span>{t('Cancel')}</span>
                                                                    </button>*/}
                                                                    {/*{
                                                                        active != 0 ? <Button onClick={(e) => {
                                                                            setRecall(true)
                                                                            prevStep()
                                                                        }}
                                                                                              className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                            <HiArrowCircleLeft size={16}
                                                                                               className={'mr-2'}/><span> {t('Back')}</span></Button> : ''
                                                                    }*/}


                                                                    {/*<Button onClick={(e) => {
                                                                        setShowModal(true);
                                                                    }}
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border  rounded text-sm mr-2"><HiEye
                                                                        size={16}
                                                                        className={'mr-2'}/><span> {t('Preview')}</span></Button>*/}

                                                                    <button type='submit'
                                                                            className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}/>
                                                                        ) : (active == 5 ?
                                                                            <FiSave size={16} className={'mr-2'}/> :
                                                                            <HiArrowCircleRight size={16}
                                                                                                className={'mr-2'}/>)}
                                                                        <span>{active == 5 ? t('Submit') : t('Next')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default CreatePurchaseOrder