import React, {useEffect} from "react";
import {
    HiEye,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,
    HiOutlinePlus, HiShieldCheck,
    HiPencil, HiCheck, HiOutlineFilter, HiTrash
} from "react-icons/hi";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {ActionIcon, Box, Drawer, Grid, Group, ScrollArea, Select, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {showNotification, updateNotification} from "@mantine/notifications";
import View from "./PreRequisitionView";
import {Link, useNavigate} from "react-router-dom";
import {format} from "date-fns";
import {DatePicker} from "@mantine/dates";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";
import {budgetDelete} from "../../../store/budget/budgetSlice";


function PreRequisitionList(){
    const {t, i18n} = useTranslation();
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PRE_PURCHASE_REQUISITION_ADD = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_ADD";
    })
    const ROLE_PRE_PURCHASE_REQUISITION_VIEW = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_VIEW";
    })
    const ROLE_PRE_PURCHASE_REQUISITION_EDIT = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_EDIT";
    })

    const ROLE_PRE_PURCHASE_REQUISITION_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_APPROVE";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_PRE_PURCHASE_REQUISITION_DELETE = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_DELETE";
    })
    const ROLE_PRE_PURCHASE_REQUISITION_TO_REQUISITION = decoded.roles.find(role => {
        return role === "ROLE_PRE_PURCHASE_REQUISITION_TO_REQUISITION";
    })

    const [activeTab,setActiveTab] = useState('draft');
    const [preRequisition, setPreRequisition] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const navigate = useNavigate();
    const [refNumberData, setRefNumberData] = useState('');
    const [prNumberData, setPrNumberData] = useState('');
    const [itemData, setItemData] = useState('');
    const [projectData, setProjectData] = useState('');
    const [keywordData, setKeywordData] = useState(null);
    const [startDateData, setStartDate] = useState(null);
    const [endDateData, setEndDate] = useState(null);
    const [archiveData, setArchiveData] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created', direction: 'DESC' });
    const [spinner, setSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [opened, setOpened] = useState(false);
    const [requisitionID,setRequisitionID] = useState('');

    // FOR DATA TABLE DATA SHOW START
        const PAGE_SIZE = 50;
        const [page, setPage] = useState(localStorage.getItem('pre_requisition_page') ? localStorage.getItem('pre_requisition_page') : 1);
        const tableHeight = localStorage.getItem('tableHeight')
        const drawerHeight = localStorage.getItem('drawerHeight')

        useEffect(() => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/list/${activeTab}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {
                    "offset": PAGE_SIZE,
                    "page" : page,
                    "ref_no" : refNumberData && refNumberData != null?refNumberData:'',
                    "pr_number" : prNumberData && prNumberData!=null?prNumberData:'',
                    "item_id" : itemData && itemData !=null?itemData:'',
                    "project_id" : projectData && projectData!= null ?projectData:'',
                    "keyword" : keywordData && keywordData !=null?keywordData:'',
                    "start_date" : startDateData && startDateData!=null?format(startDateData,'yyyy-MM-dd'):'',
                    "end_date" : endDateData && endDateData!=null?format(endDateData,'yyyy-MM-dd'):'',
                    "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                    "order_by": sortStatus.direction?sortStatus.direction:'',
                    "requisition_type" : "pre-requisition",
                    "is_archive" : archiveData
                }
            })
                .then(res => {
                    setTimeout(()=>{
                        setPreRequisition(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('pre_requisition_page', page);
                        setFetching(false)
                    },500)

                })
                .catch(function (error) {
                    console.log(error)
                })

        }, [page,activeTab,archiveData,sortStatus,fetching]);
    // FOR DATA TABLE DATA SHOW END

    // start get project dropdown
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    const ProjectDataHandel = (e) => {
        setProjectData(e);
    }

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })

    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }

    // on change item dropdown handel
    const itemDropdownHandel = (e) => {
        setItemData(e)
    }
    // get items dropdown


    // start get pr number dropdown
    const [prNumberSearchValue, setPrNumberSearchValue] = useState('');
    const [prNumber, setPrNumber] = useState([]);
    function getPrNumberDropdown(e) {
        setPrNumberSearchValue(e);
        if (prNumberSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/dropdown/pr-number?value=${prNumberSearchValue}&type=pre-requisition`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.pr_number
                        })
                    })
                setPrNumber(data);
            });
        }
    }

    // on change pr number dropdown handel
    const prNumberDropdownHandel = (e) => {
        setPrNumberData(e)
    }
    // get pr number dropdown

    // start get ref number dropdown
    const [refNumberSearchValue, setRefNumberSearchValue] = useState('');
    const [refNumber, setRefNumber] = useState([]);
    function getRefNumberDropdown(e) {
        setRefNumberSearchValue(e);
        if (refNumberSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/dropdown/ref-number?value=${refNumberSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'label': head.ref_no, 'value': Number(head.ref_no)
                        })
                    })
                setRefNumber(data);
            });
        }
    }

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created'},
            {'label': 'Projects Name', 'value': 'projectName'},
            {'label': 'Reference Number', 'value': 'ref_no'},
            {'label': 'PR Number', 'value': 'pr_number'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})

    }
    // end order type dropdown
    // on change ref number dropdown handel
    const refNumberDropdownHandel = (e) => {
        setRefNumberData(e)
    }
    // get pr number dropdown

    // REQUIRED DATE HANDEL
    const StartDateHandel = (e) => {
        setStartDate(e);
    }

    const EndDateHandel = (e) => {
        setEndDate(e);
    }
    // REQUIRED DATE HANDEL

    const KeywordDataHandel = (e) => {
        setKeywordData(e.target.value);
    }

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    const ChangeRequisitionTab = (e,value) => {
        e.preventDefault();
        setActiveTab(value);
        setArchiveData(0)
        setPage(1);
        setFetching(true)
    }

    const ChangeRequisitionTabArchive = (e,value) => {
        e.preventDefault();
        setActiveTab('all')
        setArchiveData(1)
        setPage(1);
        setFetching(true)
    }

    const [viewModel, setViewModel] = useState(false);
    const PreRequisitionView = (e,id) => {
        e.preventDefault();
        setViewModel(true);
        setRequisitionID(id)
    }

    const CreatePreRequisition = (e) => {
        const formData = {
            status: 0,
            requisition_type: "pre-requisition"
        };
        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/create`,
                formData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 201) {
                            const last_id = res.data.last_id;
                            navigate('/pre-requisition/edit/'+last_id);
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: res.data.message,
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    }
                );
        }
    }

    const PreRequisitionEdit = (e,id) => {
        navigate('/pre-requisition/edit/'+id);
    }

    const GenerateRequisition = (e,id) =>{
        e.preventDefault();
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/generate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "id": Number(id)
            }
        })
            .then(res => {
                if (res.data.status === 201){
                    navigate('/requisition/edit/'+res.data.last_id);
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const DeleteDraft = (e) =>{
        showNotification({
            id: 'load-data',
            loading: true,
            title: t('DataIsLoading'),
            message: t('DataWillBeLoadedYouCannotCloseThisYet'),
            autoClose: false,
            disallowClose: true,
            color: 'red',
            style: { alignItems: 'top' },
        });
        e.preventDefault();
        setFetching(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/draft-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setTimeout(()=>{
                    if (res.data.status === 200){
                        setFetching(false)
                        updateNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: t('DataWasLoaded'),
                            message: t('NotificationWillCloseNow'),
                            icon: <HiCheck size={16} />,
                            autoClose: 1000,
                        });
                    }
                },500)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    //Form validation
    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    return(
        <>
            {viewModel?<View requisitionID={requisitionID} setViewModel={setViewModel} setFetching={setFetching}/>:''}


            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PreRequisition')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllPRRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            {
                                                                (ROLE_PRE_PURCHASE_REQUISITION_ADD || ROLE_PRE_PURCHASE_REQUISITION_EDIT || ROLE_SUPER_ADMIN) &&
                                                                <button
                                                                    onClick={event => CreatePreRequisition(event)}
                                                                    className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/requisition/'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
<div className="h-11">



    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'draft' && archiveData === 0?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'draft')}>
        {t('Draft')}
    </button>
    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Created' && archiveData === 0?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'Created')}>
        {t('Created')}
    </button>

    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab === 'approve'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'approve')}>
        {t('Approved')}
    </button>

    <button className={`inline-flex items-center px-2 py-1 h-10 ${archiveData === 1?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTabArchive(e,'Archive')}>
        {t('Archive')}
    </button>

    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all' && archiveData === 0?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'all')}>
        {t('All')}
    </button>
</div>

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title="Search Purchase Requisition"
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{ height: drawerHeight }}>
                                                                            <div className="mb-5">
                                                                                <label htmlFor="SelectProject" className="form-input-sm-label">{t('ChooseProject')}</label>
                                                                                <Select
                                                                                    {...register("projects")}
                                                                                    placeholder={t("ChooseProjectName")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={projectDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={ProjectDataHandel}
                                                                                    value={projectData}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="item" className="form-input-sm-label">{t('SelectItem')}</label>
                                                                                <Select
                                                                                    {...register("item")}
                                                                                    placeholder={t("SelectItem")}
                                                                                    searchable clearable allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={items}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onSearchChange={ItemSearchHandel}
                                                                                    onChange={e => {itemDropdownHandel(e)}}
                                                                                    value={itemData}
                                                                                    searchValue={itemSearchValue}
                                                                                />
                                                                            </div>
                                                                            <div className="mb-5">
                                                                                <label htmlFor="pr-number" className="form-input-sm-label">{t('SelectPrNumber')}</label>
                                                                                <Select
                                                                                    {...register("pr-number")}
                                                                                    placeholder={t("SelectPrNumber")}
                                                                                    searchable clearable allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={prNumber}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onSearchChange={getPrNumberDropdown}
                                                                                    onChange={e => {prNumberDropdownHandel(e)}}
                                                                                    value={prNumberData}
                                                                                    searchValue={prNumberSearchValue}
                                                                                />
                                                                            </div>
                                                                            <div className="mb-5">
                                                                                <label htmlFor="ref-number" className="form-input-sm-label">{t('SelectRefNumber')}</label>
                                                                                <Select
                                                                                    {...register("ref-number")}
                                                                                    placeholder={t("SelectRefNumber")}
                                                                                    searchable clearable allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={refNumber}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onSearchChange={getRefNumberDropdown}
                                                                                    onChange={e => {refNumberDropdownHandel(e)}}
                                                                                    value={refNumberData}
                                                                                    searchValue={refNumberSearchValue}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="startDate" className="form-input-sm-label">{t('StartDate')}</label>

                                                                                <DatePicker
                                                                                    {...register("start_date")}
                                                                                    dropdownType="modal"
                                                                                    placeholder="Pick date"
                                                                                    onChange={StartDateHandel}
                                                                                    value={startDateData}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="endDate" className="form-input-sm-label">{t('EndDate')}</label>
                                                                                <DatePicker
                                                                                    {...register("end_date")}
                                                                                    dropdownType="modal"
                                                                                    placeholder="Pick date"
                                                                                    onChange={EndDateHandel}
                                                                                    value={endDateData}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="Keyword" className="form-input-sm-label">{t('Keyword')}</label>
                                                                                <TextInput
                                                                                    {...register("ref_no")}
                                                                                    onChange={KeywordDataHandel}
                                                                                    placeholder={t("EnterKeyword")}
                                                                                    value={keywordData?keywordData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>
                                                                        </ScrollArea>

                                                                        <div className="mb-2 mt-5 text-right">

                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderTypeDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderTypeDataHandel}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderByDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderByDataHandel}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        <HiOutlineFilter size={12} className={'mr-2'}/>
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                                </Drawer>


                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={preRequisition}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (preRequisition.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'created',title: 'PR Date',sortable: true},
                                                                            {accessor: 'projectName',title: 'Project Name',sortable: true},
                                                                            {accessor: 'pr_number',title: t('PRNumber'),sortable: true},
                                                                            {accessor: 'priority',title: t('priority'),sortable: true},
                                                                            {accessor: 'ref_no',title: t('ref_no'),sortable: true},
                                                                            {
                                                                                accessor: 'status',
                                                                                title: 'Status',
                                                                                sortable: true,
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.status === 'Draft' && <span className="items-center px-2 py-1 text-black text-xs bg-green-300	block text-center">Draft</span>}
                                                                                        {item.status === 'Created' && <span className="items-center px-2 py-1 text-black text-xs bg-indigo-300	block text-center">Created</span>}
                                                                                        {item.status === 'Archive' && <span className="items-center px-2 py-1 text-black text-xs bg-blue-300	block text-center">Archived</span>}
                                                                                        {item.status === 'Approved' && <span className="items-center px-2 py-1 text-black text-xs bg-lime-300	block text-center">Approved</span>}
                                                                                        {item.status === 'Cancel' && <span className="items-center px-2 py-1 text-black text-xs bg-red-300	block text-center">Cancel</span>}
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: '',
                                                                                title: 'Approved',
                                                                                sortable: true,
                                                                                textAlignment : 'center',
                                                                                hidden : !ROLE_PRE_PURCHASE_REQUISITION_APPROVE || archiveData === 1 || activeTab === 'approve',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {(item.is_archive == 0 && item.status_id == 1 && item.approve_status === 'Not Approved' && item.total_requisition_item_quantity > 0) &&
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                PreRequisitionView(e, item.id)
                                                                                            }}
                                                                                            className="inline-flex items-center px-2 py-1 bg-lime-600 text-white-600 hover:bg-lime-700 hover:text-white-800 text-white text-xs">
                                                                                            Approved
                                                                                        </button>
                                                                                        }
                                                                                    </>
                                                                            },

    {
        accessor: 'actions',
        title: <Text mr="xs">Action</Text>,
        textAlignment: 'right',
        hidden : (!ROLE_PRE_PURCHASE_REQUISITION_EDIT && !ROLE_PRE_PURCHASE_REQUISITION_ADD && !ROLE_PRE_PURCHASE_REQUISITION_VIEW && !ROLE_PRE_PURCHASE_REQUISITION_APPROVE && !ROLE_PRE_PURCHASE_REQUISITION_TO_REQUISITION && !ROLE_SUPER_ADMIN),
        render: (item) => (
            <Group spacing={4} position="right" noWrap>
                {(item.is_archive == 0 && item.approve_status == 'Approved' && (ROLE_PRE_PURCHASE_REQUISITION_TO_REQUISITION || ROLE_SUPER_ADMIN))?
                    <ActionIcon color="yellow" title="Generate"
                                onClick={(e) => PreRequisitionView(e, item.id)}
                    >
                        <HiShieldCheck size={16}/>
                    </ActionIcon>
                    :''
                }
                {(item.is_archive == 0 && item.approve_status == 'Approved' && (ROLE_PRE_PURCHASE_REQUISITION_EDIT || ROLE_SUPER_ADMIN))?
                    <ActionIcon color="green" onClick={(e) => PreRequisitionEdit(e, item.id)}>
                        <HiPencil size={16}/>
                    </ActionIcon>
                    :''
                }

                {
                    ((item.status === 'Draft' || item.status === 'Created') && (ROLE_PRE_PURCHASE_REQUISITION_ADD || ROLE_PRE_PURCHASE_REQUISITION_EDIT || ROLE_SUPER_ADMIN)) ?
                        <ActionIcon color="green" onClick={(e) => PreRequisitionEdit(e, item.id)}>
                            <HiPencil size={16}/>
                        </ActionIcon>
                        :''
                }


                <ActionIcon color="blue">
                    <HiEye size={16} onClick={(e) => PreRequisitionView(e, item.id)}/>
                </ActionIcon>

                {
                    item.status === 'Draft' && (ROLE_PRE_PURCHASE_REQUISITION_DELETE || ROLE_PRE_PURCHASE_REQUISITION_ADD || ROLE_PRE_PURCHASE_REQUISITION_EDIT || ROLE_SUPER_ADMIN) &&
                    <ActionIcon color="red">
                        <HiTrash size={16} onClick={(e) =>{
                            openConfirmModal({
                                title: t('RequisitionDelete'),
                                centered: true,
                                children: (
                                    <Text size="sm">
                                        {t('AreYouSureToRequisitionDelete')}
                                    </Text>
                                ),
                                labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                confirmProps: {color: 'red'},
                                onCancel: () => console.log(t('Cancel')),
                                onConfirm: () => {
                                    axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/delete/${item.id}`,
                                        {headers: {"Authorization": `Bearer ${token}`}})
                                        .then(res => {
                                                if (res.data.status === 200) {
                                                    setFetching(true)
                                                }
                                            }
                                        );
                                }
                                ,
                            });
                        }}/>
                    </ActionIcon>
                }
            </Group>
        ),
    },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="sm"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PreRequisitionList