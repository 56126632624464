import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,HiSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Group, LoadingOverlay, Select, Stack, Text, TextInput, Button} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";

import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import {openConfirmModal} from "@mantine/modals";
import {
    setPrDailyBasisReportFilterData,
    setFetching,
    setPage,
     getDailyBasisPr
} from "../../../../store/report/reportSlice";
import {vendorDropdown} from "../../../../store/vendor/vendorSlice";
import {showNotification} from "@mantine/notifications";
import {IconChevronRight} from "@tabler/icons-react";
import clsx from "clsx";

const PAGE_SIZE = 20;
import classes from "../PO/poReportNasted.module.css";

function PrDailyReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })

    // start get project dropdown
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setSubCategoryDropdown(res.data.data);
        });
    }, []);
    let subCategoryDropdownValue =
        subCategoryDropdown.map((category, index) => {
            return ({
                'label': category.sub_categories_name, 'value': category.id
            })
        })

    // start get contact person dropdown
    const [isDownload, setIsDownload] = useState(false);

    const prDailyBasisReportFilterData = useSelector((state) => state.reportReducer.prDailyBasisReportFilterData)
    const dailyBasisPrData = useSelector((state) => state.reportReducer.dailyBasisPrData)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)
    const [expandedCompanyIds, setExpandedCompanyIds] = useState([]);

    useEffect(() => {
        dispatch(vendorDropdown())
    }, [fetching,page]);

    const CallPoReport = (page=1) => {
        if (prDailyBasisReportFilterData.start_date == '' || prDailyBasisReportFilterData.end_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start & end date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                project : prDailyBasisReportFilterData.project,
                sub_category : prDailyBasisReportFilterData.sub_category,
                start_date : prDailyBasisReportFilterData.start_date,
                end_date : prDailyBasisReportFilterData.end_date,
            }
            dispatch(getDailyBasisPr(param))
        }
    }

    const exportToCSV = () => {
        openConfirmModal({
            title: 'It may some times , sure ? ',
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToDownloadAllStockItem')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
                const param = {
                    offset : PAGE_SIZE,
                    page : page,
                    project : prDailyBasisReportFilterData.project,
                    sub_category : prDailyBasisReportFilterData.sub_category,
                    start_date : prDailyBasisReportFilterData.start_date,
                    end_date : prDailyBasisReportFilterData.end_date,
                }
                 axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/daily-basis-pr/generate-csv`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `text/html`,
                        "Content-Disposition": 'attachment; filename=data.csv',
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                    },
                    params : param
                })
                    .then(res => {
                        if (res.status === 200){
                            const  fileName = 'DailyBasisPr-'+format(new Date(), 'dd-MM-yyyy')+'.csv'
                            const href = `${process.env.REACT_APP_API_GATEWAY_URL}/report/daily-basis-pr/download-csv`;
                            const anchorElement = document.createElement('a');
                            anchorElement.href = href;
                            anchorElement.download = fileName;
                            document.body.appendChild(anchorElement);
                            anchorElement.click();
                            setIsDownload(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PrDailyBasisReport')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PrDailyBasisReportRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {(dailyBasisPrData && dailyBasisPrData.length>0) &&
                                                                <>
                                                                <button
                                                                onClick={(e) => exportToCSV()}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={prDailyBasisReportFilterData.start_date?prDailyBasisReportFilterData.start_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPrDailyBasisReportFilterData({
                                                                                        ...prDailyBasisReportFilterData,
                                                                                        ['start_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPrDailyBasisReportFilterData({
                                                                                        ...prDailyBasisReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose End Date"
                                                                            value={prDailyBasisReportFilterData.end_date?prDailyBasisReportFilterData.end_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPrDailyBasisReportFilterData({
                                                                                        ...prDailyBasisReportFilterData,
                                                                                        ['end_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPrDailyBasisReportFilterData({
                                                                                        ...prDailyBasisReportFilterData,
                                                                                        ['end_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPrDailyBasisReportFilterData({
                                                                                    ...prDailyBasisReportFilterData,
                                                                                    ['project']: e
                                                                                }))
                                                                            }}
                                                                            value={prDailyBasisReportFilterData.project}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <Select
                                                                            placeholder={t("ChooseSubCategory")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={subCategoryDropdownValue}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPrDailyBasisReportFilterData({
                                                                                    ...prDailyBasisReportFilterData,
                                                                                    ['sub_category']: e
                                                                                }))
                                                                            }}
                                                                            value={prDailyBasisReportFilterData.sub_category}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <button
                                                                            onClick={(e) => CallPoReport()}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{height: reportTableHeight,width: containerWidth}} className="mt-3">

                                                                    <DataTable
                                                                        withBorder
                                                                        records={dailyBasisPrData}
                                                                        withColumnBorders={1}
                                                                        highlightOnHover
                                                                        columns={[
                                                                            {
                                                                                accessor: 'classes',
                                                                                title: '',
                                                                                render: ({ id }) => (
                                                                                    <>
                                                                                        <IconChevronRight
                                                                                            className={clsx( classes.icon,classes.expandIcon, {
                                                                                                [classes.expandIconRotated]: expandedCompanyIds.includes(id),
                                                                                            })}
                                                                                        />
                                                                                    </>
                                                                                ),
                                                                            },
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                // textAlignment: 'right',
                                                                                render: (item) => (dailyBasisPrData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'pr_id', title: <Text mr="xs">PR NO</Text>,},
                                                                            {accessor: 'ref_no', title: 'PR Ref.No',},
                                                                            {accessor: 'created_date', title: 'IssueDate',},
                                                                            {accessor: 'created_username', title: 'Created By',textAlignment:"center"},
                                                                            {accessor: 'approved_date_category_head_two', title: 'Approve Date',textAlignment:"center"},
                                                                            {accessor: 'projects_name', title: 'Project Name',textAlignment:"center"},
                                                                            {accessor: 'is_head_or_local', title: 'Purchase From',textAlignment:"center"},
                                                                            {accessor: 'process', title: 'Status',textAlignment:"center"}
                                                                        ]}
                                                                        rowExpansion={{
                                                                            expanded: { recordIds: expandedCompanyIds, onRecordIdsChange: setExpandedCompanyIds },
                                                                            collapseProps: {
                                                                                transitionDuration: 500,
                                                                                animateOpacity: false,
                                                                                transitionTimingFunction: 'ease-out',
                                                                            },
                                                                            content: ({ record }) => (
                                                                                <Stack p="xs" gap={6} className="bg-indigo-100">
                                                                                    <Group gap={6}>
                                                                                        <table className="table-full w-full border-slate-800 text-xs">
                                                                                            <tr>
                                                                                                <th>Item Name</th>
                                                                                                <td>{record.items_name}</td>
                                                                                                <th>Unit</th>
                                                                                                <td>{record.item_unit_name}</td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <th>PR NO</th>
                                                                                                <td>{record.pr_id}</td>
                                                                                                <th>PR Qty</th>
                                                                                                <td>{record.total_requisition_item_quantity}</td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <th>Item Type</th>
                                                                                                <td>{record.item_types}</td>
                                                                                                <th>Category</th>
                                                                                                <td>{record.categories_name}</td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <th>Sub Category</th>
                                                                                                <td>{record.sub_categories_name}</td>
                                                                                                <th>Spend Area</th>
                                                                                                <td>{record.spend_area}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>PO NO</th>
                                                                                                <td>{record.po_id}</td>
                                                                                                <th>PO Qty</th>
                                                                                                <td>{record.poi_quantities}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>PR Cancel Qty</th>
                                                                                                <td>{record.pri_close_quantities}</td>
                                                                                                <th>PO remaining QTY</th>
                                                                                                <td>{(record.total_requisition_item_quantity?record.total_requisition_item_quantity:0)-(record.poi_quantities?record.poi_quantities:0+record.pri_close_quantities?record.pri_close_quantities:0)}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>PR Amount</th>
                                                                                                <td>{((record.total_requisition_item_quantity?record.total_requisition_item_quantity:0)*(record.items_price?record.items_price:0)).toFixed(2)}</td>
                                                                                                <th>Claimed By</th>
                                                                                                <td>{record.claimed_username}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Claimed Date</th>
                                                                                                <td>{record.claimed_date}</td>
                                                                                                <th>Receive Qty	</th>
                                                                                                <td>{record.total_order_receive_quantity}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Receiving close Qty.</th>
                                                                                                <td>{record.poi_close_quantities}</td>
                                                                                                <th>Receive remaining Qty		</th>
                                                                                                <td>{(record.poi_quantities?record.poi_quantities:0)-(record.total_order_receive_quantity?record.total_order_receive_quantity:0)}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Verified By</th>
                                                                                                <td>{record.approved_by_project_head_username}</td>
                                                                                                <th>Validated By</th>
                                                                                                <td>{record.approved_by_category_head_one_username}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Approved By</th>
                                                                                                <td>{record.approved_by_category_head_two_username}</td>
                                                                                                <th>Statuss</th>
                                                                                                <td>{record.process}</td>
                                                                                            </tr>

                                                                                        </table>
                                                                                    </Group>
                                                                                </Stack>
                                                                            ),
                                                                        }}

                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            CallPoReport(p)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PrDailyReport