import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, ScrollArea,Avatar} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import {setUserViewID, setUserViewModel} from "../../../store/user/userSlice";
import axios from "axios";

function UserViewModel() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [projects, setProjects] = useState(null);
    const [roles, setRoles] = useState(null);
    const token = localStorage.getItem('user_token');
    const userViewID = useSelector((state) => state.userReducer.userViewID)

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${userViewID}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setTimeout(()=>{
                    setLoading(false)
                    if (res.data.status === 200){
                        setUserDetails(res.data.data)

                        const projectExisting = res.data.data.projects;
                        let projectName = [];
                        projectExisting.map((projectID)=>{
                            projectName.push(projectID.projects_name);
                        })
                        setProjects(projectName.join(' & '))

                        const roleExisting = res.data.data.roles;
                        const userRoleID = [];
                        roleExisting.map((roleID)=>{
                            userRoleID.push(roleID.name);
                        })
                        setRoles(userRoleID.join(' & '))
                    }
                },1000)

            })
            .catch(function (error) {
                console.log(error)
            })

    }, [loading]);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('UserDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setUserViewModel(false))
                                        dispatch(setUserViewID(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                <Fragment>
                                                    <div className="bg-gray-100" >
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                {
                                                                    userDetails &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <Avatar
                                                                                    size="xl"
                                                                                    src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user/${userDetails.path}`}
                                                                                    alt="it's me"
                                                                                />
                                                                                <div className="mt-4">
                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('UserInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>

                                                                                        <ul role="list"
                                                                                            className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                userDetails.full_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('FullName')} : </span>
                                                                                                        {userDetails.full_name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                userDetails.username &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('UserName')} : </span>
                                                                                                        {userDetails.username}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                userDetails.email &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('Email')} : </span>
                                                                                                        {userDetails.email}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                userDetails.cellphone &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('Phone')} : </span>
                                                                                                        {userDetails.cellphone}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                userDetails.designations &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold ">{t('Designation')} : </span>
                                                                                                        {userDetails.designations}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">Role & Project Information</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    userDetails &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Name')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Description')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        <tr className={"bg-gray-100"} key={1}>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">1</td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                Project
                                                                                            </td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                {projects}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className={"bg-gray-100"} key={2}>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">2</td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                Role
                                                                                            </td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                {roles}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className={"bg-gray-100"} key={3}>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">3</td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                Permission
                                                                                            </td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                {
                                                                                                    userDetails.user_permission?userDetails.user_permission.join(' & '):''
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">

                                                        <div className="float-left mb-3">

                                                        </div>

                                                        <div className="float-right mb-3">
                                                            <button
                                                                onClick={(e) => {
                                                                    dispatch(setUserViewModel(false))
                                                                    dispatch(setUserViewID(null))
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default UserViewModel