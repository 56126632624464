import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {
    ScrollArea,
    Select,
    Text,
    TextInput,
    Group,
    useMantineTheme,
    ActionIcon,
    LoadingOverlay, Tabs, List, ThemeIcon
} from "@mantine/core";
import {
     HiOutlinePlus,
    HiOutlineX, HiTrash
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {showNotification} from "@mantine/notifications";
import {projectDetailsData} from "../../../store/project/projectSlice";
import {
    deliveryTempDetails,
    getRemainingQuantity,
    groupLedgerDropdown,
    inlineUpdateTempDelivery,
    setIssueFromData,
    setItemIssueCustomerModel,
    setFetching,
    tempDataInsertDelivery,
    tempItemAdded,
    tempItemDelete,
    tempItemInlineUpdate,
    createDeliveryTemp,
    setProjectID,
    setEmptyRemainingQuantity, setEmptyIssueFromData, setFilterData, issueToCustomerDeliveryIndex
} from "../../../store/issue/issueSlice";
import {getIssueCustomer} from "../../../store/user/userSlice";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconCircleCheck, IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import axios from "axios";
import {getCategorySubCategory, getItemDropdownProjectWise, getItemExists} from "../../../store/items/itemsSlice";
import {openConfirmModal} from "@mantine/modals";
import {format} from "date-fns";
import {getCategoryDropdown} from "../../../store/category/CategorySlice";
import {getSubCategoryDropdown} from "../../../store/subCategory/subCategorySlice";


function ItemIssueCustomerModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_ERP_LEDGER_HEAD_ACCESS = decoded.roles.find(role=>{
        return role === "ROLE_ERP_LEDGER_HEAD_ACCESS" ;
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role=>{
        return role === "ROLE_SUPER_ADMIN" ;
    })

    const projectDetails = useSelector((state) => state.projectReducer.projectDetails)
    const projectID = useSelector((state) => state.issueReducer.projectID)
    const deliveryTempID = useSelector((state) => state.issueReducer.deliveryTempID)
    const tempDetails = useSelector((state) => state.issueReducer.tempDetails)
    const inlineUpdateTempDeliveryMessage = useSelector((state) => state.issueReducer.inlineUpdateTempDeliveryMessage)
    const existsMessage = useSelector((state) => state.issueReducer.existsMessage)
    const ledgerDropdown = useSelector((state) => state.issueReducer.ledgerDropdown)
    const remainingQuantity = useSelector((state) => state.issueReducer.remainingQuantity)
    const issueFromData = useSelector((state) => state.issueReducer.issueFromData)
    const issueCustomerDropdown = useSelector((state) => state.userReducer.issueCustomer)
    const itemCategorySubCat = useSelector((state) => state.itemReducer.itemCategorySubCat)
    const projectWiseItemDropdown = useSelector((state) => state.itemReducer.projectWiseItemDropdown)
    const itemExists = useSelector((state) => state.itemReducer.itemExists)


    /*COMMON CATEGORY & CATEGORY WISE SUBCATEGORY*/
    const categoryDropdown = useSelector((state) => state.categoryReducer.categoryDropdownData)
    const subCategoryDropdown = useSelector((state) => state.subCategoryReducer.subCategoryDropdownData)

    useEffect(() => {
        dispatch(getCategoryDropdown())
    },[])

    const [categoryData, setCategoryData] = useState([]);
    const categoryDropdownHandel = (e) => {
        setCategoryData(e)
        dispatch(getSubCategoryDropdown(e))
    }
    /*COMMON CATEGORY & CATEGORY WISE SUBCATEGORY*/


    // start get contact person dropdown
    const [itemSearchValue, setItemSearchValue] = useState('');

    // start get spend area dropdown
    const [spendArea, setSpendArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/spend-area/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setSpendArea(res.data.data);
            });
        dispatch(groupLedgerDropdown())
    }, []);

    let spendAreaDropdown =
        spendArea.map((type, index) => {
            return ({
                'label': type.titles, 'value': Number(type.id)
            })
        })


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(getItemExists({
                'project_id' : projectID
            }))
            dispatch(projectDetailsData(projectID))
            dispatch(getIssueCustomer())
            setLoading(false)
        },500)
    },[])

    useEffect((e)=>{
        setTimeout(()=>{
            if (projectID && deliveryTempID){
                dispatch(deliveryTempDetails({
                    temp_delivery_id : deliveryTempID
                }))
            }
            setLoading(false)
        },500)
    },[loading])


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ItemIssueInformation')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(createDeliveryTemp({
                                            "project_id": null
                                        }))
                                        dispatch(setItemIssueCustomerModel(false))
                                        dispatch(setProjectID(''))
                                        dispatch(setEmptyIssueFromData())
                                        dispatch(setEmptyRemainingQuantity())
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                        <Fragment>
                                                            <div className="bg-gray-100" ref={pdfRef}>
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">

    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        projectDetails &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('ProjectName')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Address')}</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                projectDetails && (
                                    <tr className={"bg-gray-100"} key={1}>
                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.projects_name?projectDetails.projects_name:''}</td>
                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.address?projectDetails.address:''}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>
    </div>



    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('IssueInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        projectDetails &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Customer')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('GetPass')} <span className="text-red-500">{existsMessage == 'GetPassAlreadyExists'?'(Get Pass Already Exists)':''}</span> </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr className={"bg-gray-100"} key={1}>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <Select
                                            placeholder='Choose Customer'
                                            searchable  allowDeselect
                                            nothingFound="No options"
                                            withAsterisk
                                            data={issueCustomerDropdown?issueCustomerDropdown:[]}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            maxDropdownHeight={280}
                                            onChange={(e) => {
                                                dispatch(inlineUpdateTempDelivery({
                                                    delivey_id : deliveryTempID,
                                                    field_name : 'customer',
                                                    value : e,
                                                    type : 'temp'
                                                }))
                                                setLoading(true)
                                            }}
                                            value={tempDetails.customer?Number(tempDetails.customer):null}
                                        />
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        <TextInput
                                            placeholder={t('GetPass')}
                                            defaultValue = {tempDetails.get_pass?tempDetails.get_pass:''}
                                            onBlur={(e)=>{
                                                dispatch(inlineUpdateTempDelivery({
                                                    delivey_id : deliveryTempID,
                                                    field_name : 'get_pass',
                                                    value : e.target.value,
                                                    type : 'temp'
                                                }))
                                                if (inlineUpdateTempDeliveryMessage === 'update'){
                                                    setLoading(true)
                                                }
                                            }}
                                            withAsterisk
                                            error = {''}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>
    </div>



    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                        <tbody>
                        <tr className={"bg-gray-100"} key={'1'}>
                            <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                <Dropzone
                                    onDrop={(file) => {
                                        dispatch(inlineUpdateTempDelivery({
                                            delivey_id : deliveryTempID,
                                            field_name : 'path',
                                            value : file[0],
                                            type:'temp'
                                        }))
                                        setLoading(true)
                                    }}
                                    onReject={(files) =>{
                                        showNotification({
                                            id: 'load-data',
                                            loading: true,
                                            title: 'Please select PDF file.',
                                            message: (t('DataLoad')),
                                            autoClose: 1000,
                                            disallowClose: true,
                                            color: 'red',
                                        });
                                    }}
                                    accept={[MIME_TYPES.pdf]}
                                    multiple={false}                                >
                                    <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                size="3.2rem"
                                                stroke={1.5}
                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                size="3.2rem"
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <IconPhoto size="3.2rem" stroke={1.5} />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text size="xl" inline>
                                                Drag images here or click to select files
                                            </Text>
                                            <Text size="sm" color="dimmed" inline mt={7}>
                                                Drag PDF here or click to select files
                                            </Text>
                                        </div>
                                    </Group>
                                </Dropzone>
                                {
                                        (tempDetails && tempDetails.path) &&
                                        <object data={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${tempDetails.path}`} type="application/pdf" width="100%" height="100%">
                                            <p>Alternative text - include a link <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${tempDetails.path}`}>to the PDF!</a></p>
                                        </object>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>




     <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemIssueInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {/*{
                        tempDetails &&*/}
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                <thead className="text-black bg-gray-200">
                                <tr>
                                    <td className="p-2 border-collapse border border-slate-400" colSpan={4}>
                                        <div className="text-center">
                                            <Select
                                                placeholder='Choose Item'
                                                searchable allowDeselect
                                                nothingFound="No options"
                                                withAsterisk
                                                data={projectWiseItemDropdown ? projectWiseItemDropdown : []}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                maxDropdownHeight={280}
                                                onSearchChange={(e) => {
                                                    setItemSearchValue(e)
                                                    if (e.length != 0) {
                                                        dispatch(getItemDropdownProjectWise({
                                                            value: e,
                                                            project_id: projectID
                                                        }))
                                                    }
                                                }}
                                                onChange={e => {
                                                    let itemExistsObj = itemExists.find(o => o.item_id === e);
                                                    if (itemExistsObj) {
                                                        openConfirmModal({
                                                            title: 'Item already exists as Created',
                                                            children: (
                                                                <Text size="sm">
                                                                    <List
                                                                        spacing="xs"
                                                                        size="sm"
                                                                        center
                                                                        icon={
                                                                            <ThemeIcon color="red" size={24}
                                                                                       radius="xl">
                                                                                <IconCircleCheck size={16}/>
                                                                            </ThemeIcon>
                                                                        }
                                                                    >
                                                                        <List.Item>Delivery status
                                                                            : {itemExistsObj.process}</List.Item>
                                                                        <List.Item>Item Name
                                                                            : {itemExistsObj.itemsName} ({itemExistsObj.itemUnitName})</List.Item>
                                                                        <List.Item>Issue Date
                                                                            : {itemExistsObj.issue_date}</List.Item>
                                                                        <List.Item>Issue Quantity
                                                                            : {itemExistsObj.quantity}</List.Item>
                                                                    </List>
                                                                </Text>
                                                            ),
                                                            labels: {confirm: 'Goto Item', cancel: 'Cancel'},
                                                            onCancel: () => console.log('Cancel'),
                                                            confirmProps: {color: 'green'},
                                                            onConfirm: () => {
                                                                const param = {
                                                                    activeTab: 'all',
                                                                    offset: '20',
                                                                    page: 1,
                                                                    order_type: 'id',
                                                                    order_by: 'DESC',
                                                                    delivery_type: itemExistsObj.issue_project_id ? 'project' : 'individual',
                                                                    deliveryDate: itemExistsObj.issue_date_filter,
                                                                    project: projectID,
                                                                }
                                                                dispatch(issueToCustomerDeliveryIndex(param))

                                                                dispatch(setProjectID(''))
                                                                dispatch(setItemIssueCustomerModel(false))
                                                                dispatch(setEmptyIssueFromData())
                                                                dispatch(setEmptyRemainingQuantity())
                                                            },
                                                        });
                                                    } else {
                                                        dispatch(getRemainingQuantity({
                                                            item_id: e,
                                                            project_id: projectID
                                                        }))
                                                        /*dispatch(getCategorySubCategory({
                                                            item_id: e
                                                        }))*/
                                                        dispatch(setIssueFromData({...issueFromData, ['item_id']: e}))
                                                    }
                                                }}
                                                searchValue={itemSearchValue}
                                                value={issueFromData.item_id}
                                            />
                                        </div>
                                    </td>

                                    <td className="p-2 border-collapse border border-slate-400" colSpan={2}>
                                        <div className="text-center">
                                            <Select
                                                placeholder='Choose Category'
                                                searchable allowDeselect
                                                nothingFound="No options"
                                                withAsterisk
                                                data={categoryDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                maxDropdownHeight={280}
                                                onChange={e => {
                                                    categoryDropdownHandel(e)
                                                }}
                                                value={Number(categoryData)}
                                            />
                                        </div>
                                    </td>

                                    <td className="p-2 border-collapse border border-slate-400" colSpan={3}>
                                        <div className="text-center">
                                            <Select
                                                placeholder='Choose Sub Category'
                                                searchable allowDeselect
                                                nothingFound="No options"
                                                withAsterisk
                                                data={subCategoryDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                maxDropdownHeight={280}
                                                onChange={(e) => {
                                                    dispatch(setIssueFromData({
                                                        ...issueFromData,
                                                        ['sub_category_id']: e
                                                    }))
                                                }}
                                                value={issueFromData.sub_category_id ? Number(issueFromData.sub_category_id) : ''}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-2 border-collapse border border-slate-400" colSpan={3}>
                                        <div className="text-center">
                                            <Select
                                                placeholder='Choose Spend Area'
                                                searchable allowDeselect
                                                nothingFound="No options"
                                                withAsterisk
                                                data={spendAreaDropdown ? spendAreaDropdown : []}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                maxDropdownHeight={280}
                                                onChange={(e) => {
                                                    dispatch(setIssueFromData({...issueFromData, ['spend_area']: e}))
                                                }}
                                                value={issueFromData.spend_area ? Number(issueFromData.spend_area) : ''}
                                            />
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    {
                                        (ROLE_SUPER_ADMIN || ROLE_ERP_LEDGER_HEAD_ACCESS) &&
                                        <td className="p-2 border-collapse border border-slate-400" colSpan={4}>
                                            <div className="text-center">
                                                <Select
                                                    placeholder='Choose Ledger ERP'
                                                    searchable allowDeselect
                                                    nothingFound="No options"
                                                    withAsterisk
                                                    data={
                                                        ledgerDropdown ?
                                                            ledgerDropdown.map((head, index) => {
                                                                return ({
                                                                    'value': Number(head.id),
                                                                    'label': head.titles,
                                                                    'group': head.parent_titles
                                                                })
                                                            }) : []
                                                    }
                                                    transition="pop-top-left"
                                                    transitionDuration={80}
                                                    transitionTimingFunction="ease"
                                                    maxDropdownHeight={280}
                                                    onChange={(e) => {
                                                        dispatch(setIssueFromData({...issueFromData, ['ledger_head']:e}))
                                                    }}
                                                    value={issueFromData.ledger_head?Number(issueFromData.ledger_head):''}
                                                />
                                            </div>
                                        </td>
                                    }



                                    <td className="p-2 border-collapse border border-slate-400" colSpan={4}>
                                        <div className="text-center">
                                            <TextInput
                                                className={`text-center`}
                                                placeholder={`Remarks`}
                                                value={issueFromData.remark?issueFromData.remark:''}
                                                onChange={(e)=>{
                                                    dispatch(setIssueFromData({...issueFromData,['remark']:e.target.value}))
                                                }}
                                            />
                                        </div>
                                    </td>

                                    <td className="p-2 border-collapse border border-slate-400" colSpan={2}>
                                        <div className="text-center">
                                            <TextInput
                                                className={`text-center`}
                                                placeholder={remainingQuantity.remainingQuantity?'Stock Quantity '+remainingQuantity.remainingQuantity:'Quantity'}
                                                value={issueFromData.quantity?issueFromData.quantity:''}
                                                onChange={(e)=>{
                                                    dispatch(setIssueFromData({...issueFromData,['quantity']:e.target.value}))
                                                    if (Number(e.target.value)>Number(remainingQuantity.remainingQuantity)){
                                                        showNotification({
                                                            id: 'load-data',
                                                            loading: true,
                                                            title: 'Stock quantity '+remainingQuantity.remainingQuantity,
                                                            message: 'Exists Stock quantity',
                                                            autoClose: 700,
                                                            disallowClose: true,
                                                            color: 'red',
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>

                                    <td className="p-2 border-collapse border border-slate-400">
                                        <button type={'button'}
                                                onClick={(e) => {
                                                    let validation = true
                                                    let validationMessage = ''
                                                    if (issueFromData.quantity == '' || issueFromData.quantity == null){
                                                        validation = false
                                                        validationMessage = 'Enter issue quantity'
                                                    }else {
                                                        if (Number(issueFromData.quantity)>Number(remainingQuantity.remainingQuantity)){
                                                            validation = false
                                                            validationMessage = 'Max quantity '+remainingQuantity.remainingQuantity
                                                        }
                                                    }

                                                    /*if (issueFromData.ledger_head == '' || issueFromData.ledger_head == null){
                                                        validation = false
                                                        validationMessage = 'Choose ledger head'
                                                    }*/

                                                    if (issueFromData.spend_area == '' || issueFromData.spend_area == null){
                                                        validation = false
                                                        validationMessage = 'Choose spend area'
                                                    }

                                                    if (categoryData=='' || categoryData == null){
                                                        validation = false
                                                        validationMessage = 'Choose category'
                                                    }

                                                    if (issueFromData.sub_category_id == '' || issueFromData.sub_category_id == null){
                                                        validation = false
                                                        validationMessage = 'Choose sub category'
                                                    }

                                                    if (issueFromData.item_id == '' || issueFromData.item_id == null){
                                                        validation = false
                                                        validationMessage = 'Choose item'
                                                    }

                                                    if (validation){
                                                        let data = {
                                                            delivery_temp_id:deliveryTempID,
                                                            project_id:projectID,
                                                            item_id:issueFromData.item_id,
                                                            sub_category_id:issueFromData.sub_category_id,
                                                            spend_area:issueFromData.spend_area,
                                                            ledger_head:issueFromData.ledger_head,
                                                            remark:issueFromData.remark,
                                                            quantity:issueFromData.quantity,
                                                            category : categoryData,
                                                            weightedAvgPrice : remainingQuantity.weightedAvgPrice
                                                        }
                                                        dispatch(tempItemAdded(data))
                                                        dispatch(setEmptyRemainingQuantity())
                                                        setCategoryData(null)
                                                        setLoading(true)
                                                    }else {
                                                        showNotification({
                                                            id: 'load-data',
                                                            loading: true,
                                                            title: validationMessage,
                                                            message: (t('DataLoad')),
                                                            autoClose: 1000,
                                                            disallowClose: true,
                                                            color: 'red',
                                                        });
                                                    }
                                                }}
                                                className="inline-flex w-full h-full justify-center items-center px-2 py-2 bg-indigo-700 text-white hover:bg-indigo-600 text-xs">
                                            <HiOutlinePlus  size={12} className={'mr-1'} ></HiOutlinePlus>{t("Add")}
                                        </button>
                                    </td>
                                </tr>
                                </thead>

                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Item')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('itemType')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Category')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SubCategory')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SpendArea')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('RemainingQty')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('IssueQty')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    {
                                        (ROLE_SUPER_ADMIN || ROLE_ERP_LEDGER_HEAD_ACCESS) &&
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">{t('LedgerHead')}</div>
                                        </th>
                                    }
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Remarks')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center"></div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    (tempDetails && tempDetails['temp-item'] && tempDetails['temp-item'].length >0 ) &&
                                    tempDetails['temp-item'].map((item, index) => {
                                        console.log(item)
                                        let subCategoryDropdown =
                                            item.subCategory?item.subCategory.map((head, index) => {
                                                return ({
                                                    'value': Number(head.id),
                                                    'label': head.sub_categories_name
                                                })
                                            }):[]
                                        return (
                                            <tr className={"bg-gray-100"} key={index}>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.item_name?item.item_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.item_type_name?item.item_type_name:''}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.category['name']?item.category['name']:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    <Select
                                                        placeholder='Choose Sub Category'
                                                        searchable  allowDeselect
                                                        nothingFound="No options"
                                                        withAsterisk
                                                        data={subCategoryDropdown?subCategoryDropdown:[]}
                                                        transition="pop-top-left"
                                                        transitionDuration={80}
                                                        transitionTimingFunction="ease"
                                                        maxDropdownHeight={280}
                                                        onChange={(e) => {
                                                            dispatch(tempItemInlineUpdate({
                                                                delivery_temp_item_id : item.id,
                                                                "field_name": "sub_category",
                                                                "value": e,
                                                                "type" : 'temp'
                                                            }))
                                                            setLoading(true)
                                                        }}
                                                        value={item.sub_category_id?Number(item.sub_category_id):''}
                                                    />
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    <Select
                                                        placeholder='Choose Spend Area'
                                                        searchable  allowDeselect
                                                        nothingFound="No options"
                                                        withAsterisk
                                                        data={spendAreaDropdown?spendAreaDropdown:[]}
                                                        transition="pop-top-left"
                                                        transitionDuration={80}
                                                        transitionTimingFunction="ease"
                                                        maxDropdownHeight={280}
                                                        onChange={(e) => {
                                                            dispatch(tempItemInlineUpdate({
                                                                delivery_temp_item_id : item.id,
                                                                "field_name": "spend_area",
                                                                "value": e,
                                                                "type" : 'temp'
                                                            }))
                                                            setLoading(true)
                                                        }}
                                                        value={item.spend_area?Number(item.spend_area):''}
                                                    />
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.remaining['remainingQuantity']?item.remaining['remainingQuantity']:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    <TextInput
                                                        className={`text-center`}
                                                        defaultValue={item.issue_quantity?Number(item.issue_quantity):''}
                                                        placeholder={item.remaining.remainingQuantity?'Stock Quantity '+item.remaining.remainingQuantity:'Quantity'}
                                                        onBlur={(e)=>{
                                                            if (Number(e.target.value)>Number(item.remaining.remainingQuantity)){
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Stock quantity '+item.remaining.remainingQuantity,
                                                                    message: 'Exists Stock quantity',
                                                                    autoClose: 700,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }else {
                                                                dispatch(tempItemInlineUpdate({
                                                                    delivery_temp_item_id : item.id,
                                                                    "field_name": "quantity",
                                                                    "value": e.target.value,
                                                                    "type" : 'temp'
                                                                }))
                                                                setLoading(true)
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.item_unit_name?item.item_unit_name:''}
                                                </td>
                                                {
                                                    (ROLE_SUPER_ADMIN || ROLE_ERP_LEDGER_HEAD_ACCESS) &&
                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                        <Select
                                                            placeholder='Choose Ledger ERP'
                                                            searchable  allowDeselect
                                                            nothingFound="No options"
                                                            withAsterisk
                                                            data={
                                                                ledgerDropdown ?
                                                                    ledgerDropdown.map((head, index) => {
                                                                        return ({
                                                                            'value': Number(head.id),
                                                                            'label': head.titles,
                                                                            'group': head.parent_titles
                                                                        })
                                                                    }):[]
                                                            }
                                                            transition="pop-top-left"
                                                            transitionDuration={80}
                                                            transitionTimingFunction="ease"
                                                            maxDropdownHeight={280}
                                                            onChange={(e) => {
                                                                dispatch(tempItemInlineUpdate({
                                                                    delivery_temp_item_id : item.id,
                                                                    "field_name": "ledger_head",
                                                                    "value": e,
                                                                    "type" : 'temp'
                                                                }))
                                                                setLoading(true)
                                                            }}
                                                            value={item.erp_ledger_account?Number(item.erp_ledger_account):''}
                                                        />
                                                    </td>
                                                }

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    <TextInput
                                                        className={`text-center`}
                                                        defaultValue={item.remark?item.remark:''}
                                                        onBlur={(e)=>{
                                                            dispatch(tempItemInlineUpdate({
                                                                delivery_temp_item_id : item.id,
                                                                "field_name": "remark",
                                                                "value": e.target.value,
                                                                "type" : 'temp'
                                                            }))
                                                            setLoading(true)
                                                        }}
                                                    />
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    <button type={'button'} className="inline-flex w-full h-full justify-center items-center text-xs">
                                                        <ActionIcon color="red">
                                                            <HiTrash
                                                                size={16}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    openConfirmModal({
                                                                        title: (t('DeleteThisData')),
                                                                        centered: true,
                                                                        children: (
                                                                            <Text size="sm">
                                                                                {t('AreYouSureYouWantToDelete')}
                                                                            </Text>
                                                                        ),
                                                                        labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                                        confirmProps: { color: 'red' },
                                                                        onCancel: () => console.log(t('Cancel')),
                                                                        onConfirm: () => {
                                                                            setTimeout(() => {
                                                                                dispatch(tempItemDelete(item.id))
                                                                                setLoading(true)
                                                                            },500)
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </ActionIcon>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                </tbody>
                            </table>
                </div>
            </div>
        </div>
    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-right mb-3">
                    {
                        (existsMessage!='GetPassAlreadyExists') &&
                        <button
                            onClick={(e) => {
                                let validation = true
                                let validationMessage = ''

                                if (!tempDetails['temp-item']){
                                    validation = false
                                    validationMessage = t('EnterDeliveryItems')
                                }
                                if (!tempDetails.get_pass || tempDetails.get_pass == '' || tempDetails.get_pass == null){
                                    validation = false
                                    validationMessage = t('EnterGatePass')
                                }
                                if (!tempDetails.customer || tempDetails.customer == '' || tempDetails.customer == null){
                                    validation = false
                                    validationMessage = t('ChooseCustomer')
                                }

                                if (validation){
                                    openConfirmModal({
                                        title: (t('SubmitThisDelivery')),
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                {t('AreYouSureYouWantToSubmitThisDelivery')}
                                            </Text>
                                        ),
                                        labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                        confirmProps: { color: 'red' },
                                        onCancel: () => console.log(t('Cancel')),
                                        onConfirm: () => {
                                            dispatch(tempDataInsertDelivery({
                                                temp_delivery_id:deliveryTempID
                                            }))
                                            dispatch(setProjectID(''))
                                            dispatch(setItemIssueCustomerModel(false))
                                            dispatch(setFetching(true))
                                        },
                                    });
                                }else {
                                    showNotification({
                                        id: 'load-data',
                                        loading: true,
                                        title: validationMessage,
                                        message: (t('DataLoad')),
                                        autoClose: 1000,
                                        disallowClose: true,
                                        color: 'red',
                                    });
                                }
                            }}
                            className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                        >
                            Submit
                        </button>
                    }


                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>



        </>
    )
}

export default ItemIssueCustomerModel