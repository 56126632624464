import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {ScrollArea, Select, TextInput, Textarea, LoadingOverlay, Group, Text, useMantineTheme} from "@mantine/core";
import {
    HiOutlineExclamation,
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {
    invoiceBillDropdown,
    ReceiveDetails,
    receivedVerifyForPayment,
    receivedWiseinvoiceChallanDropdown,
    setFilterData,
    setVerifyData,
    setVerifyID,
    setVerifyMessage,
    setVerifyModelView,
    setFetching,
    setReceiveDetailsData,
    setVerifyRefresh,
    ReceiveInvoiceChallanInlineUpdate,
    setLoading,
    setAddInvoiceModel, setAddInvoiceReceivedID, ReceiveInlineUpdate, setGrnRef
} from "../../../store/receivedItem/receivedItemSlice";

import {format} from "date-fns";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {DatePicker} from "@mantine/dates";


function AddInvoiceModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_VERIFY = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VERIFY";
    })

    const verifyID = useSelector((state) => state.receivedItemReducer.addInvoiceReceivedID)
    const receiveDetailsData = useSelector((state) => state.receivedItemReducer.receiveDetailsData)


    useEffect((e) => {
        setTimeout(() => {
            dispatch(ReceiveDetails({
                received_id: verifyID
            }))

            setLoading(false)

        }, 500)
    }, [loading])

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('AddInvoiceInformation')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setAddInvoiceModel(false))
                                        dispatch(setAddInvoiceReceivedID(null))
                                        dispatch(setReceiveDetailsData())
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">

                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow      sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label-required">{t('ChallanAttach')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <Dropzone
                                                                                                onDrop={(file) => {
                                                                                                    const data = {
                                                                                                        field_name: "challan",
                                                                                                        value: file[0],
                                                                                                        id: receiveDetailsData.calans,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                                onReject={(files) => {
                                                                                                    showNotification({
                                                                                                        id: 'load-data',
                                                                                                        loading: true,
                                                                                                        title: 'Please select PDF file.',
                                                                                                        message: (t('DataLoad')),
                                                                                                        autoClose: 1000,
                                                                                                        disallowClose: true,
                                                                                                        color: 'red',
                                                                                                    });
                                                                                                }}
                                                                                                accept={[MIME_TYPES.pdf]}
                                                                                                multiple={false}
                                                                                            >
                                                                                                <Group position="center"
                                                                                                       spacing="sm"
                                                                                                       style={{pointerEvents: 'none'}}>
                                                                                                    <Dropzone.Accept>
                                                                                                        <IconUpload
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Accept>
                                                                                                    <Dropzone.Reject>
                                                                                                        <IconX
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Reject>
                                                                                                    <Dropzone.Idle>
                                                                                                        <IconPhoto
                                                                                                            stroke={1.5}/>
                                                                                                    </Dropzone.Idle>

                                                                                                    <div>
                                                                                                        <Text size="sm"
                                                                                                              inline>
                                                                                                            {
                                                                                                                (receiveDetailsData && receiveDetailsData.challan_path) ?
                                                                                                                    receiveDetailsData?.challan_path?.split('/').pop() :
                                                                                                                    'Drag PDF here or click to select files'
                                                                                                            }
                                                                                                        </Text>
                                                                                                    </div>
                                                                                                </Group>
                                                                                            </Dropzone>

                                                                                            {
                                                                                                (receiveDetailsData && receiveDetailsData.challan_path) &&
                                                                                                <a title="Invoice Attachment"
                                                                                                   target="_blank"
                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.calans}/challan`}
                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                                                                    Attachment</a>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label-required">{t('ChallanBillNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <input
                                                                                                type="text"
                                                                                                className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                placeholder={t("ChallanBillNo")}
                                                                                                defaultValue={receiveDetailsData && receiveDetailsData.challan_bill_number ? receiveDetailsData.challan_bill_number : null}
                                                                                                onBlur={(e) => {
                                                                                                    const data = {
                                                                                                        field_name: "bill_number",
                                                                                                        value: e.target.value,
                                                                                                        id: receiveDetailsData.calans,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label-required">{t('ChallanBillDate')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <DatePicker
                                                                                                placeholder={t('ChallanBillDate')}
                                                                                                value={receiveDetailsData && receiveDetailsData.challan_bill_date ? new Date(receiveDetailsData.challan_bill_date) : null}
                                                                                                onChange={(e) => {
                                                                                                    const data = {
                                                                                                        field_name: "bill_date",
                                                                                                        value: format(e, 'yyyy-MM-dd'),
                                                                                                        id: receiveDetailsData.calans,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="GRNFileAttach"
                                                                                               className="form-input-sm-label-required">{t('GRNFileAttach')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <Dropzone
                                                                                                onDrop={(file) => {
                                                                                                    const GRNFileAttachData = {
                                                                                                        field_name: "path",
                                                                                                        value: file[0],
                                                                                                        recivedID: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInlineUpdate(GRNFileAttachData))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                                onReject={(files) => {
                                                                                                    showNotification({
                                                                                                        id: 'load-data',
                                                                                                        loading: true,
                                                                                                        title: 'Please select PDF file.',
                                                                                                        message: (t('DataLoad')),
                                                                                                        autoClose: 1000,
                                                                                                        disallowClose: true,
                                                                                                        color: 'red',
                                                                                                    });
                                                                                                }}
                                                                                                accept={[MIME_TYPES.pdf]}
                                                                                                multiple={false}
                                                                                            >
                                                                                                <Group position="center"
                                                                                                       spacing="sm"
                                                                                                       style={{pointerEvents: 'none'}}>
                                                                                                    <Dropzone.Accept>
                                                                                                        <IconUpload
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Accept>
                                                                                                    <Dropzone.Reject>
                                                                                                        <IconX
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Reject>
                                                                                                    <Dropzone.Idle>
                                                                                                        <IconPhoto
                                                                                                            stroke={1.5}/>
                                                                                                    </Dropzone.Idle>

                                                                                                    <div>
                                                                                                        <Text size="sm"
                                                                                                              inline>
                                                                                                            {
                                                                                                                (receiveDetailsData.path && receiveDetailsData.path) ?
                                                                                                                    receiveDetailsData?.path?.split('/').pop():
                                                                                                                    'Drag PDF here or click to select files'
                                                                                                            }
                                                                                                        </Text>
                                                                                                    </div>
                                                                                                </Group>
                                                                                            </Dropzone>

                                                                                            {
                                                                                                (receiveDetailsData && receiveDetailsData.path) &&
                                                                                                <a title="GRN Attachment"
                                                                                                   target="_blank"
                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.id}/grn`}
                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                                                                    Attachment</a>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="refno"
                                                                                               className="form-input-sm-label-required">{t('RefNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                placeholder={t("EnterRefNo")}
                                                                                                defaultValue={receiveDetailsData.ref_grn ? receiveDetailsData.ref_grn : ''}
                                                                                                onBlur={(e) => {
                                                                                                    dispatch(setGrnRef(e.target.value))
                                                                                                    // setValue('ref_grn',e.target.value)
                                                                                                    const GRNFRefData = {
                                                                                                        field_name: "ref_grn",
                                                                                                        value: e.target.value,
                                                                                                        recivedID: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInlineUpdate(GRNFRefData))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow      sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label">{t('InvoiceAttach')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <Dropzone
                                                                                                onDrop={(file) => {
                                                                                                    const data = {
                                                                                                        field_name: "path",
                                                                                                        value: file[0],
                                                                                                        id: receiveDetailsData.invoices,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    // console.table(data)
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                                onReject={(files) => {
                                                                                                    showNotification({
                                                                                                        id: 'load-data',
                                                                                                        loading: true,
                                                                                                        title: 'Please select PDF file.',
                                                                                                        message: (t('DataLoad')),
                                                                                                        autoClose: 1000,
                                                                                                        disallowClose: true,
                                                                                                        color: 'red',
                                                                                                    });
                                                                                                }}
                                                                                                accept={[MIME_TYPES.pdf]}
                                                                                                multiple={false}
                                                                                            >
                                                                                                <Group position="center"
                                                                                                       spacing="sm"
                                                                                                       style={{pointerEvents: 'none'}}>
                                                                                                    <Dropzone.Accept>
                                                                                                        <IconUpload
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Accept>
                                                                                                    <Dropzone.Reject>
                                                                                                        <IconX
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Reject>
                                                                                                    <Dropzone.Idle>
                                                                                                        <IconPhoto
                                                                                                            stroke={1.5}/>
                                                                                                    </Dropzone.Idle>

                                                                                                    <div>
                                                                                                        <Text size="sm"
                                                                                                              inline>
                                                                                                            {
                                                                                                                (receiveDetailsData && receiveDetailsData.invoice_path) ?
                                                                                                                    receiveDetailsData?.invoice_path?.split('/').pop() :
                                                                                                                    'Drag PDF here or click to select files'
                                                                                                            }
                                                                                                        </Text>
                                                                                                    </div>
                                                                                                </Group>
                                                                                            </Dropzone>

                                                                                            {
                                                                                                (receiveDetailsData && receiveDetailsData.invoice_path) &&
                                                                                                <a title="Invoice Attachment"
                                                                                                   target="_blank"
                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.invoices}/invoice`}
                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                                                                    Attachment</a>
                                                                                            }
                                                                                            {
                                                                                                (receiveDetailsData && receiveDetailsData.invoice_path && receiveDetailsData.is_old == 0) &&
                                                                                                <span onClick={(e) => {
                                                                                                    const data = {
                                                                                                        field_name: "empty_invoice_path",
                                                                                                        value: null,
                                                                                                        id: receiveDetailsData.invoices,
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    dispatch(setLoading(true))
                                                                                                }}
                                                                                                      title="Invoice Attachment"
                                                                                                      className="inline-flex cursor-pointer items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Delete</span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label">{t('InvoiceBillNo')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <input
                                                                                                type="text"
                                                                                                className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                placeholder={t("InvoiceBillNo")}
                                                                                                defaultValue={receiveDetailsData && receiveDetailsData.invoice_bill_number ? receiveDetailsData.invoice_bill_number : null}
                                                                                                onBlur={(e) => {
                                                                                                    const data = {
                                                                                                        field_name: "bill_number",
                                                                                                        value: e.target.value,
                                                                                                        id: receiveDetailsData.invoices,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label
                                                                                            htmlFor="vendor_quotation_reference_number"
                                                                                            className="form-input-sm-label">{t('InvoiceBillDate')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <DatePicker
                                                                                                placeholder={t('InvoiceBillDate')}
                                                                                                value={receiveDetailsData && receiveDetailsData.invoice_bill_date ? new Date(receiveDetailsData.invoice_bill_date) : null}
                                                                                                onChange={(e) => {
                                                                                                    const data = {
                                                                                                        field_name: "bill_date",
                                                                                                        value: format(e, 'yyyy-MM-dd'),
                                                                                                        id: receiveDetailsData.invoices,
                                                                                                        received_id: receiveDetailsData.id
                                                                                                    }
                                                                                                    dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                                                    setLoading(true)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-right mb-3">

                                                            <button
                                                                onClick={(e) => {
                                                                    dispatch(setAddInvoiceModel(false))
                                                                    dispatch(setAddInvoiceReceivedID(null))
                                                                    dispatch(setReceiveDetailsData())
                                                                    dispatch(setFetching(true))
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                                                            >
                                                                SAVE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default AddInvoiceModel