import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {VscLocation} from "react-icons/vsc";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import View from "./RelatedBankView";
import toast from "react-hot-toast";


function RelatedBank() {

    const {t, i18n} = useTranslation();
    const token = localStorage.getItem('user_token');
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('related_bank_page') ? localStorage.getItem('related_bank_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63);
    var fromHeight = height - 153 + "px";

    const [relatedBank, setRelatedBank] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/related-bank`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page
            }
        })
            .then(res => {
                setTimeout(()=>{
                    if (res.data.status === 200){
                        setRelatedBank(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('related_bank_page', page);
                        setFetching(false)
                    }else {
                        toast.error(res.data.message);
                    }
                },2000)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [spinner, page,listRefresh]);

    // FOR DATA TABLE DATA SHOW END

    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("BankNameIsRequired")),
        branch: Yup.string().required(t("BranchNameIsRequired")),
        address: Yup.string().required(t("AddressIsRequired"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            name: data.name,
            branch: data.branch,
            address: data.address,
            status: true
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/related-bank/`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('RelatedBankAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    reset();
                    setFetching(true)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
        }

    }



    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        name: '',
        branch: '',
        address: '',

    });
    const RelatedBankEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/related-bank/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setEditInfo(res.data.data[0]);
            } else {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'green',
                });
            }
        });
    }

    const handelInputUpdate = (e) => {
        e.persist();
        setEditInfo({...editInfo, [e.target.name]: e.target.value})
    }

    function formSubmitUpdate(data) {
        setSpinner(true);
        const formData = {
            name: editInfo.name,
            branch: editInfo.branch,
            address: editInfo.address,
            status: true
        };

        if (formData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/related-bank/${editInfo.id}`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 202) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('RelatedBankUpdatedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    reset();
                    setFetching(true)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
        }
    }


    const RelatedBankDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: { confirm: (t('DeleteBankInformation')), cancel: (t("NoDontDeleteIt")) },
            confirmProps: { color: 'red' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/related-bank/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                        if (res.status === 200) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('DataIsDeleting')),
                                message: (t('DataLoad')),
                                autoClose: 1000,
                                disallowClose: true,
                                color: 'red',
                            });
                            setListRefresh(true);
                            setFetching(true)
                        }
                    })
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const RelatedBankView = (e,id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/related-bank/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setViewData(res.data.data[0]);
                setViewModel(true);
            } else {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Error')),
                    message: res.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'green',
                });
            }
        });
    }



    return (
        <>
            {viewModel?<View data={viewData} setViewModel={setViewModel}/>:''}

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('RelatedBank')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('BankRelatedInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button
                                                                onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12}
                                                                               className={'mr-1'}></HiOutlinePlus>{t('AddRelatedBank')}
                                                            </button>

                                                            <Link to='/master-data/related-bank'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2 text-xs group relative text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={relatedBank}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (relatedBank.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'name',
                                                                                 title: <Text mr="xs">{t('Name')}</Text>
                                                                            },
                                                                            {accessor: 'branch',
                                                                                 title: <Text mr="xs">{t('Branch')}</Text>
                                                                            },
                                                                            {accessor: 'address',
                                                                                 title: <Text mr="xs">{t('Address')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => RelatedBankEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => RelatedBankView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => RelatedBankDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="lg"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('BankInformationForm')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>


                                                                                    {/* Bank Name Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name" id='name'
                                                                                                placeholder={t("EnterBankName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Branch Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="branch"
                                                                                               className="form-input-sm-label-required">{t('Branch')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.branch?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.branch?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("branch")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.branch ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="branch"
                                                                                                id='branch'
                                                                                                placeholder={t("EnterBranchName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Address Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="address"
                                                                                               className="form-input-sm-label-required">{t('Address')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.address?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <VscLocation
                                                                                                    size={16}></VscLocation>
                                                                                            </div>
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                            <textarea
                                                                                                {...register("address")}
                                                                                                name="address"
                                                                                                id='address'
                                                                                                className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            />

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateBankInformation')}</div>
                                                                                    <button
                                                                                        onClick={event => setEditForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>


                                                                                    {/* Bank Name Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name" id='name'
                                                                                                value={editInfo.name}
                                                                                                onChange={handelInputUpdate}
                                                                                                placeholder={t("EnterBankName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Branch Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="branch"
                                                                                               className="form-input-sm-label-required">{t('Branch')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.branch?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.branch?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("branch")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.branch ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="branch"
                                                                                                id='branch'
                                                                                                value={editInfo.branch}
                                                                                                onChange={handelInputUpdate}
                                                                                                placeholder={t("EnterBranchName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Address Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="address"
                                                                                               className="form-input-sm-label-required">{t('Address')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.address?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <VscLocation
                                                                                                    size={16}></VscLocation>
                                                                                            </div>
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                            <textarea
                                                                                                {...register("address")}
                                                                                                name="address"
                                                                                                id='address'
                                                                                                value={editInfo.address}
                                                                                                onChange={handelInputUpdate}
                                                                                                className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            />

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setEditForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}

export default RelatedBank