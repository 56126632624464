import React, {useEffect} from "react";
import {
    HiEye, HiOutlineChartSquareBar,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,
    HiOutlinePlus, HiOutlineSearch,HiOutlineFilter,
    HiPencil
} from "react-icons/hi";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    ActionIcon,
    Box,
    Drawer,
    Group,
    ScrollArea,
    Select,
    Text,
    TextInput,
    Grid,Switch
} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {setUserViewID, setUserViewModel, UserEnableDisable} from "../../../store/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import UserViewModel from "./UserViewModel";
import SelectSearch from "react-select-search";
import {openConfirmModal} from "@mantine/modals";
import {StorePaymentVerify} from "../../../store/payment/paymentSlice";

function Index(){
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableHeight = localStorage.getItem('tableHeight')
    const drawerHeight = localStorage.getItem('drawerHeight')
    const containerWidth = localStorage.getItem('containerWidth')

    const token = localStorage.getItem('user_token');
    const [activeTab,setActiveTab] = useState('all');
    const [users, setUsers] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [anyKeywordData, setAnyKeywordData] = useState(null);
    const [opened, setOpened] = useState(false);

    const [checked, setChecked] = useState(false);

    const userViewModel = useSelector((state) => state.userReducer.userViewModel)
    const enableMessage = useSelector((state) => state.userReducer.enableMessage)

    // FOR DATA TABLE DATA SHOW START
        const PAGE_SIZE = 50;
        const [page, setPage] = useState(localStorage.getItem('user_page') ? localStorage.getItem('user_page') : 1);

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'id', direction: 'DESC' });

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'id'},
            {'label': 'Email', 'value': 'email'},
            {'label': 'Full name', 'value': 'full_name'},
            {'label': 'Phone', 'value': 'cellphone'},
            {'label': 'Designation', 'value': 'designations'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})
    }

    const [emailData, setEmailData] = useState(null);
    const EmailDataHandel = (e) => {
        setEmailData(e.target.value);
    }

    const [userNameData, setUserNameData] = useState(null);
    const UserNameDataHandel = (e) => {
        setUserNameData(e.target.value);
    }

    const [phoneData, setPhoneData] = useState(null);
    const PhoneDataHandel = (e) => {
        setPhoneData(e.target.value);
    }
        useEffect(() => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },

                params: {
                    "offset": PAGE_SIZE,
                    "page" : page,
                    "email" : emailData && emailData != null?emailData:'',
                    "user_name" : userNameData && userNameData!=null?userNameData:'',
                    "cellphone" : phoneData && phoneData !=null?phoneData:'',
                    "keyword" : anyKeywordData,
                    "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                    "order_by": sortStatus.direction?sortStatus.direction:''
                }
            })
                .then(res => {
                    setTimeout(()=>{
                        setUsers(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('user_page', page);
                        setSpinner(false);
                        setRefresh(false);
                        setFetching(false);

                    },500)

                })
                .catch(function (error) {
                    console.log(error)
                })

        }, [page,activeTab,refresh,sortStatus]);
    // FOR DATA TABLE DATA SHOW END

    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    // on change project head data get
    const [userData, setUserData] = useState(null);
    const HandelUserSearch = (e) => {
        setUserData(e)
        setAnyKeywordData(e)
        setFetching(true)
        setRefresh(true)
    }


    const [userSearchData, setUserSearchData] = useState([]);

    function getUserData(query) {
        if (!query) {
            return
        }

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/keyword/search?value=${query}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data = res.data.data.map((head, index) => {return ({'name': head.username, 'value': head.id})})
                setUserSearchData(data);
            });
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('User')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('UserInformation')}</sub></span>
                                                    </h1>
                                                </div>

                                                <div className={"flex-1"}>
                                                    <div className="text-gray-800  tracking-normal leading-tight ">
                                                        <div className="relative text-gray-600 focus-within:text-gray-400">
                                                            <SelectSearch
                                                                onChange={HandelUserSearch}
                                                                search="true"
                                                                options={userSearchData}
                                                                getOptions={(e) => getUserData(e)}
                                                                placeholder={t("SearchUser")}
                                                                value={userData?userData:null}
                                                            />
                                                            {/*<input
                                                                onBlur={(e) => {
                                                                    setAnyKeywordData(e.target.value)
                                                                }}
                                                                type="text" id="small-input"
                                                                className="block w-full p-2 mt-2 mr-2 text-gray-900 border-1 border-gray-300 rounded  sm:text-xs  border-indigo-500  focus:border-indigo-800  dark:placeholder-gray-400 dark:text-white"/>
                                                            <span className="absolute right-0 inset-y-0 top-0 flex items-center pl-2">
                                                                <button className="p-1 focus:outline-none focus:shadow-outline"
                                                                        onClick={(e) => {
                                                                            setFetching(true)
                                                                            setRefresh(true)
                                                                        }}
                                                                >
                                                                 <HiOutlineSearch size={20} className={'mr-2 text-indigo-800'}></HiOutlineSearch>
                                                                </button>
                                                              </span>*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>

                                                            <Link to='/user/add-user' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                            </Link>
                                                            <Link to='/user/'
                                                                  className=" justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title="Search User Information"
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{ height: drawerHeight }}>
                                                                            {/*<div className="mb-5">
                                                                                <label htmlFor="SelectProject" className="form-input-sm-label">{t('ChooseProject')}</label>
                                                                                <Select
                                                                                    {...register("projects")}
                                                                                    placeholder={t("ChooseProjectName")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={projectDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={ProjectDataHandel}
                                                                                    value={projectData}
                                                                                />
                                                                            </div>*/}

                                                                            <div className="mb-5">
                                                                                <label htmlFor="email" className="form-input-sm-label">{t('Email')}</label>
                                                                                <TextInput
                                                                                    {...register("ref_no")}
                                                                                    onChange={EmailDataHandel}
                                                                                    placeholder={t("EnterEmail")}
                                                                                    value={emailData?emailData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="user_name" className="form-input-sm-label">{t('UserName')}</label>
                                                                                <TextInput
                                                                                    onChange={UserNameDataHandel}
                                                                                    placeholder={t("EnterUserName")}
                                                                                    value={userNameData?userNameData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="phone" className="form-input-sm-label">{t('Phone')}</label>
                                                                                <TextInput
                                                                                    onChange={PhoneDataHandel}
                                                                                    placeholder={t("EnterPhoneNumber")}
                                                                                    value={phoneData?phoneData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>


                                                                        </ScrollArea>



                                                                        <div className="mb-2 mt-5 text-right">

                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderTypeDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderTypeDataHandel}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderByDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderByDataHandel}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <HiOutlineSearch
                                                                                                className={'mr-2 animate-spin'}
                                                                                                size={16}></HiOutlineSearch>
                                                                                        ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                            </Drawer>

                                                                    <Box sx={{height: tableHeight,width: containerWidth}}>

                                                                    <DataTable
                                                                        withBorder
                                                                        records={users}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (users.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'email',title: 'email',sortable: true},
                                                                            {accessor: 'full_name',title: 'Full name',sortable: true},
                                                                            {accessor: 'username',title: 'User name',sortable: true},
                                                                            {accessor: 'cellphone',title: 'Phone',sortable: true},
                                                                            {accessor: 'designations',title: 'Designation',sortable: true},
                                                                            {
                                                                                accessor: 'enable',
                                                                                title: <Text mr="xs">Status</Text>,
                                                                                textAlignment: 'center',
                                                                                render: (item) => (
                                                                                    <ActionIcon color="blue">
                                                                                        <Switch
                                                                                            size="md"
                                                                                            radius="sm"
                                                                                            color="green"
                                                                                            onLabel="Enable"
                                                                                            offLabel="Disable"
                                                                                            checked={item.enable==1?true:false}
                                                                                            onChange={(e) => {
                                                                                                openConfirmModal({
                                                                                                    title: 'Are you sure ?',
                                                                                                    centered: true,
                                                                                                    children: (
                                                                                                        <Text size="sm">
                                                                                                            Press confirm, if you want to confirm otherwise cancel.
                                                                                                        </Text>
                                                                                                    ),
                                                                                                    labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                                                                    confirmProps: { color: 'green' },
                                                                                                    onCancel: () => console.log(t('Cancel')),
                                                                                                    onConfirm: () => {
                                                                                                        const data = {
                                                                                                            id:item.id,
                                                                                                            enable : e.target.value=='on'?0:1
                                                                                                        }
                                                                                                        dispatch(UserEnableDisable(data))
                                                                                                        setRefresh(true)
                                                                                                    }
                                                                                                    ,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </ActionIcon>
                                                                                ),
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right" noWrap>
                                                                                        {
                                                                                            item.username != 'superadmin' &&
                                                                                            <ActionIcon color="green" onClick={(e) =>{
                                                                                                navigate('/user/edit/'+item.id);
                                                                                            }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16} onClick={(e) => {
                                                                                                dispatch(setUserViewID(item.id))
                                                                                                dispatch(setUserViewModel(true))
                                                                                            }}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {userViewModel && <UserViewModel />}
        </>
    )
}
export default Index