import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiCloud, HiSearch, HiPencil,
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActionIcon, Box, Group, LoadingOverlay, Select, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";
import {
    getAllStockItem,
    getStockItem,
    setFetching,
    setPage, setStockItemUpdateId,
    setStockItemUpdateModel, setStockUpdateComplete
} from "../../../store/inventory/inventorySlice";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {openConfirmModal} from "@mantine/modals";
import StockItemUpdateModel from "./StockItemUpdateModel";
const PAGE_SIZE = 20;

function StockItemReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_INVENTORY_STOCK_EDIT = decoded.roles.find(role => {
        return role === "ROLE_INVENTORY_STOCK_EDIT";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const [startDate,setStartDate] = useState(null)

    // start get project dropdown
    const [projectData, setProjectData] = useState('');
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    // start get contact person dropdown
    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);
    const [itemData, setItemData] = useState('');
    const [isDownload, setIsDownload] = useState(false);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }

    const page = useSelector((state) => state.inventoryReducer.page)
    const totalItem = useSelector((state) => state.inventoryReducer.totalItem)
    const fetching = useSelector((state) => state.inventoryReducer.fetching)
    const stockItems = useSelector((state) => state.inventoryReducer.stockItems)
    const allStockItems = useSelector((state) => state.inventoryReducer.allStockItems)
    const stockItemUpdateModel = useSelector((state) => state.inventoryReducer.stockItemUpdateModel)
    const stockUpdateComplete = useSelector((state) => state.inventoryReducer.stockUpdateComplete)

    useEffect((e)=>{
        dispatch(setStockUpdateComplete(false))
        const param = {
            offset : PAGE_SIZE,
            page : page,
            project : projectData,
            item : itemData,
            start_date : startDate
        }
        dispatch(getStockItem(param))
        // dispatch(setFetching(false))
    },[stockUpdateComplete])

    const CallStockReport = () =>{
        if (projectData){
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                project : projectData,
                item : itemData,
                start_date : startDate
            }
            dispatch(getStockItem(param))
        }
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToCSV = (formatType,type) => {
        if (type == 'current'){
            setIsDownload(true)
            const ws = XLSX.utils.json_to_sheet(stockItems);
            const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {
                bookType: formatType, type: 'array'
            });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'Stock item' +format(new Date(),'dd-MM-yyyy')+ '.'+formatType);
            setIsDownload(false)
        }else {
            if (allStockItems.length==0) {
                const param = {
                    project : projectData,
                    item : itemData,
                    start_date : startDate
                }
                dispatch(getAllStockItem(param))
            }
            openConfirmModal({
                title: 'It may some times , sure ? ',
                centered: true,
                children: (
                    <Text size="sm">
                        {t('AreYouSureToDownloadAllStockItem')}
                    </Text>
                ),
                labels: {
                    confirm: (t('Confirm')),
                    cancel: (t("Cancel"))
                },
                confirmProps: {color: 'red'},
                onCancel: () => console.log(t('Cancel')),
                onConfirm: () => {
                    setIsDownload(true)
                }
                ,
            });
        }
    }
    if (isDownload){
        if (allStockItems.length>0) {
            const ws = XLSX.utils.json_to_sheet(allStockItems);
            const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {
                bookType: 'csv', type: 'array'
            });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'Stock-item-' + format(new Date(), 'dd-MM-yyyy') + '.' + 'csv');
            setIsDownload(false)
        }
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('StockItem')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('StockItemReportInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {projectData &&
                                                                <>

                                                                <button
                                                                onClick={(e) => exportToCSV('csv', 'all')}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <DatePicker
                                                                            placeholder="Choose Date"
                                                                            value={startDate?startDate:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    setStartDate(format(e, 'yyyy-MM-dd'))
                                                                                    // projectData && dispatch(setFetching(true))
                                                                                }else {
                                                                                    setStartDate(null);
                                                                                    // projectData && dispatch(setFetching(true))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <Select
                                                                            placeholder={t("ChooseItem")}
                                                                            searchable  allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={items}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onSearchChange={ItemSearchHandel}
                                                                            onChange={e => {
                                                                                setItemData(e)
                                                                                // projectData && dispatch(setFetching(true))
                                                                            }}
                                                                            value={itemData}
                                                                            searchValue={itemSearchValue}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4 ">
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                setProjectData(e);
                                                                                // dispatch(setFetching(true))
                                                                            }}
                                                                            value={projectData}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <button
                                                                            onClick={(e) => CallStockReport()}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{height: reportTableHeight,width: containerWidth}} className="mt-3">
                                                                    <DataTable
                                                                        withBorder
                                                                        records={stockItems}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                // textAlignment: 'right',
                                                                                render: (item) => (stockItems.indexOf(item) + 1) + PAGE_SIZE * (page - 1)

                                                                            },
                                                                            {accessor: 'item', title: <Text mr="xs">Item</Text>,},
                                                                            {accessor: 'unit', title: 'Unit',},
                                                                            {accessor: 'category', title: 'Category',},
                                                                            {accessor: 'openItem', title: 'Opening',textAlignment:"center"},
                                                                            {accessor: 'receivingItem', title: 'Received By PO',textAlignment:"center"},
                                                                            {accessor: 'projectTo', title: 'Received By Project',textAlignment:"center"},
                                                                            {accessor: 'projectToStore', title: 'Received By Store Req.',textAlignment:"center"},
                                                                            {accessor: 'ReceivingTotal', title: 'Receiving Total',textAlignment:"center"},
                                                                            {accessor: 'issueToCustomer', title: 'Issue To Customer Qty',textAlignment:"center"},
                                                                            {accessor: 'issueToProject', title: 'Delivery To Project qty',textAlignment:"center"},
                                                                            {accessor: 'issueToStoreReq', title: 'Delivery To Store Requisition Qty',textAlignment:"center"},
                                                                            {accessor: 'totalIssue', title: 'Issue and Delivery Total',textAlignment:"center"},
                                                                            {accessor: 'closingItem', title: 'Stock In Hand',textAlignment:"center"},
                                                                            {
                                                                                accessor: 'poAveragePrice',
                                                                                title: 'Avg Price',
                                                                                textAlignment:"right",
                                                                                // render: ({ poAveragePrice }) => <>{(poAveragePrice?poAveragePrice.toFixed(2):'')}</>
                                                                            },
                                                                            {
                                                                                accessor: 'calculateWeightedPrice',
                                                                                title: 'Weighted Avg Price',
                                                                                textAlignment:"right",
                                                                                // render: ({ weightedAvgPrice,poAveragePrice }) => <>{(weightedAvgPrice?weightedAvgPrice:poAveragePrice)}</>
                                                                            },
                                                                            {
                                                                                accessor: 'lastPoPrice',
                                                                                title: 'Last Po Price',
                                                                                textAlignment:"right",
                                                                                // render: ({ lastPoPrice }) => <>{(lastPoPrice && lastPoPrice>0?lastPoPrice.toFixed(2):'')}</>
                                                                            },
                                                                            {accessor: 'minStockCount', title: 'Min Stock',textAlignment:"center"},
                                                                            {accessor: 'maxStockCount', title: 'Max Stock',textAlignment:"center"},
                                                                            {accessor: 'rackNumber', title: 'Rack Number',textAlignment:"center"},
                                                                            {accessor: 'stockRegisterPageNo', title: 'Reg. Page No',textAlignment:"center"},
                                                                            {accessor: 'stockBookNo', title: 'Book No',textAlignment:"center"},
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'center',
                                                                                width : 100,
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="center" noWrap>
                                                                                        {
                                                                                            (ROLE_SUPER_ADMIN || ROLE_INVENTORY_STOCK_EDIT) &&
                                                                                            <ActionIcon color="green" onClick={(e) =>{
                                                                                                dispatch(setStockItemUpdateId(item.id))
                                                                                                dispatch(setStockItemUpdateModel(true))
                                                                                            }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                    </Group>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            CallStockReport()
                                                                            // dispatch(setFetching(true))
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            offset : PAGE_SIZE,
            page : page,
            project : projectData,
            item : itemData,
            start_date : startDate
            {stockItemUpdateModel?<StockItemUpdateModel offset={PAGE_SIZE} page={page} project={projectData} item={itemData} start_date={startDate} />:''}
        </>
    )
}
export default StockItemReport