import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {Group, Loader, LoadingOverlay, ScrollArea, Select, Text, TextInput, useMantineTheme} from "@mantine/core";
import {HiOutlineX} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";

import {
    paymentBankCheque,
    paymentConfirmAttach,
    paymentInlineUpdate,
    paymentUpdate,
    setBankCheque,
    setPage,
    setPaymentUpdateID,
    setPaymentUpdateMessage,
    setPaymentUpdateModel, setRefresh,
    setRelatedBank,
    vendorWiseConfirm
} from "../../../store/payment/paymentSlice";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {openConfirmModal} from "@mantine/modals";
import {RelatedBankDropdown} from "../../../store/related-bank/relatedBankSlice";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";

function PayUpdateModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();

    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_CHECKED = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_CHECKED";
    })

    const paymentUpdateMessage = useSelector((state) => state.paymentReducer.paymentUpdateMessage)
    const refresh = useSelector((state) => state.paymentReducer.refresh)
    const paymentUpdateID = useSelector((state) => state.paymentReducer.paymentUpdateID)
    const bankCheque = useSelector((state) => state.paymentReducer.bankCheque)
    const vendorWiseConfirmData = useSelector((state) => state.paymentReducer.vendorWiseConfirmData)
    const relatedBank = useSelector((state) => state.paymentReducer.relatedBank)
    const relatedBankDropdown = useSelector((state) => state.relatedBankReducer.relatedBankDropdown)


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(vendorWiseConfirm({
                pay_id : [paymentUpdateID]
            }))
            setLoading(false)
        },500)
    },[])


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(paymentBankCheque({
                pay_id : paymentUpdateID
            }))
            setLoading(false)
            dispatch(setRefresh(false))
        },500)
    },[refresh])


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(RelatedBankDropdown())
        },500)
    },[])

    let isAdvancePayment = ''
    let advance_payment_amount = ''
    vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].map((po, index) => {
        isAdvancePayment = po.is_advance_payment
        advance_payment_amount = po.advance_payment_amount
    })

    let totalRequestAmount = 0;

    const validationSchemaStep = Yup.object().shape({});
    let formOptions = {resolver: yupResolver(validationSchemaStep)};
    const {handleSubmit,register} = useForm(formOptions);

    function formSubmit(data) {
        const fromData = {
            related_bank:relatedBank?relatedBank:bankCheque.bank_id,
            cheque_no : data.cheque_no?data.cheque_no:bankCheque.cheque_no,
            pay_id : paymentUpdateID,
            adjustment_amount : data.adjustment_amount
        }
        let dataValidate = true

        if (fromData.related_bank == '' ||  fromData.related_bank == null ){
            dataValidate = false
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please choose related bank',
                message: 'Related bank is required , fill-up this !',
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }

        if (fromData.cheque_no == '' ||  fromData.cheque_no == null ){
            dataValidate = false
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please enter cheque no',
                message: 'Cheque no is required , fill-up this !',
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }

        if(dataValidate){
            openConfirmModal({
                title: (t('ConfirmationThisPayment')),
                centered: true,
                children: (
                    <Text size="sm">
                        {t('ConfirmationThisPaymentDetails')}
                    </Text>
                ),
                labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                confirmProps: { color: 'green' },
                onCancel: () => console.log(t('Cancel')),
                onConfirm: () => {
                    dispatch(paymentUpdate(fromData))
                }
                ,
            });
        }
    }

    {
        if (paymentUpdateMessage === 'PaymentUpdate'){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Payment update successfully',
                message: 'Auto redirect to payment archive',
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
            setTimeout((e)=>{
                dispatch(setBankCheque([]))
                dispatch(setPaymentUpdateMessage(''))
                dispatch(setPaymentUpdateID(''))
                dispatch(setPaymentUpdateModel(false))
                dispatch(setPage(1))
            },1000)
        }
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PaymentUpdate')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setPaymentUpdateID(''))
                                        dispatch(setBankCheque([]))
                                        dispatch(setPaymentUpdateModel(false))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">

                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>

                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100" ref={pdfRef}>
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('OrderNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RefNo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('CompanyType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('IssueDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentMethod')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentFrom')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                            vendorWiseConfirmData['pruchaseOrder'].map((item, index) => {
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.purchase_order_id?item.purchase_order_id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.ref_no?item.ref_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.company_name?item.company_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.created_date?item.created_date:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.purchase_type_name?item.purchase_type_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.payment_method?item.payment_method:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.payment_from && item.payment_from == 2?'Head Office':'Local Office'}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorBuyerInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            {
                                                                                                vendorWiseConfirmData.buyer_email &&
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Buyer')}</div>
                                                                                                </th>
                                                                                            }
                                                                                            {
                                                                                                vendorWiseConfirmData.vendor &&
                                                                                                <>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('Vendor')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('ContactPerson')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('Mobile')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('VAT/eTin')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('AccountID')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('ComplianceStatus')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('BankName')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('AccountNo')}</div>
                                                                                                    </th>
                                                                                                </>
                                                                                            }
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                            vendorWiseConfirmData['pruchaseOrder'].map((item, index) => {
                                                                                                return (

                                                                                                    (index === 0 && item.vendor_email) &&
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.buyer_email?item.buyer_email:item.vendors_name}</td>

                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.contract_person?item.contract_person:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.contract_no?item.contract_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.vat_certificate_no?item.vat_certificate_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.account_id?item.account_id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.compliance_status?item.compliance_status:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bank_account_name?item.bank_account_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bank_account_no?item.bank_account_no:''}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentRequestInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['getPayment'] && vendorWiseConfirmData['getPayment'].length >0 ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentID')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentRequestedBy')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RequestAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('VATAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('AITAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PO')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['getPayment'] && vendorWiseConfirmData['getPayment'].length >0 ) &&
                                                                                            vendorWiseConfirmData['getPayment'].map((item, index) => {
                                                                                                totalRequestAmount = totalRequestAmount+item.requestAmount
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.id?item.id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.verifier_email?item.verifier_email:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bill_date?item.bill_date:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bill_number?item.bill_number:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            TK. { Number(item.requestAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.net_payment_amount}
                                                                                                            {/*<TextInput
                            className={`text-center`}
                            size="xs"
                            defaultValue={item.net_payment_amount?item.net_payment_amount:''}
                            onBlur={(e)=>{
                                const data = {
                                    value : e.target.value,
                                    name : 'net_payment_amount',
                                    pay_id : item.id
                                }
                                dispatch(paymentInlineUpdate(data))
                            }}
                        />*/}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.vat_amount}
                                                                                                            {/*<TextInput
                            className={`text-center`}
                            size="xs"
                            defaultValue={item.vat_amount?item.vat_amount:''}
                            onBlur={(e)=>{
                                const data = {
                                    value : e.target.value,
                                    name : 'vat_amount',
                                    pay_id : item.id
                                }
                                dispatch(paymentInlineUpdate(data))
                            }}
                        />*/}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.ait_amount}
                                                                                                            {/*<TextInput
                            name="ait_amount[]"
                            className={`text-center`}
                            size="xs"
                            defaultValue={item.ait_amount?item.ait_amount:''}
                            onBlur={(e)=>{
                                const data = {
                                    value : e.target.value,
                                    name : 'ait_amount',
                                    pay_id : item.id
                                }
                                dispatch(paymentInlineUpdate(data))
                            }}
                        />*/}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.paymentType?item.paymentType:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.po_id?item.po_id:''}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                        <tr>
                                                                                            <td colSpan={4} className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total</td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                                                                                TK. { Number(totalRequestAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentConformationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                    <tbody>
                                                                                    <tr className={"bg-gray-100"} key={'1'}>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            Bank Name
                                                                                        </td>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            <Select
                                                                                                {...register('related_bank')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={
                                                                                                    relatedBankDropdown
                                                                                                }
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                placeholder="Choose Related Bank"
                                                                                                onChange={(e) => {
                                                                                                    dispatch(setRelatedBank(e))
                                                                                                }}
                                                                                                size="xs"
                                                                                                value={relatedBank?relatedBank:bankCheque.bank_id}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className={"bg-gray-100"} key={'2'}>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            Cheque No
                                                                                        </td>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            {
                                                                                                (bankCheque.bank_name == 'Online Transfer' || bankCheque.bank_name=='Project Patty Cash') ?
                                                                                                    <TextInput
                                                                                                        {...register('cheque_no')}
                                                                                                        className={`text-center`}
                                                                                                        size="xs"
                                                                                                        defaultValue={bankCheque.PartyOrOnlineDate}
                                                                                                        placeholder="Cheque no"
                                                                                                    />
                                                                                                    :
                                                                                                    <TextInput
                                                                                                        {...register('cheque_no')}
                                                                                                        className={`text-center`}
                                                                                                        size="xs"
                                                                                                        defaultValue={bankCheque.cheque_no}
                                                                                                        placeholder="Cheque no"
                                                                                                    />
                                                                                            }

                                                                                        </td>
                                                                                    </tr>
                                                                                    {
                                                                                        isAdvancePayment == 1 &&
                                                                                        <>
                                                                                            <tr className={"bg-gray-100"} key={'3'}>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                    Adjustment Amount
                                                                                                </td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                    <TextInput
                                                                                                        {...register('adjustment_amount')}
                                                                                                        className={`text-center`}
                                                                                                        size="xs"
                                                                                                        defaultValue={''}
                                                                                                        placeholder="Adjustment Amount"
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr className={"bg-gray-100"} key={'4'}>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                    Advance Payment Amount
                                                                                                </td>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                    TK. { Number(advance_payment_amount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                                                                                </td>
                                                                                            </tr>
                                                                                        </>

                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentConfirmationAttachmentInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                    <tbody>
                                                                                    <tr className={"bg-gray-100"} key={'1'}>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                                                                            <Dropzone
                                                                                                onDrop={(file) => {
                                                                                                    const data = {
                                                                                                        attachment : file[0],
                                                                                                        pay_id : paymentUpdateID
                                                                                                    }
                                                                                                    dispatch(paymentConfirmAttach(data))
                                                                                                    dispatch(setRefresh(true))
                                                                                                }}
                                                                                                onReject={(files) =>{
                                                                                                    showNotification({
                                                                                                        id: 'load-data',
                                                                                                        loading: true,
                                                                                                        title: 'Please select PDF file.',
                                                                                                        message: (t('DataLoad')),
                                                                                                        autoClose: 1000,
                                                                                                        disallowClose: true,
                                                                                                        color: 'red',
                                                                                                    });
                                                                                                }}
                                                                                                accept={[MIME_TYPES.pdf]}
                                                                                                multiple={false}                                >
                                                                                                <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
                                                                                                    <Dropzone.Accept>
                                                                                                        <IconUpload
                                                                                                            size="3.2rem"
                                                                                                            stroke={1.5}
                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                        />
                                                                                                    </Dropzone.Accept>
                                                                                                    <Dropzone.Reject>
                                                                                                        <IconX
                                                                                                            size="3.2rem"
                                                                                                            stroke={1.5}
                                                                                                        />
                                                                                                    </Dropzone.Reject>
                                                                                                    <Dropzone.Idle>
                                                                                                        <IconPhoto size="3.2rem" stroke={1.5} />
                                                                                                    </Dropzone.Idle>

                                                                                                    <div>
                                                                                                        <Text size="xl" inline>
                                                                                                            Drag images here or click to select files
                                                                                                        </Text>
                                                                                                        <Text size="sm" color="dimmed" inline mt={7}>
                                                                                                            Drag PDF here or click to select files
                                                                                                        </Text>
                                                                                                    </div>
                                                                                                </Group>
                                                                                            </Dropzone>
                                                                                            {
                                                                                                refresh ?
                                                                                                    <>
                                                                                                        <Loader className="mx-auto justify-items-right w-full" size={"sm"} />
                                                                                                    </>
                                                                                                    :
                                                                                                    (bankCheque && bankCheque.confirm_attachment) &&
                                                                                                    <object data={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/confirm-attachment/${bankCheque.confirm_attachment}`} type="application/pdf" width="100%" height="100%">
                                                                                                        <p>Alternative text - include a link <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/confirm-attachment/${bankCheque.confirm_attachment}`}>to the PDF!</a></p>
                                                                                                    </object>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentHistoryInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['paymentHistory'] ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ApprovedDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillDateNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('GRNNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RequestedAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('AdjustmentAmount')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['paymentHistory'] && vendorWiseConfirmData['paymentHistory'].length >0 ) &&
                                                                                            vendorWiseConfirmData['paymentHistory'].map((item, index) => {
                                                                                                return (
                                                                                                    item.payment_date &&
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.request_date?item.request_date:null}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.payment_date?item.payment_date:null}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.grn_id?item.grn_id:'ADV'}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.requestAmount?item.requestAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.paymentAmount?item.paymentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.adjustmentAmount?item.adjustmentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <tr>
                                                                                            <td colSpan={8} className="w-1/24 border-collapse border border-slate-400 text-center font-bold"></td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">
                <div className="float-left mb-3">

                </div>

                <div className="float-right mb-3">

                    <button
                        type="submit"
                        className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                    >
                        Payment Update
                    </button>

                    <button
                        onClick={(e) => {
                            dispatch(setPaymentUpdateID(''))
                            dispatch(setBankCheque([]))
                            dispatch(setPaymentUpdateModel(false))
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default PayUpdateModel