import axios from "axios";

export const getItemWiseCategorySubCategory = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/item/get-category/sub-category`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getProjectWiseItemDropdown = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/item/project-wise-dropdown`,
            headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData =
                res.data.data.map((head, index) => {
                    return ({
                        'value': Number(head.id), 'label': head.items_name
                    })
                })
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};


export const getItemExistsInProjectByCreated = async (param) => {
    let returnData = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/delivery-issue/item-exists`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                returnData =
                    res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return returnData
};