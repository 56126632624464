import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {ActionIcon, Grid, LoadingOverlay, ScrollArea, Select, TextInput} from "@mantine/core";
import {
    HiOutlinePlus,
    HiOutlineX, HiPencil, HiTrash
} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {
    getPoItemDetailAfterApprove, PoItemEditAfterApprove,
    poItemLastPrice, setAfterApproveEditID, setAfterApproveEditModel,
    setItemPriceModel,
    setItemPriceModelItemID
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import axios from "axios";
import {getCategorySubCategory, setItemCategorySubCat} from "../../../store/items/itemsSlice";
import {format} from "date-fns";
import {showNotification} from "@mantine/notifications";


function AfterApproveEdit() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = '400px';
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('user_token');

    const afterApproveEditID = useSelector((state) => state.purchaseOrderReducer.afterApproveEditID)
    const poItemDetails = useSelector((state) => state.purchaseOrderReducer.poItemDetails)
    const poItemEditAfterApproveMessage = useSelector((state) => state.purchaseOrderReducer.poItemEditAfterApproveMessage)

    if (poItemEditAfterApproveMessage == 'success'){
        dispatch(setAfterApproveEditModel(false))
        dispatch(setAfterApproveEditID(null))
    }

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(getPoItemDetailAfterApprove(afterApproveEditID))
            setLoading(false)
        },500)
    },[])

    let categoryDropdown = [];
    const itemCategorySubCat = useSelector((state) => state.itemReducer.itemCategorySubCat)
    if (itemCategorySubCat && itemCategorySubCat.categories_name && itemCategorySubCat.categories_id){
        categoryDropdown = [{
            'label': itemCategorySubCat.categories_name, 'value': Number(itemCategorySubCat.categories_id)
        }]
    }

    let subCategoryDropdown = [];
    if (itemCategorySubCat && itemCategorySubCat.sub_category && itemCategorySubCat.sub_category.length>0){
        subCategoryDropdown =
            itemCategorySubCat.sub_category.map((head, index) => {
                return ({
                    'label': head.sub_categories_name, 'value': Number(head.id)
                })
            })
    }

    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 0) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }

    const [itemData, setItemData] = useState(null);
    const [itemWiseType, setItemWiseType] = useState(null);
    const [itemWiseUnit, setItemWiseUnit] = useState(null);
    const itemDropdownHandel = (e) => {
        setItemData(e)
        dispatch(getCategorySubCategory({
            item_id:e
        }))
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setItemWiseType(res.data.data[0].item_types);
                setItemWiseUnit(res.data.data[0].item_unit);
            });
    }

    const [subCategoryData, setSubCategoryData] = useState([]);

    //CONTROL SUB CATEGORY
    const SubCategoryDataHandel = (e, itemID) => {
        setSubCategoryData(e);
    }

    const [spendArea, setSpendArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/spend-area/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setSpendArea(res.data.data);
            });
    }, []);

    let spendAreaDropdown =
        spendArea.map((type, index) => {
            return ({
                'label': type.titles, 'value': type.id
            })
        })
    const [budgeHeadData, setbudgeHeadData] = useState(null);
    const HandelBudgeHeadData = (e) => {
        setbudgeHeadData(e);
    }
    const [remarkData, setRemarkData] = useState('');
    const RemarkHandel = (e) => {
        setRemarkData(e.target.value)
    }
    const [localHead, setLocalHead] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/particulars-type/local-head/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setLocalHead(res.data.data);
            });
    }, []);

    let localHeadDropdown =
        localHead.map((type, index) => {
            return ({
                'label': type.name, 'value': type.slug
            })
        })

    const [localHeadData, setILocalHeadData] = useState(null);
    const LocalHeadDataHandel = (e) => {
        setILocalHeadData(e);
    }
    const [itemNatureData, setIItemNatureData] = useState(null);
    const ItemNatureDataHandel = (e) => {
        setIItemNatureData(e);
    }
    const [dateOfRequired, setDateOfRequired] = useState('');
    const RequiredDateHandel = (e) => {
        setDateOfRequired(e);
    }
    const [quantitiesData, setQuantitiesData] = useState('');
    const QuantityHandel = (e) => {
        setQuantitiesData(e.target.value)
    }
    const [unitePriceData, setUnitPriceData] = useState('');
    const UnitPriceHandel = (e) => {
        setUnitPriceData(e.target.value)
    }
    const [vatData, setVatData] = useState('');
    const VatHandel = (e) => {
        setVatData(e.target.value)
    }
    const [aitData, setAitData] = useState('');
    const AitHandel = (e) => {
        setAitData(e.target.value)
    }
    const PreRequisitionItemsInsert = (e) => {
        let validation = true;
        let validationMessage = '';

        if (aitData == '' || aitData == null){
            validation = false;
            validationMessage = 'Enter ait'
        }
        if (vatData == '' || vatData == null){
            validation = false;
            validationMessage = 'Enter vat'
        }
        if (unitePriceData == '' || unitePriceData == null){
            validation = false;
            validationMessage = "Enter unit price"
        }

        if (quantitiesData == '' || quantitiesData == null){
            validation = false;
            validationMessage = t('QuantityFillUp')
        }
        if (dateOfRequired == '' || dateOfRequired == null){
            validation = false;
            validationMessage = t('ChooseDate')
        }
        if (itemNatureData == '' || itemNatureData == null){
            validation = false;
            validationMessage = t('ChooseItemNature')
        }
        if (localHeadData == '' || localHeadData == null){
            validation = false;
            validationMessage = t('ChoosePurchaseFrom')
        }
        if (budgeHeadData == '' || budgeHeadData == null){
            validation = false;
            validationMessage = t('ChooseSpendArea')
        }

        if (subCategoryData == '' || subCategoryData == null){
            validation = false;
            validationMessage = t('SubCategoryFillUp')
        }

        if (itemData == '' || itemData == null){
            validation = false;
            validationMessage = t('ItemFillUp')
        }

        if (validation){
            const itemFormData = {
                purchase_requisition_id: poItemDetails.purchase_requisitions,
                items: itemData,
                quantities: quantitiesData,
                categories: Number(itemCategorySubCat.categories_id),
                sub_categories: subCategoryData,
                date_of_required: format(dateOfRequired, 'yyyy-MM-dd'),
                comments: remarkData,
                budget_head: budgeHeadData,
                is_head_or_local: localHeadData,
                item_nature : itemNatureData,
                po_item_id : afterApproveEditID,
                purchase_requisitions_item_id : poItemDetails.purchase_requisition_item,
                po_id : poItemDetails.po_id,
                unit_price : unitePriceData,
                vat : vatData,
                ait : aitData
            };
            dispatch(PoItemEditAfterApprove(itemFormData))
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: validationMessage,
                message: (t('ValidationError')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('AfterApprovedEdit')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setAfterApproveEditModel(false))
                                        dispatch(setAfterApproveEditID(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>

                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <div className=" mx-auto p-2 justify-items-center bg-indigo-100">
                                                    <section className="p-1">

                                                        <Grid columns={24}>
                                                            <Grid.Col span={12}>
                                                                <Select
                                                                    placeholder={t("SelectItem")}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={items}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    onSearchChange={ItemSearchHandel}
                                                                    onChange={e => {
                                                                        itemDropdownHandel(e)
                                                                    }}
                                                                    value={itemData}
                                                                    searchValue={itemSearchValue}
                                                                />
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <Select
                                                                    placeholder={t("SelectCategory")}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={categoryDropdown}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    value={Number(itemCategorySubCat.categories_id)}
                                                                />
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <Select
                                                                    placeholder={t("SelectSubCategory")}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={subCategoryDropdown}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    onChange={e => {
                                                                        SubCategoryDataHandel(e)
                                                                    }}
                                                                    value={subCategoryData}
                                                                />
                                                            </Grid.Col>
                                                        </Grid>
                                                        <Grid columns={24}>

                                                            <Grid.Col span={12}>

                                                                <Select
                                                                    placeholder={t("ChooseSpendArea")}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={spendAreaDropdown}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    onChange={HandelBudgeHeadData}
                                                                    value={budgeHeadData}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={12}>
                                                                <TextInput
                                                                    placeholder={t("Remark")}
                                                                    onChange={e => {
                                                                        RemarkHandel(e)
                                                                    }}
                                                                    value={remarkData ? remarkData : ''}
                                                                    withAsterisk
                                                                />
                                                            </Grid.Col>

                                                        </Grid>
                                                        <Grid columns={24}>

                                                            <Grid.Col span={8}>
                                                                <Select
                                                                    placeholder="Purchase From"
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={localHeadDropdown}
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    onChange={e => {LocalHeadDataHandel(e)}}
                                                                    value={localHeadData}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={4}>
                                                                <Select
                                                                    placeholder={t('ChooseItemNature')}
                                                                    searchable clearable
                                                                    allowDeselect
                                                                    nothingFound="No options"
                                                                    withAsterisk
                                                                    data={
                                                                        [
                                                                            {'label': 'Capex', 'value': 'Capex'},
                                                                            {'label': 'Opex', 'value': 'Opex'}
                                                                        ]
                                                                    }
                                                                    transition="pop-top-left"
                                                                    transitionDuration={80}
                                                                    transitionTimingFunction="ease"
                                                                    onChange={e => {ItemNatureDataHandel(e)}}
                                                                    value={itemNatureData}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={4}>
                                                                <DatePicker
                                                                    dropdownType="modal"
                                                                    className={' border border-1 border-gray-500  h-9 hover:border-gray-400'}
                                                                    placeholder={t("Date")}
                                                                    onChange={RequiredDateHandel}
                                                                    value={dateOfRequired}
                                                                    minDate={dayjs(new Date()).startOf('month').add(new Date().toLocaleString("en-US", { day : '2-digit'})-1, 'days').toDate()}
                                                                />
                                                            </Grid.Col>
                                                            <Grid.Col span={8}>
                                                                <TextInput
                                                                    onChange={e => {
                                                                        QuantityHandel(e)
                                                                    }}
                                                                    placeholder={t("Quantity")}
                                                                    value={quantitiesData ? quantitiesData : ''}
                                                                    withAsterisk
                                                                />
                                                            </Grid.Col>
                                                            {/*<Grid.Col span={4}>
                                                                <button type={'button'} onClick={(e) => PreRequisitionItemsInsert(e)} className="inline-flex w-full h-full justify-center items-center px-2 py-1 bg-indigo-700 text-white hover:bg-indigo-600 text-xs">
                                                                    {t("Update")}
                                                                </button>
                                                            </Grid.Col>*/}
                                                        </Grid>

                                                        <Grid columns={24}>

                                                            <Grid.Col span={4}>
                                                                <TextInput
                                                                    onChange={e => {
                                                                        UnitPriceHandel(e)
                                                                    }}
                                                                    placeholder={t("Unit Price")}
                                                                    value={unitePriceData ? unitePriceData : ''}
                                                                    withAsterisk
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={4}>
                                                                <TextInput
                                                                    onChange={e => {
                                                                        VatHandel(e)
                                                                    }}
                                                                    placeholder={t("Vat")}
                                                                    value={vatData ? vatData : ''}
                                                                    withAsterisk
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={4}>
                                                                <TextInput
                                                                    onChange={e => {
                                                                        AitHandel(e)
                                                                    }}
                                                                    placeholder={t("Ait")}
                                                                    value={aitData ? aitData : ''}
                                                                    withAsterisk
                                                                />
                                                            </Grid.Col>
                                                            <Grid.Col span={4}>

                                                            </Grid.Col>
                                                            <Grid.Col span={4}>

                                                            </Grid.Col>
                                                            <Grid.Col span={4}>
                                                                <button type={'button'} onClick={(e) => PreRequisitionItemsInsert(e)} className="inline-flex w-full h-full justify-center items-center px-2 py-1 bg-indigo-700 text-white hover:bg-indigo-600 text-xs">
                                                                    {t("Update")}
                                                                </button>
                                                            </Grid.Col>
                                                        </Grid>

                                                    </section>
                                                </div>

                                                <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">

    {
        poItemDetails &&
        <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4" id="pdf-id">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                    <div className="mt-4">
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderItemsInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>

                        {
                            (poItemDetails.vat_mode && poItemDetails.vat_mode == 'Including') &&
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SL')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('PRRef')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Particulars')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Project')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Brand')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Quantity')}</div>
                                    </th>

                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('UnitPrice')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('TotalAmount')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                    <tr className={"bg-gray-100"}
                                        key={1}>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                            {1}
                                        </td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">{poItemDetails.pr_number ? poItemDetails.pr_number : ''}</td>
                                        <td className="w-2/12 border-collapse border border-slate-400 text-center">{poItemDetails.items_name ? poItemDetails.items_name : ''}</td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                            {poItemDetails.item_unit_name ? poItemDetails.item_unit_name : ''}
                                        </td>
                                        <td className="w-2/12 border-collapse border border-slate-400 text-center">{poItemDetails.projects_name ? poItemDetails.projects_name : ''}</td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">{poItemDetails.brand ? poItemDetails.brand : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.quantities ? poItemDetails.quantities : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.price ? Number(poItemDetails.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>

                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.amount ? Number(poItemDetails.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                    </tr>

                                </tbody>
                            </table>
                        }

                        {
                            (poItemDetails.vat_mode && poItemDetails.vat_mode == 'Excluding') &&
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('SL')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('PRRef')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('Particulars')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('Project')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('Brand')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('Quantity')}</div>
                                    </th>

                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('UnitPrice')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('SubTotal')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" colSpan={3}>
                                        <div className="font-semibold text-center">{t('Vat')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" colSpan={3}>
                                        <div className="font-semibold text-center">{t('Ait')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('UnitTotal')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                                        <div className="font-semibold text-center">{t('TotalAmount')}</div>
                                    </th>
                                </tr>

                                <tr>

                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('%')}</div>
                                    </th>

                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Total')}</div>
                                    </th>

                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('%')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Total')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr className={"bg-gray-100"}
                                        key={1}>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                            {1}
                                        </td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">{poItemDetails.pr_number ? poItemDetails.pr_number : ''}</td>
                                        <td className="w-2/12 border-collapse border border-slate-400 text-center">{poItemDetails.items_name ? poItemDetails.items_name : ''}</td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                            {poItemDetails.item_unit_name ? poItemDetails.item_unit_name : ''}
                                        </td>
                                        <td className="w-2/12 border-collapse border border-slate-400 text-center">{poItemDetails.projects_name ? poItemDetails.projects_name : ''}</td>
                                        <td className="w-1/12 border-collapse border border-slate-400 text-center">{poItemDetails.brand ? poItemDetails.brand : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.quantities ? poItemDetails.quantities : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.unit_price ? Number(poItemDetails.unit_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.sub_total ? Number(poItemDetails.sub_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.vat ? poItemDetails.vat : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.unit_vat ? poItemDetails.unit_vat : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.total_vat_amount ? Number(poItemDetails.total_vat_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.ait ? poItemDetails.ait : ''}</td>
                                        <td className="w-1/12 text-center border-collapse border border-slate-400">{poItemDetails.unit_ait ? poItemDetails.unit_ait : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.total_ait_amount ? Number(poItemDetails.total_ait_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.price ? Number(poItemDetails.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>


                                        <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{poItemDetails.amount ? Number(poItemDetails.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>

                                    </tr>
                                </tbody>
                            </table>
                        }

                    </div>
                </div>
            </div>
        </div>
    }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>


                                            </ScrollArea>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

        </>
    )
}

export default AfterApproveEdit