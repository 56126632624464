import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiCloud, HiSearch, HiOutlineChartSquareBar
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, LoadingOverlay, Text, Tabs, Table, Anchor} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import {openConfirmModal} from "@mantine/modals";
import {
    setPrActivitiesReportFilterData,
    setFetching,
    setPage, getPrActivities
} from "../../../../store/report/reportSlice";
import {showNotification} from "@mantine/notifications";

const PAGE_SIZE = 20;

function PrActivitiesReport(){
    const {t, i18n} = useTranslation();
    const reportWithHeadTableHeight = localStorage.getItem('reportWithHeadTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);


    const [isDownload, setIsDownload] = useState(false);

    const prActivitiesReportFilterData = useSelector((state) => state.reportReducer.prActivitiesReportFilterData)
    const activitiesData = useSelector((state) => state.reportReducer.activitiesData)
    const activitiesCountRequisitionData = useSelector((state) => state.reportReducer.activitiesCountRequisitionData)
    const activitiesCountRequisitionItemData = useSelector((state) => state.reportReducer.activitiesCountRequisitionItemData)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)

    const CallPoReport = (page=1) => {
        if (prActivitiesReportFilterData.start_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start & end date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                date : prActivitiesReportFilterData.start_date,
            }

            dispatch(getPrActivities(param))
        }
    }

    const exportToCSV = () => {
        openConfirmModal({
            title: 'It may some times , sure ? ',
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToDownloadAllStockItem')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
                dispatch(setFetching(true))

                const param = {
                    date : prActivitiesReportFilterData.start_date,
                }
                 axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/pr-activities/generate-csv`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `text/html`,
                        "Content-Disposition": 'attachment; filename=data.csv',
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                    },
                    params : param
                })
                    .then(res => {
                        if (res.status === 200){
                            const  fileName = 'DailyBasisPr-'+format(new Date(), 'dd-MM-yyyy')+'.csv'
                            const href = `${process.env.REACT_APP_API_GATEWAY_URL}/report/pr-activities/download-csv`;
                            const anchorElement = document.createElement('a');
                            anchorElement.href = href;
                            anchorElement.download = fileName;
                            document.body.appendChild(anchorElement);
                            anchorElement.click();
                            setIsDownload(false)
                            dispatch(setFetching(false))
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PrActivities')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PrActivitiesReportRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {(activitiesData.activities && activitiesData.activities.length>0) &&
                                                                <>
                                                                <button
                                                                onClick={(e) => exportToCSV()}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={prActivitiesReportFilterData.start_date?prActivitiesReportFilterData.start_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPrActivitiesReportFilterData({
                                                                                        ...prActivitiesReportFilterData,
                                                                                        ['start_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPrActivitiesReportFilterData({
                                                                                        ...prActivitiesReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>


                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <button
                                                                            onClick={(e) => {
                                                                                CallPoReport()
                                                                            }}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={fetching}
                                                                    overlayBlur={2}
                                                                />



                                                                    <Tabs variant="pills" radius="xl" defaultValue="pr-activities">
                                                                        <Tabs.List>
                                                                            <Tabs.Tab value="pr-activities" >Purchase Requisition Activities</Tabs.Tab>
                                                                            <Tabs.Tab value="item-activities" >Item Activities</Tabs.Tab>
                                                                            <Tabs.Tab value="resolve-activities" >Resolved Activities</Tabs.Tab>
                                                                        </Tabs.List>

                                                                        <Tabs.Panel value="pr-activities" pt="xs">
                                                                            <Table>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>Activities</th>
                                                                                    <th>Total</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr key="pr-activities-1">
                                                                                        <td>Total Requisition :	</td>
                                                                                        <td>
                                                                                            {/*{
                                                                                                activitiesCountRequisitionData.totalRequisition ?
                                                                                                    <Link to='/requisition' target={"_blank"} state={{name:'zayne'}}>
                                                                                                        {activitiesCountRequisitionData.totalRequisition}
                                                                                                    </Link>
                                                                                                    <Anchor href="" target="_blank">
                                                                                                        {activitiesCountRequisitionData.totalRequisition}
                                                                                                    </Anchor>
                                                                                                    : 0
                                                                                            }*/}
                                                                                            {activitiesCountRequisitionData.totalRequisition?activitiesCountRequisitionData.totalRequisition:0}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-2">
                                                                                        <td>Total Requisition Open :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionOpen?activitiesCountRequisitionData.totalRequisitionOpen:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-3">
                                                                                        <td>Total Requisition Approved :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionApproved?activitiesCountRequisitionData.totalRequisitionApproved:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-4">
                                                                                        <td>Total Requisition Resolved :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionResolved?activitiesCountRequisitionData.totalRequisitionResolved:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-5">
                                                                                        <td>Total Requisition Canceled :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionCanceled?activitiesCountRequisitionData.totalRequisitionCanceled:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-6">
                                                                                        <td>Total Requisition Work in Progress :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionInProgress?activitiesCountRequisitionData.totalRequisitionInProgress:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-7">
                                                                                        <td>Total Requisition Hold :	</td>
                                                                                        <td>{activitiesCountRequisitionData.totalRequisitionHold?activitiesCountRequisitionData.totalRequisitionHold:0}</td>
                                                                                    </tr>
                                                                                    <tr key="pr-activities-8">
                                                                                        <td>Average Resolved Date :		</td>
                                                                                        <td>{activitiesCountRequisitionData.averageDate?activitiesCountRequisitionData.averageDate:0}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </Tabs.Panel>

                                                                        <Tabs.Panel value="item-activities" pt="xs">
                                                                            <Table>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>Activities</th>
                                                                                    <th>Total</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr key="pr-activities-1">
                                                                                    <td>Total Requisition Item  :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItem?activitiesCountRequisitionItemData.totalRequisitionItem:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-2">
                                                                                    <td>Total Requisition Item Open :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemOpen?activitiesCountRequisitionItemData.totalRequisitionItemOpen:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-3">
                                                                                    <td>Total Requisition Item Approved :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemApproved?activitiesCountRequisitionItemData.totalRequisitionItemApproved:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-4">
                                                                                    <td>Total Requisition Item Resolved :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemResolved?activitiesCountRequisitionItemData.totalRequisitionItemResolved:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-5">
                                                                                    <td>Total Requisition Item Canceled :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemCanceled?activitiesCountRequisitionItemData.totalRequisitionItemCanceled:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-6">
                                                                                    <td>Total Requisition Item in Progress :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemInProgress?activitiesCountRequisitionItemData.totalRequisitionItemInProgress:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-7">
                                                                                    <td>Total Requisition Item Hold :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemHold?activitiesCountRequisitionItemData.totalRequisitionItemHold:0}</td>
                                                                                </tr>
                                                                                <tr key="pr-activities-8">
                                                                                    <td>Total Requisition Item Closed :	</td>
                                                                                    <td>{activitiesCountRequisitionItemData.totalRequisitionItemCanceledIndividual?activitiesCountRequisitionItemData.totalRequisitionItemCanceledIndividual:0}</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </Tabs.Panel>

                                                                        <Tabs.Panel value="resolve-activities" pt="xs">
                                                                            <Box sx={{height: reportWithHeadTableHeight,width: containerWidth}} className="mt-3">
                                                                            <DataTable
                                                                                withBorder
                                                                                records={activitiesData.activities}
                                                                                withColumnBorders={1}
                                                                                highlightOnHover
                                                                                columns={[
                                                                                    {
                                                                                        accessor: 'index',
                                                                                        title: 'SL#',
                                                                                        // textAlignment: 'right',
                                                                                        render: (item) => (activitiesData.activities.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                    },
                                                                                    {accessor: 'prId', title: 'PR No',},
                                                                                    {accessor: 'claimAverageDates', title: 'Average Claim Days',},
                                                                                    {accessor: 'prAverageDates', title: 'Average PO Issued Days',},
                                                                                    {accessor: 'project', title: 'Project',},
                                                                                    {accessor: 'sub_category', title: 'Sub Category',}
                                                                                ]}

                                                                                totalRecords={totalItem}
                                                                                recordsPerPage={PAGE_SIZE}
                                                                                page={page}
                                                                                onPageChange={(p) => {
                                                                                    dispatch(setPage(p))
                                                                                    CallPoReport(p)
                                                                                }}
                                                                                fetching={fetching}
                                                                                loaderSize="xs"
                                                                                loadercolor="blue"
                                                                                loaderBackgroundBlur={1}
                                                                            />
                                                                            </Box>

                                                                        </Tabs.Panel>
                                                                    </Tabs>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PrActivitiesReport