import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {
    getBudgetSummaryReportData,
    getPendingClaimData,
    getPoReportData,
    getPoWiseBudgetReportData,
    getPrActivitiesData,
    getPrDailyBasisReportData,
    getPriceDirectoryReportData,
    getReceiveItemReportData,
    getReceiveItemReportDownloadData
} from "../../services/ReportService";

export const getPoReport = createAsyncThunk("report/all-po-report", async (param) => {
    try {
        const response = getPoReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getReceiveItemReport = createAsyncThunk("report/receive-item-data", async (param) => {
    try {
        const response = getReceiveItemReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getReceiveItemReportDownload = createAsyncThunk("report/receive-item-data/download", async (param) => {
    try {
        const response = getReceiveItemReportDownloadData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPoReportAll = createAsyncThunk("report/all-po-report-download", async (param) => {
    try {
        const response = getPoReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});



export const getDailyBasisPr = createAsyncThunk("report/daily-basis-pr", async (param) => {
    try {
        const response = getPrDailyBasisReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPrActivities = createAsyncThunk("report/pr-activities-report", async (param) => {
    try {
        const response = getPrActivitiesData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const getPoWiseBudgetReport = createAsyncThunk("report/po-wise-budget-data", async (param) => {
    try {
        const response = getPoWiseBudgetReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});



export const getPendingClaim = createAsyncThunk("report/pending-claim-report", async (param) => {
    try {
        const response = getPendingClaimData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const getBudgetSummary = createAsyncThunk("report/budget-summary-report", async (param) => {
    try {
        const response = getBudgetSummaryReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPriceDirectoryReport = createAsyncThunk("report/price-directory-report", async (param) => {
    try {
        const response = getPriceDirectoryReportData(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const reportSlice = createSlice({
    name : "Report",
    initialState : {
        fetching : false,
        page : 1,
        totalItem : 0,
        poReportFilterData : {project:'',vendor:'',start_date:new Date(),end_date:new Date()},
        receiveItemReportFilterData : {project:null,vendor:null,start_date:null,end_date:null,po:null,item:null},
        prDailyBasisReportFilterData : {project:null,start_date:null,end_date:null,sub_category:null},
        prActivitiesReportFilterData : {start_date:null},
        pendingClaimReportFilterData : {start_date:null},
        poWiseBudgetReportFilterData : {project:null,start_date:null,end_date:null,budget_number:null},
        budgetSummaryReportFilterData : {project:null,start_date:null,end_date:null,budget_number:null},
        priceDirectoryReportFilterData : {project:'',item:'',start_date:new Date(),end_date:new Date(),item_type:''},
        poReportReportData : [],
        poReportDownloadData : [],
        receiveItemReportData : [],
        receiveItemReportDataDownload : [],
        dailyBasisPrData : [],
        activitiesData : [],
        activitiesCountRequisitionData : [],
        activitiesCountRequisitionItemData : [],
        poWiseBudgetData : [],
        pendingClaimData : [],
        budgetSummary : [],
        priceDirectoryReportData : []

    },
    reducers : {
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setTotalItem : (state,action) => {
            state.totalItem = action.payload
        },
        setPoWiseBudgetDataEmpty : (state,action) => {
            state.poWiseBudgetData = []
        },
        setPoWiseBudgetReportFilterData : (state,action) => {
            state.poWiseBudgetReportFilterData.start_date = action.payload.start_date;
            state.poWiseBudgetReportFilterData.end_date = action.payload.end_date;
            state.poWiseBudgetReportFilterData.project = action.payload.project;
            state.poWiseBudgetReportFilterData.budget_number = action.payload.budget_number;
        },
        setBudgetSummaryReportFilterData : (state,action) => {
            state.budgetSummaryReportFilterData.start_date = action.payload.start_date;
            state.budgetSummaryReportFilterData.end_date = action.payload.end_date;
            state.budgetSummaryReportFilterData.project = action.payload.project;
            state.budgetSummaryReportFilterData.budget_number = action.payload.budget_number;
        },
        setPrDailyBasisReportFilterData : (state,action) => {
            state.prDailyBasisReportFilterData.start_date = action.payload.start_date;
            state.prDailyBasisReportFilterData.end_date = action.payload.end_date;
            state.prDailyBasisReportFilterData.project = action.payload.project;
            state.prDailyBasisReportFilterData.sub_category = action.payload.sub_category;
            state.totalItem = 0;
            state.page = 1;
        },
        setPrActivitiesReportFilterData : (state,action) => {
            state.prActivitiesReportFilterData.start_date = action.payload.start_date;
            state.totalItem = 0;
            state.page = 1;
        },
        setPendingClaimReportFilterData : (state,action) => {
            state.pendingClaimReportFilterData.start_date = action.payload.start_date;
            state.totalItem = 0;
            state.page = 1;
        },
        setPoReportFilterData : (state,action) => {
            state.poReportFilterData.start_date = action.payload.start_date;
            state.poReportFilterData.end_date = action.payload.end_date;
            state.poReportFilterData.project = action.payload.project;
            state.poReportFilterData.vendor = action.payload.vendor;
            state.totalItem = 0;
            state.page = 1;
        },
        setPriceDirectoryReportFilterData : (state,action) => {
            state.priceDirectoryReportFilterData.start_date = action.payload.start_date;
            state.priceDirectoryReportFilterData.end_date = action.payload.end_date;
            state.priceDirectoryReportFilterData.project = action.payload.project;
            state.priceDirectoryReportFilterData.item = action.payload.item;
            state.priceDirectoryReportFilterData.item_type = action.payload.item_type;
            state.totalItem = 0;
            state.page = 1;
        },
        setReceiveItemReportFilterData : (state,action) => {
            state.receiveItemReportFilterData.start_date = action.payload.start_date;
            state.receiveItemReportFilterData.end_date = action.payload.end_date;
            state.receiveItemReportFilterData.project = action.payload.project;
            state.receiveItemReportFilterData.vendor = action.payload.vendor;
            state.receiveItemReportFilterData.po = action.payload.po;
            state.receiveItemReportFilterData.item = action.payload.item;
            state.totalItem = 0;
            state.page = 1;
        },
        },


    extraReducers : (builder) => {
        builder.addCase(getPoReport.fulfilled,(state,action) => {
            state.poReportReportData = action.payload.data
            // state.poReportDownloadData = action.payload.downloadData
            state.totalItem = action.payload.total
            state.fetching = false
        })
        builder.addCase(getReceiveItemReport.fulfilled,(state,action) => {
            state.receiveItemReportData = action.payload
            state.totalItem = action.payload.total
            state.fetching = false
        })
        builder.addCase(getPoReportAll.fulfilled,(state,action) => {
            state.poReportDownloadData = action.payload.data
        })
        builder.addCase(getReceiveItemReportDownload.fulfilled,(state,action) => {
            state.receiveItemReportDataDownload = action.payload
        })
        builder.addCase(getDailyBasisPr.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.dailyBasisPrData = action.payload.data
            state.fetching = false
        })
        builder.addCase(getPrActivities.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.activitiesData = action.payload.data
            state.activitiesCountRequisitionData = action.payload.data.totalCalculate.Requisition
            state.activitiesCountRequisitionItemData = action.payload.data.totalCalculate.RequisitionItem
            state.fetching = false
        })
        builder.addCase(getPoWiseBudgetReport.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.poWiseBudgetData = action.payload.data
            state.fetching = false
        })
        builder.addCase(getPendingClaim.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.pendingClaimData = action.payload.data
            state.fetching = false
        })
        builder.addCase(getBudgetSummary.fulfilled,(state,action) => {
            state.totalItem = action.payload.total
            state.budgetSummary = action.payload.data
            state.fetching = false
        })

        builder.addCase(getPriceDirectoryReport.fulfilled,(state,action) => {
            state.priceDirectoryReportData = action.payload.data
            state.totalItem = action.payload.total
            state.fetching = false
        })
    }
})

export const {setFetching,setPage,setPoReportFilterData,setReceiveItemReportFilterData,setPrDailyBasisReportFilterData,setPrActivitiesReportFilterData,setPoWiseBudgetReportFilterData,setPoWiseBudgetDataEmpty,setTotalItem,setPendingClaimReportFilterData,setBudgetSummaryReportFilterData,setPriceDirectoryReportFilterData} = reportSlice.actions
export default reportSlice.reducer;