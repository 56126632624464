import React, {Fragment, useEffect, useState} from 'react'
import {useRef} from 'react';

import {useTranslation} from "react-i18next";
import {ActionIcon, Loader, LoadingOverlay, ScrollArea, Text, Textarea} from "@mantine/core";
import {
    HiArrowSmRight,
    HiOutlineX, HiPencil
} from "react-icons/hi";
import {
    getPurchaseOrderDetails,
    poApproveInfomation,
    poCommentCreate,
    poLastPriceInfomation,
    setPoComment,
    setPoViewID,
    setPoViewModel,
    setPoCommentstatus,
    poApprove,
    setFetching,
    poProcessUpdate,
    setAfterApproveEditID,
    setAfterApproveEditModel,
    poAmendment,
    setEmptyPODetails
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {FiSave} from "react-icons/fi";
import jwt_decode from "jwt-decode";
import {openConfirmModal} from "@mantine/modals";
import AfterApproveEdit from "./AfterApproveEdit";
import {useNavigate} from "react-router";
import axios from "axios";

function PoViewModel() {
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [commenting, setCommenting] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);
    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_TWO";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_THREE";
    })
    const ROLE_PURCHASE_ORDER_ADD = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_ADD";
    })
    const ROLE_PURCHASE_ORDER_VIEW = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_VIEW";
    })
    const ROLE_PURCHASE_ORDER_EDIT = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_EDIT";
    })
    const ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_PURCHASE_ORDER_AMENDMENT = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_AMENDMENT";
    })


    const poViewID = useSelector((state) => state.purchaseOrderReducer.poViewID)
    const purchaseOrderDetails = useSelector((state) => state.purchaseOrderReducer.purchaseOrderDetails)
    const purchaseOrderItem = useSelector((state) => state.purchaseOrderReducer.purchaseOrderItem)
    const purchaseOrderTermConditions = useSelector((state) => state.purchaseOrderReducer.purchaseOrderTermConditions)
    const poApproveInfo = useSelector((state) => state.purchaseOrderReducer.poApproveInfo)
    const poLastPriceLowestPriceInfo = useSelector((state) => state.purchaseOrderReducer.poLastPriceInfo)
    const poComment = useSelector((state) => state.purchaseOrderReducer.poComment)
    const poCommentstatus = useSelector((state) => state.purchaseOrderReducer.poCommentstatus)
    const fetching = useSelector((state) => state.purchaseOrderReducer.fetching)
    const afterApproveEditModel = useSelector((state) => state.purchaseOrderReducer.afterApproveEditModel)
    const poAmendmentMsg = useSelector((state) => state.purchaseOrderReducer.poAmendmentMsg)
    const poAmendmentId = useSelector((state) => state.purchaseOrderReducer.poAmendmentId)

    console.log(poViewID)

    useEffect((e) => {
        setTimeout(() => {
            console.log('ok')
            dispatch(
                getPurchaseOrderDetails({
                    po_id: poViewID
                })
            )
            dispatch(poApproveInfomation({
                po_id: poViewID
            }))
            dispatch(poLastPriceInfomation({
                po_id: poViewID
            }))
            setLoading(false)
            dispatch(setPoCommentstatus(false))
            setCommenting(false)
            dispatch(setFetching(false))
        }, 500)
    }, [poCommentstatus, fetching,afterApproveEditModel])


    const poApproved = (e, poID, status) => {
        e.preventDefault();
        let titleMsg = ''
        let titleMsgDetails = ''
        if (status == 'Verified') {
            titleMsg = t('ValidatedThisPO')
            titleMsgDetails = t('ValidatedThisPOThisPODetails')
        }
        if (status == 'Validated') {
            titleMsg = t('ApproveThisPO')
            titleMsgDetails = t('ApproveThisPODetails')
        }
        if (status == 'Created'  || status == 'Amendment') {
            status = 'Created'
            titleMsg = t('VerifiedThisPO')
            titleMsgDetails = t('VerifiedThisPODetails')
        }

        openConfirmModal({
            title: titleMsg,
            centered: true,
            children: (
                <Text size="sm">
                    {titleMsgDetails}
                </Text>
            ),
            labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
            confirmProps: {color: 'green'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                const approveData = {
                    po_id: poID,
                    status: status
                }
                // console.log(approveData)/
                dispatch(poApprove(approveData))
                dispatch(setFetching(true))
            }
            ,
        });
    }

    if (poAmendmentId && poAmendmentMsg == 'created'){
        dispatch(setEmptyPODetails())
        dispatch(setPoViewModel(false))
        dispatch(setPoViewID(null))
        navigate('/purchase-order/amendment/'+poAmendmentId)
    }

    let totalAmount = 0;
    let subTotalAmount = 0;
    let totalVatAmount = 0;
    let totalAitAmount = 0;


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PurchaseOrderDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setPoViewModel(false))
                                        dispatch(setPoViewID(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative" >

                                                                {
                                                                    purchaseOrderDetails &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">

                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                purchaseOrderDetails.purchase_order_id &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('OrderNo')} : </span>
                                                                                                        {purchaseOrderDetails.purchase_order_id}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.process_mode &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ProcessMode')} : </span>
                                                                                                        {purchaseOrderDetails.process_mode}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.vat_mode &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('VatMode')} : </span>
                                                                                                        {purchaseOrderDetails.vat_mode}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.purchase_type_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('PurchaseType')} : </span>
                                                                                                        {purchaseOrderDetails.purchase_type_name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.created_date &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('IssueDate')} : </span>
                                                                                                        {purchaseOrderDetails.created_date}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }{
                                                                                            purchaseOrderDetails.ref_no &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('RefNo')} : </span>
                                                                                                    {purchaseOrderDetails.ref_no}
                                                                                                </p>
                                                                                            </li>
                                                                                        }{
                                                                                            purchaseOrderDetails.date_of_delivered &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('DateOfDelivery')} : </span>
                                                                                                    {purchaseOrderDetails.date_of_delivered}
                                                                                                </p>
                                                                                            </li>
                                                                                        }{
                                                                                            purchaseOrderDetails.payment_type &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('PaymentType')} : </span>
                                                                                                    {purchaseOrderDetails.payment_type}
                                                                                                </p>
                                                                                            </li>
                                                                                        }{
                                                                                            purchaseOrderDetails.payment_from &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('PaymentFrom')} : </span>
                                                                                                    {purchaseOrderDetails.payment_from == 1 ? 'Local Office' : 'Head Office'}
                                                                                                </p>
                                                                                            </li>
                                                                                        }{
                                                                                            purchaseOrderDetails.payment_method &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('PaymentMethod')} : </span>
                                                                                                    {purchaseOrderDetails.payment_method}
                                                                                                </p>
                                                                                            </li>
                                                                                        }{
                                                                                            purchaseOrderDetails.computation_status &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('CompetitionStatus')} : </span>
                                                                                                    {purchaseOrderDetails.computation_status == 1 ? 'Yes' : 'No'}
                                                                                                </p>
                                                                                            </li>
                                                                                        }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {
                                                                    purchaseOrderDetails && purchaseOrderDetails.vendors &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                purchaseOrderDetails.vendors_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Name')} : </span>
                                                                                                        {purchaseOrderDetails.vendor_info ? purchaseOrderDetails.vendor_info.name : ''}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.vendors_address &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Address')} : </span>
                                                                                                        {purchaseOrderDetails.vendor_info ? purchaseOrderDetails.vendor_info.address : ''}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.contract_person &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('ContactPerson')} : </span>
                                                                                                        {purchaseOrderDetails.vendor_info ? purchaseOrderDetails.vendor_info.contact_person : ''}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.vendor_email &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Email')} : </span>
                                                                                                        {purchaseOrderDetails.vendor_info ? purchaseOrderDetails.vendor_info.email : ''}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }{
                                                                                            purchaseOrderDetails.vendor_quotation_reference_number &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">
                                                                                                    <span
                                                                                                        className="font-bold">{t('VendorQuotationRef')} : </span>
                                                                                                    {purchaseOrderDetails.vendor_quotation_reference_number}
                                                                                                </p>
                                                                                            </li>
                                                                                        }
    {
        purchaseOrderDetails.path &&
            <>
                {purchaseOrderDetails.vendor_quotation_reference_number == null &&
                <li className="flex items-start lg:col-span-1"></li>
                }

        <li className="flex items-start lg:col-span-1">
            <p className="ml-3 text-sm text-gray-700">
                <span className="font-bold">{t('VendorQuotationAttach')} : </span>
                {
                        <a title={t('VendorQuotationAttach')}
                           target="_blank"
                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ poViewID}/vendor-quotation`}
                           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{purchaseOrderDetails.path.split("/").pop()}</a>

                }
            </p>
        </li>
            </>
    }
    {
        purchaseOrderDetails.path_two &&
        <li className="flex items-start lg:col-span-1">
            <p className="ml-3 text-sm text-gray-700">
                <span className="font-bold">{t('ComparisonAttach')} : </span>
                {
                        <a title={t('ComparisonAttach')} target="_blank"
                           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ poViewID}/comparison`}
                           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{purchaseOrderDetails.path_two.split("/").pop()}</a>
                }
            </p>
        </li>
    }
                                </ul>
                            </>
                            </div>
                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    purchaseOrderDetails && purchaseOrderDetails.buyers &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">

                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BuyerInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            {
                                                                                                purchaseOrderDetails.buyer_full_name &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Name')} : </span>
                                                                                                        {purchaseOrderDetails.buyer_full_name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.buyer_email &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Email')} : </span>
                                                                                                        {purchaseOrderDetails.buyer_email}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.buyer_cellphone &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span
                                                                                                            className="font-bold">{t('Phone')} : </span>
                                                                                                        {purchaseOrderDetails.buyer_cellphone}
                                                                                                    </p>
                                                                                                </li>
                                                                                            }

                                                                                            {
                                                                                                purchaseOrderDetails.path &&
                                                                                                <>
                                                                                                    {purchaseOrderDetails.vendor_quotation_reference_number == null &&
                                                                                                        <li className="flex items-start lg:col-span-1"></li>
                                                                                                    }

                                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                                        <p className="ml-3 text-sm text-gray-700">
                                                                                                            <span className="font-bold">{t('VendorQuotationAttach')} : </span>
                                                                                                            {
                                                                                                                purchaseOrderDetails.is_old == 1 ?
                                                                                                                    <a title={t('VendorQuotationAttach')}
                                                                                                                       target="_blank"
                                                                                                                       href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/old/purchaseOrder/${purchaseOrderDetails.path}`}
                                                                                                                       className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{purchaseOrderDetails.path}</a>
                                                                                                                    :
                                                                                                                    <a title={t('VendorQuotationAttach')}
                                                                                                                       target="_blank"
                                                                                                                       href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/vendor-quotation/${purchaseOrderDetails.path}`}
                                                                                                                       className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                                                                        {purchaseOrderDetails.path}
                                                                                                                    </a>
                                                                                                            }
                                                                                                        </p>
                                                                                                    </li>
                                                                                                </>
                                                                                            }
                                                                                            {
                                                                                                purchaseOrderDetails.path_two &&
                                                                                                <li className="flex items-start lg:col-span-1">
                                                                                                    <p className="ml-3 text-sm text-gray-700">
                                                                                                        <span className="font-bold">{t('ComparisonAttach')} : </span>
                                                                                                        {
                                                                                                            purchaseOrderDetails.is_old == 1 ?
                                                                                                                <a title={t('ComparisonAttach')} target="_blank"
                                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/old/purchaseOrder/${purchaseOrderDetails.path_two}`}
                                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{purchaseOrderDetails.path_two}</a>
                                                                                                                :
                                                                                                                <a title={t('ComparisonAttach')} target="_blank"
                                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/vendor-quotation/${purchaseOrderDetails.path_two}`}
                                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">{purchaseOrderDetails.path_two}</a>
                                                                                                        }
                                                                                                    </p>
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    (poApproveInfo && poApproveInfo.created_by) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ApproveInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ApproveType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Name')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Date')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                        <tr className={"bg-gray-100"} key='Created'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">1</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">Created</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.created_user_name ? poApproveInfo.created_user_name : poApproveInfo.created_email}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.created_date ? poApproveInfo.created_date : ''}</td>
                                                                                        </tr>

                                                                                        <tr className={"bg-gray-100"} key='Verified'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">2</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">Verified</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.one_user_name ? poApproveInfo.one_user_name : poApproveInfo.one_email}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_one_date ? poApproveInfo.approved_one_date : ''}</td>
                                                                                        </tr>

                                                                                        <tr className={"bg-gray-100"} key='Validated'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">3</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">Validated</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.two_user_name ? poApproveInfo.two_user_name :  poApproveInfo.two_email}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_two_date ? poApproveInfo.approved_two_date : ''}</td>
                                                                                        </tr>

                                                                                        <tr className={"bg-gray-100"} key='Approved'>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">4</td>
                                                                                            <td className="w-1/12 border-collapse border border-slate-400 text-center">Approved</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.three_user_name ? poApproveInfo.three_user_name : poApproveInfo.three_email}</td>
                                                                                            <td className="w-2/12 border-collapse border border-slate-400 text-center">{poApproveInfo.approved_three_date ? poApproveInfo.approved_three_date : ''}</td>
                                                                                        </tr>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length > 0) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4" id="pdf-id">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderItemsInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>


        {
            (purchaseOrderDetails.vat_mode ) &&
            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                <thead className="text-xs font-semibold  text-black bg-gray-200">
                <tr>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('SL')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('PRRef')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('Particulars')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('Unit')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('Project')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('Brand')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('Quantity')}</div>
                    </th>

                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('UnitPrice')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('SubTotal')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" colSpan={3}>
                        <div className="font-semibold text-center">{t('Vat')}</div>
                    </th>
                    {/*<th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('VatAmount')}</div>
                    </th>*/}
                    <th className="p-2 border-collapse border border-slate-400" colSpan={3}>
                        <div className="font-semibold text-center">{t('Ait')}</div>
                    </th>
                    {/*<th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('AitAmount')}</div>
                    </th>*/}
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('UnitTotal')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center">{t('TotalAmount')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400" rowSpan={2}>
                        <div className="font-semibold text-center"></div>
                    </th>
                </tr>

                <tr>

                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('%')}</div>
                    </th>

                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Unit')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Total')}</div>
                    </th>

                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('%')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Unit')}</div>
                    </th>
                    <th className="p-2 border-collapse border border-slate-400">
                        <div className="font-semibold text-center">{t('Total')}</div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (purchaseOrderDetails && purchaseOrderItem && purchaseOrderItem.length > 0) && purchaseOrderItem.map((orderItem, index) => {
                        totalAmount = (totalAmount + Number(orderItem.amount))
                        subTotalAmount = (subTotalAmount + Number(orderItem.sub_total))
                        totalAitAmount = (totalAitAmount + Number(orderItem.total_ait_amount))
                        totalVatAmount = (totalVatAmount + Number(orderItem.total_vat_amount))
                        return (
                            <tr className={"bg-gray-100"}
                                key={index}>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                    {++index}
                                </td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.pr_number ? orderItem.pr_number : ''}</td>
                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{orderItem.items_name ? orderItem.items_name : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">
                                    {orderItem.item_unit_name ? orderItem.item_unit_name : ''}
                                </td>
                                <td className="w-2/12 border-collapse border border-slate-400 text-center">{orderItem.projects_name ? orderItem.projects_name : ''}</td>
                                <td className="w-1/12 border-collapse border border-slate-400 text-center">{orderItem.brand ? orderItem.brand : ''}</td>
                                <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.quantities ? orderItem.quantities : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.unit_price ? Number(orderItem.unit_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.sub_total ? Number(orderItem.sub_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.vat ? orderItem.vat : ''}</td>
                                <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.unit_vat ? Number(orderItem.unit_vat).toFixed(2) : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.total_vat_amount ? Number(orderItem.total_vat_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.ait ? orderItem.ait : ''}</td>
                                <td className="w-1/12 text-center border-collapse border border-slate-400">{orderItem.unit_ait ? Number(orderItem.unit_ait).toFixed(2) : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.total_ait_amount ? Number(orderItem.total_ait_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.price ? Number(orderItem.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>


                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">{orderItem.amount ? Number(orderItem.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                                <td className="w-1/12 text-right p-2 border-collapse border border-slate-400">
                                    {
                                        (orderItem.total==1 && orderItem.approval_status==3 && orderItem.status != 2 && orderItem.total_order_receive_quantity==null && (ROLE_PURCHASE_REQUISITION_EDIT_AFTER_APPROVE || ROLE_SUPER_ADMIN)) &&
                                        <ActionIcon color="green" onClick={(e) => {
                                            dispatch(setAfterApproveEditModel(true))
                                            dispatch(setAfterApproveEditID(orderItem.id))
                                        }}>
                                            <HiPencil size={16}/>
                                        </ActionIcon>
                                    }
                                </td>
                            </tr>
                        );
                    })}


                <tr>
                    <td colSpan="8" className="font-bold border-collapse border border-slate-400 text-right pr-5">{t('Total')}</td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{subTotalAmount ? subTotalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalVatAmount ? totalVatAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAitAmount ? totalAitAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400"></td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAmount ? totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>

                </tr>
                <tr>
                    <td colSpan="16"
                        className="font-bold border-collapse border border-slate-400 text-right pr-5">{t('POAmount')}</td>
                    <td className="text-right p-2 font-bold border-collapse border border-slate-400">{totalAmount ? totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</td>
                </tr>
                {
                    (purchaseOrderDetails.payment_type=='advance-payment' && purchaseOrderDetails.advance_payment_amount>0) &&
                    <tr>
                        <td colSpan="16"
                            className="font-bold border-collapse border border-slate-400 text-right pr-5">{t('AdvancePayment')}</td>
                        <td className="text-right p-2 font-bold border-collapse border border-slate-400">{purchaseOrderDetails.advance_payment_amount ? purchaseOrderDetails.advance_payment_amount:''}</td>
                    </tr>

                }


                </tbody>
            </table>
        }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {(purchaseOrderDetails && poLastPriceLowestPriceInfo && poLastPriceLowestPriceInfo.length > 0) &&
                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderLastLowestPriceInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table
                                                                                    className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                                                    <thead
                                                                                        className="text-xs font-semibold  text-black bg-gray-200">
                                                                                    <tr>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('SL')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('ItemName')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LastRefNoProject')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LastPurchaseType')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LastPrice')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LowestRefNoProject')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LowestPurchaseType')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LowestPrice')}</div>
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                        (purchaseOrderDetails && poLastPriceLowestPriceInfo && poLastPriceLowestPriceInfo.length > 0) &&
                                                                                        poLastPriceLowestPriceInfo.map((lastlowest, index) => {
                                                                                            return (
                                                                                                <tr className={"bg-gray-100"}
                                                                                                    key={index}>
                                                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                        {++index}
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{lastlowest.items_name ? lastlowest.items_name : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{lastlowest.last_ref_no || lastlowest.last_project ? lastlowest.last_ref_no + ' / ' + lastlowest.last_project : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{lastlowest.last_purchase_type ? lastlowest.last_purchase_type : ''}</td>
                                                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{lastlowest.last_price ? lastlowest.last_price : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{lastlowest.lowest_ref_no || lastlowest.lowest_project ? lastlowest.lowest_ref_no + ' / ' + lastlowest.lowest_project : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{lastlowest.lowest_purchase_type ? lastlowest.lowest_purchase_type : ''}</td>
                                                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{lastlowest.lowest_price || lastlowest.lowest_price >= 0 ? lastlowest.lowest_price : ''}</td>

                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }

                                                                {
                                                                    (purchaseOrderDetails && purchaseOrderDetails.comments) &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('Comment&EditInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <ul role="list" className="mt-5 ">
                                                                                        {
                                                                                            purchaseOrderDetails.comments &&
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700 text-justify">
                                                                                                    <span
                                                                                                        className="font-bold">{t('Remark')} : </span>
                                                                                                    {purchaseOrderDetails.comments}
                                                                                                </p>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            purchaseOrderDetails.edit_reason &&
                                                                                            <li className="flex items-start lg:col-span-1 mt-5">
                                                                                                <p className="ml-3 text-sm text-gray-700 text-justify">
                                                                                                    <span
                                                                                                        className="font-bold">{t('EditReason')} : </span>
                                                                                                    {purchaseOrderDetails.edit_reason}
                                                                                                </p>
                                                                                            </li>
                                                                                        }
                                                                                    </ul>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    (purchaseOrderDetails && purchaseOrderTermConditions) &&
                                                                    <div
                                                                        className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div
                                                                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div
                                                                                className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('TermsConditionsInformation')}</h4>
                                                                                        <div
                                                                                            className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>
                                                                                    <table
                                                                                        className="table-full w-full mt-5">
                                                                                        <tbody>
                                                                                        {
                                                                                            (purchaseOrderDetails && purchaseOrderTermConditions && purchaseOrderTermConditions.length > 0) && purchaseOrderTermConditions.map((term, index) => {
                                                                                                return (
                                                                                                    <tr className={"border"}
                                                                                                        key={index}>
                                                                                                        <td className="w-1/12 bg-green-50 px-6 py-1">
                                                                                                            {term.conditions}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('CommentsInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    commenting ?
                                                                                        <>
                                                                                            <div
                                                                                                className="grid place-items-center">
                                                                                                <Loader size="sm"
                                                                                                        color={'green'}/>
                                                                                            </div>

                                                                                        </>
                                                                                        :
                                                                                        (purchaseOrderDetails && purchaseOrderDetails['po_comments'] && purchaseOrderDetails['po_comments'].length > 0) &&
                                                                                        <table
                                                                                            className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                                                            <thead
                                                                                                className="text-xs font-semibold  text-black bg-gray-200">
                                                                                            <tr>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div
                                                                                                        className="font-semibold text-center">{t('SL')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div
                                                                                                        className="font-semibold text-center">{t('Date')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div
                                                                                                        className="font-semibold text-center">{t('CommentBy')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div
                                                                                                        className="font-semibold text-center">{t('Comment')}</div>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {
                                                                                                (purchaseOrderDetails && purchaseOrderDetails['po_comments'] && purchaseOrderDetails['po_comments'].length > 0) &&
                                                                                                purchaseOrderDetails['po_comments'].map((comment, index) => {
                                                                                                    return (
                                                                                                        <tr className={"bg-gray-100"}
                                                                                                            key={index}>
                                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">{comment.created_date ? comment.created_date : ''}</td>
                                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">{comment.full_name ? comment.full_name : ''}</td>
                                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">{comment.comments ? comment.comments : ''}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                            </tbody>
                                                                                        </table>

                                                                                }

                                                                                <div className=" w-full items-center">
                                                                                    <label htmlFor="remark"
                                                                                           className="form-input-sm-label">{t('Comment')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                        <Textarea
                                                                                            placeholder="Your comment"
                                                                                            autosize
                                                                                            minRows={2}
                                                                                            defaultValue={poComment && poComment != '' ? poComment : ''}
                                                                                            onBlur={(e) => {
                                                                                                dispatch(setPoComment(e.target.value))
                                                                                            }}
                                                                                        />

                                                                                        <button
                                                                                            type='submit'
                                                                                            className="mt-5 inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0"
                                                                                            onClick={(e) => {
                                                                                                setCommenting(true)
                                                                                                const data = {
                                                                                                    po_id: poViewID,
                                                                                                    comment: poComment
                                                                                                }
                                                                                                dispatch(poCommentCreate(data))
                                                                                                dispatch(setPoComment(''))
                                                                                            }}
                                                                                        >
                                                                                            <FiSave size={16}
                                                                                                    className={'mr-2'}/>
                                                                                            <span>{t('Submit')}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Fragment>


                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-left mb-3">
                                                            {
                                                                fetching ?
                                                                    <>
                                                                        <div className="grid place-items-left">
                                                                            <Loader size="sm" color={'green'}/>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            ((purchaseOrderDetails.process === 'Created' ) && ROLE_PURCHASE_ORDER_APPROVE_ONE) &&
                                                                            <button
                                                                                onClick={(e) => poApproved(e, purchaseOrderDetails.purchase_order_id, purchaseOrderDetails.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Verified
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (purchaseOrderDetails.process === 'Verified' && ROLE_PURCHASE_ORDER_APPROVE_TWO) &&
                                                                            <button
                                                                                onClick={(e) => poApproved(e, purchaseOrderDetails.purchase_order_id, purchaseOrderDetails.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Validated
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (purchaseOrderDetails.process === 'Validated' && ROLE_PURCHASE_ORDER_APPROVE_THREE) &&
                                                                            <button
                                                                                onClick={(e) => poApproved(e, purchaseOrderDetails.purchase_order_id, purchaseOrderDetails.process)}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Approved
                                                                            </button>
                                                                        }

                                                                        {
                                                                            ((purchaseOrderDetails.process === 'Approved' || purchaseOrderDetails.process === 'RIP') && (ROLE_SUPER_ADMIN || ROLE_PURCHASE_ORDER_AMENDMENT) && purchaseOrderDetails.amendment_to_po_no == null) &&
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: (t('AmendmentThisPurchaseOrder')),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureAmendmentThisPurchaseOrder')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {
                                                                                            confirm: (t('Confirm')),
                                                                                            cancel: (t("Cancel"))
                                                                                        },
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            const data = {
                                                                                                po_id: purchaseOrderDetails.purchase_order_id,
                                                                                            }
                                                                                            dispatch(poAmendment(data))
                                                                                            dispatch(setEmptyPODetails(null))
                                                                                            dispatch(setFetching(true))
                                                                                        }
                                                                                    });

                                                                                }}
                                                                                className="inline-flex items-center px-6 py-2 bg-indigo-700 hover:bg-indigo-900 text-white text-xs">
                                                                                Amendment
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (purchaseOrderDetails.approve_status != 3 && purchaseOrderDetails.process != 'Hold' && (ROLE_PURCHASE_ORDER_ADD || ROLE_PURCHASE_ORDER_EDIT || ROLE_PURCHASE_ORDER_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: (t('HoldThisPurchaseOrder')),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureToHoldThisPO')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {
                                                                                            confirm: (t('Confirm')),
                                                                                            cancel: (t("Cancel"))
                                                                                        },
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            const data = {
                                                                                                po_id: purchaseOrderDetails.purchase_order_id,
                                                                                                process: 'Hold'
                                                                                            }
                                                                                            dispatch(setFetching(true))
                                                                                            dispatch(poProcessUpdate(data))
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                className="inline-flex items-center px-6 py-2 bg-fuchsia-700 hover:bg-fuchsia-900 text-white text-xs ml-2"
                                                                            >
                                                                                Hold
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (purchaseOrderDetails.approve_status != 3 && purchaseOrderDetails.process === 'Hold' && (ROLE_PURCHASE_ORDER_ADD || ROLE_PURCHASE_ORDER_EDIT || ROLE_PURCHASE_ORDER_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: (t('ActiveThisPurchaseOrder')),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureToActiveThisPO')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {
                                                                                            confirm: (t('Confirm')),
                                                                                            cancel: (t("Cancel"))
                                                                                        },
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            let status = '';
                                                                                            if (purchaseOrderDetails.approve_status == 0) {
                                                                                                status = 'Created'
                                                                                            } else if (purchaseOrderDetails.approve_status == 1) {
                                                                                                status = 'Verified'
                                                                                            } else if (purchaseOrderDetails.approve_status == 2) {
                                                                                                status = 'Validated'
                                                                                            }
                                                                                            const data = {
                                                                                                po_id: purchaseOrderDetails.purchase_order_id,
                                                                                                process: status
                                                                                            }
                                                                                            dispatch(setFetching(true))
                                                                                            dispatch(poProcessUpdate(data))
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                className="inline-flex items-center px-6 py-2 bg-fuchsia-700 hover:bg-fuchsia-900 text-white text-xs ml-2"
                                                                            >
                                                                                Active
                                                                            </button>
                                                                        }

                                                                        {
                                                                            (purchaseOrderDetails.process != 'ReceiveCancel' && (ROLE_PURCHASE_ORDER_ADD || ROLE_PURCHASE_ORDER_EDIT || ROLE_PURCHASE_ORDER_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                                <></>
                                                                            /*<button
                                                                                onClick={(e) => {
                                                                                    openConfirmModal({
                                                                                        title: (t('ActiveThisReceiveCancel')),
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {t('AreYouSureToReceiveCancelThisPO')}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {
                                                                                            confirm: (t('Confirm')),
                                                                                            cancel: (t("Cancel"))
                                                                                        },
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            const data = {
                                                                                                po_id: purchaseOrderDetails.purchase_order_id,
                                                                                                process: 'ReceiveCancel'
                                                                                            }
                                                                                            dispatch(setFetching(true))
                                                                                            dispatch(poProcessUpdate(data))
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                className="inline-flex items-center px-6 py-2 bg-pink-700 hover:bg-pink-900 text-white text-xs ml-2">
                                                                                Receive Cancel
                                                                            </button>*/
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="float-right mb-3">
                                                            <button
                                                                onClick={(e) => {
                                                                    dispatch(setPoViewModel(false))
                                                                    dispatch(setPoViewID(null))
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Close
                                                            </button>

                                                            <button
                                                                className="inline-flex items-center px-6 py-2 bg-blue-700 hover:bg-blue-900 text-white text-xs ml-2"
                                                                onClick={(e)=>{
                                                                    const param = {
                                                                        po_id : purchaseOrderDetails.purchase_order_id,
                                                                    }
                                                                    axios({
                                                                        method: 'get',
                                                                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/pdf`,
                                                                        headers: {
                                                                            "Accept": `application/json`,
                                                                            "Content-Type": `text/html`,
                                                                            "Content-Disposition": 'attachment; filename=data.csv',
                                                                            "Access-Control-Allow-Origin": '*',
                                                                            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                                                                        },
                                                                        params : param
                                                                    })
                                                                        .then(res => {
                                                                            // console.log(res.data.data)
                                                                            if (res.status === 200){
                                                                                const  fileName = 'PoPDF-'+purchaseOrderDetails.purchase_order_id+'.pdf'
                                                                                const href = `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-order/po-pdf/download?fileName=${res.data.data}&id=${purchaseOrderDetails.purchase_order_id}`;
                                                                                const anchorElement = document.createElement('a');
                                                                                anchorElement.href = href;
                                                                                anchorElement.download = fileName;
                                                                                document.body.appendChild(anchorElement);
                                                                                anchorElement.click();
                                                                            }
                                                                        })
                                                                        .catch(function (error) {
                                                                            console.log(error)
                                                                        })
                                                                }}
                                                            >Generate PDF</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
            {afterApproveEditModel?<AfterApproveEdit />:''}

        </>
    )
}

export default PoViewModel