import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {
    ScrollArea,
    Select,
    Text,
    TextInput,
    Group,
    useMantineTheme,
    LoadingOverlay
} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {showNotification} from "@mantine/notifications";
import {projectDetailsData} from "../../../../store/project/projectSlice";
import {
    getDeliveryApprove,
    getDeliveryApproveData,
    getDeliveryDetailsData,
    groupLedgerDropdown,
    inlineUpdateTempDelivery,
    setDeliveryApproveID,
    setProjectID,
    tempItemInlineUpdate,
    setFetching, setApprovalModelProject, setProjectApproveOrSubmit
} from "../../../../store/issue/issueSlice";
import {getIssueCustomer} from "../../../../store/user/userSlice";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import axios from "axios";
import {openConfirmModal} from "@mantine/modals";


function ApprovalProjectModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_INVENTORY_DELIVERY_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_INVENTORY_DELIVERY_APPROVE";
    })
    const projectDetails = useSelector((state) => state.projectReducer.projectDetails)
    const projectID = useSelector((state) => state.issueReducer.projectID)
    const deliveryApproveID = useSelector((state) => state.issueReducer.deliveryApproveID)
    const deliveryDetailsData = useSelector((state) => state.issueReducer.deliveryDetailsData)
    const inlineUpdateTempDeliveryMessage = useSelector((state) => state.issueReducer.inlineUpdateTempDeliveryMessage)
    const existsMessage = useSelector((state) => state.issueReducer.existsMessage)
    const ledgerDropdown = useSelector((state) => state.issueReducer.ledgerDropdown)
    const projectApproveOrSubmit = useSelector((state) => state.issueReducer.projectApproveOrSubmit)
    const deliveryApproveMessage = useSelector((state) => state.issueReducer.deliveryApproveMessage)
    const issueCustomerDropdown = useSelector((state) => state.userReducer.issueCustomer)
    const projectsDropdown = useSelector((state) => state.projectReducer.projectsUserWise)

    if (deliveryApproveMessage && deliveryApproveMessage == 'create') {
        dispatch(setApprovalModelProject(false))
        dispatch(setDeliveryApproveID(''))
        dispatch(setProjectID(''))
        dispatch(setFetching(true))
    }

    // start get spend area dropdown
    const [spendArea, setSpendArea] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/spend-area/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setSpendArea(res.data.data);
            });
        dispatch(groupLedgerDropdown())
    }, []);

    let spendAreaDropdown =
        spendArea.map((type, index) => {
            return ({
                'label': type.titles, 'value': Number(type.id)
            })
        })
    useEffect((e) => {
        setTimeout(() => {
            dispatch(projectDetailsData(projectID))
            dispatch(getIssueCustomer())
            setLoading(false)
        }, 500)
    }, [])

    useEffect((e) => {
        setTimeout(() => {
            if (projectID && deliveryApproveID) {
                dispatch(getDeliveryDetailsData({
                    delivery_id: deliveryApproveID
                }))
            }
            setLoading(false)
        }, 500)
    }, [loading])


    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div
                            className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ItemIssueToProjectInformation')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setApprovalModelProject(false))
                                        dispatch(setDeliveryApproveID(''))
                                        dispatch(setProjectID(''))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100" ref={pdfRef}>
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">

                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    projectDetails &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('ProjectName')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('Address')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            projectDetails && (
                                                                                                <tr className={"bg-gray-100"}
                                                                                                    key={1}>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.projects_name ? projectDetails.projects_name : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.address ? projectDetails.address : ''}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('IssueInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    projectDetails &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('ProjectTo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('Customer')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div
                                                                                                    className="font-semibold text-center">{t('GetPass')}
                                                                                                    <span
                                                                                                        className="text-red-500">{existsMessage == 'GetPassAlreadyExists' ? '(Get Pass Already Exists)' : ''}</span>
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        <tr className={"bg-gray-100"}
                                                                                            key={1}>
                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                {
                                                                                                    projectApproveOrSubmit == 'approve' ?
                                                                                                        deliveryDetailsData.issued_to_project_name
                                                                                                        :
                                                                                                        <Select
                                                                                                            placeholder='Choose project'
                                                                                                            searchable clearable
                                                                                                            allowDeselect
                                                                                                            nothingFound="No options"
                                                                                                            withAsterisk
                                                                                                            data={projectsDropdown ? projectsDropdown : []}
                                                                                                            transition="pop-top-left"
                                                                                                            transitionDuration={80}
                                                                                                            transitionTimingFunction="ease"
                                                                                                            maxDropdownHeight={280}
                                                                                                            onChange={(e) => {
                                                                                                                dispatch(inlineUpdateTempDelivery({
                                                                                                                    delivey_id: deliveryApproveID,
                                                                                                                    field_name: 'issued_to_project_id',
                                                                                                                    value: e,
                                                                                                                    type: 'delivery'
                                                                                                                }))
                                                                                                                setLoading(true)
                                                                                                            }}
                                                                                                            value={deliveryDetailsData.issued_to_project_id ? Number(deliveryDetailsData.issued_to_project_id) : null}
                                                                                                        />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                {
                                                                                                    projectApproveOrSubmit == 'approve' ?
                                                                                                        deliveryDetailsData.issue_to_customer_name
                                                                                                        :
                                                                                                        <Select
                                                                                                            placeholder='Choose Customer'
                                                                                                            searchable clearable
                                                                                                            allowDeselect
                                                                                                            nothingFound="No options"
                                                                                                            withAsterisk
                                                                                                            data={issueCustomerDropdown ? issueCustomerDropdown : []}
                                                                                                            transition="pop-top-left"
                                                                                                            transitionDuration={80}
                                                                                                            transitionTimingFunction="ease"
                                                                                                            maxDropdownHeight={280}
                                                                                                            onChange={(e) => {
                                                                                                                dispatch(inlineUpdateTempDelivery({
                                                                                                                    delivey_id: deliveryApproveID,
                                                                                                                    field_name: 'issued_to_customer',
                                                                                                                    value: e,
                                                                                                                    type: 'delivery'
                                                                                                                }))
                                                                                                                setLoading(true)
                                                                                                            }}
                                                                                                            value={deliveryDetailsData.issue_to_customer ? Number(deliveryDetailsData.issue_to_customer) : null}
                                                                                                        />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                {
                                                                                                    projectApproveOrSubmit == 'approve' ?
                                                                                                        deliveryDetailsData.get_pass
                                                                                                        :
                                                                                                        <TextInput
                                                                                                            placeholder={t('GetPass')}
                                                                                                            defaultValue={deliveryDetailsData.get_pass ? deliveryDetailsData.get_pass : ''}
                                                                                                            onBlur={(e) => {
                                                                                                                dispatch(inlineUpdateTempDelivery({
                                                                                                                    delivey_id: deliveryApproveID,
                                                                                                                    field_name: 'get_pass',
                                                                                                                    value: e.target.value,
                                                                                                                    type: 'delivery'
                                                                                                                }))
                                                                                                                if (inlineUpdateTempDeliveryMessage === 'update') {
                                                                                                                    setLoading(true)
                                                                                                                }
                                                                                                            }}
                                                                                                            withAsterisk
                                                                                                            error={''}
                                                                                                        />
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table
                                                                                    className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                    id="my-table">
                                                                                    <tbody>
                                                                                    <tr className={"bg-gray-100"}
                                                                                        key={'1'}>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            {
                                                                                                projectApproveOrSubmit == 'submit' &&
                                                                                                <Dropzone
                                                                                                    onDrop={(file) => {
                                                                                                        dispatch(inlineUpdateTempDelivery({
                                                                                                            delivey_id: deliveryApproveID,
                                                                                                            field_name: 'path',
                                                                                                            value: file[0],
                                                                                                            type: 'delivery'
                                                                                                        }))
                                                                                                        setLoading(true)
                                                                                                    }}
                                                                                                    onReject={(files) => {
                                                                                                        showNotification({
                                                                                                            id: 'load-data',
                                                                                                            loading: true,
                                                                                                            title: 'Please select PDF file.',
                                                                                                            message: (t('DataLoad')),
                                                                                                            autoClose: 1000,
                                                                                                            disallowClose: true,
                                                                                                            color: 'red',
                                                                                                        });
                                                                                                    }}
                                                                                                    accept={[MIME_TYPES.pdf]}
                                                                                                    multiple={false}>
                                                                                                    <Group position="center"
                                                                                                           spacing="xl"
                                                                                                           style={{pointerEvents: 'none'}}>
                                                                                                        <Dropzone.Accept>
                                                                                                            <IconUpload
                                                                                                                size="3.2rem"
                                                                                                                stroke={1.5}
                                                                                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                            />
                                                                                                        </Dropzone.Accept>
                                                                                                        <Dropzone.Reject>
                                                                                                            <IconX
                                                                                                                size="3.2rem"
                                                                                                                stroke={1.5}
                                                                                                            />
                                                                                                        </Dropzone.Reject>
                                                                                                        <Dropzone.Idle>
                                                                                                            <IconPhoto
                                                                                                                size="3.2rem"
                                                                                                                stroke={1.5}/>
                                                                                                        </Dropzone.Idle>

                                                                                                        <div>
                                                                                                            <Text size="xl"
                                                                                                                  inline>
                                                                                                                Drag images
                                                                                                                here or
                                                                                                                click to
                                                                                                                select files
                                                                                                            </Text>
                                                                                                            <Text size="sm"
                                                                                                                  color="dimmed"
                                                                                                                  inline
                                                                                                                  mt={7}>
                                                                                                                Drag PDF
                                                                                                                here or
                                                                                                                click to
                                                                                                                select files
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                    </Group>
                                                                                                </Dropzone>
                                                                                            }

                                                                                            {
                                                                                                (deliveryDetailsData && deliveryDetailsData.path) &&
                                                                                                <object
                                                                                                    data={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${deliveryDetailsData.path}`}
                                                                                                    type="application/pdf"
                                                                                                    width="100%"
                                                                                                    height="100%">
                                                                                                    <p>Alternative text
                                                                                                        - include a
                                                                                                        link <a
                                                                                                            href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${deliveryDetailsData.path}`}>to
                                                                                                            the PDF!</a>
                                                                                                    </p>
                                                                                                </object>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemIssueInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table
                                                                                    className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                    id="my-table">

                                                                                    <thead
                                                                                        className="text-xs font-semibold  text-black bg-gray-200">
                                                                                    <tr>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('Item')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('itemType')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('Category')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('SubCategory')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('SpendArea')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('RemainingQty')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('IssueQty')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('Unit')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('LedgerHead')}</div>
                                                                                        </th>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div
                                                                                                className="font-semibold text-center">{t('Remarks')}</div>
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>

                                                                                    {
                                                                                        (deliveryDetailsData && deliveryDetailsData['itemDetails'] && deliveryDetailsData['itemDetails'].length > 0) &&
                                                                                        deliveryDetailsData['itemDetails'].map((item, index) => {
                                                                                            let subCategoryDropdown =
                                                                                                item.subCategory ? item.subCategory.map((head, index) => {
                                                                                                    return ({
                                                                                                        'value': Number(head.id),
                                                                                                        'label': head.sub_categories_name
                                                                                                    })
                                                                                                }) : []
                                                                                            return (
                                                                                                <tr className={"bg-gray-100"}
                                                                                                    key={index}>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {item.item_name ? item.item_name : ''}
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.item_type_name ? item.item_type_name : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {item.category['name'] ? item.category['name'] : ''}
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {
                                                                                                            projectApproveOrSubmit == 'approve'?
                                                                                                                item.sub_category_name
                                                                                                                :
                                                                                                                <Select
                                                                                                                    placeholder='Choose Sub Category'
                                                                                                                    searchable
                                                                                                                    clearable
                                                                                                                    allowDeselect
                                                                                                                    nothingFound="No options"
                                                                                                                    withAsterisk
                                                                                                                    data={subCategoryDropdown ? subCategoryDropdown : []}
                                                                                                                    transition="pop-top-left"
                                                                                                                    transitionDuration={80}
                                                                                                                    transitionTimingFunction="ease"
                                                                                                                    maxDropdownHeight={280}
                                                                                                                    onChange={(e) => {
                                                                                                                        dispatch(tempItemInlineUpdate({
                                                                                                                            delivery_temp_item_id: item.id,
                                                                                                                            "field_name": "sub_category",
                                                                                                                            "value": e,
                                                                                                                            "type": 'delivery'
                                                                                                                        }))
                                                                                                                        setLoading(true)
                                                                                                                    }}
                                                                                                                    value={item.sub_category_id ? Number(item.sub_category_id) : ''}
                                                                                                                />
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {
                                                                                                            projectApproveOrSubmit == 'approve'?
                                                                                                                item.spend_area_name
                                                                                                                :
                                                                                                                <Select
                                                                                                                    placeholder='Choose Spend Area'
                                                                                                                    searchable
                                                                                                                    clearable
                                                                                                                    allowDeselect
                                                                                                                    nothingFound="No options"
                                                                                                                    withAsterisk
                                                                                                                    data={spendAreaDropdown ? spendAreaDropdown : []}
                                                                                                                    transition="pop-top-left"
                                                                                                                    transitionDuration={80}
                                                                                                                    transitionTimingFunction="ease"
                                                                                                                    maxDropdownHeight={280}
                                                                                                                    onChange={(e) => {
                                                                                                                        dispatch(tempItemInlineUpdate({
                                                                                                                            delivery_temp_item_id: item.id,
                                                                                                                            "field_name": "spend_area",
                                                                                                                            "value": e,
                                                                                                                            "type": 'delivery'
                                                                                                                        }))
                                                                                                                        setLoading(true)
                                                                                                                    }}
                                                                                                                    value={item.spend_area ? Number(item.spend_area) : ''}
                                                                                                                />
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {item.remaining['remainingQuantity'] ? item.remaining['remainingQuantity'] : ''}
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {
                                                                                                            projectApproveOrSubmit == 'approve'?
                                                                                                            item.issue_quantity:
                                                                                                                <TextInput
                                                                                                                    className={`text-center`}
                                                                                                                    defaultValue={item.issue_quantity ? Number(item.issue_quantity) : ''}
                                                                                                                    placeholder={item.remaining.remainingQuantity ? 'Max Quantity ' + item.remaining.remainingQuantity : 'Quantity'}
                                                                                                                    onBlur={(e) => {
                                                                                                                        if (Number(e.target.value) > Number(item.remaining.remainingQuantity)) {
                                                                                                                            showNotification({
                                                                                                                                id: 'load-data',
                                                                                                                                loading: true,
                                                                                                                                title: 'Max quantity ' + item.remaining.remainingQuantity,
                                                                                                                                message: 'Exists max quantity',
                                                                                                                                autoClose: 700,
                                                                                                                                disallowClose: true,
                                                                                                                                color: 'red',
                                                                                                                            });
                                                                                                                        } else {
                                                                                                                            dispatch(tempItemInlineUpdate({
                                                                                                                                delivery_temp_item_id: item.id,
                                                                                                                                "field_name": "quantity",
                                                                                                                                "value": e.target.value,
                                                                                                                                "type": 'delivery'
                                                                                                                            }))
                                                                                                                            setLoading(true)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {item.item_unit_name ? item.item_unit_name : ''}
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {
                                                                                                            projectApproveOrSubmit == 'approve'?
                                                                                                                item.erp_ledger_account_name:
                                                                                                                <Select
                                                                                                                    placeholder='Choose Ledger ERP'
                                                                                                                    searchable
                                                                                                                    clearable
                                                                                                                    allowDeselect
                                                                                                                    nothingFound="No options"
                                                                                                                    withAsterisk
                                                                                                                    data={
                                                                                                                        ledgerDropdown ?
                                                                                                                            ledgerDropdown.map((head, index) => {
                                                                                                                                return ({
                                                                                                                                    'value': Number(head.id),
                                                                                                                                    'label': head.titles,
                                                                                                                                    'group': head.parent_titles
                                                                                                                                })
                                                                                                                            }) : []
                                                                                                                    }
                                                                                                                    transition="pop-top-left"
                                                                                                                    transitionDuration={80}
                                                                                                                    transitionTimingFunction="ease"
                                                                                                                    maxDropdownHeight={280}
                                                                                                                    onChange={(e) => {
                                                                                                                        dispatch(tempItemInlineUpdate({
                                                                                                                            delivery_temp_item_id: item.id,
                                                                                                                            "field_name": "ledger_head",
                                                                                                                            "value": e,
                                                                                                                            "type": 'delivery'
                                                                                                                        }))
                                                                                                                        setLoading(true)
                                                                                                                    }}
                                                                                                                    value={item.erp_ledger_account ? Number(item.erp_ledger_account) : ''}
                                                                                                                />
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        {
                                                                                                            projectApproveOrSubmit == 'approve'?
                                                                                                                item.remark:
                                                                                                                <TextInput
                                                                                                                    className={`text-center`}
                                                                                                                    defaultValue={item.remark ? item.remark : ''}
                                                                                                                    onBlur={(e) => {
                                                                                                                        dispatch(tempItemInlineUpdate({
                                                                                                                            delivery_temp_item_id: item.id,
                                                                                                                            "field_name": "remark",
                                                                                                                            "value": e.target.value,
                                                                                                                            "type": 'delivery'
                                                                                                                        }))
                                                                                                                        setLoading(true)
                                                                                                                    }}
                                                                                                                />
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">

                                                        <div className="float-right mb-3">
                                                            {
                                                                (existsMessage != 'GetPassAlreadyExists' && projectApproveOrSubmit == 'approve' && ROLE_INVENTORY_DELIVERY_APPROVE) &&
                                                                <button
                                                                    onClick={(e) => {
                                                                        console.log(deliveryDetailsData)

                                                                        let validation = true
                                                                        let validationMessage = ''

                                                                        if (!deliveryDetailsData.get_pass || deliveryDetailsData.get_pass == '' || deliveryDetailsData.get_pass == null) {
                                                                            validation = false
                                                                            validationMessage = t('EnterGatePass')
                                                                        }
                                                                        if (!deliveryDetailsData.issue_to_customer || deliveryDetailsData.issue_to_customer == '' || deliveryDetailsData.issue_to_customer == null) {
                                                                            validation = false
                                                                            validationMessage = t('ChooseIssueCustomer')
                                                                        }
                                                                        if (!deliveryDetailsData.issued_to_project_id || deliveryDetailsData.issued_to_project_id == '' || deliveryDetailsData.issued_to_project_id == null) {
                                                                            validation = false
                                                                            validationMessage = t('ChooseProjectTo')
                                                                        }

                                                                        if (validation) {
                                                                            openConfirmModal({
                                                                                title: (t('ApproveThisDelivery')),
                                                                                centered: true,
                                                                                children: (
                                                                                    <Text size="sm">
                                                                                        {t('AreYouSureYouWantToApprovedThisDelivery')}
                                                                                    </Text>
                                                                                ),
                                                                                labels: {
                                                                                    confirm: (t('Confirm')),
                                                                                    cancel: (t("Cancel"))
                                                                                },
                                                                                confirmProps: {color: 'red'},
                                                                                onCancel: () => console.log(t('Cancel')),
                                                                                onConfirm: () => {
                                                                                    dispatch(getDeliveryApproveData({
                                                                                        delivery_id: deliveryApproveID
                                                                                    }))
                                                                                    dispatch(setProjectApproveOrSubmit(''))
                                                                                },
                                                                            });
                                                                        } else {
                                                                            showNotification({
                                                                                id: 'load-data',
                                                                                loading: true,
                                                                                title: validationMessage,
                                                                                message: (t('DataLoad')),
                                                                                autoClose: 1000,
                                                                                disallowClose: true,
                                                                                color: 'red',
                                                                            });
                                                                        }
                                                                    }}
                                                                    className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                                                                >
                                                                    Approved
                                                                </button>
                                                            }

                                                            {
                                                                (existsMessage != 'GetPassAlreadyExists' && projectApproveOrSubmit == 'submit' && ROLE_INVENTORY_DELIVERY_APPROVE) &&
                                                                <button
                                                                    onClick={(e) => {
                                                                        let validation = true
                                                                        let validationMessage = ''

                                                                        if (!deliveryDetailsData.get_pass || deliveryDetailsData.get_pass == '' || deliveryDetailsData.get_pass == null) {
                                                                            validation = false
                                                                            validationMessage = t('EnterGatePass')
                                                                        }
                                                                        if (!deliveryDetailsData.issue_to_customer || deliveryDetailsData.issue_to_customer == '' || deliveryDetailsData.issue_to_customer == null) {
                                                                            validation = false
                                                                            validationMessage = t('ChooseIssueCustomer')
                                                                        }
                                                                        if (!deliveryDetailsData.issued_to_project_id || deliveryDetailsData.issued_to_project_id == '' || deliveryDetailsData.issued_to_project_id == null) {
                                                                            validation = false
                                                                            validationMessage = t('ChooseProjectTo')
                                                                        }

                                                                        if (validation) {
                                                                            openConfirmModal({
                                                                                title: (t('UpdateThisDelivery')),
                                                                                centered: true,
                                                                                children: (
                                                                                    <Text size="sm">
                                                                                        {t('AreYouSureYouWantToUpdatetThisDelivery')}
                                                                                    </Text>
                                                                                ),
                                                                                labels: {
                                                                                    confirm: (t('Confirm')),
                                                                                    cancel: (t("Cancel"))
                                                                                },
                                                                                confirmProps: {color: 'red'},
                                                                                onCancel: () => console.log(t('Cancel')),
                                                                                onConfirm: () => {
                                                                                    dispatch(setProjectApproveOrSubmit(''))
                                                                                    dispatch(setApprovalModelProject(false))
                                                                                    dispatch(setDeliveryApproveID(''))
                                                                                    dispatch(setProjectID(''))
                                                                                    dispatch(setFetching(true))
                                                                                },
                                                                            });
                                                                        } else {
                                                                            showNotification({
                                                                                id: 'load-data',
                                                                                loading: true,
                                                                                title: validationMessage,
                                                                                message: (t('DataLoad')),
                                                                                autoClose: 1000,
                                                                                disallowClose: true,
                                                                                color: 'red',
                                                                            });
                                                                        }
                                                                    }}
                                                                    className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                                                                >
                                                                    Submit
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default ApprovalProjectModel