import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {
    ScrollArea,
    useMantineTheme,
    LoadingOverlay
} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {projectDetailsData} from "../../../store/project/projectSlice";
import {
    getDeliveryApprove,
    getDeliveryDetailsData,
    groupLedgerDropdown,
    setDeliveryApproveID, setDeliveryViewModel, setFetching,
    setProjectID,
} from "../../../store/issue/issueSlice";

function DeliveryCustomerViewModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);


    const projectDetails = useSelector((state) => state.projectReducer.projectDetails)
    const projectID = useSelector((state) => state.issueReducer.projectID)
    const deliveryApproveID = useSelector((state) => state.issueReducer.deliveryApproveID)
    const deliveryDetailsData = useSelector((state) => state.issueReducer.deliveryDetailsData)
    const existsMessage = useSelector((state) => state.issueReducer.existsMessage)

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(projectDetailsData(projectID))
            setLoading(false)
        },500)
    },[])

    useEffect((e)=>{
        setTimeout(()=>{
            if (projectID && deliveryApproveID){
                dispatch(getDeliveryDetailsData({
                    delivery_id : deliveryApproveID
                }))
            }
            setLoading(false)
        },500)
    },[loading])


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ItemIssueInformation')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setDeliveryViewModel(false))
                                        dispatch(setDeliveryApproveID(''))
                                        dispatch(setProjectID(''))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                        <Fragment>
                                                            <div className="bg-gray-100" ref={pdfRef}>
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">

    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        projectDetails &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('ProjectName')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Address')}</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                projectDetails && (
                                    <tr className={"bg-gray-100"} key={1}>
                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.projects_name?projectDetails.projects_name:''}</td>
                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{projectDetails.address?projectDetails.address:''}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    </div>



    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('IssueInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        projectDetails &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('Customer')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('GetPass')} <span className="text-red-500">{existsMessage == 'GetPassAlreadyExists'?'(Get Pass Already Exists)':''}</span> </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr className={"bg-gray-100"} key={1}>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {deliveryDetailsData.customer_full_name?deliveryDetailsData.customer_full_name:''}
                                    </td>
                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                        {deliveryDetailsData.get_pass?deliveryDetailsData.get_pass:''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    </div>



    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                        <tbody>
                        <tr className={"bg-gray-100"} key={'1'}>
                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                {
                                        (deliveryDetailsData && deliveryDetailsData.path) &&
                                        <object data={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${deliveryDetailsData.path}`} type="application/pdf" width="100%" height="100%">
                                            <p>Alternative text - include a link <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/delivery-issue/${deliveryDetailsData.path}`}>to the PDF!</a></p>
                                        </object>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

     <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ItemIssueInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">

                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Item')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('itemType')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Category')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SubCategory')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SpendArea')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('RemainingQty')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('IssueQty')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Unit')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('LedgerHead')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Remarks')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    (deliveryDetailsData && deliveryDetailsData['itemDetails'] && deliveryDetailsData['itemDetails'].length >0 ) &&
                                    deliveryDetailsData['itemDetails'].map((item, index) => {
                                        let subCategoryDropdown =
                                            item.subCategory?item.subCategory.map((head, index) => {
                                                return ({
                                                    'value': Number(head.id),
                                                    'label': head.sub_categories_name
                                                })
                                            }):[]
                                        return (
                                            <tr className={"bg-gray-100"} key={index}>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.item_name?item.item_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.item_type_name?item.item_type_name:''}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.category['name']?item.category['name']:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.sub_category_name?item.sub_category_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.spend_area_name?item.spend_area_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.remaining['remainingQuantity']?item.remaining['remainingQuantity']:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.issue_quantity?Number(item.issue_quantity):''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.item_unit_name?item.item_unit_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.erp_ledger_account_name?item.erp_ledger_account_name:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.remark?item.remark:''}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                </tbody>
                            </table>
                </div>
            </div>
        </div>
    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-right mb-3">
                    {
                        <button
                            onClick={(e) => {
                                dispatch(setDeliveryViewModel(false))
                                dispatch(setDeliveryApproveID(''))
                                dispatch(setProjectID(''))
                                dispatch(setFetching(true))
                            }}
                            className="inline-flex items-center px-6 py-2 bg-rose-700 hover:bg-rose-900 text-white text-xs ml-2"
                        >
                            Close
                        </button>
                    }


                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default DeliveryCustomerViewModel