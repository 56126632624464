import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, ScrollArea, TextInput, Select} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import CompanyTypeModal from '../company-type/CompanyTypeModal';
import axios from "axios";
import SelectSearch from "react-select-search";
import 'react-select-search/style.css';
import View from "./ItemView";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import toast from "react-hot-toast";


function Varieties() {

    const {t, i18n} = useTranslation();
    const [isAddForm, setAddForm] = useState(true);
    const [listRefresh, setListRefresh] = useState(false);


    const token = localStorage.getItem('user_token');


    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);


    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('variety_page') ? localStorage.getItem('variety_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63);
    var fromHeight = height - 153 + "px";
    const [varieties, setVarieties] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [varietiesDropdown,setVarietiesDropdown] = useState([]);

    useEffect(()=>{
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/variety-type/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setTimeout(() => {
                    if (res.data.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'value': Number(head.id), 'label': head.name
                                })
                            })
                        setVarietiesDropdown(data);
                    } else {
                        toast.error(res.data.message);
                    }
                }, 1000)

            })
            .catch(function (error) {
                console.log(error)
            })
    },[])

    // console.log(varietiesDropdown)

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/variety`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page": page
            }
        })
            .then(res => {
                setTimeout(() => {
                    if (res.data.status === 200) {
                        setVarieties(res.data.data);
                        setTotalItem(res.data.total);
                        localStorage.setItem('variety_page', page);
                        setFetching(false)
                    } else {
                        toast.error(res.data.message);
                    }
                }, 2000)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [spinner, page, listRefresh,fetching]);

    // FOR DATA TABLE DATA SHOW END
    const [varietyTypeData,serVarietyTypeData] = useState('')
    const VarietyTypeDataHandel = (e) => {
        serVarietyTypeData(e);
        setValue('variety_type_id',e)
        setError('variety_type_id',null)
    }
    //Form validation
    const validationSchema = Yup.object().shape({
        variety_type_id: Yup.string().required(t("ChooseVarietyType")),
        name: Yup.string().required(t("EnterVehicleName")),
        number: Yup.string().required(t("EnterVehicleNumber"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue,setError} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        data['status'] = true;
        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/variety/`,
            data,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('DataAddedSuccesfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                        setFetching(true)
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'red',
                        });
                    }
                }
            );
    }


    const [isEditForm, setEditForm] = useState(false);

    const VehicleEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/vehicle/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 200) {
                        // setEditInfo(res.data.data);
                        setValue('name', res.data.data.name)
                        setValue('number', res.data.data.number)
                        setValue('id', id)
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                }
            );
    }

    function formSubmitUpdate(data) {
        setSpinner(true);
        data['status'] = true;

        if (data) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/vehicle/${data.id}`,
                data,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        // console.log(res.data)
                        if (res.data.status === 202) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('VehicleUpdatedSuccessfully')),
                                message: (t('DataLoad')),
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });
                            setSpinner(false);
                            reset();
                            setFetching(true)
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: res.data.message,
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    }
                );
        }
    }


    const VehicleDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteItem')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/vehicle/${id}`,
                        {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                                if (res.status === 200) {
                                    showNotification({
                                        id: 'load-data',
                                        loading: true,
                                        title: (t('VehicleDeletedSuccessfully')),
                                        message: (t('DataLoad')),
                                        autoClose: 500,
                                        disallowClose: true,
                                        color: 'red',
                                    });
                                    setListRefresh(true);
                                    setFetching(true)
                                }
                            }
                        )
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const ItemView = (e, id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/${id}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 200) {
                        setViewData(res.data.data[0]);
                        setViewModel(true);
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 3000,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                }
            );
    }


    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('Varieties')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('VehiclesRelatedInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button
                                                                onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12}
                                                                               className={'mr-1'}></HiOutlinePlus>{t('AddVariety')}
                                                            </button>

                                                            <Link to='/master-data/project'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2 text-xs group relative text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={varieties}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (varieties.indexOf(item) + 1) + PAGE_SIZE * (page - 1)

                                                                            },
                                                                            {
                                                                                accessor: 'variety_type_name',
                                                                                title: <Text mr="xs">{t('VarietyType')}</Text>
                                                                            },{
                                                                                accessor: 'name',
                                                                                title: <Text mr="xs">{t('Name')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'number',
                                                                                title: <Text
                                                                                    mr="xs">{t('Number')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text
                                                                                    mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => VehicleEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        {/*<ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => ItemView(e, item.id)}/>
                                                                                        </ActionIcon>*/}
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => VehicleDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('VehiclesForm')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(false)}

                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                                            <ScrollArea style={{height: fromHeight}}>
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="variety_type_id" className="form-input-sm-label-required">{t('VarietyType')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.variety_type_id?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.variety_type_id?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                {...register("variety_type_id")}
                                                                                                placeholder={t("ChooseVarietyType")}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={varietiesDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={VarietyTypeDataHandel}
                                                                                                value={varietyTypeData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Varieties Name Section */}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name" className="form-input-sm-label-required">{t('VehicleName')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("name")}
                                                                                                placeholder={t("EnterVehicleName")}
                                                                                                radius="xs"
                                                                                                size="xs"
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Varieties Number Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="number"
                                                                                               className="form-input-sm-label-required">{t('VehicleNumber')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.number?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.number?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("number")}
                                                                                                placeholder={t("EnterVehicleNumber")}
                                                                                                radius="xs"
                                                                                                size="xs"
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}

                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateVehicleData')}</div>
                                                                                    <button
                                                                                        onClick={event => setEditForm(false)}

                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)}
                                                                              id="horizontal-form">
                                                                            <ScrollArea style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    {/* Varieties Name Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('VehicleName')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("name")}
                                                                                                placeholder={t("EnterVehicleName")}
                                                                                                radius="xs"
                                                                                                size="xs"
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Varieties Number Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="number"
                                                                                               className="form-input-sm-label-required">{t('VehicleNumber')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.number?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.number?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("number")}
                                                                                                placeholder={t("EnterVehicleNumber")}
                                                                                                radius="xs"
                                                                                                size="xs"
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}

export default Varieties