import axios from "axios";

export const masterDataDropdownService = async (type) => {
    let data = [];
    data['type'] = type
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params :{
            slug : type
        }
    })
        .then(res => {
            if (res.status === 200){
                if (type == 'payment-from'){
                    data['data'] =
                        res.data.data.map((item, index) => {
                            return ({
                                'value': item.slug==='local-office'?1:2, 'label': item.name
                            })
                        })
                }else {
                    data['data'] =
                        res.data.data.map((item, index) => {
                            return ({
                                'value': item.slug, 'label': item.name
                            })
                        })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};
