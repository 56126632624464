import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, ScrollArea} from "@mantine/core";
import {HiOutlineX} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";

import {
    paymentBankCheque,setPaymentViewID, setPaymentViewModel, vendorWiseConfirm
} from "../../../store/payment/paymentSlice";

import {RelatedBankDropdown} from "../../../store/related-bank/relatedBankSlice";

function PayViewModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_CHECKED = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_CHECKED";
    })

    const vendorWiseConfirmData = useSelector((state) => state.paymentReducer.vendorWiseConfirmData)
    const paymentViewID = useSelector((state) => state.paymentReducer.paymentViewID)
    const bankCheque = useSelector((state) => state.paymentReducer.bankCheque)


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(vendorWiseConfirm({
                pay_id : [paymentViewID]
            }))

            dispatch(paymentBankCheque({
                pay_id : paymentViewID
            }))
            setLoading(false)
        },500)
    },[loading])


    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(RelatedBankDropdown())
        },500)
    },[])

    let totalRequestAmount = 0;

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PaymentDetails')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setPaymentViewID(''))
                                        dispatch(setPaymentViewModel(false))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>

                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>

                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                <Fragment>
                                                    <div className="bg-gray-100" ref={pdfRef}>
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('OrderNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RefNo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('CompanyType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('IssueDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentMethod')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentFrom')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                            vendorWiseConfirmData['pruchaseOrder'].map((item, index) => {
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.purchase_order_id?item.purchase_order_id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.ref_no?item.ref_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.company_name?item.company_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.created_date?item.created_date:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.purchase_type_name?item.purchase_type_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.payment_method?item.payment_method:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.payment_from && item.payment_from == 2?'Head Office':'Local Office'}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorBuyerInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            {
                                                                                                vendorWiseConfirmData.buyer_email &&
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Buyer')}</div>
                                                                                                </th>
                                                                                            }
                                                                                            {
                                                                                                vendorWiseConfirmData.vendor &&
                                                                                                <>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('Vendor')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('ContactPerson')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('Mobile')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('VAT/eTin')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('AccountID')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('ComplianceStatus')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('BankName')}</div>
                                                                                                    </th>
                                                                                                    <th className="p-2 border-collapse border border-slate-400">
                                                                                                        <div className="font-semibold text-center">{t('AccountNo')}</div>
                                                                                                    </th>
                                                                                                </>
                                                                                            }
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['pruchaseOrder'] && vendorWiseConfirmData['pruchaseOrder'].length >0 ) &&
                                                                                            vendorWiseConfirmData['pruchaseOrder'].map((item, index) => {
                                                                                                return (

                                                                                                    (index === 0 && item.vendor_email) &&
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.buyer_email?item.buyer_email:item.vendors_name}</td>

                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.contract_person?item.contract_person:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.contract_no?item.contract_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.vat_certificate_no?item.vat_certificate_no:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.account_id?item.account_id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.compliance_status?item.compliance_status:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bank_account_name?item.bank_account_name:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bank_account_no?item.bank_account_no:''}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentRequestInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['getPayment'] && vendorWiseConfirmData['getPayment'].length >0 ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentID')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentRequestedBy')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RequestAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('VATAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('AITAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentType')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PO')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['getPayment'] && vendorWiseConfirmData['getPayment'].length >0 ) &&
                                                                                            vendorWiseConfirmData['getPayment'].map((item, index) => {
                                                                                                totalRequestAmount = totalRequestAmount+item.requestAmount
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.id?item.id:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.verifier_email?item.verifier_email:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bill_date?item.bill_date:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.bill_number?item.bill_number:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            TK. { Number(item.requestAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            TK. { Number(item.net_payment_amount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            TK. { item.vat_amount }
                                                                                                            {/*TK. { Number(item.vat_amount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            TK. { item.ait_amount}
                                                                                                            {/*TK. { Number(item.ait_amount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.paymentType?item.paymentType:''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.po_id?item.po_id:''}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                        <tr>
                                                                                            <td colSpan={4} className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total</td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                                                                                TK. { Number(totalRequestAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentConformationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                    <tr>
                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                            <div className="font-semibold text-center">{t('BankName')}</div>
                                                                                        </th>
                                                                                        {
                                                                                            (bankCheque.bank_name == 'Online Transfer' || bankCheque.bank_name=='Project Patty Cash') ?
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Date')}</div>
                                                                                                </th>
                                                                                                :
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('ChequeNo')}</div>
                                                                                                </th>
                                                                                        }

                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    <tr className={"bg-gray-100"} key={'1'}>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            {bankCheque.bank_name}
                                                                                        </td>
                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                            {
                                                                                                (bankCheque.bank_name == 'Online Transfer' || bankCheque.bank_name=='Project Patty Cash')?
                                                                                                    bankCheque.PartyOrOnlineDate
                                                                                                    :
                                                                                                    bankCheque.cheque_no
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentHistoryInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (vendorWiseConfirmData && vendorWiseConfirmData['paymentHistory'] ) &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('ApprovedDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentDate')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('BillDateNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('GRNNumber')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RequestedAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PaymentAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('AdjustmentAmount')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (vendorWiseConfirmData && vendorWiseConfirmData['paymentHistory'] && vendorWiseConfirmData['paymentHistory'].length >0 ) &&
                                                                                            vendorWiseConfirmData['paymentHistory'].map((item, index) => {
                                                                                                return (
                                                                                                    item.payment_date &&
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.request_date?item.request_date:null}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.payment_date?item.payment_date:null}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.grn_id?item.grn_id:'ADV'}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.requestAmount?item.requestAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.paymentAmount?item.paymentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            { Number(item.adjustmentAmount?item.adjustmentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <tr>
                                                                                            <td colSpan={8} className="w-1/24 border-collapse border border-slate-400 text-center font-bold"></td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">
                <div className="float-left mb-3">

                </div>

                <div className="float-right mb-3">
                    <button
                        onClick={(e) => {
                            dispatch(setPaymentViewID(''))
                            dispatch(setPaymentViewModel(false))
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default PayViewModel