import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Group, Loader, Select, Text, TextInput, useMantineTheme} from "@mantine/core";

import  {
    setLoading,
    ReceiveInlineUpdate, ReceiveInvoiceChallanInlineUpdate, ReceiveItemsInlineUpdate, setGrnRef
} from "../../../../store/receivedItem/receivedItemSlice";
import {HiOutlineExclamation} from "react-icons/hi";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {showNotification} from "@mantine/notifications";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import {openConfirmModal} from "@mantine/modals";
import {
    inlineUpdatePOItem,
    setPoItemInlineUpdate,
    setPoItemInlineUpdateID
} from "../../../../store/purchaseOrder/purchaseOrderSlice";


function ReceivedNoteDetails(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const theme = useMantineTheme();
    const [physicalDate,setPhysicalDate] = useState(null)
    const [invoiceBillDate,setInvoiceBillDate] = useState(null)
    const [challanBillDate,setChallanBillDate] = useState(null)

    const {register,setValue,setError,errors} = props
    const receiveDetailsData = useSelector((state) => state.receivedItemReducer.receiveDetailsData)
    const loading = useSelector((state) => state.receivedItemReducer.loading)
    const grn_ref = useSelector((state) => state.receivedItemReducer.grn_ref)

    if (receiveDetailsData){
        setValue('ref_grn',receiveDetailsData.ref_grn)
    }
    if (receiveDetailsData){
        setValue('grn_file',receiveDetailsData.path)
    }
    if (receiveDetailsData){
        setValue('challan_path',receiveDetailsData.challan_path)
    }
    if (receiveDetailsData){
        setValue('challan_bill_number',receiveDetailsData.challan_bill_number)
    }
    if (receiveDetailsData){
        setValue('challan_bill_date',receiveDetailsData.challan_bill_date)
    }
    if (receiveDetailsData){
        setValue('physical_received_date',receiveDetailsData.physical_received_date)
    }


    return(
        <>
            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow      sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="project" className="form-input-sm-label">{t('ProjectName')} : <span
                                        className="font-bold ml-5">{receiveDetailsData.project_name ? receiveDetailsData.project_name : null}</span></label>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="Created" className="form-input-sm-label">{t('Created')} :
                                        <span
                                            className="font-bold ml-5">{receiveDetailsData.received_email ? receiveDetailsData.received_email : null}</span>
                                    </label>
                                </div>

                                <div className=" w-1/3 items-center"></div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="ReceivedFrom" className="form-input-sm-label">{t('ReceivedFrom')} :
                                        {
                                            receiveDetailsData.vendors ?
                                                <span className="font-bold ml-5">Vendor</span>
                                                :
                                                <span className="font-bold ml-5">Buyer</span>
                                        }
                                    </label>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendors_name" className="form-input-sm-label">
                                        {
                                            receiveDetailsData.vendors ?
                                                <>
                                                    Vendor : <span
                                                    className="font-bold ml-5">{receiveDetailsData.vendors_name ? receiveDetailsData.vendors_name : null}</span>
                                                </>
                                                :
                                                <>
                                                    Buyer : <span
                                                    className="font-bold ml-5">{receiveDetailsData.buyer_email ? receiveDetailsData.buyer_email : null}</span>
                                                </>
                                        }

                                    </label>
                                </div>


                                <div className=" w-1/3 items-center">
                                    <label htmlFor="project" className="form-input-sm-label">Contact No :
                                        <span
                                            className="font-bold">{receiveDetailsData.contact_number ? receiveDetailsData.contact_number : null}</span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow      sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label-required">{t('ChallanAttach')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.challan_path?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.challan_path?.message}
                                            </div>}
                                        <Dropzone
                                            onDrop={(file) => {
                                                setValue('challan_path', file[0])
                                                const data = {
                                                    field_name: "path",
                                                    value: file[0],
                                                    id: receiveDetailsData.calans
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }}
                                            onReject={(files) => {
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: 'Please select PDF file.',
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                            }}
                                            accept={[MIME_TYPES.pdf]}
                                            multiple={false}
                                        >
                                            <Group position="center" spacing="sm" style={{pointerEvents: 'none'}}>
                                                <Dropzone.Accept>
                                                    <IconUpload
                                                        stroke={1.5}
                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Accept>
                                                <Dropzone.Reject>
                                                    <IconX
                                                        stroke={1.5}
                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Reject>
                                                <Dropzone.Idle>
                                                    <IconPhoto stroke={1.5}/>
                                                </Dropzone.Idle>

                                                <div>
                                                    <Text size="sm" inline>
                                                        {
                                                            (receiveDetailsData && receiveDetailsData.challan_path) ?
                                                                receiveDetailsData.challan_path.split("/").pop() :
                                                                'Drag PDF here or click to select files'
                                                        }
                                                    </Text>
                                                </div>
                                            </Group>
                                        </Dropzone>
                                        {
                                            (receiveDetailsData && receiveDetailsData.challan_path) &&
                                            <a title="Invoice Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.calans}/challan`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                Attachment</a>
                                        }
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label-required">{t('ChallanBillNo')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.challan_bill_number?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16}
                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.challan_bill_number?.message}
                                            </div>}
                                        <input
                                            {...register('challan_bill_number')}
                                            type="text"
                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                            placeholder={t("ChallanBillNo")}
                                            defaultValue={receiveDetailsData && receiveDetailsData.challan_bill_number ? receiveDetailsData.challan_bill_number : null}
                                            onBlur={(e) => {
                                                setValue('challan_bill_number', e.target.value)
                                                const data = {
                                                    field_name: "bill_number",
                                                    value: e.target.value,
                                                    id: receiveDetailsData.calans
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label-required">{t('ChallanBillDate')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.challan_bill_date?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16}
                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.challan_bill_date?.message}
                                            </div>}
                                        <DatePicker
                                            {...register('challan_bill_date')}
                                            placeholder={t('ChallanBillDate')}
                                            value={receiveDetailsData && receiveDetailsData.challan_bill_date ? new Date(receiveDetailsData.challan_bill_date) : null}
                                            value={challanBillDate ? challanBillDate : (receiveDetailsData && receiveDetailsData.challan_bill_date ? new Date(receiveDetailsData.challan_bill_date) : null)}
                                            onChange={(e) => {
                                                setChallanBillDate(format(e, 'yyyy-MM-dd'))
                                                setValue('challan_bill_date', e)
                                                const data = {
                                                    field_name: "bill_date",
                                                    value: format(e, 'yyyy-MM-dd'),
                                                    id: receiveDetailsData.calans
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="GRNFileAttach"
                                           className="form-input-sm-label-required">{t('GRNFileAttach')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.grn_file?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.grn_file?.message}
                                            </div>}

                                        <Dropzone
                                            onDrop={(file) => {
                                                setValue('grn_file', file[0])
                                                const GRNFileAttachData = {
                                                    field_name: "path",
                                                    value: file[0],
                                                    recivedID: receiveDetailsData.id
                                                }
                                                dispatch(ReceiveInlineUpdate(GRNFileAttachData))
                                                dispatch(setLoading(true))
                                            }}
                                            onReject={(files) => {
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: 'Please select PDF file.',
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                            }}
                                            accept={[MIME_TYPES.pdf]}
                                            multiple={false}
                                        >
                                            <Group position="center" spacing="sm" style={{pointerEvents: 'none'}}>
                                                <Dropzone.Accept>
                                                    <IconUpload
                                                        stroke={1.5}
                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Accept>
                                                <Dropzone.Reject>
                                                    <IconX
                                                        stroke={1.5}
                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Reject>
                                                <Dropzone.Idle>
                                                    <IconPhoto stroke={1.5}/>
                                                </Dropzone.Idle>

                                                <div>
                                                    <Text size="sm" inline>
                                                        {
                                                            (receiveDetailsData.path && receiveDetailsData.path) ?
                                                                receiveDetailsData.path.split("/").pop() :
                                                                'Drag PDF here or click to select files'
                                                        }
                                                    </Text>
                                                </div>
                                            </Group>
                                        </Dropzone>
                                        {
                                            (receiveDetailsData && receiveDetailsData.path) &&
                                            <a title="GRN Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.id}/grn`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                Attachment</a>
                                        }
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="refno" className="form-input-sm-label-required">{t('RefNo')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.ref_grn?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16}
                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.ref_grn?.message}
                                            </div>}
                                        <input
                                            {...register('ref_grn')}
                                            type="text"
                                            className={`form-input-sm-control`}
                                            placeholder={t("EnterRefNo")}
                                            defaultValue={grn_ref ? grn_ref : ''}
                                            onBlur={(e) => {
                                                dispatch(setGrnRef(e.target.value))
                                                setValue('ref_grn', e.target.value)
                                                const GRNFRefData = {
                                                    field_name: "ref_grn",
                                                    value: e.target.value,
                                                    recivedID: receiveDetailsData.id
                                                }
                                                dispatch(ReceiveInlineUpdate(GRNFRefData))
                                                dispatch(setLoading(true))
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="physical_received_date"
                                           className="form-input-sm-label">{t('PhysicalReceivedDate')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        {errors.physical_received_date?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16}
                                                                      className={'mr-2'}></HiOutlineExclamation> {errors.physical_received_date?.message}
                                            </div>}
                                        <DatePicker
                                            {...register('physical_received_date')}
                                            value={physicalDate ? physicalDate : (receiveDetailsData && receiveDetailsData.physical_received_date ? new Date(receiveDetailsData.physical_received_date) : null)}
                                            onChange={(e) => {
                                                setPhysicalDate(format(e, 'yyyy-MM-dd'))
                                                setValue('physical_received_date', e)
                                                const data = {
                                                    field_name: "physical_received_date",
                                                    value: format(e, 'yyyy-MM-dd'),
                                                    recivedID: receiveDetailsData.id
                                                }
                                                dispatch(ReceiveInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow      sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label">{t('InvoiceAttach')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        <Dropzone
                                            onDrop={(file) => {
                                                const data = {
                                                    field_name: "path",
                                                    value: file[0],
                                                    id: receiveDetailsData.invoices
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }}
                                            onReject={(files) => {
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: 'Please select PDF file.',
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                            }}
                                            accept={[MIME_TYPES.pdf]}
                                            multiple={false}
                                        >
                                            <Group position="center" spacing="sm" style={{pointerEvents: 'none'}}>
                                                <Dropzone.Accept>
                                                    <IconUpload
                                                        stroke={1.5}
                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Accept>
                                                <Dropzone.Reject>
                                                    <IconX
                                                        stroke={1.5}
                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Reject>
                                                <Dropzone.Idle>
                                                    <IconPhoto stroke={1.5}/>
                                                </Dropzone.Idle>

                                                <div>
                                                    <Text size="sm" inline>
                                                        {
                                                            (receiveDetailsData && receiveDetailsData.invoice_path) ?
                                                                receiveDetailsData.invoice_path.split("/").pop() :
                                                                'Drag PDF here or click to select files'
                                                        }
                                                    </Text>
                                                </div>
                                            </Group>
                                        </Dropzone>
                                        {
                                            (receiveDetailsData && receiveDetailsData.invoice_path) &&
                                            <a title="Invoice Attachment" target="_blank"
                                               href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ receiveDetailsData.invoices}/invoice`}
                                               className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open
                                                Attachment</a>
                                        }
                                        {
                                            (receiveDetailsData && receiveDetailsData.invoice_path) &&
                                            <span onClick={(e) => {
                                                const data = {
                                                    field_name: "empty_invoice_path",
                                                    value: null,
                                                    id: receiveDetailsData.invoices
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }} title="Invoice Attachment"
                                                  className="inline-flex cursor-pointer items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Delete</span>
                                        }
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label">{t('InvoiceBillNo')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                        <input
                                            type="text"
                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                            placeholder={t("InvoiceBillNo")}
                                            defaultValue={receiveDetailsData && receiveDetailsData.invoice_bill_number ? receiveDetailsData.invoice_bill_number : null}
                                            onBlur={(e) => {
                                                const data = {
                                                    field_name: "bill_number",
                                                    value: e.target.value,
                                                    id: receiveDetailsData.invoices
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/3 items-center">
                                    <label htmlFor="vendor_quotation_reference_number"
                                           className="form-input-sm-label">{t('InvoiceBillDate')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        <DatePicker
                                            {...register('invoice_bill_date')}
                                            placeholder={t('InvoiceBillDate')}
                                            value={receiveDetailsData && receiveDetailsData.invoice_bill_date ? new Date(receiveDetailsData.invoice_bill_date) : null}
                                            value={invoiceBillDate ? invoiceBillDate : (receiveDetailsData && receiveDetailsData.invoice_bill_date ? new Date(receiveDetailsData.invoice_bill_date) : null)}
                                            onChange={(e) => {
                                                setInvoiceBillDate(format(e, 'yyyy-MM-dd'))
                                                setValue('invoice_bill_date', e)
                                                const data = {
                                                    field_name: "bill_date",
                                                    value: format(e, 'yyyy-MM-dd'),
                                                    id: receiveDetailsData.invoices
                                                }
                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                dispatch(setLoading(true))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                            {
                                loading ?
                                    <Loader className="mx-auto justify-items-right w-full" size={"sm"}/> :
                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2 mt-5'>
                                        <div className="mx-auto justify-items-right w-full">
                                            <section className="-mx-0.5">
                                                <div className="flex flex-col justify-center h-full">

                                                    <table
                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                        <thead
                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                        <tr>

                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('SL')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('Particulars')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('RemainQuantity')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('Unit')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('ProjectName')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('PONO')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('PRNO')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('Remarks')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div
                                                                    className="font-semibold text-center">{t('Quality')}</div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {receiveDetailsData && receiveDetailsData['received_items'] && receiveDetailsData['received_items'].length > 0 && receiveDetailsData['received_items'].map((items, index) => {
                                                            return (
                                                                <tr className={"bg-gray-100"} key={index}>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{items.items_name ? items.items_name : ''}</td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <input
                                                                            type="text"
                                                                            className="form-input-sm-control border-gray-300 focus:border-blue-600"
                                                                            placeholder={
                                                                            items.total_order_quantity && Number(items.total_order_quantity) - (Number(items.total_order_receive_quantity ? items.total_order_receive_quantity : 0)+Number(items.draft_quantity))}
                                                                            defaultValue={items.quantities && items.quantities > 0 ? items.quantities : null}
                                                                            onKeyUp={(e) => {
                                                                                const data = {
                                                                                    field_name: "quantities",
                                                                                    value: e.target.value,
                                                                                    id: items.receive_item_id
                                                                                }

                                                                                let existingQuantity = items.total_order_quantity && Number(items.total_order_quantity) - (Number(items.total_order_receive_quantity ? items.total_order_receive_quantity : 0)+Number(items.draft_quantity))
                                                                                if (Number(existingQuantity) < Number(e.target.value)) {
                                                                                    openConfirmModal({
                                                                                        title: 'Quantity should be 0 to ' + existingQuantity,
                                                                                        centered: true,
                                                                                        children: (
                                                                                            <Text size="sm">
                                                                                                {'Are you sure , you want to received for quantity ' + e.target.value + ' ? Press confirm, if you want to confirm otherwise cancel.'}
                                                                                            </Text>
                                                                                        ),
                                                                                        labels: {
                                                                                            confirm: (t('Confirm')),
                                                                                            cancel: (t("Cancel"))
                                                                                        },
                                                                                        confirmProps: {color: 'green'},
                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                        onConfirm: () => {
                                                                                            dispatch(ReceiveItemsInlineUpdate(data))
                                                                                        }
                                                                                    });
                                                                                } else {
                                                                                    dispatch(ReceiveItemsInlineUpdate(data))
                                                                                }
                                                                            }}
                                                                            required={true}
                                                                        />
                                                                    </td>
                                                                    <td width="100"
                                                                        className="w-1/24 border-collapse border border-slate-400 text-center">{items.item_unit_name ? items.item_unit_name : ''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{items.projects_name ? items.projects_name : ''}</td>
                                                                    <td width="100"
                                                                        className="w-1/24 border-collapse border border-slate-400 text-center">{items.purchase_orders ? items.purchase_orders : ''}</td>
                                                                    <td width="100"
                                                                        className="w-1/24 border-collapse border border-slate-400 text-center">{items.purchase_requisitions ? items.purchase_requisitions : ''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <input
                                                                            type="textarea"
                                                                            className="form-input-sm-control border-gray-300 focus:border-blue-600"
                                                                            placeholder="Remark"
                                                                            defaultValue={items.comments ? items.comments : null}
                                                                            onBlur={(e) => {
                                                                                const data = {
                                                                                    field_name: "comments",
                                                                                    value: e.target.value,
                                                                                    id: items.receive_item_id
                                                                                }
                                                                                dispatch(ReceiveItemsInlineUpdate(data))
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <Select
                                                                            placeholder={t('ChooseQuality')}
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={['Good', 'Poor', 'Medium']}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={e => {
                                                                                const data = {
                                                                                    field_name: "quality",
                                                                                    value: e,
                                                                                    id: items.receive_item_id
                                                                                }
                                                                                dispatch(ReceiveItemsInlineUpdate(data))
                                                                                dispatch(setLoading(true))
                                                                            }}
                                                                            value={items.quality}
                                                                            required={true}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br/>
                                            </section>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReceivedNoteDetails