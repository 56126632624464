import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {Loader, LoadingOverlay, Select, Text, TextInput} from "@mantine/core";
import {projectDropdown, projectDropdownUserWise} from "../../../../store/project/projectSlice";
import {
    getClaimItemSendBack,
    getPoDropdown, openItem, setCheckOpenItem,
    setPoDropdownLoading, setPoItemCloseId, setPoItemCloseModel,
    setRemoveOpenItem
} from "../../../../store/purchaseOrder/purchaseOrderSlice";
import receivedItemReducer, {
    setProjectData,
    setLoading,
    setPOID, ReceivableItem, setReceivableItem, setCheckItem, setRemoveItem, setEmptyCheckItem
} from "../../../../store/receivedItem/receivedItemSlice";
import {HiOutlineExclamation} from "react-icons/hi";
import {openConfirmModal} from "@mantine/modals";
import CloseOpenItemModel from "../../PurchaseOrder/CloseOpenItemModel";
import CloseReceivedItemModel from "../../PurchaseOrder/CloseReceivedItemModel";


function ProjectPoInformation(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();


    // const projectDropdownData = useSelector((state) => state.projectReducer.projects)
    const projectDropdownData = useSelector((state) => state.projectReducer.projectsUserWise)
    const poDropdownData = useSelector((state) => state.receivedItemReducer.poDropdown)
    // const poDropdownData = useSelector((state) => state.purchaseOrderReducer.poDropdown)
    // const poDropdownLoading = useSelector((state) => state.purchaseOrderReducer.poDropdownLoading)
    const poDropdownLoading = useSelector((state) => state.receivedItemReducer.poDropdownLoading)
    const projectData = useSelector((state) => state.receivedItemReducer.projectData)
    const poID = useSelector((state) => state.receivedItemReducer.poID)
    const receivableItem = useSelector((state) => state.receivedItemReducer.receivableItem)
    const loading = useSelector((state) => state.receivedItemReducer.loading)
    const poItemCloseModel = useSelector((state) => state.purchaseOrderReducer.poItemCloseModel)

    const [checkList, setCheckList] = useState([]);


    useEffect(()=>{
        dispatch(projectDropdownUserWise())
    },[]);

    const projectPOWiseData = (po) => {
        const params = {
            project_id : projectData?projectData:null,
            po_id : po
        }
        setTimeout(()=>{
            dispatch(ReceivableItem(params))
        },500)
    }

    const CheckItemsHandel = (e, items) => {
        if (e.target.checked === true) {
            projectPOWiseData(items.purchase_orders)
            setCheckList({...checkList,[e.target.value] : Number(items.po_item_id)});
            dispatch(setCheckItem(items))
        } else {
            dispatch(setRemoveItem(items.po_item_id))
            delete checkList[e.target.value];
            setCheckList({...checkList}) ;
            if (Object.keys(checkList).length == 0 && poID == null){
                const params = {
                    project_id : projectData?projectData:null,
                }
                dispatch(ReceivableItem(params))
            }
        }
    }

    return(
        <>
            <div className="md:grid md:grid-cols-5 md:gap-6">
                <div className="mt-5 md:col-span-5 md:mt-0">
                    <div className="shadow sm:rounded">
                        <div className="space-y-1 bg-gray-50 px-4 py-2 ">

                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                <div className=" w-1/2 items-center">
                                    <label htmlFor="vendor" className="form-input-sm-label">{t('ProjectName')}</label>
                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                        <Select
                                            placeholder={t("ChooseProjectName")}
                                            searchable
                                            allowDeselect
                                            nothingFound="No options"
                                            withAsterisk
                                            data={projectDropdownData}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            dropdownPosition="bottom"
                                            value={projectData?projectData:null}
                                            onChange={(e)=>{
                                                dispatch(setLoading(true))
                                                dispatch(setReceivableItem([]))
                                                setCheckList([])
                                                dispatch(setPoDropdownLoading(true))
                                                dispatch(
                                                    setProjectData(e)
                                                )
                                                dispatch(setEmptyCheckItem([]))
                                                /*setTimeout(()=>{
                                                    dispatch(getPoDropdown({
                                                        project_id : e
                                                    }))
                                                },500)*/

                                                const params = {
                                                    project_id : e
                                                }
                                                setTimeout(()=>{
                                                    dispatch(ReceivableItem(params))
                                                },500)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" w-1/2 items-center">
                                    <label htmlFor="vendor" className="form-input-sm-label">{t('PurchaseOrder')}</label>
                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                <Select
                                                    placeholder={t("ChoosePurchaseOrder")}
                                                    searchable clearable
                                                    allowDeselect
                                                    nothingFound="No options"
                                                    withAsterisk
                                                    data={poDropdownData?poDropdownData:''}
                                                    transition="pop-top-left"
                                                    transitionDuration={80}
                                                    transitionTimingFunction="ease"
                                                    value={poID?poID:null}
                                                    onChange={(e)=> {
                                                        dispatch(setLoading(true))
                                                        dispatch(setPOID(e))
                                                        dispatch(setEmptyCheckItem([]))

                                                        const params = {
                                                            project_id : projectData?projectData:null,
                                                            po_id : e
                                                        }
                                                        setTimeout(()=>{
                                                            dispatch(ReceivableItem(params))
                                                        },500)
                                                    }}
                                                />


                                    </div>
                                </div>
                            </div>

                            <LoadingOverlay
                                loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                visible={loading || poDropdownLoading}
                                overlayBlur={2}
                            />

                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2 mt-5'>
                                        <div className="mx-auto justify-items-right w-full">
                                            <section className="-mx-0.5">
                                                <div className="flex flex-col justify-center h-full">
                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                        <tr>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('Particulars')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('Unit')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('PONO')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('POQuantity')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('ReceivedQuantity')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('BeforeApproveQuantity')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('RemainQuantity')}</div>
                                                            </th>
                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('vendorBuyer')}</div>
                                                            </th>
                                                            {/*<th className="p-2 border-collapse border border-slate-400">
                                                                <div className="font-semibold text-center">{t('')}</div>
                                                            </th>*/}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { receivableItem && receivableItem.length > 0 && receivableItem.map((items, index) => {
                                                            return (
                                                                <tr className={"bg-gray-100"} key={index}>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {++index}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <input
                                                                            onChange={(e) => {
                                                                                CheckItemsHandel(e, items)
                                                                            }}
                                                                            name={"test"}
                                                                            type="checkbox"
                                                                            value={items.po_item_id}
                                                                            className="w-4 h-4 p-2 text-blue-600 bg-gray-100"
                                                                            checked={checkList && checkList.hasOwnProperty(items.po_item_id)?true:false}
                                                                        />
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{items.items_name?items.items_name:''}</td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{items.item_unit?items.item_unit:''}</td>
                                                                    <td  className="w-1/24 border-collapse border border-slate-400 text-center">{items.purchase_orders?items.purchase_orders:''}</td>
                                                                    <td  className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {items.quantities?items.quantities:''}
                                                                    </td>
                                                                    <td  className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {items.total_order_receive_quantity?items.total_order_receive_quantity:0}
                                                                    </td>
                                                                    <td  className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {items.draft_quantity?items.draft_quantity:0}
                                                                    </td>
                                                                    <td  className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {
                                                                            Number(items.quantities)-(Number(items.total_order_receive_quantity)+items.draft_quantity)
                                                                        }
                                                                        {/*{items.quantities?(items.quantities-(items.total_order_receive_quantity?Number(items.total_order_receive_quantity)+items.draft_quantity:0)):0}*/}
                                                                    </td>
                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        {items.vendors_name?items.vendors_name:(items.buyer_user_name && items.buyer_user_name != ''?items.buyer_user_name:items.buyer_email)}
                                                                    </td>
                                                                    {/*<td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                        <button
                                                                            type="button"
                                                                            className="px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                                                            onClick={(e)=> {
                                                                                dispatch(setPoItemCloseModel(true))
                                                                                dispatch(setPoItemCloseId(items.po_item_id))
                                                                            }}>Close</button>
                                                                    </td>*/}
                                                                </tr>
                                                            );
                                                        }) }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br/>
                                            </section>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
            {poItemCloseModel?<CloseReceivedItemModel />:''}

        </>
    )
}

export default ProjectPoInformation