import React, {useEffect} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiArrowCircleRight, HiOutlineExclamation,
    HiOutlineOfficeBuilding
    , HiArrowCircleLeft, HiOutlineFilter, HiOutlinePlus, HiOutlineChartSquareBar, HiOutlineExclamationCircle
} from "react-icons/hi";
import { Group, Text, ScrollArea, MultiSelect, SimpleGrid, Image, Button} from "@mantine/core";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Dropzone} from "@mantine/dropzone";
import {useMantineTheme} from "@mantine/styles";
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import {showNotification} from "@mantine/notifications";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";



function CreateUser() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const token = localStorage.getItem('user_token');
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    const [imageFile, setImageFile] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const previews = imageFile.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return (
            <Image
                key={index}
                src={imageUrl}
                imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
            />
        );
    });

    // start get project dropdown
    const [projectData, setProjectData] = useState([]);
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'label': type.projects_name, 'value': Number(type.id)
            })
        })

    const ProjectDataHandel = (e) => {
        setProjectData(e);
        setValue('project',e)
    }

    // END PROJECT DROPDOWN
    //
    // USER ROLE DROPDOWN
    const [roleData, setRoleData] = useState([]);
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/user-group/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setRoles(res.data.data);
            });
    }, []);

    let rolesDropdown =
        roles.map((type, index) => {
            return ({
                'label': type.name, 'value': Number(type.id)
            })
        })

    const roleDataHandel = (e) => {
        setRoleData(e);
        setValue('role',e)
    }

    // END ROLE DROPDOWN

    //Form validation
    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required(t("EnterFullName")),
        user_name: Yup.string().required(t('EnterUserName')),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        cellphone: Yup.string().required(t("EnterPhoneNumber")),
        project: Yup.array().required(t("ChooseProject")).nullable(),
        role: Yup.array().required(t("ChooseRole")).nullable(),
        password: Yup.string().required(t("EnterPassword")),
        confirm_password: Yup.string()
            .required(t("ConfirmPassword"))
            .oneOf([Yup.ref('password')], t('PasswordNotMatch'))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        if (data) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `multipart/form-data`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: data
            })
                .then(res => {
                    if (res.data.status == 201 && res.data.message == 'created'){
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('DataAddedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'green',
                        });
                        setTimeout(()=>{
                            setSpinner(false);
                            reset();
                            navigate('/user')
                        },1000)
                    }else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: res.data.message,
                            message: (t('DataLoad')),
                            autoClose: 1500,
                            disallowClose: true,
                            color: 'red',
                        });
                    }
                })
                .catch(function (error) {
                    toast.error(res.data.message);
                })
        }
    }




    return (
        <>
            <main className="w-full">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('User')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('UserInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2 mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <Link to='/user/' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>{t('Lists')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border ">

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">

                                                                {/*                USER INFORMATION START                   */}
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 ">
                                                                    <div className="md:col-span-1">
                                                                        <div className="px-4 sm:px-0 ml-2">
                                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("UserInformation")}</h3>
                                                                            <p className="mt-1 text-sm text-gray-600">
                                                                                {t('UserDetails')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                                                        <div className="shadow sm:rounded-md">
                                                                            <div className="bg-gray-50 px-4 py-5 sm:p-6">

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="full_name" className="form-input-sm-label-required">{t('FullName')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.full_name?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.full_name?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                type="text"
                                                                                                {...register("full_name")}
                                                                                                className={`form-input-sm-control ${errors.full_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterFullName")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="full_name" className="form-input-sm-label-required">{t('UserName')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.user_name?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.user_name?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                type="text"
                                                                                                {...register("user_name")}
                                                                                                className={`form-input-sm-control ${errors.user_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterUserName")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="email" className="form-input-sm-label-required">{t('Email')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.email?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.email?.message}
                                                                                            </div>}

                                                                                            {errors.email?.type === 'email' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.email?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("email")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterEmail")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="phone" className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.cellphone?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.cellphone?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("cellphone")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.cellphone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterPhoneNumber")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="designations" className="form-input-sm-label">{t('Designation')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("designations")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control border-gray-300 focus:border-blue-600 `}
                                                                                                placeholder={t("EnterDesignation")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="password" className="form-input-sm-label-required">{t('Password')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.password?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.password?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("password")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.password ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("EnterPassword")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/2 items-center">
                                                                                        <label htmlFor="confirm_password" className="form-input-sm-label-required">{t('ConfirmPassword')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.confirm_password &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.confirm_password?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("confirm_password")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.confirm_password ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t("ConfirmPassword")}
                                                                                                defaultValue={''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="project" className="form-input-sm-label-required">{t('Project')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.project &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.project?.message}
                                                                                            </div>}

                                                                                            <MultiSelect
                                                                                                {...register("project")}
                                                                                                placeholder={t('ChooseProject')}
                                                                                                searchable clearable
                                                                                                nothingFound="No options"
                                                                                                data={projectDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={ProjectDataHandel}
                                                                                                value={projectData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="role" className="form-input-sm-label-required">{t('UserRole')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.role &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.role?.message}
                                                                                            </div>}

                                                                                            <MultiSelect
                                                                                                {...register("role")}
                                                                                                placeholder={t('ChooseRole')}
                                                                                                searchable clearable
                                                                                                nothingFound="No options"
                                                                                                data={rolesDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={roleDataHandel}
                                                                                                value={roleData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="role" className="form-input-sm-label">{t('UserImage')}</label>
                                                                                        <Dropzone
                                                                                            onDrop={(file) => {
                                                                                                setImageFile(file)
                                                                                                setValue("file", file[0])
                                                                                            }}
                                                                                            onReject={(files) => console.log('rejected files', files)}
                                                                                            maxSize={3 * 1024 ** 2}
                                                                                            accept={['image/png', 'image/jpeg', 'image/jpg']}
                                                                                            multiple={false}
                                                                                        >
                                                                                            <Group position="center" spacing="xl" style={{ minHeight: 100, pointerEvents: 'none' }}>
                                                                                                <Dropzone.Accept>
                                                                                                    <IconUpload
                                                                                                        size={50}
                                                                                                        stroke={1.5}
                                                                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                    />
                                                                                                </Dropzone.Accept>
                                                                                                <Dropzone.Reject>
                                                                                                    <IconX
                                                                                                        size={50}
                                                                                                        stroke={1.5}
                                                                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                    />
                                                                                                </Dropzone.Reject>

                                                                                                <>
                                                                                                <Dropzone.Idle>
                                                                                                    <IconPhoto size={50} stroke={1.5} />
                                                                                                </Dropzone.Idle>
                                                                                                <Text size="xl" inline>
                                                                                                    {t('DragProfileImage')}
                                                                                                </Text>
                                                                                                <Text size="sm" color="dimmed" inline mt={7}>
                                                                                                    Attach as many files as you like, each file should not exceed 5mb
                                                                                                </Text>

                                                                                                <SimpleGrid
                                                                                                    cols={4}
                                                                                                    breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                                                                                                    mt={previews.length > 0 ? 'xl' : 0}
                                                                                                >
                                                                                                    {previews}
                                                                                                </SimpleGrid></>
                                                                                            </Group>
                                                                                        </Dropzone>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*                USER INFORMATION START                   */}

                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="justify-items-center">
                                                                <div className="text-right border-t pt-0.5 mb-0.5 pr-2">
                                                                    <Button
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}></AiOutlineLoading3Quarters>
                                                                        ) : (<HiArrowCircleRight size={16}
                                                                                                 className={'mr-2'}></HiArrowCircleRight>)}
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default CreateUser