import React, {useEffect} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActionIcon, Box, Group, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {openConfirmModal} from "@mantine/modals";
import {vendorDropdown} from "../../../store/vendor/vendorSlice";

import {
    advancePaymentIndex,
    setOpened,
    setSortStatus,
    setSpinner,
    setRefresh,
    setPage,
    setFetching,
    setActiveTab,
    advancePaymentVerify
} from "../../../store/advancePayment/advancePaymentSlice";
import {setPoViewID, setPoViewModel} from "../../../store/purchaseOrder/purchaseOrderSlice";
import PoViewModel from "../PurchaseOrder/PoViewModel";
import AdvancePaymentFilter from "./AdvancePaymentFilter";
const PAGE_SIZE = 20;

function AdvancePaymentDashboard(){
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const tableHeightWithFilter = localStorage.getItem('tableHeightWithFilter')

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const ROLE_ADVANCE_PAYMENT_ALL = decoded.roles.find(role=>{
        return role === "ROLE_ADVANCE_PAYMENT_ALL" ;
    })
    const ROLE_ADVANCE_PAYMENT_HEAD = decoded.roles.find(role=>{
        return role === "ROLE_ADVANCE_PAYMENT_HEAD" ;
    })
    const ROLE_ADVANCE_PAYMENT_LOCAL = decoded.roles.find(role=>{
        return role === "ROLE_ADVANCE_PAYMENT_LOCAL" ;
    })

    const fetching = useSelector((state) => state.advancePaymentReducer.fetching)
    const page = useSelector((state) => state.advancePaymentReducer.page)
    const activeTab = useSelector((state) => state.advancePaymentReducer.activeTab)
    const sortStatus = useSelector((state) => state.advancePaymentReducer.sortStatus)
    const advancePayments = useSelector((state) => state.advancePaymentReducer.advancePayments)
    const totalItem = useSelector((state) => state.advancePaymentReducer.totalItem)
    const filterData = useSelector((state) => state.advancePaymentReducer.filterData)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(()=>{
        dispatch(vendorDropdown())
    },[])

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            vendor : filterData.vendor ? filterData.vendor:'',
            po : filterData.po ? filterData.po:'',
        }
            dispatch(advancePaymentIndex(param))
    }, [page,activeTab,sortStatus,fetching]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('AdvancePayment')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllAdvancePaymentRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <Link to='/payment/advance'
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">

                                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                                    <AdvancePaymentFilter />
                                                                </form>

                                                                    <div className="h-11">
                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'not-verify' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'not-verify')}>
                                                                            {t('AdvancePayment')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'archive' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'archive')}>
                                                                            {t('AdvanceArchive')}
                                                                        </button>
                                                                    </div>

                                                                    <Box sx={{height: tableHeightWithFilter}}>
                                                                        <DataTable
                                                                            withBorder
                                                                            records={advancePayments}
                                                                            withColumnBorders={1}
                                                                            style={(theme) => ({
                                                                                color: 'red',
                                                                            })}
                                                                            columns={[
                                                                                {
                                                                                    accessor: 'index',
                                                                                    title: 'SL#',
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (advancePayments.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                },
                                                                                {
                                                                                    accessor: 'created_date',
                                                                                    title: <Text mr="xs">Created
                                                                                        Date</Text>,
                                                                                    textAlignment: 'center'
                                                                                },
                                                                                {
                                                                                    accessor: 'po_id',
                                                                                    title: 'PONo',
                                                                                    sortable: true,
                                                                                    textAlignment: 'center',
                                                                                    render: (item) => (
                                                                                        <Group spacing={4}
                                                                                               position="center" noWrap>
                                                                                            <ActionIcon color="blue">
                                                                                                <button
                                                                                                    className="inline-flex items-center px-2 py-1 bg-lime-500 hover:bg-lime-600 text-white text-xs"
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setPoViewModel(true))
                                                                                                        dispatch(setPoViewID(item.po_id))
                                                                                                    }}
                                                                                                >
                                                                                                    {/*<HiEye size={16}/>*/} {item.po_id}
                                                                                                </button>
                                                                                            </ActionIcon>
                                                                                        </Group>
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    accessor: 'vendorsName',
                                                                                    title: 'Vendor/Buyer',
                                                                                    textAlignment: 'center',
                                                                                    render: ({vendorsName, username}) =>
                                                                                        <>
                                                                                            {
                                                                                                (vendorsName ? vendorsName : '')
                                                                                            }
                                                                                            {
                                                                                                (username ? username : '')
                                                                                            }
                                                                                        </>
                                                                                },
                                                                                {
                                                                                    accessor: 'netTotal',
                                                                                    title: 'Amount',
                                                                                    textAlignment: "right"
                                                                                },
                                                                                {
                                                                                    accessor: 'amount',
                                                                                    title: 'Advance',
                                                                                    textAlignment: "right",
                                                                                    sortable: true
                                                                                },
                                                                                {
                                                                                    accessor: 'paymentNumber',
                                                                                    title: 'Payment Number',
                                                                                    textAlignment: "center"
                                                                                },

                                                                                {
                                                                                    accessor: 'actions',
                                                                                    title: <Text mr="xs">Action</Text>,
                                                                                    textAlignment: 'center',
                                                                                    hidden: activeTab == 'archive',
                                                                                    render: (item) => (
                                                                                        <Group spacing={4}
                                                                                               position="center" noWrap>

                                                                                            {
                                                                                                (ROLE_ADVANCE_PAYMENT_ALL || ROLE_SUPER_ADMIN || ROLE_ADVANCE_PAYMENT_HEAD || ROLE_ADVANCE_PAYMENT_LOCAL) && !item.verify_id &&
                                                                                                <ActionIcon
                                                                                                    color="blue">
                                                                                                    <button
                                                                                                        className="inline-flex items-center px-2 py-1 bg-rose-500 hover:bg-rose-600 text-white text-xs"
                                                                                                        onClick={(e) => {
                                                                                                            openConfirmModal({
                                                                                                                title: (t('VerifyThisAdvancePayment')),
                                                                                                                centered: true,
                                                                                                                children: (
                                                                                                                    <Text
                                                                                                                        size="sm">
                                                                                                                        {t('AreYouSureVerifyThisAdvancePayment')}
                                                                                                                    </Text>
                                                                                                                ),
                                                                                                                labels: {
                                                                                                                    confirm: (t('Confirm')),
                                                                                                                    cancel: (t("Cancel"))
                                                                                                                },
                                                                                                                confirmProps: {color: 'green'},
                                                                                                                onCancel: () => console.log(t('Cancel')),
                                                                                                                onConfirm: () => {
                                                                                                                    const approveData = {
                                                                                                                        rfp_id: item.id
                                                                                                                    }
                                                                                                                    dispatch(advancePaymentVerify(approveData))
                                                                                                                }
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        Verify
                                                                                                    </button>
                                                                                                </ActionIcon>
                                                                                            }
                                                                                        </Group>
                                                                                    ),
                                                                                },

                                                                            ]}
                                                                            totalRecords={totalItem}
                                                                            recordsPerPage={PAGE_SIZE}
                                                                            page={page}
                                                                            onPageChange={(p) => {
                                                                                dispatch(setPage(p))
                                                                                dispatch(setFetching(true))
                                                                            }}
                                                                            fetching={fetching}
                                                                            loaderSize="xs"
                                                                            loadercolor="blue"
                                                                            loaderBackgroundBlur={1}
                                                                            sortStatus={sortStatus}
                                                                            onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                        />
                                                                    </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {poViewModel?<PoViewModel />:''}

        </>
    )
}
export default AdvancePaymentDashboard