import React, {useEffect, useState} from "react";
import {
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiEye, HiCloudDownload, HiTrash, HiCurrencyDollar, HiOutlineExclamation
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Accordion, ActionIcon, Box, Drawer, Grid, Group, ScrollArea, Select, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {
    poIndexList,
    setFetching,
    setPage,
    setActiveTab,
    setSortStatus,
    poApprove,
    setSpinner,
    setOpened,
    setRefresh,
    setFilterData,
    setPoViewModel,
    setPoViewID,
    poProcessUpdate,
    setEmptyPODetails,
    setAdvancePaymentModel,
    setAdvancePaymentModelId,
    poDelete,
    inlineUpdatePO,
    setProcessMode, setVendor, setPurchaseType, setBuyer, setLoading
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {openConfirmModal} from "@mantine/modals";
import {vendorDropdown} from "../../../store/vendor/vendorSlice";
import PoViewModel from "./PoViewModel";
import axios from "axios";
import AdvancePaymentModel from "./AdvancePaymentModel";
import {IconPlus} from "@tabler/icons-react";
import PoFilter from "./PoFilter";
const PAGE_SIZE = 20;

function Dashboard(){
    const {t, i18n} = useTranslation();

    const tableHeight = localStorage.getItem('tableHeight')
    const tableHeightWithFilter = localStorage.getItem('tableHeightWithFilter')
    const drawerHeight = localStorage.getItem('drawerHeight')

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_TWO";
    })
    const ROLE_PURCHASE_ORDER_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_THREE";
    })
    const ROLE_PURCHASE_ORDER_ADD = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_ADD";
    })
    const ROLE_PURCHASE_ORDER_VIEW = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_VIEW";
    })
    const ROLE_PURCHASE_ORDER_EDIT = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_EDIT";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_PURCHASE_ORDER_DELETE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_DELETE";
    })

    const leftMenuCollapsed = useSelector((state) => state.commonReducer.leftMenuCollapsed)
    const [containerWidth,setContainerWidth] = useState(localStorage.getItem('containerWidth'))

    useEffect(() => {
        if (leftMenuCollapsed){
            setContainerWidth(window.innerWidth - 113 + "px")
        }else{
            setContainerWidth(localStorage.getItem('containerWidth'))
        }
    },[leftMenuCollapsed])

    const fetching = useSelector((state) => state.purchaseOrderReducer.fetching)
    const purchaseOrders = useSelector((state) => state.purchaseOrderReducer.purchaseOrders)
    const totalItem = useSelector((state) => state.purchaseOrderReducer.totalItem)
    const page = useSelector((state) => state.purchaseOrderReducer.page)
    const activeTab = useSelector((state) => state.purchaseOrderReducer.activeTab)
    const sortStatus = useSelector((state) => state.purchaseOrderReducer.sortStatus)
    const spinner = useSelector((state) => state.purchaseOrderReducer.spinner)
    const opened = useSelector((state) => state.purchaseOrderReducer.opened)
    const filterData = useSelector((state) => state.purchaseOrderReducer.filterData)
    const refresh = useSelector((state) => state.purchaseOrderReducer.refresh)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)
    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)
    const poAmendmentMsg = useSelector((state) => state.purchaseOrderReducer.poAmendmentMsg)
    const poAmendmentId = useSelector((state) => state.purchaseOrderReducer.poAmendmentId)
    const advancePaymentModel = useSelector((state) => state.purchaseOrderReducer.advancePaymentModel)


    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(()=>{
        dispatch(vendorDropdown())
        dispatch(setEmptyPODetails())
    },[])

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            vendor : filterData.vendor ? filterData.vendor:'',
            po : filterData.po ? filterData.po:'',
            poRef : filterData.poRef ? filterData.poRef:'',
        }
            dispatch(poIndexList(param))
    }, [page,activeTab,sortStatus,refresh,fetching]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);

    const poApproved = (e,poID,status) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('ApprovedThisPO')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToApprovedThisPO')}
                </Text>
            ),
            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
            confirmProps: { color: 'green' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                const approveData = {
                    po_id : poID,
                    status : status
                }
                dispatch(poApprove(approveData))
                dispatch(setFetching(true))
            }
            ,
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PurchaseOrder')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllPurchaseOrderRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {/*<button
                                                                onClick={() => dispatch(setOpened(true))}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>*/}

                                                            {
                                                                ROLE_PURCHASE_ORDER_ADD &&
                                                                <button onClick={(event) => {
                                                                    navigate('/purchase-order/create')
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/purchase-order'
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">

                                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                                    <PoFilter />
                                                                </form>


                                                                <div className="h-11">
                                                                    <div className="flow-root">
                                                                        <div className="float-left">
                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'all')}>
                                                                                {t('All')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Created' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Created')}>
                                                                            {t('Created')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Verified' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Verified')}>
                                                                                {t('Verified')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Validated' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Validated')}>
                                                                                {t('Validated')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Approved' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Approved')}>
                                                                                {t('Approved')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'DIP' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'RIP')}>
                                                                                {t('RIP')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Received' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Received')}>
                                                                                {t('Received')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Hold' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Hold')}>
                                                                                {t('Hold')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Cancel' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'ReceiveCancel')}>
                                                                                {t('Cancel')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Amendment' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Amendment')}>
                                                                                {t('Amendment')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Draft' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Draft')}>
                                                                                {t('Draft')}
                                                                            </button>
                                                                        </div>
                                                                        {/*<div className="float-right">

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Draft' ? 'bg-rose-600' : 'bg-rose-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => navigate(0)}>
                                                                                {t('Reset')}
                                                                            </button>

                                                                        </div>*/}
                                                                    </div>
                                                                </div>


                                                                {/*<Drawer
                                                                    opened={opened}
                                                                    onClose={() => dispatch(setOpened(false))}
                                                                    title={t('PurchaseOrderFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{height: drawerHeight}}>
                                                                            <div className="mb-5">
                                                                                <label htmlFor="SelectProject"
                                                                                       className="form-input-sm-label">{t('Vendor')}</label>
                                                                                <Select
                                                                                    placeholder={t("ChooseVendor")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={vendorDropdownData}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={(e) => {
                                                                                        dispatch(setFilterData({
                                                                                            ...filterData,
                                                                                            ['vendor']: e
                                                                                        }))
                                                                                    }}
                                                                                    value={filterData && filterData.vendor ? filterData.vendor : null}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="Keyword"
                                                                                       className="form-input-sm-label">PO
                                                                                    No</label>
                                                                                <TextInput
                                                                                    placeholder={t("EnterPoNumber")}
                                                                                    value={filterData && filterData.po ? filterData.po : ''}
                                                                                    withAsterisk
                                                                                    onChange={(e) => {
                                                                                        dispatch(setFilterData({
                                                                                            ...filterData,
                                                                                            ['po']: e.target.value
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                        </ScrollArea>

                                                                        <div className="mb-2 mt-5 text-right">
                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {
                                                                                                    'label': 'Created Date',
                                                                                                    'value': 'createdDate'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'PO No',
                                                                                                    'value': 'id'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'Status',
                                                                                                    'value': 'process'
                                                                                                }

                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e) => {
                                                                                            dispatch(setSortStatus({
                                                                                                ...sortStatus,
                                                                                                ['columnAccessor']: e
                                                                                            }))
                                                                                        }}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={
                                                                                            [
                                                                                                {
                                                                                                    'label': 'ASC',
                                                                                                    'value': 'ASC'
                                                                                                },
                                                                                                {
                                                                                                    'label': 'DESC',
                                                                                                    'value': 'DESC'
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={(e) => {
                                                                                            dispatch(setSortStatus({
                                                                                                ...sortStatus,
                                                                                                ['direction']: e
                                                                                            }))
                                                                                        }}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit'
                                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        <HiOutlineSearch
                                                                                            className={'mr-2'}
                                                                                            size={16}></HiOutlineSearch>
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                                </Drawer>*/}

                                                                <Box sx={{height: tableHeightWithFilter}}>

                                                                    <DataTable
                                                                        withBorder
                                                                        records={purchaseOrders}
                                                                        withColumnBorders={1}
                                                                        style={(theme) => ({
                                                                            color: 'red',
                                                                        })}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                textAlignment: 'right',
                                                                                // render: (item) => (purchaseOrders.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                render: (item) => (
                                                                                    <span style={{ color: item.isOld == 1 ? 'red' : 'initial' }}>
                                                                                        {purchaseOrders.indexOf(item) + 1 + PAGE_SIZE * (page - 1)}
                                                                                    </span>
                                                                                )
                                                                            },
                                                                            {
                                                                                accessor: 'createdDate',
                                                                                title: <Text mr="xs">Date</Text>,
                                                                                sortable: true,
                                                                                width: 95
                                                                            },
                                                                            {
                                                                                accessor: 'id',
                                                                                title: 'PONo',
                                                                                sortable: true,
                                                                                width: 90
                                                                            },
                                                                            {
                                                                                accessor: 'projectsCategoryName',
                                                                                title: 'Company Name'
                                                                            },
                                                                            {
                                                                                accessor: 'vendorOrname',
                                                                                title: 'Vendor/Buyer',
                                                                                render: ({
                                                                                             vendorsName,
                                                                                             buyerEmail,
                                                                                             buyer_username
                                                                                         }) =>
                                                                                    <>
                                                                                        {
                                                                                            (vendorsName ? vendorsName : '')
                                                                                        }
                                                                                        {
                                                                                            (buyer_username ? buyer_username : buyerEmail)
                                                                                        }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'process',
                                                                                title: 'Status',
                                                                                sortable: true,
                                                                                textAlignment: 'center',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.process === 'Draft' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-rose-300	w-20 block text-center">Draft</span>}
                                                                                        {item.process === 'Created' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-green-300	 w-20 block text-center">Created</span>}
                                                                                        {item.process === 'Verified' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-yellow-300	   w-20 block text-center">Verified</span>}
                                                                                        {item.process === 'Validated' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-purple-300	  w-20 block text-center">Validated</span>}
                                                                                        {item.process === 'Approved' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-lime-300	 w-20 block text-center">Approved</span>}
                                                                                        {item.process === 'RIP' && <span
                                                                                            className="items-center px-2 py-1 text-black text-xs bg-blue-300	 w-20 block text-center">RIP</span>}
                                                                                        {item.process === 'Received' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-orange-300	 w-20 block text-center">Received</span>}
                                                                                        {item.process === 'Hold' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-fuchsia-300 w-20 block text-center">Hold</span>}
                                                                                        {item.process === 'ReceiveCancel' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-pink-300 w-20 block text-center">Receive Cancel</span>}
                                                                                        {item.process === 'Amendment' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-red-300 w-20 block text-center">Amendment</span>}
                                                                                        {(item.amendmentByPoNo && item.amendmentByPoNo != null) &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-pink-300 w-20 block text-center">Amended {item.amendmentByPoNo}</span>}
                                                                                        {(item.amendmentToPoNo && item.amendmentToPoNo != null) &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-amber-300 w-20 block text-center">Amendment {item.amendmentToPoNo}</span>}
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'netTotal', title: 'Amount',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.path && item.netTotal &&
                                                                                            (item.netTotal).toFixed(2)
                                                                                        }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'createdUserName',
                                                                                title: 'Created By'
                                                                            },
                                                                            {
                                                                                accessor: 'deliveryDate',
                                                                                title: 'Delivery Date'
                                                                            },
                                                                            {
                                                                                accessor: 'path', title: '',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.path &&
                                                                                             <a title="Vendor Quotation Attach"
                                                                                                   target="new"
                                                                                                   rel="noreferrer"
                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-order/pdf-show/'+ item.id}/vendor-quotation`}
                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                                                    <HiCloudDownload/>
                                                                                                </a>
                                                                                        }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'approved',
                                                                                title: <Text mr="xs">Approve</Text>,
                                                                                textAlignment: 'center',
                                                                                hidden: !ROLE_PURCHASE_ORDER_APPROVE_ONE && !ROLE_PURCHASE_ORDER_APPROVE_TWO && !ROLE_PURCHASE_ORDER_APPROVE_THREE,
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {
                                                                                            (item.process === 'Created' && item.netTotal > 0 && ROLE_PURCHASE_ORDER_APPROVE_ONE) &&
                                                                                            // item.amendmentByPoNo?'':
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setPoViewModel(true))
                                                                                                    dispatch(setPoViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-yellow-600 hover:bg-yellow-700 text-white text-xs">
                                                                                                Verified
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.process === 'Verified' && ROLE_PURCHASE_ORDER_APPROVE_TWO) &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setPoViewModel(true))
                                                                                                    dispatch(setPoViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-indigo-600 hover:bg-indigo-700 text-white text-xs">
                                                                                                Validated
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.process === 'Validated' && ROLE_PURCHASE_ORDER_APPROVE_THREE) &&
                                                                                            <button
                                                                                                // onClick={(e) => poApproved(e, item.id,item.process)}
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setPoViewModel(true))
                                                                                                    dispatch(setPoViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-lime-600 hover:bg-lime-700 text-white text-xs">
                                                                                                Approved
                                                                                            </button>
                                                                                        }
                                                                                        {
                                                                                            item.process === 'Hold' &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    openConfirmModal({
                                                                                                        title: (t('ActiveThisPurchaseOrder')),
                                                                                                        centered: true,
                                                                                                        children: (
                                                                                                            <Text
                                                                                                                size="sm">
                                                                                                                {t('AreYouSureToActiveThisPO')}
                                                                                                            </Text>
                                                                                                        ),
                                                                                                        labels: {
                                                                                                            confirm: (t('Confirm')),
                                                                                                            cancel: (t("Cancel"))
                                                                                                        },
                                                                                                        confirmProps: {color: 'green'},
                                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                                        onConfirm: () => {
                                                                                                            let status = '';
                                                                                                            if (item.approveStatus == 0) {
                                                                                                                status = 'Created'
                                                                                                            } else if (item.approveStatus == 1) {
                                                                                                                status = 'Verified'
                                                                                                            } else if (item.approveStatus == 2) {
                                                                                                                status = 'Validated'
                                                                                                            }
                                                                                                            const data = {
                                                                                                                po_id: item.id,
                                                                                                                process: status
                                                                                                            }
                                                                                                            dispatch(setFetching(true))
                                                                                                            dispatch(poProcessUpdate(data))
                                                                                                        }
                                                                                                    });
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-fuchsia-600 hover:bg-fuchsia-700 text-white text-xs">
                                                                                                Active
                                                                                            </button>
                                                                                        }
                                                                                    </>,
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        {
                                                                                            ((item.process == 'Created' || item.process == 'Amendment' || item.process == 'Verified' || item.process == 'Validated' || item.process == 'Draft') && (ROLE_PURCHASE_ORDER_ADD || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="green"
                                                                                                        onClick={(e) => {
                                                                                                            (item.amendmentByPoNo && item.amendmentStatus == 0) ?
                                                                                                                navigate('/purchase-order/amendment/' + item.id) :
                                                                                                                navigate('/purchase-order/update/' + item.id);
                                                                                                        }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }


                                                                                        {
                                                                                            ((item.process == 'Approved') && (ROLE_PURCHASE_ORDER_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="green"
                                                                                                        onClick={(e) => {
                                                                                                            (item.amendmentByPoNo && item.amendmentStatus == 0) ?
                                                                                                                navigate('/purchase-order/amendment/' + item.id) :
                                                                                                                navigate('/purchase-order/update/' + item.id);
                                                                                                        }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => {
                                                                                                       dispatch(setPoViewModel(true))
                                                                                                       dispatch(setPoViewID(item.id))
                                                                                                   }}/>
                                                                                        </ActionIcon>

                                                                                        {
                                                                                            ((item.paymentType == 'advance-payment') && item.process == 'Approved' && ((ROLE_PURCHASE_ORDER_VIEW || ROLE_PURCHASE_ORDER_ADD || ROLE_PURCHASE_ORDER_EDIT) || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="red">
                                                                                                <HiCurrencyDollar
                                                                                                    size={16}
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setAdvancePaymentModel(true))
                                                                                                        dispatch(setAdvancePaymentModelId(item.id))
                                                                                                    }}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        {
                                                                                            item.process === 'Draft' &&
                                                                                            <ActionIcon color="red">
                                                                                                <HiTrash size={16}
                                                                                                         onClick={(e) => {
                                                                                                             openConfirmModal({
                                                                                                                 title: t('PurchaseOrderDelete'),
                                                                                                                 centered: true,
                                                                                                                 children: (
                                                                                                                     <Text
                                                                                                                         size="sm">
                                                                                                                         {t('AreYouSureToPurchaseOrderDelete')}
                                                                                                                     </Text>
                                                                                                                 ),
                                                                                                                 labels: {
                                                                                                                     confirm: (t('Confirm')),
                                                                                                                     cancel: (t("Cancel"))
                                                                                                                 },
                                                                                                                 confirmProps: {color: 'red'},
                                                                                                                 onCancel: () => console.log(t('Cancel')),
                                                                                                                 onConfirm: () => {
                                                                                                                     // console.log(item.id)
                                                                                                                     dispatch(poDelete(item.id))
                                                                                                                 }
                                                                                                             });
                                                                                                         }}/>
                                                                                            </ActionIcon>
                                                                                        }
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            dispatch(setFetching(true))
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {poViewModel ? <PoViewModel/> : ''}
            {advancePaymentModel ? <AdvancePaymentModel/> : ''}
        </>
    )
}

export default Dashboard