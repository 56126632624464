import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiOutlineUser,
    HiPencil, HiTrash
} from "react-icons/hi";
import {AiOutlineMinus} from "react-icons/ai";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box,ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {TbCurrencyTaka} from "react-icons/tb";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import container from "postcss/lib/container";
import CompanyTypeModal from "../master-data/company-type/CompanyTypeModal";
import SelectSearch from 'react-select-search';


const PAGE_SIZE = 1;

function TentativeBudget() {

    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height-63);
    var fromHeight = height-153+"px";


    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);

    //Form validation
    const validationSchema = Yup.object().shape({
        project: Yup.string().required(t("SelectProject")),
        receivedFrom : Yup.string().required(t('SelectReceivedFrom')),
        refGrn : Yup.string().required(t('RefNoIsRequired')),
        contactNumber : Yup.string().required(t('ContactNumberIsRequired')),
        buyer : Yup.string().required(t('SelectBuyer')),
        file : Yup.string().required(t('GRNFileAttachmentIsRequired'))
       
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //Table at end form add more rows
    const [noOfRows, setNoOfRows] = useState(1);
   
    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
         console.log(data)
        const formData = {
            projectName: data.name,
            workName : data.name,
            status:1
        };

        
    }

    return(
        <>
            <main className="flex flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                             {t('TentativeConstructionBudget')}
                                                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('MakeTentativeBudgetPlansForConstructionProjects')}</sub></span>
                                                    </div>
                                                </div>

                                            </div>
                                            {/*<div className={'flex flex-1 overflow-hidden w-full'}>*/}
                                            <div className={'flex flex-1 w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <header className="relative mb-1 bg-indigo-600 px-2 py-1">
                                                    <div className="text-white text-md font-bold leading-7"> {t('TentativeBudgetForm')}</div>
                                                </header>
                                                <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                    <div
                                                        className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                        style={{height:fromHeight}}
                                                    >                                                

                                                         {/* Components Section */}

                                                        <div className="py-2 hidden sm:block">
                                                            <div className="border-t border-gray-200" />
                                                        </div>


                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("Component")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('EnterComponentDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                                        <div className="mb-3 w-3/4 items-center">
                                                                            <label htmlFor="componentName" className="form-input-sm-label">{t('ComponentName')}</label>
                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <input
                                                                                    {...register("componentName")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="componentName"
                                                                                    id = "componentName" disabled
                                                                                    placeholder={t("Earth Work In Excavation For Footing For 5 Feet Height")}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="mb-3 w-3/4 items-center">
                                                                            <label htmlFor="unit" className="form-input-sm-label">{t('Unit')}</label>
                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <input
                                                                                    {...register("unit")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="unit"
                                                                                    id = "unit" disabled
                                                                                    placeholder={t("Cft")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                       
                                                                        <div className="mb-3 w-3/4 items-center">
                                                                            <label htmlFor="description" className="form-input-sm-label">{t('Description')}</label>
                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <textarea
                                                                                    {...register("description")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="description"
                                                                                    id = "description"
                                                                                    placeholder={t("")}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="mb-3 w-3/4 items-center">
                                                                            <label htmlFor="costHeader" className="form-input-sm-label">{t('CostHeader')}</label>
                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <input
                                                                                    {...register("costHeader")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="costHeader"
                                                                                    id = "costHeader"
                                                                                    placeholder={t("EnterCostHeader")}
                                                                                />
                                                                            </div>
                                                                        </div>            
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        
                                                        {/* Measurement Section */}
                                                        <br></br>
                                                        <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("Measurement")}</h3>
                                                                   
                                                                </div>
                                                            </div>
                                                        <div className="container mx-auto px-8 justify-items-center">
                                                                    <section class="px-2" >
                                                                    <div class="flex flex-col justify-center h-full">
                                                                        {/* <!-- Table --> */}
                                                                                <table class="table-fixed w-full shadow-md rounded border-separate border-spacing-y-4">
                                                                                    <thead class="text-xs font-semibold uppercase text-white bg-indigo-800">
                                                                                    <tr>
                                                                                    <th class="p-2 ml-2 space-x-8">
                                                                                                <div class="font-semibold text-left">{t('SerialNumber')}</div>
                                                                                            </th>
                                                                                            <th class="p-2 px-4">
                                                                                                <div class="font-semibold text-left">{t('Length')}</div>
                                                                                            </th>
                                                                                            <th class="p-3">
                                                                                                <div class="font-semibold text-left">{t('Operation')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Breadth')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Operation')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Height')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Quantity')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Unit')}</div>
                                                                                            </th>
                                                                                            

                                                                                            <th class="p-2">
                                                                                        <div class="font-semibold text-left">
                                                                                             {/* Button For Add New */}
                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-indigo-400 hover:bg-indigo-600 text-white text-xs" onClick={() => setNoOfRows(noOfRows + 1)}>
                                                                                            <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('Add')}
                                                                                            </button>

                                                                                        </div>
                                                                                            
                                                                                    </th>

                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody class="text-sm divide-y divide-gray-100">
                                                                                     
                                                                                        {/* Add New Rows */}
                                                                               
                                                                                        {[...Array(noOfRows)].map((elementInArray, index) => {

                                                                                                            return (
                                                                                                            <tr>
                                                                                                            <th scope="row">{index}</th>
                                                                                                           
                                                                                                            {/* Length Section*/}
                                                                                                            <td> <div class="w-2/3">
                                                                                                            <input
                                                                                                                    {...register("unit")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="unit" id="unit"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                </div>
                                                                                                                </td>

                                                                                                            {/* Operator Section */}
                                                                                                            <td>
                                                                                                                <div className='w-2/3 px-2'>
                                                                                                                <select
                                                                                                                {...register("operation")}
                                                                                                                name="operation" id="operation"
                                                                                                                className={`form-input-sm-control`}>
                                                                                                                <option value="">{t('')}</option>
                                                                                                                <option value="1">x</option>
                                                                                                                <option value="2">÷</option>
                                                                                                                <option value="3">+</option>
                                                                                                                <option value="3">-</option>
                                                                                                            </select>
                                                                                                            </div> </td>

                                                                                                            {/* Breadth Section */}
                                                                                                            <td><div class="w-2/3">
                                                                                                            <input
                                                                                                                    {...register("unit")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="unit" id="unit"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                </div>
                                                                                                                </td>

                                                                                                            {/* Operation 2 Section */}                      
                                                                                                            <td>
                                                                                                                <div className='w-2/3 px-2'>
                                                                                                                <select
                                                                                                                {...register("operation2")}
                                                                                                                name="operation2" id="operation2"
                                                                                                                className={`form-input-sm-control`}>
                                                                                                                <option value="">{t('')}</option>
                                                                                                                <option value="1">x</option>
                                                                                                                <option value="2">÷</option>
                                                                                                                <option value="3">+</option>
                                                                                                                <option value="3">-</option>
                                                                                                            </select>
                                                                                                            </div> </td>
                                                                                                                                        
                                                                                                            {/* Width Section */}
                                                                                                            <td><div class="w-2/3">
                                                                                                            <input
                                                                                                                    {...register("unit")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="unit" id="unit"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                </div>
                                                                                                                </td>
                                                                                                            
                                                                                                            

                                                                                                             {/* Quantity Section */}
                                                                                                             <td>
                                                                                                               
                                                                                                            <input
                                                                                                                    {...register("Quantity")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="remarks" id="remarks"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                
                                                                                                            </td>

                                                                                                            {/* Unit Section */}
                                                                                                            <td>
                                                                                                                <div class="w-2/3">
                                                                                                            <input
                                                                                                                    {...register("unit")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="unit" id="unit" disabled
                                                                                                                    placeholder={t("Cft")}
                                                                                                                />
                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td>
                                                                                                        <div className='px-3'>
                                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-red-600 hover:bg-red-200 text-white text-xs" onClick={() => setNoOfRows(noOfRows - 1)}>
                                                                                                        <AiOutlineMinus size={12} className={'mr-1'}></AiOutlineMinus>{t('')}
                                                                                                            </button>

                                                                                                            </div>
                                                                                                        </td>
                                                                                                                 

                                                                                                            
                                                                                                            </tr>
                                                                                                            );
                                                                                                        })}

                                                                                    </tbody>
                                                                                  
                                                                                </table>
                                                                                   
                                                                    </div>
                                                                    <br></br>
                                                                    </section>
                                            
                                                             </div> 



                                                             {/* Materials Used Section */}
                                                        <br></br>
                                                        <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("Materials")}</h3>
                                                                   
                                                                </div>
                                                            </div>
                                                        <div className="container mx-auto px-8 justify-items-center">
                                                                    <section class="px-2" >
                                                                    <div class="flex flex-col justify-center h-full">
                                                                        {/* <!-- Table --> */}
                                                                                <table class="table-fixed w-full shadow-md rounded border-separate border-spacing-y-4">
                                                                                    <thead class="text-xs font-semibold uppercase text-white bg-indigo-500">
                                                                                    <tr>
                                                                                    <th class="p-2 ml-2 space-x-8">
                                                                                                <div class="font-semibold text-left">{t('SerialNumber')}</div>
                                                                                            </th>
                                                                                            <th class="p-2 px-4">
                                                                                                <div class="font-semibold text-left">{t('MaterialName')}</div>
                                                                                            </th>
                                                                                            <th class="p-3">
                                                                                                <div class="font-semibold text-left">{t('Description')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Quantity')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Unit')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Price')}</div>
                                                                                            </th>
                                                                                            <th class="p-2">
                                                                                                <div class="font-semibold text-left">{t('Total')}</div>
                                                                                            </th>
                                                                                            
                                                                                            

                                                                                            <th class="p-2">
                                                                                        <div class="font-semibold text-left">
                                                                                             {/* Button For Add New */}
                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-indigo-800 hover:bg-indigo-600 text-white text-xs" onClick={() => setNoOfRows(noOfRows + 1)}>
                                                                                            <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('Add')}
                                                                                            </button>

                                                                                        </div>
                                                                                            
                                                                                    </th>

                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody class="text-sm divide-y divide-gray-100">
                                                                                     
                                                                                        {/* Add New Rows */}
                                                                               
                                                                                        {[...Array(noOfRows)].map((elementInArray, index) => {

                                                                                                            return (
                                                                                                            <tr>
                                                                                                            <th scope="row">{index}</th>
                                                                                                            {/* Material Name Section*/}
                                                                                                            <td> <select
                                                                                                                {...register("materialName")}
                                                                                                                name="materialName" id="materialName"
                                                                                                                className={`form-input-sm-control`}>
                                                                                                                <option value="">{t('Select')}</option>
                                                                                                                <option value="1">Wood</option>
                                                                                                                <option value="2">Steel</option>
                                                                                                                <option value="3">Cement</option>
                                                                                                                <option value="3">Tiles</option>
                                                                                                            </select></td>

                                                                                                            {/* Description Section */}
                                                                                                            <td>
                                                                                                            <input
                                                                                                                    {...register("description")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="description" id="description"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                            </td>

                                                                                                            {/* Quantity Section */}
                                                                                                            <td>
                                                                                                            <input
                                                                                                                    {...register("quantity")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="quantity" id="quantity"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                            </td>

                                                                                                            {/* Unit Section */}                      
                                                                                                            <td>
                                                                                                                <div className='w-3/4 px-2'>
                                                                                                                <select
                                                                                                                {...register("particulars")}
                                                                                                                name="particulars" id="particulars"
                                                                                                                className={`form-input-sm-control`}>
                                                                                                                <option value="">{t('')}</option>
                                                                                                                <option value="1">Cft</option>
                                                                                                                <option value="2">Sqft</option>
                                                                                                                <option value="3">kg</option>
                                                                                                                <option value="3"></option>
                                                                                                            </select>
                                                                                                            </div> </td>
                                                                                                            
                                                                                                            
                                                                                                            {/* Price Section */}
                                                                                                            <td>
                                                                                                                <div class="w-2/3">
                                                                                                            <input
                                                                                                                    {...register("price")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="price" id="price"
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                </div>
                                                                                                            </td>

                                                                                                             {/* Total Section */}
                                                                                                             <td>
                                                                                                                <div className='bg-slate-400'>
                                                                                                            <input
                                                                                                                    {...register("total")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control`}
                                                                                                                    name="total" id="total" disabled
                                                                                                                    placeholder={t("")}
                                                                                                                />
                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td>
                                                                                                        <div className='px-3'>
                                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-red-600 hover:bg-red-200 text-white text-xs" onClick={() => setNoOfRows(noOfRows - 1)}>
                                                                                                        <AiOutlineMinus size={12} className={'mr-1'}></AiOutlineMinus>{t('')}
                                                                                                            </button>

                                                                                                            </div>
                                                                                                        </td>
                                                                                                                 

                                                                                                            
                                                                                                            </tr>
                                                                                                            );
                                                                                                        })}

                                                                                    </tbody>
                                                                                  
                                                                                </table>
                                                                                   
                                                                    </div>
                                                                    <br></br>
                                                                    </section>
                                            
                                                             </div> 
                                                    </div>

                                                    

                                                    <footer className="relative mt-1">
                                                        <div className="container mx-auto justify-items-center">
                                                            <div
                                                                className="text-right border-t pt-0.5 mb-0.5">
                                                                <button type='reset'
                                                                        className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                ><FiX size={16} className={'mr-2'}/>
                                                                    <span>{t('Cancel')}</span>
                                                                </button>
                                                                <button type='submit'
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                    {spinner ? (
                                                                        <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/>
                                                                    ) : (<FiSave size={16} className={'mr-2'}/>)}
                                                                    <span>{t('Save')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </form>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
export default TentativeBudget