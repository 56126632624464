import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { ChevronDownIcon  } from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mantine/core";
import axios from "axios";
export default function ProfileDropdown() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);
    const username = localStorage.getItem('username');
    const userId = localStorage.getItem('loggedUserId');
    const [userDetails,setUserDetails] = useState(null)
    const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false);
    const userViewModel = useSelector((state) => state.userReducer.userViewModel)

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${userId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setTimeout(()=>{
                    if (res.data.status === 200){
                        setUserDetails(res.data.data)
                    }
                },1000)

            })
            .catch(function (error) {
                console.log(error)
            })

    }, [userId]);

    function Logout(e) {
        localStorage.clear();
        navigate("/login");
    }
    return (
        <div className="top-10 text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="bg-indigo-800 inline-flex w-full justify-center bg-in bg-opacity-1 px-4 h-8 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-4 focus:outline-none focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-opacity-75">
                        {
                            userDetails && userDetails.path &&
                            <Avatar
                                size="xs"
                                src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user/${userDetails.path}`}
                                alt="it's me"
                            />
                        }

                        <ChevronDownIcon
                            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                <button className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                    {
                                        userDetails && userDetails.path &&
                                        <Avatar
                                            className="mr-2 h-5 w-5"
                                            size="sm"
                                            src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user/${userDetails.path}`}
                                            alt="it's me"
                                        />
                                    }

                                    {username}
                                </button>
                            </Menu.Item>
                        </div>

                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        // onClick={() => setShowMyProfileModal(prevState => !prevState)}
                                        onClick={() => {
                                            dispatch(setUserViewID(userDetails.id))
                                            dispatch(setUserViewModel(true))
                                        }}
                                    >
                                        <HiOutlineUserCircle className="mr-2 h-5 w-5 text-indigo-800" />
                                        My Profile
                                    </button>
                                )}
                            </Menu.Item>
                        </div>


                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={() => setShowPasswordUpdateModal(prevState => !prevState)}
                                    >
                                        {active ? (
                                            <HiOutlinePencilAlt
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        ) : (
                                            <HiOutlinePencilAlt
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        )}
                                        {t('ChangePassword')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>

                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        onClick={(e) => Logout(e)}
                                        className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        <HiOutlineLogout className="mr-2 h-5 w-5 text-indigo-800"/>
                                        {t('Logout')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>


                    </Menu.Items>
                </Transition>
            </Menu>
            {showPasswordUpdateModal && <PasswordUpdateModal setShowModal={setShowPasswordUpdateModal}/>}
            {userViewModel && <UserViewModel />}

        </div>

    )
}

import jwt_decode from "jwt-decode";
import {
    HiLogout, HiOutlineLogout,
    HiOutlinePencilAlt, HiOutlineUserCircle
} from "react-icons/hi";

import PasswordUpdateModal from "../modules/User/PasswordUpdateModal";
import {setUserViewID, setUserViewModel} from "../../store/user/userSlice";
import UserViewModel from "../modules/User/UserViewModel";
import {useDispatch, useSelector} from "react-redux";

function EditInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 13V16H7L16 7L13 4L4 13Z"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 13V16H7L16 7L13 4L4 13Z"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
        </svg>
    )
}

function DuplicateInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 4H12V12H4V4Z"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
            <path
                d="M8 8H16V16H8V8Z"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
        </svg>
    )
}

function DuplicateActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 4H12V12H4V4Z"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
            <path
                d="M8 8H16V16H8V8Z"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
        </svg>
    )
}

function ArchiveInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="8"
                width="10"
                height="8"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
            <rect
                x="4"
                y="4"
                width="12"
                height="4"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
            <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
        </svg>
    )
}

function ArchiveActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="8"
                width="10"
                height="8"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
            <rect
                x="4"
                y="4"
                width="12"
                height="4"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
            <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
        </svg>
    )
}

function MoveInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
            <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
            <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
        </svg>
    )
}

function MoveActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
            <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
            <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
        </svg>
    )
}

function DeleteInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="6"
                width="10"
                height="10"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
            <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
            <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
        </svg>
    )
}

function DeleteActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="6"
                width="10"
                height="10"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
            <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
            <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
        </svg>
    )
}

