import axios from "axios";

export const getPoReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/po-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getReceiveItemReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/received-item`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};

export const getReceiveItemReportDownloadData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/received-item/download`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};




export const getPrDailyBasisReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/daily-basis-pr`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};



export const getPrActivitiesData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/requisition-activities-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getPoWiseBudgetReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/po-wise-budget`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};



export const getPendingClaimData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/pending-claim-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getBudgetSummaryReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/budget-summary-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};


export const getPriceDirectoryReportData = async (param) => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/purchase-price-directory-report`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        },
        params : param
    })
        .then(res => {
            if (res.status === 200){
                data = res.data
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};
