import axios from "axios";

export const purchaseTypeDropdownService = async () => {
    let data = [];
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-type/dropdown`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
        }
    })
        .then(res => {
            if (res.status === 200){
                data =
                    res.data.data.map((type, index) => {
                        return ({
                            'value': Number(type.id), 'label': type.name
                        })
                    })
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    return data
};
