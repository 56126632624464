import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, ScrollArea, Select, TextInput, Grid, Drawer} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import View from "./SubCategoryView";
import {showNotification, updateNotification} from "@mantine/notifications";

function SubCategory() {

    const {t, i18n} = useTranslation();
    const [isAddForm, setAddForm] = useState(true);
    const [listRefresh, setListRefresh] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const token = localStorage.getItem('user_token');
    const [fetching, setFetching] = useState(true);
    const [opened, setOpened] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created_date', direction: 'DESC' });
    const [filterData, setFilterData] = useState({
        sub_categories_name: '',
        categories: '',
        category_heads: '',
        category_sub_heads: '',
    });

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created_date'},
            {'label': 'Sub Category Name', 'value': 'sub_categories_name'},
            {'label': 'Category', 'value': 'categories'},
            {'label': 'Category Head', 'value': 'category_heads'},
            {'label': 'Sub Category Head', 'value': 'category_sub_heads'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})
    }

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('sub_category_page') ? localStorage.getItem('sub_category_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height - 63);
    var fromHeight = height - 153 + "px";
    var drawerHeight = height - 20 + "px";

    const [subCategory, setSubCategory] = useState([]);
    const [totalItem, setTotalItem] = useState(0);

    const [headSearchFilterValue, setHeadSearchFilterValue] = useState('');
    const [categoryFilterHeadData, setCategoryFilterHeadData] = useState([]);



    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/sub-category`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page,
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
                "sub_categories_name":filterData.sub_categories_name?filterData.sub_categories_name:'',
                "categories":filterData.categories?filterData.categories:'',
                "category_heads":filterData.category_heads?filterData.category_heads:'',
                "category_sub_heads":filterData.category_sub_heads?filterData.category_sub_heads:'',
            }
        })
            .then(res => {
                setTimeout(()=>{
                    setSubCategory(res.data.data);
                    setTotalItem(res.data.total);
                    localStorage.setItem('sub_category_page', page);
                    setFetching(false)
                },2000)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [spinner, page,listRefresh,sortStatus,refresh]);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    function filterSubmit(data) {
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    // FOR DATA TABLE DATA SHOW END

    // start get category dropdown
        const [categories, setCategories] = useState([]);
        useEffect(() => {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/category/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                setCategories(res.data.data);
            });
        }, []);

        let categoriesDropdown =
            categories.map((category, index) => {
                return ({
                    'label': category.categories_name, 'value': category.id
                })
            })
        // on change company type data get
        const [category, setCategory] = useState(null);
        const categoryHandel = (e) => {
            setValue('categories',e)
            setCategory(e)
        }

    // End get category dropdown

    const [headSearchValue, setHeadSearchValue] = useState('');
    const [categoryHeadData, setCategoryHeadData] = useState([]);

    const CategoryHeadSearchHandel = (e,type) => {
        if (type === 'filter'){
            setHeadSearchFilterValue(e)
        }else {
            setHeadSearchValue(e);
        }
        if (headSearchValue.length > 1 || headSearchFilterValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/head/dropdown/search?value=${headSearchValue?headSearchValue:headSearchFilterValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                // console.log(res.data.data)
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': head.id, 'label': head.email
                        })
                    })
                if (type === 'filter'){
                    setCategoryFilterHeadData(data)
                }else {
                    setCategoryHeadData(data);
                }
            });
        }
    }

    // on change contact person data get
    const [categoryHead, setCategoryHead] = useState(null);
    const CategoryHeadHandel = (e) => {
        setCategoryHead(e)
        setValue('category_heads',e)
    }

    // End get project head dropdown


    // start get category sub head dropdown
    const [subHeadSearchValue, setSubHeadSearchValue] = useState('');
    const [categorySubHedData, setCategorySubHedData] = useState([]);

    const [subHeadFilterSearchValue, setSubHeadFilterSearchValue] = useState('');
    const [categoryFilterSubHedData, setCategoryFilterSubHedData] = useState([]);

    const CategorySubHeadSearchHandel = (e,type) => {
        if (type === 'filter'){
            setSubHeadFilterSearchValue(e)
        }else {
            setSubHeadSearchValue(e);
        }
        if (subHeadSearchValue.length > 1 || subHeadFilterSearchValue.length>1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/head/dropdown/search?value=${subHeadFilterSearchValue?subHeadFilterSearchValue:subHeadSearchValue}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'label': head.email, 'value': head.id
                        })
                    })
                if (type === 'filter') {
                    setCategoryFilterSubHedData(data)
                }else {
                    setCategorySubHedData(data);
                }
            });
        }
    }
        // on change category sub data get
        const [categorySubHead, setCategorySubHead] = useState(null);
        const categorySubHeadHandel = (e) => {
            setCategorySubHead(e)
            setValue('contact_person_name',e)
        }
    // End get category sub head dropdown
    



    //Form validation
    const validationSchema = Yup.object().shape({
        sub_categories_name: Yup.string().required(t("SubCategoryNameIsRequired")),
        categories: Yup.string().required(t("SelectCategory")),
        // category_heads: Yup.string().required(t("SelectHead")),
        // contact_person_name: Yup.string().required(t("SelectSubHead"))
    
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState ,setValue } = useForm(formOptions);
    const { errors } = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            sub_categories_name: data.sub_categories_name,
            categories : data.categories,
            category_heads : data.category_heads,
            category_sub_heads : data.contact_person_name,
            created_by: localStorage.getItem('username'),
            status:true
        };
        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SubCategoryAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 900,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout((e)=>{
                        setSpinner(false);
                        reset();
                        setValue('categories',null)
                        setValue('category_heads',null)
                        setValue('contact_person_name',null)
                        setFetching(true)
                    },1000)
                } else {
                    toast.error(res.data.message);
                }
            });
        }
    }




    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        sub_categories_name: '',
        categories: '',
        category_heads:'',
        category_sub_heads:''

    });
    const SubCategoryEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setEditInfo(res.data.data[0]);
                // console.log(res.data.data[0])
                setValue('sub_categories_name',res.data.data[0].sub_categories_name)
                setValue('categories',res.data.data[0].categories)

                let headArray = [];
                headArray.push({
                    email: res.data.data[0].category_heads_name,
                    id:  res.data.data[0].category_heads
                });

                let headData =
                    headArray.map((head, index) => {
                        return ({
                            'label': head.email, 'value': head.id
                        })
                    })
                setCategoryHeadData(headData);
                setCategoryHead(editInfo.category_heads?editInfo.category_heads:null)
                setValue('category_heads',editInfo.category_heads)

                let subHeadArray = [];
                subHeadArray.push({
                    email: res.data.data[0].category_sub_heads_name,
                    id:  res.data.data[0].category_sub_heads
                });
                let subHeadData =
                    subHeadArray.map((contact, index) => {
                        return ({
                            'label': contact.email, 'value': contact.id
                        })
                    })
                setCategorySubHedData(subHeadData);
                setCategorySubHead(editInfo.category_sub_heads)
                setValue('category_sub_heads',editInfo.category_sub_heads)
            } else {
                toast.error(res.data.message);
            }
        });
    }

    function formSubmitUpdate(data) {
        setSpinner(true);

        const formData = {
            sub_categories_name: data.sub_categories_name?data.sub_categories_name:editInfo.sub_categories_name,
            categories : data.categories?data.categories:editInfo.categories,
            category_heads : data.category_heads?data.category_heads:editInfo.category_heads,
            category_sub_heads : data.category_sub_heads?data.category_sub_heads:editInfo.category_sub_heads,
            created_by: localStorage.getItem('username'),
            status:true
        };

        if (formData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/${editInfo.id}`, formData, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                if (res.data.status === 202) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SubCategoryUpdatedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 900,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout((e)=>{
                        setSpinner(false);
                        reset();
                        setValue('categories',null)
                        setValue('category_heads',null)
                        setValue('category_sub_heads',null)
                        setCategorySubHead(null)
                        setCategoryHead(null)
                        setCategory(null)
                        setFetching(true)
                        setEditForm(false)
                        setCategoryHeadData([])
                        setCategorySubHedData([])
                    },1000)
                } else {
                    toast.error(res.data.message);
                }
            });
        }
    }


    const SubCategoryDelete = (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: (t('AreYouSure')),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: (t('YesDeleteIt'))
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                    if (res.status === 200) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('SubCategoryDeletedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 900,
                            disallowClose: true,
                            color: 'red',
                        });
                        setTimeout(()=>{
                            setListRefresh(true);
                            setFetching(true)
                        },1000)
                    }
                });
            }
        })

        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const SubCategoryView = (e,id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/sub-category/${id}`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            if (res.data.status === 200) {
                setViewData(res.data.data[0]);
                setViewModel(true);
            } else {
                toast.error(res.data.message);
            }
        });
    }

    return (
        <>
            {viewModel?<View data={viewData} setViewModel={setViewModel}/>:''}

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('SubCategory')}
                                                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('SubCategoryRelatedInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>
                                                            <button
                                                                onClick={event => addFormControl(event)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddSubCategory')}
                                                            </button>

                                                            <Link to='/master-data/sub-category'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2 text-xs group relative text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title= {t('ProjectFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <ScrollArea style={{ height: drawerHeight }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="sub_categories_name" className="form-input-sm-label">{t('SubCategory')}</label>
                                                                            <input
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                placeholder={t("EnterSubCategory")}
                                                                                value={filterData.sub_categories_name?filterData.sub_categories_name:''}
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['sub_categories_name']:e.target.value})
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label htmlFor="category" className="form-input-sm-label">{t('Category')}</label>
                                                                            <Select
                                                                                {...register("categories")}
                                                                                placeholder={t("ChooseCategory")}
                                                                                searchable clearable
                                                                                allowDeselect
                                                                                nothingFound="No options"
                                                                                withAsterisk
                                                                                data={categoriesDropdown}
                                                                                transition="pop-top-left"
                                                                                transitionDuration={80}
                                                                                transitionTimingFunction="ease"
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['categories']:e})
                                                                                }}
                                                                                value={filterData.categories?filterData.categories:''}
                                                                            />
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label htmlFor="head" className="form-input-sm-label">{t('Head')}</label>
                                                                            <Select
                                                                                placeholder={t("ChooseHead")}
                                                                                searchable clearable allowDeselect
                                                                                nothingFound="No options"
                                                                                withAsterisk
                                                                                data={categoryFilterHeadData}
                                                                                transition="pop-top-left"
                                                                                transitionDuration={80}
                                                                                transitionTimingFunction="ease"
                                                                                onSearchChange={(e)=>CategoryHeadSearchHandel(e,'filter')}
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['category_heads']:e})
                                                                                }}
                                                                                searchValue={headSearchFilterValue}
                                                                            />
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label htmlFor="subHead" className="form-input-sm-label">{t('SubHead')}</label>
                                                                            <Select
                                                                                placeholder={t("ChooseSubHeadPerson")}
                                                                                searchable clearable allowDeselect
                                                                                nothingFound="No options"
                                                                                withAsterisk
                                                                                data={categoryFilterSubHedData}
                                                                                transition="pop-top-left"
                                                                                transitionDuration={80}
                                                                                transitionTimingFunction="ease"
                                                                                onSearchChange={(e)=>CategorySubHeadSearchHandel(e,'filter')}
                                                                                onChange={(e)=>{
                                                                                    setFilterData({...filterData,['category_sub_heads']:e})
                                                                                }}
                                                                                searchValue={subHeadFilterSearchValue}
                                                                            />
                                                                        </div>
                                                                    </ScrollArea>



                                                                    <div className="mb-2 mt-5 text-right">

                                                                        <Grid>
                                                                            <Grid.Col span={6}>
                                                                                <Select
                                                                                    {...register("order_type")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderTypeDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderTypeDataHandel}
                                                                                    value={sortStatus.columnAccessor}
                                                                                />

                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <Select
                                                                                    {...register("order_by")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={orderByDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={OrderByDataHandel}
                                                                                    value={sortStatus.direction}
                                                                                />
                                                                            </Grid.Col>
                                                                            <Grid.Col span={3}>
                                                                                <button onClick={filterSubmit} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                    {spinner ? (
                                                                                        <HiOutlineSearch
                                                                                            className={'mr-2 animate-spin'}
                                                                                            size={16}></HiOutlineSearch>
                                                                                    ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                    <span>{t('Filter')}</span>
                                                                                </button>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </div>
                                                                    {/*</form>*/}
                                                                </Drawer>

                                                                <Box sx={{ height: tableHeight }}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={subCategory}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (subCategory.indexOf(item) + 1) + PAGE_SIZE * (page - 1),
                                                                            },
                                                                            {accessor: 'sub_categories_name',
                                                                                 title: <Text mr="xs">{t('SubCategoryName')}</Text>,
                                                                                sortable:true
                                                                            },
                                                                            {accessor: 'categories_name',
                                                                                 title: <Text mr="xs">{t('CategoryName')}</Text>,
                                                                                sortable:true
                                                                            },

                                                                            {accessor: 'category_heads_name',
                                                                                   title: <Text mr="xs">{t('CategoryHead')}</Text>,
                                                                                sortable:true
                                                                            },

                                                                            {accessor: 'category_sub_heads_name',
                                                                            title:<Text mr="xs">{t('CategorySubHead')}</Text>,
                                                                                sortable:true
                                                                            },

                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => SubCategoryEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => SubCategoryView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => SubCategoryDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="lg"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                    <header className="relative mb-1 bg-indigo-600">
                                                                        <div
                                                                            className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                            <div
                                                                                className="text-center flex justify-between">
                                                                                <div className="text-white   text-sm font-bold items-center leading-7">  {t('SubCategoryForm')}</div>
                                                                                <button onClick={event => setAddForm(false)}
                                                                                    className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                    type="button">
                                                                                    <FiX></FiX>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </header>
                                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                                        <ScrollArea style={{ height: fromHeight }} type="hover">

                                                                        <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                {/* Sub Category Name Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="sub_categories_name" className="form-input-sm-label-required">{t('SubCategory')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.sub_categories_name?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.sub_categories_name?.message}
                                                                                        </div>}
                                                                                        <div
                                                                                            className="form-input-sm-right-icon">
                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                        </div>
                                                                                        <input
                                                                                            {...register("sub_categories_name")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.sub_categories_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="sub_categories_name" id='sub_categories_name'
                                                                                            placeholder={t("EnterSubCategory")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                 {/* Category Section */}
                                                                                  <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="category" className="form-input-sm-label-required">{t('Category')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.categories?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.categories?.message}
                                                                                        </div>}
                                                                                     
                                                                                        <div className="relative items-stretch focus-within:z-10">
                                                                                            <Select
                                                                                                {...register("categories")}
                                                                                                placeholder={t("ChooseCategory")}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={categoriesDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={categoryHandel}
                                                                                                value={category}
                                                                                            />
                                                                                        </div>
                                                                                   
                                                                                    </div>
                                                                                </div>


                                                                                 {/* Head Section */}
                                                                                 <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="head" className="form-input-sm-label">{t('Head')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.category_heads?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.category_heads?.message}
                                                                                        </div>}
                                                                                     
                                                                                        <div className="relative items-stretch focus-within:z-10">
                                                                                            <Select
                                                                                                {...register("category_heads")}
                                                                                                placeholder={t("ChooseHead")}
                                                                                                searchable clearable allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={categoryHeadData}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onSearchChange={(e)=>CategoryHeadSearchHandel(e,'form')}
                                                                                                onChange={CategoryHeadHandel}
                                                                                                value={categoryHead}
                                                                                                searchValue={headSearchValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/* Sub Head Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="subHead" className="form-input-sm-label">{t('SubHead')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.contact_person_name?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.contact_person_name?.message}
                                                                                        </div>}
                                                                                        
                                                                                        
                                                                                        <div className="relative items-stretch focus-within:z-10">

                                                                                            <Select
                                                                                                {...register("contact_person_name")}
                                                                                                placeholder={t("ChooseSubHeadPerson")}
                                                                                                searchable clearable allowDeselect
                                                                                                nothingFound="No options"
                                                                                                withAsterisk
                                                                                                data={categorySubHedData}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onSearchChange={(e)=>CategorySubHeadSearchHandel(e,'form')}
                                                                                                onChange={categorySubHeadHandel}
                                                                                                value={categorySubHead}
                                                                                                searchValue={subHeadSearchValue}
                                                                                            />
                                                                                            
                                                                                        </div>
                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ScrollArea>
                                                                        <footer className="relative mt-1">
                                                                            <div className="container mx-auto">
                                                                                <div
                                                                                    className="text-right border-t pt-0.5 mb-0.5">
                                                                                    <button onClick={event => setAddForm(false)} type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                            ><FiX
                                                                                        size={16}
                                                                                        className={'mr-2'}></FiX>
                                                                                        <span>{t('Cancel')}</span>
                                                                                    </button>
                                                                                    <button type='submit'
                                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <AiOutlineLoading3Quarters
                                                                                                className={'mr-2 animate-spin'}
                                                                                                size={16}></AiOutlineLoading3Quarters>
                                                                                        ) : (<FiSave size={16}
                                                                                                     className={'mr-2'}></FiSave>)}
                                                                                        <span>{t('Save')}</span>
                                                                                    </button>

                                                                                </div>
                                                                            </div>
                                                                        </footer>

                                                                    </form>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </aside> : ''}


                                                {isEditForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateSubCategoryForm')}</div>
                                                                                    <button onClick={event => setEditForm(false)}
                                                                                            className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                            type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)} id="horizontal-form" >
                                                                            <ScrollArea style={{ height: fromHeight }} type="hover">

                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                     {/*Sub Category Name Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="sub_categories_name" className="form-input-sm-label-required">{t('SubCategory')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.sub_categories_name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.sub_categories_name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("sub_categories_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.sub_categories_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="sub_categories_name" id='sub_categories_name'
                                                                                                placeholder={t("EnterSubCategory")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                     {/*Category Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="category" className="form-input-sm-label-required">{t('Category')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.categories && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.categories?.message}
                                                                                            </div>}

                                                                                            <div className="relative items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("categories")}
                                                                                                    placeholder={t("ChooseCategory")}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={categoriesDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={categoryHandel}
                                                                                                    value={category?category:editInfo.categories}
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                     {/*Head Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="head" className="form-input-sm-label">{t('Head')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.category_heads?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.category_heads?.message}
                                                                                            </div>}

                                                                                            <div className="relative items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("category_heads")}
                                                                                                    placeholder={t("ChooseHead")}
                                                                                                    searchable clearable allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={categoryHeadData?categoryHeadData:[]}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onSearchChange={(e)=>CategoryHeadSearchHandel(e,'form')}
                                                                                                    onChange={CategoryHeadHandel}
                                                                                                    value={categoryHead}
                                                                                                    searchValue={headSearchValue}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                     {/*Sub Head Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="subHead" className="form-input-sm-label">{t('SubHead')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.contact_person_name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.contact_person_name?.message}
                                                                                            </div>}


                                                                                            <div className="relative items-stretch focus-within:z-10">

                                                                                                <Select
                                                                                                    {...register("contact_person_name")}
                                                                                                    placeholder={t("ChooseSubHeadPerson")}
                                                                                                    searchable clearable allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    withAsterisk
                                                                                                    data={categorySubHedData}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onSearchChange={(e)=>CategorySubHeadSearchHandel(e,'form')}
                                                                                                    onChange={categorySubHeadHandel}
                                                                                                    value={categorySubHead}
                                                                                                    searchValue={subHeadSearchValue}
                                                                                                />

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ScrollArea>
                                                                            {/*<div className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} >
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>



                                                                                     Sub Category Name Section
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="sub_categories_name" className="form-input-sm-label-required">{t('SubCategory')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.sub_categories_name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.sub_categories_name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("sub_categories_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.sub_categories_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="sub_categories_name" id='sub_categories_name'
                                                                                                placeholder={t("EnterSubCategory")}
                                                                                                value={editInfo.sub_categories_name}
                                                                                                onChange={handelInputUpdate}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                     Category Section
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="category" className="form-input-sm-label-required">{t('Category')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.category?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.category?.message}
                                                                                            </div>}

                                                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">

                                                                                                <SelectSearch
                                                                                                    {...register("categories")}
                                                                                                    name="categories"
                                                                                                    onChange={categoryHandel}
                                                                                                    value={category?category:editInfo.categories}
                                                                                                    options={categoriesDropdown}
                                                                                                    placeholder={t("ChooseCategory")}/>
                                                                                                <CompanyTypeModal></CompanyTypeModal>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                     Head Section
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="head" className="form-input-sm-label-required">{t('Head')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.head?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.head?.message}
                                                                                            </div>}

                                                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                <SelectSearch
                                                                                                    name="category_heads"
                                                                                                    onChange={categoryHeadHandel}
                                                                                                    search="true"
                                                                                                    options={categoryHeadData}
                                                                                                    value={categoryHead?categoryHead:editInfo.category_heads}
                                                                                                    getOptions={(e) => getCategoryHead(e)}
                                                                                                    placeholder={t("ChooseHead")}/>
                                                                                                <ContactPersonModal></ContactPersonModal>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                     Sub Head Section
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="subHead" className="form-input-sm-label-required">{t('SubHead')}</label>

                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.subHead?.type === 'required' && <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.subHead?.message}
                                                                                            </div>}


                                                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                <SelectSearch
                                                                                                    name="contact_person_name"
                                                                                                    onChange={categorySubHeadHandel}
                                                                                                    search="true"
                                                                                                    options={categorySubHedData}
                                                                                                    value={categorySubHead?categorySubHead:editInfo.category_sub_heads}
                                                                                                    getOptions={(e) => getCategorySubHedData(e)}
                                                                                                    placeholder={t("ChooseSubHeadPerson")}/>
                                                                                                <ContactPersonModal></ContactPersonModal>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>*/}


                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button onClick={event => setEditForm(false)} type='reset'
                                                                                                className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}
export default SubCategory