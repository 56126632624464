import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    amendmentPo,
    closeReceivedItem,
    createPurchaseOrder,
    POClaimItemSendBack,
    PoEditAfterApprove,
    PoItemDetailsAfterApproved,
    purchaseOrderAdvancePaymentCreate,
    purchaseOrderAmendmentDeleteItem,
    purchaseOrderAmendmentFinalUpdate,
    purchaseOrderApprove,
    purchaseOrderApproveInfo,
    purchaseOrderAttachInlineUpdate,
    purchaseOrderCommentCreate, purchaseOrderDelete,
    purchaseOrderDetails,
    purchaseOrderDropdown,
    purchaseOrderDropdownPoIdWise,
    purchaseOrderFinalUpdate,
    purchaseOrderIndex,
    purchaseOrderInlineUpdate,
    purchaseOrderItemInlineUpdate,
    purchaseOrderItemLastPriceDetails,
    purchaseOrderLastPriceInfo,
    purchaseOrderOpenItem,
    purchaseOrderProcessUpdate,
    purchaseOrderWiseItem,
    purchaseRequisitionItemClose
} from "../../services/PurchaseOrderService";
import {useState} from "react";


export const openItem = createAsyncThunk("purchase-order/open-item", async (params) => {
    try {
        const response = purchaseOrderOpenItem(params);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const createNewPurchaseOrder = createAsyncThunk("purchase-order/create", async (data) => {
    try {
        const response = createPurchaseOrder(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPurchaseOrderDetails = createAsyncThunk("purchase-order/details", async (data) => {
    try {
        const response = purchaseOrderDetails(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const inlineUpdatePO = createAsyncThunk("purchase-order/inline-update", async (data) => {
    try {
        const response = purchaseOrderInlineUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const inlineUpdatePOItem = createAsyncThunk("purchase-order/item-inline-update", async (data) => {
    try {
        const response = purchaseOrderItemInlineUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const inlineAttachUpdatePO = createAsyncThunk("purchase-order/attach-inline-update", async (data) => {
    try {
        const response = purchaseOrderAttachInlineUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poFinalUpdate = createAsyncThunk("purchase-order/final-update", async (data) => {
    try {
        const response = purchaseOrderFinalUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poItemLastPrice = createAsyncThunk("purchase-order/po-item-last-price", async (param) => {
    try {
        const response = purchaseOrderItemLastPriceDetails(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const poApprove = createAsyncThunk("purchase-order/index", async (param) => {
    try {
        const response = purchaseOrderApprove(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poIndexList = createAsyncThunk("purchase-order/approve", async (param) => {
    try {
        const response = purchaseOrderIndex(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poApproveInfomation = createAsyncThunk("purchase-order/approve-info", async (param) => {
    try {
        const response = purchaseOrderApproveInfo(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poLastPriceInfomation = createAsyncThunk("purchase-order/last-price-info", async (param) => {
    try {
        const response = purchaseOrderLastPriceInfo(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poCommentCreate = createAsyncThunk("purchase-order/comment-create", async (data) => {
    try {
        const response = purchaseOrderCommentCreate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poAdvancePaymentCreate = createAsyncThunk("purchase-order/advance-payment-create", async (data) => {
    try {
        const response = purchaseOrderAdvancePaymentCreate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poProcessUpdate = createAsyncThunk("purchase-order/process-update", async (data) => {
    try {
        const response = purchaseOrderProcessUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPoDropdown = createAsyncThunk("purchase-order/dropdown", async (param) => {
    try {
        const response = purchaseOrderDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPoDropdownPOidWise = createAsyncThunk("purchase-order/dropdown/po-id-wise", async (param) => {
    try {
        const response = purchaseOrderDropdownPoIdWise(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getClaimItemSendBack = createAsyncThunk("purchase-order/claim-item/send-back", async (param) => {
    try {
        const response = POClaimItemSendBack(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const getPoItemDetailAfterApprove = createAsyncThunk("purchase-order/edit-after-approved/details", async (param) => {
    try {
        const response = PoItemDetailsAfterApproved(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const PoItemEditAfterApprove = createAsyncThunk("purchase-order/edit-after-approved", async (data) => {
    try {
        const response = PoEditAfterApprove(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const prItemClose = createAsyncThunk("purchase-order/pr-item-close", async (data) => {
    try {
        const response = purchaseRequisitionItemClose(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const receivedItemClose = createAsyncThunk("purchase-order/received-item-close", async (data) => {
    try {
        const response = closeReceivedItem(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poAmendment = createAsyncThunk("purchase-order/amendment", async (data) => {
    try {
        const response = amendmentPo(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const amendmentPoFinalUpdate = createAsyncThunk("purchase-order/amendment-final-update", async (data) => {
    try {
        const response = purchaseOrderAmendmentFinalUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const amendmentItemDelete = createAsyncThunk("purchase-order/amendment-item-delete", async (data) => {
    try {
        const response = purchaseOrderAmendmentDeleteItem(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const poDelete = createAsyncThunk("purchase-order/delete", async (data) => {
    try {
        const response = purchaseOrderDelete(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const purchaseOrderSlice = createSlice({
    name : "purchase-order",
    initialState : {
        isLoading : true,
        message : null,
        status : null,
        openItemData : [],
        projectData : null,
        keywordData : null,
        checkOpenItem : [],
        createdPoId : null,
        purchaseOrderItem : [],
        purchaseOrderTermConditions : [],
        purchaseOrderDetails : [],
        processMode : null,
        vendor : null,
        buyer : null,
        purchaseType : null,
        paymentType : null,
        paymentFrom : null,
        computationStatus : null,
        poItemInlineUpdate : null,
        poItemInlineUpdateID : null,
        purchaseTypeName : null,
        finalUpdatePO : false,
        spinner : false,
        itemPriceModel : false,
        itemPriceModelItemID : false,
        itemLastPriceDetails : null,
        purchaseOrders : [],
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('purchase_order_page') ? localStorage.getItem('purchase_order_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'id', direction: 'DESC' },
        opened : false,
        refresh : false,
        filterData : {vendor:'',po:'',poRef:''},
        poViewModel : false,
        poViewID : null,
        poApproveInfo : [],
        poLastPriceInfo : [],
        poComment : null,
        poCommentstatus : false,
        poDropdown : [],
        poDropdownLoading : false,
        poDropdownPoIdWise : [],
        claimSendBackMSG : '',
        afterApproveEditModel : false,
        afterApproveEditID : false,
        poItemDetails : [],
        poItemEditAfterApproveMessage : null,
        closeModel : false,
        closeId : null,
        prItemCloseMessage : null,
        poItemCloseModel : false,
        poItemCloseId : null,
        poItemCloseMessage : null,
        poAmendmentMsg : null,
        poAmendmentId : null,
        advancePaymentModel : false,
        advancePaymentModelId : null
    },
    reducers : {
        setPoItemCloseModel : (state,action) => {
            state.poItemCloseModel = action.payload
        },
        setPoItemCloseId : (state,action) => {
            state.poItemCloseId = action.payload
            // state.prItemCloseMessage = null
        },
        setCloseModel : (state,action) => {
            state.closeModel = action.payload
        },
        setCloseId : (state,action) => {
            state.closeId = action.payload
            state.prItemCloseMessage = null
        },
        setAfterApproveEditModel : (state,action) => {
            state.afterApproveEditModel = action.payload
        },
        setAfterApproveEditID : (state,action) => {
            state.afterApproveEditID = action.payload
        },
        setProjectData : (state,action) => {
            state.projectData = action.payload
        },
        setKeywordData : (state,action) => {
            state.keywordData = action.payload
        },
        setLoading : (state,action) => {
            state.isLoading = action.payload
        },
        setCheckOpenItemEnpty : (state,action) => {
            state.checkOpenItem=[]
        },
        setCheckOpenItem : (state,action) => {
            state.checkOpenItem.push(action.payload)
        },
        setRemoveOpenItem : (state,action) => {
            state.checkOpenItem = state.checkOpenItem.filter(d=> d.requisition_item_id !== action.payload )
        },
        setOpenItemData : (state,action) => {
            state.openItemData = []
        },
        setCreatePoId : (state,action) => {
            state.createdPoId = null
        },
        setProcessMode : (state,action) => {
            state.processMode = action.payload
        },
        setVendor : (state,action) => {
            state.vendor = action.payload
        },
        setPurchaseType : (state,action) => {
            state.purchaseType = action.payload
        },
        setPaymentType : (state,action) => {
            state.paymentType = action.payload
        },
        setPaymentFrom : (state,action) => {
            state.paymentFrom = action.payload
        },
        setComputationStatus : ( state,action ) => {
            state.computationStatus = action.payload
        },
        setPoItemInlineUpdate : (state,action) => {
            state.poItemInlineUpdate = action.payload
        },
        setPoItemInlineUpdateID : (state,action) => {
            state.poItemInlineUpdateID = action.payload
        },
        setBuyer : (state,action) => {
            state.buyer = action.payload
        },
        setFinalUpdate : (state,action) => {
            state.finalUpdatePO = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setItemPriceModel : (state,action) => {
            state.itemPriceModel = action.payload
        },
        setItemPriceModelItemID : (state,action) => {
            state.itemPriceModelItemID = action.payload
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.po = action.payload.po;
            state.filterData.poRef = action.payload.poRef;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setPoViewModel : (state,action) => {
            state.poViewModel = action.payload
        },
        setAdvancePaymentModel : (state,action) => {
            state.advancePaymentModel = action.payload
        },
        setAdvancePaymentModelId : (state,action) => {
            state.advancePaymentModelId = action.payload
        },
        setPoViewID : (state,action) => {
            state.poViewID = action.payload
        },
        setPoComment : (state,action) => {
            state.poComment = action.payload
        },
        setPoCommentstatus : (state,action) => {
            state.poCommentstatus = action.payload
        },
        setPoDropdownLoading : (state,action) => {
            state.poDropdownLoading = action.payload
        },
        setEmptyPODetails : (state,action) => {
            state.purchaseOrderDetails = []
            state.purchaseOrderItem = []
            state.purchaseOrderTermConditions = []
            state.processMode = null
            state.vendor = null
            state.buyer = null
            state.purchaseType = null
            state.purchaseTypeName = null
            state.paymentType = null
            state.paymentFrom = null
            state.computationStatus = null
        }

        },
    extraReducers : (builder) => {
        builder.addCase(openItem.fulfilled, (state, action) => {
            state.openItemData = action.payload
            state.message = 'open item load'
            state.isLoading = false
        })
        builder.addCase(poDelete.fulfilled, (state, action) => {
            state.fetching = true
        })
        builder.addCase(createNewPurchaseOrder.fulfilled, (state, action) => {
            state.createdPoId = action.payload
            state.isLoading = false
            state.status = 201
            state.message  = 'create'
        })
        builder.addCase(getPurchaseOrderDetails.fulfilled,(state,action)=> {
            state.purchaseOrderDetails = action.payload
            state.processMode = action.payload.process_mode
            state.purchaseOrderItem = action.payload.purchase_order_items
            state.purchaseOrderTermConditions = action.payload.term_condition
            state.vendor = action.payload.vendors
            state.buyer = action.payload.buyers
            state.purchaseType = action.payload.po_nonpo
            state.purchaseTypeName = action.payload.purchase_type_name
            state.paymentType = action.payload.payment_type
            state.paymentFrom = action.payload.payment_from
            state.computationStatus = action.payload.computation_status
            state.isLoading = false
            state.status = 200
            state.poItemInlineUpdate = null
            state.poItemInlineUpdateID = null
        })

        builder.addCase(inlineUpdatePO.fulfilled,(state,action) => {
            state.message = 'inline'+action.payload
            state.isLoading = false
            state.status = 200
        })

        builder.addCase(inlineUpdatePOItem.fulfilled,(state,action)=>{
            state.poItemInlineUpdate = action.payload
        })

        builder.addCase(inlineAttachUpdatePO.fulfilled,(state,action)=>{
            state.isLoading = false
            state.message = action.payload
        })

        builder.addCase(poFinalUpdate.fulfilled,(state,action) => {
            if (action.payload === 'update'){
                state.finalUpdatePO = true
                state.purchaseOrderDetails = []
                state.purchaseOrderItem = []
            }
        })

        builder.addCase(amendmentPoFinalUpdate.fulfilled,(state,action) => {
            if (action.payload === 'update'){
                state.finalUpdatePO = true
                state.purchaseOrderDetails = []
                state.purchaseOrderItem = []
            }
        })

        builder.addCase(poItemLastPrice.fulfilled,(state,action) => {
            state.itemLastPriceDetails = action.payload
        })

        builder.addCase(poIndexList.fulfilled,(state,action) => {
            state.purchaseOrders = action.payload.data
            state.totalItem = action.payload.total
            state.fetching = false
        })

        builder.addCase(poApprove.fulfilled,(state,action) => {
            state.message = action.payload.messages
        })

        builder.addCase(poApproveInfomation.fulfilled,(state,action) => {
            state.poApproveInfo = action.payload
        })

        builder.addCase(poLastPriceInfomation.fulfilled,(state,action) => {
            state.poLastPriceInfo = action.payload
        })

        builder.addCase(poCommentCreate.fulfilled,(state,action) => {
            state.poCommentstatus = true
        })

        builder.addCase(poAdvancePaymentCreate.fulfilled,(state,action) => {
            // state.poCommentstatus = true
        })

        builder.addCase(poProcessUpdate.fulfilled,(state,action) => {
            state.message = action.payload
        })

        builder.addCase(getPoDropdown.fulfilled,(state,action) => {
            state.poDropdown = action.payload
            state.poDropdownLoading = false
        })

        builder.addCase(getPoDropdownPOidWise.fulfilled,(state,action) => {
            state.poDropdownPoIdWise = action.payload
        })

        builder.addCase(getClaimItemSendBack.fulfilled,(state,action) => {
            state.claimSendBackMSG = action.payload
        })

        builder.addCase(getPoItemDetailAfterApprove.fulfilled,(state,action)=>{
            state.poItemDetails = action.payload
        })

        builder.addCase(PoItemEditAfterApprove.fulfilled,(state,action) => {
            state.poItemEditAfterApproveMessage = action.payload
        })

        builder.addCase(prItemClose.fulfilled,(state,action)=>{
            state.prItemCloseMessage = action.payload
        })

        builder.addCase(receivedItemClose.fulfilled,(state,action)=>{
            state.poItemCloseMessage = action.payload
        })

        builder.addCase(poAmendment.fulfilled,(state,action)=>{
            state.poAmendmentMsg = action.payload.message
            state.poAmendmentId = action.payload.id
            state.purchaseOrderItem = []
            state.purchaseOrderDetails = []
        })

        builder.addCase(amendmentItemDelete.fulfilled,(state,action)=>{
            // state.poAmendmentMsg = action.payload.message
            // state.poAmendmentId = action.payload.id
            state.purchaseOrderItem = []
            state.purchaseOrderDetails = []
        })
    }
})

export const { setProjectData,setKeywordData,setLoading,setCheckOpenItem,setRemoveOpenItem,setOpenItemData,setCreatePoId,setProcessMode,setVendor,setPurchaseType,setPaymentType,setPaymentFrom,setComputationStatus,setPoItemInlineUpdate,setPoItemInlineUpdateID,setBuyer,setFinalUpdate,setSpinner,setItemPriceModel,setItemPriceModelItemID,setFetching,setPage,setActiveTab,setSortStatus,setOpened,setRefresh,setFilterData,setPoViewModel,setPoViewID,setPoComment,setPoCommentstatus,setPoDropdownLoading,setEmptyPODetails,setCheckOpenItemEnpty,setAfterApproveEditID,setAfterApproveEditModel,setCloseModel,setCloseId,setPoItemCloseId,setPoItemCloseModel,setAdvancePaymentModel,setAdvancePaymentModelId } = purchaseOrderSlice.actions
export default purchaseOrderSlice.reducer;