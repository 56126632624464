import React, {Fragment, useEffect, useState,useRef} from 'react'
import {useTranslation} from "react-i18next";
import {LoadingOverlay, ScrollArea, Text, TextInput} from "@mantine/core";
import {
    HiCloudDownload,
    HiOutlineX
} from "react-icons/hi";
import {Skeleton} from '@mantine/core';
import {setPoViewID, setPoViewModel} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";

import {
    PaymentVerifyFrom,
    setFetching,
    setPaymentVerifyModel, StorePaymentVerify
} from "../../../store/payment/paymentSlice";


import PoViewModel from "../PurchaseOrder/PoViewModel";
import {setRecViewID, setRecViewModel} from "../../../store/receivedItem/receivedItemSlice";
import RecViewModel from "../ReceivedItem/RecViewModel";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {openConfirmModal} from "@mantine/modals";

function PayVerifyModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_CHECKED = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_CHECKED";
    })

    const readyIdChecked = useSelector((state) => state.paymentReducer.readyIdChecked)
    const filterData = useSelector((state) => state.paymentReducer.filterData)
    const paymentVerifyFromData = useSelector((state) => state.paymentReducer.paymentVerifyFromData)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)
    const recViewModel = useSelector((state) => state.receivedItemReducer.recViewModel)


    let totaladjustmentAmount = 0

    useEffect((e)=>{
        setTimeout(()=>{
            dispatch(PaymentVerifyFrom({
                rfp_id : readyIdChecked,
                po_id : filterData.po
            }))
            setLoading(false)
        },500)
    },[loading])

    let totalAmount = 0;
    let totalAmountwithAdj = 0;
    let totalAit = 0;
    let totalVat = 0;
    let rfpIsAdvance = 0;
    let totalAdvPay = 0;

    const [totalPayable,setTotalPayable] = useState(0)

    const validationSchemaStep = Yup.object().shape({});
    let formOptions = {resolver: yupResolver(validationSchemaStep)};
    const {handleSubmit, formState, setValue, setError,register} = useForm(formOptions);

    function formSubmit(data) {
        let rfp_id = [];
        let grn_id = [];
        paymentVerifyFromData['rfp'] && paymentVerifyFromData['rfp'].map((item, index) => {
            rfp_id.push(item.rfp_id)
            grn_id.push(item.grn_id)
        })
        const fromdata = {
            rfp_id : rfp_id,
            grn_id : grn_id,
            vat_amount : data.vat_amount,
            ait_amount : data.ait_amount,
            po_id : paymentVerifyFromData.purchase_order_id,
            request_amount : data.net_payment_amount,
            net_payment_amount : data.net_payment_amount,
            adjustment_amount : data.adjustment_amount?data.adjustment_amount:0,
        }

        openConfirmModal({
            title: (t('VerifyThisPayment')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('VerifyThisPaymentDetails')}
                </Text>
            ),
            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
            confirmProps: { color: 'green' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                dispatch(StorePaymentVerify(fromdata))
            }
            ,
        });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PaymentVerified')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setPaymentVerifyModel(false))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">

                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />
                                                        <Fragment>
                                                            <div className="bg-gray-100" ref={pdfRef}>
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">

{
    paymentVerifyFromData  &&
    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">

                    <>
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseOrderInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>
                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                            {
                                paymentVerifyFromData.purchase_order_id &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('PurchaseOrderNo')} : </span>
                                        <span className="cursor-pointer" onClick={(e) => {
                                            dispatch(setPoViewModel(true))
                                            dispatch(setPoViewID(paymentVerifyFromData.purchase_order_id))
                                        }}>
                                        <u>{paymentVerifyFromData.purchase_order_id}</u>
                                        </span>
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.projects_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('ProjectName')} : </span>
                                        {paymentVerifyFromData.projects_name}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.company_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('CompanyType')} : </span>
                                        {paymentVerifyFromData.company_name}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.created_date &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('IssueDate')} : </span>
                                        {paymentVerifyFromData.created_date}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.purchase_type_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('PurchaseType')} : </span>
                                        {paymentVerifyFromData.purchase_type_name}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.payment_type &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('PaymentType')} : </span>
                                        {paymentVerifyFromData.payment_type}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.payment_method &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('PaymentMethod')} : </span>
                                        {paymentVerifyFromData.payment_method}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.payment_from &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('PaymentFrom')} : </span>
                                        {paymentVerifyFromData.payment_from == 2 ? 'Head Office': 'Local Office'}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.purchase_order_path &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('DownloadPo')} : </span>
                                        <a title="Vendor Quotation Attach" target="new" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/vendor-quotation/${paymentVerifyFromData.purchase_order_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                            <HiCloudDownload /> <u>{paymentVerifyFromData.purchase_order_id}</u>
                                        </a>
                                    </p>
                                </li>
                            }
                        </ul>
                    </>
                </div>
            </div>
        </div>
    </div>
}

{
    (paymentVerifyFromData && paymentVerifyFromData.vendors_name)  &&
    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">

                    <>
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VendorInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>
                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                            {
                                paymentVerifyFromData.vendors_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('VendorName')} : </span>
                                        {paymentVerifyFromData.vendors_name}
                                    </p>
                                </li>
                            }

                            {
                                paymentVerifyFromData.contract_person &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('ContactPerson')} : </span>
                                        {paymentVerifyFromData.contract_person}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.contract_no &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('ContactNumber')} : </span>
                                        {paymentVerifyFromData.contract_no}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.vendors_address &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('Address')} : </span>
                                        {paymentVerifyFromData.vendors_address}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.vat_certificate_no &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('VATeTin')} : </span>
                                        {paymentVerifyFromData.vat_certificate_no}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('AccountID')} : </span>
                                        {paymentVerifyFromData.account_id}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.compliance_status &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('ComplianceStatus')} : </span>
                                        {paymentVerifyFromData.compliance_status}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.bank_account_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('BankName')} : </span>
                                        {paymentVerifyFromData.bank_account_name}
                                    </p>
                                </li>
                            }
                            {
                                paymentVerifyFromData.bank_account_no &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold">{t('AccountNo')} : </span>
                                        {paymentVerifyFromData.bank_account_no}
                                    </p>
                                </li>
                            }
                        </ul>
                    </>
                </div>
            </div>
        </div>
    </div>
}

{
    (paymentVerifyFromData && paymentVerifyFromData.buyer_email)  &&
    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">

                    <>
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BuyerInformation')}</h4>
                            <div className="flex-1 border-t-2 border-gray-200"/>
                        </div>
                        <ul role="list" className="mt-5 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                            {
                                paymentVerifyFromData.buyer_email &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('Email')} : </span>
                                        {paymentVerifyFromData.buyer_email}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.buyer_full_name &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('FullName')} : </span>
                                        {paymentVerifyFromData.buyer_full_name}
                                    </p>
                                </li>
                            }{
                                paymentVerifyFromData.buyer_username &&
                                <li className="flex items-start lg:col-span-1">
                                    <p className="ml-3 text-sm text-gray-700">
                                        <span className="font-bold ">{t('UserName')} : </span>
                                        {paymentVerifyFromData.buyer_username}
                                    </p>
                                </li>
                            }
                        </ul>
                    </>
                </div>
            </div>
        </div>
    </div>
}
                {
                    (paymentVerifyFromData && paymentVerifyFromData.edit_reason ) &&
                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                <div className="mt-4">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('EditInformation')}</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                    </div>

                                    <ul role="list" className="mt-5 ">
                                        <li className="flex items-start lg:col-span-1 mt-5">
                                            <p className="ml-3 text-sm text-gray-700 text-justify">
                                                <span className="font-bold">{t('EditReason')} : </span>
                                                {paymentVerifyFromData.edit_reason}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }

    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentRequestInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                            (paymentVerifyFromData && paymentVerifyFromData['rfp'] && paymentVerifyFromData['rfp'].length >0 ) &&
                            <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                <tr>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SL')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('RequestedBy')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('RequestDate')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('PaymentID')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('BillNumber')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('GRN')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Mode')}</div>
                                    </th>
                                    {/*{
                                        paymentVerifyFromData.is_advance_payment != 1 &&
                                            <>*/}
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">{t('Vat')}</div>
                                        </th>
                                        <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Ait')}</div>
                                        </th>
                                        {/*</>
                                    }*/}

                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Amount')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Invoice')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('Challan')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('GRN')}</div>
                                    </th>
                                    <th className="p-2 border-collapse border border-slate-400">
                                        <div className="font-semibold text-center">{t('SendBackComment')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (paymentVerifyFromData && paymentVerifyFromData['rfp'] && paymentVerifyFromData['rfp'].length >0 ) &&
                                    paymentVerifyFromData['rfp'].map((item, index) => {
                                        totalAmount = totalAmount +Number(item.amount);
                                        totalAmountwithAdj = totalAmountwithAdj +Number(item.amount);
                                        rfpIsAdvance = item.isAdvance == 1 && item.grn_id == null?1:0;
                                        totalAit = totalAit +item.total_ait?Number(item.total_ait):0;
                                        totalVat = totalVat +item.total_vat?Number(item.total_vat):0;
                                        setValue('ait_amount',totalAit)
                                        setValue('vat_amount',totalVat)
                                        setValue('net_payment_amount',totalAmount)
                                        return (
                                            <tr className={"bg-gray-100"} key={index}>
                                                <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                    {++index}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.verify_email?item.verify_email:item.grn_approve_email}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.request_date?item.request_date:''}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.rfp_id?item.rfp_id:''}</td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {item.bill_date?item.bill_date:''}<span> / </span>{item.billNumber?item.billNumber:''}
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {
                                                        item.grn_id ?
                                                            <p className="ml-3 text-sm text-gray-700">
                                                                <span className="cursor-pointer"
                                                                      onClick={(e) => {
                                                                    dispatch(setRecViewModel(true))
                                                                    dispatch(setRecViewID(item.grn_id))
                                                                }}
                                                                >
                                                                <u>{item.grn_id}</u>
                                                                </span>
                                                            </p>
                                                            :'Adv'
                                                    }
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {paymentVerifyFromData.vat_mode?paymentVerifyFromData.vat_mode:''}
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                    {item.total_vat?item.total_vat:''}
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                    {item.total_ait?item.total_ait:''}
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                    { Number(item.amount?item.amount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {
                                                        item.grn_id && item.invoice_id ?
                                                            <a title="Invoice" target="new" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/invoice/${item.invoice_path?item.invoice_path:''}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                <HiCloudDownload />
                                                            </a>
                                                            :''
                                                    }
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {
                                                        item.grn_id && item.calans_id ?
                                                            <a title="Challan" target="new" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/challan/${item.calans_path?item.calans_path:''}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                <HiCloudDownload />
                                                            </a>
                                                            :''
                                                    }
                                                </td>

                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                    {
                                                        item.grn_id && item.grn_path ?
                                                            <a title="GRN" target="new" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/grn-file/${item.grn_path?item.grn_path:''}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                <HiCloudDownload />
                                                            </a>
                                                            :''
                                                    }
                                                </td>
                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.sendBackComment?item.sendBackComment:''}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={7} className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total</td>
                                    <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                        TK. { Number(totalVat).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                        TK. { Number(totalAit).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                        TK. { Number(totalAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </div>
    </div>

    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('VerifiedInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        paymentVerifyFromData &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <tbody>
                            {
                                paymentVerifyFromData.net_total &&
                                <tr className={"bg-gray-100"} key="1">
                                    <td className="w-3/4 border-collapse border border-slate-400 text-center">Total PO Amount </td>
                                    <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                        TK. { Number(paymentVerifyFromData.net_total).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                    </td>
                                </tr>
                            }
                            {
                                totalAmount &&
                                <tr className={"bg-gray-100"} key="2">
                                    <td className="w-3/4 border-collapse border border-slate-400 text-center">Total Request Amount </td>
                                    <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                        TK. { Number(totalAmount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                </tr>
                            }
                            {/*{
                                rfpIsAdvance == 0 &&
                                    <>*/}
                                <tr className={"bg-gray-100"} key="3">
                                    <td className="w-3/4 border-collapse border border-slate-400 text-center">Vat Amount </td>
                                    <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                        TK. { Number(totalVat).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                </tr>
                                <tr className={"bg-gray-100"} key="4">
                                <td className="w-3/4 border-collapse border border-slate-400 text-center">AIT Amount </td>
                                <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                TK. { Number(totalAit).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                </td>
                                </tr>
                                {/*</>*/}
                            {/*}*/}


                            {
                                (paymentVerifyFromData && paymentVerifyFromData['payment-history'] && paymentVerifyFromData['payment-history'].length >0) && paymentVerifyFromData['payment-history'].map((item, index) => {
                                    totaladjustmentAmount = totaladjustmentAmount + item.adjustmentAmount?Number(item.adjustmentAmount):0;
                                })
                            }

                            {
                                paymentVerifyFromData.is_advance_payment == 1 &&
                                <>
                                <tr className={"bg-gray-100"} key="5">
                                    <td className="w-3/4 border-collapse border border-slate-400 text-center">Advance Amount </td>
                                    <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                        TK. { Number(paymentVerifyFromData.advance_payment_amount).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                </tr>
                                    {
                                        rfpIsAdvance == 0 &&
                                        <tr className={"bg-gray-100"} key="6">
                                            <td className="w-3/4 border-collapse border border-slate-400 text-center">Adjustment Amount </td>
                                            <td className="w-1/4 border-collapse border border-slate-400 text-right">
                                                <TextInput
                                                    {...register('adjustment_amount')}
                                                    className={`text-center`}
                                                    size="xs"
                                                    onKeyUp={(e)=>{
                                                        // setTotalPayable(totalAmountwithAdj-e.target.value)
                                                    }}
                                                    // placeholder={paymentVerifyFromData.advance_payment_amount?Number(paymentVerifyFromData.advance_payment_amount):0 - totaladjustmentAmount}
                                                    defaultValue={''}
                                                />
                                            </td>
                                        </tr>
                                    }


                                </>
                            }

                            <tr className={"bg-gray-100"} key="7">
                                <td className="w-3/4 border-collapse border border-slate-400 text-center"> Net Payment Amount </td>
                                <td className="w-1/4 border-collapse border border-slate-400 text-center">
                                    <TextInput
                                        {...register('net_payment_amount')}
                                        className={`text-center`}
                                        size="xs"
                                        // value={totalAmount}
                                        value={totalPayable && totalPayable>0 ? totalPayable:totalAmount}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>
    </div>


    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                <div className="mt-4">
                    <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PaymentHistoryInformation')}</h4>
                        <div className="flex-1 border-t-2 border-gray-200"/>
                    </div>
                    {
                        (paymentVerifyFromData && paymentVerifyFromData['payment-history'] ) &&
                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                            <tr>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('SL')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('ApprovedDate')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('PaymentDate')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('BillDateNumber')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('GRNNumber')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('RequestedAmount')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('PaymentAmount')}</div>
                                </th>
                                <th className="p-2 border-collapse border border-slate-400">
                                    <div className="font-semibold text-center">{t('AdjustmentAmount')}</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (paymentVerifyFromData && paymentVerifyFromData['payment-history'] && paymentVerifyFromData['payment-history'].length >0 ) &&
                                paymentVerifyFromData['payment-history'].map((item, index) => {
                                    totalAdvPay = totalAdvPay+item.paymentAmount
                                    return (
                                        <tr className={"bg-gray-100"} key={index}>
                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                {item.request_date?item.request_date:null}
                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                {item.payment_date?item.payment_date:null}
                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">

                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                {item.grn_id?item.grn_id:'ADV'}
                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                { Number(item.requestAmount?item.requestAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                { Number(item.paymentAmount?item.paymentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td>
                                            <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                { Number(item.adjustmentAmount?item.adjustmentAmount:'').toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={8} className="w-1/24 border-collapse border border-slate-400 text-center font-bold"></td>
                            </tr>
                            <tr>
                                <td colSpan={6} className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total</td>
                                <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                    TK. { Number(totalAdvPay).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                </td>
                            </tr>
                            </tbody>
                        </table>

                    }
                </div>
            </div>
        </div>
    </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                            </ScrollArea>

    <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
        <div className="justify-items-center">
            <div className=" mt-3 mb-3">

                <div className="float-left mb-3">

                </div>

                <div className="float-right mb-3">

                    <button
                        type="submit"
                        className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                    >
                        Verify
                    </button>

                    <button
                        onClick={(e) => {
                            dispatch(setPaymentVerifyModel(false))
                            dispatch(setFetching(true))
                        }}
                        className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
            {poViewModel?<PoViewModel />:''}
            {recViewModel?<RecViewModel />:''}
        </>
    )
}

export default PayVerifyModel