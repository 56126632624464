import React from "react";
import { HiOutlineSearch,HiRefresh} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import TextInputField from "../../globalInput/filterForm/TextInputField";
import SelectInputField from "../../globalInput/filterForm/SelectInputField";
import {useNavigate} from "react-router";

function PoFilter(){
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)

    return(
        <>
            <div className="space-y-1 bg-gray-50 px-1 py-1">
                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                    <div className=" w-1/4 items-center">
                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                            <SelectInputField
                                placeholder={t("ChooseVendor")}
                                dropdownData={vendorDropdownData}
                                module="po"
                                name="vendor"
                            />
                        </div>
                    </div>

                    <div className=" w-1/4 items-center">
                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                            <TextInputField
                                module="po"
                                placeholder={t("PoNumber")}
                                name="po"
                            />
                        </div>
                    </div>

                    <div className=" w-1/4 items-center">
                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                            <TextInputField
                                module="po"
                                placeholder={t("RefNumber")}
                                name="poRef"
                            />
                        </div>
                    </div>

                    <div className=" w-1/4 items-center">
                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                            <button type='submit'
                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                <HiOutlineSearch className={'mr-2'} size={16}></HiOutlineSearch>
                                <span>{t('Filter')}</span>
                            </button>

                            <button
                                className="px-6 py-2 text-white inline-flex focus:ring-rose-700 transition duration-150 ease-in-out hover:bg-rose-600 bg-rose-700 items-center border  rounded text-sm"
                            onClick={(e) => navigate(0)}>
                                <HiRefresh className={'mr-2'} size={16}></HiRefresh>
                                <span>{t('Reset')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PoFilter