import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {CategoryDropdown} from "../../services/CategoryService";


export const getCategoryDropdown = createAsyncThunk("vendor/dropdown", async () => {
    try {
        const response = CategoryDropdown();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
const categorySlice = createSlice({
    name : "category",
    initialState : {
        isLoading : true,
        message : null,
        categoryDropdownData : [],
    },
    reducers : {
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setSpinner : (state,action) => {
            state.spinner = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.vendor = action.payload.vendor;
            state.filterData.item_type = action.payload.item_type;
            state.filterData.area = action.payload.area;
        }

    },

    extraReducers : (builder) => {
        builder.addCase(getCategoryDropdown.fulfilled, (state, action) => {
            state.categoryDropdownData = action.payload
        })

    }
})
export const { setActiveTab,setSortStatus,setFilterData,setPage,setFetching,setRefresh,setSpinner,setOpened,setEmptyVendorDetailsData } = categorySlice.actions

export default categorySlice.reducer;