import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle, HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiOutlineExclamation, HiArrowCircleLeft, HiArrowCircleRight
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {
    Group,
    MultiSelect,
    ScrollArea,
    Select,
    Text,
} from "@mantine/core";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import vendorReducer, {
    setSpinner,
    setOpened,
} from "../../../../store/vendor/vendorSlice";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {useMantineTheme} from "@mantine/styles";
import toast from "react-hot-toast";
import {projectDetailsData} from "../../../../store/project/projectSlice";

function EditProject() {
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const {id} = useParams();

    const fullFormHeight = localStorage.getItem('fullFormHeight');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);


    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })


    const [pdfFile, setPdfFile] = useState([]);
    const [projectHead, setProjectHead] = useState(null);
    const [companyType, setCompanyType] = useState(null);
    const [proType, setProType] = useState(null);
    const [areaData, setAreaData] = useState(null);
    const [address, setAddress] = useState(null);
    const [projectData, setProjectData] = useState([]);


    useEffect(() => {
        setTimeout(()=>{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/project/details/${id}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                }
            })
                .then(res => {
                    if (res.status === 200){
                        setProjectData(res.data.data)

                        if (res.data.data.projectsName){
                            setValue('projects_name',res.data.data.projectsName)
                        }
                        if (res.data.data.costCenterNumber){
                            setValue('cost_center_number',res.data.data.costCenterNumber)
                        }
                        if (res.data.data.tinNo){
                            setValue('tin_no',res.data.data.tinNo)
                        }
                        if (res.data.data.binNo){
                            setValue('bin_no',res.data.data.binNo)
                        }
                        if (res.data.data.projectHeadId){
                            setValue('project_heads',res.data.data.projectHeadId)
                            setProjectHead(res.data.data.projectHeadId)
                        }
                        if (res.data.data.projectTypesId){
                            setValue('project_types',res.data.data.projectTypesId)
                            setCompanyType(res.data.data.projectTypesId)
                        }
                        if (res.data.data.proTypeId){
                            setValue('pro_type',res.data.data.proTypeId)
                            setProType(res.data.data.proTypeId)
                        }
                        if (res.data.data.projectAreaId){
                            setValue('area',res.data.data.projectAreaId)
                            setAreaData(res.data.data.projectAreaId)
                        }

                        if (res.data.data.projectContactName){
                            setValue('project_contact_name',res.data.data.projectContactName)
                        }
                        if (res.data.data.projectContactMobile){
                            setValue('project_contact_mobile',res.data.data.projectContactMobile)
                        }
                        if (res.data.data.address){
                            setValue('address',res.data.data.address)
                        }
                        if (res.data.data.courierContactName){
                            setValue('courier_contact_name',res.data.data.courierContactName)
                        }
                        if (res.data.data.courierContactMobile){
                            setValue('courier_contact_mobile',res.data.data.courierContactMobile)
                        }
                        if (res.data.data.courierAddress){
                            setValue('courier_address',res.data.data.courierAddress)
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },700)
    }, []);


    //Form validation
    const validationSchema = Yup.object().shape({
        projects_name: Yup.string().required(t("EnterProjectName")),
        cost_center_number: Yup.string().required(t("EnterCostCenterNumber")),
        tin_no: Yup.string().required(t("EnterTinNo")),
        bin_no: Yup.string().required(t("EnterBinNo")),
        project_heads: Yup.string().required(t("ChooseProjectHead")),
        project_types: Yup.string().required(t("ChooseCompanyType")),
        pro_type: Yup.string().required(t("ChooseProjectType")),
        area: Yup.string().required(t("SelectArea")),
        // file: Yup.string().required(t("ChooseAttachment")),
        project_contact_name: Yup.string().required(t("EnterName")),
        project_contact_mobile: Yup.string().required(t("EnterMobile")),
        address: Yup.string().required(t("EnterAddress")),
        courier_contact_name: Yup.string().required(t("EnterName")),
        courier_contact_mobile: Yup.string().required(t("EnterMobile")),
        courier_address: Yup.string().required(t("EnterAddress"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setError, setValue} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/project/update/${id}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `multipart/form-data`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.data.status == 201 && res.data.message == 'updated') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('DataAddedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 700,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout(() => {
                        setSpinner(false);
                        reset();
                        navigate('/master-data/project')
                    }, 700)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: res.data.message,
                        message: (t('DataLoad')),
                        autoClose: 1500,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (error) {
                toast.error(res.data.message);
            })

    }


    // start get area dropdown
    const [areas, setAreas] = useState([]);
    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/area/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setAreas(res.data.data);
        });
    }, []);

    let areaDropdown =
        areas.map((type, index) => {
            return ({
                'label': type.areas_name, 'value': Number(type.id)
            })
        })
    // End get area dropdown

    // project head
    const [projectHeads, setProjectHeads] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/head/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjectHeads(res.data.data);
            });
    }, []);

    let projectHeadDropdown =
        projectHeads.map((type, index) => {
            return ({
                'label': type.username?type.username:type.email, 'value': Number(type.id)
            })
        })
    // project head

    // start get company type dropdown
    const [companyTypes, setCompanyTypes] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project-type/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setCompanyTypes(res.data.data);
        });
    }, []);

    let companyTypesDropdown =
        companyTypes.map((type, index) => {
            return ({
                'label': type.projects_category_name, 'value': Number(type.id)
            })
        })
    // End get company type dropdown

    // start get project type dropdown
    const [proTypes, setProTypes] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/pro-type/dropdown`, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setProTypes(res.data.data);
        });
    }, []);

    let proTypeDropdown =
        proTypes.map((type, index) => {
            return ({
                'value': Number(type.id),'label': type.title
            })
        })
    // End get project type dropdown

    return (
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Project')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllProjectRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            {
                                                                ROLE_SUPER_ADMIN &&
                                                                <button onClick={event => {
                                                                    navigate('/master-data/create-project')
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/master-data/project'
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle size={16}></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4}>

                                                            <div
                                                                className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                                                        <div className="shadow sm:rounded">
                                                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div className="flex w-full items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BasicInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="projects_name" className="form-input-sm-label-required">{t('ProjectName')}</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.projects_name?.type === 'required' &&
            <div
                className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.projects_name?.message}
            </div>}
            <input
                {...register("projects_name")}
                type="text"
                className={`form-input-sm-control ${errors.projects_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                placeholder={t("EnterProjectName")}
            />
        </div>
    </div>

    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="contractPerson" className="form-input-sm-label-required">{t('CCN')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.cost_center_number?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={18}
                    className={'mr-2'}></HiOutlineExclamation> {errors.cost_center_number?.message}
            </div>}
            <input
                {...register("cost_center_number")}
                type="text"
                className={`form-input-sm-control ${errors.cost_center_number ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                placeholder={t("EnterCostCenterNumber")}
            />
        </div>
    </div>

    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="contractPerson" className="form-input-sm-label-required">{t('TinNo')}</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.tin_no?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={18}
                    className={'mr-2'}></HiOutlineExclamation> {errors.tin_no?.message}
            </div>}
            <input
                {...register("tin_no")}
                type="text"
                className={`form-input-sm-control ${errors.tin_no ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                placeholder={t("EnterTinNo")}
            />
        </div>
    </div>

    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="bin_no" className="form-input-sm-label-required">{t('BinNo')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.bin_no?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={18}
                    className={'mr-2'}></HiOutlineExclamation> {errors.bin_no?.message}
            </div>}
            <input
                {...register("bin_no")}
                type="text"
                className={`form-input-sm-control ${errors.bin_no ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                placeholder={t("EnterBinNo")}
            />
        </div>
    </div>


    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="paymentType" className="form-input-sm-label-required">{t('ProjectHead')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.project_heads?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.project_heads?.message}
            </div>}
            <div
                className="relative flex flex-grow items-stretch focus-within:z-10">
                <Select
                    {...register("project_heads")}
                    placeholder={t("ChooseProjectHead")}
                    searchable clearable
                    allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={projectHeadDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={(e) => {
                        setProjectHead(e)
                        setValue('project_heads', e)
                        setError('project_heads', null)
                    }}
                    value={projectHead}
                />
            </div>
        </div>
    </div>


    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="project_types" className="form-input-sm-label-required">{t('CompanyType')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.project_types?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.project_types?.message}
            </div>}
            <div
                className="relative flex flex-grow items-stretch focus-within:z-10">
                <Select
                    {...register("project_types")}
                    placeholder={t("ChooseCompanyType")}
                    searchable clearable
                    allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={companyTypesDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={(e) => {
                        setCompanyType(e)
                        setValue('project_types', e)
                        setError('project_types', null)
                    }}
                    value={companyType}
                />
            </div>
        </div>
    </div>


    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="pro_type" className="form-input-sm-label-required">{t('ProjectType')}</label>

        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.pro_type?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.pro_type?.message}
            </div>}
            <div
                className="relative flex flex-grow items-stretch focus-within:z-10">
                <Select
                    {...register("pro_type")}
                    placeholder={t("ChooseProjectType")}
                    searchable clearable
                    allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={proTypeDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={(e) => {
                        setProType(e)
                        setValue('pro_type', e)
                        setError('pro_type', null)
                    }}
                    value={proType}
                />
            </div>
        </div>
    </div>

    <div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="area" className="form-input-sm-label-required">{t('Area')}</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.area?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.area?.message}
            </div>}
            <div
                className="relative flex flex-grow items-stretch focus-within:z-10">
                <Select
                    {...register("area")}
                    placeholder={t("ChooseArea")}
                    searchable clearable
                    allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={areaDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={(e) => {
                        setAreaData(e)
                        setValue('area', e)
                        setError('area', null)
                    }}
                    value={areaData}
                />
            </div>
        </div>
    </div>

    {/*<div className="mb-3 w-1/3 pr-3 items-center">
        <label htmlFor="area" className="form-input-sm-label-required">{t('ContactPerson')}</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.project_contact_persons?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.project_contact_persons?.message}
            </div>}
            <div
                className="relative flex flex-grow items-stretch focus-within:z-10">
                <Select
                    {...register("project_contact_persons")}
                    placeholder={t("ChooseContactPerson")}
                    searchable clearable
                    allowDeselect
                    nothingFound="No options"
                    withAsterisk
                    data={projectHeadDropdown}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                    onChange={(e) => {
                        setAreaData(e)
                        setValue('project_contact_persons', e)
                        setError('project_contact_persons', null)
                    }}
                    value={areaData}
                />
            </div>
        </div>
    </div>*/}

    <div className="mb-3 w-1/3 pr-3 items-center">

        <label htmlFor="vat_file_file" className="form-input-sm-label">Attachment</label>
        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
            {errors.file?.type === 'required' &&
            <div className="form-input-sm-error">
                <HiOutlineExclamation
                    size={16}
                    className={'mr-2'}></HiOutlineExclamation> {errors.file?.message}
            </div>}
            <Dropzone
                onDrop={(file) => {
                    setPdfFile(file[0])
                    setValue("file", file[0])
                    setError("file", null)
                }}
                onReject={(files) => console.log('rejected files', files)}
                accept={[MIME_TYPES.pdf]}
                multiple={false}
            >
                <Group position="center"
                       spacing="sm"
                       style={{pointerEvents: 'none'}}>
                    <Dropzone.Accept>
                        <IconUpload
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconPhoto
                            stroke={1.5}/>
                    </Dropzone.Idle>

                    <div>
                        <Text size="sm"
                              inline>
                            {
                                pdfFile.path ?
                                    pdfFile.path
                                    : 'Choose File'
                            }

                        </Text>
                    </div>
                </Group>
            </Dropzone>
            {
                projectData.path &&
                <a title={t('VendorAttach')} target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/project/${projectData.path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>

            }
        </div>
    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>



    <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
            <div className="mt-5 md:col-span-5 md:mt-0">
                <div className="shadow sm:rounded">
                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                            <div className="flex w-full items-center">
                                <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ProjectContactPerson')}</h4>
                                <div className="flex-1 border-t-2 border-gray-200"/>
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="project_contact_name" className="form-input-sm-label-required">{t('Name')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.project_contact_name?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={18}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.project_contact_name?.message}
                                    </div>}
                                    <input
                                        {...register("project_contact_name")}
                                        type="text"
                                        className={`form-input-sm-control ${errors.project_contact_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                        placeholder={t("EnterName")}
                                    />
                                </div>

                                {/*<div className="mb-3 w-1/3 pr-3 items-center">
                                    <label htmlFor="contractPerson" className="form-input-sm-label-required">{t('CCN')}</label>

                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                        {errors.cost_center_number?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation
                                                size={18}
                                                className={'mr-2'}></HiOutlineExclamation> {errors.cost_center_number?.message}
                                        </div>}
                                        <input
                                            {...register("cost_center_number")}
                                            type="text"
                                            className={`form-input-sm-control ${errors.cost_center_number ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            placeholder={t("EnterCostCenterNumber")}
                                        />
                                    </div>
                                </div>*/}
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="project_contact_mobile" className="form-input-sm-label-required">{t('Mobile')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.project_contact_mobile?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={18}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.project_contact_mobile?.message}
                                    </div>}
                                    <input
                                        {...register("project_contact_mobile")}
                                        type="text"
                                        className={`form-input-sm-control ${errors.project_contact_mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                        placeholder={t("EnterMobile")}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="Address" className="form-input-sm-label-required">{t('Address')}</label>
                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.address?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                    </div>}
                                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                        <textarea
                                            {...register("address")}
                                            className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                                setValue('address', e.target.value)
                                                setError('address', null)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
            <div className="mt-5 md:col-span-5 md:mt-0">
                <div className="shadow sm:rounded">
                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                            <div className="flex w-full items-center">
                                <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('CourierContactPerson')}</h4>
                                <div className="flex-1 border-t-2 border-gray-200"/>
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="courier_contact_name" className="form-input-sm-label-required">{t('Name')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.courier_contact_name?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={18}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.courier_contact_name?.message}
                                    </div>}
                                    <input
                                        {...register("courier_contact_name")}
                                        type="text"
                                        className={`form-input-sm-control ${errors.courier_contact_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                        placeholder={t("EnterName")}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="project_contact_mobile" className="form-input-sm-label-required">{t('Mobile')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.courier_contact_mobile?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={18}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.courier_contact_mobile?.message}
                                    </div>}
                                    <input
                                        {...register("courier_contact_mobile")}
                                        type="text"
                                        className={`form-input-sm-control ${errors.courier_contact_mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                        placeholder={t("EnterMobile")}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 w-1/3 pr-3 items-center">
                                <label htmlFor="courier_address" className="form-input-sm-label-required">{t('Address')}</label>
                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.courier_address?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.courier_address?.message}
                                    </div>}
                                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                        <textarea
                                            {...register("courier_address")}
                                            className={`form-input-sm-control ${errors.courier_address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                                setValue('courier_address', e.target.value)
                                                setError('courier_address', null)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-6">
                                                                <div className="text-right pt-0.5 mb-0.5 ">
                                                                    <button
                                                                        type='reset'
                                                                        onClick={() => navigate(-1)}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16}
                                                                                           className={'mr-2'}/>
                                                                        <span>{t('Back')}</span>
                                                                    </button>

                                                                    <button type='submit'
                                                                            className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        <HiArrowCircleRight size={16}
                                                                                            className={'mr-2'}/>
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


        </>
    )
}

export default EditProject