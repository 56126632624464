import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createReceived,
    getGRNDropdown,
    getInvoiceBillDropdown,
    getReceivableItem,
    getReceivedApproved, getReceiveDataDelete,
    getReceivedChecked,
    getReceiveDetails, getReceiveDetailsMultiple,
    getReceivedWiseInvoiceBillDropdown,
    getReceiveList, getVerifyListForCsvDownload,
    postInvoiceChallanInlineUpdate,
    postReceivedFinalUpdate,
    postReceivedInlineUpdate,
    postReceivedItemsInlineUpdate, postReceivedMulVerifyForPayment, postReceivedVerifyForPayment, verifyCsvUpload
} from "../../services/ReceivedItemService";

export const ReceivableItem = createAsyncThunk("receivable/item", async (param) => {
    try {
        const response = getReceivableItem(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceivableCreate = createAsyncThunk("receivable/create", async (data) => {
    try {
        const response = createReceived(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceiveDetails = createAsyncThunk("receivable/details", async (param) => {
    try {
        const response = getReceiveDetails(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const ReceiveDetailsMultiple = createAsyncThunk("receivable/details-multiple", async (param) => {
    try {
        const response = getReceiveDetailsMultiple(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceiveInlineUpdate = createAsyncThunk("receivable/inline-update", async (param) => {
    try {
        const response = postReceivedInlineUpdate(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceiveInvoiceChallanInlineUpdate = createAsyncThunk("receivable/invoice-challan/inline-update", async (param) => {
    try {
        const response = postInvoiceChallanInlineUpdate(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceiveItemsInlineUpdate = createAsyncThunk("receivable/item/inline-update", async (data) => {
    try {
        const response = postReceivedItemsInlineUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceiveFinalUpdate = createAsyncThunk("receivable/final-update", async (data) => {
    try {
        const response = postReceivedFinalUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceivesList = createAsyncThunk("receivable/index", async (param) => {
    try {
        const response = getReceiveList(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceivedChecked = createAsyncThunk("received/checked", async (param) => {
    try {
        const response = getReceivedChecked(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const ReceivedApproved = createAsyncThunk("received/approved", async (param) => {
    try {
        const response = getReceivedApproved(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const grnDropdown = createAsyncThunk("received/grn-dropdown", async (param) => {
    try {
        const response = getGRNDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const invoiceBillDropdown = createAsyncThunk("received/invoice-bill-dropdown", async (param) => {
    try {
        const response = getInvoiceBillDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const receivedWiseinvoiceChallanDropdown = createAsyncThunk("received/received-wise/invoice-bill-dropdown", async (param) => {
    try {
        const response = getReceivedWiseInvoiceBillDropdown(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const receivedVerifyForPayment = createAsyncThunk("received/verify-for-payment", async (data) => {
    try {
        const response = postReceivedVerifyForPayment(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const receivedMulVerifyForPayment = createAsyncThunk("received/mul-verify-for-payment", async (data) => {
    try {
        const response = postReceivedMulVerifyForPayment(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const verifyListDownload = createAsyncThunk("received/verify-list-download", async (param) => {
    try {
        const response = getVerifyListForCsvDownload(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const verifyCsvUploadPost = createAsyncThunk("received/verify-csv/upload", async (data) => {
    try {
        const response = verifyCsvUpload(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const receivedDelete = createAsyncThunk("received/delete", async (data) => {
    try {
        const response = getReceiveDataDelete(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const receivedItemSlice = createSlice({
    name : "Received-Item",
    initialState : {
        projectData : null,
        loading : false,
        poID : null,
        receivableItem : [],
        checkItem : [],
        receivedID : null,
        receiveDetailsData : [],
        receiveDetailsDataMultiple : [],
        recInlineUpMSG : null,
        billChallanInlineUpMSG : null,
        recItemsInlineUpMSG : null,
        recFinalUpdateMSG : null,
        grn_ref : null,
        totalItem : 0,
        receivedIndexData : [],
        recItems : [],
        fetching : true,
        page : localStorage.getItem('received_item_page') ? localStorage.getItem('received_item_page') : 1,
        activeTab : 'all',
        sortStatus : { columnAccessor: 'id', direction: 'DESC' },
        opened : false,
        poIdForSearch : '',
        refresh : false,
        filterData : {po_id:'',po:'',grn_no:'',grn:'',vendor:'',project:'',invoiceBillNo:'',invoice:'',challanBillNo:'',challan:''},
        recViewID : null,
        recViewModel : false,
        checkedMessage : null,
        approvedMessage : null,
        grnDropdownData : [],
        verifyModelView : false,
        verifyID : null,
        invoiceBillDropdownData : [],
        challanDropdownData : [],
        invoiceDP : [],
        challanDP : [],
        verifyData : {bill_date:'',bill_number:'',replaySendBackComment:''},
        verifyMessage : null,
        verifyMulMessage : null,
        verifyRefresh : false,
        addInvoiceModel : false,
        addInvoiceReceivedID : null,
        verifyListDownloadDataForHeadOffice : [],
        verifyListDownloadDataForLocalOffice : [],
        verifyFileUploadModel : false,
        verifyCsvUploadMessage : null,
        verifyIdChecked : [],
        multiVerify : false,
        poDropdownLoading : false,
        poDropdown : [],


    },
    reducers : {
        setEmptyVerifyCsvUploadMessage : (state,action) => {
            state.verifyCsvUploadMessage = null
        },
        setAddInvoiceModel : (state,action) => {
            state.addInvoiceModel = action.payload
        },
        setAddInvoiceReceivedID : (state,action) => {
            state.addInvoiceReceivedID = action.payload
        },
        setProjectData : (state,action) => {
            state.projectData = action.payload
        },
        setVerifyRefresh : (state,action) => {
            state.verifyRefresh = action.payload
        },
        setLoading : (state,action) => {
            state.loading = action.payload
        },
        setPOID : (state,action) => {
            state.poID = action.payload
        },
        setReceivableItem : (state,action) => {
            state.receivableItem = []
        },
        setCheckItem : (state,action) => {
            state.checkItem.push(action.payload)
        },
        setEmptyCheckItem : (state,action) => {
            state.checkItem = []
        },
        setRemoveItem : (state,action) => {
            state.checkItem = state.checkItem.filter(d=> d.po_item_id !== action.payload )
        },
        setReceivedID : (state,action) => {
            state.receivedID = action.payload
        },
        setGrnRef : (state,action)=>{
            state.grn_ref = action.payload
        },
        setFinalUpdateMSG : (state,action)=>{
            state.recFinalUpdateMSG = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.po_id = action.payload.po_id;
            state.filterData.po = action.payload.po;
            state.filterData.grn_no = action.payload.grn_no;
            state.filterData.grn = action.payload.grn;
            state.filterData.vendor = action.payload.vendor;
            state.filterData.project = action.payload.project;
            state.filterData.invoiceBillNo = action.payload.invoiceBillNo;
            state.filterData.invoice = action.payload.invoice;
            state.filterData.challanBillNo = action.payload.challanBillNo;
            state.filterData.challan = action.payload.challan;
        },
        setRecViewID : (state,action) => {
            state.recViewID = action.payload
        },
        setRecViewModel : (state,action) => {
            state.recViewModel = action.payload
        },
        setVerifyModelView : (state,action) => {
            state.verifyModelView = action.payload
        },
        setVerifyID : (state,action) => {
            state.verifyID = action.payload
        },
        setVerifyData : (state,action) => {
            state.verifyData.bill_date = action.payload.bill_date
            state.verifyData.bill_number = action.payload.bill_number
            state.verifyData.replaySendBackComment = action.payload.replaySendBackComment
        },
        setVerifyMessage : (state,action) => {
            state.verifyMessage = action.payload
        },
        setVerifyMulMessage : (state,action) => {
            state.verifyMulMessage = action.payload
        },
        setReceiveDetailsData : (state,action) =>{
            state.receiveDetailsData=[]
        },
        setVerifyFileUploadModel : (state,action)=>{
            state.verifyFileUploadModel = action.payload
        },
        setVerifyIdChecked : (state,action) => {
            state.verifyIdChecked.push(action.payload)
            state.verifyIdChecked = [...new Set(state.verifyIdChecked)];
        },
        setVerifyIdUnChecked : (state,action) => {
            state.verifyIdChecked = state.verifyIdChecked.filter(e => e !== action.payload)
        },
        setVerifyMulIdNull : (state,action) => {
            state.verifyIdChecked = []
        },
        setMultiVerifyModel : (state,action) => {
            state.multiVerify = action.payload
        }
        },


    extraReducers : (builder) => {
        builder.addCase(ReceivableItem.fulfilled, (state, action) => {
            state.receivableItem = action.payload.receivableItems
            let poDp = []
            if (action.payload.receivablePo){
                poDp = action.payload.receivablePo.map((type, index) => {
                    if (type.vendors_name && type.vendors_name !='' && type.vendors_name != null){
                        return ({
                            'value': Number(type.id), 'label': 'POID: '+type.id+' (Vendor: '+type.vendors_name+') (Date Of Delivery: '+type.date_of_delivered+') (OrderQty: '+Number(type.total_order_item_quantity)+' )'
                        })
                    }
                    if (type.buyer_email && type.buyer_email !='' && type.buyer_email != null){
                        return ({
                            'value': Number(type.id), 'label': 'POID: '+type.id+' (Buyer: '+(type.buyer_username?type.buyer_username:type.buyer_email)+') (Date Of Delivery: '+type.date_of_delivered+') (OrderQty: '+Number(type.total_order_item_quantity)+')'
                        })
                    }
                    if (type.buyer_email =='' || type.vendors_name == '' || type.vendors_name == null || type.buyer_email == null){
                        return ({
                            'value': Number(type.id), 'label': 'POID: '+type.id+' (OrderQty: '+Number(type.total_order_item_quantity)+')'
                        })
                    }
                })
                state.poDropdown = poDp
            }
            // state.receivableItem = action.payload.receivableItems
            state.message = 'receivable item load'
            state.loading = false
            state.poDropdownLoading = false
        })

        builder.addCase(ReceivableCreate.fulfilled, (state, action) => {
            state.receivableItem = []
            state.checkItem = []
            state.projectData = null
            state.poID = null
            state.message = 'receivable create'
            state.receivedID = action.payload
            state.loading = false
        })

        builder.addCase(ReceiveDetails.fulfilled, (state, action) => {
            state.message = 'receive Details'
            state.loading = false
            state.receiveDetailsData = action.payload
            state.grn_ref = action.payload.ref_grn
            state.filterData.invoice = action.payload.invoices
            state.filterData.challan = action.payload.calans
        })

        builder.addCase(ReceiveDetailsMultiple.fulfilled, (state, action) => {
            state.message = 'receive Details'
            state.receiveDetailsDataMultiple = action.payload.data
            state.recItems = action.payload.rec_item
        })

        builder.addCase(ReceiveInlineUpdate.fulfilled,(state,action)=>{
            // state.receiveDetailsData = []
            // console.log(action.payload)
            state.recInlineUpMSG = action.payload
        })
        builder.addCase(ReceiveInvoiceChallanInlineUpdate.fulfilled,(state,action)=>{
            // state.receiveDetailsData = []
            // console.log(action.payload)
            state.billChallanInlineUpMSG = action.payload
        })
        builder.addCase(ReceiveItemsInlineUpdate.fulfilled,(state,action)=>{
            state.recItemsInlineUpMSG = action.payload
        })
        builder.addCase(ReceiveFinalUpdate.fulfilled,(state,action)=>{
            state.recFinalUpdateMSG = action.payload
            state.projectData = null
            state.loading = false
            state.receivableItem = []
            state.checkItem = []
            state.poID = null
            state.receivedID = null
            state.receiveDetailsData = []
            state.recInlineUpMSG = null,
            state.billChallanInlineUpMSG = null,
            state.recItemsInlineUpMSG = null,
            state.recItemsInlineUpMSG = null,
            state.grn_ref = null
        })
        builder.addCase(ReceivesList.fulfilled,(state,action)=>{
            state.totalItem = action.payload.total
            state.receivedIndexData = action.payload.data
            state.fetching = false
            state.verifyRefresh = false
        })
        builder.addCase(receivedDelete.fulfilled,(state,action)=>{
            state.fetching = true
        })

        builder.addCase(ReceivedChecked.fulfilled,(state,action)=>{
            state.checkedMessage = action.payload
        })

        builder.addCase(ReceivedApproved.fulfilled,(state,action) => {
            state.approvedMessage = action.payload
        })

        builder.addCase(grnDropdown.fulfilled,(state,action) => {
            state.grnDropdownData = action.payload
        })
        builder.addCase(invoiceBillDropdown.fulfilled,(state,action) => {
            if (action.payload.type === 'invoice'){
                state.invoiceBillDropdownData = action.payload.data
            }
            if (action.payload.type === 'challan'){
                state.challanDropdownData = action.payload.data
            }
        })

        builder.addCase(receivedWiseinvoiceChallanDropdown.fulfilled,(state,action) => {
            state.invoiceDP = action.payload.invoice
            state.challanDP = action.payload.challan
        })

        builder.addCase(receivedVerifyForPayment.fulfilled,(state,action) => {
            state.verifyMessage = action.payload
        })
        builder.addCase(receivedMulVerifyForPayment.fulfilled,(state,action) => {
            state.verifyMulMessage = action.payload
            // state.verifyIdChecked = []
        })

        builder.addCase(verifyListDownload.fulfilled,(state,action) => {
            state.verifyListDownloadDataForLocalOffice = action.payload.localOffice
            state.verifyListDownloadDataForHeadOffice = action.payload.headOffice
        })

        builder.addCase(verifyCsvUploadPost.fulfilled,(state,action) => {
            state.verifyCsvUploadMessage = action.payload
        })
    }
})

export const { setProjectData,setLoading,setPOID,setReceivableItem,setCheckItem,setRemoveItem,setEmptyCheckItem,setReceivedID,setGrnRef,setFinalUpdateMSG,setPage,setActiveTab,setSortStatus,setOpened,setFetching,setRefresh,setFilterData,setRecViewID,setRecViewModel,setVerifyID,setVerifyModelView,setVerifyData,setVerifyMessage,setReceiveDetailsData,setVerifyRefresh,setAddInvoiceModel,setAddInvoiceReceivedID,setVerifyFileUploadModel,setVerifyIdChecked,setVerifyIdUnChecked,setMultiVerifyModel,setVerifyMulMessage,setVerifyMulIdNull,setEmptyVerifyCsvUploadMessage } = receivedItemSlice.actions
export default receivedItemSlice.reducer;