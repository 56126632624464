import React from "react";
import {useTranslation} from "react-i18next";

import {
    setFilterData as setPaymentFilterData
} from "../../../store/payment/paymentSlice";

import {useDispatch, useSelector} from "react-redux";
import {DatePicker} from "@mantine/dates";

function DatePickerInputField(props){
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const paymentFilterData = useSelector((state) => state.paymentReducer.filterData)

    return(
        <>
            <DatePicker
                dropdownType="modal"
                placeholder={props.placeholder}
                onChange={(e) => {
                    if(props.module === 'payment'){dispatch(setPaymentFilterData({...paymentFilterData, [props.name]: e}))}
                }}
                value={
                    props.module === 'payment' ? paymentFilterData[props.name] :
                    ''
                }
            />
        </>
    )
}

export default DatePickerInputField