import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,HiSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Group, LoadingOverlay, Select, Stack, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";

import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {openConfirmModal} from "@mantine/modals";
import {
    getPoReport,
    setPoReportFilterData,
    setFetching,
    setPage,
    getPoReportAll
} from "../../../../store/report/reportSlice";
import {vendorDropdown} from "../../../../store/vendor/vendorSlice";
import {showNotification} from "@mantine/notifications";
import {IconChevronRight} from "@tabler/icons-react";
import classes from './poReportNasted.module.css';
import clsx from "clsx";

const PAGE_SIZE = 20;

function PoReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })

    const [startDate,setStartDate] = useState(null)

    // start get project dropdown
    const [projectData, setProjectData] = useState('');
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    // start get contact person dropdown
    const [isDownload, setIsDownload] = useState(false);

    const poReportReportData = useSelector((state) => state.reportReducer.poReportReportData)
    const poReportDownloadData = useSelector((state) => state.reportReducer.poReportDownloadData)
    const poReportFilterData = useSelector((state) => state.reportReducer.poReportFilterData)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)
    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)

    const [expandedCompanyIds, setExpandedCompanyIds] = useState([]);

    useEffect(() => {
        dispatch(vendorDropdown())
    }, [fetching,page]);

    const CallPoReport = (page=1) => {
        if (poReportFilterData.start_date == '' || poReportFilterData.end_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start & end date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            dispatch(setFetching(true))
            const param = {
                offset : PAGE_SIZE,
                page : page,
                project : poReportFilterData.project,
                vendor : poReportFilterData.vendor,
                start_date : format(poReportFilterData.start_date,'yyyy-MM-dd'),
                end_date : format(poReportFilterData.end_date,'yyyy-MM-dd')
            }

            dispatch(getPoReport(param))
        }
    }


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToCSV = (formatType,type) => {
        const param = {
            project : poReportFilterData.project,
            vendor : poReportFilterData.vendor,
            start_date : format(poReportFilterData.start_date,'yyyy-MM-dd'),
            end_date : format(poReportFilterData.end_date,'yyyy-MM-dd')
        }
        dispatch(getPoReportAll(param))
        openConfirmModal({
            title: 'It may some times , sure ? ',
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToDownloadAllStockItem')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
            }
            ,
        });
    }

        if (isDownload){
            if (poReportDownloadData.length>0) {
                const ws = XLSX.utils.json_to_sheet(poReportDownloadData);
                const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'csv', type: 'array'
                });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, 'po-report-' + format(new Date(), 'dd-MM-yyyy') + '.' + 'csv');
                setIsDownload(false)
            }
        }


    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PoReport')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PoReportRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {(poReportFilterData.start_date && poReportFilterData.end_date && poReportReportData.length>0) &&
                                                                <>
                                                                <button
                                                                onClick={(e) => exportToCSV('csv', 'all')}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={poReportFilterData.start_date?poReportFilterData.start_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPoReportFilterData({
                                                                                        ...poReportFilterData,
                                                                                        ['start_date']: e
                                                                                        // ['start_date']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPoReportFilterData({
                                                                                        ...poReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <DatePicker
                                                                            placeholder="Choose End Date"
                                                                            value={poReportFilterData.end_date?poReportFilterData.end_date:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setPoReportFilterData({
                                                                                        ...poReportFilterData,
                                                                                        ['end_date']: e
                                                                                    }))
                                                                                }else {
                                                                                    dispatch(setPoReportFilterData({
                                                                                        ...poReportFilterData,
                                                                                        ['end_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5 ">
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPoReportFilterData({
                                                                                    ...poReportFilterData,
                                                                                    ['project']: e
                                                                                }))
                                                                            }}
                                                                            value={poReportFilterData.project}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5 ">
                                                                        <Select
                                                                            placeholder={t("ChooseVendor")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={vendorDropdownData}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPoReportFilterData({
                                                                                    ...poReportFilterData,
                                                                                    ['vendor']: e
                                                                                }))
                                                                            }}
                                                                            value={poReportFilterData.vendor}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/5">
                                                                        <button
                                                                            onClick={(e) => CallPoReport()}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{height: reportTableHeight,width: containerWidth}} className="mt-3">
                                                                    <DataTable
                                                                        withBorder
                                                                        records={poReportReportData}
                                                                        withColumnBorders={1}
                                                                        highlightOnHover
                                                                        columns={[
                                                                            {
                                                                                accessor: 'classes',
                                                                                title: '',
                                                                                render: ({ id }) => (
                                                                                    <>
                                                                                        <IconChevronRight
                                                                                            className={clsx( classes.icon,classes.expandIcon, {
                                                                                                [classes.expandIconRotated]: expandedCompanyIds.includes(id),
                                                                                            })}
                                                                                        />
                                                                                    </>
                                                                                ),
                                                                            },
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                // textAlignment: 'right',
                                                                                render: (item) => (poReportReportData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {accessor: 'itemsName', title: <Text mr="xs">Item</Text>,},
                                                                            {accessor: 'projectsName', title: 'Project Name',},
                                                                            {accessor: 'companyType', title: 'Company Name',},
                                                                            {accessor: 'projectType', title: 'Project Type',textAlignment:"center"},
                                                                            {accessor: 'areasName', title: 'Project Area',textAlignment:"center"},
                                                                            {accessor: 'subCategoriesName', title: 'Sub Category',textAlignment:"center"},
                                                                            {accessor: 'purchaseTypeName', title: 'PO Type',textAlignment:"center"},
                                                                            {accessor: 'poNetTotal', title: 'Po Amount',textAlignment:"center"},
                                                                            {accessor: 'lastPoiProjectItemWise', title: 'Last Po',textAlignment:"center"},
                                                                            // {accessor: 'lastPoPrice', title: 'Last Po Price',textAlignment:"center"},
                                                                            {accessor: 'lastPoiPriceProjectItemWise', title: 'Last Po Price',textAlignment:"center",
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.lastPoiPriceProjectItemWise &&
                                                                                        item.lastPoiPriceProjectItemWise.toFixed(2)
                                                                                        }
                                                                                    </>

                                                                            },
                                                                        ]}
                                                                        rowExpansion={{
                                                                            expanded: { recordIds: expandedCompanyIds, onRecordIdsChange: setExpandedCompanyIds },
                                                                            collapseProps: {
                                                                                transitionDuration: 500,
                                                                                animateOpacity: false,
                                                                                transitionTimingFunction: 'ease-out',
                                                                            },
                                                                            content: ({ record }) => (
                                                                                <Stack p="xs" gap={6} className="bg-indigo-100">
                                                                                    <Group gap={6}>
                                                                                        <table className="table-full w-full border-slate-800 text-xs">
                                                                                            <tr>
                                                                                                <th>Brand</th>
                                                                                                <td>{record.brand}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Unit</th>
                                                                                                <td>{record.itemUnitName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Item type </th>
                                                                                                <td>{record.itemTypes}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Category Name </th>
                                                                                                <td>{record.categoriesName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Sub Category Name</th>
                                                                                                <td>{record.subCategoriesName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Unit Price</th>
                                                                                                <td>{record.unitPrice}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Item Nature</th>
                                                                                                <td>{record.itemNature}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Operation Type</th>
                                                                                                <td>{record.pr_projectType}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Vehicle Number</th>
                                                                                                <td>{record.vehicle_number}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Purchase Order</th>
                                                                                                <td>{record.poId}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Pr Ref No</th>
                                                                                                <td>{record.prNumber?record.prNumber:record.pr_id}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Po Ref No</th>
                                                                                                <td>{record.refNo}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Po Qty</th>
                                                                                                <td>{record.poQuantity}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Spend Area</th>
                                                                                                <td>{record.spendAreaTitle}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Net Payment Amount</th>
                                                                                                <td>{record.poNetTotal}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>AIT Deduct Amount</th>
                                                                                                <td>{record.totalAitAmount}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Advance Amount</th>
                                                                                                <td>{record.advancePaymentAmount}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Payment From</th>
                                                                                                <td>{record.paymentFrom==1?'Local':'Head'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Payment Method</th>
                                                                                                <td>{record.paymentMethod}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Vat</th>
                                                                                                <td>{record.totalVatAmount}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>GRN Qty</th>
                                                                                                <td>{record.totalOrderReceiveQuantity}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>GRN Amount</th>
                                                                                                <td>{record.totalOrderReceiveQuantity && (record.totalOrderReceiveQuantity*record.unitPrice).toFixed(2)}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>GRN Date</th>
                                                                                                <td>{record.totalOrderReceiveQuantity && record.receivedDate}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Po Date</th>
                                                                                                <td>{record.createdDate}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Month</th>
                                                                                                <td>{record.month}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Issued By</th>
                                                                                                <td>{record.username}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Buyer Name</th>
                                                                                                <td>{record.buyerName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Vendor Name</th>
                                                                                                <td>{record.vendorsName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Purchase Type</th>
                                                                                                <td>{record.purchaseTypeName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Close/Cancel Qty</th>
                                                                                                <td>{record.closeQty}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Close/Cancel By</th>
                                                                                                <td>{record.closeBy}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Close/Cancel Remarks</th>
                                                                                                <td>{record.remark}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Payment Amount</th>
                                                                                                <td>{record.paymentAmount}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Received By</th>
                                                                                                <td>{record.receivedBy}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Budget</th>
                                                                                                <td>{record.budgetGenerateId}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Last Price Item</th>
                                                                                                <td>{record.lastPoiPrice?record.lastPoiPrice.toFixed(2):null}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Last Price Item & User Wise</th>
                                                                                                <td>{record.las?record.lastPoiProjectItemUserWise.toFixed(2):null}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </Group>
                                                                                </Stack>
                                                                            ),
                                                                        }}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            CallPoReport(p)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PoReport