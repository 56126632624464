import React, {useEffect} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,HiEye,HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {ActionIcon, Box, Group, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import {openConfirmModal} from "@mantine/modals";
import {
    setFetching,
    setPage,
    setActiveTab,
    setSortStatus,
    setSpinner,
    setOpened,
    setRefresh,
    getStoreRequisitionList,
    createEmptyStoreRequisition,
    setViewModel,
    setViewId,
    storeRequisitionDelete
} from "../../../store/storeRequisition/storeRequisitionSlice";
import {projectDropdown, projectDropdownUserWise} from "../../../store/project/projectSlice";
import SrViewModel from "./SrViewModel";
import {setItemCategorySubCat} from "../../../store/items/itemsSlice";
import StoreRequisitionFilter from "./StoreRequisitionFilter";
const PAGE_SIZE = 20;

function Dashboard(){
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableHeightWithFilter = localStorage.getItem('tableHeightWithFilter')
    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_STORE_REQUISITION_ADD = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_ADD";
    })
    const ROLE_STORE_REQUISITION_EDIT = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_EDIT";
    })
    const ROLE_STORE_REQUISITION_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_ONE";
    })
    const ROLE_STORE_REQUISITION_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_TWO";
    })
    const ROLE_STORE_REQUISITION_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_APPROVE_THREE";
    })
    const ROLE_STORE_REQUISITION_RECEIVED = decoded.roles.find(role => {
        return role === "ROLE_STORE_REQUISITION_RECEIVED";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })


    const fetching = useSelector((state) => state.storeRequisitionReducer.fetching)
    const storeRequisitionIndex = useSelector((state) => state.storeRequisitionReducer.storeRequisitionIndex)
    const totalItem = useSelector((state) => state.storeRequisitionReducer.totalItem)
    const page = useSelector((state) => state.storeRequisitionReducer.page)
    const activeTab = useSelector((state) => state.storeRequisitionReducer.activeTab)
    const sortStatus = useSelector((state) => state.storeRequisitionReducer.sortStatus)
    const filterData = useSelector((state) => state.storeRequisitionReducer.filterData)
    const refresh = useSelector((state) => state.storeRequisitionReducer.refresh)
    const viewModel = useSelector((state) => state.storeRequisitionReducer.viewModel)

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(()=>{
        dispatch(projectDropdownUserWise())
        dispatch(projectDropdown())
    },[])

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            project_from : filterData.project_from ? filterData.project_from:'',
            project_to : filterData.project_to ? filterData.project_to:'',
            sr_number : filterData.sr_number ? filterData.sr_number:'',
        }
            dispatch(getStoreRequisitionList(param))
    }, [page,activeTab,sortStatus,refresh,fetching]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);

    const CreateStoreRequisition = (e) => {
        dispatch(setItemCategorySubCat())
        dispatch(createEmptyStoreRequisition({
            "status": 0
        }))
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('StoreRequisition')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllStoreRequisitionRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            {
                                                                ROLE_STORE_REQUISITION_ADD &&
                                                                <button onClick={event => CreateStoreRequisition(event)}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">

                                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                                    <StoreRequisitionFilter/>
                                                                </form>

                                                                    <div className="h-11">
                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'all')}>
                                                                            {t('All')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Created' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Created')}>
                                                                            {t('Created')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Verified' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Verified')}>
                                                                            {t('Verified')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Validated' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Validated')}>
                                                                            {t('Validated')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Approved' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Approved')}>
                                                                            {t('Approved')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Delivered' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Delivered')}>
                                                                            {t('Delivered')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Draft' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Draft')}>
                                                                            {t('Draft')}
                                                                        </button>

                                                                        <button
                                                                            className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Received' ? 'bg-indigo-600' : 'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `}
                                                                            onClick={(e) => HandelPoTabChange(e, 'Received')}>
                                                                            {t('Archive')}
                                                                        </button>
                                                                    </div>

                                                                    <Box sx={{height: tableHeightWithFilter}}>
                                                                        <DataTable
                                                                            withBorder
                                                                            records={storeRequisitionIndex}
                                                                            withColumnBorders={1}
                                                                            columns={[
                                                                                {
                                                                                    accessor: 'index',
                                                                                    title: 'SL#',
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (storeRequisitionIndex.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                },
                                                                                {
                                                                                    accessor: 'createdDate',
                                                                                    title: <Text mr="xs">Date</Text>,
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'srNumber',
                                                                                    title: 'Sr Number',
                                                                                    sortable: true,
                                                                                },
                                                                                {
                                                                                    accessor: 'pf_name',
                                                                                    title: 'Project From'
                                                                                },
                                                                                {
                                                                                    accessor: 'pt_name',
                                                                                    title: 'Project To'
                                                                                },

                                                                                {
                                                                                    accessor: 'process',
                                                                                    title: 'Status',
                                                                                    sortable: true,
                                                                                    textAlignment: 'center',
                                                                                    render: (item) =>
                                                                                        <>
                                                                                            {item.process === 'Draft' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-rose-300	w-20 block text-center">Draft</span>}
                                                                                            {item.process === 'Created' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-green-300	 w-20 block text-center">Created</span>}
                                                                                            {item.process === 'Verified' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-yellow-300	   w-20 block text-center">Verified</span>}
                                                                                            {item.process === 'Validated' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-purple-300	  w-20 block text-center">Validated</span>}
                                                                                            {item.process === 'Approved' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-lime-300	 w-20 block text-center">Approved</span>}
                                                                                            {item.process === 'Received' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-blue-300	 w-20 block text-center">Received</span>}
                                                                                            {item.process === 'Delivered' &&
                                                                                                <span
                                                                                                    className="items-center px-2 py-1 text-black text-xs bg-fuchsia-300	 w-20 block text-center">Delivered</span>}
                                                                                        </>
                                                                                }, {
                                                                                    accessor: 'createdUserName',
                                                                                    title: 'Created By',
                                                                                    textAlignment: 'center',
                                                                                    render: (item) =>
                                                                                        <>
                                                                                            {
                                                                                                item.createdUserName ? item.createdUserName : item.createdEmail
                                                                                            }
                                                                                        </>
                                                                                },

                                                                                {
                                                                                    accessor: 'approved',
                                                                                    title: <Text mr="xs">Approve</Text>,
                                                                                    textAlignment: 'center',
                                                                                    hidden: !ROLE_STORE_REQUISITION_APPROVE_ONE && !ROLE_STORE_REQUISITION_APPROVE_TWO && !ROLE_STORE_REQUISITION_APPROVE_THREE && !ROLE_STORE_REQUISITION_RECEIVED && !ROLE_SUPER_ADMIN,
                                                                                    render: (item) =>
                                                                                        <>
                                                                                            {
                                                                                                (item.process === 'Created' && ROLE_STORE_REQUISITION_APPROVE_ONE && item.is_approved == 'yes') &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setViewModel(true))
                                                                                                        dispatch(setViewId(item.id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-yellow-600 hover:bg-yellow-700 text-white text-xs">
                                                                                                    Verified
                                                                                                </button>
                                                                                            }

                                                                                            {
                                                                                                (item.process === 'Verified' && ROLE_STORE_REQUISITION_APPROVE_TWO && item.is_approved == 'yes') &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setViewModel(true))
                                                                                                        dispatch(setViewId(item.id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-indigo-600 hover:bg-indigo-700 text-white text-xs">
                                                                                                    Validated
                                                                                                </button>
                                                                                            }

                                                                                            {
                                                                                                (item.process === 'Validated' && ROLE_STORE_REQUISITION_APPROVE_THREE && item.is_approved == 'yes') &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setViewModel(true))
                                                                                                        dispatch(setViewId(item.id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-lime-600 hover:bg-lime-700 text-white text-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            }

                                                                                            {
                                                                                                (item.process === 'Approved' && (ROLE_STORE_REQUISITION_APPROVE_THREE || ROLE_STORE_REQUISITION_APPROVE_ONE || ROLE_STORE_REQUISITION_APPROVE_TWO) && item.is_approved == 'yes' && item.isDelivered == 0) &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setViewModel(true))
                                                                                                        dispatch(setViewId(item.id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-lime-600 hover:bg-lime-700 text-white text-xs">
                                                                                                    Delivery
                                                                                                </button>
                                                                                            }

                                                                                            {
                                                                                                ((item.is_received == 'yes' && ROLE_STORE_REQUISITION_RECEIVED && item.approveStatus == 3 && item.status != 2 && item.isDelivered == 1) || (ROLE_SUPER_ADMIN && item.approveStatus == 3 && item.status != 2 && item.isDelivered == 1)) &&
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(setViewModel(true))
                                                                                                        dispatch(setViewId(item.id))
                                                                                                    }}
                                                                                                    className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                                    Received
                                                                                                </button>
                                                                                            }

                                                                                        </>,
                                                                                },
                                                                                {
                                                                                    accessor: 'actions',
                                                                                    title: <Text mr="xs">Action</Text>,
                                                                                    textAlignment: 'right',
                                                                                    render: (item) => (
                                                                                        <Group spacing={4}
                                                                                               position="right"
                                                                                               noWrap>
                                                                                            {
                                                                                                (item.process == 'Created' && (ROLE_STORE_REQUISITION_EDIT || ROLE_STORE_REQUISITION_ADD || ROLE_SUPER_ADMIN)) &&
                                                                                                <ActionIcon
                                                                                                    color="green"
                                                                                                    onClick={(e) => {
                                                                                                        navigate('/store-requisition/edit/' + item.id);
                                                                                                    }}>
                                                                                                    <HiPencil
                                                                                                        size={16}/>
                                                                                                </ActionIcon>
                                                                                            }

                                                                                            <ActionIcon color="blue">
                                                                                                <HiEye size={16}
                                                                                                       onClick={(e) => {
                                                                                                           dispatch(setViewModel(true))
                                                                                                           dispatch(setViewId(item.id))
                                                                                                       }}/>
                                                                                            </ActionIcon>

                                                                                            {
                                                                                                item.process == 'Draft' && (ROLE_STORE_REQUISITION_ADD || ROLE_SUPER_ADMIN) &&
                                                                                                <>
                                                                                                    <ActionIcon
                                                                                                        color="green"
                                                                                                        onClick={(e) => {
                                                                                                            navigate('/store-requisition/edit/' + item.id);
                                                                                                        }}>
                                                                                                        <HiPencil
                                                                                                            size={16}/>
                                                                                                    </ActionIcon>
                                                                                                    <ActionIcon
                                                                                                        color="red">
                                                                                                        <HiTrash
                                                                                                            size={16}
                                                                                                            onClick={(e) => {
                                                                                                                openConfirmModal({
                                                                                                                    title: t('storeRequisitionDelete'),
                                                                                                                    centered: true,
                                                                                                                    children: (
                                                                                                                        <Text
                                                                                                                            size="sm">
                                                                                                                            {t('AreYouSurestoreRequisitionDelete')}
                                                                                                                        </Text>
                                                                                                                    ),
                                                                                                                    labels: {
                                                                                                                        confirm: (t('Confirm')),
                                                                                                                        cancel: (t("Cancel"))
                                                                                                                    },
                                                                                                                    confirmProps: {color: 'red'},
                                                                                                                    onCancel: () => console.log(t('Cancel')),
                                                                                                                    onConfirm: () => {
                                                                                                                        dispatch(storeRequisitionDelete(item.id))
                                                                                                                        dispatch(setFetching(true))
                                                                                                                    }
                                                                                                                    ,
                                                                                                                });
                                                                                                            }}/>
                                                                                                    </ActionIcon>
                                                                                                </>
                                                                                            }
                                                                                        </Group>
                                                                                    ),
                                                                                },

                                                                            ]}
                                                                            totalRecords={totalItem}
                                                                            recordsPerPage={PAGE_SIZE}
                                                                            page={page}
                                                                            onPageChange={(p) => {
                                                                                dispatch(setPage(p))
                                                                                dispatch(setFetching(true))
                                                                            }}
                                                                            fetching={fetching}
                                                                            loaderSize="xs"
                                                                            loadercolor="blue"
                                                                            loaderBackgroundBlur={1}
                                                                            sortStatus={sortStatus}
                                                                            onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                        />
                                                                    </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {viewModel?<SrViewModel />:''}
        </>
    )
}
export default Dashboard