import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { LoadingOverlay, ScrollArea, Text} from "@mantine/core";
import {
    HiCloudDownload,
    HiOutlineX,
} from "react-icons/hi";
import axios from "axios";
import {openConfirmModal} from "@mantine/modals";
import {Skeleton} from '@mantine/core';
import jwt_decode from "jwt-decode";


function RequisitionView({requisitionID, setViewModel, setRefresh,requisitionStatus}) {
    const {t, i18n} = useTranslation();
    const modalHeight = localStorage.getItem('modalHeight');
    const [requisitionData, setRequisitionData] = useState('');
    const [requisitionItem, setRequisitionItem] = useState('');
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState(false);
    const [totalLoading, setTotalLoading] = useState(1);
    const [loadingID,setLoadingId]=useState('')
    const [reqStatus,setReqStatus]=useState(requisitionStatus)

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_REQUISITION_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_APPROVE_ONE";
    })
    const ROLE_PURCHASE_REQUISITION_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_APPROVE_TWO";
    })
    const ROLE_PURCHASE_REQUISITION_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_APPROVE_THREE";
    })

    const ROLE_PURCHASE_REQUISITION_CLAIM = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_CLAIM";
    })

    const ROLE_PURCHASE_REQUISITION_CANCEL = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_REQUISITION_CANCEL";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    useEffect(() => {
        if (totalLoading === 1) {
            setLoading(true)
        }
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/details/${requisitionID}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {}
        })
            .then(res => {
                setTimeout(() => {
                    if (res.data.status === 200) {
                        setRequisitionData(res.data.data);
                        setRequisitionItem(res.data.data.purchaseRequisitionItems);
                        setReload(false)
                        setLoading(false)
                        setItemLoading(false)
                        setTotalLoading(totalLoading + 1)
                        setLoadingId('')
                    }

                }, 500)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [reload])

    const ItemClaimed = (e, itemID) => {
        openConfirmModal({
            title: (t('ClaimedThisItem')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToClaimedThisItem')}
                </Text>
            ),
            labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
            confirmProps: {color: 'green'},
            // cancelProps: { color: 'red' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setItemLoading(true)
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition-item/claim`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    params: {
                        'item_id': itemID
                    }
                })
                    .then(res => {
                        setTimeout(() => {
                            if (res.data.status === 202) {
                                setReload(true)
                                setLoading(false)
                                setLoadingId(itemID)
                            }
                        }, 700)

                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div
                            className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PurchaseRequisitionDetails')}</h5>
                                <button
                                    onClick={() => {
                                        setViewModel(false)
                                        setRefresh(true)
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{ size: 'sm', color: 'indigo', variant: 'bars' }}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div
                                                                            className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div
                                                                                className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div
                                                                                    className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('RequisitionInformation')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list"
                                                                                            className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('PRNumber')} : {requisitionData.pr_number ? requisitionData.pr_number : ''} </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('ProjectName')} : {requisitionData.projectName ? requisitionData.projectName : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('RefNo')} : {requisitionData.ref_no ? requisitionData.ref_no : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('Priority')} : {requisitionData.priority ? requisitionData.priority : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('Vehicle Type')} : {requisitionData.variantName?'Vehicle':'Not Applicable'}</p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">Vehicle Number : {requisitionData.variantName?requisitionData.variantName:''}</p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('ProjectType')} : {requisitionData.project_type?requisitionData.project_type:''}</p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('Budget')} : {requisitionData.budget ? 'Applicable ' + requisitionData.budgetName : 'Not applicable'}</p>
                                                                                            </li>


                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('AreaName')} : {requisitionData.projectAreaName ? requisitionData.projectAreaName : ''}</p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('CompanyType')} : {requisitionData.projectTypeName ? requisitionData.projectTypeName : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('CCN')} : {requisitionData.projectCostCenterNumber ? requisitionData.projectCostCenterNumber : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('IssueBy')} : {requisitionData.createdByName ? requisitionData.createdByName : ''} </p>
                                                                                            </li>
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('ProjectHead')} : {requisitionData.projectHeadName ? requisitionData.projectHeadName : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('IssueDate')} : {requisitionData.createdDate ? requisitionData.createdDate : ''} </p>
                                                                                            </li>


                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('Address')} : {requisitionData.projectAddress ? requisitionData.projectAddress : ''} </p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
<p className="ml-3 text-sm text-gray-700">{t('Attachment')} :
    {requisitionData.path &&
        <a title="Purchase Requisition"
           target="_blank"
           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/purchase-requisition/pdf-show/'+ requisitionData.id}`}
           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
            Open Attachment
        </a>
    }
</p>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>


                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div
                                                                            className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div
                                                                                className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div
                                                                                    className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ApprovalInformation')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>

<table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
<thead className="text-xs font-semibold  text-black bg-gray-200">
<tr>
    <th className="p-2 border-collapse border border-slate-400">
        <div className="font-semibold text-center">{t('SL')}</div>
    </th>
    <th className="p-2 border-collapse border border-slate-400">
        <div className="font-semibold text-center">{t('ApprovalType')}</div>
    </th>
    <th className="p-2 border-collapse border border-slate-400">
        <div className="font-semibold text-center">{t('Name')}</div>
    </th>
    <th className="p-2 border-collapse border border-slate-400">
        <div className="font-semibold text-center">{t('Date')}</div>
    </th>
</tr>
</thead>
<tbody>
{
    requisitionData.verifiedName &&
    <tr className={"bg-gray-100"} key="1">
        <td className="w-1/24 border-collapse border border-slate-400 text-center">1</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">Verified</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.verifiedName ? requisitionData.verifiedName : ''}</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.verifiedDate ? requisitionData.verifiedDate : ''}</td>
    </tr>
}

{
    requisitionData.validatedName &&
    <tr className={"bg-gray-100"} key="2">
        <td className="w-1/24 border-collapse border border-slate-400 text-center">2</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">Validated</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.validatedName ? requisitionData.validatedName : ''}</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.validatedDate ? requisitionData.validatedDate : ''}</td>
    </tr>
}
{
    requisitionData.approvedName &&
    <tr className={"bg-gray-100"} key="3">
        <td className="w-1/24 border-collapse border border-slate-400 text-center">3</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">Approved</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.approvedName ? requisitionData.approvedName : ''}</td>
        <td className="w-1/24 border-collapse border border-slate-400 text-center">{requisitionData.approvedDate ? requisitionData.approvedDate : ''}</td>
    </tr>
}

</tbody>
</table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div
                                                                            className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div
                                                                                className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div
                                                                                    className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseRequisitionItem')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                            <tr>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('SL')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Item')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('ItemNature')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Category')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('SubCategory')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('SpendArea')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Quantity')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('RequiredDate')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('PurchaseFrom')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Remark')}</div>
                                                                                                </th>

                                                                                                {
                                                                                                    requisitionData.approvedId &&
                                                                                                    <>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('status')}</div>
                                                                                                        </th>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('ClaimedBy')}</div>
                                                                                                        </th>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('Claimed')}</div>
                                                                                                        </th>
                                                                                                    </>
                                                                                                }

                                                                                                {
                                                                                                    requisitionData.is_item_close &&
                                                                                                    <>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('CloseBy')}</div>
                                                                                                        </th>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('CloseDate')}</div>
                                                                                                        </th>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('CloseQuantity')}</div>
                                                                                                        </th>
                                                                                                        <th className="p-2 border-collapse border border-slate-400">
                                                                                                            <div className="font-semibold text-center">{t('CloseRemark')}</div>
                                                                                                        </th>
                                                                                                    </>
                                                                                                }
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {requisitionItem ? requisitionItem.map((item, index) => {
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.itemName ? item.itemName : null} ({item.itemType ? item.itemType : null}-{item.itemUnit ? item.itemUnit : null})</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.item_nature ? item.item_nature : null}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.category_name ? item.category_name : null}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.sub_category_name ? item.sub_category_name:''}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.spend_area_name ? item.spend_area_name : null}
                                                                                                            {item.budget_head_amount ? ' ('+item.budget_head_amount+' tk.)' : null}
                                                                                                        </td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.quantities?item.quantities:''}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.date_of_required ? item.date_of_required : null}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.isHeadOrLocal?item.isHeadOrLocal:null}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.comments ? item.comments : null}</td>
        {
            requisitionData.approvedId &&
            <>
                <td className="w-1/24 border-collapse border border-slate-400 text-center">
                    {itemLoading && item.id === loadingID ?
                        <>
                            <Skeleton
                                height={8}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                width="70%"
                                radius="xl"/>
                        </>
                        :
                        <>
                            {
                                item.claimed_by ?
                                    (item.close_info.close_by?
                                        <span className={`bg-rose-700 px-1 py-1 text-white`}>Close</span>:
                                        <span className={`bg-blue-700 px-1 py-1 text-white`}>Claimed</span>)
                                     :
                                    <span className={`bg-green-300 px-1 py-1`}>Open</span>
                            }
                        </>
                    }

                </td>

                <td className="w-1/24 border-collapse border border-slate-400 text-center">
                    {itemLoading && item.id === loadingID ?
                        <>
                            <Skeleton
                                height={8}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                width="70%"
                                radius="xl"/>
                        </>
                        :
                        <>
                            {
                                item.claimed_by ?
                                    item.claimed_by :
                                    'None'
                            }
                        </>
                    }

                </td>

                <td className="w-1/24 border-collapse border border-slate-400 text-center">

                    {itemLoading && item.id === loadingID ?
                        <>
                            <Skeleton
                                height={8}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                radius="xl"/>
                            <Skeleton
                                height={8}
                                mt={6}
                                width="70%"
                                radius="xl"/>
                        </>
                        :
                        <>
                            {
                                item.claimed_by ?
                                    '' : (ROLE_PURCHASE_REQUISITION_CLAIM && requisitionData.approve_status==3) &&
                                    <button
                                        onClick={(e) => ItemClaimed(e, item.id)}
                                        className="inline-flex items-center px-2 py-1 bg-green-700 hover:bg-green-900 text-white text-xs">
                                        Claim
                                    </button>
                            }
                        </>
                    }

                </td>
            </>
        }
                                                                                                        {
                                                                                                            requisitionData.is_item_close &&
                                                                                                                <>
                                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.close_info.close_by ? item.close_info.close_by : null}</td>
                                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.close_info.close_date ? item.close_info.close_date : null}</td>
                                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.close_info.close_quantity ? item.close_info.close_quantity : null}</td>
                                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.close_info.close_remark ? item.close_info.close_remark : null}</td>
                                                                                                            </>


                                                                                                        }

                                                                                                    </tr>
                                                                                                )
                                                                                            }) : null}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>


                                                        <Fragment>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div
                                                                            className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div
                                                                                className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div
                                                                                    className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <div className="mt-4">
                                                                                        <div
                                                                                            className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PurchaseRequisitionItemHistory')}</h4>
                                                                                            <div
                                                                                                className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs" id="my-table">
                                                                                            <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                            <tr>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('SL')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Item')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('Category')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('SubCategory')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">Ware-house / Non-stock</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('LastPOUnitPrice')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('LastReceiveQuantity')}</div>
                                                                                                </th>
                                                                                                <th className="p-2 border-collapse border border-slate-400">
                                                                                                    <div className="font-semibold text-center">{t('StockINHand')}</div>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {requisitionItem ? requisitionItem.map((item, index) => {
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"} key={index}>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.itemName ? item.itemName : null} ({item.itemType ? item.itemType : null}-{item.itemUnit ? item.itemUnit : null})</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.category_name ? item.category_name : null}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.last_pr_sub_category ? item.last_pr_sub_category:''}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.stock_non_stock ? item.stock_non_stock:''}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.last_po_unit_price ? item.last_po_unit_price:0}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.last_receive_quantity ? item.last_receive_quantity:0}</td>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{item.stock_in_hand ? item.stock_in_hand:0}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }) : null}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">

                                                        <div className="float-left mb-3">
                                                            {
                                                            ((reqStatus === 'Created' || (requisitionData.status === 1 && requisitionData.approve_status==0 )) && (ROLE_PURCHASE_REQUISITION_APPROVE_ONE || ROLE_SUPER_ADMIN) && requisitionData.total_requisition_item > 0) &&
                                                            <button
                                                                onClick={(e) =>{
                                                                    e.preventDefault();
                                                                    openConfirmModal({
                                                                        title: (t('VerifiedThisRequisition')),
                                                                        centered: true,
                                                                        children: (
                                                                            <Text size="sm">
                                                                                {t('AreYouSureYouVerifiedThisRequisition')}
                                                                            </Text>
                                                                        ),
                                                                        labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                                        confirmProps: { color: 'green' },
                                                                        // cancelProps: { color: 'red' },
                                                                        onCancel: () => console.log(t('Cancel')),
                                                                        onConfirm: () => {
                                                                            axios({
                                                                                method: 'get',
                                                                                url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                                                                headers: {
                                                                                    "Accept": `application/json`,
                                                                                    "Content-Type": `application/json`,
                                                                                    "Access-Control-Allow-Origin": '*',
                                                                                    "Authorization": `Bearer ${token}`
                                                                                },
                                                                                params: {
                                                                                    "requisition_id" : requisitionID,
                                                                                    "status" : reqStatus
                                                                                }
                                                                            })
                                                                                .then(res => {
                                                                                    setTimeout(()=>{
                                                                                        if (res.status === 200){
                                                                                            setReqStatus('Verified')
                                                                                            setReload(true)
                                                                                        }
                                                                                    },1000)

                                                                                })
                                                                                .catch(function (error) {
                                                                                    console.log(error)
                                                                                })
                                                                        }
                                                                        ,
                                                                    });
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-lime-600 text-white-600 hover:bg-lime-700 hover:text-white-800 text-white text-xs">
                                                                Verified
                                                            </button>
                                                            }

                                                            {
                                                                ((reqStatus === 'Verified' || (requisitionData.status === 1 && requisitionData.approve_status==1 )) && (ROLE_PURCHASE_REQUISITION_APPROVE_TWO || ROLE_SUPER_ADMIN) && requisitionData.total_requisition_item > 0) &&
                                                                <button
                                                                    onClick={(e) =>{
                                                                        e.preventDefault();
                                                                        openConfirmModal({
                                                                            title: (t('VerifiedThisRequisition')),
                                                                            centered: true,
                                                                            children: (
                                                                                <Text size="sm">
                                                                                    {t('AreYouSureYouVerifiedThisRequisition')}
                                                                                </Text>
                                                                            ),
                                                                            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                                            confirmProps: { color: 'green' },
                                                                            // cancelProps: { color: 'red' },
                                                                            onCancel: () => console.log(t('Cancel')),
                                                                            onConfirm: () => {
                                                                                axios({
                                                                                    method: 'get',
                                                                                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                                                                    headers: {
                                                                                        "Accept": `application/json`,
                                                                                        "Content-Type": `application/json`,
                                                                                        "Access-Control-Allow-Origin": '*',
                                                                                        "Authorization": `Bearer ${token}`
                                                                                    },
                                                                                    params: {
                                                                                        "requisition_id" : requisitionID,
                                                                                        "status" : reqStatus
                                                                                    }
                                                                                })
                                                                                    .then(res => {
                                                                                        setTimeout(()=>{
                                                                                            if (res.status === 200){
                                                                                                setReqStatus('Validated')
                                                                                                setReload(true)
                                                                                            }
                                                                                        },1000)

                                                                                    })
                                                                                    .catch(function (error) {
                                                                                        console.log(error)
                                                                                    })
                                                                            }
                                                                            ,
                                                                        });
                                                                    }}
                                                                    className="inline-flex items-center px-6 py-2 bg-lime-600 text-white-600 hover:bg-lime-700 hover:text-white-800 text-white text-xs">
                                                                    Validated
                                                                </button>
                                                            }

                                                            {
                                                                ((reqStatus === 'Validated' || (requisitionData.status === 1 && requisitionData.approve_status==2 )) && (ROLE_PURCHASE_REQUISITION_APPROVE_THREE || ROLE_SUPER_ADMIN) && requisitionData.total_requisition_item > 0) &&
                                                                <button
                                                                    onClick={(e) =>{
                                                                        e.preventDefault();
                                                                        openConfirmModal({
                                                                            title: (t('VerifiedThisRequisition')),
                                                                            centered: true,
                                                                            children: (
                                                                                <Text size="sm">
                                                                                    {t('AreYouSureYouVerifiedThisRequisition')}
                                                                                </Text>
                                                                            ),
                                                                            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                                                            confirmProps: { color: 'green' },
                                                                            // cancelProps: { color: 'red' },
                                                                            onCancel: () => console.log(t('Cancel')),
                                                                            onConfirm: () => {
                                                                                axios({
                                                                                    method: 'get',
                                                                                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                                                                    headers: {
                                                                                        "Accept": `application/json`,
                                                                                        "Content-Type": `application/json`,
                                                                                        "Access-Control-Allow-Origin": '*',
                                                                                        "Authorization": `Bearer ${token}`
                                                                                    },
                                                                                    params: {
                                                                                        "requisition_id" : requisitionID,
                                                                                        "status" : reqStatus
                                                                                    }
                                                                                })
                                                                                    .then(res => {
                                                                                        setTimeout(()=>{
                                                                                            if (res.status === 200){
                                                                                                setReqStatus('')
                                                                                                setReload(true)
                                                                                            }
                                                                                        },1000)

                                                                                    })
                                                                                    .catch(function (error) {
                                                                                        console.log(error)
                                                                                    })
                                                                            }
                                                                            ,
                                                                        });
                                                                    }}
                                                                    className="inline-flex items-center px-6 py-2 bg-lime-600 text-white-600 hover:bg-lime-700 hover:text-white-800 text-white text-xs">
                                                                    Approved
                                                                </button>
                                                            }

                                                        </div>

            <div className="float-right mb-3">
                {
                    (reqStatus ==='Created' || reqStatus ==='Verified' || reqStatus ==='Validated' || (requisitionData.status === 1 && (requisitionData.approve_status==0 || requisitionData.approve_status==1 || requisitionData.approve_status==2  ))) &&
                        <>
                    <button
                        onClick={(e) =>{
                            e.preventDefault();
                            openConfirmModal({
                                title: (t('InactiveThisRequisition')),
                                centered: true,
                                children: (
                                    <Text size="sm">
                                        {t('AreYouSureYouInactiveThisRequisition')}
                                    </Text>
                                ),
                                labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                confirmProps: { color: 'green' },
                                // cancelProps: { color: 'red' },
                                onCancel: () => console.log(t('Cancel')),
                                onConfirm: () => {
                                    setLoading(true)
                                    axios({
                                        method: 'get',
                                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                        headers: {
                                            "Accept": `application/json`,
                                            "Content-Type": `application/json`,
                                            "Access-Control-Allow-Origin": '*',
                                            "Authorization": `Bearer ${token}`
                                        },
                                        params: {
                                            "requisition_id" : requisitionID,
                                            "status" : 'Inactive'
                                        }
                                    })
                                        .then(res => {
                                            setTimeout(()=>{
                                                if (res.status === 200){
                                                    setReqStatus('')
                                                    setReload(true)
                                                }
                                            },1000)

                                        })
                                        .catch(function (error) {
                                            console.log(error)
                                        })
                                }
                                ,
                            });
                        }}
                        className="inline-flex items-center px-6 py-2 bg-rose-600 text-white-600 hover:bg-rose-700 hover:text-white-800 text-white text-xs">
                        Inactive
                    </button>

                            {
                                ROLE_PURCHASE_REQUISITION_CANCEL &&
                                <button
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        openConfirmModal({
                                            title: (t('CancelThisRequisition')),
                                            centered: true,
                                            children: (
                                                <Text size="sm">
                                                    {t('AreYouSureYouCancelThisRequisition')}
                                                </Text>
                                            ),
                                            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                            confirmProps: { color: 'green' },
                                            // cancelProps: { color: 'red' },
                                            onCancel: () => console.log(t('Cancel')),
                                            onConfirm: () => {
                                                setLoading(true)
                                                axios({
                                                    method: 'get',
                                                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                                    headers: {
                                                        "Accept": `application/json`,
                                                        "Content-Type": `application/json`,
                                                        "Access-Control-Allow-Origin": '*',
                                                        "Authorization": `Bearer ${token}`
                                                    },
                                                    params: {
                                                        "requisition_id" : requisitionID,
                                                        "status" : 'Cancel'
                                                    }
                                                })
                                                    .then(res => {
                                                        setTimeout(()=>{
                                                            if (res.status === 200){
                                                                setReqStatus('')
                                                                setReload(true)
                                                            }
                                                        },1000)

                                                    })
                                                    .catch(function (error) {
                                                        console.log(error)
                                                    })
                                            }
                                            ,
                                        });
                                    }}
                                    className="inline-flex items-center px-6 py-2 bg-rose-600 hover:bg-rose-700 text-white text-xs ml-2"
                                >
                                    Cancel
                                </button>
                            }

                    </>
                }
                {(reqStatus ==='Hold' || requisitionData.status === 5) &&
                    <>
                    <button
                        onClick={(e) =>{
                            e.preventDefault();
                            openConfirmModal({
                                title: (t('ActiveThisRequisition')),
                                centered: true,
                                children: (
                                    <Text size="sm">
                                        {t('AreYouSureYouActiveThisRequisition')}
                                    </Text>
                                ),
                                labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                confirmProps: { color: 'green' },
                                // cancelProps: { color: 'red' },
                                onCancel: () => console.log(t('Cancel')),
                                onConfirm: () => {
                                    setLoading(true)
                                    axios({
                                        method: 'get',
                                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                        headers: {
                                            "Accept": `application/json`,
                                            "Content-Type": `application/json`,
                                            "Access-Control-Allow-Origin": '*',
                                            "Authorization": `Bearer ${token}`
                                        },
                                        params: {
                                            "requisition_id" : requisitionID,
                                            "status" : 'Active'
                                        }
                                    })
                                        .then(res => {
                                            setTimeout(()=>{
                                                if (res.status === 200){
                                                    setReqStatus('')
                                                    setReload(true)
                                                }
                                            },1000)

                                        })
                                        .catch(function (error) {
                                            console.log(error)
                                        })
                                }
                                ,
                            });
                        }}
                        className="inline-flex items-center px-6 py-2 bg-rose-600 text-white-600 hover:bg-rose-700 hover:text-white-800 text-white text-xs">
                        Active
                    </button>

                        {
                            ROLE_PURCHASE_REQUISITION_CANCEL &&
                            <button
                                onClick={(e) =>{
                                    e.preventDefault();
                                    openConfirmModal({
                                        title: (t('CancelThisRequisition')),
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                {t('AreYouSureYouCancelThisRequisition')}
                                            </Text>
                                        ),
                                        labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
                                        confirmProps: { color: 'green' },
                                        // cancelProps: { color: 'red' },
                                        onCancel: () => console.log(t('Cancel')),
                                        onConfirm: () => {
                                            setLoading(true)
                                            axios({
                                                method: 'get',
                                                url: `${process.env.REACT_APP_API_GATEWAY_URL}/purchase-requisition/approve`,
                                                headers: {
                                                    "Accept": `application/json`,
                                                    "Content-Type": `application/json`,
                                                    "Access-Control-Allow-Origin": '*',
                                                    "Authorization": `Bearer ${token}`
                                                },
                                                params: {
                                                    "requisition_id" : requisitionID,
                                                    "status" : 'Cancel'
                                                }
                                            })
                                                .then(res => {
                                                    setTimeout(()=>{
                                                        if (res.status === 200){
                                                            setReqStatus('')
                                                            setReload(true)
                                                        }
                                                    },1000)

                                                })
                                                .catch(function (error) {
                                                    console.log(error)
                                                })
                                        }
                                        ,
                                    });
                                }}
                                className="inline-flex items-center px-6 py-2 bg-rose-600 hover:bg-rose-700 text-white text-xs ml-2"
                            >
                                Cancel
                            </button>

                        }


                    </>
                }

            </div>
                                                    </div>
                                                </div>
                                            </footer>

                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default RequisitionView