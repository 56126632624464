import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { LoadingOverlay, ScrollArea, TextInput} from "@mantine/core";
import {
    HiOutlineX
} from "react-icons/hi";
import {
    getPurchaseOrderDetails,

     setAdvancePaymentModel, setAdvancePaymentModelId, poAdvancePaymentCreate
} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {FiSave} from "react-icons/fi";
import jwt_decode from "jwt-decode";
import {showNotification} from "@mantine/notifications";


function AdvancePaymentModel() {


    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [paymenting, setPaymenting] = useState(true);
    const [advance, setAdvance] = useState('');

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);
    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })


    const purchaseOrderDetails = useSelector((state) => state.purchaseOrderReducer.purchaseOrderDetails)
    const advancePaymentModelId = useSelector((state) => state.purchaseOrderReducer.advancePaymentModelId)

    useEffect((e) => {
        setTimeout(() => {
            dispatch(
                getPurchaseOrderDetails({
                    po_id: advancePaymentModelId
                })
            )
            setPaymenting(false)
            setAdvance('')
        }, 500)
    }, [paymenting])

    let totalAdvance = 0;


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('AdvancePayment')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setAdvancePaymentModel(false))
                                        dispatch(setAdvancePaymentModelId(null))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={paymenting}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">


            <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                    <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                        <div className="mt-4">
                            <div className="flex items-center">
                                <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AdvancePaymentInformation')}</h4>
                                <div className="flex-1 border-t-2 border-gray-200"/>
                            </div>
                            {
                                (purchaseOrderDetails && purchaseOrderDetails['advance_payment'] && purchaseOrderDetails['advance_payment'].length > 0) &&
                                <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs">
                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                    <tr>
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">{t('SL')}</div>
                                        </th>
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">{t('Date')}</div>
                                        </th>
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">{t('Amount')}</div>
                                        </th>
                                        <th className="p-2 border-collapse border border-slate-400">
                                            <div className="font-semibold text-center">Payment Number</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (purchaseOrderDetails && purchaseOrderDetails['advance_payment'] && purchaseOrderDetails['advance_payment'].length > 0) &&
                                        purchaseOrderDetails['advance_payment'].map((pay, index) => {
                                            totalAdvance = totalAdvance+pay.amount
                                            return (
                                                <tr className={"bg-gray-100"}
                                                    key={index}>
                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{pay.created_date ? pay.created_date : ''}</td>
                                                    <td className="w-3/24 border-collapse border border-slate-400 text-right">{pay.amount ? pay.amount : ''}</td>
                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{pay.paymentNumber ? pay.paymentNumber : ''}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr className={"bg-gray-100"} key="9999">
                                        <td className="w-1/24 border-collapse border border-slate-400 text-center" colSpan={2}>Total</td>
                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                            {totalAdvance ? totalAdvance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            }


                            <div className=" w-full items-center">
                                <label htmlFor="remark"
                                       className="form-input-sm-label">{t('AdvancePayment')}</label>
                                <div
                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                    <TextInput
                                        placeholder={t('AdvancePayment')}
                                        value={advance}
                                        onChange={(e) => {
                                            setAdvance(e.target.value)
                                        }}
                                    />

                                    <button
                                        type='submit'
                                        className="mt-5 inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0"
                                        onClick={(e) => {
                                            let inTotalAdvance = parseInt(advance)+parseInt(totalAdvance)
                                            if (purchaseOrderDetails.net_total>=inTotalAdvance){
                                                const data = {
                                                    po_id: advancePaymentModelId,
                                                    advance_payment: advance
                                                }
                                                dispatch(poAdvancePaymentCreate(data))
                                                setPaymenting(true)
                                                setAdvance('')
                                            }else {
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: (t('AdvancePaymentExistsPoAmount')),
                                                    message: (t('DataLoad')),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'red',
                                                });
                                            }

                                        }}
                                    >
                                        <FiSave size={16} className={'mr-2'}/>
                                        <span>{t('Submit')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>


                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-left mb-3">
                                                        </div>
                                                        <div className="float-right mb-3">
                                                            <button
                                                                onClick={(e) => {
                                                                    dispatch(setAdvancePaymentModel(false))
                                                                    dispatch(setAdvancePaymentModelId(null))
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-red-700 hover:bg-red-900 text-white text-xs ml-2"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

        </>
    )
}

export default AdvancePaymentModel