import React, {useEffect} from "react";
import {
    HiCloudDownload,
    HiEye,
    HiOutlineExclamationCircle,
    HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus, HiOutlineSearch,
    HiPencil, HiTrash
} from "react-icons/hi";
import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActionIcon, Box, Drawer, Grid, Group, ScrollArea, Select, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {showNotification} from "@mantine/notifications";
import axios from "axios";
import {format} from "date-fns";
import {DatePicker} from "@mantine/dates";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {budgetDelete, setBudgetFetching, setBudgetModelView, setBudgetViewID} from "../../../store/budget/budgetSlice";
import {useDispatch, useSelector} from "react-redux";
import BudgetViewModel from "./BudgetViewModel";
import {openConfirmModal} from "@mantine/modals";

function BudgetList(){
    const {t, i18n} = useTranslation();

    const [activeTab,setActiveTab] = useState('all');
    const [budgets, setBudget] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);
    const loginUserId = decoded.id

    const budgetModelView = useSelector((state) => state.budgetReducer.budgetModelView)
    const budgetFetching = useSelector((state) => state.budgetReducer.fetching)

    const ROLE_BUDGET_ADD = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_ADD";
    })
    const ROLE_BUDGET_EDIT = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_EDIT";
    })
    const ROLE_BUDGET_VIEW = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_VIEW";
    })
    const ROLE_BUDGET_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_ONE";
    })
    const ROLE_BUDGET_APPROVE_TWO = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_TWO";
    })
    const ROLE_BUDGET_APPROVE_THREE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_APPROVE_THREE";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })
    const ROLE_BUDGET_DELETE = decoded.roles.find(role => {
        return role === "ROLE_BUDGET_DELETE";
    })

    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'created_date', direction: 'DESC' });
    const [fetching, setFetching] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [opened, setOpened] = useState(false);


    // FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('budget_page') ? localStorage.getItem('budget_page') : 1);
    let tableHeight = localStorage.getItem('fullTableHeight')
    let drawerHeight = localStorage.getItem('drawerHeight')

    // start get project dropdown
    const [projectData, setProjectData] = useState(null);
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'label': type.projects_name, 'value': type.id
            })
        })

    // on change project data get
    const ProjectDataHandel = (e) => {
        setProjectData(e);
    }
    // End get project dropdown

    const [startDateData, setStartDate] = useState(null);
    const [endDateData, setEndDate] = useState(null);
    // REQUIRED DATE HANDEL
    const StartDateHandel = (e) => {
        setStartDate(e);
    }

    const EndDateHandel = (e) => {
        setEndDate(e);
    }

    const [budgetNumberData, setBudgetNumber] = useState(null);
    const BudgetNumberHandel = (e) => {
        setBudgetNumber(e.target.value);
    }

    const [budgetAmountData, setBudgetAmount] = useState(null);
    const BudgetAmountHandel = (e) => {
        setBudgetAmount(e.target.value);
    }

    // start order by dropdown
    let orderByDropdown =
        [
            {'label': 'ASC', 'value': 'ASC'},
            {'label': 'DESC', 'value': 'DESC'}
        ];

    const OrderByDataHandel = (e) => {
        setSortStatus({...sortStatus,['direction']:e})
    }
    // end order by dropdown

    // start order type dropdown
    let orderTypeDropdown =
        [
            {'label': 'Created Date', 'value': 'created_date'},
            {'label': 'Projects Name', 'value': 'projects_name'},
            {'label': 'Budget Number', 'value': 'budget_generate_id'},
            {'label': 'Budget Amount', 'value': 'net_total'},
            {'label': 'Start Date', 'value': 'budget_start_date'},
            {'label': 'End Date', 'value': 'budget_end_date'}
        ];

    const OrderTypeDataHandel = (e) => {
        setSortStatus({...sortStatus,['columnAccessor']:e})
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/list/${activeTab}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "offset": PAGE_SIZE,
                "page" : page,
                "budget_generate_id" : budgetNumberData && budgetNumberData!= null ?budgetNumberData:'',
                "net_total" : budgetAmountData && budgetAmountData!= null ?budgetAmountData:'',
                "project" : projectData && projectData!= null ?projectData:'',
                "budget_start_date" : startDateData && startDateData!=null?format(startDateData,'yyyy-MM-dd'):'',
                "budget_end_date" : endDateData && endDateData!=null?format(endDateData,'yyyy-MM-dd'):'',
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
            }
        })
            .then(res => {
                setTimeout(()=>{
                    setBudget(res.data.data);
                    setTotalItem(res.data.total);
                    localStorage.setItem('budget_page', page);
                    setFetching(false)
                    setRefresh(false)
                    dispatch(setBudgetFetching(false))
                },500)

            })
            .catch(function (error) {
                console.log(error)
            })

    }, [page,activeTab,sortStatus,refresh,budgetFetching]);
    // FOR DATA TABLE DATA SHOW END

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    const ChangeRequisitionTab = (e,value) => {
        e.preventDefault();
        setActiveTab(value);
        setPage(1);
        setFetching(true)
    }

    const CreateBudget = (e) => {
        const formData = {
            status: 0
        };
        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/budget/create`,
                formData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                        if (res.data.status === 201) {
                            const last_id = res.data.last_id;
                            navigate('/budget/edit/'+last_id);
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: res.data.message,
                                autoClose: 500,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    }
                );
        }
    }

    const BudgetEdit = (e,id) => {
        navigate('/budget/edit/'+id);
    }

    //Form validation
    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
        setFetching(true)
    }

    const budgetApprove = (e, id,status) => {
        let message = ''
        let messageDetails = ''
        if ( status=== 'Created'){
            message = 'Verify This Budget ?'
            messageDetails = ' Are you sure , you want to verify this budget? Press confirm , if you want to verify otherwise cancel.'
        }
        if ( status=== 'Verified'){
            message = 'Validate This Budget ?'
            messageDetails = ' Are you sure , you want to validate this budget? Press confirm , if you want to validate otherwise cancel.'
        }
        if ( status=== 'Validated'){
            message = 'Approve This Budget ?'
            messageDetails = ' Are you sure , you want to approve this budget? Press confirm , if you want to approve otherwise cancel.'
        }
        e.preventDefault();
        openConfirmModal({
            title: message,
            centered: true,
            children: (
                <Text size="sm">
                    {messageDetails}
                </Text>
            ),
            labels: { confirm: (t('Confirm')), cancel: (t("Cancel")) },
            confirmProps: { color: 'green' },
            // cancelProps: { color: 'red' },
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/budget/approve`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },

                    params: {
                        "budget_id" : id,
                        "status" : status
                    }
                })
                    .then(res => {
                        setTimeout(()=>{
                            if (res.status === 200){
                                setRefresh(true);
                            }
                        },500)

                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }

    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('Budget')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllBudgetRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={() => setOpened(true)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                                            </button>
                                                            {
                                                                (ROLE_BUDGET_ADD || ROLE_SUPER_ADMIN) &&
                                                                <button
                                                                    onClick={event => CreateBudget(event)}
                                                                    className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddNew')}
                                                                </button>
                                                            }

                                                            <Link to='/budget'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'all')}>
                                                                        {t('All')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'created'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'created')}>
                                                                        {t('Created')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'verified'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'verified')}>
                                                                        {t('Verified')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'validated'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'validated')}>
                                                                        {t('Validated')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'approved'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'approved')}>
                                                                        {t('Approved')}
                                                                    </button>

                                                                    <button className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'draft'?'bg-indigo-600':'bg-indigo-400'} hover:bg-indigo-600 text-white text-xs `} onClick={(e) => ChangeRequisitionTab(e,'draft')}>
                                                                        {t('Draft')}
                                                                    </button>
                                                                </div>

                                                                <Drawer
                                                                    opened={opened}
                                                                    onClose={() => setOpened(false)}
                                                                    title= {t('BudgetFilter')}
                                                                    padding="xl"
                                                                    size="xl"
                                                                    overlayColor={"black"}
                                                                    overlayOpacity={0.55}
                                                                    overlayBlur={3}
                                                                    position={"right"}
                                                                    transitionDuration={250}
                                                                    transitionTimingFunction="ease"
                                                                >
                                                                    <form onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{ height: drawerHeight }}>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="SelectProject" className="form-input-sm-label">{t('ChooseProject')}</label>
                                                                                <Select
                                                                                    {...register("projects")}
                                                                                    placeholder={t("ChooseProjectName")}
                                                                                    searchable clearable
                                                                                    allowDeselect
                                                                                    nothingFound="No options"
                                                                                    withAsterisk
                                                                                    data={projectDropdown}
                                                                                    transition="pop-top-left"
                                                                                    transitionDuration={80}
                                                                                    transitionTimingFunction="ease"
                                                                                    onChange={ProjectDataHandel}
                                                                                    value={projectData}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="Keyword" className="form-input-sm-label">{t('BudgetNumber')}</label>
                                                                                <TextInput
                                                                                    onChange={BudgetNumberHandel}
                                                                                    placeholder={t("EnterBudgetNumber")}
                                                                                    value={budgetNumberData?budgetNumberData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="Keyword" className="form-input-sm-label">{t('BudgetAmount')}</label>
                                                                                <TextInput
                                                                                    onChange={BudgetAmountHandel}
                                                                                    placeholder={t("EnterBudgetAmount")}
                                                                                    value={budgetAmountData?budgetAmountData:''}
                                                                                    withAsterisk
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="startDate" className="form-input-sm-label">{t('StartDate')}</label>

                                                                                <DatePicker
                                                                                    {...register("start_date")}
                                                                                    dropdownType="modal"
                                                                                    placeholder="Pick date"
                                                                                    onChange={StartDateHandel}
                                                                                    value={startDateData}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-5">
                                                                                <label htmlFor="endDate" className="form-input-sm-label">{t('EndDate')}</label>
                                                                                <DatePicker
                                                                                    {...register("end_date")}
                                                                                    dropdownType="modal"
                                                                                    placeholder="Pick date"
                                                                                    onChange={EndDateHandel}
                                                                                    value={endDateData}
                                                                                />
                                                                            </div>
                                                                        </ScrollArea>



                                                                        <div className="mb-2 mt-5 text-right">

                                                                            <Grid>
                                                                                <Grid.Col span={6}>
                                                                                    <Select
                                                                                        {...register("order_type")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderTypeDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderTypeDataHandel}
                                                                                        value={sortStatus.columnAccessor}
                                                                                    />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <Select
                                                                                        {...register("order_by")}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        withAsterisk
                                                                                        data={orderByDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={OrderByDataHandel}
                                                                                        value={sortStatus.direction}
                                                                                    />
                                                                                </Grid.Col>
                                                                                <Grid.Col span={3}>
                                                                                    <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                        {spinner ? (
                                                                                            <HiOutlineSearch
                                                                                                className={'mr-2 animate-spin'}
                                                                                                size={16}></HiOutlineSearch>
                                                                                        ) : (<HiOutlineFilter size={12} className={'mr-2'}/>)}
                                                                                        <span>{t('Filter')}</span>
                                                                                    </button>
                                                                                </Grid.Col>
                                                                            </Grid>
                                                                        </div>
                                                                    </form>
                                                                </Drawer>

                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={budgets}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                textAlignment: 'right',
                                                                                // render: (item) => (budgets.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                                render: (item) => (
                                                                                    <span style={{ color: item.is_old == 1 ? 'red' : 'initial' }}>
                                                                                        {budgets.indexOf(item) + 1 + PAGE_SIZE * (page - 1)}
                                                                                    </span>
                                                                                )
                                                                            },
                                                                            {
                                                                                accessor: 'projects_name',
                                                                                title: 'Project Name',
                                                                                sortable: true
                                                                            },
                                                                            {
                                                                                accessor: 'budget_generate_id',
                                                                                title: 'Budget Number',
                                                                                sortable: true,
                                                                                textAlignment: "center"
                                                                            },
                                                                            {
                                                                                accessor: 'net_total',
                                                                                title: 'Budget Amount',
                                                                                sortable: true,
                                                                                textAlignment: 'right'
                                                                            },
                                                                            {
                                                                                accessor: 'budget_start_date',
                                                                                title: 'Start Date',
                                                                                sortable: true,
                                                                                textAlignment: "center"
                                                                            },
                                                                            {
                                                                                accessor: 'budget_end_date',
                                                                                title: 'End Date',
                                                                                sortable: true,
                                                                                textAlignment: "center"
                                                                            },
                                                                            {
                                                                                accessor: 'path',
                                                                                title: 'Attachment',
                                                                                textAlignment: "center",
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.path &&
        <a title="Budget Attachment"
           target="_blank"
           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/budget/pdf-show/'+ item.id}`}
           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
            <HiCloudDownload/>
        </a>
                                                                                        }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'status',
                                                                                title: 'Status',
                                                                                textAlignment: 'center',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.status === 'Verified' && <span className="items-center px-2 py-1 text-black text-xs bg-blue-300 block text-center">Verified</span>}
                                                                                        {item.status === 'Created' && <span className="items-center px-2 py-1 text-black text-xs bg-green-300 block text-center">Created</span>}
                                                                                        {item.status === 'Validated' && <span className="items-center px-2 py-1 text-black text-xs bg-yellow-300 block text-center">Validated</span>}
                                                                                        {item.status === 'Approved' && <span className="items-center px-2 py-1 text-black text-xs bg-lime-300 block text-center">Approved</span>}
                                                                                        {item.status === 'Draft' && <span className="items-center px-2 py-1 text-black text-xs bg-rose-300 block text-center">Draft</span>}
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'approved',
                                                                                title: <Text mr="xs">{t('Approved')}</Text>,
                                                                                textAlignment: 'center',
                                                                                hidden : !ROLE_BUDGET_APPROVE_ONE || !ROLE_BUDGET_APPROVE_TWO || ! ROLE_BUDGET_APPROVE_THREE || activeTab === 'draft' || activeTab=='approved',

                                                                                render: (item) =>
                                                                                    <>
                                                                                        {
                                                                                            (item.status === 'Created' && ROLE_BUDGET_APPROVE_ONE) &&
                                                                                            <button
                                                                                                // onClick={(e) => budgetApprove(e, item.id,item.status)}
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setBudgetModelView(true))
                                                                                                    dispatch(setBudgetViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-indigo-600 hover:bg-indigo-700 text-white text-xs">
                                                                                                Verified

                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.status === 'Verified' && ROLE_BUDGET_APPROVE_TWO) &&
                                                                                            <button
                                                                                                // onClick={(e) => budgetApprove(e, item.id,item.status)}
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setBudgetModelView(true))
                                                                                                    dispatch(setBudgetViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                                Validated
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.status === 'Validated' && ROLE_BUDGET_APPROVE_THREE) &&
                                                                                            <button
                                                                                                // onClick={(e) => budgetApprove(e, item.id,item.status)}
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setBudgetModelView(true))
                                                                                                    dispatch(setBudgetViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-lime-600 hover:bg-lime-700 text-white text-xs">
                                                                                                Approved
                                                                                            </button>
                                                                                        }
                                                                                    </>,
                                                                            },

                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'right',
                                                                                hidden : !ROLE_BUDGET_EDIT && !ROLE_SUPER_ADMIN && !ROLE_BUDGET_ADD,
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right" noWrap>

                                                                                        {
                                                                                            ((ROLE_BUDGET_EDIT || ROLE_SUPER_ADMIN) && item.status === 'Approved') &&
                                                                                            <ActionIcon color="green" onClick={(e) => BudgetEdit(e, item.id)}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        {
                                                                                            ((item.status === 'Draft' || item.status === 'Created') && ( ROLE_SUPER_ADMIN || ROLE_BUDGET_ADD)) &&
                                                                                            <ActionIcon color="green" onClick={(e) => BudgetEdit(e, item.id)}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        {
                                                                                            (ROLE_BUDGET_ADD || ROLE_SUPER_ADMIN || ROLE_BUDGET_EDIT) &&
                                                                                            <ActionIcon color="blue" onClick={(e) => {
                                                                                                dispatch(setBudgetModelView(true))
                                                                                                dispatch(setBudgetViewID(item.id))
                                                                                            }}>
                                                                                                <HiEye size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        {
                                                                                            (item.status == 'Draft' && (ROLE_SUPER_ADMIN || ROLE_BUDGET_ADD)) &&
                                                                                            <ActionIcon color="red">
                                                                                                <HiTrash size={16} onClick={(e) =>{
                                                                                                    openConfirmModal({
                                                                                                        title: t('BudgetDelete'),
                                                                                                        centered: true,
                                                                                                        children: (
                                                                                                            <Text size="sm">
                                                                                                                {t('AreYouSureToBudgetDelete')}
                                                                                                            </Text>
                                                                                                        ),
                                                                                                        labels: {confirm: (t('Confirm')), cancel: (t("Cancel"))},
                                                                                                        confirmProps: {color: 'red'},
                                                                                                        onCancel: () => console.log(t('Cancel')),
                                                                                                        onConfirm: () => {
                                                                                                            dispatch(budgetDelete(item.id))
                                                                                                            setRefresh(true)
                                                                                                        }
                                                                                                        ,
                                                                                                    });
                                                                                                }}/>
                                                                                            </ActionIcon>
                                                                                        }
                                                                                    </Group>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            setPage(p)
                                                                            setFetching(true)
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={setSortStatus}                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {budgetModelView?<BudgetViewModel />:''}
        </>
    )
}
export default BudgetList