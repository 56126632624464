import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {purchaseTypeDropdownService} from "../../services/PurchaseTypeService";


export const purchaseTypeDropdown = createAsyncThunk("purchase-type/dropdown", async () => {
    try {
        const response = purchaseTypeDropdownService();
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

const purchaseTypeSlice = createSlice({
    name : "purchase-type",
    initialState : {
        isLoading : true,
        message : null,
        purchaseTypeDropdown : [],
    },
    extraReducers : (builder) => {
        builder.addCase(purchaseTypeDropdown.fulfilled, (state, action) => {
            state.purchaseTypeDropdown = action.payload
            state.isLoading = false
            state.message = 'purchaseTypeDropdownLoad'
        })
    }
})

export default purchaseTypeSlice.reducer;