import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createPaymentPayFrom,
    createPaymentVerifyFrom, getPaymentBankCheque,
    getPaymentRequestList, getPayVerifyDataForCsvDownload, paymentConfirmationCSVupload,
    paymentConfirmationForArchive,
    paymentSendBack, postPaymentConfirmAttachment, postPaymentUpdate,
    storePaymentVerify,
    updatePaymentInline
} from "../../services/PaymentService";

export const PaymentRequestList = createAsyncThunk("payment/list", async (param) => {
    try {
        const response = getPaymentRequestList(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const PaymentVerifyFrom = createAsyncThunk("payment/create/verify-from", async (data) => {
    try {
        const response = createPaymentVerifyFrom(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const StorePaymentVerify = createAsyncThunk("payment/store/payment-verify", async (data) => {
    try {
        const response = storePaymentVerify(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const vendorWiseConfirm = createAsyncThunk("payment/vendor-wise-confirm", async (data) => {
    try {
        const response = createPaymentPayFrom(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});
export const paymentInlineUpdate = createAsyncThunk("payment/inline-update", async (data) => {
    try {
        const response = updatePaymentInline(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const paymentConfirmation = createAsyncThunk("payment/confirmation", async (data) => {
    try {
        const response = paymentConfirmationForArchive(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const sendBackProcess = createAsyncThunk("payment/send-back", async (data) => {
    try {
        const response = paymentSendBack(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


export const paymentBankCheque = createAsyncThunk("payment/get/bank-cheque", async (param) => {
    try {
        const response = getPaymentBankCheque(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const paymentUpdate = createAsyncThunk("payment/update", async (data) => {
    try {
        const response = postPaymentUpdate(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const paymentConfirmAttach = createAsyncThunk("payment/Confirm-attachment", async (data) => {
    try {
        const response = postPaymentConfirmAttachment(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const verifyDataCsvDownload = createAsyncThunk("payment/verify-data-csv-download", async (param) => {
    try {
        const response = getPayVerifyDataForCsvDownload(param);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});

export const paymentConfirmationCSVuploadPost = createAsyncThunk("payment/confirmation-csv-upload", async (data) => {
    try {
        const response = paymentConfirmationCSVupload(data);
        return response;
    } catch (error) {
        console.log('error', error.message);
        throw error;
    }
});


const paymentSlice = createSlice({
    name : "Payment",
    initialState : {
        loading : false,
        totalItem : 0,
        fetching : true,
        page : localStorage.getItem('payment_page') ? localStorage.getItem('payment_page') : 1,
        activeTab : 'payment-request',
        sortStatus : { columnAccessor: 'payment_request_date', direction: 'DESC' },
        opened : false,
        refresh : false,
        filterData : {po_id:'',po:'',vendor:'',buyer:'',start_date:'',end_date:''},
        paymentRequestData : [],
        paymentVerifyData : [],
        paymentArchiveData : [],
        readyIdChecked : [],
        paymentIdChecked : [],
        paymentVerifyModel : false,
        paymentPayModel : false,
        paymentVerifyFromData : [],
        verifyFromSubmit : false,
        vendorWiseConfirmData : [],
        updateMessage : '',
        relatedBank : '',
        paymentConfirmMessage : '',
        sendBackRFPID : '',
        sendBackPOID : '',
        sendBackModel : false,
        sendBackMessage : '',
        paymentViewModel : false,
        paymentViewID : '',
        bankCheque : [],
        paymentUpdateModel : false,
        paymentUpdateID : '',
        paymentUpdateMessage : '',
        paymentConfirmAttachMessage : '',
        paymentVerifyTopSheetModel : false,
        LocalPayVerifyCsvData : [],
        HeadPayVerifyCsvData : [],
        paymentConfirmationCSVuploadmessage : null
    },
    reducers : {
        setProjectData : (state,action) => {
            state.projectData = action.payload
        },
        setLoading : (state,action) => {
            state.loading = action.payload
        },
        setPage : (state,action) => {
            state.page = action.payload
        },
        setActiveTab : (state,action) => {
            state.activeTab = action.payload
        },
        setSortStatus : (state,action) => {
            state.sortStatus.columnAccessor = action.payload.columnAccessor;
            state.sortStatus.direction = action.payload.direction;
        },
        setOpened : (state,action) => {
            state.opened = action.payload;
        },
        setFetching : (state,action) => {
            state.fetching = action.payload
        },
        setRefresh : (state,action) => {
            state.refresh = action.payload;
        },
        setFilterData : (state,action) => {
            state.filterData.po_id = action.payload.po_id;
            state.filterData.po = action.payload.po;
            state.filterData.vendor = action.payload.vendor;
            state.filterData.buyer = action.payload.buyer;
            state.filterData.start_date = action.payload.start_date;
            state.filterData.end_date = action.payload.end_date;
        },
        setReadyIdChecked : (state,action) => {
            state.readyIdChecked.push(action.payload)
            state.readyIdChecked = [...new Set(state.readyIdChecked)];
        },
        setReadyIdUnChecked : (state,action) => {
            state.readyIdChecked = state.readyIdChecked.filter(e => e !== action.payload)
        },
        setPaymentVerifyModel : (state,action) => {
            state.paymentVerifyModel = action.payload
        },
        setPaymentPayModel : (state,action) => {
            state.paymentPayModel = action.payload
        },
        setVerifyFromSubmit : (state,action) => {
            state.verifyFromSubmit = action.payload
        },
        setPaymentIdChecked : (state,action) => {
            state.paymentIdChecked.push(action.payload)
            state.paymentIdChecked = [...new Set(state.paymentIdChecked)];
        },
        setPaymentIdUnChecked : (state,action) => {
            state.paymentIdChecked = state.paymentIdChecked.filter(e => e !== action.payload)
        },
        setEmptyPaymentIdUnChecked : (state,action) => {
            state.paymentIdChecked = []
            state.readyIdChecked = []
        },
        setRelatedBank : (state,action) => {
            state.relatedBank = action.payload
        },
        setSendBackRFPID : (state,action) => {
            state.sendBackRFPID = action.payload;
        },
        setSendBackPOID : (state,action) => {
            state.sendBackPOID = action.payload;
        },
        setSendBackModel : (state,action) => {
            state.sendBackModel = action.payload
        },
        setSendBackMessage : (state,action) => {
            state.sendBackMessage = action.payload
        },
        setPaymentViewModel : (state,action) => {
            state.paymentViewModel = action.payload
        },
        setPaymentViewID : (state,action) =>{
            state.paymentViewID = action.payload
        },
        setPaymentUpdateModel : (state,action) => {
            state.paymentUpdateModel = action.payload
        },
        setPaymentUpdateID : (state,action) => {
            state.paymentUpdateID = action.payload
        },
        setPaymentUpdateMessage : (state,action) => {
            state.paymentUpdateMessage = action.payload
        },
        setBankCheque : (state,action) => {
            state.bankCheque = action.payload
        },
        setPaymentVerifyTopSheetModel : (state,action) => {
            state.paymentVerifyTopSheetModel = action.payload
        },
        setPaymentConfirmationCSVuploadmessage : (state,action) => {
            state.paymentConfirmationCSVuploadmessage = null
        }
    },

    extraReducers : (builder) => {

        builder.addCase(PaymentRequestList.fulfilled,(state,action)=>{
            state.totalItem = action.payload.total
            state.fetching = false
            state.verifyFromSubmit = false
            if (action.payload.paymentType == 'payment-request'){
                state.paymentRequestData = action.payload.data
            }
            if (action.payload.paymentType == 'payment-verify'){
                state.paymentVerifyData = action.payload.data
            }
            if (action.payload.paymentType == 'payment-archive'){
                state.paymentConfirmMessage = ''
                state.paymentArchiveData = action.payload.data
            }
        })

        builder.addCase(PaymentVerifyFrom.fulfilled,(state,action) => {
            state.paymentVerifyFromData = action.payload
        })

        builder.addCase(StorePaymentVerify.fulfilled,(state,action)=>{
            state.paymentVerifyModel = false
            state.fetching = true
            state.filterData.po_id = '';
            state.filterData.po = '';
            state.verifyFromSubmit = true
        })

        builder.addCase(vendorWiseConfirm.fulfilled,(state,action)=>{
            state.vendorWiseConfirmData = action.payload
        })

        builder.addCase(paymentInlineUpdate.fulfilled,(state,action)=>{
            state.updateMessage = action.payload
        })

        builder.addCase(paymentConfirmation.fulfilled,(state,action)=>{
            state.paymentConfirmMessage = action.payload
            state.filterData.vendor = '';
            state.filterData.buyer = '';
        })

        builder.addCase(sendBackProcess.fulfilled,(state,action)=>{
            state.sendBackMessage = action.payload
        })

        builder.addCase(paymentBankCheque.fulfilled,(state,action)=>{
            state.bankCheque = action.payload
        })

        builder.addCase(paymentUpdate.fulfilled,(state,action)=>{
            state.paymentUpdateMessage = action.payload
        })

        builder.addCase(paymentConfirmAttach.fulfilled,(state,action)=>{
            state.paymentConfirmAttachMessage = action.payload
        })

        builder.addCase(verifyDataCsvDownload.fulfilled,(state,action)=>{
            state.LocalPayVerifyCsvData = action.payload.local
            state.HeadPayVerifyCsvData = action.payload.head
        })

        builder.addCase(paymentConfirmationCSVuploadPost.fulfilled,(state,action)=>{
            state.paymentConfirmationCSVuploadmessage = action.payload
        })

    }
})

export const { setProjectData,setLoading,setPage,setSortStatus,setOpened,setFetching,setRefresh,setFilterData,setActiveTab,setReadyIdChecked,setReadyIdUnChecked,setPaymentVerifyModel,setVerifyFromSubmit,setPaymentIdChecked,setPaymentIdUnChecked,setPaymentPayModel,setRelatedBank,setSendBackModel,setSendBackPOID,setSendBackRFPID,setSendBackMessage,setPaymentViewID,setPaymentViewModel,setPaymentUpdateID,setPaymentUpdateModel,setPaymentUpdateMessage,setBankCheque,setPaymentVerifyTopSheetModel,setPaymentConfirmationCSVuploadmessage,setEmptyPaymentIdUnChecked } = paymentSlice.actions
export default paymentSlice.reducer;