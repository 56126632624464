import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {ScrollArea, Select, TextInput, Textarea, LoadingOverlay, Group, Text, useMantineTheme} from "@mantine/core";
import {
    HiCloudDownload,
    HiOutlineExclamation,
    HiOutlineX
} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {
    invoiceBillDropdown,
    ReceiveDetails,
    receivedVerifyForPayment,
    receivedWiseinvoiceChallanDropdown,
    setFilterData,
    setVerifyData,
    setVerifyID,
    setVerifyMessage,
    setVerifyModelView,
    setFetching,
    setReceiveDetailsData,
    setVerifyRefresh,
    ReceiveInlineUpdate,
    setLoading,
    setGrnRef,
    ReceiveInvoiceChallanInlineUpdate
} from "../../../store/receivedItem/receivedItemSlice";

import {format} from "date-fns";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {setPoViewID, setPoViewModel} from "../../../store/purchaseOrder/purchaseOrderSlice";
import PoViewModel from "../PurchaseOrder/PoViewModel";
import View from "../Requisition/RequisitionView";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";


function VerifyViewModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_VERIFY = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VERIFY";
    })
    const [viewModel, setViewModel] = useState(false);
    const [requisitionID, setRequisitionID] = useState('');

    const validationSchemaStepOne = Yup.object().shape({});
    let formOptions = {resolver: yupResolver(validationSchemaStepOne)};
    // get functions to build form with useForm() hook
    const {handleSubmit, formState, setValue, setError, register} = useForm(formOptions);
    const {errors} = formState;


    const verifyID = useSelector((state) => state.receivedItemReducer.verifyID)
    const receiveDetailsData = useSelector((state) => state.receivedItemReducer.receiveDetailsData)
    const filterData = useSelector((state) => state.receivedItemReducer.filterData)
    const invoiceBillDropdownData = useSelector((state) => state.receivedItemReducer.invoiceBillDropdownData)
    const challanDropdownData = useSelector((state) => state.receivedItemReducer.challanDropdownData)
    const invoiceDP = useSelector((state) => state.receivedItemReducer.invoiceDP)
    const challanDP = useSelector((state) => state.receivedItemReducer.challanDP)
    const verifyData = useSelector((state) => state.receivedItemReducer.verifyData)
    const verifyMessage = useSelector((state) => state.receivedItemReducer.verifyMessage)

    const recViewID = useSelector((state) => state.receivedItemReducer.recViewID)
    const poViewModel = useSelector((state) => state.purchaseOrderReducer.poViewModel)


    useEffect((e) => {
        setTimeout(() => {
            dispatch(ReceiveDetails({
                received_id: verifyID
            }))

            dispatch(receivedWiseinvoiceChallanDropdown({
                received_id: verifyID
            }))
            setLoading(false)

        }, 500)
    }, [loading])

    let totalReceiveAmount = 0;


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('VerifyReceivedItemForPayment')}</h5>
                                <button
                                    onClick={() => {
                                        dispatch(setReceiveDetailsData())
                                        dispatch(setVerifyID(null))
                                        dispatch(setFilterData({
                                            invoice: '',
                                            challan: '',
                                            invoiceBillNo: '',
                                            challanBillNo: ''
                                        }))
                                        dispatch(setVerifyModelView(false))
                                        dispatch(setFetching(true))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">

                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div
                                                                        className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div
                                                                            className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ReceivedItemsInformation')}</h4>
                                                                                    <div
                                                                                        className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    (receiveDetailsData && receiveDetailsData['received_items'] && receiveDetailsData['received_items'].length > 0) &&
                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead
                                                                                            className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Particulars')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PRNo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PRQty')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PRAttach')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PONo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('POQty')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('POAmount')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('POAttach')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('RemainingQTY')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('GRNQty')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('UnitPrice')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SubTotal')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Vat')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Ait')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Mode')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('GRNAmount')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            (receiveDetailsData && receiveDetailsData['received_items'] && receiveDetailsData['received_items'].length > 0) &&
                                                                                            receiveDetailsData['received_items'].map((item, index) => {
                                                                                                let remainQty = item.total_order_quantity - (Number(receiveDetailsData['approve_status'] == 1 ? 0 : (item.quantities ? item.quantities : 0)) + Number((item.total_order_receive_quantity ? item.total_order_receive_quantity : 0)))
                                                                                                totalReceiveAmount = totalReceiveAmount + (item.quantities * item.item_unit_price);
                                                                                                let totalItem = receiveDetailsData['received_items'].length
                                                                                                return (
                                                                                                    <tr className={"bg-gray-100"}
                                                                                                        key={index}>
                                                                                                        <td className="w-1/24 border-collapse border border-slate-400 text-center">{++index}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.items_name ? item.items_name : ''}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center text-blue-600">
                                                                                                            <button
                                                                                                                onClick={() => {
                                                                                                                    setViewModel(true);
                                                                                                                    setRequisitionID(item.purchase_requisitions)
                                                                                                                }}>{item.purchase_requisitions ? item.pr_number : ''}</button>

                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.pr_quantity ? item.pr_quantity : ''}
                                                                                                            {' ' + item.item_unit_name}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.pr_attach ? item.pr_attach : ''}</td>

                                                                                                        {
                                                                                                            (index && index === 1) &&
                                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center text-blue-600"
                                                                                                                rowSpan={totalItem}>
                                                                                                                <button
                                                                                                                    onClick={(e) => {
                                                                                                                        dispatch(setPoViewModel(true))
                                                                                                                        dispatch(setPoViewID(receiveDetailsData.purchase_orders))
                                                                                                                    }}>
                                                                                                                    {receiveDetailsData.purchase_orders ? receiveDetailsData.purchase_orders : ''}

                                                                                                                </button>
                                                                                                            </td>
                                                                                                        }


                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                            {item.total_order_quantity ? item.total_order_quantity : ''}
                                                                                                            {' ' + item.item_unit_name}
                                                                                                        </td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            {Number(item.po_amount ? item.po_amount : '').toFixed(2)}
                                                                                                        </td>
                                                                                                        {
                                                                                                            (index && index === 1) &&
                                                                                                            <td className="w-3/24 border-collapse border border-slate-400 text-center"
                                                                                                                rowSpan={totalItem}>
                                                                                                                <a title="Vendor Quotation Attach"
                                                                                                                   target="new"
                                                                                                                   href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/vendor-quotation/${receiveDetailsData.po_attach ? receiveDetailsData.po_attach : ''}`}
                                                                                                                   className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
                                                                                                                    <HiCloudDownload/>
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        }

                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{remainQty + ' ' + item.item_unit_name}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.quantities + ' ' + item.item_unit_name}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_price).toFixed(2)}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_price*item.quantities).toFixed(2)}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_vat*item.quantities).toFixed(2)}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{Number(item.unit_ait*item.quantities).toFixed(2)}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-center">{item.vat_mode}</td>
                                                                                                        <td className="w-3/24 border-collapse border border-slate-400 text-right">
                                                                                                            {Number(item.quantities * item.item_unit_price).toFixed(2)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <tr>
                                                                                            <td colSpan={5}
                                                                                                className="w-1/24 border-collapse border border-slate-400 text-center font-bold">Total
                                                                                            </td>
                                                                                            <td colSpan={3}
                                                                                                className="w-1/24 border-collapse border border-slate-400 text-right font-bold">
                                                                                                TK. {Number(receiveDetailsData.net_total).toFixed(2)}
                                                                                            </td>
                                                                                            <td className="w-1/24 border-collapse border border-slate-400 text-right font-bold"
                                                                                                colSpan={9}>
                                                                                                TK. {Number(totalReceiveAmount).toFixed(2)}
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ReceivedInvoiceChallanInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    receiveDetailsData &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('SL')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('GRN')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">Vendor/Buyer</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PONo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('PRNo')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Invoice')}</div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">{t('Challan')}</div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            receiveDetailsData && (
                                                                                                <tr className={"bg-gray-100"}
                                                                                                    key={1}>
                                                                                                    <td className="w-1/24 border-collapse border border-slate-400 text-center">{'1'}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.ref_grn ? receiveDetailsData.ref_grn : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.vendors_name ? receiveDetailsData.vendors_name : receiveDetailsData.buyer_user_name}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.purchase_orders ? receiveDetailsData.purchase_orders : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">{receiveDetailsData.pr_number ? receiveDetailsData.pr_number : ''}</td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        <Select
                                                                                                            placeholder='Type bill number'
                                                                                                            searchable
                                                                                                            clearable
                                                                                                            allowDeselect
                                                                                                            nothingFound="No options"
                                                                                                            withAsterisk
                                                                                                            data={invoiceBillDropdownData && invoiceBillDropdownData.length > 1 ? invoiceBillDropdownData : invoiceDP}
                                                                                                            transition="pop-top-left"
                                                                                                            transitionDuration={80}
                                                                                                            transitionTimingFunction="ease"
                                                                                                            onSearchChange={(e) => {
                                                                                                                dispatch(setFilterData({
                                                                                                                    ...filterData,
                                                                                                                    ['invoiceBillNo']: e
                                                                                                                }))
                                                                                                                if (filterData.invoiceBillNo.length > 1) {
                                                                                                                    dispatch(invoiceBillDropdown({
                                                                                                                        keyword: filterData.invoiceBillNo,
                                                                                                                        type: 'invoice'
                                                                                                                    }))
                                                                                                                }
                                                                                                            }}
                                                                                                            onChange={(e) => {
                                                                                                                dispatch(setFilterData({
                                                                                                                    ...filterData,
                                                                                                                    ['invoice']: e
                                                                                                                }))
                                                                                                            }}
                                                                                                            value={Number(filterData.invoice)}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        <Select
                                                                                                            placeholder='Type bill number'
                                                                                                            searchable
                                                                                                            clearable
                                                                                                            allowDeselect
                                                                                                            nothingFound="No options"
                                                                                                            withAsterisk
                                                                                                            data={challanDropdownData && challanDropdownData.length > 1 ? challanDropdownData : challanDP}
                                                                                                            transition="pop-top-left"
                                                                                                            transitionDuration={80}
                                                                                                            transitionTimingFunction="ease"
                                                                                                            onSearchChange={(e) => {
                                                                                                                dispatch(setFilterData({
                                                                                                                    ...filterData,
                                                                                                                    ['challanBillNo']: e
                                                                                                                }))
                                                                                                                if (filterData.challanBillNo.length > 1) {
                                                                                                                    dispatch(invoiceBillDropdown({
                                                                                                                        keyword: filterData.challanBillNo,
                                                                                                                        type: 'challan'
                                                                                                                    }))
                                                                                                                }
                                                                                                            }}
                                                                                                            onChange={(e) => {
                                                                                                                dispatch(setFilterData({
                                                                                                                    ...filterData,
                                                                                                                    ['challan']: e
                                                                                                                }))
                                                                                                            }}
                                                                                                            value={Number(filterData.challan)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('BillDateNumberInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {
                                                                                    receiveDetailsData &&
                                                                                    <table className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <thead className="text-xs font-semibold text-black bg-gray-200">
                                                                                        <tr>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">
                                                                                                    {t('BillDate')}
                                                                                                    {(errors.bill_date && errors.bill_date != null) &&
                                                                                                    <span className="text-rose-900">
                                                                                                         {errors.bill_date?.message}
                                                                                                    </span>
                                                                                                    }
                                                                                                </div>
                                                                                            </th>
                                                                                            <th className="p-2 border-collapse border border-slate-400">
                                                                                                <div className="font-semibold text-center">
                                                                                                    {t('BillNumber')}
                                                                                                    {(errors.bill_number && errors.bill_number != null) &&
                                                                                                    <span className="text-rose-900">
                                                                                                         {errors.bill_number?.message}
                                                                                                    </span>
                                                                                                    }
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            receiveDetailsData && (
                                                                                                <tr className={"bg-gray-100"}
                                                                                                    key={1}>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        <input
                                                                                                            type="date"
                                                                                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                            onBlur={(e) => {
                                                                                                                setError("bill_date", null)
                                                                                                                dispatch(setVerifyData({
                                                                                                                    ...verifyData,
                                                                                                                    ['bill_date']: e.target.value
                                                                                                                }))
                                                                                                            }}
                                                                                                            defaultValue={receiveDetailsData.bill_date ? format(new Date(receiveDetailsData.bill_date), 'yyyy-MM-dd') : null}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                        <TextInput
                                                                                                            placeholder={t('BillNumber')}
                                                                                                            onBlur={(e) => {
                                                                                                                setError("bill_number", null)
                                                                                                                dispatch(setVerifyData({
                                                                                                                    ...verifyData,
                                                                                                                    ['bill_number']: e.target.value
                                                                                                                }))
                                                                                                            }}
                                                                                                            withAsterisk
                                                                                                            defaultValue={receiveDetailsData.bill_number ? receiveDetailsData.bill_number : ''}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>

                                                                                            )
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    (receiveDetailsData && receiveDetailsData.send_back_status == 3) &&
                                                                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                                                                        <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                <div className="mt-4">
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ReplySendBackComments')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>

                                                                                    <table
                                                                                        className="mt-5 table-full w-full border-collapse border border-slate-400 text-xs"
                                                                                        id="my-table">
                                                                                        <tbody>
                                                                                        {
                                                                                            // (receiveDetailsData && receiveDetailsData.send_back_status == 3) && (
                                                                                            <tr className={"bg-gray-100"}
                                                                                                key={1}>
                                                                                                <td className="w-3/24 border-collapse border border-slate-400 text-center">
                                                                                                    <Textarea
                                                                                                        placeholder={t('ReplySendBackComments')}
                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                        autosize
                                                                                                        minRows={1}
                                                                                                        onBlur={(e) => {
                                                                                                            dispatch(setVerifyData({
                                                                                                                ...verifyData,
                                                                                                                ['replaySendBackComment']: e.target.value
                                                                                                            }))
                                                                                                        }}
                                                                                                        required={true}
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>

                                                                                            // )
                                                                                        }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                <div className="relative mx-auto max-w-7xl px-2 lg:px-2 mb-4">
                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                            <div className="mt-4">
                                <div className="flex items-center">
                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('GRNInvoiceChallanInformation')}</h4>
                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                </div>
                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                    <div className="mt-5 md:col-span-5 md:mt-0">
                                        <div className="shadow sm:rounded">
                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                <div className=" w-1/3 items-center">
                                                    <label htmlFor="GRNFileAttach" className="form-input-sm-label">{t('GRNFileAttach')}</label>
                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                        <Dropzone
                                                            onDrop={(file) => {
                                                                const GRNFileAttachData = {
                                                                    field_name : "path",
                                                                    value : file[0],
                                                                    recivedID : receiveDetailsData.id
                                                                }
                                                                dispatch(ReceiveInlineUpdate(GRNFileAttachData))
                                                                setLoading(true)
                                                            }}
                                                            onReject={(files) =>{
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Please select PDF file.',
                                                                    message: (t('DataLoad')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }}
                                                            accept={[MIME_TYPES.pdf]}
                                                            multiple={false}
                                                        >
                                                            <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                                <Dropzone.Accept>
                                                                    <IconUpload
                                                                        stroke={1.5}
                                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Accept>
                                                                <Dropzone.Reject>
                                                                    <IconX
                                                                        stroke={1.5}
                                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Reject>
                                                                <Dropzone.Idle>
                                                                    <IconPhoto  stroke={1.5} />
                                                                </Dropzone.Idle>

                                                                <div>
                                                                    <Text size="sm" inline>
                                                                        {
                                                                            (receiveDetailsData.path && receiveDetailsData.path) ?
                                                                                receiveDetailsData.path :
                                                                                'Drag PDF here or click to select files'
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            </Group>
                                                        </Dropzone>
                                                        {
                                                            (receiveDetailsData && receiveDetailsData.path) &&
                                                            <a title="GRN Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/grn-file/${receiveDetailsData.path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className=" w-1/3 items-center">
                                                    <label htmlFor="vendor_quotation_reference_number" className="form-input-sm-label">
                                                        {t('InvoiceAttach')}
                                                        {(errors.invoice_path && errors.invoice_path != null) &&
                                                        <span className="text-rose-900">
                                                             {errors.invoice_path?'.   required':''}
                                                        </span>
                                                        }
                                                    </label>
                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                        <Dropzone
                                                            onDrop={(file) => {
                                                                setError('invoice_path',null)
                                                                const data = {
                                                                    field_name : "path",
                                                                    value : file[0],
                                                                    id : receiveDetailsData.invoices
                                                                }
                                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                setLoading(true)
                                                            }}
                                                            onReject={(files) =>{
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Please select PDF file.',
                                                                    message: (t('DataLoad')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }}
                                                            accept={[MIME_TYPES.pdf]}
                                                            multiple={false}
                                                        >
                                                            <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                                <Dropzone.Accept>
                                                                    <IconUpload
                                                                        stroke={1.5}
                                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Accept>
                                                                <Dropzone.Reject>
                                                                    <IconX
                                                                        stroke={1.5}
                                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Reject>
                                                                <Dropzone.Idle>
                                                                    <IconPhoto  stroke={1.5} />
                                                                </Dropzone.Idle>

                                                                <div>
                                                                    <Text size="sm" inline>
                                                                        {
                                                                            (receiveDetailsData && receiveDetailsData.invoice_path) ?
                                                                                receiveDetailsData.invoice_path :
                                                                                'Drag PDF here or click to select files'
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            </Group>
                                                        </Dropzone>
                                                        {
                                                            (receiveDetailsData && receiveDetailsData.invoice_path) &&
                                                            <a title="Invoice Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/invoice/${receiveDetailsData.invoice_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className=" w-1/3 items-center">
                                                    <label htmlFor="vendor_quotation_reference_number" className="form-input-sm-label">{t('ChallanAttach')}</label>
                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                        <Dropzone
                                                            onDrop={(file) => {
                                                                const data = {
                                                                    field_name : "path",
                                                                    value : file[0],
                                                                    id : receiveDetailsData.calans
                                                                }
                                                                dispatch(ReceiveInvoiceChallanInlineUpdate(data))
                                                                setLoading(true)
                                                            }}
                                                            onReject={(files) =>{
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: 'Please select PDF file.',
                                                                    message: (t('DataLoad')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }}
                                                            accept={[MIME_TYPES.pdf]}
                                                            multiple={false}
                                                        >
                                                            <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                                <Dropzone.Accept>
                                                                    <IconUpload
                                                                        stroke={1.5}
                                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Accept>
                                                                <Dropzone.Reject>
                                                                    <IconX
                                                                        stroke={1.5}
                                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                    />
                                                                </Dropzone.Reject>
                                                                <Dropzone.Idle>
                                                                    <IconPhoto  stroke={1.5} />
                                                                </Dropzone.Idle>

                                                                <div>
                                                                    <Text size="sm" inline>
                                                                        {
                                                                            (receiveDetailsData && receiveDetailsData.challan_path) ?
                                                                                receiveDetailsData.challan_path :
                                                                                'Drag PDF here or click to select files'
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            </Group>
                                                        </Dropzone>
                                                        {
                                                            (receiveDetailsData && receiveDetailsData.challan_path) &&
                                                            <a title="Invoice Attachment" target="_blank" href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/challan/${receiveDetailsData.challan_path}`} className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">Open Attachment</a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-right mb-3">

                                                            <button
                                                                onClick={(e) => {
                                                                    const fromData = {
                                                                        receivedID: receiveDetailsData.id,
                                                                        invoice: filterData.invoice,
                                                                        challan: filterData.challan,
                                                                        bill_date: receiveDetailsData.bill_date ? receiveDetailsData.bill_date : verifyData.bill_date,
                                                                        bill_number: receiveDetailsData.bill_number ? receiveDetailsData.bill_number : verifyData.bill_number,
                                                                        send_back_replay: verifyData.replaySendBackComment,
                                                                        vendor: receiveDetailsData.vendors,
                                                                        buyer: receiveDetailsData.buyers,
                                                                        grn: receiveDetailsData.ref_grn
                                                                    }
                                                                    let validation = true
                                                                    let validationMessage = ''

                                                                    if (receiveDetailsData.bill_number) {
                                                                        if (!fromData.send_back_replay || fromData.send_back_replay == '' || fromData.send_back_replay == null) {
                                                                            validation = false
                                                                            validationMessage = t('EnterSendBackReplay')
                                                                        }
                                                                    }

                                                                    if (!fromData.challan || fromData.challan == '' || fromData.challan == null) {
                                                                        validation = false
                                                                        validationMessage = t('ChooseChallan')
                                                                    }
                                                                    if (!fromData.invoice || fromData.invoice == '' || fromData.invoice == null) {
                                                                        validation = false
                                                                        validationMessage = t('ChooseInvoice')
                                                                    }
                                                                    if (!receiveDetailsData.invoice_path || receiveDetailsData.invoice_path == '' || receiveDetailsData.invoice_path == null) {
                                                                        validation = false
                                                                        validationMessage = t('ChooseInvoice')
                                                                        setError("invoice_path", { type: "custom", message: " required" })
                                                                    }
                                                                    if (!fromData.bill_number || fromData.bill_number == '' || fromData.bill_number == null) {
                                                                        validation = false
                                                                        validationMessage = t('EnterBillNumber')
                                                                        setError("bill_number", { type: "custom", message: " required" })
                                                                    }
                                                                    if (!fromData.bill_date || fromData.bill_date == '' || fromData.bill_date == null) {
                                                                        validation = false
                                                                        validationMessage = t('ChooseBillDate')
                                                                        setError("bill_date", { type: "custom", message: " required" })
                                                                    }
                                                                    if (validation) {
                                                                        // console.log(fromData,receiveDetailsData)
                                                                        dispatch(receivedVerifyForPayment(fromData))
                                                                        if (verifyMessage != 'Already existing this bill number') {
                                                                            dispatch(setVerifyMessage(null))
                                                                            dispatch(setVerifyModelView(false))
                                                                            dispatch(setVerifyID(null))
                                                                            dispatch(setFetching(true))
                                                                            dispatch(setVerifyRefresh(true))
                                                                        } else {
                                                                            showNotification({
                                                                                id: 'load-data',
                                                                                loading: true,
                                                                                title: 'Already existing this bill number',
                                                                                message: (t('DataLoad')),
                                                                                autoClose: 1000,
                                                                                disallowClose: true,
                                                                                color: 'red',
                                                                            });
                                                                        }
                                                                    } else {
                                                                        showNotification({
                                                                            id: 'load-data',
                                                                            loading: true,
                                                                            title: validationMessage,
                                                                            message: (t('DataLoad')),
                                                                            autoClose: 1000,
                                                                            disallowClose: true,
                                                                            color: 'red',
                                                                        });
                                                                    }
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
            {poViewModel ? <PoViewModel/> : ''}
            {viewModel ? <View requisitionID={requisitionID} setViewModel={setViewModel}/> : ''}
        </>
    )
}

export default VerifyViewModel