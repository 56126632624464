import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,HiSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Group, LoadingOverlay, Select, Stack, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";

import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {openConfirmModal} from "@mantine/modals";
import {
    getPoReport,
    setPriceDirectoryReportFilterData,
    setFetching,
    setPage,
    getPoReportAll, setReceiveItemReportFilterData, getPriceDirectoryReport
} from "../../../../store/report/reportSlice";
import {vendorDropdown} from "../../../../store/vendor/vendorSlice";
import {showNotification} from "@mantine/notifications";
import {IconChevronRight} from "@tabler/icons-react";
import classes from './priceDirectoryNasted.module.css';
import clsx from "clsx";

const PAGE_SIZE = 20;

function PriceDirectoryReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })

    const [startDate,setStartDate] = useState(null)

    // start get project dropdown
    const [projectData, setProjectData] = useState('');
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    // start get contact person dropdown
    const [isDownload, setIsDownload] = useState(false);

    const priceDirectoryReportData = useSelector((state) => state.reportReducer.priceDirectoryReportData)

    const poReportReportData = useSelector((state) => state.reportReducer.poReportReportData)
    const poReportDownloadData = useSelector((state) => state.reportReducer.poReportDownloadData)
    const priceDirectoryReportFilterData = useSelector((state) => state.reportReducer.priceDirectoryReportFilterData)
    console.log(priceDirectoryReportFilterData && priceDirectoryReportFilterData.length)
    const totalItem = useSelector((state) => state.reportReducer.totalItem)
    const page = useSelector((state) => state.reportReducer.page)
    const fetching = useSelector((state) => state.reportReducer.fetching)
    const vendorDropdownData = useSelector((state) => state.vendorReducer.vendorDropdownData)

    const [expandedCompanyIds, setExpandedCompanyIds] = useState([]);

    useEffect(() => {
        dispatch(vendorDropdown())
    }, [fetching,page]);


    // start get item type dropdown
    const [itemType, setItemType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item-type/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    setItemType(res.data.data);
                }
            );
    }, []);

    let itemTypeDropdown =
        itemType.map((type, index) => {
            return ({
                'label': type.item_types, 'value': type.id
            })
        })
    // End get item type dropdown


    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }


    const CallPoReport = (page=1) => {
        if (priceDirectoryReportFilterData.start_date == '' || priceDirectoryReportFilterData.end_date == ''){
            showNotification({
                id: 'load-data',
                loading: true,
                title: 'Please select start & end date',
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            if (priceDirectoryReportFilterData.project == '' && priceDirectoryReportFilterData.item == '' && priceDirectoryReportFilterData.item_type == ''){
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: 'Choose project or item or item type',
                    message: (t('DataLoad')),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }else{
                dispatch(setFetching(true))
                const param = {
                    offset : PAGE_SIZE,
                    page : page,
                    project : priceDirectoryReportFilterData.project,
                    item : priceDirectoryReportFilterData.item,
                    item_type : priceDirectoryReportFilterData.item_type,
                    start_date : format(priceDirectoryReportFilterData.start_date,'yyyy-MM-dd'),
                    end_date : format(priceDirectoryReportFilterData.end_date,'yyyy-MM-dd')
                }

                dispatch(getPriceDirectoryReport(param))
            }
        }
    }

    const exportToCSV = () => {
        openConfirmModal({
            title: t('ItMaySomeTimeSure'),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureToDownloadAllPriceDirectory')}
                </Text>
            ),
            labels: {
                confirm: (t('Confirm')),
                cancel: (t("Cancel"))
            },
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setIsDownload(true)
                const param = {
                    offset : PAGE_SIZE,
                    page : page,
                    project : priceDirectoryReportFilterData.project,
                    item : priceDirectoryReportFilterData.item,
                    item_type : priceDirectoryReportFilterData.item_type,
                    start_date : format(priceDirectoryReportFilterData.start_date,'yyyy-MM-dd'),
                    end_date : format(priceDirectoryReportFilterData.end_date,'yyyy-MM-dd')
                }

                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/report/price-directory/generate-csv`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `text/html`,
                        "Content-Disposition": 'attachment; filename=data.csv',
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                    },
                    params : param
                })
                    .then(res => {
                        if (res.status === 200){
                            const  fileName = 'PriceDirectory-'+format(new Date(), 'dd-MM-yyyy')+'.csv'
                            const href = `${process.env.REACT_APP_API_GATEWAY_URL}/report/price-directory/download-csv`;
                            const anchorElement = document.createElement('a');
                            anchorElement.href = href;
                            anchorElement.download = fileName;
                            document.body.appendChild(anchorElement);
                            anchorElement.click();
                            setIsDownload(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        });
    }





    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('PriceDirectoryReport')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('PriceDirectoryRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            {(priceDirectoryReportData && priceDirectoryReportData.length>0) &&
                                                                <>
                                                                    <button
                                                                        onClick={(e) => exportToCSV()}
                                                                        className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                        <HiCloud size={16} className={'mr-1 animate-pulse'}/> Export in excel
                                                                    </button>
                                                                </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">

                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6">
                                                                        <DatePicker
                                                                            placeholder="Choose Start Date"
                                                                            value={priceDirectoryReportFilterData.start_date ? priceDirectoryReportFilterData.start_date : null}
                                                                            onChange={(e) => {
                                                                                if (e != null) {
                                                                                    dispatch(setPriceDirectoryReportFilterData({
                                                                                        ...priceDirectoryReportFilterData,
                                                                                        ['start_date']: e
                                                                                    }))
                                                                                } else {
                                                                                    dispatch(setPriceDirectoryReportFilterData({
                                                                                        ...priceDirectoryReportFilterData,
                                                                                        ['start_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6">
                                                                        <DatePicker
                                                                            placeholder="Choose End Date"
                                                                            value={priceDirectoryReportFilterData.end_date ? priceDirectoryReportFilterData.end_date : null}
                                                                            onChange={(e) => {
                                                                                if (e != null) {
                                                                                    dispatch(setPriceDirectoryReportFilterData({
                                                                                        ...priceDirectoryReportFilterData,
                                                                                        ['end_date']: e
                                                                                    }))
                                                                                } else {
                                                                                    dispatch(setPriceDirectoryReportFilterData({
                                                                                        ...priceDirectoryReportFilterData,
                                                                                        ['end_date']: null
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6 ">
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e) => {
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPriceDirectoryReportFilterData({
                                                                                    ...priceDirectoryReportFilterData,
                                                                                    ['project']: e
                                                                                }))
                                                                            }}
                                                                            value={priceDirectoryReportFilterData.project}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6 ">
                                                                        <Select
                                                                            placeholder={t("ChooseItem")}
                                                                            searchable allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={items}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onSearchChange={ItemSearchHandel}
                                                                            onChange={e => {
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPriceDirectoryReportFilterData({
                                                                                    ...priceDirectoryReportFilterData,
                                                                                    ['item']: e
                                                                                }))
                                                                            }}
                                                                            value={priceDirectoryReportFilterData.item}
                                                                            searchValue={itemSearchValue}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6 ">
                                                                        <Select
                                                                            placeholder={t("ChooseItemType")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={itemTypeDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e) => {
                                                                                dispatch(setPage(1))
                                                                                dispatch(setPriceDirectoryReportFilterData({
                                                                                    ...priceDirectoryReportFilterData,
                                                                                    ['item_type']: e
                                                                                }))
                                                                            }}
                                                                            value={priceDirectoryReportFilterData.item_type}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/6">
                                                                        <button
                                                                            onClick={(e) => CallPoReport()}
                                                                            className="inline-flex items-center px-2 py-2 bg-blue-600 text-white text-xs">
                                                                            <HiSearch size={16} className={'mr-1'}/> Submit
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <LoadingOverlay
                                                                    loaderProps={{
                                                                        size: 'sm',
                                                                        color: 'indigo',
                                                                        variant: 'bars'
                                                                    }}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{
                                                                    height: reportTableHeight,
                                                                    width: containerWidth
                                                                }} className="mt-3">
                    <DataTable
                        withBorder
                        records={priceDirectoryReportData}
                        withColumnBorders={1}
                        highlightOnHover
                        columns={[
                            {
                                accessor: 'classes',
                                title: '',
                                render: ({id}) => (
                                    <>
                                        <IconChevronRight
                                            className={clsx(classes.icon, classes.expandIcon, {
                                                [classes.expandIconRotated]: expandedCompanyIds.includes(id),
                                            })}
                                        />
                                    </>
                                ),
                            },
                            {
                                accessor: 'index',
                                title: 'SL#',
                                render: (item) => (priceDirectoryReportData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                            },
                            {accessor: 'category_name', title: <Text mr="xs">{t('CategoryName')}</Text>,},
                            {accessor: 'item_type_name', title: <Text mr="xs">{t('ItemType')}</Text>,},
                            {accessor: 'item_name', title: <Text mr="xs">{t('Item')}</Text>,},
                            {accessor: 'item_unit_name', title: <Text mr="xs">{t('UnitName')}</Text>,textAlignment:"center"},
                            {accessor: 'quantity', title: <Text mr="xs">{t('Quantity')}</Text>,textAlignment:"center"},
                            {
                                accessor: 'price_w_va',
                                title: <Text mr="xs">{t('PerUnitRate')}</Text>,
                                textAlignment : "right",
                                render: (item) =>
                                    <>
                                        {item.price_w_va &&
                                            item.price_w_va.toFixed(2)
                                        }
                                    </>
                            },
                            {accessor: 'brand', title: <Text mr="xs">{t('Brand')}</Text>,},
                            {accessor: 'created_date', title: <Text mr="xs">{t('Date')}</Text>,},
                        ]}
                        rowExpansion={{
                            expanded: { recordIds: expandedCompanyIds, onRecordIdsChange: setExpandedCompanyIds },
                            collapseProps: {
                                transitionDuration: 500,
                                animateOpacity: false,
                                transitionTimingFunction: 'ease-out',
                            },
                            content: ({ record }) => (
                                <Stack p="xs" gap={6} className="bg-indigo-100">
                                    <Group gap={6}>
                                        <table className="table-full w-full border-slate-800 text-xs">
                                            <tr>
                                                <th>{t('Area')}</th>
                                                <td>{record.area_name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('ProjectName')}</th>
                                                <td>{record.project_name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('Supplier')}</th>
                                                <td>{record.vendor_name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('Address')}</th>
                                                <td>{record.vendor_address}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('ContactPerson')}</th>
                                                <td>{record.contact_person}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('PONo')}</th>
                                                <td>{record.po_id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('PORefNo')}</th>
                                                <td>{record.po_ref_no}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('IssuedBy')}</th>
                                                <td>{record.created_by_full_name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('PurchaseType')}</th>
                                                <td>{record.purchase_type_name}</td>
                                            </tr>
                                        </table>
                                    </Group>
                                </Stack>
                            ),
                        }}
                        totalRecords={totalItem}
                        recordsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={(p) => {
                            dispatch(setPage(p))
                            CallPoReport(p)
                        }}
                        fetching={fetching}
                        loaderSize="xs"
                        loadercolor="blue"
                        loaderBackgroundBlur={1}
                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default PriceDirectoryReport