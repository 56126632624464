import React, {useEffect} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil, HiEye, HiCloudUpload, HiTrash, HiCloudDownload
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {ActionIcon, Box, Group, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {setSpinner} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {vendorDropdown} from "../../../store/vendor/vendorSlice";
import {
    ReceivesList,
    setPage,
    setActiveTab,
    setSortStatus,
    setOpened,
    setFetching,
    setFilterData,
    setRefresh,
    setRecViewModel,
    setRecViewID,
    setVerifyModelView,
    setVerifyID,
    setAddInvoiceModel,
    setAddInvoiceReceivedID,
    setVerifyFileUploadModel,
    setVerifyIdChecked,
    setVerifyIdUnChecked,
    setMultiVerifyModel,
    setProjectData, setReceivableItem, receivedDelete
} from "../../../store/receivedItem/receivedItemSlice";
import RecViewModel from "./RecViewModel";
import { projectDropdownUserWise} from "../../../store/project/projectSlice";
import VerifyViewModel from "./VerifyViewModel";
import AddInvoiceModel from "./AddInvoiceModel";
import VerifyUploadModel from "./VerifyUploadModel";
import MultiVerifyViewModel from "./MultiVerifyViewModel";
import {openConfirmModal} from "@mantine/modals";
import ReceiveFilter from "./ReceiveFilter";
const PAGE_SIZE = 20;

function Dashboard(){
    const {t, i18n} = useTranslation();
    const tableHeightWithFilter = localStorage.getItem('tableHeightWithFilter')

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    dispatch(setProjectData(null))
    dispatch(setReceivableItem(null))

    const ROLE_RECEIVE_CHECKED = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_CHECKED";
    })

    const ROLE_RECEIVE_APPROVE = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_APPROVE";
    })
    const ROLE_RECEIVE_VERIFY = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VERIFY";
    })
    const ROLE_RECEIVE_ADD = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_ADD";
    })
    const ROLE_RECEIVE_DELETE = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_DELETE";
    })
    const ROLE_RECEIVE_EDIT = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_EDIT";
    })
    const ROLE_RECEIVE_VIEW = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VIEW";
    })
    const ROLE_REQUEST_FOR_PAYMENT_ADD = decoded.roles.find(role => {
        return role === "ROLE_REQUEST_FOR_PAYMENT_ADD";
    })
    const ROLE_SUPER_ADMIN = decoded.roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const fetching = useSelector((state) => state.receivedItemReducer.fetching)
    const totalItem = useSelector((state) => state.receivedItemReducer.totalItem)
    const receivedIndexData = useSelector((state) => state.receivedItemReducer.receivedIndexData)
    const page = useSelector((state) => state.receivedItemReducer.page)
    const activeTab = useSelector((state) => state.receivedItemReducer.activeTab)
    const sortStatus = useSelector((state) => state.receivedItemReducer.sortStatus)
    const refresh = useSelector((state) => state.receivedItemReducer.refresh)
    const filterData = useSelector((state) => state.receivedItemReducer.filterData)
    const recViewModel = useSelector((state) => state.receivedItemReducer.recViewModel)
    const verifyModelView = useSelector((state) => state.receivedItemReducer.verifyModelView)
    const verifyRefreshModel = useSelector((state) => state.receivedItemReducer.verifyRefresh)
    const addInvoiceModel = useSelector((state) => state.receivedItemReducer.addInvoiceModel)
    const verifyFileUploadModel = useSelector((state) => state.receivedItemReducer.verifyFileUploadModel)
    const verifyIdChecked = useSelector((state) => state.receivedItemReducer.verifyIdChecked)
    const multiVerify = useSelector((state) => state.receivedItemReducer.multiVerify)

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit } = useForm(formOptions);

    function formSubmit(data) {
        dispatch(setSpinner(true))
        if (page === 1){
            dispatch(setRefresh(true))
        }else{
            dispatch(setPage(1))
        }
        dispatch(setOpened(false))
        dispatch(setFetching(true))
    }

    useEffect(()=>{
        dispatch(vendorDropdown())
        dispatch(projectDropdownUserWise())
    },[])

    useEffect(() => {
        const param = {
            activeTab : activeTab,
            offset : PAGE_SIZE,
            page : page,
            order_type : sortStatus.columnAccessor?sortStatus.columnAccessor:'',
            order_by : sortStatus.direction?sortStatus.direction:'',
            po_id : filterData.po ? filterData.po:'',
            grn_number : filterData.grn?filterData.grn:'',
            vendor : filterData.vendor?filterData.vendor:'',
            project : filterData.project?filterData.project:''
        }
        dispatch(ReceivesList(param))
    }, [page,activeTab,sortStatus,fetching,refresh,verifyRefreshModel]);

    const HandelPoTabChange = (e,value) => {
        e.preventDefault();
        dispatch(setActiveTab(value))
        dispatch(setPage(1))
        dispatch(setFetching(true))
    }

    useEffect(() => {
        dispatch(setFetching(true))
    }, [sortStatus]);


    return(
        <>
            <main className="flex flex-1 flex-1" >
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('ReceivedItem')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllReceivedItemRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            {
                                                                (ROLE_REQUEST_FOR_PAYMENT_ADD || ROLE_SUPER_ADMIN || ROLE_RECEIVE_VERIFY) &&
                                                                <button
                                                                    onClick={(e) =>{
                                                                        dispatch(setVerifyFileUploadModel(true))
                                                                    }}
                                                                    className="inline-flex items-center px-2 py-1 bg-blue-600 text-white text-xs">
                                                                    <HiCloudUpload size={16} className={'mr-1'}/> Top sheet for verify
                                                                </button>
                                                            }

                                                            {
                                                                (ROLE_RECEIVE_ADD || ROLE_SUPER_ADMIN) &&
                                                                <button onClick={(event) => {
                                                                    navigate('/received-item/create')
                                                                }}
                                                                        className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('Receive')}
                                                                </button>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">

                                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                                    <ReceiveFilter/>
                                                                </form>

                                                                <div className="h-11">
                                                                    <div className="flow-root">
                                                                        <div className="float-left">
                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'all' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'all')}>
                                                                                {t('All')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Created' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Created')}>
                                                                                {t('Created')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Checked' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Checked')}>
                                                                                {t('Checked')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Approved' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Approved')}>
                                                                                {t('Approved')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Draft' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Draft')}>
                                                                                {t('Draft')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'Archive' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'Archive')}>
                                                                                {t('Archive')}
                                                                            </button>

                                                                            <button
                                                                                className={`inline-flex items-center px-2 py-1 h-10 ${activeTab == 'SendBack' ? 'bg-rose-600' : 'bg-indigo-400'} hover:bg-rose-600 text-white text-xs `}
                                                                                onClick={(e) => HandelPoTabChange(e, 'SendBack')}>
                                                                                {t('SendBack')}
                                                                            </button>


                                                                            {
                                                                                ((ROLE_REQUEST_FOR_PAYMENT_ADD || ROLE_SUPER_ADMIN || ROLE_RECEIVE_VERIFY) && verifyIdChecked.length >= 1) &&
                                                                                <button
                                                                                    className={`inline-flex items-center ml-5 px-2 py-1 h-10 bg-rose-600 hover:bg-rose-600 text-white text-xs `}
                                                                                    onClick={(e) => {
                                                                                        dispatch(setMultiVerifyModel(true))
                                                                                    }}>
                                                                                    {t('VerifyForPayment')}
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <Box sx={{height: tableHeightWithFilter}}>

                                                                    <DataTable
                                                                        withBorder
                                                                        records={receivedIndexData}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'id',
                                                                                title: '',
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {((item.process === 'Approved' || item.process === 'SendBack') && item.inv_path) &&
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                name="verify_id[]"
                                                                                                value={item.id}
                                                                                                onClick={(e) => {
                                                                                                    if (e.target.checked === true) {
                                                                                                        dispatch(setVerifyIdChecked(e.target.value))
                                                                                                        dispatch(setFilterData({
                                                                                                            ...filterData,
                                                                                                            ['po']: item.po_id
                                                                                                        }))
                                                                                                        dispatch(setFetching(true))
                                                                                                    } else {
                                                                                                        dispatch(setVerifyIdUnChecked(e.target.value))
                                                                                                        if (verifyIdChecked.length === 1) {
                                                                                                            dispatch(setFilterData({
                                                                                                                ...filterData,
                                                                                                                ['po']: null
                                                                                                            }))
                                                                                                            dispatch(setFetching(true))
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'receivedDate',
                                                                                title: <Text mr="xs">Rec. Date</Text>,
                                                                                sortable: true,
                                                                                render: (item) => (
                                                                                    <span style={{ color: item.isOld == 1 ? 'red' : 'initial' }}>
                                                                                        {item.receivedDate}
                                                                                    </span>
                                                                                )
                                                                            },
                                                                            {
                                                                                accessor: 'grn_num',
                                                                                title: <Text mr="xs">Grn Num.</Text>,
                                                                                sortable: true,
                                                                            },

                                                                            {
                                                                                accessor: 'projectsName',
                                                                                title: 'Project Name'
                                                                            },
                                                                            {
                                                                                accessor: 'vendorsName',
                                                                                title: 'Vendor/Buyer',
                                                                                render: ({
                                                                                             vendorsName,
                                                                                             buyerEmail,
                                                                                             buyer_username
                                                                                         }) =>
                                                                                    <>
                                                                                        {
                                                                                            (vendorsName ? vendorsName : '')
                                                                                        }
                                                                                        {
                                                                                            (buyer_username ? buyer_username : buyerEmail)
                                                                                        }
                                                                                    </>

                                                                            },
                                                                            {accessor: 'po_id', title: 'PO Number'},
                                                                            {
                                                                                accessor: 'paymentFrom',
                                                                                title: 'Local/Head Office',
                                                                                render: ({paymentFrom}) =>
                                                                                    <>
                                                                                        {
                                                                                            (paymentFrom && paymentFrom === 1 ? 'Local Office' : 'Head Office')
                                                                                        }
                                                                                    </>

                                                                            },
                                                                            {
                                                                                accessor: 'process',
                                                                                title: 'Status',
                                                                                sortable: true,
                                                                                width: 100,
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {item.process === 'Draft' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-rose-300	">Draft</span>}
                                                                                        {item.process === 'Created' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-green-300	">Created</span>}
                                                                                        {item.process === 'Checked' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-yellow-300	">Checked</span>}
                                                                                        {item.process === 'Approved' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-lime-300	">Approved</span>}
                                                                                        {item.process === 'Archive' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-lime-300	">Archive</span>}
                                                                                        {item.process === 'SendBack' &&
                                                                                            <span
                                                                                                className="items-center px-2 py-1 text-black text-xs bg-rose-300	">Send Back</span>}
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'approved',
                                                                                title: <Text mr="xs">Approve</Text>,
                                                                                textAlignment: 'right',
                                                                                hidden: !ROLE_RECEIVE_APPROVE && !ROLE_RECEIVE_CHECKED && !ROLE_RECEIVE_VERIFY && !ROLE_SUPER_ADMIN && !ROLE_REQUEST_FOR_PAYMENT_ADD,
                                                                                render: (item) =>
                                                                                    <>
                                                                                        {
                                                                                            (item.process === 'Created' && (ROLE_RECEIVE_CHECKED || ROLE_SUPER_ADMIN)) &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setRecViewModel(true))
                                                                                                    dispatch(setRecViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-indigo-600 hover:bg-indigo-700 text-white text-xs">
                                                                                                Checked
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.process === 'Checked' && (ROLE_RECEIVE_APPROVE || ROLE_SUPER_ADMIN)) &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    dispatch(setRecViewModel(true))
                                                                                                    dispatch(setRecViewID(item.id))
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                                Approved
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.process === 'Approved' && item.inv_path && (ROLE_REQUEST_FOR_PAYMENT_ADD || ROLE_SUPER_ADMIN || ROLE_RECEIVE_VERIFY)) &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    setTimeout(() => {
                                                                                                        dispatch(setVerifyModelView(true))
                                                                                                        dispatch(setVerifyID(item.id))
                                                                                                    }, 500)
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                                Verify
                                                                                            </button>
                                                                                        }

                                                                                        {
                                                                                            (item.process === 'SendBack' && (ROLE_RECEIVE_VERIFY || ROLE_SUPER_ADMIN || ROLE_REQUEST_FOR_PAYMENT_ADD)) &&
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    setTimeout(() => {
                                                                                                        dispatch(setVerifyModelView(true))
                                                                                                        dispatch(setVerifyID(item.id))
                                                                                                    }, 500)
                                                                                                }}
                                                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 hover:bg-rose-700 text-white text-xs">
                                                                                                Verify
                                                                                            </button>
                                                                                        }
                                                                                    </>,
                                                                            },
                                                                            {
                                                                                accessor: '',
                                                                                title: <Text mr="xs">Add Invoice</Text>,
                                                                                textAlignment: 'center',
                                                                                width: 120,
                                                                                hidden: !ROLE_RECEIVE_VIEW && !ROLE_RECEIVE_EDIT && !ROLE_SUPER_ADMIN,
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="center"
                                                                                           noWrap>
                                                                                        {
                                                                                            (ROLE_RECEIVE_EDIT || ROLE_RECEIVE_VIEW || ROLE_RECEIVE_ADD || ROLE_SUPER_ADMIN) &&

                                                                                            <button
                                                                                                className={`rounded-full text-white px-2 py-2 text-xs ${item.inv_path && item.calans_path && item.grn_path ? 'bg-lime-800' : 'bg-rose-800'} `}

                                                                                                onClick={() => {
                                                                                                    dispatch(setAddInvoiceModel(true))
                                                                                                    dispatch(setAddInvoiceReceivedID(item.id))
                                                                                                }}
                                                                                            >
                                                                                                <HiOutlinePlus
                                                                                                    size={16}></HiOutlinePlus>
                                                                                            </button>
                                                                                        }

                                                                                    </Group>
                                                                                ),
                                                                            },
                                                                            {accessor: 'path',title: '',textAlignment:"center",
                                                                                render: (item) =>
                                                                                    <>
    {item.grn_path &&
        <a title="GRN"
           target="_blank"
           href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}${'/api/received/pdf-show/'+ item.id}/grn`}
           className="inline-flex items-center px-2 py-1 bg-indigo-200 text-indigo-800  hover:bg-indigo-800 hover:text-white text-xs">
            <HiCloudDownload/>
        </a>
    }
                                                                                    </>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">Action</Text>,
                                                                                textAlignment: 'right',
                                                                                hidden: !ROLE_RECEIVE_VIEW && !ROLE_RECEIVE_EDIT && !ROLE_RECEIVE_ADD && !ROLE_SUPER_ADMIN,
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>

                                                                                        {/*{
                                                                                            ((item.process == 'Created' || item.process == 'Draft') && (ROLE_RECEIVE_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="green" onClick={(e) => {
                                                                                                navigate('/received-item/update/'+item.id);
                                                                                            }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }*/}

                                                                                        {/*{
                                                                                            (ROLE_RECEIVE_VIEW || ROLE_SUPER_ADMIN) &&
                                                                                            <ActionIcon color="blue">
                                                                                                <HiEye size={16} onClick={(e) => {
                                                                                                    dispatch(setRecViewModel(true))
                                                                                                    dispatch(setRecViewID(item.id))
                                                                                                }}/>
                                                                                            </ActionIcon>
                                                                                        }*/}

                                                                                        {
                                                                                            ((item.process == 'Created' || item.process == 'Draft') && (ROLE_RECEIVE_ADD || ROLE_RECEIVE_EDIT || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="green"
                                                                                                        onClick={(e) => {
                                                                                                            navigate('/received-item/update/' + item.id);
                                                                                                        }}>
                                                                                                <HiPencil size={16}/>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => {
                                                                                                       dispatch(setRecViewModel(true))
                                                                                                       dispatch(setRecViewID(item.id))
                                                                                                   }}/>
                                                                                        </ActionIcon>

                                                                                        {
                                                                                            ((item.process == 'Draft') && (ROLE_RECEIVE_ADD || ROLE_RECEIVE_EDIT || ROLE_RECEIVE_DELETE || ROLE_SUPER_ADMIN)) &&
                                                                                            <ActionIcon color="red"
                                                                                                        onClick={(e) => {
                                                                                                            openConfirmModal({
                                                                                                                title: t('PurchaseOrderDelete'),
                                                                                                                centered: true,
                                                                                                                children: (
                                                                                                                    <Text
                                                                                                                        size="sm">
                                                                                                                        {t('AreYouSureToPurchaseOrderDelete')}
                                                                                                                    </Text>
                                                                                                                ),
                                                                                                                labels: {
                                                                                                                    confirm: (t('Confirm')),
                                                                                                                    cancel: (t("Cancel"))
                                                                                                                },
                                                                                                                confirmProps: {color: 'red'},
                                                                                                                onCancel: () => console.log(t('Cancel')),
                                                                                                                onConfirm: () => {
                                                                                                                    dispatch(receivedDelete(item.id))
                                                                                                                }
                                                                                                            });
                                                                                                        }}>
                                                                                                <HiTrash size={16}/>
                                                                                            </ActionIcon>
                                                                                        }
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            dispatch(setFetching(true))
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                        sortStatus={sortStatus}
                                                                        onSortStatusChange={(e) => dispatch(setSortStatus(e))}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {recViewModel?<RecViewModel />:''}
            {verifyModelView?<VerifyViewModel />:''}
            {multiVerify?<MultiVerifyViewModel />:''}
            {addInvoiceModel?<AddInvoiceModel />:''}
            {verifyFileUploadModel?<VerifyUploadModel />:''}
        </>
    )
}
export default Dashboard