import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
     HiChevronLeft, HiEye, HiArrowCircleLeft, HiArrowCircleRight, HiOutlineOfficeBuilding
} from "react-icons/hi";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {ScrollArea, Stepper, Button,} from '@mantine/core';
import {showNotification} from "@mantine/notifications";
import {useDispatch, useSelector} from "react-redux";
import {
    ReceiveDetails,
    ReceiveFinalUpdate,
    setFinalUpdateMSG, setRecViewID, setRecViewModel
} from "../../../store/receivedItem/receivedItemSlice";
import ReceivedNoteDetails from "./include/ReceivedNoteDetails";
import RecViewModel from "./RecViewModel";

function UpdateReceivedItem() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [recall, setRecall] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const [spinner, setSpinner] = useState(false);
    const loading = useSelector((state) => state.receivedItemReducer.loading)
    const recFinalUpdateMSG = useSelector((state) => state.receivedItemReducer.recFinalUpdateMSG)
    const recViewModel = useSelector((state) => state.receivedItemReducer.recViewModel)

    useEffect(() => {
        dispatch(ReceiveDetails({
            received_id: id
        }))
    }, [loading]);

    const validationSchemaStepOne = Yup.object().shape({
        grn_file: Yup.string().required('Choose grn file.').nullable(),
        challan_path: Yup.string().required('Choose challan file.').nullable(),
        challan_bill_number: Yup.string().required('Enter challan bill number.').nullable(),
        challan_bill_date: Yup.string().required('Enter challan bill date.').nullable(),
        physical_received_date: Yup.string().required('Enter physical received date.').nullable(),
        ref_grn: Yup.string().required('Enter grn reference.').nullable()
    });

    let formOptions = {resolver: yupResolver(validationSchemaStepOne)};

    // get functions to build form with useForm() hook
    const {handleSubmit, formState, setValue, setError, register} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        let validate = true
        let validateMessage = ''

        if (data.challan_bill_date == null) {
            validate = false
            validateMessage = t('ChooseChallanBillDate')
        }
        if (data.challan_bill_number == null) {
            validate = false
            validateMessage = t('EnterChallanBillNumber')
        }
        if (data.challan_path == null) {
            validate = false
            validateMessage = t('PleaseChooseChallanFile')
        }
        if (data.ref_grn == null) {
            validate = false
            validateMessage = t('EnterGrnRefNo')
        }
        if (data.physical_received_date == null) {
            validate = false
            validateMessage = 'Enter physical received date'
        }
        if (data.grn_file == null) {
            validate = false
            validateMessage = t('PleaseSelectGrnFile')
        }


        if (validate){
            dispatch(ReceiveFinalUpdate({
                id: id
            }))
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: validateMessage,
                message: (t('DataLoad')),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    if (recFinalUpdateMSG) {
        setTimeout(() => {
            dispatch(setFinalUpdateMSG(null))
            navigate('/received-item')
        }, 700)
    }

    return (
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('ReceivedItem')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('AllReceivedItemRelatedInformation')}</sub></span>
                                                    </h1>
                                                </div>

                                            </div>
                                            <div className="min-h-full w-full">
                                                <div className="flex w-full md:w-auto">
                                                    <div className="flex-1">
                                                        <div className="min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                                                <div className="h-full rounded">
                                                                    <div className="relative">
                                                                        <div className={'flex flex-1 w-full mt-3'}>
                                                                            <div className="w-full mr-0 mx-auto border">
                                                                                <form
                                                                                    onSubmit={handleSubmit(formSubmit)}
                                                                                    id="horizontal-form">
                                                                                    <ScrollArea
                                                                                        style={{height: fullFormHeight}}
                                                                                        scrollbarSize={4}>
                                                                                        <div
                                                                                            className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                                                            <Stepper active={active}
                                                                                                     onStepClick={setActive}
                                                                                                     breakpoint="sm"
                                                                                                     color="green"
                                                                                                     size="sm">
                                                                                                <Stepper.Step
                                                                                                    label={t("GoodsReceivedNote")}
                                                                                                    description={t('GoodsReceivedNoteDetails')}>
                                                                                                    <ReceivedNoteDetails
                                                                                                        register={register}
                                                                                                        setValue={setValue}
                                                                                                        setError={setError}
                                                                                                        errors={errors}
                                                                                                    />
                                                                                                </Stepper.Step>

                                                                                            </Stepper>
                                                                                        </div>
                                                                                    </ScrollArea>
                                                                                    <footer
                                                                                        className="relative mt-1 border-t">
                                                                                        <div className="mr-6">
                                                                                            <div className="text-right pt-0.5 mb-0.5 ">
                                                                                                <Button
                                                                                                    onClick={() => navigate(-1)}
                                                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                                                    <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Back')}</span></Button>


                                                                                                <button type='submit'
                                                                                                        className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                                                    {spinner ? (
                                                                                                        <AiOutlineLoading3Quarters
                                                                                                            className={'mr-2 animate-spin'}
                                                                                                            size={16}/>
                                                                                                    ) : (active == 5 ?
                                                                                                        <FiSave
                                                                                                            size={16}
                                                                                                            className={'mr-2'}/> :
                                                                                                        <HiArrowCircleRight
                                                                                                            size={16}
                                                                                                            className={'mr-2'}/>)}
                                                                                                    <span>{active == 0 ? t('Submit') : t('Next')}</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </footer>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {recViewModel?<RecViewModel />:''}

        </>
    )
}

export default UpdateReceivedItem