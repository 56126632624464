import {Group, Modal, ScrollArea, Select, TextInput, useMantineTheme} from '@mantine/core';
import React, {useEffect, useRef, useState} from "react";
import {HiOutlineExclamation} from "react-icons/hi";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import purchaseRequisitionReducer, {PrVehicleDropdown} from "../../../store/purchaseRequisition/purchaseRequisitionSlice";

function VariantModel(props) {
    const {opened, close, setVariantModel, setIsSlug, setMasterDataTypeSlug,setMasterDataDropdown} = props
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [companyDropdown, setCompanyDropdown] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const token = localStorage.getItem('user_token');


    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var fromHeight = height - 153 + "px";

    // const vehicleDropdown = useSelector((state) => state.purchaseRequisitionReducer.vehicleDropdown)
    //
    // console.log(vehicleDropdown)


    setIsSlug(false)

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/project-type/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setTimeout(() => {
                    if (res.data.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({'value': Number(head.id), 'label': head.projects_category_name})
                            })
                        setCompanyDropdown(data);
                    } else {
                        toast.error(res.data.message);
                    }
                }, 1000)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [])

    // FOR DATA TABLE DATA SHOW END
    const [companyData, setCompanyData] = useState('')
    const CompanyDataHandel = (e) => {
        setValue('company',e)
        setError('company',null)
        setCompanyData(e);
    }
    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("EnterVariantName")),
        company: Yup.string().required(t("ChooseCompany"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        data['master_data_slug'] = 'vehicle';
        data['status'] = true;
        // console.log(data)
        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/`,
            data,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                    if (res.data.status === 201) {
                        // dispatch(PrVehicleDropdown('vehicle'))
                        GetMasterDataDropdownByTypeSlug('vehicle')
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('DataAddedSuccesfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setMasterDataTypeSlug(data.master_data_slug)
                        setSpinner(false);
                        reset();
                        close()
                        setVariantModel(true)
                        setIsSlug(true)
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'red',
                        });
                    }
                }
            );
    }

    const GetMasterDataDropdownByTypeSlug = (typeSlug) =>{
        if (typeSlug && typeSlug != null && typeSlug != '') {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {
                    slug: typeSlug
                }
            })
                .then(res => {
                    if (res.data.status === 200) {
                        setMasterDataDropdown(
                            res.data.data.map((type, index) => {
                                return ({
                                    'label': type.name, 'value': Number(type.id)
                                })
                            })
                        )
                        setIsSlug(false)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }


    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Vehicle number modal"
                overlayBlur={3}
                overlayOpacity={0.55}
                overlayColor={"black"}
            >

                <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                    <ScrollArea style={{height: fromHeight}}>
                        <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                            {/* Varieties Name Section */}
                            <div className="mb-3 w-full items-center">
                                <label htmlFor="name"
                                       className="form-input-sm-label-required">{t('VehicleNumber')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.name?.type === 'required' &&
                                    <div
                                        className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={16}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                    </div>}
                                    <TextInput
                                        {...register("name")}
                                        placeholder={t("EnterVariantName")}
                                        radius="xs"
                                        size="xs"
                                        withAsterisk
                                    />
                                </div>
                            </div>

                            <div className="mb-3 w-full items-center">
                                <label htmlFor="company" className="form-input-sm-label-required">{t('Company')}</label>

                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                    {errors.company?.type === 'required' &&
                                    <div className="form-input-sm-error">
                                        <HiOutlineExclamation
                                            size={16}
                                            className={'mr-2'}></HiOutlineExclamation> {errors.company?.message}
                                    </div>}
                                    <Select
                                        {...register("company")}
                                        placeholder={t("ChooseCompany")}
                                        searchable clearable
                                        allowDeselect
                                        nothingFound="No options"
                                        withAsterisk
                                        data={companyDropdown}
                                        transition="pop-top-left"
                                        transitionDuration={80}
                                        transitionTimingFunction="ease"
                                        onChange={CompanyDataHandel}
                                        value={companyData}
                                    />
                                </div>
                            </div>


                        </div>
                    </ScrollArea>
                    <footer className="relative mt-1">
                        <div className="container mx-auto">
                            <div
                                className="text-right border-t pt-0.5 mb-0.5">
                                <button
                                    onClick={close}
                                    type='reset'
                                    className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                ><FiX
                                    size={16}
                                    className={'mr-2'}></FiX>
                                    <span>{t('Cancel')}</span>
                                </button>
                                <button type='submit'
                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                    {spinner ? (
                                        <AiOutlineLoading3Quarters
                                            className={'mr-2 animate-spin'}
                                            size={16}></AiOutlineLoading3Quarters>
                                    ) : (<FiSave size={16}
                                                 className={'mr-2'}></FiSave>)}
                                    <span>{t('Save')}</span>
                                </button>

                            </div>
                        </div>
                    </footer>

                </form>


            </Modal>
        </>
    );
}

export default VariantModel