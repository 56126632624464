import React, {useEffect, useState} from "react";
import {
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding,HiCloud,
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Box, LoadingOverlay, Select, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import axios from "axios";
import {
    getAllIssueItem,
    getIssueItem,
    setFetching, setIssueItemFilterData,
    setPage
} from "../../../store/inventory/inventorySlice";
import {DatePicker} from "@mantine/dates";
import {format} from "date-fns";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {openConfirmModal} from "@mantine/modals";
const PAGE_SIZE = 20;

function IssueItemProjectReport(){
    const {t, i18n} = useTranslation();

    const reportTableHeight = localStorage.getItem('reportTableHeight')
    const containerWidth = localStorage.getItem('containerWidth')
    const dispatch = useDispatch();

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_PURCHASE_ORDER_APPROVE_ONE = decoded.roles.find(role => {
        return role === "ROLE_PURCHASE_ORDER_APPROVE_ONE";
    })

    // start get project dropdown
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/project/dropdown/user-wise`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setProjects(res.data.data);
            });
    }, []);

    let projectDropdown =
        projects.map((type, index) => {
            return ({
                'value': Number(type.id), 'label': type.projects_name
            })
        })
    // End get project dropdown

    // start get contact person dropdown
    const [itemSearchValue, setItemSearchValue] = useState('');
    const [items, setItems] = useState([]);
    const [itemData, setItemData] = useState('');
    const [isDownload, setIsDownload] = useState(false);

    const ItemSearchHandel = (e) => {
        setItemSearchValue(e);
        if (itemSearchValue.length > 1) {
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/item/dropdown?value=${itemSearchValue}`,
                {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
                let data =
                    res.data.data.map((head, index) => {
                        return ({
                            'value': Number(head.id), 'label': head.items_name
                        })
                    })
                setItems(data);
            });
        }
    }


    const page = useSelector((state) => state.inventoryReducer.page)
    const totalItem = useSelector((state) => state.inventoryReducer.totalItem)
    const fetching = useSelector((state) => state.inventoryReducer.fetching)
    const issueItemFilterData = useSelector((state) => state.inventoryReducer.issueItemFilterData)
    const issueItems = useSelector((state) => state.inventoryReducer.issueItems)
    const issueItemsDownloadData = useSelector((state) => state.inventoryReducer.issueItemsDownloadData)
    const allIssueItems = useSelector((state) => state.inventoryReducer.allIssueItems)


    useEffect(() => {
        const param = {
            offset: PAGE_SIZE,
            page: page,
            order_type: 'id',
            order_by: 'DESC',
            delivery_type: 'project',
            data_for: 'report',
            deliveryDate: issueItemFilterData.deliveryDate ? issueItemFilterData.deliveryDate : null,
            project: issueItemFilterData.project ? issueItemFilterData.project : null,
            get_pass: issueItemFilterData.get_pass ? issueItemFilterData.get_pass : null,
            item: issueItemFilterData.item ? issueItemFilterData.item : null,
        }
        issueItemFilterData.project && dispatch(getIssueItem(param))
    }, [page, fetching]);


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToCSV = (formatType,type) => {
        if (type == 'current'){
            setIsDownload(true)
            const ws = XLSX.utils.json_to_sheet(issueItemsDownloadData);

            const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {
                bookType: formatType, type: 'array'
            });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'Issue-item-' +format(new Date(),'dd-MM-yyyy')+ '.'+formatType);
            setIsDownload(false)
        }else {
            if (allIssueItems.length==0) {
                const param = {
                    delivery_type: 'project',
                    data_for: 'report',
                    project: issueItemFilterData.project ? issueItemFilterData.project : null,
                }
                dispatch(getAllIssueItem(param))
            }
            openConfirmModal({
                title: 'May be huge data , it may some time , sure ?',
                centered: true,
                children: (
                    <Text size="sm">
                        {t('AreYouSureToDownloadAllStockItem')}
                    </Text>
                ),
                labels: {
                    confirm: (t('Confirm')),
                    cancel: (t("Cancel"))
                },
                confirmProps: {color: 'red'},
                onCancel: () => console.log(t('Cancel')),
                onConfirm: () => {
                    setIsDownload(true)
                }
                ,
            });
        }
    }

        if (isDownload){
            if (allIssueItems.length>0) {
                const ws = XLSX.utils.json_to_sheet(allIssueItems);
                const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'csv', type: 'array'
                });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, 'Issue-item-' + format(new Date(), 'dd-MM-yyyy') + '.' + 'csv');
                setIsDownload(false)
            }
        }


    return(
        <>
            <main className="flex flex-1 flex-1 ">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold text-base clear-both relative text-indigo-500 w-full'}>
                                                            {t('IssueItem')}
                                                            <sub className={'font-normal absolute left-0 top-7.5 text-indigo-400'}>  {t('IssueItemReportInformation')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex mt-2"}>
                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">
                                                            {issueItemFilterData.project &&
                                                                <>
                                                            <button
                                                                onClick={(e) => exportToCSV('csv', 'current')}
                                                                className="inline-flex items-center px-2 py-1 bg-blue-600 text-white text-xs ">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Download
                                                                (Current)
                                                            </button>

                                                                <button
                                                                onClick={(e) => exportToCSV('csv', 'all')}
                                                                className="inline-flex items-center px-2 py-1 bg-rose-600 text-white text-xs ml-4">
                                                                <HiCloud size={16} className={'mr-1 animate-pulse'}/> Download (All)
                                                                </button>
                                                            </>
                                                            }

                                                            <Link to=''
                                                                  className="justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'flex flex-1 overflow-hidden'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                <div className="h-11">
                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4 ">
                                                                        {/*<label htmlFor="SelectProject" className="form-input-sm-label">{t('ChooseProject')}</label>*/}
                                                                        <Select
                                                                            placeholder={t("ChooseProjectName")}
                                                                            searchable
                                                                            allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={projectDropdown}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onChange={(e)=>{
                                                                                dispatch(setIssueItemFilterData({
                                                                                    ...issueItemFilterData,
                                                                                    ['project']: e
                                                                                }))
                                                                                dispatch(setFetching(true))
                                                                            }}
                                                                            value={issueItemFilterData.project}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <Select
                                                                            placeholder={t("ChooseItem")}
                                                                            searchable  allowDeselect
                                                                            nothingFound="No options"
                                                                            withAsterisk
                                                                            data={items}
                                                                            transition="pop-top-left"
                                                                            transitionDuration={80}
                                                                            transitionTimingFunction="ease"
                                                                            onSearchChange={ItemSearchHandel}
                                                                            onChange={e => {
                                                                                dispatch(setIssueItemFilterData({
                                                                                    ...issueItemFilterData,
                                                                                    ['item']: e
                                                                                }))
                                                                                issueItemFilterData.project && dispatch(setFetching(true))
                                                                            }}
                                                                            value={issueItemFilterData.item}
                                                                            searchValue={itemSearchValue}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <TextInput
                                                                            onChange={(e) => {
                                                                                dispatch(setIssueItemFilterData({
                                                                                    ...issueItemFilterData,
                                                                                    ['get_pass']: e.target.value
                                                                                }))
                                                                                issueItemFilterData.project && dispatch(setFetching(true))
                                                                            }}
                                                                            placeholder={t("EnterGetPass")}
                                                                            value={issueItemFilterData.get_pass ? issueItemFilterData.get_pass : ''}
                                                                            withAsterisk
                                                                        />
                                                                    </div>

                                                                    <div className="mb-5 inline-flex items-center px-2 py-1 h-10 w-1/4">
                                                                        <DatePicker
                                                                            placeholder="Choose Date"
                                                                            value={issueItemFilterData.deliveryDate?issueItemFilterData.deliveryDate:null}
                                                                            onChange={(e)=>{
                                                                                if (e!=null) {
                                                                                    dispatch(setIssueItemFilterData({
                                                                                        ...issueItemFilterData,
                                                                                        ['deliveryDate']: format(e, 'yyyy-MM-dd')
                                                                                    }))
                                                                                    issueItemFilterData.project && dispatch(setFetching(true))
                                                                                }else {
                                                                                    dispatch(setIssueItemFilterData({
                                                                                        ...issueItemFilterData,
                                                                                        ['deliveryDate']: null
                                                                                    }))
                                                                                    issueItemFilterData.project && dispatch(setFetching(true))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <LoadingOverlay
                                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                                    visible={isDownload}
                                                                    overlayBlur={2}
                                                                />

                                                                <Box sx={{height: reportTableHeight,width: containerWidth}} className="mt-3">
                                                                    <DataTable
                                                                        withBorder
                                                                        records={issueItems}
                                                                        withColumnBorders={1}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'SL#',
                                                                                render: (item) => (issueItems.indexOf(item) + 1) + PAGE_SIZE * (page - 1)

                                                                            },
                                                                            {accessor: 'itemsName', title: <Text mr="xs">{t('Item')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'itemUnitName', title: <Text mr="xs">{t('Unit')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'quantity', title: <Text mr="xs">{t('Quantity')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'issue_date', title: <Text mr="xs">{t('IssueDate')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'issue_by_username', title: <Text mr="xs">{t('IssueBy')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'issue_customer_username', title: <Text mr="xs">{t('IssueTo')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'project_name', title: <Text mr="xs">{t('Project')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'issue_project_name', title: <Text mr="xs">{t('ProjectTo')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'getPass', title: <Text mr="xs">{t('GetPass')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'category_name', title: <Text mr="xs">{t('Category')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'sub_category_name', title: <Text mr="xs">{t('SubCategory')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'spend_area_name', title: <Text mr="xs">{t('SpendArea')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'erp_ledger_parent_name', title: <Text mr="xs">{t('ERPSecondaryGroup')}</Text>,textAlignment:'center'},
                                                                            {accessor: 'erp_ledger_name', title: <Text mr="xs">{t('ERPLedgerAccount')}</Text>,textAlignment:'center'},
                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => {
                                                                            dispatch(setPage(p))
                                                                            dispatch(setFetching(true))
                                                                        }}
                                                                        fetching={fetching}
                                                                        loaderSize="xs"
                                                                        loadercolor="blue"
                                                                        loaderBackgroundBlur={1}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default IssueItemProjectReport