import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import { LoadingOverlay, Group, Text, useMantineTheme} from "@mantine/core";
import {
    HiOutlineX,HiArrowCircleRight,HiOutlineExclamation,HiOutlineUpload,HiBan,HiOutlineDownload,HiOutlineDocumentText,HiOutlineDocumentAdd

} from "react-icons/hi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {FiArrowRight} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {
    setFetching, verifyListDownload, setVerifyFileUploadModel, verifyCsvUploadPost, setEmptyVerifyCsvUploadMessage
} from "../../../store/receivedItem/receivedItemSlice";

import {format} from "date-fns";
import {showNotification} from "@mantine/notifications";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";


function VerifyUploadModel() {
    const pdfRef = useRef()
    const {t, i18n} = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const modalHeight = localStorage.getItem('modalHeight');
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(true);
    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [fileProcess, setFileProcess] = useState(false);

    const token = localStorage.getItem('user_token');
    const decoded = jwt_decode(token);

    const ROLE_RECEIVE_VERIFY = decoded.roles.find(role => {
        return role === "ROLE_RECEIVE_VERIFY";
    })

    const verifyListDownloadDataForLocalOffice = useSelector((state) => state.receivedItemReducer.verifyListDownloadDataForLocalOffice)
    const verifyListDownloadDataForHeadOffice = useSelector((state) => state.receivedItemReducer.verifyListDownloadDataForHeadOffice)
    const verifyCsvUploadMessage = useSelector((state) => state.receivedItemReducer.verifyCsvUploadMessage)

    useEffect((e) => {
        setTimeout(() => {
            const param = {
                order_type : 'id',
                order_by : 'DESC',
            }
            dispatch(verifyListDownload(param))

            setLoading(false)
            setSpinner(false)
        }, 800)
    }, [loading])

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToCSV = (type,headLocal) => {
        let downloadData = [];
        if (headLocal == 'head'){
            downloadData = verifyListDownloadDataForHeadOffice
        }else {
            downloadData = verifyListDownloadDataForLocalOffice
        }
        if (downloadData && downloadData.length>0){
            const ws = XLSX.utils.json_to_sheet(downloadData);
            const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {
                bookType: type, type: 'array'
            });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'Received Verify top sheet ' +format(new Date(),'dd-MM-yyyy')+ '.'+type);
        }else {
            showNotification({
                id: 'load-data',
                loading: true,
                title: t('NoVerifyDataFound'),
                message: t('DataNotFound'),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }
    }

    if (verifyCsvUploadMessage  == 'Received verify for payment'){
        showNotification({
            id: 'load-data',
            loading: true,
            title: "Verify Successful.",
            message: 'success',
            autoClose: 1500,
            disallowClose: true,
            color: 'green',
        });
        setTimeout(()=>{
            dispatch(setEmptyVerifyCsvUploadMessage())
            dispatch(setVerifyFileUploadModel(false))
            dispatch(setFetching(true))
        },1500)
    }else {
        if (verifyCsvUploadMessage !=null){
            showNotification({
                id: 'load-data',
                loading: true,
                title: verifyCsvUploadMessage,
                message: 'Error',
                autoClose: 1500,
                disallowClose: true,
                color: 'red',
            });
            dispatch(setEmptyVerifyCsvUploadMessage())
        }

    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('UploadVerifyTopSheetInformation')}</h5>
                                <button
                                    onClick={() => {
                                        setFile(null)
                                        setFilePath(null)
                                        dispatch(setVerifyFileUploadModel(false))
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                                <LoadingOverlay
                                                    loaderProps={{size: 'sm', color: 'indigo', variant: 'bars'}}
                                                    visible={loading}
                                                    overlayBlur={2}
                                                />

                                                <Fragment>
                                                        <div className="flex w-full">
                                                            <div className="w-1/2 pr-2">
                                                                <div>
                                                                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                                                        <li className="flex gap-x-3">
                                                                            <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                            {t('YouneedtodownloadthefollowingCSVfiletofillthebilldatebillnumberofreceived')}
                                                                        </li>
                                                                        <li className="flex gap-x-3">
                                                                            <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                            {t('YouOnlyFindThoseReceivesWhoseDonTHaveBilldateBillnumber')}.
                                                                        </li>
                                                                        <li className="flex gap-x-3">
                                                                            <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                            {t('AfterFillingAllTheInformationUploadTheCsvFileHere')}.
                                                                        </li>
                                                                        <li className="flex gap-x-3 text-red-500">
                                                                            <HiOutlineExclamation size={18} className="h-6 w-5 flex-none text-red-500"/>
                                                                            {t('AfterFillingShouldNotChangeField')}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="flex justify-center mt-2">
                                                                    {
                                                                        (verifyListDownloadDataForLocalOffice && verifyListDownloadDataForLocalOffice.length>0) ?
                                                                        <button
                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                            onClick={(e) => exportToCSV('csv','local')}
                                                                        ><HiOutlineDownload size={16} className={'mr-1'} />{t('DownloadForLocalOffice')}
                                                                        </button>
                                                                            :
                                                                            <button
                                                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none bg-rose-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-0"
                                                                                onClick={(e) => {
                                                                                    console.log('ok')}}
                                                                            >{t('NoDataForLocalOffice')}
                                                                            </button>
                                                                    }

                                                                    {
                                                                        (verifyListDownloadDataForHeadOffice && verifyListDownloadDataForHeadOffice.length>0) ?
                                                                        <button
                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                            onClick={(e) => exportToCSV('csv','head')}
                                                                        ><HiOutlineDownload size={16} className={'mr-1'} />{t('DownloadForHeadOffice')}
                                                                        </button>
                                                                            :
                                                                            <button
                                                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none bg-rose-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-0"
                                                                                onClick={(e) => {
                                                                                    console.log('ok')}}
                                                                            >{t('NoDataForHeadOffice')}
                                                                            </button>
                                                                    }
                                                                </div>


                                                            </div>
                                                            <div className="w-1/2 relative">
                                                                {
                                                                    (!file && fileProcess) &&
                                                                    <div className="mt-6 form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/>Upload verify top sheet</div>
                                                                }
                                                                <Dropzone
                                                                    onDrop={(file) => {
                                                                        setFileProcess(false)
                                                                        setFilePath(file[0].path)
                                                                        setFile(file[0])
                                                                    }}
                                                                    accept={[MIME_TYPES.csv]}
                                                                    multiple={false}
                                                                >
                                                                    <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                                                                        <Dropzone.Accept>
                                                                            <HiOutlineUpload
                                                                                size={40}
                                                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                            />

                                                                        </Dropzone.Accept>
                                                                        <Dropzone.Reject>
                                                                            <HiOutlineX
                                                                                size={40}
                                                                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                            />
                                                                        </Dropzone.Reject>
                                                                        <Dropzone.Idle>

                                                                            {file ? <HiOutlineDocumentText size={40} className="text-indigo-800"/> : <HiOutlineDocumentAdd size={40} className="text-indigo-800"/>}
                                                                        </Dropzone.Idle>

                                                                        <div className="text-center">
                                                                            {
                                                                                file ? <Text size="xl" inline className="text-indigo-800">{t('FileSelected')} ({filePath})</Text> : (
                                                                                    <Fragment>
                                                                                        <Text size="xl" inline className="text-indigo-800">{t('DragFileHereOrClickToSelectFile')}</Text>
                                                                                        <Text size="sm" inline mt={7} className="text-indigo-300">
                                                                                            {t('Only')} <span className="font-bold font-italic">CSV</span> {t('formatAcceptable')}
                                                                                        </Text>
                                                                                    </Fragment>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </Group>
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                </Fragment>

                                            <footer className="relative border-t pt-0.5 mb-0.5 pr-2">
                                                <div className="justify-items-center">
                                                    <div className=" mt-3 mb-3">
                                                        <div className="float-right mb-3">

                                                            <button
                                                                onClick={(e) => {
                                                                    setFileProcess(true)
                                                                    if (file && filePath){
                                                                        dispatch(verifyCsvUploadPost({
                                                                            file : file
                                                                        }))
                                                                    }
                                                                }}
                                                                className="inline-flex items-center px-6 py-2 bg-green-700 hover:bg-green-900 text-white text-xs ml-2"
                                                            >
                                                                Process
                                                                {
                                                                    spinner ? ( <AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16}/> ) : (<FiArrowRight size={16} className={'ml-1'}/>)
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    )
}

export default VerifyUploadModel