import React, {useState} from "react";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {
    HiOutlineExclamation,
    HiOutlineEye,
    HiOutlineEyeOff,
    HiOutlineLogin
} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import {Navigate} from "react-router";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

function Login() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [loginErrorMessage, setLogingErrorMessage] = useState('');
    const [spinner, setSpinner] = useState(false);
    const user = localStorage.getItem("user_token");

    if(user){
        return <Navigate replace to="/dashboard"/>;
    }

    //Form validation
    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t("NameIsRequired")),
        password: Yup.string().required(t("NameIsRequired"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            username: data.username,
            password: data.password
        };

        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/login_check`,formData).then(res => {
            localStorage.setItem('user_token',res.data.token);
            const decoded = jwt_decode(res.data.token);

            const user = {
                "token" : res.data.token,
                "roles" : decoded.roles,
                "loggedUserId" : decoded.id,
                "username" : decoded.username,
            }
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem('roles',decoded.roles);
            localStorage.setItem('username',decoded.username);
            localStorage.setItem('loggedUserId',decoded.id);
            setSpinner(false);
            navigate('/dashboard/');
        }).catch(function(error){
            setLogingErrorMessage(error.response.data.message);
            setSpinner(false);
        });
    }

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img className="mx-auto h-12 w-auto" src={`${process.env.REACT_APP_IMAGE_FRONTEND}/assets/nourish_logo.png`} alt="Your Company"/>
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"> Sign in to your account </h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        {loginErrorMessage && <p className="text-red-600 bg-red-50 p-1 rounded">{loginErrorMessage}</p>}
                        <form className="space-y-6" onSubmit={handleSubmit(formSubmit)}>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">User Name</label>
                                    <div className="mt-1">
                                        {errors.username?.type === 'required' && <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.username?.message}
                                        </div>}
                                        <input
                                            {...register("username")}
                                            id="username"
                                            type="text"
                                            autoComplete="CreateUser Name"

                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm {errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}"
                                            />
                                    </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                <div className="mt-1">
                                    {
                                        errors.password?.type === 'required' &&
                                        <div className="form-input-sm-error">
                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.password?.message}
                                        </div>
                                    }
                                    <div className="relative flex">
                                        <input
                                            {...register("password")}
                                            id="password"
                                            type={ showPassword ? 'text' : 'password' }
                                            autoComplete="current-password"
                                            placeholder={`* * * * * * * *`}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />

                                        <span onClick={(e) => setShowPassword(prevState => !prevState)} className="cursor-pointer absolute right-0 top-0 bottom-0 inline-flex items-center px-3 text-gray-500 sm:text-sm">
                                            {
                                                showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />
                                            }
                                            </span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    <label htmlFor ="remember-me" className = "ml-2 block text-sm text-gray-900" >Remember me</label>
                                </div>

                                <div className="text-sm">
                                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password ?</a>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    {
                                        spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<HiOutlineLogin size={16} className={'mr-2'} />)
                                    }
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

</>
)
}

export default Login