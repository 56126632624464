import { combineReducers } from 'redux'

import purchaseOrderReducer from './purchaseOrder/purchaseOrderSlice'
import projectReducer from './project/projectSlice'
import purchaseTypeReducer from './purchase-type/purchaseTypeSlice'
import masterDataReducer from './master-data/masterDataSlice'
import vendorReducer from './vendor/vendorSlice'
import userReducer from './user/userSlice'
import receivedItemReducer from './receivedItem/receivedItemSlice'
import paymentReducer from './payment/paymentSlice'
import relatedBankReducer from './related-bank/relatedBankSlice'
import issueReducer from './issue/issueSlice'
import itemReducer from './items/itemsSlice'
import budgetReducer from './budget/budgetSlice'
import commonReducer from './common/commonSlice'
import storeRequisitionReducer from './storeRequisition/storeRequisitionSlice'
import inventoryReducer from './inventory/inventorySlice'
import advancePaymentReducer from './advancePayment/advancePaymentSlice'
import purchaseRequisitionReducer from './purchaseRequisition/purchaseRequisitionSlice'
import reportReducer from './report/reportSlice'
import categoryReducer from './category/CategorySlice'
import subCategoryReducer from './subCategory/subCategorySlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        projectReducer,
        purchaseOrderReducer,
        purchaseTypeReducer,
        masterDataReducer,
        vendorReducer,
        userReducer,
        receivedItemReducer,
        paymentReducer,
        relatedBankReducer,
        issueReducer,
        itemReducer,
        budgetReducer,
        commonReducer,
        storeRequisitionReducer,
        inventoryReducer,
        advancePaymentReducer,
        purchaseRequisitionReducer,
        reportReducer,
        categoryReducer,
        subCategoryReducer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
