import React from "react";
import {useTranslation} from "react-i18next";
import {Select} from "@mantine/core";

import {setFilterData as setPOFilterData} from "../../../store/purchaseOrder/purchaseOrderSlice";
import {setFilterData as setReceiveFilterData} from "../../../store/receivedItem/receivedItemSlice";
import {setFilterData as setPaymentFilterData} from "../../../store/payment/paymentSlice";
import {setFilterData as setAdvancePaymentFilterData} from "../../../store/advancePayment/advancePaymentSlice";
import {setFilterData as setStoreReqFilterData} from "../../../store/storeRequisition/storeRequisitionSlice";

import {useDispatch, useSelector} from "react-redux";

function SelectInputField(props){
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();


    const poFilterData = useSelector((state) => state.purchaseOrderReducer.filterData)
    const receiveFilterData = useSelector((state) => state.receivedItemReducer.filterData)
    const paymentFilterData = useSelector((state) => state.paymentReducer.filterData)
    const advancePaymentFilterData = useSelector((state) => state.advancePaymentReducer.filterData)
    const storeReqFilterData = useSelector((state) => state.storeRequisitionReducer.filterData)


    return(
        <>
            <Select
                placeholder={props.placeholder}
                searchable clearable
                allowDeselect
                nothingFound="No options"
                withAsterisk
                data={props.dropdownData}
                transition="pop-top-left"
                transitionDuration={80}
                transitionTimingFunction="ease"
                onChange={(e) => {
                    if(props.module === 'po'){dispatch(setPOFilterData({...poFilterData, [props.name]: e}))}
                    if(props.module === 'receive'){dispatch(setReceiveFilterData({...receiveFilterData, [props.name]: e}))}
                    if(props.module === 'payment'){dispatch(setPaymentFilterData({...paymentFilterData, [props.name]: e}))}
                    if(props.module === 'advance-payment'){dispatch(setAdvancePaymentFilterData({...advancePaymentFilterData, [props.name]: e}))}
                    if(props.module === 'store-req'){dispatch(setStoreReqFilterData({...storeReqFilterData, [props.name]: e}))}
                }}
                value={
                    props.module === 'po' ? poFilterData[props.name] :
                    props.module === 'receive' ? receiveFilterData[props.name] :
                    props.module === 'payment' ? paymentFilterData[props.name] :
                    props.module === 'advance-payment' ? advancePaymentFilterData[props.name] :
                    props.module === 'store-req' ? storeReqFilterData[props.name] :
                        ''
                }
            />
        </>
    )
}

export default SelectInputField